import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, PersonaThumbnail, VideoPlayer } from '@repo/ui'
import { IoReload } from 'react-icons/io5'
import { MdErrorOutline } from 'react-icons/md'

import {
  type AIQuestionAndAnswerStep,
  type CoachingStep,
} from '../../../builder/steps/api'
import { useGoogleStorageAsset } from '../../../hooks/use-google-storage-asset'
import { useAIBuilderContext } from '../../scenario-ai-builder-page/shared/ai-builder-context'
import { useInteractionStepContext } from '../../scenario-ai-builder-page/step-router/interaction-step/interaction-step-context'
import { useUpdateAiVideos } from '../use-update-ai-videos'

const PREVIEW_WIDTH = '380px'

export const AIQuestionAnswerStep = ({
  step,
  order,
  showUpdateButton,
}: {
  order: number
  step: CoachingStep
  showUpdateButton?: boolean
}) => {
  const aiStep = step.step as AIQuestionAndAnswerStep
  const { scenario } = useAIBuilderContext()
  const { setCurrentStep, setInitialQuestionIndex } = useInteractionStepContext()

  const videoHttpLink = useGoogleStorageAsset(
    aiStep.questionVideoGenerationData?.videoUrl
  )

  const { updateVideos, isLoading: isUpdatingVideos } = useUpdateAiVideos()

  return (
    <HStack gap={3} alignItems="flex-start">
      <Stack position="relative" alignItems="flex-start">
        {aiStep.questionVideoGenerationData?.status === 'done' && videoHttpLink ? (
          <VideoPlayer
            url={videoHttpLink}
            nativeControls
            aspectRatio="16/9"
            videoWidth={PREVIEW_WIDTH}
          />
        ) : (
          <Box
            w={PREVIEW_WIDTH}
            aspectRatio="16/9"
            pointerEvents="none"
            overflow="hidden"
          >
            <PersonaThumbnail
              avatarPreviewUrl={scenario.persona?.videoGenerationAvatar?.imageUrl}
              bgUrl={scenario.persona?.backgroundUrl}
              useGoogleStorageAsset={useGoogleStorageAsset}
            />
          </Box>
        )}

        {(aiStep.questionVideoGenerationData?.status === 'pending' ||
          aiStep.questionVideoGenerationData?.status === 'init') && (
          <Center position="absolute" inset={0} bg="rgba(0, 0, 0, 0.5)">
            <HStack p={2} alignItems="center">
              <Spinner color="white" w={4} h={4} />
              <Text color="white">
                <FormattedMessage id="scenario.builder.ai.steps.review.video.rendering" />
              </Text>
            </HStack>
          </Center>
        )}

        {aiStep.questionVideoGenerationData?.status === 'failed' && (
          <Center
            position="absolute"
            inset={0}
            bg="rgba(0, 0, 0, 0.7)"
            border="3px solid"
            borderColor={colors.red[400]}
          >
            <VStack p={2} alignItems="center" color="white">
              <Icon as={MdErrorOutline} fontSize={18} />
              <FormattedMessage id="scenario.builder.ai.steps.review.video.failed" />
              <Button
                bgColor="white"
                colorScheme="whiteAlpha"
                textColor={colors.blue[400]}
                _hover={{ bg: colors.secondary }}
                onClick={() => {
                  setCurrentStep('conversation-generation')
                  setInitialQuestionIndex(order - 1)
                }}
              >
                <FormattedMessage id="scenario.builder.ai.steps.review.video.failed.button" />
              </Button>
            </VStack>
          </Center>
        )}

        {aiStep.questionVideoGenerationData?.status === 'done' &&
          aiStep.isVideoGenerationDataChanged && (
            <Center position="absolute" inset={0} bg="rgba(0, 0, 0, 0.7)">
              <VStack p={2} alignItems="center" color="white">
                <Icon as={MdErrorOutline} fontSize={18} />
                <Text textAlign="center" w="250px" fontSize={14}>
                  <FormattedMessage id="scenario.builder.ai.steps.review.outdated.video" />
                </Text>
                {showUpdateButton && (
                  <Button
                    bgColor="white"
                    textColor={colors.blue[400]}
                    _hover={{ bg: colors.secondary }}
                    leftIcon={<IoReload />}
                    isLoading={isUpdatingVideos}
                    onClick={() => updateVideos([step.id])}
                    mt={2}
                  >
                    <FormattedMessage id="scenario.builder.ai.steps.review.outdated.video.button" />
                  </Button>
                )}
              </VStack>
            </Center>
          )}
      </Stack>
      <VStack alignItems="flex-start" maxW="612px">
        <Box
          minW={8}
          minH={8}
          borderRadius="full"
          border="1px solid"
          color="white"
          bg={colors.gray[700]}
          px="16px"
          py="4px"
          fontSize="14px"
          fontWeight={500}
        >
          <FormattedMessage id="questionnaire.form.question.text.label" /> {order}
        </Box>
        <Box borderRadius="8px" bg="white" p={2}>
          <Text>{aiStep.questionTranscript}</Text>
        </Box>
      </VStack>
    </HStack>
  )
}
