import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { ContextBar, showToast } from '@repo/ui'
import { type Undefined } from '@repo/utils'
import { get } from 'lodash-es'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { AppHeaderTitle } from '../../components/app-header-title'
import { RouteNotFoundError } from '../../components/route-not-found-error'
import { useCurrentTokenData } from '../../hooks/use-current-token-data'
import { useLocalStorage } from '../../hooks/use-local-storage'
import { FolderNavigation } from '../../recruiting/folder-navigation/folder-navigation'
import { RecordingFilters } from '../../recruiting/recording-filters/recording-filters'
import { useRecordingFiltersContext } from '../../recruiting/recording-filters/recording-filters-context'
import { FILTER_NUMERICAL_VALUES } from '../../recruiting/recording-filters/recording-filters-hooks'
import {
  type RecordingSession,
  useRecordingSessionsInfinite,
} from '../../recruiting/recording-sessions-api'
import { type Folder } from '../../store/entities/folders/folders-types'
import {
  makeSelectFolder,
  makeSelectFoldersList,
  selectRootFolderId,
} from '../../store/entities/folders/selectors'
import {
  type SelectFolderFn,
  type SelectFolderListFn,
} from '../../store/entities/folders/selectors-types'
import { api } from '../../utils/api'
import { FetchErrorAlert } from './fetch-error-alert'
import { ListInfoHeader } from './list-info-header'
import { RecordingListHeader } from './recording-list-header'
import { VideoList } from './video-list'

const PageLayoutContainer = styled.div`
  display: grid;
  grid-template-areas:
    'header header'
    'sidebar main';
  grid-template-rows: max-content 1fr;
  grid-template-columns: max-content 1fr;
  height: 100%;
  width: 100%;

  > .header {
    grid-area: header;
  }
  > .aside {
    grid-area: sidebar;
  }
  > .main {
    grid-area: main;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`

export const RecordingList = memo(() => {
  const navigate = useNavigate()
  const { folderId } = useParams()

  const rootId = useSelector(selectRootFolderId)
  const isRootFolder = folderId === rootId
  const selectFolder: SelectFolderFn = useMemo(makeSelectFolder, [])
  const selectFoldersList: SelectFolderListFn = useMemo(makeSelectFoldersList, [])

  const folder: Undefined<Folder> = useSelector(state => selectFolder(state, folderId))
  const list = useSelector(state => selectFoldersList(state, folderId))
  const listId = get<{ id: string }, 'id', null>(list, 'id', null)

  const {
    filterSelectValue: filterValue,
    columnSelectValue: columnValue,
    columnSelectValueRoot: columnValueRoot,
    sorting,
  } = useRecordingFiltersContext()

  const selectedColumn = isRootFolder ? columnValueRoot : columnValue
  const parameters = useMemo(
    () => ({
      listId,
      query: {
        specialCriteria: filterValue,
        ...sorting,
      },
    }),
    [listId, filterValue, sorting]
  )

  const {
    data,
    size,
    setSize,
    isValidating,
    error: requestError,
    mutate: updateRecordings,
    totalCount,
  } = useRecordingSessionsInfinite(parameters)

  // E.g. when deleting a recording and going back to the RecordingList,
  // SWR returns the cached list that still includes the deleted recording.
  // Therefore on every render, we revalidate the list to get the latest data.
  useEffect(() => {
    updateRecordings()
  }, [updateRecordings])

  const sessions = data?.flatMap(recordingsBatch => recordingsBatch) ?? []

  const onMoreRecordingsNeeded = () => {
    setSize(size + 1)
  }

  // totalCountSessions is used by swr to check if there are more sessions to fetch
  // in case a top-x filter is selected, we want to set this as fetch limit
  const totalCountSessions = Object.keys(FILTER_NUMERICAL_VALUES).includes(filterValue)
    ? Math.min(FILTER_NUMERICAL_VALUES[filterValue], totalCount)
    : totalCount

  const [selectedView, setSelectedView] = useLocalStorage('recording-list-view', 'list')

  const { isOwner } = useCurrentTokenData()

  const deleteRecording = useCallback(
    async (recordingId: string) => {
      try {
        await api.recordings.deleteRecording(recordingId)
        await updateRecordings()
        showToast({ messageKey: 'recording.delete.success', status: 'success' })
      } catch (error) {
        showToast({ messageKey: 'recording.delete.error', status: 'error' })
      }
    },
    [updateRecordings]
  )

  const onSelectRecording = useCallback(
    (selectedRecording: RecordingSession) =>
      navigate(`recordings/${selectedRecording.id}`),
    [navigate]
  )

  if (!list || !folder) {
    return <RouteNotFoundError />
  }

  const { plugin } = list

  return (
    <PageLayoutContainer>
      <AppHeaderTitle formattedMessageId="folders.title" />
      <Box pl="1.5rem" pr="1.5rem" className="header">
        <RecordingListHeader
          folder={folder}
          onChangeRecordingView={setSelectedView}
          recordingView={selectedView}
          pluginId={plugin.id}
          updateRecordings={updateRecordings}
        />
      </Box>

      <Box overflowY="auto" borderRight="1px" borderColor="#CBD5E0" className="aside">
        <ContextBar>
          <FolderNavigation />
        </ContextBar>
      </Box>
      <Box className="main" p="1.5rem">
        <RecordingFilters isRootFolder={isRootFolder} recordingView={selectedView} />
        <ListInfoHeader
          numberSessionsTotal={totalCount}
          filterCriteria={filterValue}
          sortingCriteria={sorting}
          isRootFolder={isRootFolder}
        />

        {requestError && <FetchErrorAlert />}

        <VideoList
          onDeleteRecording={deleteRecording}
          onSelectRecording={onSelectRecording}
          isRootFolder={isRootFolder}
          columns={selectedColumn}
          onMoreRecordingsNeeded={onMoreRecordingsNeeded}
          sessions={sessions}
          totalCountSessions={totalCountSessions}
          showDeleteButtons={isOwner}
          updateRecordings={updateRecordings}
          selectedView={selectedView}
          isValidating={isValidating}
        />
      </Box>
    </PageLayoutContainer>
  )
})
