import { type ThemeTypings } from '@chakra-ui/system'
import { useIntl } from '@repo/i18n'
import { Badge } from '@repo/ui'

import { type BenchmarkProfileState } from '../types/benchmark-profile'

type StateBadgeProps = {
  state: BenchmarkProfileState
}

const getBadgeColorScheme = (
  state: BenchmarkProfileState
): ThemeTypings['colorSchemes'] => {
  switch (state) {
    case 'processing':
      return 'orange'
    case 'error':
      return 'red'
    default:
      return 'green'
  }
}

export const BenchmarkProfileStateBadge = ({ state }: StateBadgeProps) => {
  const { formatMessage } = useIntl()
  const label = formatMessage({ id: `benchmarkProfile.state.${state}` })

  return <Badge label={label} colorScheme={getBadgeColorScheme(state)} />
}
