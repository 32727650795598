import { Box, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'

interface KeyValueWithInputProps {
  labelKey: I18nKey
  value: string | number
}

export const KeyValueWithInput = ({ labelKey, value }: KeyValueWithInputProps) => (
  <VStack alignItems="flex-start">
    <Text fontSize="14px" fontWeight={500}>
      <FormattedMessage id={labelKey} />
    </Text>
    <Box
      minW="300px"
      p={1}
      px={4}
      borderRadius="md"
      border="1px solid"
      borderColor={colors.gray[200]}
    >
      {value}
    </Box>
  </VStack>
)
