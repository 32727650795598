import { Box, Flex } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { useLocation } from 'react-router-dom'

import { AppHeaderTitle } from '../components/app-header-title'
import { CustomizeDashboardButton } from './dashboard-page/customize-dashboard-button'
import { DashboardFilters } from './dashboard-page/dashboard-filters'
import { DashboardRouter } from './dashboard-page/dashboard-router'
import { DashboardContextProvider } from './shared/dashboard-context'

export const DashboardPage = () => {
  const { pathname } = useLocation()

  // only show the customize button on the overview page
  const shouldShowCustomize = pathname === '/coaching/analytics'

  return (
    <Flex w="full" flexDir="column">
      <AppHeaderTitle formattedMessageId="dashboard.title" />

      <DashboardContextProvider>
        <Box
          position="sticky"
          top={0}
          zIndex={100}
          boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
          bgColor="white"
        >
          <Flex
            gap={2}
            bgColor={colors.gray[300]}
            height="60px"
            alignItems="center"
            px={7}
          >
            <DashboardFilters />
            {shouldShowCustomize && (
              <Flex justifyContent="flex-end" flex={1}>
                <CustomizeDashboardButton />
              </Flex>
            )}
          </Flex>
        </Box>

        <DashboardRouter />
      </DashboardContextProvider>
    </Flex>
  )
}
