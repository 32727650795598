import { type ApiOperations, type ApiSchemas } from '@repo/api'
import { intlObjectsForEachLocale } from '@repo/i18n'
import { assertExists } from '@repo/utils'
import { type FetchResponse } from 'openapi-fetch'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { client } from '../../utils/openapi-client'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'

type RequireKeys<T> = {
  [K in keyof T]: T[K]
}

export const useScenarioBuilderData = () => {
  const { programExtId, trainingExtId, scenarioExtId } = useParams()

  assertExists(programExtId, 'programExtId')
  assertExists(trainingExtId, 'trainingExtId')
  assertExists(scenarioExtId, 'scenarioExtId')

  const programSWR = useOpenapiSWR('getProgramByExtId', {
    params: { extId: programExtId },
  })

  const trainingSWR = useOpenapiSWR('getTrainingByExtId', {
    params: { programExtId, extId: trainingExtId },
  })

  const scenarioSWR = useOpenapiSWR('getScenarioByExtId', {
    params: { programExtId, trainingExtId, extId: scenarioExtId },
  })

  const scenario = scenarioSWR.data

  // this endpoint requires all fields to be present, so we need to merge the existing scenario data with the new data with an util
  const updateScenarioDetails = useCallback<
    (
      data: Partial<ApiSchemas['updateScenarioDetails']>
    ) => Promise<
      FetchResponse<ApiOperations['updateScenarioDetails'], 'put', `${string}/${string}`>
    >
  >(
    updatedFields => {
      assertExists(scenario, 'scenario')

      const mappedScenarioData: RequireKeys<ApiSchemas['updateScenarioDetails']> = {
        description: scenario.description,
        goal: scenario.goal,
        title: scenario.title,
        traineeRole: scenario.traineeRole,
        degreeOfRelationship: scenario.degreeOfRelationship!,
        interlocutorRole: scenario.interlocutorRole!,
        furtherDetails: scenario.furtherDetails,
        missionGoal: scenario.missionGoal,
        realtimeCallType: scenario.realtimeCallType,
      }

      return client.put('updateScenarioDetails', {
        body: {
          ...mappedScenarioData,
          ...updatedFields,
        },
        params: {
          path: {
            programExtId,
            trainingExtId,
            extId: scenarioExtId,
          },
        },
      })
    },
    [scenario, programExtId, trainingExtId, scenarioExtId]
  )

  const program = programSWR.data

  const formatInProgramLanguage = useCallback(
    (key: I18nKey): string => {
      assertExists(program, 'program')

      return intlObjectsForEachLocale[program.language].formatMessage({ id: key })
    },
    [program]
  )

  return [
    { programExtId, trainingExtId, scenarioExtId },
    programSWR,
    trainingSWR,
    scenarioSWR,
    updateScenarioDetails,
    formatInProgramLanguage,
  ] as const
}
