import { type Nil } from '@repo/utils'
import useSWR from 'swr'

type Invitation = {
  role: string
  invitationEmail: string
  organization: {
    name: string
  }
}

export const useInvitation = (invitationId: Nil<string>) =>
  useSWR<Invitation>(() => (invitationId ? `/invitations/${invitationId}` : null))
