import { FormControl, FormLabel, Switch } from '@chakra-ui/react'

export const CheckboxSliderField = ({ children, ...props }) => (
  <FormControl display="flex" alignItems="center">
    <Switch size="md" id="switch-label" {...props} />
    <FormLabel htmlFor="switch-label" mb="0" ml={2}>
      {children}
    </FormLabel>
  </FormControl>
)
