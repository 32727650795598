import { Button, HStack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, showToast } from '@repo/ui'
import { useState } from 'react'

import { SharedProgramStatusBadge } from '../components/shared-program-status-badge'
import { useProgramsList } from '../new-program-builder/shared/api'
import { client } from '../utils/openapi-client'
import { useOpenapiSWR } from '../utils/use-openapi-swr'
import { type UserNotificationsType } from './api'

export const ShareProgramNotification = ({
  notification,
}: {
  notification: UserNotificationsType
}) => {
  const [isLoadingAcceptButton, setIsLoadingAcceptButton] = useState(false)
  const [isLoadingRejectButton, setIsLoadingRejectButton] = useState(false)
  const { mutate: mutatePrograms } = useProgramsList()

  const { data: sharedProgramsData, mutate: mutateSharedProgram } =
    useOpenapiSWR('getSharedPrograms')

  const sharedProgram = sharedProgramsData?.find(
    item => item.extId === notification.payload.sharedProgramExtId
  )

  const onClickAcceptSharedProgram = async () => {
    try {
      if (!notification.payload.sharedProgramExtId) {
        return
      }

      setIsLoadingAcceptButton(true)

      const { error } = await client.put('acceptSharedProgram', {
        params: { path: { sharedExtId: notification.payload.sharedProgramExtId } },
      })

      if (error) {
        throw new Error()
      }

      mutateSharedProgram(
        sharedProgramsData?.map(item =>
          item.extId === sharedProgram?.extId ? { ...item, status: 'accepted' } : item
        ),
        false
      )

      setIsLoadingAcceptButton(false)
      showToast({
        messageKey: 'coaching.program.received.toast.accept.success',
        status: 'success',
      })

      mutatePrograms()
    } catch (err) {
      setIsLoadingAcceptButton(false)
      showToast({
        messageKey: 'coaching.program.received.toast.accept.error',
        status: 'error',
      })
    }
  }

  const onClickRejectSharedProgram = async () => {
    try {
      if (!notification.payload.sharedProgramExtId) {
        return
      }

      setIsLoadingRejectButton(true)
      const { error } = await client.put('rejectSharedProgram', {
        params: { path: { sharedExtId: notification.payload.sharedProgramExtId } },
      })

      if (error) {
        throw new Error()
      }

      mutateSharedProgram(
        sharedProgramsData?.map(item =>
          item.extId === sharedProgram?.extId ? { ...item, status: 'rejected' } : item
        ),
        false
      )

      setIsLoadingRejectButton(false)
      showToast({
        messageKey: 'coaching.program.received.toast.rejected.success',
        status: 'success',
      })
    } catch (err) {
      setIsLoadingRejectButton(false)
      showToast({
        messageKey: 'coaching.program.received.toast.rejected.error',
        status: 'error',
      })
    }
  }

  return (
    <>
      {sharedProgram?.status === 'pending' && (
        <HStack pl={2} my={2} spacing={2} justify="center">
          <Button
            type="submit"
            isLoading={isLoadingRejectButton}
            onClick={onClickRejectSharedProgram}
            variant="outline"
            size="xs"
            px="8px"
            minWidth="114px"
            height="32px"
          >
            <FormattedMessage id="coaching.program.received.modal.button.reject" />
          </Button>
          <Button
            type="submit"
            isLoading={isLoadingAcceptButton}
            onClick={onClickAcceptSharedProgram}
            size="xs"
            px="8px"
            minWidth="114px"
            height="32px"
          >
            <FormattedMessage id="coaching.program.received.modal.button.accept" />
          </Button>
        </HStack>
      )}
      {sharedProgram && <SharedProgramStatusBadge status={sharedProgram.status} />}
      {!sharedProgram && !!notification && (
        <Text color={colors.gray[700]} pl={2} fontSize="12px" fontWeight={500}>
          <FormattedMessage id="coaching.program.send.modal.tabs.requestsStatus.cancelled" />
        </Text>
      )}
    </>
  )
}
