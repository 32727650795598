import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { FieldArray, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { CgAdd } from 'react-icons/cg'
import { v4 as generateUuid } from 'uuid'

import { type CoachingStepFormFields } from '../../../../../../builder/steps/api'
import { QuizOption } from './quiz-option'

type TextOptionsProps = {
  readOnly: boolean | undefined
  disabled: boolean | undefined
}

export const TextOptions = ({ readOnly, disabled }: TextOptionsProps) => {
  const { values, setFieldValue } = useFormikContext<CoachingStepFormFields>()
  const { step } = values

  if (step.stepType !== 'coachingStepQuiz') {
    throw new Error('Wrong step type')
  }

  const [correctOptionId, setCorrectOptionId] = useState<string | undefined>(undefined)

  useEffect(() => {
    // When we delete an option, if we set correctOptionId to undefined, RadioGroup shows a random option selected
    // The (hacky) solution is setting the value to an nonexisting id
    setCorrectOptionId(
      step.optionsData.find(opt => opt.isCorrect)?.id ?? 'nonexisting-id'
    )
  }, [step.id, step.optionsData])

  const handleRadioChange = (selectedOptionId: string) => {
    step.optionsData.forEach((opt, index) => {
      setFieldValue(`step.optionsData.${index}.isCorrect`, selectedOptionId === opt.id)
    })
  }

  return (
    <FormControl>
      <FormLabel fontSize="sm">
        <FormattedMessage id="step.quiz.form.textOptions.label" />
      </FormLabel>
      <FormHelperText>
        <FormattedMessage id="step.quiz.form.textOptions.subLabel" />
      </FormHelperText>

      <Box p={4}>
        <FieldArray
          name="step.optionsData"
          render={arrayHelpers => (
            <VStack alignItems="flex-start" spacing="6">
              {step.quizType === 'singleChoice' ? (
                <RadioGroup
                  w="full"
                  onChange={handleRadioChange}
                  value={correctOptionId}
                  name="step.optionsData"
                >
                  {step.optionsData.map((option, index) => (
                    <QuizOption
                      key={option.id}
                      arrayHelpers={arrayHelpers}
                      optIndex={index}
                      isReadOnly={readOnly}
                      disabled={disabled}
                      option={option}
                    >
                      <Radio
                        colorScheme="green"
                        value={option.id}
                        isDisabled={readOnly}
                      />
                    </QuizOption>
                  ))}
                </RadioGroup>
              ) : (
                <CheckboxGroup>
                  {step.optionsData.map((option, index) => (
                    <QuizOption
                      key={option.id}
                      arrayHelpers={arrayHelpers}
                      optIndex={index}
                      isReadOnly={readOnly}
                      disabled={disabled}
                      option={option}
                    >
                      <Checkbox
                        colorScheme="green"
                        disabled={readOnly}
                        onChange={e => {
                          setFieldValue(
                            `step.optionsData.${index}.isCorrect`,
                            e.target.checked
                          )
                        }}
                        isChecked={option.isCorrect}
                      />
                    </QuizOption>
                  ))}
                </CheckboxGroup>
              )}

              <Button
                isDisabled={step.optionsData.length >= 6 || !!step.id}
                leftIcon={<CgAdd size="1.5rem" />}
                variant="ghost"
                colorScheme="gray"
                onClick={() =>
                  arrayHelpers.push({ id: generateUuid(), text: '', isCorrect: false })
                }
              >
                <FormattedMessage id="step.quiz.form.textOptions.add" />
              </Button>
            </VStack>
          )}
        />
      </Box>
    </FormControl>
  )
}
