import { Box } from '@chakra-ui/react'
import { times } from 'lodash-es'

import { HorizontalStepper } from '../horizontal-stepper'
import { ComponentSection } from './shared/component-section'
import { ComponentVariant } from './shared/component-variant'

const steps = times(5).map(
  i =>
    ({
      key: `key-${i}`,
      titleKey: 'common.name',
    }) as const
)

export const HorizontalStepperSection = () => (
  <ComponentSection title="HorizontalStepper">
    <ComponentVariant title="At first step">
      <Box w="500px">
        <HorizontalStepper currentStep="key-0" stepsWithTitles={steps} />
      </Box>
    </ComponentVariant>
    <ComponentVariant title="At third step">
      <Box w="500px">
        <HorizontalStepper currentStep="key-2" stepsWithTitles={steps} />
      </Box>
    </ComponentVariant>
  </ComponentSection>
)
