import { type ThemeTypings } from '@chakra-ui/react'
import { colors } from '@repo/ui'

import EndStage from '../../../../../../assets/end-stage.svg'
import Stage1 from '../../../../../../assets/stage1.svg'
import Stage2 from '../../../../../../assets/stage2.svg'
import Stage3 from '../../../../../../assets/stage3.svg'
import StepperTabImage1 from '../../../../../../assets/stepper-tab-image1.svg'
import StepperTabImage2 from '../../../../../../assets/stepper-tab-image2.svg'
import StepperTabImage3 from '../../../../../../assets/stepper-tab-image3.svg'

export type Stage = 'beginning' | 'mainPart' | 'end' | 'final'

type StageConfig = {
  name: Stage
  colorScheme: ThemeTypings['colorSchemes']
  color: string
  number: number
  image?: string
  icon: string
}

export const stageConfigs: Record<Stage, StageConfig> = {
  beginning: {
    name: 'beginning',
    colorScheme: 'pink',
    number: 1,
    image: StepperTabImage1,
    icon: Stage1,
    color: colors.pink[800],
  },
  mainPart: {
    name: 'mainPart',
    colorScheme: 'purple',
    number: 2,
    image: StepperTabImage2,
    icon: Stage2,
    color: colors.purple[800],
  },
  end: {
    name: 'end',
    colorScheme: 'orange',
    number: 3,
    image: StepperTabImage3,
    icon: Stage3,
    color: colors.indicator.orange,
  },
  final: {
    name: 'final',
    colorScheme: 'teal',
    number: 4,
    icon: EndStage,
    color: colors.teal[800],
  },
}
