import { Menu, RadioGroup, useControllableState } from '@chakra-ui/react'
import { type PropsWithChildren, useCallback } from 'react'

type RadioSelectProps = {
  defaultValue: string
  value: string
  onClose: (v: string) => void
  onChange: (v: string) => void
  isOpen: boolean
}

export const RadioSelect = ({
  children,
  defaultValue: initialDefaultValue,
  value: valueProp,
  onChange,
  onClose,
  isOpen,
}: PropsWithChildren<RadioSelectProps>) => {
  const onChangeInternal = useCallback((v: string) => onChange(v), [onChange])

  const [value, setValue] = useControllableState<string>({
    defaultValue: initialDefaultValue,
    value: valueProp,
    onChange: onChangeInternal,
  })

  const handleOnClose = useCallback(() => onClose(value), [onClose, value])

  return (
    <Menu closeOnSelect={false} isOpen={isOpen} onClose={handleOnClose} isLazy>
      <RadioGroup
        value={value ?? undefined}
        onChange={nextValue => setValue(String(nextValue))}
      >
        {children}
      </RadioGroup>
    </Menu>
  )
}
