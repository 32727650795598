import { chakra, type SystemStyleObject } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'

const itemStyles: SystemStyleObject = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  px: 3,
  py: 1,
}

/**
 * Looks like the chakra-ui `MenuItem`, but without the interactivity.
 */
export const MenuItemDiv = ({ children }: PropsWithChildren) => (
  <chakra.div sx={itemStyles}>{children}</chakra.div>
)
