import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { closeToast, colors, Header, Heading, LanguageSwitch, showToast } from '@repo/ui'
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  RiLogoutBoxRLine,
  RiSettings3Fill,
  RiTeamLine,
  RiUser3Line,
} from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'

import { logout } from '../authentication/authentication'
import { useOrganizationSettingsModal } from '../containers/organization/organization-settings-modal'
import { useOrganization } from '../hooks/use-organization'
import { UserNotifications } from '../notifications/user-notifications'
import { logOut } from '../store/auth/actions'
import { clearUserPropertiesOnLogout } from '../utils/analytics'
import { clearSWRCache } from '../utils/swr-fetcher'

export const AppHeaderTitleContext = createContext<{
  title: string
  setTitle: (heading: string) => void
}>({ title: '', setTitle: () => {} })

export const AppHeaderTitleProvider = ({ children }: PropsWithChildren) => {
  const [title, setTitle] = useState<string>('')

  const value = useMemo(() => ({ title, setTitle }), [title, setTitle])

  return (
    <AppHeaderTitleContext.Provider value={value}>
      {children}
    </AppHeaderTitleContext.Provider>
  )
}

export const AppHeader = () => {
  const { organization } = useOrganization()

  const { title } = useContext(AppHeaderTitleContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loggingOut, setLoggingOut] = useState(false)

  const handleLogout = useCallback(async () => {
    const loadingToast = showToast({
      messageKey: 'header.menu.logout.loading',
      status: 'loading',
      options: {
        duration: null,
      },
    })

    try {
      setLoggingOut(true)
      await logout()
      clearSWRCache()
      clearUserPropertiesOnLogout()
      dispatch(logOut())
      navigate('/login')
    } catch (error) {
      setLoggingOut(false)
      showToast({ messageKey: 'header.menu.logout.fail', status: 'error' })
    } finally {
      closeToast(loadingToast)
    }
  }, [dispatch, navigate])

  const [organizationSettingsModal, showOrganizationSettingsModal] =
    useOrganizationSettingsModal()

  return (
    <Header
      backgroundColor="gray.50"
      minHeight="64px"
      boxShadow={`0px 0px 10px ${colors.appHeaderShadow}`}
      opacity="1"
      zIndex={1400}
    >
      {organizationSettingsModal}
      <Heading fontSize="28px" fontWeight="medium" color="primary" pl="12px">
        {title}
      </Heading>
      <Box flexGrow={1} />

      <Text color={colors['primary-dark']} fontWeight="medium" px={4}>
        {organization?.name}
      </Text>

      <UserNotifications />
      <LanguageSwitch color="primary" />
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="User menu"
          bg="transparent !important"
          color={colors['primary-dark']}
          size="md"
          icon={<RiUser3Line size="20px" />}
        />
        <MenuList zIndex={100}>
          <MenuItem
            onClick={() => {
              showOrganizationSettingsModal()
            }}
            icon={<RiSettings3Fill />}
          >
            <FormattedMessage id="header.menu.settings" />
          </MenuItem>
          <MenuItem as={NavLink} to="/company/team" icon={<RiTeamLine />}>
            <FormattedMessage id="header.menu.team" />
          </MenuItem>
          <MenuItem
            as="button"
            onClick={handleLogout}
            isDisabled={loggingOut}
            icon={<RiLogoutBoxRLine />}
          >
            <FormattedMessage id="header.menu.logout" />
          </MenuItem>
        </MenuList>
      </Menu>
    </Header>
  )
}
