const types = {
  CREATE_QUESTIONNAIRE_REQUEST: 'questionnaires/create_request',
  CREATE_QUESTIONNAIRE_SUCCESS: 'questionnaires/create_success',
  RECEIVE_QUESTIONNAIRES: 'questionnaires/receive',
  RECEIVE_QUESTIONNAIRE: 'questionnaires/receive_one',
  UPDATE_QUESTIONNAIRE: 'questionnaires/update',
}

// eslint-disable-next-line import/no-default-export
export default types

export const receiveQuestionnaires = questionnaires => ({
  type: types.RECEIVE_QUESTIONNAIRES,
  data: questionnaires,
})

export const receiveQuestionnaire = (questionnaire, questions) => ({
  type: types.RECEIVE_QUESTIONNAIRE,
  data: { ...questionnaire, questions },
})

export const updateQuestionnaire = questionnaire => ({
  type: types.UPDATE_QUESTIONNAIRE,
  data: questionnaire,
})
