import { SharedUserNotifications, showToast } from '@repo/ui'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  deleteUserNotifications,
  setUserNotificationsHasViewed,
  useNotifications,
  type UserNotificationsType,
} from './api'
import { ShareProgramNotification } from './share-program-notification'

const renderShareProgramNotification = (notification: UserNotificationsType) => (
  <ShareProgramNotification notification={notification} />
)

export const UserNotifications = () => {
  const {
    data: notificationsData,
    error: notificationsError,
    mutate: mutateNotifications,
    isValidating,
  } = useNotifications()

  const navigate = useNavigate()
  const extIds = notificationsData?.map(dataNotification => dataNotification.extId)
  const isInitializing = notificationsData === undefined && isValidating

  useEffect(() => {
    const interval = 1 * 60 * 1000
    let intervalId: NodeJS.Timeout

    if (window.location.hostname !== 'localhost') {
      intervalId = setInterval(() => {
        mutateNotifications()
      }, interval)
    }

    return () => clearInterval(intervalId)
  }, [mutateNotifications])

  const handleOnClick = useCallback(
    async (notification: UserNotificationsType) => {
      try {
        navigate(notification.actionUrl ?? '')
        await setUserNotificationsHasViewed([notification.extId])
        const updatedData = notificationsData?.map(item =>
          item.extId === notification.extId ? { ...item, isViewed: true } : item
        )

        mutateNotifications(updatedData, false)
      } catch (err) {
        showToast({ messageKey: 'notification.set.hasViewed.error', status: 'error' })
      }
    },
    [navigate, mutateNotifications, notificationsData]
  )

  const handleMarkAllAsRead = useCallback(async () => {
    if (!notificationsData) {
      return
    }

    const previousData = [...notificationsData]

    try {
      if (extIds) {
        const updatedData = notificationsData?.map(item => ({ ...item, isViewed: true }))

        mutateNotifications(updatedData, false)

        await setUserNotificationsHasViewed(extIds)
      }
    } catch (err) {
      mutateNotifications(previousData, false)
      showToast({ messageKey: 'notification.set.hasViewed.error', status: 'error' })
    }
  }, [extIds, mutateNotifications, notificationsData])

  const handleEmptyBox = async () => {
    if (!notificationsData) {
      return
    }

    const previousData = [...notificationsData]

    try {
      if (extIds) {
        mutateNotifications([], false)
        await deleteUserNotifications(extIds)
      }
    } catch (err) {
      mutateNotifications(previousData, false)
      showToast({ messageKey: 'notification.delete.error', status: 'error' })
    }
  }

  const unreadNotificationCount = notificationsData?.filter(
    notification => !notification.isViewed
  ).length

  return (
    <SharedUserNotifications
      handleOnClick={handleOnClick}
      handleMarkAllAsRead={handleMarkAllAsRead}
      handleEmptyBox={handleEmptyBox}
      unreadNotificationCount={unreadNotificationCount}
      notificationsError={notificationsError}
      notificationsData={notificationsData}
      isInitializing={isInitializing}
      mutateNotifications={() => mutateNotifications()}
      renderShareProgramNotification={renderShareProgramNotification}
    />
  )
}
