import { FormattedMessage } from '@repo/i18n'
import { PageWrapper } from '@repo/ui'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { CenteredSpinner } from '../../components/centered-spinner'
import { selectFolderTree } from '../../store/entities/folders/selectors'
import { useInitFolders } from '../../store/entities/folders/use-init-folders'

export const FolderIndex = () => {
  const initialized = useInitFolders()

  const rootFolder = useSelector(selectFolderTree)

  if (!initialized) {
    return <CenteredSpinner />
  }

  // if user sees this, it's possible a bug, because we have a non-deletable root folder by default. This should never happen.
  if (!rootFolder) {
    return (
      <PageWrapper>
        <FormattedMessage id="recording.list.noRecords" />
        <br />
        <FormattedMessage id="recording.list.noRecordsLink" />
      </PageWrapper>
    )
  }

  return <Navigate to={rootFolder.id} />
}
