import { Button, Center, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast, useModal } from '@repo/ui'
import { type SubmitHandler } from 'react-hook-form'

import { type Training } from '../../../types/api-types'
import { client } from '../../../utils/openapi-client'
import { useOpenapiSWR } from '../../../utils/use-openapi-swr'
import { type TrainingFormValues } from '../../shared/types'
import { useTrainingForm } from '../use-training-form'

const EditTrainingForm = ({
  closeModal,
  training,
  programExtId,
}: {
  programExtId: string
  closeModal: () => void
  training: Training
}) => {
  const [form, formFieldsJsx] = useTrainingForm(training)
  const { handleSubmit, formState } = form
  const { mutate } = useOpenapiSWR('getTrainings', { params: { programExtId } })

  const onSubmit: SubmitHandler<TrainingFormValues> = async data => {
    try {
      const { data: updatedTraining } = await client.put('updateTraining', {
        params: { path: { programExtId, extId: training.extId } },
        body: data,
      })

      if (!updatedTraining) {
        throw new Error()
      }

      mutate(
        trainings =>
          trainings?.map(t =>
            t.extId === training.extId ? { ...t, ...updatedTraining } : t
          ),
        false
      )

      showToast({ messageKey: 'common.alert.saved', status: 'success' })
      closeModal()
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
      {formFieldsJsx}

      <Center gap={3} mt={6}>
        <Button variant="outline" onClick={closeModal}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          type="submit"
          isDisabled={!formState.isValid || !formState.isDirty}
          isLoading={formState.isSubmitting}
        >
          <FormattedMessage id="common.save" />
        </Button>
      </Center>
    </Stack>
  )
}

export const useEditTrainingModal = (training: Training, programExtId: string) => {
  const modal = useModal(
    ({ closeModal }) => (
      <EditTrainingForm
        training={training}
        programExtId={programExtId}
        closeModal={closeModal}
      />
    ),
    {
      titleKey: 'training.edit',
    }
  )

  return modal
}
