import { Button, type ButtonProps, HStack } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { type ReactNode } from 'react'

const RoundedButton = ({ isActive, ...rest }: ButtonProps) => (
  <Button
    variant="unstyled"
    borderRadius="full"
    fontWeight={300}
    border="none"
    display="flex"
    bgColor={isActive ? colors.blue[500] : 'none'}
    color={isActive ? 'white' : colors.blue[500]}
    {...rest}
  />
)

export const ToggleBetweenTwo = ({
  isLeftActive,
  toggleValue,
  left,
  right,
}: {
  isLeftActive: boolean
  toggleValue: () => void
  left: ReactNode
  right: ReactNode
}) => (
  <HStack spacing={0} bg="#E4EFFE" borderRadius="full" onClick={toggleValue}>
    <RoundedButton isActive={isLeftActive}>{left}</RoundedButton>
    <RoundedButton isActive={!isLeftActive}>{right}</RoundedButton>
  </HStack>
)
