import {
  Center,
  Spinner as ChakraSpinner,
  type SpinnerProps as ChakraSpinnerProps,
} from '@chakra-ui/react'

export type SpinnerProps = ChakraSpinnerProps & { centered?: boolean }

export const Spinner = ({ centered, ...rest }: SpinnerProps) => {
  const SpinnerEl = (
    <ChakraSpinner
      thickness="4px"
      speed="0.6s"
      emptyColor="gray.200"
      color="blue.500"
      {...rest}
    />
  )

  if (centered) {
    return <Center>{SpinnerEl}</Center>
  }

  return SpinnerEl
}
