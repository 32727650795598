import { Navigate } from 'react-router-dom'

import { type BuilderType } from './types'
import { useResumeStepKey } from './use-resume-step-key'

export const RedirectToBuilderResumeStep = ({
  builderType,
}: {
  builderType: BuilderType
}) => {
  const resumeStepKey = useResumeStepKey(builderType)

  return <Navigate to={resumeStepKey} replace />
}
