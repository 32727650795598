import { type SortOptions } from '@repo/ui'
import { type Nil } from '@repo/utils'
import { includes } from 'lodash-es'
import { stringify } from 'qs'
import { useState } from 'react'
import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'

import { api } from '../utils/api'
import { type FilterItemValue } from './recording-filters/filter-control-data'

export type FieldName =
  | RecordingSessionOwnField
  | RecordingSessionMetaField
  | RecordingSessionSummaryField

type RecordingSessionOwnField = 'createdAt' | 'id'
type RecordingSessionMetaField = 'name' | 'email'
type RecordingSessionSummaryField =
  | 'overallScore'
  | 'openness'
  | 'conscientiousness'
  | 'extraversion'
  | 'agreeableness'
  | 'neuroticism'
  | 'tfDealing'
  | 'tfIdentity'
  | 'tfVariety'
  | 'tfAutonomy'
  | 'tfSocialRelations'
  | 'cfCompetitive'
  | 'cfInnovative'
  | 'cfDetailOriented'
  | 'cfOutcomeOriented'
  | 'cfTeamOriented'
  | 'cfSupportive'
  | 'cfStability'

export type RecordingSession = {
  id: string
  result: Record<string, number>
  recordings: Array<Recording>
  benchmarkProfile: BenchmarkProfile
  meta: {
    name?: string
    email?: string
  }
  status: 'blocked' | 'waiting' | 'running' | 'failed' | 'done'
  tags: Array<string>
  createdAt: string
}

export type BenchmarkProfile = Array<{
  name: string
  ideal: number
  weight: number
}>

type Question = {
  id: string
  text: string
  note: string
  order: number
}
type Recording = {
  id: string
  previewUrl: string
  videoUrl: string
  question: Question
}
const recordingSessionMetaFields = ['name', 'email'] as const
const recordingSessionSummaryFields = [
  'overallScore',
  'openness',
  'conscientiousness',
  'extraversion',
  'agreeableness',
  'neuroticism',
  'tfDealing',
  'tfIdentity',
  'tfVariety',
  'tfAutonomy',
  'tfSocialRelations',
  'cfCompetitive',
  'cfInnovative',
  'cfDetailOriented',
  'cfOutcomeOriented',
  'cfTeamOriented',
  'cfSupportive',
  'cfStability',
] as const

export const isMetaField = (field: FieldName): field is RecordingSessionMetaField =>
  includes(recordingSessionMetaFields, field)

export const isSummaryField = (field: FieldName): field is RecordingSessionSummaryField =>
  includes(recordingSessionSummaryFields, field)

export const useRecordingSession = (recordingId: Nil<string>) =>
  useSWR<RecordingSession>(recordingId ? `/recordings/${recordingId}` : null)

type UseRecordingSessionsParams = {
  listId: string | null
  query: RecordingSessionQuery
}
type RecordingSessionQuery = {
  specialCriteria: FilterItemValue
  sortBy: FieldName
  sortOrder: Exclude<SortOptions, 'default'>
  limit?: number
  offset?: number
}

type RecordingSessionToken = {
  token: string
}

export const useRecordingToken = (recordingId: Nil<string>) =>
  useSWR<RecordingSessionToken>(recordingId ? `/recordings/${recordingId}/token` : null)

/**
 * Amount of items that are fetched per batch
 * Needs to be large enough to prevent problems with fetching the next batches
 * see https://github.com/ankeetmaini/react-infinite-scroll-component/issues/217
 */
const DEFAULT_RECORDING_SESSIONS_LIMIT = 16

export const useRecordingSessionsInfinite = (params: UseRecordingSessionsParams) => {
  const [totals, setTotals] = useState<Record<string, number>>({})
  const fetchWithTotalCount = async url => {
    const { items, total } = await api.recordings.getList(url)

    setTotals({ ...totals, [stringify(params)]: total })

    return items
  }

  const { data, size, setSize, isValidating, error, mutate } = useSWRInfinite<
    Array<RecordingSession>
  >(
    (pageIndex, previousPageData) => {
      if (!params || (previousPageData && !previousPageData.length)) {
        return null
      }

      const { listId, query } = params

      if (!listId) {
        return null
      }

      const limit = DEFAULT_RECORDING_SESSIONS_LIMIT
      const offset = pageIndex * Number(limit)

      return `/recordings?list=${listId}&${stringify({
        query: { ...query, offset, limit },
      })}`
    },
    fetchWithTotalCount,
    {
      // Avoid revalidating the first page every time the pageIndex changes
      // https://github.com/vercel/swr/issues/1401#issuecomment-939368020
      revalidateFirstPage: false,
    }
  )

  const hasSessions = data?.[0]?.length
  const totalCount = hasSessions ? (totals[stringify(params)] as number) : 0

  return {
    data,
    totalCount,
    size,
    setSize,
    isValidating,
    error,
    mutate,
  }
}
