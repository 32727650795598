import { type ThemeTypings } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { Badge } from '@repo/ui'

import { type ProgramStatus } from '../types/api-types'

type StateBadgeProps = {
  state: ProgramStatus
}

const getBadgeColorScheme = (state: ProgramStatus): ThemeTypings['colorSchemes'] => {
  switch (state) {
    case 'unpublished':
      return 'blue'
    case 'publishing':
      return 'orange'
    default:
      return 'green'
  }
}

export const ProgramStateBadge = ({ state }: StateBadgeProps) => {
  const { formatMessage } = useIntl()
  const label = formatMessage({
    id: `coaching.program.state.${state}` satisfies I18nKey,
  })

  return <Badge colorScheme={getBadgeColorScheme(state)} label={label} />
}
