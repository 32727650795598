import { Box, Button, Flex, Heading, Icon, Spacer } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'

import { Field } from '../../components/formik-form/field'
import { RangeSlider } from '../../components/range-slider'

export const AttributeField = ({
  id,
  className,
  minFieldValue,
  weightValue,
  label,
  onValueChange,
  onWeightChange,
  validateWeigthField,
  adjectives,
  description,
  details,
  hasScores,
  expertMode,
  ...props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) => {
  const [showDescription, setShowDescription] = useState(false)
  const [showWeightError, setShowWeightError] = useState(false)
  const toggleDescription = () => setShowDescription(!showDescription)
  const { formatMessage } = useIntl()
  const scoreLevels = [
    {
      value: 0,
      label: formatMessage({ id: 'benchmarkProfile.score.exclude' }),
    },
    {
      value: 0.25,
      label: formatMessage({ id: 'benchmarkProfile.score.low' }),
    },
    {
      value: 0.5,
      label: formatMessage({ id: 'benchmarkProfile.score.medium' }),
    },
    {
      value: 0.75,
      label: formatMessage({ id: 'benchmarkProfile.score.high' }),
    },
    {
      value: 1,
      label: formatMessage({ id: 'benchmarkProfile.score.essential' }),
    },
  ]

  const scoreLevelValues = [0, 0.25, 0.5, 0.75, 1]

  if (!scoreLevelValues.includes(weightValue)) {
    scoreLevels.push({
      value: weightValue,
      label: `${formatMessage({
        id: 'benchmarkProfile.score.custom',
      })}: ${weightValue}`,
    })
  }

  const validate = e => {
    onWeightChange(e.target.value)
    const isValid = validateWeigthField(e.target.value)

    setShowWeightError(!isValid)
  }

  return (
    <Box className={className} {...props}>
      <Flex direction="row" alignItems="center">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Heading as="h6" color="gray.600" margin="3px" size="xs" pr={2}>
            {label}
          </Heading>

          <Button
            colorScheme="gray"
            borderRadius="full"
            onClick={toggleDescription}
            p={1}
          >
            <Icon as={showDescription ? FaAngleUp : FaAngleDown} color="black" />
          </Button>
        </Box>
        <Spacer />
        {hasScores && !expertMode && (
          <Box>
            <Field
              type="select"
              name={`scores.${id}.normal`}
              options={scoreLevels}
              onChange={e => onWeightChange(parseFloat(e.target.value))}
              value={weightValue}
            />
          </Box>
        )}
        {hasScores && expertMode && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <FormattedMessage id="benchmarkProfile.score.label.expert" />
            <Box ml={2} w="60px">
              <Field
                type="text"
                name={`scores.${id}.expert`}
                onChange={e => onWeightChange(e.target.value)}
                value={weightValue}
                maxL={4}
                onBlur={validate}
              />
            </Box>
          </Box>
        )}
      </Flex>
      {showWeightError && (
        <Box textAlign="center" color="red">
          <FormattedMessage id="benchmarkProfile.score.custom.error" />
        </Box>
      )}
      {showDescription && <Box>{description}</Box>}
      <Box mt={4}>
        <RangeSlider single minValue={minFieldValue} onChange={onValueChange} />
      </Box>
      <Box mt={1} mb={2} w="full">
        {adjectives}
        {expertMode && <> (Value: {minFieldValue})</>}
      </Box>
      {showDescription && (
        <Box borderRadius="sm" p={2} bgColor="secondary">
          <p>{details}</p>
        </Box>
      )}
    </Box>
  )
}

AttributeField.defaultProps = {
  minFieldValue: 0,
  onValueChange: Function.prototype,
  onWeightChange: Function.prototype,
}

AttributeField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  minFieldValue: PropTypes.number,
  weightValue: PropTypes.node,
  label: PropTypes.node,
  onValueChange: PropTypes.func,
  onWeightChange: PropTypes.func,
  description: PropTypes.string,
  adjectives: PropTypes.string,
  details: PropTypes.string,
  hasScores: PropTypes.bool,
  expertMode: PropTypes.bool,
  validateWeigthField: PropTypes.func,
}
