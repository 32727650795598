import { useIntl } from '@repo/i18n'
import { useMemo } from 'react'

import { apiHandler, isApiRequestError } from './api-handler'
import { authHandler, isAuthError } from './auth-handler'
import type { ApiRequestError, AuthError } from './types'

const defaultErrorCode = 'error.unknownError'

type TranslateOptions = { logging?: boolean }

export const useErrorHandler = () => {
  const { formatMessage } = useIntl()

  return useMemo(() => {
    const translate = (error: unknown, _options?: TranslateOptions) => {
      let errorCode = defaultErrorCode

      if (isApiRequestError(error)) {
        errorCode = apiHandler(error as ApiRequestError)
      } else if (isAuthError(error)) {
        errorCode = authHandler(error as AuthError)
      }

      return formatMessage({ id: `errors.catalog.${errorCode}` as I18nKey })
    }

    return { translate }
  }, [formatMessage])
}
