import { createSelector } from 'reselect'

const getLocalState = state => state.entities.benchmarkProfiles

export const selectBenchmarkProfiles = createSelector(
  getLocalState,
  benchmarkProfiles => benchmarkProfiles
)

export const makeSelectBenchmarkProfileSelector = () =>
  createSelector(
    selectBenchmarkProfiles,
    (_, { id }) => id,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (profiles: any, id) => profiles.find(profile => profile.id === id)
  )
