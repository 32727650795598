import { type PropsWithChildren, useEffect, useState } from 'react'

import { getCurrentUserTokenData } from '../utils/api'

const roles = {
  owner: 'owner',
  member: 'member',
}

type Props = {
  allowedRoles?: Array<(typeof roles)[keyof typeof roles]>
}

// TODO remove allowedRoles, it's not used anywhere
export const Gate = ({ allowedRoles = [], children }: PropsWithChildren<Props>) => {
  const [hasAccess, setHasAccess] = useState(false)

  useEffect(() => {
    let unmounted = false
    const checkAccess = async () => {
      const { role } = await getCurrentUserTokenData()

      // We check if the component is unmounted, to avoid React from throwing an error
      if (unmounted) {
        return
      }

      // Owner can access everything
      if (role === roles.owner) {
        setHasAccess(true)
      } else {
        setHasAccess(allowedRoles.includes(role))
      }
    }

    checkAccess()

    return () => {
      unmounted = true
    }
  }, [allowedRoles, setHasAccess])

  if (!hasAccess) {
    return null
  }

  return children
}
