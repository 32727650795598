import {
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useOrganizationSettingsModal } from '../containers/organization/organization-settings-modal'
import { useOrganization } from '../hooks/use-organization'
import { savePrivacy } from '../store/entities/organization/effects'

// The GDPR compliance is very important, as the information is required for the Recorder-Plugin
// So for every company who doesn't have privacy data provided, will see this modal.
export const GDPRAlertModal = () => {
  const [seconds, setSeconds] = useState(10)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { hasReviewedGDPR } = useOrganization()
  const dispatch = useDispatch()

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(currentValue => {
        const newValue = currentValue - 1

        if (!newValue) {
          clearInterval(interval)
        }

        return newValue
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const [
    organizationSettingsModal,
    showOrganizationSettingsModal,
    ,
    isSettingsModalOpen,
  ] = useOrganizationSettingsModal()

  useEffect(() => {
    if (!hasReviewedGDPR && !isSettingsModalOpen) {
      onOpen()
    }
  }, [hasReviewedGDPR, onOpen, isSettingsModalOpen])

  const handleGDPRClick = useCallback(() => {
    showOrganizationSettingsModal()
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleReviewLater = useCallback(() => {
    dispatch(savePrivacy({ enabled: false }))
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {organizationSettingsModal}
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="lg"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader className="modal-header has-text-centered">
            <Heading size="md" className="modal-header-title">
              <FormattedMessage id="gdpr.alert.title" />
            </Heading>
          </ModalHeader>
          <ModalBody>
            <Text style={{ marginBottom: '1rem' }}>
              <FormattedMessage id="gdpr.alert.info" />
            </Text>
            <Heading size="sm" className="has-text-centered">
              <FormattedMessage id="gdpr.alert.warning" />
            </Heading>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <ButtonGroup variant="outline" spacing="6">
              <Button color="primary" onClick={handleGDPRClick}>
                <FormattedMessage id="gdpr.alert.settingsButton" />
              </Button>
              <Button
                color="primary"
                onClick={handleReviewLater}
                isDisabled={seconds > 0}
              >
                {seconds === 0 ? (
                  <FormattedMessage id="gdpr.alert.reviewLater" />
                ) : (
                  `${seconds}...`
                )}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
