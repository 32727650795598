import { Button, Center, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { type ModalContentRenderer, showToast, useModal } from '@repo/ui'
import { type SubmitHandler } from 'react-hook-form'

import { client } from '../../../utils/openapi-client'
import { useOpenapiSWR } from '../../../utils/use-openapi-swr'
import { type ExerciseFormValues } from '../../exercise-form-values'
import { useExerciseForm } from './use-exercise-form'

const CreateExerciseForm: ModalContentRenderer = ({ closeModal }) => {
  const [form, formFieldsJsx] = useExerciseForm()
  const { handleSubmit, formState } = form
  const { mutate } = useOpenapiSWR('getExercises')

  const onSubmit: SubmitHandler<ExerciseFormValues> = async data => {
    try {
      const { data: newExercise } = await client.post('createExercise', {
        body: {
          exerciseData: {
            ...data,
            durationInMinutes: Number(data.durationInMinutes),
          },
        },
      })

      if (!newExercise) {
        throw new Error()
      }

      mutate(
        exercises => [{ ...newExercise, programExtIds: [] }, ...(exercises ?? [])],
        false
      )

      closeModal()
      showToast({ messageKey: 'common.alert.created', status: 'success' })
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
      {formFieldsJsx}

      <Center gap={3} mt={6}>
        <Button variant="outline" onClick={closeModal}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          type="submit"
          isDisabled={!formState.isValid || !!formState.errors.imageUrl}
          isLoading={formState.isSubmitting}
        >
          <FormattedMessage id="common.create" />
        </Button>
      </Center>
    </Stack>
  )
}

export const useCreateExerciseModal = () => {
  const modal = useModal(CreateExerciseForm, {
    titleKey: 'exercise.editor.title.default',
    modalProps: { size: '2xl' },
  })

  return modal
}
