import { Box, Icon, Spacer, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { BsInbox } from 'react-icons/bs'

import { DashboardInfoBox } from './dashboard-info-box'

export const NoDataInformation = ({ customInfoKey }: { customInfoKey?: I18nKey }) => (
  <VStack spacing={8} maxW={600} mx="auto" mt={20} minH={380} flex={1}>
    <VStack maxW={400}>
      <Icon as={BsInbox} color={colors.blue[400]} w={10} h={10} mr={2} />
      <Text fontSize="md" textAlign="center">
        <FormattedMessage id="dashboard.cards.empty.label" />
      </Text>
    </VStack>
    <Spacer />
    <DashboardInfoBox sx={{ mb: '15px' }}>
      <Box>
        <Text fontSize="md" fontWeight="500">
          <FormattedMessage id="dashboard.cards.empty.alert.header" />
        </Text>
        <Text fontSize="md">
          <FormattedMessage id={customInfoKey ?? 'dashboard.cards.empty.alert.content'} />
        </Text>
      </Box>
    </DashboardInfoBox>
  </VStack>
)
