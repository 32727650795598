import { Box, Button, Center, Select, Stack, Text } from '@chakra-ui/react'
import {
  AVAILABLE_LANGUAGES,
  FormattedMessage,
  type LanguageKey,
  useIntl,
} from '@repo/i18n'
import { colors, showToast } from '@repo/ui'
import { Form, useFormikContext } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { MdDelete } from 'react-icons/md'

import { useCohortParticipants } from '../../../../../../use-cohort-participants'
import { CohortEmailInvitationsAlert } from '../../../../../cohort-email-invitations-alert'
import { type CohortCreationValues } from '../../../../types'
import { StepActions } from '../../../step-actions'
import { MultiTagInput, type MultiTagInputHandle } from './components/multi-tag-input'

const MAX_NUMBER_OF_EMAILS = 200

interface EmailStepProps {
  isCohortCreation?: boolean
}

export const EmailStep = ({ isCohortCreation = true }: EmailStepProps) => {
  const { addEmails } = useCohortParticipants()
  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    validateField,
    errors: { emails: errors },
    values: { emails, id: cohortId },
    setFieldError,
  } = useFormikContext<CohortCreationValues>()

  const { locale } = useIntl()
  const [backendErrors, setBackendErrors] = useState<Array<string>>([])
  const [frontendErrors, setFrontendErrors] = useState<Array<string>>([])
  const [emailErrors, setEmailErrors] = useState<Array<string>>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [preferredLanguage, setPreferredLanguage] = useState(locale)
  const multiTagInputRef = useRef<MultiTagInputHandle>(null)
  const { formatMessage } = useIntl()

  const hasReachedLimit = emails.length > MAX_NUMBER_OF_EMAILS
  const isButtonDisabled = emailErrors.length > 0 || hasReachedLimit || emails.length < 1

  const onChange = (values: Array<string>) => {
    setFieldValue('emails', values)
    setFieldTouched('emails', true)
    if (values.length === 0) {
      setFieldError('emails', '')
    } else {
      validateField('emails')
    }

    if (backendErrors.length > 0) {
      setBackendErrors(values.filter(error => backendErrors.indexOf(error) !== -1))
    }
  }

  const onSubmit = async () => {
    setIsSubmitting(true)
    const filteredEmails = emails.filter(
      (value, index, array) => index === array.indexOf(value)
    )

    try {
      await addEmails(filteredEmails, cohortId, preferredLanguage)

      if (!isCohortCreation) {
        showToast({ messageKey: 'common.alert.created', status: 'success' })
      }

      handleSubmit()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setBackendErrors(error.details)
    }

    setIsSubmitting(false)
  }

  useEffect(() => {
    if (errors) {
      if (Array.isArray(errors)) {
        setFrontendErrors(errors.filter(error => Boolean(error)))
      }
    } else {
      setFrontendErrors([])
    }
  }, [errors])

  // TODO: Get rid of this effect https://gitlab.com/retorio/platform/js-clients/-/merge_requests/1526#note_1708109422
  useEffect(() => {
    setEmailErrors([...backendErrors, ...frontendErrors])
  }, [backendErrors, frontendErrors])

  return (
    <Box h={460}>
      <Form>
        <FormattedMessage
          id={
            isCohortCreation
              ? 'cohort.creation.email.step.description'
              : 'email.participant.modal.description'
          }
        />
        <MultiTagInput
          onChange={onChange}
          errors={emailErrors}
          placeholder={formatMessage({
            id: 'cohort.creation.email.step.input.placeholder',
          })}
          ref={multiTagInputRef}
        />
        {emailErrors.length > 0 || hasReachedLimit ? (
          <Box>
            {hasReachedLimit && (
              <Text fontSize="xs" color={colors.red[500]}>
                <FormattedMessage id="cohort.creation.email.step.reached.limit.error" />
              </Text>
            )}
            {frontendErrors.length > 0 && (
              <Text fontSize="xs" color={colors.red[500]}>
                <FormattedMessage id="cohort.creation.email.step.frontend.error" />
              </Text>
            )}
            {backendErrors.length > 0 && (
              <Text fontSize="xs" color={colors.red[500]}>
                <FormattedMessage id="cohort.creation.email.step.backend.error" />
              </Text>
            )}
            <Center>
              <Button
                color={colors.blue[500]}
                leftIcon={<MdDelete fontSize="20px" />}
                variant="ghost"
                onClick={() => multiTagInputRef.current?.resetErrors()}
                size="md"
                mt={5}
              >
                <FormattedMessage id="cohort.creation.email.step.delete.wrong.emails.button" />
              </Button>
            </Center>
          </Box>
        ) : (
          <Box>
            <Text fontSize="xs">
              <FormattedMessage id="cohort.creation.email.step.footnote" />
            </Text>
            <Stack mt={5} mb={7}>
              <Text>
                <FormattedMessage id="cohort.creation.email.step.language.select" />
              </Text>
              <Select
                onChange={option =>
                  setPreferredLanguage(option.target.value as LanguageKey)
                }
                value={preferredLanguage}
                size="md"
                borderRightRadius="5px"
                width={200}
              >
                {AVAILABLE_LANGUAGES.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </Stack>
            <CohortEmailInvitationsAlert
              messageKey={
                isCohortCreation
                  ? 'cohort.creation.email.step.alert.description'
                  : 'cohort.add.email.participants.alert.description'
              }
            />
          </Box>
        )}
        {isCohortCreation ? (
          <StepActions
            onSubmit={onSubmit}
            isDisabled={isButtonDisabled}
            isSubmitting={isSubmitting}
            buttonKey="cohort.creation.email.step.send.invitations.button"
          />
        ) : (
          <Center>
            <Button
              position="absolute"
              bottom={8}
              onClick={onSubmit}
              isDisabled={isButtonDisabled}
              isLoading={isSubmitting}
            >
              <FormattedMessage id="cohort.creation.email.step.send.invitations.button" />
            </Button>
          </Center>
        )}
      </Form>
    </Box>
  )
}
