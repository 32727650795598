import { FormControl } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { CustomFormLabel, RHF, useFormRules } from '@repo/ui'
import { Controller, useFormContext } from 'react-hook-form'

import { ImageSelector } from '../../../image-selector'
import { traineeImageNames, traineeImageNameToUrl } from '../../trainee-image-utils'
import { type TraineeFormValues } from '../../types'

const getStringBeforeUnderscore = <T extends string>(path: `${T}_${number}`) =>
  path.split('_')[0] as T

export const StepGeneral = () => {
  const { control } = useFormContext<TraineeFormValues['general']>()
  const rules = useFormRules()
  const { formatMessage } = useIntl()

  return (
    <>
      <RHF.Input
        control={control}
        name="name"
        labelKey="common.name"
        rules={rules.stringShortRequired}
        tooltipKey="trainee.name.tooltip.text"
      />

      <Controller
        control={control}
        name="imageUrl"
        render={({ field, fieldState }) => {
          const options = [
            {
              imageSrc: '',
              key: '',
              label: formatMessage({ id: 'common.noBackground' }),
            },
            ...traineeImageNames.map(filename => ({
              key: traineeImageNameToUrl(filename),
              imageSrc: traineeImageNameToUrl(filename),
              label: `${formatMessage({
                id: `trainee.image.${getStringBeforeUnderscore(filename)}` satisfies I18nKey,
              })} ${filename.split('_')[1]}`,
            })),
          ]

          const currentOption = options.find(o => o.key === field.value)

          return (
            <FormControl isInvalid={!!fieldState.error}>
              <CustomFormLabel>
                <FormattedMessage id="scenario.builder.ai.steps.trainee.formLabel.image" />
              </CustomFormLabel>

              <ImageSelector
                titleKey="scenario.builder.ai.steps.trainee.formLabel.image"
                options={options}
                value={currentOption}
                onChange={value => field.onChange(value.key)}
                placeholderKey="common.select"
                searchPlaceholderKey="common.search"
              />
            </FormControl>
          )
        }}
      />
    </>
  )
}
