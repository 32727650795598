import { Box, Button, Center, HStack, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type PropsWithChildren } from 'react'
import { useFormContext } from 'react-hook-form'
import { BsStars } from 'react-icons/bs'
import { FiCheck } from 'react-icons/fi'

import {
  orderedFormSections,
  type QuestionFormValues,
  type SectionKey,
} from '../question-form-utils'

type Status = 'done' | 'current' | 'disabled'

const stepperColors: Record<
  Status,
  {
    bg?: string
    text?: string
  }
> = {
  current: {
    text: colors.blue[400],
  },
  disabled: {
    text: colors.gray[400],
  },
  done: {
    bg: colors.blue[500],
    text: 'white',
  },
}

export const QuestionFormSection = ({
  children,
  sectionKey,
  inputComponent,
}: PropsWithChildren<{
  sectionKey: SectionKey
  inputComponent?: React.ReactNode
}>) => {
  const { watch, formState } = useFormContext<QuestionFormValues>()
  const currentFormSection = watch('currentFormSection')

  const number = orderedFormSections.indexOf(sectionKey) + 1
  const status: Status =
    // eslint-disable-next-line no-nested-ternary
    sectionKey === currentFormSection
      ? 'current'
      : orderedFormSections.indexOf(sectionKey) <
          orderedFormSections.indexOf(currentFormSection)
        ? 'done'
        : 'disabled'

  const { bg, text } = stepperColors[status]

  const isLastSection = sectionKey === orderedFormSections.slice(-1)[0]
  const showSubmitButton = status === 'current' && !isLastSection

  return (
    <HStack gap={5} alignItems="stretch">
      <Center flexDirection="column">
        <Center
          minW={8}
          minH={8}
          fontSize={18}
          borderRadius="full"
          border="1px solid"
          color={text}
          bg={bg}
        >
          {status === 'done' ? <FiCheck /> : number}
        </Center>
        <Box
          borderLeft="1px solid"
          borderColor={status === 'done' ? colors.blue[500] : colors.gray[400]}
          h="100%"
        />
      </Center>
      <Stack pb={isLastSection ? 0 : 10} pt={1} flex={1}>
        {children}

        {status === 'disabled' ? (
          <Center height="100px" bg={colors.lightBlue} borderRadius="4px">
            <BsStars fontSize={22} color={colors.gray[400]} />
          </Center>
        ) : (
          inputComponent
        )}

        {showSubmitButton && (
          <Button
            type="submit"
            isDisabled={!formState.isValid}
            isLoading={formState.isSubmitting}
            mt={3}
            alignSelf="flex-end"
          >
            <FormattedMessage id="common.saveAndContinue" />
          </Button>
        )}
      </Stack>
    </HStack>
  )
}
