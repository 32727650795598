import '@fontsource/rubik/300.css'
import '@fontsource/rubik/400.css'
import '@fontsource/rubik/500.css'
import '@fontsource/rubik/600.css'
import '@fontsource/rubik/700.css'

import { blue } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

export const muiTheme = createTheme({
  typography: {
    fontFamily:
      'Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  shape: {
    borderRadius: '8px',
    borderRadiusLarge: '24px',
    borderRadiusFull: '9999px',
  },
  palette: {
    primary: {
      main: blue[800],
      light: blue[400],
      dark: blue[900],
      contrastText: '#ffffff',
    },
  },
})
