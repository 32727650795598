import { Box, Button } from '@chakra-ui/react'
import { type ProgramLanguages } from '@repo/api'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { Formik } from 'formik'
import { useCallback, useEffect, useState } from 'react'

import { useBenchmarkProfiles } from '../../hooks/use-benchmark-profiles'
import { type ProgramTrainingScenariosTree } from '../../types/program-training-scenario-tree'
import {
  SelectScenariosForm,
  type SelectScenariosFormValues,
} from '../select-scenarios-form'
import { ButtonContainer } from './button-container'
import { ContentContainer } from './content-container'

type LinkScenariosScreenProps = {
  handleClose: (selectedScenarioExtIds?: Array<string>) => void
  isCreatingBenchmarkProfile: boolean
  language: ProgramLanguages
}

export const LinkScenariosScreen = ({
  handleClose,
  isCreatingBenchmarkProfile,
  language,
}: LinkScenariosScreenProps) => {
  const { formatMessage } = useIntl()
  const { getProgramTrainingScenariosTree } = useBenchmarkProfiles()
  const [programTrainingScenarioTree, setProgramTrainingScenarioTree] =
    useState<ProgramTrainingScenariosTree>([])

  const fetchTree = useCallback(async () => {
    const { scenariosTree: tree } =
      (await getProgramTrainingScenariosTree({
        benchmarkProfileToFilterFor: 'NewBenchmarkProfile',
        language,
      })) || {}

    setProgramTrainingScenarioTree(tree || [])
  }, [getProgramTrainingScenariosTree, setProgramTrainingScenarioTree, language])

  useEffect(() => {
    if (!programTrainingScenarioTree.length) {
      fetchTree()
    }
  }, [programTrainingScenarioTree.length, getProgramTrainingScenariosTree, fetchTree])

  const handleSubmit = async (formData: SelectScenariosFormValues) => {
    try {
      handleClose(formData.selectedScenarioExtIds)
    } catch (err) {
      console.error(err)
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }
  }

  return (
    <ContentContainer
      title={formatMessage({
        id: 'coaching.benchmarking.wizard.linkScenarios.title',
      })}
      subtitle={formatMessage({
        id: 'coaching.benchmarking.wizard.linkScenarios.subtitle',
      })}
    >
      {programTrainingScenarioTree.length ? (
        <Formik onSubmit={handleSubmit} initialValues={{ selectedScenarioExtIds: [] }}>
          {({ submitForm, dirty, isValid }) => (
            <Box mt="10px" height="375px" overflow="scroll">
              <SelectScenariosForm
                programTrainingScenarioTree={programTrainingScenarioTree}
                showActionButtons={false}
                showAllCollapsed
              />
              <ButtonContainer>
                <Button
                  isLoading={isCreatingBenchmarkProfile}
                  variant="outline"
                  onClick={() => handleClose()}
                >
                  <FormattedMessage id="coaching.benchmarking.wizard.linkScenarios.cancel" />
                </Button>
                <Button
                  isLoading={isCreatingBenchmarkProfile}
                  onClick={submitForm}
                  isDisabled={!(dirty && isValid)}
                >
                  <FormattedMessage id="coaching.benchmarking.wizard.linkScenarios.continue" />
                </Button>
              </ButtonContainer>
            </Box>
          )}
        </Formik>
      ) : (
        <Box mt={8}>
          <FormattedMessage id="coaching.benchmarking.wizard.linkScenarios.noScenarios" />
        </Box>
      )}
    </ContentContainer>
  )
}
