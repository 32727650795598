import {
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Popover,
  PopoverBody,
  type PopoverBodyProps,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  type useDisclosure,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type ReactNode } from 'react'
import { type IconType } from 'react-icons'
import { BiChevronDown } from 'react-icons/bi'
import { MdClose } from 'react-icons/md'

import { SearchInput, type SearchInputProps } from './search-input'

interface SelectWithPopoverProps {
  titleKey: I18nKey
  placeholderKey: I18nKey
  icon?: IconType
  searchInputProps?: SearchInputProps
  children: ReactNode
  sx?: PopoverBodyProps['sx']
  inputValue?: string
  disclosure: ReturnType<typeof useDisclosure>
}

const popperModifiers = [
  {
    name: 'flip',
    options: {
      fallbackPlacements: ['top-start', 'right-start'],
    },
  },
]

export const SelectWithPopover = ({
  children,
  placeholderKey,
  searchInputProps,
  inputValue = '',
  titleKey,
  icon = BiChevronDown,
  sx,
  disclosure: { isOpen, onClose, onToggle },
}: SelectWithPopoverProps) => {
  const { formatMessage } = useIntl()

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-start"
      modifiers={popperModifiers}
    >
      <PopoverTrigger>
        <InputGroup size="sm" cursor="pointer" onClick={onToggle}>
          <Input
            border={isOpen ? `1px solid ${colors.blue[500]} !important` : undefined}
            cursor="pointer"
            isReadOnly
            placeholder={formatMessage({ id: placeholderKey })}
            value={inputValue}
          />
          <InputRightAddon px={3} bg="none" borderStart="1px solid" borderColor="inherit">
            <Icon fontSize={16} as={isOpen ? MdClose : icon} color={colors.blue[500]} />
          </InputRightAddon>
        </InputGroup>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          rootProps={{ zIndex: 'popover' }}
          width="360px"
          overflow="hidden"
          border="none"
          shadow="0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
        >
          <PopoverHeader p={4} fontWeight="semibold">
            <Text>
              <FormattedMessage id={titleKey} />
            </Text>
            {searchInputProps && <SearchInput {...searchInputProps} sx={{ mt: 3 }} />}
          </PopoverHeader>

          <PopoverBody p={0} sx={sx}>
            {children}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
