import { Center, Icon } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { BsInbox } from 'react-icons/bs'

import { colors } from '../theme/main'

export const EmptyStateIndicator = ({ messageKey }: { messageKey: I18nKey }) => (
  <Center py={6} flexDir="column" textAlign="center" gap={2} color={colors.gray[400]}>
    <Icon fontSize={20} as={BsInbox} />
    <FormattedMessage id={messageKey} />
  </Center>
)
