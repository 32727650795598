import { type AnyObject } from '@repo/utils'
import moment, { type Moment } from 'moment'

export type PluginFormValues = {
  questionnaireId: string
  identityRequired: boolean
  preventUpload: boolean
  expirationDate: Moment | null
  isActiveRecorder: boolean
  notifyApplicant: boolean
  shareWithTargetProfile: boolean
  hasShareResult: boolean
}

export type PluginData = {
  createdAt: string
  expirationDate: string | null
  id: string
  identityRequired: boolean
  notificationConfig: null
  notifications: []
  organization: AnyObject
  organizationId: number
  organizationName: string
  organization_settings: AnyObject
  preventUpload: boolean
  questionnaire: AnyObject
  tags: Array<string>
  updatedAt: string
  notifyApplicant: boolean
  shareWithTargetProfile: boolean
  hasShareResult: boolean
}

export const sanitizeFormData = (formData: PluginFormValues) => {
  const dayPickerDate = formData.expirationDate
    ? moment(formData.expirationDate.format('YYYY-MM-DD 23:59:59'))
    : null

  const expirationDate = formData.isActiveRecorder
    ? dayPickerDate
    : moment().subtract(1, 'days')

  const questionnaireId =
    formData.questionnaireId === '' ? null : formData.questionnaireId

  return { ...formData, expirationDate, questionnaireId }
}

export const getIsActiveRecorder = (date: string | null) => {
  if (date === null) {
    return true
  }

  const now = moment()
  const expirationDate = moment(date)

  return expirationDate.isAfter(now)
}

export const getInitialDateInputValue = (date: string | null) => {
  if (!getIsActiveRecorder(date)) {
    return null
  }

  return date ? moment(date) : null
}
