import { cloneDeep } from 'lodash-es'

import { type DashboardLayout } from './types'

export const mergeFetchedLayoutWithDefaultOne = (
  fetchedLayout: DashboardLayout,
  defaultLayout: DashboardLayout
): { shouldUpdateSavedConfig: boolean; mergedLayout: DashboardLayout } => {
  let mergedLayout = cloneDeep(fetchedLayout)

  // eslint-disable-next-line no-param-reassign
  defaultLayout = cloneDeep(defaultLayout)
  const defaultSectionIds = defaultLayout.map(s => s.id)
  const fetchedSectionIds = fetchedLayout.map(s => s.id)

  const removedSectionIds = fetchedSectionIds.filter(
    id => !defaultSectionIds.includes(id)
  )

  const newSectionIds = defaultSectionIds.filter(id => !fetchedSectionIds.includes(id))

  // remove sections that are not in default layout
  mergedLayout = mergedLayout.filter(section => !removedSectionIds.includes(section.id))

  // resolving per-section conflicts
  mergedLayout.forEach(section => {
    // get the default section
    const defaultSection = defaultLayout.find(s => s.id === section.id)

    if (!defaultSection) {
      return
    }

    // remove the cards that are not in the default layout
    section.cards = section.cards.filter(card =>
      defaultSection.cards.some(({ id }) => id === card.id)
    )

    // // add the new cards to the end
    defaultSection.cards.forEach(defaultCard => {
      if (!section.cards.some(c => c.id === defaultCard.id)) {
        section.cards.push(defaultCard)
      }
    })
  })

  // add new sections to the end of layout
  newSectionIds.forEach(newSectionId => {
    const newSection = defaultLayout.find(s => s.id === newSectionId)

    if (newSection) {
      mergedLayout.push(newSection)
    }
  })

  // create a hash of ids for hardcoded and fetched layouts
  // if the sorted ids are different, it means the saved config is outdated, so we update the saved config
  const defaultIds: Array<string> = []
  const fetchedIds: Array<string> = []

  defaultLayout.forEach(s => {
    defaultIds.push(s.id)
    s.cards.forEach(c => defaultIds.push(c.id))
  })

  fetchedLayout.forEach(s => {
    fetchedIds.push(s.id)
    s.cards.forEach(c => fetchedIds.push(c.id))
  })

  const defaultIdsSorted = defaultIds.toSorted().join('')
  const fetchedIdsSorted = fetchedIds.toSorted().join('')

  const shouldUpdateSavedConfig = defaultIdsSorted !== fetchedIdsSorted

  return { shouldUpdateSavedConfig, mergedLayout }
}
