import { HStack, Image, VStack } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { RHF, useFormRules } from '@repo/ui'
import { type Control } from 'react-hook-form'

import { type Stage, stageConfigs } from '../shared/stage-configs'

interface BehavioralAttitudeSectionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  name: Stage
}

export const BehavioralAttitudeSection = ({
  control,
  name,
}: BehavioralAttitudeSectionProps) => {
  const { formatMessage } = useIntl()

  const { icon } = stageConfigs[name]

  const rules = useFormRules()

  return (
    <VStack alignItems="flex-start" w="full">
      <HStack gap={2} alignItems="flex-start" w="full">
        <Image src={icon} mt={6} w="42px" h="42px" />
        <RHF.TextArea
          control={control}
          name={name}
          componentProps={{
            placeholder: formatMessage({
              id: `scenario.builder.ai.steps.behavioral.attitude.placeholder`,
            }),
          }}
          labelKey={
            `scenario.builder.ai.steps.behavioral.attitude.${name}.label.text` satisfies I18nKey
          }
          helperTextKey={
            `scenario.builder.ai.steps.behavioral.attitude.${name}.label.edit` satisfies I18nKey
          }
          rules={rules.string5k}
        />
      </HStack>
    </VStack>
  )
}
