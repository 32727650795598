import {
  Box,
  Button,
  ButtonGroup,
  CloseButton,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { HelpTooltip } from '@repo/ui'
import { FieldArray, type FieldArrayRenderProps, useFormikContext } from 'formik'
import { useCallback, useMemo } from 'react'
import { BsPlus } from 'react-icons/bs'
import { CgAdd, CgRemove } from 'react-icons/cg'
import { v4 } from 'uuid'

import { DragItem } from '../../components/drag-item'
import { InputControl } from '../../components/form-controls/input-control'
import { type CoachingStepFormFields, type GoalsInstructions } from '../api'

type GoalsProps = {
  disabled?: boolean
  fieldPrefix: 'dos' | 'donts'
  handleClose?: () => void
}

export const Goals = ({ disabled, fieldPrefix, handleClose }: GoalsProps) => {
  const { values } = useFormikContext<CoachingStepFormFields>()
  const { formatMessage } = useIntl()
  const OPTION_DROP_TYPE = 'text-option'

  const suggestionList = useMemo<Array<I18nKey>>(() => {
    if (!values.step || !values.step[fieldPrefix]) {
      return []
    }

    return Array.from(
      Array(4),
      (_, index) => `step.questionAnswer.${fieldPrefix}.suggestion${index + 1}`
    ).filter(
      suggestion =>
        !values.step[fieldPrefix].some((item: GoalsInstructions) =>
          item.instruction.includes(formatMessage({ id: suggestion as I18nKey }))
        )
    ) as Array<I18nKey>
  }, [fieldPrefix, formatMessage, values.step])

  const findItem = useCallback(
    (id: string) => {
      const opt = values.step[fieldPrefix].find(item => item.extId === id)

      return {
        opt,
        index: opt ? values.step[fieldPrefix].indexOf(opt) : -1,
      }
    },
    [fieldPrefix, values.step]
  )

  const handleClick = useCallback(
    (arrayHelpers: FieldArrayRenderProps, formattedSuggestion: string) => {
      const emptyOptionIndex = values.step[fieldPrefix].findIndex(
        item => !item.instruction
      )

      if (emptyOptionIndex > -1) {
        arrayHelpers.replace(emptyOptionIndex, {
          extId: values.step[fieldPrefix][emptyOptionIndex].extId,
          instruction: formattedSuggestion,
        })
      } else {
        arrayHelpers.push({ extId: v4(), instruction: formattedSuggestion })
      }
    },
    [fieldPrefix, values.step]
  )

  if (!values.step[fieldPrefix]) {
    return null
  }

  return (
    <VStack p={5} border="1px solid">
      {handleClose && (
        <CloseButton alignSelf="end" onClick={handleClose} disabled={disabled} />
      )}
      <FormControl>
        <Flex align="baseline">
          <FormLabel>
            <FormattedMessage id={`step.questionAnswer.${fieldPrefix}.label`} />
          </FormLabel>
          <HelpTooltip
            label={formatMessage({
              id: `step.questionAnswer.${fieldPrefix}.tooltipLabel`,
            })}
          />
        </Flex>
        <FormHelperText>
          <FormattedMessage id={`step.questionAnswer.${fieldPrefix}.subLabel`} />
        </FormHelperText>

        <Box p={4}>
          <FieldArray
            name={`step.${fieldPrefix}`}
            render={arrayHelpers => (
              <>
                {values.step[fieldPrefix].map(
                  (item: GoalsInstructions, optIndex: number) => (
                    <FormControl mt={2} key={item.extId}>
                      <DragItem
                        id={item.extId}
                        findItem={findItem}
                        moveItem={(id: string, atIndex: number) => {
                          const { index } = findItem(id)

                          arrayHelpers.swap(index, atIndex)
                          arrayHelpers.form.validateForm()
                        }}
                        canDrag={!disabled}
                        pos="relative"
                        dragIcon={false}
                        dragItemType={OPTION_DROP_TYPE}
                      >
                        <HStack spacing="2">
                          <InputControl
                            size="sm"
                            name={`step.${fieldPrefix}.${optIndex}.instruction`}
                            type="text"
                            placeholder={formatMessage({
                              id: 'step.questionAnswer.goals.placeholder',
                            })}
                            disabled={disabled}
                            isReadOnly={disabled}
                            withDrag
                          />
                          <IconButton
                            onClick={() => arrayHelpers.remove(optIndex)}
                            isDisabled={
                              !values.step[fieldPrefix].length ||
                              disabled ||
                              (fieldPrefix === 'dos' &&
                                values.step[fieldPrefix].length === 1)
                            }
                            variant="ghost"
                            colorScheme="gray"
                            aria-label={formatMessage({ id: 'step.option.delete' })}
                            icon={<CgRemove size="1.5rem" />}
                          />
                        </HStack>
                      </DragItem>
                    </FormControl>
                  )
                )}
                <Button
                  isDisabled={values.step[fieldPrefix].length >= 3 || disabled}
                  leftIcon={<CgAdd size="1.5rem" />}
                  variant="ghost"
                  colorScheme="gray"
                  style={{ marginTop: '1.5rem' }}
                  onClick={() => arrayHelpers.push({ extId: v4(), instruction: '' })}
                >
                  <FormattedMessage id="step.questionAnswer.form.textOptions.add" />
                </Button>

                {suggestionList.length && (
                  <>
                    <FormHelperText>
                      <FormattedMessage
                        id={`step.questionAnswer.${fieldPrefix}.suggestions`}
                      />
                    </FormHelperText>
                    <ButtonGroup
                      display="grid"
                      gridTemplateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
                      gap={4}
                      p={4}
                    >
                      {suggestionList.map(suggestion => {
                        const formattedSuggestion = formatMessage({ id: suggestion })

                        return (
                          <Button
                            p={5}
                            key={suggestion}
                            whiteSpace="pre-wrap"
                            onClick={() => handleClick(arrayHelpers, formattedSuggestion)}
                            isDisabled={
                              (values.step[fieldPrefix].length >= 3 &&
                                !values.step[fieldPrefix].some(
                                  item => !item.instruction
                                )) ||
                              disabled
                            }
                            variant="outline"
                            colorScheme="blue"
                            leftIcon={<BsPlus />}
                          >
                            <FormattedMessage id={suggestion} />
                          </Button>
                        )
                      })}
                    </ButtonGroup>
                  </>
                )}
              </>
            )}
          />
        </Box>
      </FormControl>
    </VStack>
  )
}
