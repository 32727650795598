import {
  Box,
  Button,
  type ButtonProps,
  Center,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { BsStars } from 'react-icons/bs'

interface AiInputPlaceholderProps {
  hideToneButtons?: boolean
  label: I18nKey
}

const toneButtonProps: ButtonProps = {
  size: 'xs',
  borderRadius: 'full',
  px: 4,
  variant: 'outline',
  isDisabled: true,
}

const MockToneButtons = () => (
  <HStack>
    <Text fontSize={14}>
      <FormattedMessage id="common.tone.select" />
    </Text>
    <Button {...toneButtonProps} variant="solid">
      <FormattedMessage id="common.tone.professional" />
    </Button>
    <Button {...toneButtonProps}>
      <FormattedMessage id="common.tone.catchy" />
    </Button>
    <Button {...toneButtonProps}>
      <FormattedMessage id="common.custom" />
    </Button>
  </HStack>
)

export const AiInputPlaceholder = ({
  hideToneButtons = false,
  label,
}: AiInputPlaceholderProps) => (
  <Stack gap={2}>
    <Text fontSize={14} fontWeight={500}>
      <FormattedMessage id={label} />
    </Text>
    {!hideToneButtons && <MockToneButtons />}
    <Box
      w="full"
      h="100px"
      bg={colors.gray[100]}
      borderRadius="lg"
      position="relative"
      overflow="hidden"
    >
      <Center h="full">
        <Icon as={BsStars} color={colors.gray[400]} fontSize={22} />
      </Center>
    </Box>
  </Stack>
)
