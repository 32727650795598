import { api } from '../../../utils/api'
import * as actions from './private/actions'

export const fetchBenchmarkProfiles = () => async dispatch => {
  const data = await api.benchmarkProfiles.getAll()

  dispatch(actions.receiveBenchmarkProfiles(data))
}

export const createBenchmarkProfile = data => async dispatch => {
  dispatch(actions.createRequest())
  const benchmark = await api.benchmarkProfiles.create(data)

  dispatch(actions.createSuccess(benchmark))

  return benchmark
}

export const updateBenchmarkProfile = (id, data) => async dispatch => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dispatch(actions.updateRequest())
  const benchmark = await api.benchmarkProfiles.update(id, data)

  dispatch(actions.updateSuccess(benchmark))

  return benchmark
}
