import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Menu,
  MenuList,
  Stack,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, CustomSelectButton } from '@repo/ui'
import { Form, Formik } from 'formik'
import { uniqBy } from 'lodash-es'
import { useState } from 'react'
import * as yup from 'yup'

import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { type CoachingRecordingSession } from '../types/coaching-recording'

const validationSchema: yup.SchemaOf<{ selectedCohorts }> = yup.object().shape({
  selectedCohorts: yup.array().min(1, 'At least one cohort needs to be selected'),
})

type SessionCohortFilterProps = {
  sessions: Array<CoachingRecordingSession>
  selectedSessions: Array<string>
  cohorts: Array<{ name: string; extId: string }>
  cohortsFilter: Array<string>
  setCohortsFilter: (cohorts: Array<string>) => void
}

export const SessionCohortFilter = ({
  sessions,
  selectedSessions,
  cohorts,
  cohortsFilter,
  setCohortsFilter,
}: SessionCohortFilterProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { formatMessage } = useIntl()
  const [showInterceptModal, setShowInterceptModal] = useState(false)
  const [cohortsToOverwrite, setCohortsToOverwrite] = useState<Array<string>>([])

  const handleSubmit = (formData: { selectedCohorts: Array<string> }) => {
    // if a selected session is excluded by filters, we want to show an intercept and confirm
    const cohortsInSelection = sessions
      .filter(session => selectedSessions.includes(session.extId))
      .map(session => session.cohort)

    const uniqCohortsInSelection = uniqBy(cohortsInSelection, 'extId')
    const filteredOutCohorts = uniqCohortsInSelection.filter(
      cohort => !formData.selectedCohorts.includes(cohort.extId)
    )

    setCohortsToOverwrite(filteredOutCohorts.map(cohort => cohort.name))
    if (filteredOutCohorts.length) {
      setShowInterceptModal(true)
    } else {
      setCohortsFilter(formData.selectedCohorts)
      setIsOpen(false)
    }
  }

  return (
    <Menu closeOnSelect={false} isOpen={isOpen} onClose={() => setIsOpen(false)} isLazy>
      <CustomSelectButton
        bg={
          cohortsFilter.length !== cohorts.length ? colors['secondary-dark'] : undefined
        }
        onClick={() => setIsOpen(true)}
        maxWidth="full"
      >
        <FormattedMessage id="coaching.benchmarking.recordings.filters.cohorts.title" />
      </CustomSelectButton>
      <MenuList>
        <Box p={4} overflow="scroll">
          <Formik
            onSubmit={values => handleSubmit(values)}
            initialValues={{ selectedCohorts: cohortsFilter }}
            validationSchema={validationSchema}
          >
            {({ values, submitForm, dirty, isValid, setFieldValue, isSubmitting }) => (
              <Form>
                <FormControl isInvalid={!isValid}>
                  <FormLabel>
                    <FormattedMessage id="coaching.benchmarking.recordings.filters.cohorts.label" />
                  </FormLabel>
                  <Stack maxH="500px" overflow="auto" p={2}>
                    {cohorts.map(cohort => (
                      <Checkbox
                        key={cohort.extId}
                        isChecked={values.selectedCohorts.includes(cohort.extId)}
                        onChange={() => {
                          if (values.selectedCohorts.includes(cohort.extId)) {
                            setFieldValue(
                              'selectedCohorts',
                              values.selectedCohorts.filter(c => c !== cohort.extId)
                            )
                          } else {
                            setFieldValue('selectedCohorts', [
                              ...values.selectedCohorts,
                              cohort.extId,
                            ])
                          }
                        }}
                      >
                        {cohort.name}
                      </Checkbox>
                    ))}
                  </Stack>
                </FormControl>

                <Box display="flex" flexWrap="wrap" justifyContent="center">
                  <Divider m={3} />
                  <Button
                    onClick={submitForm}
                    isDisabled={!(dirty && isValid)}
                    isLoading={isSubmitting}
                  >
                    <FormattedMessage id="coaching.benchmarking.recordings.filters.cohorts.apply" />
                  </Button>
                  <InterceptAndConfirm
                    isOpen={showInterceptModal}
                    onConfirm={() => {
                      setCohortsFilter(values.selectedCohorts)
                      setShowInterceptModal(false)
                      setIsOpen(false)
                    }}
                    onCancel={() => setShowInterceptModal(false)}
                    description={formatMessage(
                      {
                        id: 'coaching.benchmarking.recordings.filters.cohorts.intercept.description',
                      },
                      { cohorts: cohortsToOverwrite.toString() }
                    )}
                    title={formatMessage({
                      id: 'coaching.benchmarking.recordings.filters.cohorts.intercept.title',
                    })}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </MenuList>
    </Menu>
  )
}
