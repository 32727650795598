import { useMemo } from 'react'

import {
  makeColumnSelectDictionaryData,
  useColumnSelectDataInner,
  useColumnSelectRootData,
} from './column-control-data'
import { makeFilterSelectDictionaryData, useRadioSelectData } from './filter-control-data'

export const FILTER_NUMERICAL_VALUES = { 'top-10': 10, 'top-20': 20, 'top-30': 30 }

export const useFilterSelectData = () => {
  const selectData = useRadioSelectData()

  return useMemo(() => {
    const selectDictionary = makeFilterSelectDictionaryData(selectData)

    return [selectData, selectDictionary] as const
  }, [selectData])
}

export const useColumnSelectData = () => {
  const selectData = useColumnSelectDataInner()
  const selectRootData = useColumnSelectRootData()

  return useMemo(() => {
    const selectDictionary = makeColumnSelectDictionaryData(selectData)
    const selectDictionaryRoot = makeColumnSelectDictionaryData(selectRootData)

    const fields = selectData
      .flatMap(g => g.subGroups)
      .flatMap(s => s.items)
      .map(item => item.value)

    return {
      selectData,
      selectDictionary,
      selectRootData,
      fields,
      selectDictionaryRoot,
    } as const
  }, [selectData, selectRootData])
}
