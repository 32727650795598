import { Flex } from '@chakra-ui/react'
import { PageWrapper, SectionHeader } from '@repo/ui'

import { AppHeaderTitle } from '../components/app-header-title'
import { ManualSteps } from './scenario-manual-builder-page/manual-steps'
import { ManualBuilderContextProvider } from './scenario-manual-builder-page/shared/manual-builder-context'

export const ScenarioManualBuilderPage = () => (
  <PageWrapper
    backLabelKey="scenario.goBack"
    backTo=".."
    sx={{
      overflowY: 'auto',
      overflowX: 'hidden',
      pb: 0,
    }}
  >
    <AppHeaderTitle formattedMessageId="coaching.programList.title" />
    <ManualBuilderContextProvider>
      <Flex mx="auto" direction="column" maxW="1152px" w="full" flex={1}>
        <SectionHeader
          titleKey="scenario.manual.builder.title"
          subtitleKey="scenario.manual.builder.subtitle"
          sx={{ mb: 10 }}
        />
        <ManualSteps />
      </Flex>
    </ManualBuilderContextProvider>
  </PageWrapper>
)
