import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  Text,
  Textarea,
  type TextareaProps,
} from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'
import { useField } from 'formik'
import { type ForwardedRef, forwardRef } from 'react'

type TextareaControlProps = TextareaProps & {
  name: string
  label?: string
  subLabel?: string
  disabled?: boolean
}

export const TextareaControl = forwardRef(
  (props: TextareaControlProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
    const { name, label, subLabel, ...rest } = props
    const [field, { error, touched }, { setValue }] = useField(name)
    const { formatMessage } = useIntl()

    const handleChange = event => {
      if (event.target.value) {
        setValue(event.target.value)
      } else {
        setValue(undefined)
      }
    }

    return (
      <FormControl isInvalid={(!!error && touched) ?? false} flexGrow={1}>
        {label && <FormLabel>{label}</FormLabel>}
        {subLabel && (
          <Text fontSize="sm" color={colors.gray[500]}>
            {subLabel}
          </Text>
        )}
        <InputGroup>
          <Textarea
            type="text"
            w="full"
            minWidth="10rem"
            bg={colors.gray[50]}
            {...rest}
            {...field}
            onChange={handleChange}
            name={name}
            ref={ref}
          />
        </InputGroup>
        <FormErrorMessage>
          {error && formatMessage({ id: error as I18nKey })}
        </FormErrorMessage>
      </FormControl>
    )
  }
)
