import { forwardRef, type HTMLChakraProps, Th } from '@chakra-ui/react'

type ThStickyProps = HTMLChakraProps<'th'>

/**
 * Th that sticks at the top.
 *
 * IMPORTANT: add position:relative to the table to make it working
 */
export const ThSticky = forwardRef<ThStickyProps, 'th'>(
  ({ borderBottom, borderColor, ...props }, ref) => (
    <Th
      position="sticky"
      top={0}
      bgColor="white"
      zIndex={1}
      // Using :after to create a border that sticks to the `th`
      sx={{
        ':after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: '100%',
          borderBottom,
          borderColor,
          mb: '-1px',
        },
      }}
      {...props}
      ref={ref}
    />
  )
)
