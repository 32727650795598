import { useMemo } from 'react'

import { useStepsNew } from '../scenario-manual-builder-page/steps-context'
import { DraggableStepSections } from './drag-drop-step-editor/draggable-step-sections'
import {
  getSectionStep,
  toolboxItems,
} from './drag-drop-step-editor/draggable-step-sections/constants'
import { type Sections } from './drag-drop-step-editor/draggable-step-sections/types'
import { DraggableStepsContextProvider } from './draggable-steps-context'

export const DragDropStepEditor = ({
  isReadonly,
  isEditEnabled = false,
  isRealtimeBuilder = false,
  missionGoalForRealtimeBuilder = null,
}: {
  isReadonly: boolean
  isEditEnabled?: boolean
  isRealtimeBuilder?: boolean
  /**
   * this is a temporary workaround to pass the mission goal to the realtime step
   * because stepType===coachingStepRealtime is being used for both old and new realtime builders
   * so, we can remove this workaround and implement this properly when we remove the old realtime builder
   */
  missionGoalForRealtimeBuilder?: string | null
}) => {
  const { steps } = useStepsNew()

  const stepsEditorSection = useMemo(
    () =>
      steps
        .filter(step => step.data.id !== undefined)
        .map(step => getSectionStep(step, missionGoalForRealtimeBuilder)),
    [steps, missionGoalForRealtimeBuilder]
  )

  // Filter toolbox items for realtime conversation page
  const filteredToolboxItems = isRealtimeBuilder
    ? toolboxItems.filter(item => item.type === 'video' || item.type === 'text')
    : toolboxItems

  const initialSectionItems: Sections = {
    toolbox: filteredToolboxItems,
    stepsEditor: stepsEditorSection,
  }

  return (
    <DraggableStepsContextProvider isReadonly={isReadonly} isEditEnabled={isEditEnabled}>
      <DraggableStepSections
        initialItems={initialSectionItems}
        ignoreDropsAtLastItem={isRealtimeBuilder}
      />
    </DraggableStepsContextProvider>
  )
}
