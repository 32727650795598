import { Button, useDisclosure } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { useCallback, useState } from 'react'

import { ConnectExerciseModal } from '../../../builder/exercises/connect-exercise-modal'
import { type ExerciseWithConnectedPrograms } from '../../../types/api-types'
import { client } from '../../../utils/openapi-client'
import { useOpenapiSWR } from '../../../utils/use-openapi-swr'

export const ConnectExerciseButton = ({
  exercise,
}: {
  exercise: ExerciseWithConnectedPrograms
}) => {
  const { mutate } = useOpenapiSWR('getExercises')
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleConnectPrograms = useCallback(
    async (programExtIds: Array<string>) => {
      try {
        setIsSubmitting(true)
        const { error } = await client.put('updateConnectedProgramsOfExercise', {
          params: { path: { extId: exercise.extId } },
          body: { programExtIds },
        })

        if (error) {
          throw new Error()
        }

        await mutate()
        onClose()
        showToast({ messageKey: 'common.alert.saved', status: 'success' })
      } catch (error) {
        showToast({ status: 'error', messageKey: 'common.error.unexpected' })
      } finally {
        setIsSubmitting(false)
      }
    },
    [exercise, mutate, onClose]
  )

  return (
    <>
      <ConnectExerciseModal
        exercises={[exercise]}
        onSubmit={handleConnectPrograms}
        isOpen={isOpen}
        onClose={onClose}
        isSubmitting={isSubmitting}
      />

      <Button onClick={onOpen}>
        <FormattedMessage id="exercise.form.button.connect" />
      </Button>
    </>
  )
}
