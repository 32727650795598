import { SimpleGrid, Stack } from '@chakra-ui/react'

import { CenteredSpinner } from '../../../components/centered-spinner'
import { useDashboardContext } from '../../shared/dashboard-context'
import { type QuestionWithRatios } from '../../shared/types'
import { ResultsPerQuestionCard } from './knowledge-check-card-details/results-per-question-card'
import { ScorePerCohortCard } from './knowledge-check-card-details/score-per-cohort-card'
import {
  StrengthsAndWeaknessesCard,
  type StrengthsAndWeaknessesCardProps,
} from './knowledge-check-card-details/strengths-and-weaknesses-card'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { ScoreCircle } from './shared/score-circle'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

const getBestAndWorstQuestions = (
  questions: Array<QuestionWithRatios>
): { best: Array<QuestionWithRatios>; worst: Array<QuestionWithRatios> } => {
  if (questions.length >= 6) {
    return {
      best: questions.slice(0, 3),
      worst: questions.slice(-3),
    }
  }

  if (questions.length === 1) {
    return {
      best: questions,
      worst: [],
    }
  }

  const seperatorIndex = Math.floor(questions.length / 2)

  if (questions.length % 2 === 0) {
    return {
      best: questions.slice(0, seperatorIndex),
      worst: questions.slice(-seperatorIndex),
    }
  }

  return {
    best: questions.slice(0, seperatorIndex + 1),
    worst: questions.slice(-seperatorIndex),
  }
}

export const KnowledgeCheckCardDetails = () => {
  const { dataSlot } = useDashboardCardData('knowledgeCheck')

  const { filters } = useDashboardContext()

  if (dataSlot.status !== 'loaded') {
    return <CenteredSpinner />
  }

  const { data } = dataSlot

  const cohorts: StrengthsAndWeaknessesCardProps['cohorts'] = filters.selectedCohorts.map(
    (cohort, cohortIndex) => {
      const { best, worst } = getBestAndWorstQuestions(
        data.knowledgeCheckQuestionStats[cohortIndex] ?? []
      )

      return {
        id: cohort.extId,
        name: cohort.name,
        best,
        worst,
      }
    }
  )

  return (
    <DetailPageWrapper
      cardId="knowledgeCheck"
      sx={{ overflowY: 'auto' }}
      titleStartContent={
        data.knowledgeCheckScoreOverall !== null && (
          <ScoreCircle value={data.knowledgeCheckScoreOverall} />
        )
      }
    >
      <Stack px={4} pt={8} w="full">
        <SimpleGrid templateColumns="1fr 1fr" gap={5}>
          <ScorePerCohortCard
            data={{
              averageScoreData: data.knowledgeCheckScore,
              averageScore: data.knowledgeCheckScoreOverall,
            }}
          />

          <ResultsPerQuestionCard scenarioTree={data.scenarioTree} />

          <StrengthsAndWeaknessesCard
            overall={getBestAndWorstQuestions(data.userStatsDataKnowledgeCheck)}
            cohorts={cohorts}
          />
        </SimpleGrid>
      </Stack>
    </DetailPageWrapper>
  )
}
