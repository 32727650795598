import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Menu,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'
import { type ProgramLanguages } from '@repo/api'
import { FormattedMessage, languageLabels, useIntl } from '@repo/i18n'
import { colors, CustomSelectButton } from '@repo/ui'
import { Form, Formik } from 'formik'
import { uniq } from 'lodash-es'
import { useEffect, useState } from 'react'

import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { type CoachingRecordingSession } from '../types/coaching-recording'

export type LanguageFilterValue = ProgramLanguages | null

type Props = {
  sessions: Array<CoachingRecordingSession>
  selectedSessions: Array<string>
  resetSessionSelection: () => void
  languageFilter: LanguageFilterValue
  setLanguageFilter: (language: LanguageFilterValue) => void
}

export const SessionLanguageFilter = ({
  sessions,
  selectedSessions,
  resetSessionSelection,
  languageFilter,
  setLanguageFilter,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { formatMessage } = useIntl()
  const [showInterceptModal, setShowInterceptModal] = useState(false)

  const [hasSetLanguageFilterAutomatically, setHasSetLanguageFilterAutomatically] =
    useState(false)

  useEffect(() => {
    // If no language filter set and a user selects a session, we want to automatically set the
    // language filters, because it's a restriction, that only session from one language can be selected.
    if (!languageFilter && selectedSessions.length) {
      const language = sessions?.find(session => session.extId === selectedSessions[0])
        ?.program.language as ProgramLanguages

      setLanguageFilter(language)
      setHasSetLanguageFilterAutomatically(true)
    }

    // If the language filter was set automatically in this regards, we want to unset the filter when
    // user deselects all sessions.
    if (languageFilter && !selectedSessions.length && hasSetLanguageFilterAutomatically) {
      setLanguageFilter(null)
      setHasSetLanguageFilterAutomatically(false)
    }
    // This use effect should only run when selectedSessions changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSessions])

  const availableLanguages = sessions
    .reduce(
      (acc: Array<ProgramLanguages>, session) => uniq([...acc, session.program.language]),
      []
    )
    .sort()

  const handleSubmit = (filterValue: LanguageFilterValue) => {
    if (!selectedSessions.length) {
      setLanguageFilter(filterValue)
      setHasSetLanguageFilterAutomatically(false)
      setIsOpen(false)

      return
    }

    setShowInterceptModal(true)
  }

  return (
    <Menu closeOnSelect={false} isOpen={isOpen} onClose={() => setIsOpen(false)} isLazy>
      <CustomSelectButton
        bg={languageFilter ? colors['secondary-dark'] : undefined}
        onClick={() => setIsOpen(true)}
        maxWidth="full"
      >
        {languageFilter ? (
          languageLabels[languageFilter]
        ) : (
          <FormattedMessage id="coaching.benchmarking.recordings.filters.language.title" />
        )}
      </CustomSelectButton>
      <MenuList>
        <Box p={4} overflow="scroll">
          <Formik
            onSubmit={values => handleSubmit(values.language)}
            initialValues={{ language: languageFilter }}
          >
            {({ values, submitForm, dirty, isValid, setFieldValue, isSubmitting }) => (
              <Form>
                <FormControl isInvalid={!isValid}>
                  <FormLabel>
                    <FormattedMessage id="coaching.benchmarking.recordings.filters.language.label" />
                  </FormLabel>
                  <RadioGroup
                    onChange={value =>
                      setFieldValue('language', value === 'showAll' ? null : value)
                    }
                    value={values.language || 'showAll'}
                  >
                    <Stack>
                      <Radio value="showAll">
                        <FormattedMessage id="coaching.benchmarking.recordings.filters.language.showAll" />
                      </Radio>
                      {availableLanguages.map(language => (
                        <Radio key={language} value={language}>
                          {languageLabels[language]}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </FormControl>

                <Box display="flex" flexWrap="wrap" justifyContent="center">
                  <Divider m={3} />
                  <Button
                    onClick={submitForm}
                    isDisabled={!(dirty && isValid)}
                    isLoading={isSubmitting}
                  >
                    <FormattedMessage id="coaching.benchmarking.recordings.filters.languages.apply" />
                  </Button>
                  <InterceptAndConfirm
                    isOpen={showInterceptModal}
                    onConfirm={() => {
                      setLanguageFilter(values.language)
                      setHasSetLanguageFilterAutomatically(false)
                      setIsOpen(false)
                      setShowInterceptModal(false)
                      resetSessionSelection()
                    }}
                    onCancel={() => setShowInterceptModal(false)}
                    description={formatMessage({
                      id: 'coaching.benchmarking.recordings.filters.language.intercept.description',
                    })}
                    title={formatMessage({
                      id: 'coaching.benchmarking.recordings.filters.language.intercept.title',
                    })}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </MenuList>
    </Menu>
  )
}
