import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { type ProgramLanguages } from '@repo/api'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { WizardProgressBar } from '../../../components/wizard-progress-bar'
import { useBenchmarkProfiles } from '../../hooks/use-benchmark-profiles'
import { type SecondWizardStep } from '../../types/wizard'
import { CreatedScreen } from './created-screen'
import { LinkScenariosScreen } from './link-scenario-screen'
import { NameScreen } from './name-screen'

const orderedSecondWizardSteps = [
  'name',
  'created',
  'linkScenarios',
] as const satisfies Array<SecondWizardStep>

type SecondWizardModalProps = {
  onClose: VoidFunction
  sessionExtIds: Array<string>
  language: ProgramLanguages
}

export const SecondWizardModal = ({
  onClose,
  sessionExtIds,
  language,
}: SecondWizardModalProps) => {
  if (sessionExtIds.length < 5) {
    throw new Error('Not enough sessionExtIds provided')
  }

  const navigate = useNavigate()
  const [currentStep, setCurrentStep] = useState<SecondWizardStep>(
    orderedSecondWizardSteps[0]
  )

  const [benchmarkProfileName, setBenchmarkProfileName] = useState<string | null>(null)
  const { create } = useBenchmarkProfiles()

  const [isCreatingBenchmarkProfile, setIsCreatingBenchmarkProfile] = useState(false)
  const handleCreateBenchmarkProfileAndClose = async (
    scenariosToLink: Array<string> = []
  ) => {
    setIsCreatingBenchmarkProfile(true)
    if (!benchmarkProfileName) {
      throw Error('Benchmark profile name not set')
    }

    const benchmarkProfile = await create({
      title: benchmarkProfileName,
      sessionExtIds,
      scenariosToLink,
    })

    if (!benchmarkProfile) {
      throw Error('Benchmark profile not created')
    }

    setIsCreatingBenchmarkProfile(false)
    navigate('/coaching/benchmarking/profiles')
  }

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <Box sx={{ '.chakra-modal__content-container': { overflow: 'hidden' } }}>
        <ModalOverlay />
        <ModalContent minW="800px" h="700px">
          <ModalHeader>
            <WizardProgressBar
              steps={orderedSecondWizardSteps}
              currentStep={currentStep}
            />
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody pb={4}>
            {currentStep === 'name' && (
              <NameScreen
                handleCancel={onClose}
                handleContinue={() => setCurrentStep('created')}
                setBenchmarkProfileName={setBenchmarkProfileName}
              />
            )}
            {currentStep === 'created' && (
              <CreatedScreen
                handleCancel={handleCreateBenchmarkProfileAndClose}
                handleContinue={() => setCurrentStep('linkScenarios')}
                isCreatingBenchmarkProfile={isCreatingBenchmarkProfile}
              />
            )}
            {currentStep === 'linkScenarios' && (
              <LinkScenariosScreen
                handleClose={handleCreateBenchmarkProfileAndClose}
                isCreatingBenchmarkProfile={isCreatingBenchmarkProfile}
                language={language}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Box>
    </Modal>
  )
}
