import { Box } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { useMemo } from 'react'

import { CustomLineChart } from '../shared/custom-line-chart'
import { DashboardCard } from '../shared/dashboard-card'
import { ScoreCircle } from '../shared/score-circle'
import { useDashboardCardData } from '../shared/use-dashboard-card-data'
import { NoDataInformation } from './shared/no-data-information'

export const MessagingCard = () => {
  const { dataSlot, dataSlotSelector } = useDashboardCardData('messaging')

  const messagingData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    return data.startDatesFormatted.map((date, index) => ({
      date,
      messaging: data.messagingScoreOverallPerTimestamp[index]!,
    }))
  }, [dataSlot])

  const isEmptyData =
    dataSlot.status === 'loaded' && dataSlot.data.messagingScoreOverall === null

  return (
    <DashboardCard
      titleKey="dashboard.cards.messaging.title"
      descriptionKey="dashboard.cards.messaging.subtitle"
      colSpan={3}
      wrapperProps={{ pt: 5 }}
      titleEndContent={
        dataSlot.status === 'loaded' &&
        dataSlot.data.messagingScoreOverall !== null && (
          <ScoreCircle value={dataSlot.data.messagingScoreOverall} />
        )
      }
      dataSlotSelector={dataSlotSelector}
      isLoading={dataSlot.status !== 'loaded'}
    >
      {messagingData.length && !isEmptyData ? (
        <Box height={300}>
          <CustomLineChart
            data={messagingData}
            yAxisTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            yAxisLabelKey="dashboard.cards.behavioralAdaptation.yaxis.label"
            dataConfig={[{ dataKey: 'messaging', color: colors.blue[400] }]}
            valueSuffix="%"
          />
        </Box>
      ) : (
        <NoDataInformation />
      )}
    </DashboardCard>
  )
}
