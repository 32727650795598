import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Collapse,
  Flex,
  Heading,
  List,
  ListItem as BaseListItem,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { HelpTooltip, Tooltip } from '@repo/ui'
import { mean } from 'lodash-es'
import { type ReactNode } from 'react'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'

import { getScoreRating, type ScoreRatingEnum } from '../../hooks/use-result'
import { Dot } from './dot'

type ScoreKeys =
  | 'agreeableness'
  | 'extraversion'
  | 'openness'
  | 'conscientiousness'
  | 'neuroticism'
  | 'tfDealing'
  | 'tfSocialRelations'
  | 'tfAutonomy'
  | 'tfIdentity'
  | 'tfVariety'
  | 'cfCompetitive'
  | 'cfInnovative'
  | 'cfDetailOriented'
  | 'cfOutcomeOriented'
  | 'cfTeamOriented'
  | 'cfSupportive'
  | 'cfStability'

export type ScoresObj = Record<ScoreKeys, number>

export const Legend = () => {
  const { formatMessage } = useIntl()
  const legends = [
    {
      color: '#F06E6E',
      label: formatMessage({
        id: 'recording.details.overview.requirements.legend.low',
      }),
    },
    {
      color: '#FFB673',
      label: formatMessage({
        id: 'recording.details.overview.requirements.legend.medium',
      }),
    },
    {
      color: '#78E296',
      label: formatMessage({
        id: 'recording.details.overview.requirements.legend.good',
      }),
    },
    {
      color: '#00C8B0',
      label: formatMessage({
        id: 'recording.details.overview.requirements.legend.great',
      }),
    },
  ]

  return (
    <Stack alignSelf="flex-end" direction="row" h={12} align="center" spacing={3}>
      {legends.map(legend => (
        <Stack direction="row" key={legend.label} align="center" spacing={1}>
          <Dot backgroundColor={legend.color} />
          <Text as="span" fontSize="sm" fontWeight="medium">
            {legend.label}
          </Text>
        </Stack>
      ))}
    </Stack>
  )
}

type CustomProgressProps = {
  score: number
  color: string
}

const CustomProgress = ({ score, color }: CustomProgressProps) => (
  <Box pos="relative" h="10px">
    <Box pos="relative" w="100%" bg="#EEEEEE" h="2px" />
    <Tooltip
      label={
        <FormattedMessage
          id={`recording.details.overview.graphs.hover.${getScoreRating(score)}`}
        />
      }
    >
      <Box
        width={`${score * 100}%`}
        pos="absolute"
        bg={color}
        h="10px"
        top="-4px"
        borderRadius="6px"
      />
    </Tooltip>
  </Box>
)

type ListItemProps = {
  label: keyof ScoresObj
  score: number
  color: string
}

const ListItem = ({ color, label, score }: ListItemProps) => (
  <BaseListItem>
    <Stack spacing={2}>
      <Flex align="center" pb={2}>
        <Text fontWeight="medium" fontSize="sm">
          <FormattedMessage id={`meta.benchmarkProfile.field.${label}.title.result`} />
        </Text>
        <HelpTooltip
          label={<FormattedMessage id={`recording.details.overview.${label}.hover`} />}
        />
      </Flex>
      <Box>
        <CustomProgress score={score} color={color} />
      </Box>
    </Stack>
  </BaseListItem>
)

const getColor = (level: ScoreRatingEnum) => {
  switch (level) {
    case 'low':
      return '#F06E6E'
    case 'medium':
      return '#FFB673'
    case 'good':
      return '#78E296'
    case 'great':
      return '#00C8B0'
    default:
      return '#DBDBDB'
  }
}

type PersonVSRequirementsProps = {
  label: ReactNode
  scores: Partial<ScoresObj>
  showLegend?: boolean
}

export const PersonVSRequirements = ({
  label,
  scores,
  showLegend,
}: PersonVSRequirementsProps) => {
  const scoreEntries = [...Object.entries(scores)].sort((a, b) => b[1] - a[1]) as Array<
    [ScoreKeys, number]
  >

  const hasData = scoreEntries.length > 0

  // TODO: we shouldn't calculate scores in the frontend!
  // TODO: this calculation is wrong! it doesn't take weights into account
  const averageScore = mean(Object.values(scores))

  const { isOpen, onToggle } = useDisclosure()

  const scoreRating = getScoreRating(averageScore)
  const scoreColor = getColor(scoreRating)

  return (
    <Flex flexGrow={1} direction="column" align="center" maxW="350px" p={5} pb={10}>
      <Heading fontSize="lg" color="primary" fontWeight="medium">
        {label}
      </Heading>
      <CircularProgress
        thickness="6px"
        mt={5}
        value={averageScore * 100}
        capIsRound
        color={scoreColor}
        size={200}
      >
        <Tooltip
          label={
            <FormattedMessage
              id={
                hasData
                  ? `recording.details.overview.graphs.hover.${scoreRating}`
                  : 'recording.details.overview.graphs.hover.noScores'
              }
            />
          }
        >
          <CircularProgressLabel>
            <Text
              as="span"
              bg={scoreColor}
              py={2}
              px={4}
              borderRadius="30px"
              verticalAlign="middle"
              fontWeight="medium"
              fontSize="md"
            >
              {hasData ? (
                <FormattedMessage id={`recording.list.tags.${scoreRating}`} />
              ) : (
                <FormattedMessage id="recording.details.overview.graphs.noDimensions.circular" />
              )}
            </Text>
          </CircularProgressLabel>
        </Tooltip>
      </CircularProgress>
      <Stack alignSelf="flex-end" direction="row" h={12} align="center" spacing={3}>
        {showLegend && <Legend />}
      </Stack>
      <Stack
        alignSelf="stretch"
        border="2px"
        borderColor="gray.200"
        borderRadius="md"
        p={4}
        spacing={4}
      >
        {hasData ? (
          <>
            <List spacing={4}>
              {scoreEntries.slice(0, 3).map(([name, score]) => (
                <ListItem
                  key={name}
                  label={name}
                  score={score}
                  color={getColor(getScoreRating(score))}
                />
              ))}
            </List>
            <Collapse in={isOpen} animateOpacity>
              <List spacing={4}>
                {scoreEntries.slice(3).map(([name, score]) => (
                  <ListItem
                    key={name}
                    label={name}
                    score={score}
                    color={getColor(getScoreRating(score))}
                  />
                ))}
              </List>
            </Collapse>
            {scoreEntries.length > 3 && (
              <Button
                width="full"
                colorScheme="whiteAlpha"
                color="primary"
                onClick={onToggle}
                leftIcon={isOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                iconSpacing={1}
              >
                <Text as="span">
                  {isOpen ? (
                    <FormattedMessage id="recording.details.overview.less" />
                  ) : (
                    <FormattedMessage id="recording.details.overview.more" />
                  )}
                </Text>
              </Button>
            )}
          </>
        ) : (
          <Text>
            <FormattedMessage id="recording.details.overview.graphs.noDimensions.bars" />
          </Text>
        )}
      </Stack>
    </Flex>
  )
}
