import { Button } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { type FormState } from 'react-hook-form'

export const ContinueButton = ({
  showAsSkip,
  formState,
}: {
  showAsSkip?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formState: FormState<any>
}) => (
  <Button
    type="submit"
    alignSelf="flex-end"
    isLoading={formState.isSubmitting}
    variant={showAsSkip ? 'outline' : 'solid'}
    isDisabled={!formState.isValid}
    mt={-10}
    mb={-10}
  >
    <FormattedMessage id={showAsSkip ? 'common.skip' : 'common.saveAndContinue'} />
  </Button>
)
