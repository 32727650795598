import { Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type PropsWithChildren } from 'react'

export const SidebarSection = ({
  labelKey,
  children,
  onLabelClick,
}: PropsWithChildren<{ labelKey: I18nKey; onLabelClick?: () => void }>) => (
  <Stack gap={1}>
    <Text
      px={2}
      fontWeight={500}
      fontSize="12px"
      textTransform="uppercase"
      color={colors.lineChartColors[200]}
      onClick={onLabelClick}
    >
      <FormattedMessage id={labelKey} />
    </Text>

    {children}
  </Stack>
)
