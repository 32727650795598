import { Box, Button, Icon, Stack, Text, VStack } from '@chakra-ui/react'
import { type SxProps } from '@mui/material'
import { FormattedMessage } from '@repo/i18n'
import { colors, SectionHeader } from '@repo/ui'
import { createColumnHelper } from '@tanstack/react-table'
import { useCallback } from 'react'
import { HiPlus } from 'react-icons/hi'
import { MdThumbUpAlt } from 'react-icons/md'

import { AdvancedTable } from '../../../components/advanced-table'
import { useLocalStorage } from '../../../hooks/use-local-storage'
import { type BehavioralGoal } from '../../../types/api-types'
import { StickyFooter } from '../../shared/sticky-footer'
import { useNextStepModal } from '../../shared/use-next-step-modal'
import { useRealtimeBuilderContext } from '../shared/realtime-builder-context'
import { useRenderSkillName } from '../shared/use-render-skill-name'
import { MAX_GOALS_PER_STAGE } from './goal-step/constants'
import { OptionsMenu } from './goal-step/options-menu'
import { useCreateBehavioralGoalModal } from './goal-step/use-create-behavioral-goal-modal'
import {
  StageStepperProvider,
  useStageStepperContext,
} from './shared/stage-stepper-context'
import { StageTabs } from './shared/stage-tabs'
import { StatusBadge } from './shared/status-badge'

const tableSx: SxProps = { width: '100%' }
const columnHelper = createColumnHelper<BehavioralGoal>()

const SkillNameCell = ({ value }: { value: string }) => {
  const renderSkillName = useRenderSkillName()

  return renderSkillName(value)
}

const columns = [
  columnHelper.accessor('description', {
    header: () => (
      <FormattedMessage id="scenario.builder.realtime.steps.goal.table.behavioral" />
    ),
    enableSorting: false,
  }),
  columnHelper.display({
    id: 'behaviorType',
    header: () => <FormattedMessage id="common.type" />,
    cell: props =>
      props.row.original.behaviorType === 'positive' && (
        <StatusBadge
          icon={MdThumbUpAlt}
          intlKey="common.show"
          borderColor={colors.green[600]}
          bgColor={colors.green[100]}
        />
      ),
  }),
  columnHelper.accessor('skill.name', {
    header: () => <FormattedMessage id="common.skill" />,
    cell: props => <SkillNameCell value={props.getValue()} />,
    enableSorting: false,
  }),
  columnHelper.display({
    id: 'actions',
    cell: props => <OptionsMenu goal={props.row.original} />,
    meta: {
      align: 'right',
    },
  }),
]

const GoalStepInner = () => {
  const { goToStep, scenario, isBuilderReadonly } = useRealtimeBuilderContext()
  const { activeStageIndex, goToNextStage, goToPreviousStage } = useStageStepperContext()

  const [createGoalModal, showCreateGoalModal] = useCreateBehavioralGoalModal()

  const [_, setHasSeenBuilderIntro] = useLocalStorage('hasSeenBuilderIntro')

  const [nextStepModal, confirmProceedingViaModal] = useNextStepModal({
    titleKey: 'common.wellDone',
    descriptionKey: 'scenario.builder.ai.steps.goal.nextStepModal.description',
    continueLabelKey: 'common.saveAndProceed',
  })

  const goalCountOnActiveStage =
    scenario.missionStages?.[activeStageIndex]?.winningBehaviorsRealtime?.length ?? 0

  const isCurrentStepValid = goalCountOnActiveStage > 0

  const goNextStep = useCallback(async () => {
    setHasSeenBuilderIntro(true)

    if (activeStageIndex !== 2) {
      return goToNextStage()
    }

    const isConfirmed = await confirmProceedingViaModal()

    if (!isConfirmed) {
      return
    }

    goToStep('conversation-partner')
  }, [
    activeStageIndex,
    confirmProceedingViaModal,
    goToStep,
    goToNextStage,
    setHasSeenBuilderIntro,
  ])

  const renderPanels = () => (
    <VStack>
      <Box alignSelf="flex-end">
        {createGoalModal}
        <Button
          leftIcon={<Icon as={HiPlus} boxSize="14px" />}
          onClick={showCreateGoalModal}
          isDisabled={isBuilderReadonly || goalCountOnActiveStage >= MAX_GOALS_PER_STAGE}
        >
          <FormattedMessage id="scenario.builder.realtime.steps.goal.button" />
        </Button>
      </Box>

      <AdvancedTable
        disableSearch
        data={scenario.missionStages?.[activeStageIndex]?.winningBehaviorsRealtime ?? []}
        columns={columns}
        sx={tableSx}
        emptyStateLabelKey="scenario.builder.realtime.steps.goal.emptyTable"
      />
    </VStack>
  )

  const isTabDisabled = (index: number) =>
    index !== activeStageIndex &&
    (!scenario.missionStages?.[index]?.winningBehaviorsRealtime ||
      scenario.missionStages?.[index]?.winningBehaviorsRealtime?.length === 0)

  return (
    <Stack flex={1} gap={0}>
      {nextStepModal}
      <SectionHeader
        titleKey="scenario.builder.realtime.steps.goal.formTitle"
        subtitleKey="scenario.builder.ai.steps.goal.formSubtitle"
        sx={{ mb: 8 }}
      />
      <Text fontSize="16px" pb={1}>
        <FormattedMessage id="scenario.builder.realtime.steps.goal.stages.text" />
      </Text>

      <StageTabs
        renderPanels={renderPanels}
        activeStep={activeStageIndex}
        isTabDisabled={isTabDisabled}
      />

      <StickyFooter
        onBack={activeStageIndex > 0 ? goToPreviousStage : undefined}
        onNext={isCurrentStepValid ? goNextStep : undefined}
        isNextDisabled={!isCurrentStepValid}
        nextHintKey={
          !isCurrentStepValid && 'scenario.builder.realtime.steps.goal.disabledHint'
        }
      />
    </Stack>
  )
}

export const GoalStep = () => (
  <StageStepperProvider>
    <GoalStepInner />
  </StageStepperProvider>
)
