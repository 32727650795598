import { Box, Center, Circle, Divider, Flex, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { MdPeople } from 'react-icons/md'

import { useDraggableStepsContext } from '../../draggable-steps-context'
import { DraggableStepSection } from './draggable-step-section'
import { type Sections } from './types'

interface ConversationCustomizationProps {
  sections: Sections
}

export const ConversationCustomization = ({
  sections,
}: ConversationCustomizationProps) => {
  const { isReadonly } = useDraggableStepsContext()
  const toolboxItems = sections.toolbox
  const stepEditorItems = sections.stepsEditor

  return (
    <Flex gap={6}>
      <Box
        backgroundColor={colors['secondary-light']}
        minW="270px"
        w="270px"
        alignSelf="flex-start"
        borderRadius={8}
        p={5}
        pos="sticky"
        top={0}
        display={isReadonly ? 'none' : 'block'}
      >
        <Text fontSize="md" fontWeight="semibold">
          <FormattedMessage id="conversation.customization.toolbox.title" />
        </Text>
        <Text fontSize="xs" mt={1} mb={3}>
          <FormattedMessage id="conversation.customization.toolbox.subtitle" />
        </Text>
        <DraggableStepSection
          id="toolbox"
          items={toolboxItems}
          sx={{ gap: 3, display: 'flex', flexWrap: 'wrap' }}
        />
      </Box>
      <Box
        backgroundColor={colors.gray['50']}
        w="full"
        minH={`calc(30vh + 20px * ${stepEditorItems.length})`}
        borderRadius={8}
        p={5}
        alignSelf="normal"
      >
        <Center h="full" position="relative" flexDir="column">
          <Circle
            position="absolute"
            top={0}
            zIndex={1}
            size="40px"
            bg={colors.gray['600']}
            color="white"
          >
            <MdPeople />
          </Circle>
          <Text transform="translateX(50%)" ms={16} position="absolute" top={2}>
            <FormattedMessage id="conversation.customization.conversation.start" />
          </Text>
          <Divider
            backgroundColor={colors.gray['600']}
            orientation="vertical"
            position="absolute"
            w="1.5px"
          />
          <DraggableStepSection
            id="stepsEditor"
            items={stepEditorItems}
            sx={{ gap: 7, position: 'relative', textAlign: 'center', py: '70px' }}
            tagSx={{ backgroundColor: colors.gray['50'], py: 1 }}
          />
          <Circle
            size="40px"
            bg={colors.gray['600']}
            position="absolute"
            bottom={0}
            color="white"
          >
            <MdPeople />
          </Circle>
          <Text transform="translateX(50%)" ms={16} position="absolute" bottom={2}>
            <FormattedMessage id="conversation.customization.conversation.end" />
          </Text>
        </Center>
      </Box>
    </Flex>
  )
}
