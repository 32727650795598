import { Box, Flex } from '@chakra-ui/react'
import { LanguageSwitch } from '@repo/ui'
import { Outlet } from 'react-router-dom'

export const PublicLayout = () => (
  <Flex
    w="100vw"
    h="100vh"
    bgColor="secondary-light"
    overflow="auto"
    justifyContent="center"
  >
    <Box pos="absolute" top={5} right={5}>
      <LanguageSwitch color="primary" />
    </Box>
    <Outlet />
  </Flex>
)
