import { Box, type HTMLChakraProps } from '@chakra-ui/react'

type PageListOwnProps = {
  scrollX?: boolean
  scrollY?: boolean
}

type PageListProps = PageListOwnProps & HTMLChakraProps<'div'>

export const PageList = ({ scrollX, scrollY, ...props }: PageListProps) => (
  <Box
    padding="1.5em"
    width="full"
    overflowX={scrollX ? 'auto' : undefined}
    overflowY={scrollY ? 'auto' : undefined}
    {...props}
  />
)
