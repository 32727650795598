import { assign, createMachine, send, type TransitionConfigOrTarget } from 'xstate'

import { type VideoSource } from './types'
import { videoMetadata } from './video-meta-preloader'
import { type VideoPlayerHandle } from './video-player'

type VideoMeta = {
  duration: number
  previewImageUrl?: string
  url: string
}

type VideoManageContext = {
  currentVideoIndex: number
  videoPlayerHandle: VideoPlayerHandle | null
  time: number
  progress: number
  duration: number
  autoPlay?: boolean
  autoPlayNext?: boolean
  videos: Array<VideoMeta> | null
  videoSources: Array<VideoSource> | null
  isPlaying: boolean
}

type LoadEvent = { type: 'LOAD'; videoSources: Array<VideoSource> }
type SeekEvent = { type: 'SEEK'; progress: number }
type VideoEndEvent = { type: 'ON_VIDEO_END' }
type ReadyEvent = {
  type: 'READY'
  videoPlayerHandle: VideoPlayerHandle
}

type VideoManageEvent =
  | { type: 'LOADED'; videos: Array<VideoMeta> }
  | LoadEvent
  | ReadyEvent
  | { type: 'FAIL' }
  | { type: 'PLAY' }
  | { type: 'ON_PLAY_START' }
  | VideoEndEvent
  | { type: 'PAUSE' }
  | SeekEvent
  | { type: 'TIME'; time: number }
  | { type: 'RETRY' }
  | { type: 'END' }

const readyAction: TransitionConfigOrTarget<VideoManageContext, ReadyEvent> = [
  {
    target: '#ready.paused',
    actions: assign((_context, event) => {
      event.videoPlayerHandle.play()

      return {
        videoPlayerHandle: event.videoPlayerHandle,
      }
    }),
    cond: context => context.isPlaying,
  },
  {
    target: '#ready.paused',
    actions: assign({
      videoPlayerHandle: (_context, event) => event.videoPlayerHandle,
    }),
  },
]

const endAction: TransitionConfigOrTarget<VideoManageContext, VideoManageEvent> = {
  target: '#ended',
  actions: assign(context => {
    context.videoPlayerHandle?.pause()

    return {
      isPlaying: false,
    }
  }),
}

const onVideoEndAction: TransitionConfigOrTarget<VideoManageContext, VideoEndEvent> = [
  {
    target: '#loading.loadingVideo',
    actions: assign({
      currentVideoIndex: (context, _event) => context.currentVideoIndex + 1,
      time: (_context, _event) => 0,
    }),
    cond: (context, _event) =>
      (context.videos?.length && context.currentVideoIndex < context.videos.length - 1) ||
      false,
  },
  {
    target: '#ended',
  },
]

const seekAction: TransitionConfigOrTarget<VideoManageContext, SeekEvent> = [
  {
    // check if seek can be preformed in current video
    actions: assign((context, event) => {
      const relativeProgress =
        event.progress -
        (context?.videos || [])
          .slice(0, context.currentVideoIndex)
          .reduce((acc, el) => acc + el.duration, 0)

      context.videoPlayerHandle?.seek(relativeProgress)

      return {
        progress: event.progress,
        time: relativeProgress,
      }
    }),
    cond: (context, event) => {
      const currentVideo = context.videos?.[context.currentVideoIndex]

      if (context.videos && currentVideo) {
        const relativeProgress =
          event.progress -
          context.videos
            .slice(0, context.currentVideoIndex)
            .reduce((acc, el) => acc + el.duration, 0)

        if (relativeProgress >= 0 && relativeProgress < currentVideo.duration) {
          return true
        }
      }

      return false
    },
  },
  {
    // seek across videos
    target: '#loading.loadingVideo',
    actions: assign((context, event) => {
      type NewVideoType = {
        index: number
        time: number
      }

      const duration = Math.min(event.progress, context.duration)

      const newVideo: NewVideoType = (context?.videos || []).reduce(
        (acc, el, index) => {
          if (index < acc.index) {
            if (acc.time < el.duration) {
              return {
                time: acc.time,
                index,
              }
            }

            return {
              index: acc.index,
              time: acc.time - el.duration,
            }
          }

          return acc
        },
        {
          index: context?.videos?.length || 0,
          time: duration,
        } as NewVideoType
      )

      return {
        progress: duration,
        time: newVideo.time,
        currentVideoIndex: newVideo.index,
      }
    }),
  },
]

// TODO Typing doesn't work
// https://github.com/statelyai/xstate/issues/2232

export const videoManagerMachine = createMachine<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  VideoManageContext,
  VideoManageEvent
>({
  id: 'videoManagerMachine',
  initial: 'idle',
  context: {
    videoPlayerHandle: null,
    currentVideoIndex: 0,
    time: 0,
    progress: 0,
    duration: 0,
    videos: null,
    videoSources: null,
    isPlaying: false,
  },
  states: {
    idle: {
      on: {
        LOAD: {
          target: 'loading',
          actions: assign({
            videoSources: (_context, event) => event.videoSources,
          }),
        },
      },
    },
    loading: {
      id: 'loading',
      initial: 'loadingMetadata',
      states: {
        loadingMetadata: {
          invoke: {
            id: 'loadVideosMetadata',
            src: (context, _event) =>
              Promise.all(context.videoSources?.map(src => videoMetadata(src.url)) || []),
            onDone: {
              actions: send((_context, event) => ({
                type: 'LOADED',
                videos: event.data,
              })),
            },

            onError: {
              actions: send({ type: 'FAIL' }),
            },
          },
          on: {
            LOADED: [
              {
                target: 'loadingVideo',
                actions: assign({
                  videos: (_context, event) => event.videos,
                  duration: (_context, event) =>
                    event.videos.reduce((acc, el) => acc + el.duration, 0) || 0,
                }),
                cond: (_context, event) => !!event.videos.length,
              },
              {
                actions: send({ type: 'FAIL' }), // empty array, invalid urls
              },
            ],
          },
        },
        loadingVideo: {
          exit: (context, _event) => {
            if (context.videoPlayerHandle && context.time) {
              context.videoPlayerHandle?.seek(context.time)
            }
          },
          on: {
            READY: readyAction,
          },
        },
      },
      on: {
        FAIL: {
          target: 'error',
        },
        END: {
          target: 'idle',
        },
      },
    },
    ready: {
      id: 'ready',
      initial: 'paused',
      states: {
        paused: {
          on: {
            PLAY: {
              actions: context => context.videoPlayerHandle?.play(),
            },
            ON_PLAY_START: {
              target: 'playing',
              actions: assign({ isPlaying: (_context, _event) => true }),
            },
            END: endAction,
          },
        },
        playing: {
          on: {
            TIME: {
              actions: assign((context, event) => ({
                progress:
                  (context.videos
                    ?.slice(0, context.currentVideoIndex)
                    .reduce((acc, el) => acc + el.duration, 0) || 0) + event.time,
                time: event.time,
              })),
            },
            ON_VIDEO_END: onVideoEndAction,
            END: endAction,

            PAUSE: {
              target: 'paused',
              actions: assign(context => {
                context.videoPlayerHandle?.pause()

                return {
                  isPlaying: false,
                }
              }),
            },
          },
        },
        ended: {
          id: 'ended',
          entry: [
            assign({
              isPlaying: (_context, _event) => false,
            }),
            send({ type: 'SEEK', progress: 0 }),
          ],

          on: {
            PLAY: {
              target: 'paused',
              actions: context => context.videoPlayerHandle?.play(),
            },
            SEEK: {
              actions: assign((_context, _event) => ({
                progress: 0,
                time: 0,
                currentVideoIndex: 0,
              })),
            },
            LOAD: {
              target: '#loading.loadingMetadata',
              actions: assign((_context, event) => ({
                videoSources: event.videoSources,
                videos: [],
                duration: 0,
              })),
            },
          },
        },
      },
      on: {
        SEEK: seekAction,
      },
    },

    error: {
      entry: [assign({ isPlaying: (_context, _event) => false })],
      on: {
        RETRY: {
          target: 'loading',
        },
        END: {
          target: 'idle',
        },
      },
    },
  },
})
