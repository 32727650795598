import { type DashboardLayout, type DashboardSectionLayout } from './types'

export const MAX_NUMBER_OF_COHORTS_FOR_SELECTION = 50

export const SESSION_STORAGE_SCROLL_KEY = 'dashboard-last-scroll-top'

export const DEFAULT_DASHBOARD_LAYOUT: DashboardLayout = [
  {
    id: 'overallOverview',
    cards: [
      { id: 'behavioralAdaptation', visible: true },
      { id: 'recordingCount', visible: true },
      { id: 'traineeCount', visible: true },
    ],
  },
  {
    id: 'behavioralAdaptionIndicators',
    cards: [
      { id: 'learningDevelopmentFunnel', visible: true },
      { id: 'scenarioRepetitions', visible: true },
      { id: 'popularScenarios', visible: true },
      { id: 'competencyTime', visible: true },
    ],
  },
  {
    id: 'behavioralSuccessPattern',
    cards: [
      { id: 'communicationAndLanguage', visible: true },
      { id: 'knowledgeCheck', visible: true },
      { id: 'messaging', visible: true },
      { id: 'cohortPerformance', visible: true },
    ],
  },
] satisfies [
  DashboardSectionLayout<'overallOverview'>,
  DashboardSectionLayout<'behavioralAdaptionIndicators'>,
  DashboardSectionLayout<'behavioralSuccessPattern'>,
]
