import { useIntl } from '@repo/i18n'
import { useContext, useEffect } from 'react'

import { AppHeaderTitleContext } from './app-header'

export const AppHeaderTitle = ({
  formattedMessageId,
}: {
  formattedMessageId: I18nKey
}) => {
  const title = useIntl().formatMessage({ id: formattedMessageId })
  const { setTitle } = useContext(AppHeaderTitleContext)

  useEffect(() => {
    setTitle(title)
  }, [title, setTitle])

  return null
}
