import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Stack,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { Form, useFormikContext } from 'formik'
import { concat, difference } from 'lodash-es'
import { useEffect, useState } from 'react'

import { TreeToggle } from '../../components/animated-tree/tree-toggle'
import { type ProgramTrainingScenariosTree } from '../types/program-training-scenario-tree'

export type SelectScenariosFormValues = {
  selectedScenarioExtIds: Array<string>
}

type SelectScenariosFormProps = {
  programTrainingScenarioTree: ProgramTrainingScenariosTree
  readOnlyScenarioExtIds?: Array<string>
  showActionButtons?: boolean
  showAllCollapsed?: boolean
  showLabel?: boolean
}

export const SelectScenariosForm = ({
  programTrainingScenarioTree,
  readOnlyScenarioExtIds = [],
  showActionButtons = true,
  showLabel = true,
  showAllCollapsed = false,
}: SelectScenariosFormProps) => {
  const { values, setFieldValue, isSubmitting, dirty, isValid } =
    useFormikContext<SelectScenariosFormValues>()

  const [collapsedItems, setCollapsedItems] = useState<Array<string>>([])
  const { selectedScenarioExtIds } = values
  const setValues = (extIds: Array<string>) =>
    setFieldValue('selectedScenarioExtIds', extIds)

  useEffect(() => {
    if (showAllCollapsed) {
      const allCollapsedItems: Array<string> = []

      programTrainingScenarioTree.forEach(program => {
        allCollapsedItems.push(program.extId)
        program.trainings.forEach(training => {
          allCollapsedItems.push(training.extId)
          training.scenarios.forEach(scenario => allCollapsedItems.push(scenario.extId))
        })
      })

      setCollapsedItems(allCollapsedItems)
    }
  }, [showAllCollapsed, programTrainingScenarioTree])

  const isCollapsed = (extId: string): boolean => collapsedItems.includes(extId)
  const toggleCollapsedItem = (extId: string) =>
    setCollapsedItems(
      isCollapsed(extId)
        ? difference(collapsedItems, [extId])
        : concat(collapsedItems, [extId])
    )

  const areTrainingScenariosChecked = (scenarios): boolean =>
    scenarios.every(scenario => selectedScenarioExtIds.includes(scenario.extId))

  const areAllTrainingScenariosReadOnly = (scenarios): boolean =>
    scenarios.every(scenario => readOnlyScenarioExtIds.includes(scenario.extId))

  const areTrainingScenariosPartiallyChecked = (scenarios): boolean =>
    scenarios.some(scenario => selectedScenarioExtIds.includes(scenario.extId)) &&
    !areTrainingScenariosChecked(scenarios)

  const areProgramScenariosChecked = trainings =>
    trainings.every(training => areTrainingScenariosChecked(training.scenarios))

  const areAllProgramScenariosReadOnly = trainings =>
    trainings.every(training => areAllTrainingScenariosReadOnly(training.scenarios))

  const areProgramScenariosPartiallyChecked = (trainings): boolean =>
    trainings.some(
      training =>
        areTrainingScenariosChecked(training.scenarios) ||
        areTrainingScenariosPartiallyChecked(training.scenarios)
    ) && trainings.some(training => !areTrainingScenariosChecked(training.scenarios))

  const handleOnChangeScenarioSelection = (scenarioExtId: string) => {
    if (selectedScenarioExtIds.includes(scenarioExtId)) {
      setValues(difference(selectedScenarioExtIds, [scenarioExtId]))
    } else {
      setValues(concat(selectedScenarioExtIds, [scenarioExtId]))
    }
  }

  const handleOnChangeTrainingSelection = scenarios => {
    const scenarioIds = scenarios
      .map(({ extId }) => extId)
      // Don't allow to check/uncheck read only scenarios
      .filter(extId => !readOnlyScenarioExtIds.includes(extId))

    if (areTrainingScenariosChecked(scenarios)) {
      setValues(difference(selectedScenarioExtIds, scenarioIds))
    } else {
      setValues(concat(selectedScenarioExtIds, scenarioIds))
    }
  }

  const handleOnChangeProgramSelection = trainings => {
    const scenarioIds = trainings
      .flatMap(training => training.scenarios.map(({ extId }) => extId))
      // Don't allow to check/uncheck read only scenarios
      .filter(extId => !readOnlyScenarioExtIds.includes(extId))

    if (areProgramScenariosChecked(trainings)) {
      setValues(difference(selectedScenarioExtIds, scenarioIds))
    } else {
      setValues(concat(selectedScenarioExtIds, scenarioIds))
    }
  }

  return (
    <Form>
      <FormControl isInvalid={!isValid}>
        {showLabel && (
          <>
            <FormLabel>
              <FormattedMessage id="benchmarkProfile.linkScenario.form.label" />
            </FormLabel>
            <FormHelperText>
              <FormattedMessage id="benchmarkProfile.linkScenario.modal.subtitle" />
            </FormHelperText>
          </>
        )}

        <Stack maxH="500px" overflowX="hidden" overflowY="auto" p={2} mt={4}>
          {programTrainingScenarioTree.map(trainingProgram => (
            <Box key={trainingProgram.extId}>
              <Flex>
                <TreeToggle
                  onClick={() => toggleCollapsedItem(trainingProgram.extId)}
                  isOpen={!isCollapsed(trainingProgram.extId)}
                  isIconVisible
                />
                <Checkbox
                  isChecked={areProgramScenariosChecked(trainingProgram.trainings)}
                  isIndeterminate={areProgramScenariosPartiallyChecked(
                    trainingProgram.trainings
                  )}
                  isDisabled={areAllProgramScenariosReadOnly(trainingProgram.trainings)}
                  onChange={() =>
                    handleOnChangeProgramSelection(trainingProgram.trainings)
                  }
                >
                  {trainingProgram.title}
                </Checkbox>
              </Flex>
              <Collapse in={!isCollapsed(trainingProgram.extId)} animateOpacity>
                <Stack pl={6} mt={1} spacing={1}>
                  {trainingProgram.trainings.map(training => (
                    <Box key={training.extId}>
                      <Flex>
                        <TreeToggle
                          onClick={() => toggleCollapsedItem(training.extId)}
                          isOpen={!isCollapsed(training.extId)}
                          isIconVisible
                        />
                        <Checkbox
                          onChange={() =>
                            handleOnChangeTrainingSelection(training.scenarios)
                          }
                          isChecked={areTrainingScenariosChecked(training.scenarios)}
                          isDisabled={areAllTrainingScenariosReadOnly(training.scenarios)}
                          isIndeterminate={areTrainingScenariosPartiallyChecked(
                            training.scenarios
                          )}
                        >
                          {training.title}
                        </Checkbox>
                      </Flex>
                      <Collapse in={!isCollapsed(training.extId)} animateOpacity>
                        <Stack pl={12} mt={1} spacing={1}>
                          {training.scenarios.map(scenario => (
                            <Checkbox
                              key={scenario.extId}
                              isChecked={selectedScenarioExtIds.includes(scenario.extId)}
                              isDisabled={readOnlyScenarioExtIds.includes(scenario.extId)}
                              onChange={() =>
                                handleOnChangeScenarioSelection(scenario.extId)
                              }
                            >
                              {scenario.title}
                            </Checkbox>
                          ))}
                        </Stack>
                      </Collapse>
                    </Box>
                  ))}
                </Stack>
              </Collapse>
            </Box>
          ))}
        </Stack>
        <FormErrorMessage>
          <FormattedMessage id="benchmarkProfile.linkScenario.form.error" />
        </FormErrorMessage>
      </FormControl>

      {showActionButtons && (
        <HStack mt={12} spacing={4} justify="center">
          <Button variant="outline" type="reset">
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
            isDisabled={
              !(
                // Read only scenarios are not included in selectedScenarioExtIds, so user needs
                // to have selected at least one scenario that is not read only
                (
                  dirty &&
                  difference(selectedScenarioExtIds, readOnlyScenarioExtIds).length
                )
              )
            }
          >
            <FormattedMessage id="benchmarkProfile.linkScenario.form.submit" />
          </Button>
        </HStack>
      )}
    </Form>
  )
}
