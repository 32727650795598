import { Box, Icon, IconButton, Td } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { ClickableTr, Tooltip } from '@repo/ui'
import { lowerCase } from 'lodash-es'
import { memo, type MouseEvent, useCallback } from 'react'
import { DragPreviewImage } from 'react-dnd'
import { RiDeleteBinLine } from 'react-icons/ri'

import recordingImage from '../../assets/recording.png'
import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { useResult } from '../../hooks/use-result'
import {
  type DroppableRecording,
  useDraggable,
} from '../../recruiting/folder-navigation/folder-and-recording-dnd'
import { type ColumnSortableItemValue } from '../../recruiting/recording-filters/column-control-data'
import { useColumnSelectData } from '../../recruiting/recording-filters/recording-filters-hooks'
import { RelevanceTag } from '../../recruiting/recording-result/relevance-tag'
import { ValueTag } from '../../recruiting/recording-result/value-tag'
import {
  type FieldName,
  type RecordingSession,
} from '../../recruiting/recording-sessions-api'
import { getRecordingFieldDisplayText } from './get-recording-field-display-text'
import { ListViewRowPreview } from './list-view-row-preview'

const META_INFORMATION_FIELDS = ['name', 'id', 'email', 'createdAt']

type TableCellProps = {
  field: FieldName
  session: RecordingSession
}

const TableCell = memo(({ field, session }: TableCellProps) => {
  const { overallScore, getResultFieldValue, rawValues } = useResult(session)
  const { selectDictionary } = useColumnSelectData()
  const dimension = lowerCase(getRecordingFieldDisplayText(field, selectDictionary))

  if (field === 'overallScore') {
    return (
      <Td>
        <Tooltip label={<FormattedMessage id="recording.list.relevanceTags.hoverText" />}>
          <Box w="fit-content">
            <RelevanceTag overallScore={overallScore} />
          </Box>
        </Tooltip>
      </Td>
    )
  }

  if (META_INFORMATION_FIELDS.includes(field)) {
    return <Td>{getResultFieldValue(field)}</Td>
  }

  return (
    <Td>
      <Tooltip
        label={
          <FormattedMessage
            id="recording.list.valueTags.hoverText"
            values={{ dimension }}
          />
        }
      >
        <Box w="fit-content">
          <ValueTag rawValue={rawValues[field]} />
        </Box>
      </Tooltip>
    </Td>
  )
})

type ListViewRowProps = {
  session: RecordingSession
  columns: Array<ColumnSortableItemValue>
  showDeleteButtons: boolean
  onRowClick: () => void
  onDeleteClick: () => Promise<void>
  updateRecordings?: () => void
}

export const ListViewRow = memo(
  ({
    session,
    columns,
    showDeleteButtons,
    onRowClick,
    onDeleteClick,
    updateRecordings,
  }: ListViewRowProps) => {
    const [{ isDragging }, ref, preview] = useDraggable<DroppableRecording>({
      item: { session, type: 'recording', onAfterMove: updateRecordings },
    })

    const handleOnDeleteClick = useCallback(
      (e: MouseEvent, openDeleteModel: () => void) => {
        // Needed because the whole row is clickable,
        // and we should prevent the click to pass through the button
        e.stopPropagation()
        openDeleteModel()
      },
      []
    )

    const handleOnClick = useCallback(() => {
      onRowClick()
    }, [onRowClick])

    return (
      <>
        <DragPreviewImage connect={preview} src={recordingImage} />
        <ClickableTr
          ref={ref}
          key={session.id}
          onClick={handleOnClick}
          opacity={isDragging ? 0.25 : 1}
        >
          <Td>
            <ListViewRowPreview session={session} />
          </Td>
          {columns.map(({ value }) => (
            <TableCell key={value} field={value} session={session} />
          ))}
          {showDeleteButtons && (
            <Td>
              <InterceptAndConfirm
                onConfirm={onDeleteClick}
                title={<FormattedMessage id="confirmation.deleteRecording.heading" />}
                description={<FormattedMessage id="confirmation.deleteRecording.text" />}
              >
                {({ openModal }) => (
                  <IconButton
                    color="indicator.red-dark"
                    onClick={e => handleOnDeleteClick(e, openModal)}
                    aria-label="Delete recording"
                    variant="ghost"
                    icon={<Icon boxSize="1.25em" as={RiDeleteBinLine} />}
                  />
                )}
              </InterceptAndConfirm>
            </Td>
          )}
        </ClickableTr>
      </>
    )
  }
)
