import { colors } from '@repo/ui'
import { type ContentType } from 'recharts/types/component/Label'

export const BarDotIndicators: ContentType = props => {
  const { x, y, width } = props

  const xCenter = Number(x) + Number(width) / 2

  return <circle cx={xCenter} cy={y} r={2.5} fill={colors.lineChartColors[300]} />
}
