import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  type SystemStyleObject,
} from '@chakra-ui/react'
import { FormattedMessage, type ReactNodeOrI18nKey } from '@repo/i18n'
import { type ReactNode, useCallback, useState } from 'react'

export type ModalConfig = {
  bodySx?: SystemStyleObject
  headerSx?: SystemStyleObject
  contentSx?: SystemStyleObject
  modalProps?: Partial<Omit<ModalProps, 'isOpen' | 'onClose'>>
  onClose?: () => void
  onOpen?: () => void
} & ReactNodeOrI18nKey<'title'>

export type ModalContentRenderer = (props: { closeModal: () => void }) => ReactNode

export const useModal = (Renderer: ModalContentRenderer, config?: ModalConfig) => {
  const { bodySx, title, titleKey, modalProps, onClose, onOpen, headerSx, contentSx } =
    config ?? {}

  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback(() => {
    onOpen?.()
    setIsOpen(true)
  }, [onOpen])

  const closeModal = useCallback(() => {
    onClose?.()
    setIsOpen(false)
  }, [onClose])

  const modalJsx = (
    <Modal size="lg" onClose={closeModal} isOpen={isOpen} isCentered {...modalProps}>
      <ModalOverlay />
      <ModalContent borderRadius={8} overflow="hidden" sx={contentSx}>
        <ModalHeader sx={headerSx}>
          {titleKey ? <FormattedMessage id={titleKey} /> : title}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody p={6} sx={bodySx}>
          <Renderer closeModal={closeModal} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )

  return [modalJsx, openModal, closeModal, isOpen] as const
}
