import { Stack } from '@chakra-ui/react'
import { SectionHeader } from '@repo/ui'

import { ContentEditorManagerProvider } from '../../../../../builder/components/content-editor/content-editor-manager-provider'
import { StepsProviderNew } from '../../../../scenario-manual-builder-page/steps-context'
import { DragDropStepEditor } from '../../../../shared/drag-drop-step-editor'
import { StickyFooter } from '../../../../shared/sticky-footer'
import { useAIBuilderContext } from '../../../shared/ai-builder-context'
import { useInteractionStepContext } from '../interaction-step-context'

export const StepConversationCustomization = () => {
  const { setCurrentStep } = useInteractionStepContext()
  const { programExtId, trainingExtId, scenarioExtId, isBuilderReadonly } =
    useAIBuilderContext()

  return (
    <ContentEditorManagerProvider>
      <StepsProviderNew
        programId={programExtId}
        trainingId={trainingExtId}
        scenarioId={scenarioExtId}
      >
        <Stack flex={1}>
          <SectionHeader
            titleKey="scenario.builder.ai.steps.conversationCustomization.formTitle"
            sx={{ p: { fontSize: '18px' }, mb: 10 }}
          />

          <DragDropStepEditor isReadonly={isBuilderReadonly} />

          <StickyFooter
            onBack={() => setCurrentStep('conversation-generation')}
            onNext={() => {
              setCurrentStep('review')
            }}
          />
        </Stack>
      </StepsProviderNew>
    </ContentEditorManagerProvider>
  )
}
