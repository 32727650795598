import { Center, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { HiPlusCircle } from 'react-icons/hi'

import { BaseCard } from './shared/base-card'

type GridCreateCardProps = {
  labelKey: I18nKey
  onClick: () => void
}

export const GridCreateCard = ({ labelKey, onClick }: GridCreateCardProps) => (
  <BaseCard
    onClick={onClick}
    borderColor={colors.blue[100]}
    cursor="pointer"
    minHeight="300px"
  >
    <Center flex={1} flexDir="column" gap={3}>
      <HiPlusCircle size={32} color={colors.blue[500]} />
      <Text>
        <FormattedMessage id={labelKey} />
      </Text>
    </Center>
  </BaseCard>
)
