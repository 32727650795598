import { FormattedMessage } from '@repo/i18n'
import PropTypes from 'prop-types'

import { Field } from '../../components/formik-form/field'
import { ROOT_FOLDER_NAME } from '../../utils/constants'

export const FolderSelectItem = ({ folderId, name, checked, onChange }) => {
  // Opposed to custom created folders, the root folder gets its name from
  // react-intl (FormattedMessage) in the currently selected user language
  const folderName =
    name === ROOT_FOLDER_NAME ? <FormattedMessage id="labels.folders.root.name" /> : name

  return (
    <Field
      type="checkbox"
      name="folders"
      value={folderId}
      label={folderName}
      checked={checked}
      onChange={onChange}
    />
  )
}

FolderSelectItem.propTypes = {
  folderId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
