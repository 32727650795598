import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, VideoPlayer } from '@repo/ui'

import { type QuestionAndAnswerStep } from '../../../builder/steps/api'
import { useGoogleStorageAsset } from '../../../hooks/use-google-storage-asset'

export const CustomQuestionAnswerStepReview = ({
  step,
}: {
  step: QuestionAndAnswerStep
}) => {
  const questionUrlHttpLink = useGoogleStorageAsset(step.questionUrl)
  const answerUrlHttpLink = useGoogleStorageAsset(step.answerUrl)

  return (
    <VStack gap="14px" alignItems="flex-start" w="full">
      <Box
        w="fit-content"
        minH={8}
        borderRadius="full"
        border="1px solid"
        color="white"
        bg={colors.gray[700]}
        px="16px"
        py="4px"
        fontSize="14px"
        fontWeight={500}
        textAlign="center"
      >
        <FormattedMessage id={`scenario.builder.ai.steps.review.step.${step.stepType}`} />
      </Box>
      {answerUrlHttpLink && questionUrlHttpLink && (
        <HStack gap={4} w="full" alignItems="flex-start">
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize="16px" fontWeight={500}>
              <FormattedMessage id="scenario.builder.ai.steps.review.step.coachingStepQA.question.video" />
            </Text>
            <VideoPlayer
              url={questionUrlHttpLink ?? ''}
              nativeControls
              aspectRatio="16/9"
              videoHeight="300px"
            />
          </VStack>
          <VStack alignItems="flex-start" gap={2}>
            <Text fontSize="16px" fontWeight={500}>
              <FormattedMessage id="scenario.builder.ai.steps.review.step.coachingStepQA.listening.video" />
            </Text>
            <VideoPlayer
              url={answerUrlHttpLink}
              nativeControls
              aspectRatio="16/9"
              videoHeight="300px"
            />
          </VStack>
        </HStack>
      )}
    </VStack>
  )
}
