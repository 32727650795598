import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { ActionItem, SessionCard, SessionCardText, SessionCardTitle } from '@repo/ui'
import { useMemo } from 'react'
import { DragPreviewImage } from 'react-dnd'
import { RiDeleteBinLine } from 'react-icons/ri'

import recordingImage from '../../assets/recording.png'
import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { useResult } from '../../hooks/use-result'
import {
  type DroppableRecording,
  useDraggable,
} from '../../recruiting/folder-navigation/folder-and-recording-dnd'
import {
  type ColumnSelectDictionary,
  type ColumnSortableItemValue,
} from '../../recruiting/recording-filters/column-control-data'
import { RelevanceTag } from '../../recruiting/recording-result/relevance-tag'
import { type RecordingSession } from '../../recruiting/recording-sessions-api'
import { getPreviewUrl } from '../../utils/recordings'
import { getRecordingFieldDisplayText } from './get-recording-field-display-text'

type RecruitingSessionCardProps = {
  session: RecordingSession
  columnsDictionary: ColumnSelectDictionary
  onClick: () => void
  onDeleteClick: () => Promise<void>
  updateRecordings: () => void
  fields: Array<ColumnSortableItemValue>
  shouldDisplayName: boolean
}

export const RecruitingSessionCard = ({
  session,
  shouldDisplayName,
  fields,
  columnsDictionary,
  onClick,
  onDeleteClick,
  updateRecordings,
}: RecruitingSessionCardProps) => {
  const { getResultFieldValue, overallScore } = useResult(session)

  const { formatMessage } = useIntl()
  const imageUrl = getPreviewUrl(session)
  const recordingMetaName = session.meta?.name
  const showOverallScore = fields.some(field => field.value === 'overallScore')
  const title = useMemo(
    () =>
      shouldDisplayName
        ? recordingMetaName || formatMessage({ id: 'recording.list.card.withoutName' })
        : formatMessage({ id: 'recording.list.card.hiddenName' }),
    [shouldDisplayName, recordingMetaName, formatMessage]
  )

  const renderedFields = useMemo(
    () =>
      fields
        .filter(field => field.value !== 'overallScore')
        .map(
          ({ value: field }) =>
            `${getRecordingFieldDisplayText(
              field,
              columnsDictionary
            )}: ${getResultFieldValue(field)}`
        ),
    [fields, columnsDictionary, getResultFieldValue]
  )

  const [{ isDragging }, ref, preview] = useDraggable<DroppableRecording>({
    item: { session, type: 'recording', onAfterMove: updateRecordings },
  })

  return (
    <SessionCard onClick={onClick} imageUrl={imageUrl}>
      <DragPreviewImage connect={preview} src={recordingImage} />
      {/* Drag and Drop Container */}
      <Box ref={ref} opacity={isDragging ? 0.25 : 1} minW={60} maxW={80}>
        <ActionItem isShownOnHover>
          <InterceptAndConfirm
            onConfirm={onDeleteClick}
            title={<FormattedMessage id="confirmation.deleteRecording.heading" />}
            description={<FormattedMessage id="confirmation.deleteRecording.text" />}
          >
            {({ openModal }) => (
              <IconButton
                className="delete-button"
                size="xs"
                aria-label="Remove"
                color="indicator.red"
                _hover={{ color: 'indicator.red-dark' }}
                bg="indicator.red-extra-light"
                icon={<RiDeleteBinLine size="1.25em" />}
                onClick={openModal}
              />
            )}
          </InterceptAndConfirm>
        </ActionItem>
        <SessionCardTitle title={title} />
        <SessionCardText fields={renderedFields} />
        {showOverallScore && (
          <Flex pt={2}>
            <Text
              display="inline"
              mr={2}
              fontSize="sm"
              color="gray.500"
              sx={{ overflowWrap: 'break-word', whiteSpace: 'break-word' }}
            >
              {getRecordingFieldDisplayText('overallScore', columnsDictionary)}:
            </Text>
            <RelevanceTag overallScore={overallScore} />
          </Flex>
        )}
      </Box>
    </SessionCard>
  )
}
