import { Button, HStack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { FormattedMessage } from '@repo/i18n'
import { ClickableTr } from '@repo/ui'
import { useCallback, useEffect, useState } from 'react'
import { RiAddFill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'

import { fetchRoles } from '../../store/entities/roles/effects'
import { type Role } from '../../store/entities/roles/roles-types'
import { selectRoles } from '../../store/entities/roles/selectors'
import { formatDateTime } from '../../utils/dates'
import { RoleEditorModal } from './role-editor-modal'

const DivS = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;
`

const RoleList = () => {
  const dispatch = useDispatch()

  const roles = useSelector<unknown, ReadonlyArray<Role>>(selectRoles)

  const [roleEditorModalId, setRoleEditorModalId] = useState('')

  const handleCreateBtnClick = useCallback(() => {
    setRoleEditorModalId('new')
  }, [])

  useEffect(() => {
    dispatch(fetchRoles())
  }, [dispatch])

  return (
    <>
      {!!roleEditorModalId && (
        <RoleEditorModal
          mode={
            roleEditorModalId === 'new'
              ? { type: 'create' }
              : { type: 'edit', id: roleEditorModalId }
          }
          onClose={() => {
            setRoleEditorModalId('')
          }}
        />
      )}
      <HStack justify="flex-end">
        <Button
          leftIcon={<RiAddFill size="1.25em" />}
          onClick={handleCreateBtnClick}
          iconSpacing="1"
        >
          <FormattedMessage id="common.create" />
        </Button>
      </HStack>
      <DivS>
        <Table>
          <Thead>
            <Tr>
              <Th>
                <FormattedMessage id="role.list.table.name" />
              </Th>
              <Th>
                <FormattedMessage id="role.list.table.updated" />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {roles.map(({ id, name, updatedAt }) => (
              <ClickableTr
                key={id}
                onClick={() => {
                  setRoleEditorModalId(id)
                }}
              >
                <Td>{name}</Td>
                <Td>{formatDateTime(updatedAt)}</Td>
              </ClickableTr>
            ))}
          </Tbody>
        </Table>
      </DivS>
    </>
  )
}

export { RoleList }
