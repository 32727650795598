import {
  Step,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
} from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { type PropsWithChildren } from 'react'

type InstructionFormStepProps = {
  stepKey: number
  showSeparator?: boolean
}

export const InstructionFormStep = ({
  stepKey,
  showSeparator = false,
  children,
}: PropsWithChildren<InstructionFormStepProps>) => (
  <Step key={stepKey} style={{ width: '100%', paddingBottom: '40px' }}>
    <StepIndicator bg={colors.blue[500]} borderColor={colors.blue[500]}>
      <StepStatus incomplete={<StepNumber style={{ color: 'white' }} />} />
    </StepIndicator>
    {children}
    {showSeparator && (
      <StepSeparator
        style={{
          background: colors.blue[500],
        }}
      />
    )}
  </Step>
)
