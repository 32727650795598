import { Box, Text } from '@chakra-ui/react'

import { type QuestionWithRatios } from '../../../../../../shared/types'
import { AnswerAccuracyGraph } from '../../../shared/answer-accuracy-graph'

interface AnswerAccuracyGraphCardProps {
  data: QuestionWithRatios
}

export const AnswerAccuracyGraphCard = ({ data }: AnswerAccuracyGraphCardProps) => (
  <Box
    p={4}
    borderRadius="lg"
    bg="white"
    filter="drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))"
  >
    <Text fontSize="sm" fontWeight="medium" mb={4}>
      {data.questionText}
    </Text>
    <AnswerAccuracyGraph data={data} />
  </Box>
)
