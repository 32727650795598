import { Center, useDisclosure } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast, Spinner } from '@repo/ui'
import moment from 'moment'
import { type FC, useMemo, useState } from 'react'

import { api } from '../../../../utils/api'
import { useDashboardContext } from '../../../shared/dashboard-context'
import {
  type Question,
  type ResultsPerQuestionResponse,
  type ScenarioTree,
} from '../../../shared/types'
import { DashboardCard } from '../shared/dashboard-card'
import { ClickToSelectQuestion } from './results-per-question-card/click-to-select-question'
import { QuestionSelectionModal } from './results-per-question-card/question-selection-modal'
import { ResultView } from './results-per-question-card/result-view'

type ResultsPerQuestionCardProps = {
  scenarioTree: Array<ScenarioTree>
}

export const ResultsPerQuestionCard: FC<ResultsPerQuestionCardProps> = ({
  scenarioTree,
}) => {
  const { filters } = useDashboardContext()
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [selectedQuestions, setSelectedQuestions] = useState<
    Array<Question> | undefined
  >()

  const [resultsPerQuestion, setResultsPerQuestion] =
    useState<ResultsPerQuestionResponse>()

  const selectQuestionModal = useDisclosure()

  const handleQuestionsSelect = async (questions: Array<Question>) => {
    try {
      selectQuestionModal.onClose()
      setIsLoadingData(true)
      const selectedCohortExtIds = filters.selectedCohorts.map(cohort => cohort.extId)

      const query = {
        startDate: filters.selectedDates.startDate.toISOString(),
        endDate: moment(filters.selectedDates.endDate).add(1, 'day').toISOString(),
        'quizExtIds[]': questions.map(question => question.extId),
      }

      if (selectedCohortExtIds.length) {
        query['cohorts[]'] = selectedCohortExtIds
      }

      const dashboardData: ResultsPerQuestionResponse = await api.dashboard.get(query)

      setResultsPerQuestion(dashboardData)
      setSelectedQuestions(questions)
    } catch (error) {
      showToast({
        messageKey: 'dashboard.resultPerQuestion.fetch.error',
        status: 'error',
      })
    } finally {
      setIsLoadingData(false)
    }
  }

  const displayedContent = useMemo(() => {
    if (isLoadingData) {
      return (
        <Center flex="1">
          <Spinner />
        </Center>
      )
    }

    if (!selectedQuestions) {
      if (scenarioTree.length === 0) {
        return (
          <Center flex="1">
            <FormattedMessage id="dashboard.resultPerQuestion.noData" />
          </Center>
        )
      }

      return <ClickToSelectQuestion onClick={selectQuestionModal.onOpen} />
    }

    if (!resultsPerQuestion?.userStatsDataKnowledgeCheckSummary) {
      throw new Error(
        'this state should be handled somehow, the component below requires this data'
      )
    }

    return (
      <ResultView
        openSelectQuestionModal={selectQuestionModal.onOpen}
        questions={selectedQuestions}
        userStatsDataKnowledgeCheckSummary={
          resultsPerQuestion.userStatsDataKnowledgeCheckSummary
        }
      />
    )
  }, [
    scenarioTree.length,
    selectedQuestions,
    isLoadingData,
    selectQuestionModal,
    resultsPerQuestion,
  ])

  return (
    <DashboardCard
      colSpan={1}
      titleKey="dashboard.resultPerQuestion.title"
      descriptionKey="dashboard.resultPerQuestion.description"
      wrapperProps={{ pt: 5 }}
    >
      <QuestionSelectionModal
        scenarioTree={scenarioTree}
        isOpen={selectQuestionModal.isOpen}
        onClose={selectQuestionModal.onClose}
        onQuestionsSelect={handleQuestionsSelect}
      />

      {displayedContent}
    </DashboardCard>
  )
}
