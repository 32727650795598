import { Box, Button, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

interface KeyValueWithButtonProps {
  labelKey: I18nKey
  value: string | number
}

export const KeyValueWithButton = ({ labelKey, value }: KeyValueWithButtonProps) => (
  <VStack alignItems="flex-start">
    <Text fontSize="14px" fontWeight={500}>
      <FormattedMessage id={labelKey} />
    </Text>
    <Box as="label">
      <Button as={Box}> {value}</Button>
    </Box>
  </VStack>
)
