import {
  Box,
  Icon,
  IconButton,
  type SystemStyleObject,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { useToggle } from '@repo/utils'
import { forwardRef, type PropsWithChildren, type ReactNode } from 'react'
import { RiAddFill } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'

import { AnimatedNode } from '../../components/animated-tree/animated-node'
import { TreeToggle } from '../../components/animated-tree/tree-toggle'
import { Interpolated } from '../../components/interpolated'
import { useCurrentTokenData } from '../../hooks/use-current-token-data'
import { type Folder } from '../../store/entities/folders/folders-types'
import { type FolderTree } from '../../store/ui/sidebar/sidebar-types'
import { NewFolderModal } from '../folder-editor/new-folder-modal'
import {
  canDropItem,
  type DroppableObject,
  type DropStatus,
  useDraggable,
  useDroppable,
} from './folder-and-recording-dnd'

const getDropStyle = (status: DropStatus): SystemStyleObject => {
  switch (status) {
    case 'accept-drop':
      return { borderColor: 'primary', borderStyle: 'dashed' }
    case 'will-drop':
      return { bg: 'secondary-dark' }
    case 'wont-drop':
      return { bg: 'red.200' }

    default:
      return {}
  }
}

type ElementProps = {
  isSelected: boolean
  depth?: number
  status: DropStatus
  children: ReactNode
}
const Element = forwardRef<HTMLDivElement, ElementProps>(
  ({ isSelected, depth = 0, status, children }, ref) => (
    <Box
      width="full"
      display="flex"
      alignItems="center"
      h={8}
      px={1}
      mb={1}
      pl={`${depth * 0.5}rem`}
      bg={isSelected ? 'secondary' : 'white'}
      borderTopLeftRadius="md"
      borderBottomLeftRadius="md"
      border="1px"
      borderColor="transparent"
      _hover={{ bgColor: isSelected ? 'secondary' : 'secondary-light' }}
      sx={getDropStyle(status)}
      ref={ref}
    >
      {children}
    </Box>
  )
)

type FolderNavLinkProps = {
  id: string
  name: string
}
const FolderNavLink = ({ id, name }: FolderNavLinkProps) => (
  <Box
    as={NavLink}
    height="full"
    lineHeight="1.9rem"
    color="primary-dark"
    _hover={{ color: 'primary-dark' }}
    width="full"
    role="menuitem"
    whiteSpace="nowrap"
    overflow="hidden"
    textOverflow="ellipsis"
    tabIndex={0}
    to={`/folders/${id}`}
    fontSize="sm"
  >
    <Interpolated text={name} />
  </Box>
)

const FolderAdd = ({ onClick }: { onClick: () => void }) => {
  const { formatMessage } = useIntl()

  return (
    <Tooltip
      label={formatMessage({
        id: 'positions.form.button.create',
      })}
    >
      <IconButton
        variant="ghost"
        size="xs"
        color="secondary-dark"
        sx={{ _hover: { color: 'primary' } }}
        aria-label="Add folder"
        icon={<Icon as={RiAddFill} w={4} h={4} />}
        onClick={onClick}
      />
    </Tooltip>
  )
}

type NodeProps = {
  isSelected?: boolean
  depth?: number
  folder: FolderTree
  onDrop: (item: DroppableObject, destinationFolder: Folder) => void | Promise<void>
}

export const FolderNode = ({
  isSelected = false,
  depth = 0,
  folder,
  onDrop,
  children,
}: PropsWithChildren<NodeProps>) => {
  const { id, name, open } = folder

  const [isExpanded, toggle] = useToggle(open)

  const [, dragRef] = useDraggable({
    canDrag: () => !!folder.parent,
    item: { folder, type: 'folder' },
  })

  const { isOwner } = useCurrentTokenData()

  const [{ dropStatus }, dropRef] = useDroppable({
    canDrop: canDropItem(isOwner, folder),
    drop: item => onDrop(item, folder),
  })

  const newFolderModal = useDisclosure()

  return (
    <>
      <NewFolderModal
        isOpen={newFolderModal.isOpen}
        onClose={newFolderModal.onClose}
        parent={id}
      />
      <div ref={dropRef}>
        <Element isSelected={isSelected} status={dropStatus} ref={dragRef} depth={depth}>
          <TreeToggle onClick={toggle} isOpen={isExpanded} isIconVisible={!!children} />
          <FolderNavLink id={id} name={name} />
          {isOwner && <FolderAdd onClick={newFolderModal.onOpen} />}
        </Element>
      </div>
      <AnimatedNode isOpen={isExpanded}>{children}</AnimatedNode>
    </>
  )
}
