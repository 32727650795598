import { Stack } from '@chakra-ui/react'

import { ContentEditorManagerProvider } from '../../../../../builder/components/content-editor/content-editor-manager-provider'
import { StepsProviderNew } from '../../../../scenario-manual-builder-page/steps-context'
import { DragDropStepEditor } from '../../../../shared/drag-drop-step-editor'
import { StickyFooter } from '../../../../shared/sticky-footer'
import { useRealtimeBuilderContext } from '../../../shared/realtime-builder-context'
import { useInteractionStepContext } from '../interaction-step-context'

export const StepConversationCustomization = () => {
  const { goToPreviousStep, goToNextStep } = useInteractionStepContext()
  const { programExtId, trainingExtId, scenarioExtId, isBuilderReadonly, scenario } =
    useRealtimeBuilderContext()

  return (
    <ContentEditorManagerProvider>
      <StepsProviderNew
        programId={programExtId}
        trainingId={trainingExtId}
        scenarioId={scenarioExtId}
      >
        <Stack flex={1}>
          <DragDropStepEditor
            isReadonly={isBuilderReadonly}
            isRealtimeBuilder
            missionGoalForRealtimeBuilder={scenario?.missionGoal}
          />

          <StickyFooter onBack={goToPreviousStep} onNext={goToNextStep} />
        </Stack>
      </StepsProviderNew>
    </ContentEditorManagerProvider>
  )
}
