import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { type ReactNode, useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'

export const ComponentSection = ({
  title,
  subtitle,
  children,
}: {
  title: string
  subtitle?: string
  children: ReactNode
}) => {
  const [visible, setVisible] = useState(true)

  return (
    <Box border="2px solid" borderColor={colors.gray[400]} mb={4}>
      <HStack
        cursor="pointer"
        _hover={{
          opacity: 0.8,
        }}
        borderBottom="2px solid"
        borderColor="inherit"
        bg={colors.blue[50]}
        p={2}
        onClick={() => setVisible(!visible)}
      >
        <Box
          as={FaChevronRight}
          transform={!visible ? 'rotate(0deg)' : 'rotate(90deg)'}
          transition="all 0.3s"
        />
        <Box>
          <Text fontSize={20} fontWeight={600}>
            {title}
          </Text>
          {subtitle ? <Text fontSize={14}>{subtitle}</Text> : undefined}
        </Box>
      </HStack>
      {visible && (
        <Box p={4}>
          <Stack gap={4}>{children}</Stack>
        </Box>
      )}
    </Box>
  )
}
