/* eslint-disable no-alert */
import { Button, IconButton } from '@chakra-ui/react'
import { PageWrapper } from '@repo/ui'
import { FaPlus } from 'react-icons/fa'

import { ComponentSection } from './shared/component-section'
import { ComponentVariant } from './shared/component-variant'

const alertClick = () => alert('clicked')

export const PageWrapperSection = () => (
  <ComponentSection
    title="PageWrapper"
    subtitle="This component should be used for wrapping all of the pages in the app"
  >
    <ComponentVariant title="simple usage">
      <PageWrapper
        title="Page Title"
        subtitle="This is the most simple usage, with only a title and subtitle"
      >
        Page content comes here
      </PageWrapper>
    </ComponentVariant>

    <ComponentVariant title="complex usage">
      <PageWrapper
        backTo="/"
        backLabelKey="common.back"
        pageActions={<Button onClick={alertClick}>Page Action</Button>}
        title="Page Title"
        subtitle="But there are more options available"
        titleStartContent={<IconButton isRound aria-label="" icon={<FaPlus />} />}
        titleEndContent={<IconButton isRound aria-label="" icon={<FaPlus />} />}
      >
        Page content comes here
      </PageWrapper>
    </ComponentVariant>
  </ComponentSection>
)
