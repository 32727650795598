/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from 'reselect'

const getLocalState = state => state.entities.questionnaires

export const makeSelectQuestionnaire = () =>
  createSelector(
    getLocalState,
    (_, id) => id,
    (questionnaires: any, questionnaireId) => {
      const questionnaire = questionnaires.find(({ id }) => id === questionnaireId)

      if (questionnaire) {
        const { questions } = questionnaire

        // When `note` has `null` as value, we get a warning that
        // "value" of textarea should not be null.
        return {
          ...questionnaire,
          questions: questions.map(({ note = '', ...question }) => ({
            ...question,
            note,
            // There is no "type" field in our database, our frontend
            // just "fakes" it. But when we fetch data, we have to make
            // sure we have it for our questionnaire editor.
            type: note.startsWith('http') ? 'video' : 'standard',
          })),
        }
      }

      return null
    }
  )

export const makeSelectQuestionnaires = () =>
  createSelector(
    getLocalState,
    (state, selector) => selector(state),
    (questionnaires: any, questionnaireIds: any) =>
      questionnaireIds.map(questionnaireId =>
        questionnaires.find(({ id }) => id === questionnaireId)
      )
  )
