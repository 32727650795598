/* eslint-disable no-alert */
import { Box, Button, Stack, Text } from '@chakra-ui/react'
import { useConfirmationModal, useModal, usePromisingModal } from '@repo/ui'

import { ComponentSection } from './shared/component-section'
import { ComponentVariant } from './shared/component-variant'

const alertClick = () => alert('clicked')

const sleep = ms =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })

const HookButton = ({ title, onClick }) => (
  <Button variant="outline" onClick={onClick}>
    {title}
  </Button>
)

export const ModalHooksSection = () => {
  const [basic, showBasic] = useModal(
    ({ closeModal }) => (
      <Box>
        <Text mb={2}>Modal content</Text>
        <Button onClick={closeModal}>Click to close modal</Button>
      </Box>
    ),
    {
      title: 'Modal title',
    }
  )

  const [conf, showConf] = useConfirmationModal({
    onConfirm: alertClick,
    title: 'Modal title',
    description: 'Modal description',
  })

  const [confDest, showConfDest] = useConfirmationModal({
    onConfirm: alertClick,
    title: 'Modal title',
    description: 'Modal description',
    isDestructive: true,
  })

  const [confAsync, showConfAsync] = useConfirmationModal({
    onConfirm: async () => {
      await sleep(1000)
    },
    title: 'Modal title',
    description: 'Modal description',
  })

  const [promisingModal, choosePetViaModal] = usePromisingModal<'cat' | 'dog'>(
    ({ resolve }) => (
      <Stack>
        <Button onClick={() => resolve('cat')}>Cats</Button>
        <Button onClick={() => resolve('dog')}>Dogs</Button>
      </Stack>
    ),
    { title: 'What is your favorite pet?' }
  )

  return (
    <ComponentSection
      title="Modal Hooks"
      subtitle="We should be using these utility hooks for modals, instead of using ChakraUI modal manually"
    >
      {basic}
      {conf}
      {confDest}
      {confAsync}
      {promisingModal}
      <ComponentVariant title="useModal">
        <HookButton title="Basic" onClick={showBasic} />
      </ComponentVariant>
      <ComponentVariant title="useConfirmationModal">
        <HookButton title="Basic" onClick={showConf} />
        <HookButton title="Destructive" onClick={showConfDest} />
        <HookButton title="With async action" onClick={showConfAsync} />
      </ComponentVariant>
      <ComponentVariant title="usePromisingModal">
        <HookButton
          title="Basic"
          onClick={async () => {
            const favoritePet = await choosePetViaModal()

            if (favoritePet === null) {
              return alert('No pet selected')
            }

            alert(`Favorite pet: ${favoritePet}`)
          }}
        />
      </ComponentVariant>
    </ComponentSection>
  )
}
