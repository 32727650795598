import { FormControl, FormErrorMessage, FormHelperText } from '@chakra-ui/react'
import { useField } from 'formik'
import { omit } from 'lodash-es'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { DateRangePicker as DatesPicker } from 'react-dates'

export const DateRangePicker = ({
  name,
  help,
  defaultStartDate,
  defaultEndDate,
  ...restProps
}) => {
  const [field, meta, form] = useField(name)
  const [focusedInput, setFocusedInput] = useState(null)
  const { value } = field

  return (
    <FormControl>
      <DatesPicker
        startDate={value?.startDate || defaultStartDate}
        startDateId="startDate"
        endDate={value?.endDate || defaultEndDate}
        endDateId="endDate"
        onDatesChange={dates => form.setValue(dates)}
        focusedInput={focusedInput}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFocusChange={newFocus => setFocusedInput(newFocus as any)}
        displayFormat="DD/MM/YYYY"
        {...omit(restProps, ['type'])}
      />

      {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      {help && <FormHelperText>{help}</FormHelperText>}
    </FormControl>
  )
}

DateRangePicker.propTypes = {
  name: PropTypes.string.isRequired,
  help: PropTypes.node,
  defaultStartDate: PropTypes.object,
  defaultEndDate: PropTypes.object,
}
