import { Box, type BoxProps, Button, Flex, HStack, Icon, Stack } from '@chakra-ui/react'
import { FormattedMessage, type ReactNodeOrI18nKey } from '@repo/i18n'
import { type ComponentRef, forwardRef, type ReactNode } from 'react'
import { IoArrowBackCircleSharp } from 'react-icons/io5'
import { Link } from 'react-router-dom'

import { colors } from '../theme/main'
import { SectionHeader } from './section-header'

export type PageWrapperProps = {
  children: ReactNode
  backTo?: string
  onBack?: () => void
  backLabelKey?: I18nKey
  sx?: BoxProps['sx']
  titleStartContent?: ReactNode
  titleEndContent?: ReactNode
  pageActions?: ReactNode
} & ReactNodeOrI18nKey<'title'> &
  ReactNodeOrI18nKey<'subtitle'>

export const PageWrapper = forwardRef<ComponentRef<typeof Flex>, PageWrapperProps>(
  (
    {
      titleKey,
      title,
      subtitleKey,
      subtitle,
      children,
      backTo,
      onBack,
      backLabelKey,
      sx,
      titleEndContent,
      titleStartContent,
      pageActions,
    },
    ref
  ) => {
    const hasSectionHeader = !!(titleKey || subtitleKey || title || subtitle)

    return (
      <Flex ref={ref} flex={1} p={6} pb={10} flexDir="column" sx={sx}>
        <Flex alignItems="flex-start" mb={10} lineHeight={1}>
          <Stack gap={10} alignItems="flex-start" flex={1}>
            {(backTo || onBack) && (
              <Button
                as={backTo ? Link : undefined}
                variant="unstyled"
                display="flex"
                color={colors.blue[500]}
                fontSize="16px"
                to={backTo}
                onClick={onBack}
                _hover={{ textDecoration: 'underline', color: colors.blue[500] }}
              >
                <Icon
                  as={IoArrowBackCircleSharp}
                  w="34px"
                  mr="6px"
                  ml={-1}
                  h="34px"
                  color={colors.blue[500]}
                />
                {!!backLabelKey && <FormattedMessage id={backLabelKey} />}
              </Button>
            )}
            {hasSectionHeader && (
              <HStack w="full" gap={6}>
                {!!titleStartContent && <Box>{titleStartContent}</Box>}
                {/* @ts-expect-error this is safe to ignore, normally ReactNodeOrI18nKey does not accept both title and titleKey at the same time but in this case, we are just passing both down */}
                <SectionHeader
                  titleKey={titleKey}
                  title={title}
                  subtitleKey={subtitleKey}
                  subtitle={subtitle}
                />
                {!!titleEndContent && <Box>{titleEndContent}</Box>}
              </HStack>
            )}
          </Stack>
          {pageActions}
        </Flex>

        {children}
      </Flex>
    )
  }
)
