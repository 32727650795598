import { useIntl } from '@repo/i18n'
import { type SortOptions } from '@repo/ui'

import { type FieldName as RecordingSessionFieldName } from '../recording-sessions-api'

export type ColumnSortableItemValue = {
  value: ColumnItemValue
  sortBy: SortOptions
}

export type ColumnItemValue = RecordingSessionFieldName

type ColumnSelectItemData = {
  text: string
  selectedText: string
  value: ColumnItemValue
}
type ColumnSelectGroupData = {
  title?: string
  items: Array<ColumnSelectItemData>
}
type ColumnSelectSubGroupData = {
  title?: string
  subGroups: Array<ColumnSelectGroupData>
}

type ColumnSelectData = Array<ColumnSelectSubGroupData>

export type ColumnSelectDictionary = {
  [key in ColumnItemValue]: Omit<ColumnSelectItemData, 'value'>
}

export interface SortingState {
  sortBy: ColumnItemValue
  sortOrder: Exclude<SortOptions, 'default'>
}

export const makeColumnSelectDictionaryData = (
  data: ColumnSelectData
): ColumnSelectDictionary =>
  data
    .flatMap(g => g.subGroups)
    .flatMap(s => s.items)
    .reduce((acc, item) => ({ ...acc, [item.value]: item }), {} as ColumnSelectDictionary)

export const useColumnSelectRootData = (): ColumnSelectData => {
  const { formatMessage } = useIntl()

  return [
    {
      title: formatMessage({ id: 'recording.list.filters.title.basic-information' }),
      subGroups: [
        {
          items: [
            {
              text: formatMessage({ id: 'common.name' }),
              selectedText: formatMessage({ id: 'common.name' }),
              value: 'name',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.date' }),
              selectedText: formatMessage({ id: 'recording.list.filters.date' }),
              value: 'createdAt',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.id' }),
              selectedText: formatMessage({ id: 'recording.list.filters.id' }),
              value: 'id',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.email' }),
              selectedText: formatMessage({ id: 'recording.list.filters.email' }),
              value: 'email',
            },
          ],
        },
      ],
    },
  ]
}

export const useColumnSelectDataInner = (): ColumnSelectData => {
  const { formatMessage } = useIntl()

  return [
    {
      title: formatMessage({ id: 'recording.list.filters.title.basic-information' }),
      subGroups: [
        {
          items: [
            {
              text: formatMessage({ id: 'common.name' }),
              selectedText: formatMessage({ id: 'common.name' }),
              value: 'name',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.date' }),
              selectedText: formatMessage({ id: 'recording.list.filters.date' }),
              value: 'createdAt',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.id' }),
              selectedText: formatMessage({ id: 'recording.list.filters.id' }),
              value: 'id',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.email' }),
              selectedText: formatMessage({ id: 'recording.list.filters.email' }),
              value: 'email',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.overall-relevance' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.overall-relevance',
              }),
              value: 'overallScore',
            },
          ],
        },
      ],
    },
    {
      title: formatMessage({
        id: 'recording.list.filters.title.target-profile-traits',
      }),
      subGroups: [
        {
          title: formatMessage({ id: 'common.personality' }),
          items: [
            {
              text: formatMessage({ id: 'recording.list.filters.openness' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.openness',
              }),
              value: 'openness',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.conscientiousness' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.conscientiousness',
              }),
              value: 'conscientiousness',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.extraversion' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.extraversion',
              }),
              value: 'extraversion',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.agreeableness' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.agreeableness',
              }),
              value: 'agreeableness',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.neuroticism' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.neuroticism',
              }),
              value: 'neuroticism',
            },
          ],
        },
        {
          title: formatMessage({ id: 'recording.list.filters.title.job-role' }),
          items: [
            {
              text: formatMessage({ id: 'recording.list.filters.dealing-with-others' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.dealing-with-others',
              }),
              value: 'tfDealing',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.task-identitiy' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.task-identitiy',
              }),
              value: 'tfIdentity',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.task-variety' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.task-variety',
              }),
              value: 'tfVariety',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.autonomy' }),
              selectedText: formatMessage({ id: 'recording.list.filters.autonomy' }),
              value: 'tfAutonomy',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.social-relations' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.social-relations',
              }),
              value: 'tfSocialRelations',
            },
          ],
        },
        {
          title: formatMessage({ id: 'recording.list.filters.title.culture' }),
          items: [
            {
              text: formatMessage({ id: 'recording.list.filters.competition' }),
              selectedText: formatMessage({ id: 'recording.list.filters.competition' }),
              value: 'cfCompetitive',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.innovation' }),
              selectedText: formatMessage({ id: 'recording.list.filters.innovation' }),
              value: 'cfInnovative',
            },
            {
              text: formatMessage({
                id: 'recording.list.filters.attention-for-detail',
              }),
              selectedText: formatMessage({
                id: 'recording.list.filters.attention-for-detail',
              }),
              value: 'cfDetailOriented',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.outcome-orientation' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.outcome-orientation',
              }),
              value: 'cfOutcomeOriented',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.team-orientation' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.team-orientation',
              }),
              value: 'cfTeamOriented',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.supportiveness' }),
              selectedText: formatMessage({
                id: 'recording.list.filters.supportiveness',
              }),
              value: 'cfSupportive',
            },
            {
              text: formatMessage({ id: 'recording.list.filters.stability' }),
              selectedText: formatMessage({ id: 'recording.list.filters.stability' }),
              value: 'cfStability',
            },
          ],
        },
      ],
    },
  ]
}
