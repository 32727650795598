import {
  Box,
  Button,
  FormControl,
  type HTMLChakraProps,
  MenuGroup,
  MenuList,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { CustomSelectButton, RadioSelect, RadioSelectItem } from '@repo/ui'
import { useCallback, useMemo, useState } from 'react'

import { type FilterItemValue } from './filter-control-data'
import { useFilterSelectData } from './recording-filters-hooks'

export type FilterControlProps = {
  value: FilterItemValue
  onChange: (v: FilterItemValue) => void
  onApply: (v: FilterItemValue) => void
  onClose: (v: FilterItemValue) => void
} & Omit<HTMLChakraProps<'div'>, 'onChange'>

export const FilterControl = ({
  value,
  onChange,
  onApply,
  onClose,
  ...rest
}: FilterControlProps) => {
  const [data, dictionaryData] = useFilterSelectData()

  const [isOpen, setIsOpen] = useState(false)

  const text = useMemo(() => dictionaryData[value].selectedText, [value, dictionaryData])

  const handleOnChange = useCallback(
    (v: string) => onChange(v as FilterItemValue),
    [onChange]
  )

  const handleOnClose = useCallback(
    (v: string) => {
      onClose(v as FilterItemValue)
      setIsOpen(false)
    },
    [onClose]
  )

  const handleOnApply = useCallback(() => {
    onApply(value)
    setIsOpen(false)
  }, [onApply, value])

  return (
    <FormControl {...rest}>
      <RadioSelect
        defaultValue="all"
        value={value}
        onChange={handleOnChange}
        isOpen={isOpen}
        onClose={handleOnClose}
      >
        <CustomSelectButton onClick={() => setIsOpen(true)}>{text}</CustomSelectButton>
        <MenuList>
          {data.map(group => (
            <MenuGroup fontWeight="medium" key={group.title} title={group.title}>
              {group.items.map(item => (
                <RadioSelectItem key={item.value} value={item.value}>
                  {item.text}
                </RadioSelectItem>
              ))}
            </MenuGroup>
          ))}
          <Box display="flex" justifyContent="center" pt={3}>
            <Button onClick={handleOnApply}>
              <FormattedMessage id="recording.list.filters.form-control.apply" />
            </Button>
          </Box>
        </MenuList>
      </RadioSelect>
    </FormControl>
  )
}
