import { Select } from '@chakra-ui/react'
import { findIndex } from 'lodash-es'
import PropTypes from 'prop-types'

export const RoleSelector = ({ roles, index, role, disabled, onChange }) => {
  const handleChange = e => {
    const selectedRole = e.target.value
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const roleIndex = findIndex<any>(roles, r => r.id === selectedRole)

    onChange(index, { roleIndex })
  }

  return (
    <Select isDisabled={disabled} onChange={handleChange} value={role}>
      {roles.map(({ id: roleId, name }) => (
        <option key={roleId} value={roleId} selected={roleId === role}>
          {name}
        </option>
      ))}
    </Select>
  )
}

RoleSelector.propTypes = {
  roles: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

RoleSelector.defaultProps = {
  disabled: false,
}
