import {
  Box,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
} from '@chakra-ui/react'
import { type AnyObject } from '@repo/utils'
import { Field, type FormikErrors, type FormikTouched, useFormikContext } from 'formik'
import { memo, useState } from 'react'
import { SingleDatePicker, type SingleDatePickerShape } from 'react-dates'
import { BiErrorCircle } from 'react-icons/bi'

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

type FormControlDateInputFieldProps<
  TValue extends { [key: string]: string | boolean | number },
  TProp extends keyof TValue,
> = {
  prop: TProp
  touched?: FormikTouched<TValue>
  errors?: FormikErrors<TValue>
  label?: string
} & Omit<
  SingleDatePickerShape,
  'id' | 'date' | 'focused' | 'onDateChange' | 'onFocusChange' | 'renderMonthText'
> // renderMothText has false typings and needs to be ignored as well

const FormControlDateInputFieldNoMemo = <
  T extends { [key: string]: string | boolean | number },
>({
  prop: p,
  touched,
  errors,
  label,
  ...datePickerProps
}: FormControlDateInputFieldProps<T, keyof T>) => {
  const prop = p as string
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const [focusedInput, setFocusedInput] = useState<boolean>(false)

  // IMPORTANT We are not using Formik `useField` hook because it would re-render
  // the whole compnent every time a field in the form changes.
  // That would lead to huge performance issues on slow machines.
  // By using the memo + props the component will re-render only when
  // `touched` and `errors` change.

  const onChange = date => {
    setFieldValue(prop, date)
    setFieldTouched(prop)
  }

  const isInvalid = !!errors?.[prop] && !!touched?.[prop]

  return (
    <FormControl id={prop} isInvalid={isInvalid}>
      {label && (
        <FormLabel
          htmlFor={prop}
          color="primary-dark"
          textTransform="uppercase"
          fontSize="sm"
          fontWeight="normal"
        >
          {datePickerProps?.required && '*'}
          {label}
        </FormLabel>
      )}
      <Field name={prop}>
        {({ field }: AnyObject) => (
          <Box sx={{ 'div > .DateInput_input': { width: '105px' } }}>
            <SingleDatePicker
              id={prop}
              date={field.value}
              focused={focusedInput}
              onDateChange={date => onChange(date)}
              onFocusChange={() => {
                setFocusedInput(!focusedInput)
              }}
              hideKeyboardShortcutsPanel
              showClearDate
              readOnly
              displayFormat="DD/MM/YYYY"
              {...datePickerProps}
            />
          </Box>
        )}
      </Field>
      <FormErrorMessage as={HStack} spacing={1}>
        <BiErrorCircle />
        <chakra.span>{errors?.[prop] as string}</chakra.span>
      </FormErrorMessage>
    </FormControl>
  )
}

export const FormControlDateInputField = memo(
  FormControlDateInputFieldNoMemo
) as typeof FormControlDateInputFieldNoMemo
