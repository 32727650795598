import { Flex } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useModal } from '@repo/ui'
import { useNavigate } from 'react-router-dom'

import { useOrganization } from '../../../hooks/use-organization'
import { BuilderModeCard } from '../../../new-program-builder/training-detail-page/create-scenario-card/builder-mode-card'

export const useGeneratorModal = ({
  showCreateProgramModal,
}: {
  showCreateProgramModal: () => void
}) => {
  const navigate = useNavigate()
  const { hasAiContentGen, hasUnderstanding, hasRealtimeConversationStep } =
    useOrganization()

  const hasConsentForAIBuilder = hasUnderstanding && hasAiContentGen

  return useModal(
    ({ closeModal }) => (
      <Flex gap="8" mb={6}>
        <BuilderModeCard
          isDisabled={!hasRealtimeConversationStep}
          mode="realtimeOld"
          onClick={() => {
            closeModal()
            navigate(`/training_programs`)
            showCreateProgramModal()
          }}
          tooltip={
            !hasRealtimeConversationStep && (
              <FormattedMessage id="coaching.generator.modal.title" />
            )
          }
        />
        <BuilderModeCard
          isDisabled={!hasConsentForAIBuilder}
          mode="ai"
          onClick={() => {
            closeModal()
            navigate(`/training_programs`)
            showCreateProgramModal()
          }}
          tooltip={
            !hasConsentForAIBuilder && (
              <FormattedMessage id="coaching.generator.modal.title" />
            )
          }
        />
      </Flex>
    ),
    {
      titleKey: 'coaching.generator.modal.title',
      modalProps: {
        size: '5xl',
      },
    }
  )
}
