import { Input, type InputProps } from '@chakra-ui/react'
import { Controller, type FieldPath, type FieldValues } from 'react-hook-form'

import { FormControlWrapper } from './shared/form-control-wrapper'
import { type FieldBaseProps } from './shared/types'

export const FormFieldInput = <T extends FieldValues, TName extends FieldPath<T>>(
  props: FieldBaseProps<T, InputProps, TName>
) => {
  const { name, control, rules, shouldUnregister, componentProps } = props

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FormControlWrapper fieldBaseProps={props} error={fieldState.error}>
          <Input
            {...field}
            {...componentProps}
            // empty string fallback is here to prevent the value being undefined, which causes a warning in the console
            value={field.value ?? ''}
            onChange={e => {
              field.onChange(e)
              componentProps?.onChange?.(e)
            }}
          />
        </FormControlWrapper>
      )}
    />
  )
}
