import { type LanguageKey } from './types'

export const languageLabels: Record<LanguageKey, string> = {
  'de-DE': 'Deutsch',
  'en-US': 'English',
  'es-ES': 'Español',
  'fr-FR': 'Français',
  'it-IT': 'Italiano',
  'pt-PT': 'Português',
}

export const AVAILABLE_LANGUAGES: Array<{ value: LanguageKey; label: string }> = [
  { value: 'de-DE', label: languageLabels['de-DE'] },
  { value: 'en-US', label: languageLabels['en-US'] },
  { value: 'es-ES', label: languageLabels['es-ES'] },
  { value: 'fr-FR', label: languageLabels['fr-FR'] },
  { value: 'it-IT', label: languageLabels['it-IT'] },
  { value: 'pt-PT', label: languageLabels['pt-PT'] },
]
