import { HStack, Switch, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { useOrganization } from '../../hooks/use-organization'
import { saveProgramNotificationsSettings } from '../../store/entities/organization/effects'

export const NotificationsSettings = () => {
  const { hasProgramNotificationsEnabled } = useOrganization()
  const dispatch = useDispatch()

  const [isChecked, setIsChecked] = useState<boolean>(!!hasProgramNotificationsEnabled)

  const onSwitchChange = () => {
    try {
      const isEnabled = !isChecked

      setIsChecked(isEnabled)
      dispatch(saveProgramNotificationsSettings({ isEnabled }))
      showToast({ messageKey: 'common.alert.created', status: 'success' })
    } catch (error) {
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }
  }

  return (
    <HStack alignItems="start" minHeight={430} mt={2}>
      <Switch size="md" isChecked={isChecked} onChange={onSwitchChange} />
      <Text lineHeight="22px">
        <FormattedMessage id="organization.settings.notifications.toggle.label" />
      </Text>
    </HStack>
  )
}
