/* eslint-disable camelcase */
import { createAnalyticsLogger } from '@repo/utils'
import { getAnalytics, setUserProperties } from 'firebase/analytics'

import { type DashboardCardId, type DataSlotType } from '../dashboard/shared/types'

const analytics = getAnalytics()

setUserProperties(analytics, { retorio_platform: 'admin' })

const { logAnalyticsEvent } = createAnalyticsLogger<AdminEventParams>(analytics, 'admin')

export { logAnalyticsEvent }

export const setUserPropertiesOnLogin = ({ organization_name }: AdminUserProperties) => {
  setUserProperties(analytics, {
    organization_name,
  })
}

export const clearUserPropertiesOnLogout = () => {
  setUserProperties(analytics, {
    organization_name: undefined,
  } satisfies AdminUserProperties)
}

interface AdminUserProperties {
  organization_name: string | undefined
}

type AdminEventParams = {
  page_view_dashboard: null
  page_view_dashboard_widget_detail: {
    widgetId: DashboardCardId
  }
  change_widget_time_slot: {
    widgetId: DashboardCardId
    timeSlot: DataSlotType
  }
}
