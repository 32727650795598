import { useSWRLoader } from '../hooks/use-swr-loader'
import { customSwrFetcher } from '../utils/swr-fetcher'
import { type AvailableProgram } from './types/available-program'
import { type Cohort } from './types/cohort'
import { type CohortProgram } from './types/cohort-program'
import { type CohortProgramCreate } from './types/cohort-program-create'
import { type CohortProgramUpdate } from './types/cohort-program-update'

const getCohortProgramsBaseUrl = (cohortId: string) => `/cohorts/${cohortId}/programs`
const getCohortProgramsProgramUrl = (cohortId: string, cohortProgramId: string) =>
  `${getCohortProgramsBaseUrl(cohortId)}/${cohortProgramId}`

export const useCohortPrograms = () => {
  const useAvailablePrograms = (cohortId: string) =>
    useSWRLoader<Array<AvailableProgram>>(`${getCohortProgramsBaseUrl(cohortId)}/unused`)

  const useCohortProgramsList = (cohortId: string) =>
    useSWRLoader<Array<CohortProgram>>(getCohortProgramsBaseUrl(cohortId))

  const useCohortProgram = (cohortId: string, cohortProgramId: string) =>
    useSWRLoader<CohortProgram>(getCohortProgramsProgramUrl(cohortId, cohortProgramId))

  const createCohortPrograms = async (data: CohortProgramCreate, cohortId: string) =>
    customSwrFetcher<Cohort>(getCohortProgramsBaseUrl(cohortId), {
      method: 'POST',
      body: JSON.stringify(data),
    })

  const updateCohortProgram = async (
    data: CohortProgramUpdate,
    cohortId: string,
    cohortProgramId: string
  ) =>
    customSwrFetcher<Cohort>(getCohortProgramsProgramUrl(cohortId, cohortProgramId), {
      method: 'PUT',
      body: JSON.stringify(data),
    })

  const deleteCohortProgram = async (cohortId: string, cohortProgramId: string) =>
    customSwrFetcher<Cohort>(getCohortProgramsProgramUrl(cohortId, cohortProgramId), {
      method: 'DELETE',
    })

  const saveCohortProgramsOrder = async (
    cohortExtId: string,
    cohortPrograms: Array<CohortProgram>
  ) =>
    customSwrFetcher<CohortProgram>(`${getCohortProgramsBaseUrl(cohortExtId)}/order`, {
      method: 'POST',
      body: JSON.stringify(
        cohortPrograms.map(({ extId, order }) => ({
          extId,
          order,
        }))
      ),
    })

  return {
    useCohortProgramsList,
    useAvailablePrograms,
    useCohortProgram,
    createCohortPrograms,
    deleteCohortProgram,
    updateCohortProgram,
    saveCohortProgramsOrder,
  }
}
