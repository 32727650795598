import {
  Box,
  createIcon,
  type HTMLChakraProps,
  type SystemStyleObject,
  useControllableState,
} from '@chakra-ui/react'

const UpIcon = createIcon({
  displayName: 'UpIcon',
  viewBox: '0 0 24 24',
  path: <path fill="currentColor" d="M 12 6 l 12 12 H 0 z" />,
})

const DownIcon = createIcon({
  displayName: 'DownIcon',
  viewBox: '0 0 24 24',
  path: <path fill="currentColor" d="M 12 18 l -12 -12 h 24 z" />,
})

export type SortOptions = 'default' | 'asc' | 'desc'

export type SortDirectionToggleOwnProps = {
  value?: SortOptions
  onChange?: (v: SortOptions) => void
}
type SortDirectionToggleProps = Omit<HTMLChakraProps<'div'>, 'onChange'> &
  SortDirectionToggleOwnProps

const sx: SystemStyleObject = {
  _hover: {
    cursor: 'pointer',
    color: 'primary-dark',
  },
}

export const SortDirectionToggle = ({
  value: valueProp,
  onChange,
  ...rest
}: SortDirectionToggleProps) => {
  const [value, setValue] = useControllableState<SortOptions>({
    defaultValue: 'default',
    value: valueProp,
    onChange,
  })

  return (
    <Box display="flex" flexDirection="column" color="secondary-dark" {...rest}>
      <UpIcon
        focusable
        boxSize={3}
        color={value === 'asc' ? 'primary' : undefined}
        onClick={() => setValue('asc')}
        sx={sx}
      />
      <DownIcon
        focusable
        boxSize={3}
        color={value === 'desc' ? 'primary' : undefined}
        onClick={() => setValue('desc')}
        sx={sx}
      />
    </Box>
  )
}
