import { Box, Button, Center } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { Formik } from 'formik'

import {
  SelectScenariosForm,
  type SelectScenariosFormValues,
} from '../../../../../../coaching-benchmark-profiles/components/select-scenarios-form'
import { type ScenarioTree } from '../../../../../shared/types'

export const SelectContentStep = ({
  handleSubmit,
  scenarioTree,
}: {
  handleSubmit: (data: SelectScenariosFormValues) => void
  scenarioTree: Array<ScenarioTree>
}) => (
  <Formik<SelectScenariosFormValues>
    onSubmit={handleSubmit}
    initialValues={{ selectedScenarioExtIds: [] }}
  >
    {({ submitForm, dirty, isValid }) => (
      <>
        <Box px={6}>
          {/* TODO: empty state when there is no scenario in the org */}
          <SelectScenariosForm
            programTrainingScenarioTree={scenarioTree}
            showActionButtons={false}
            showLabel={false}
          />
        </Box>

        <Center>
          <Button isDisabled={!(dirty && isValid)} onClick={submitForm}>
            <FormattedMessage id="common.continue" />
          </Button>
        </Center>
      </>
    )}
  </Formik>
)
