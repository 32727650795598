import { Stack, Text } from '@chakra-ui/react'
import { AVAILABLE_PROGRAM_LANGUAGES, BETA_PROGRAM_LANGUAGES } from '@repo/api'
import { FormattedMessage, languageLabels, useIntl } from '@repo/i18n'
import { colors, RHF, useFormRules } from '@repo/ui'
import { type AutocompleteOptions } from '@repo/ui/src/react-hook-forms/rhf/form-field-autocomplete-select'
import { useForm } from 'react-hook-form'

import { RHFImageUploader } from '../../../components/rhf-image-uploader'
import { type ProgramFormValues } from '../../shared/types'

export const useProgramForm = (defaultValues?: ProgramFormValues) => {
  const form = useForm<ProgramFormValues>({
    defaultValues,
    mode: 'onTouched',
  })

  const { formatMessage } = useIntl()
  const rules = useFormRules()

  const { control, setError, clearErrors } = form

  const languageOptions: AutocompleteOptions = AVAILABLE_PROGRAM_LANGUAGES.map(key => ({
    label: languageLabels[key],
    value: key,
    tag: BETA_PROGRAM_LANGUAGES.includes(key)
      ? formatMessage({ id: 'common.beta' })
      : undefined,
  }))

  const formFieldsJsx = (
    <Stack gap={6}>
      <RHF.Input
        control={control}
        name="title"
        labelKey="program.title"
        rules={rules.stringShortRequired}
        componentProps={{
          placeholder: formatMessage({ id: 'program.title.placeholder' }),
        }}
      />
      <RHF.TextArea
        control={control}
        name="description"
        labelKey="program.introduction.label"
        componentProps={{
          placeholder: formatMessage({ id: 'program.description.placeholder' }),
        }}
        rules={rules.stringLong}
      />

      <Stack gap={2}>
        <RHF.AutocompleteSelect
          control={control}
          name="language"
          labelKey="program.language.placeholder"
          rules={rules.required}
          componentProps={{
            placeholderKey: 'program.language.placeholder',
            options: languageOptions,
            isDisabled: !!defaultValues,
          }}
        />
        <Text color={colors.blue[500]} fontSize={14}>
          <FormattedMessage id="program.language.infoText" />
        </Text>
      </Stack>
      <RHFImageUploader
        control={control}
        name="imageUrl"
        labelKey="program.image.label"
        setError={setError}
        clearErrors={clearErrors}
      />
    </Stack>
  )

  return [form, formFieldsJsx] as const
}
