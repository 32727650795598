import {
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { RangeDatepicker } from 'chakra-dayzed-datepicker'
import { isEqual, truncate } from 'lodash-es'
import moment from 'moment'
import { type ChangeEvent, useCallback, useState } from 'react'
import { BsChevronDown } from 'react-icons/bs'

import { MAX_NUMBER_OF_COHORTS_FOR_SELECTION } from '../shared/constants'
import { useDashboardContext } from '../shared/dashboard-context'
import { fetchDashboardData } from '../shared/fetch-dashboard-data'
import { generateSelectedDataSlotPerCard } from '../shared/generate-data-slot-per-card'
import { type DashboardFilterDates, type DataSlot } from '../shared/types'

// TODO: get the earliestDate form the start_date column of coaching_dashboard_metrics table
// This is the earliest possible date we can query from backend
const EARLIEST_METRICS_DATE = moment('2021-08-01T00:00:00.000Z').toDate()

const getStartOfUTCDate = (date: Date | null) => {
  if (!date) {
    return date
  }

  const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))

  return newDate
}

export const DashboardFilters = () => {
  const {
    filters: { selectedDates, selectedCohorts: cohorts },
    selectedCohorts,
    dataSlots,
    allCohorts,
    setPageState,
    setDataSlots,
    pageState,
    setSelectedDataSlotPerCard,
    setSelectedCohorts,
    setSelectedDates,
  } = useDashboardContext()

  const [isApplyingFilter, setIsApplyingFilter] = useState(false)
  const [tempSelectedCohorts, setTempSelectCohorts] =
    useState<Array<string>>(selectedCohorts)

  const [tempSelectedDates, setTempSelectDates] = useState<Array<Date>>([
    selectedDates.startDate,
    selectedDates.endDate,
  ])

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newSelectedOptions = tempSelectedCohorts.includes(event.target.value)
        ? tempSelectedCohorts.filter(option => option !== event.target.value)
        : [...tempSelectedCohorts, event.target.value]

      setTempSelectCohorts(newSelectedOptions)
    },
    [tempSelectedCohorts]
  )

  const datesChanged =
    tempSelectedDates[0]?.toDateString() !== selectedDates.startDate.toDateString() ||
    tempSelectedDates[1]?.toDateString() !== selectedDates.endDate.toDateString()

  const cohortsChanged = !isEqual(
    [...tempSelectedCohorts].sort(),
    [...selectedCohorts].sort()
  )

  const onFilterChange = async ({
    dates,
    cohorts: cohortsParam,
  }: {
    cohorts?: Array<string>
    dates?: DashboardFilterDates
  }) => {
    if (pageState === 'error') {
      setPageState('loading')
    }

    if (dates) {
      setSelectedDates(dates)
    }

    if (cohortsParam) {
      setSelectedCohorts(cohortsParam)
    }

    try {
      // we are not setting slots to loading state before the api call here, because we don't want to show loading states for all cards
      const dashboardData = await fetchDashboardData({
        dates: dates ?? selectedDates,
        cohorts: cohortsParam ?? selectedCohorts,
      })

      // set all to custom
      setSelectedDataSlotPerCard(generateSelectedDataSlotPerCard('custom'))

      setPageState('loaded')
      setDataSlots(prev => {
        const loadedSlot: DataSlot = { status: 'loaded', data: dashboardData }

        // if cohorts changed, empty other data slots
        if (cohortsParam) {
          return {
            lastWeek: { status: 'empty' },
            lastMonth: { status: 'empty' },
            custom: loadedSlot,
          }
        }

        return {
          ...prev,
          custom: loadedSlot,
        }
      })
    } catch (err) {
      setPageState('error')
      setDataSlots(prev => ({
        ...prev,
        custom: { status: 'empty' },
      }))
    }
  }

  const handleOnApply = async () => {
    try {
      setIsApplyingFilter(true)

      if (!datesChanged || !tempSelectedDates[0] || !tempSelectedDates[1]) {
        // only cohorts changed
        await onFilterChange({ cohorts: tempSelectedCohorts })
      } else if (!cohortsChanged) {
        // only dates changed
        await onFilterChange({
          dates: {
            endDate: getStartOfUTCDate(tempSelectedDates[1])!,
            startDate: getStartOfUTCDate(tempSelectedDates[0])!,
          },
        })
      } else {
        await onFilterChange({
          cohorts: tempSelectedCohorts,
          dates: {
            endDate: getStartOfUTCDate(tempSelectedDates[1])!,
            startDate: getStartOfUTCDate(tempSelectedDates[0])!,
          },
        })
      }
    } finally {
      setIsApplyingFilter(false)
    }
  }

  const isSubmitDisabled =
    // no cohorts selected
    (tempSelectedCohorts.length === 0 && selectedCohorts.length > 0) ||
    // selection limit reached
    tempSelectedCohorts.length > MAX_NUMBER_OF_COHORTS_FOR_SELECTION ||
    // no changes made to filter selection
    (!cohortsChanged && !datesChanged)

  const isLoading =
    dataSlots.custom.status === 'loading' ||
    dataSlots.lastWeek.status === 'loading' ||
    dataSlots.lastMonth.status === 'loading' ||
    isApplyingFilter

  // this state sync is to make this component respect the selectedCohorts changing from the context
  // for now, it only happens on the initial render when we set the first X cohorts as selected after the cohorts data is fetched
  const [prevSelectedCohorts, setPrevSelectedCohorts] = useState(selectedCohorts)

  if (prevSelectedCohorts !== selectedCohorts) {
    setPrevSelectedCohorts(selectedCohorts)
    setTempSelectCohorts(selectedCohorts)
  }

  return (
    <HStack>
      <Box
        border="1px"
        borderRadius="md"
        height="32px"
        borderWidth="1px"
        pl={2}
        pr={2}
        bgColor="white"
        color={colors.gray[700]}
        borderColor={colors['secondary-dark']}
      >
        <RangeDatepicker
          selectedDates={tempSelectedDates}
          onDateChange={setTempSelectDates}
          configs={{
            dateFormat: 'dd/MM/yyyy',
          }}
          onCalendarClose={() => {
            if (tempSelectedDates[0] && !tempSelectedDates[1]) {
              setTempSelectDates([tempSelectedDates[0], tempSelectedDates[0]])
            }
          }}
          maxDate={new Date()}
          minDate={EARLIEST_METRICS_DATE}
          closeOnSelect
          propsConfigs={{
            dateNavBtnProps: {
              colorScheme: 'blue',
            },

            dayOfMonthBtnProps: {
              defaultBtnProps: {
                _hover: {
                  background: colors.blue[500],
                  color: 'white',
                },
                color: 'black',
                fontSize: '12px',
              },
              isInRangeBtnProps: {
                background: colors['secondary-light'],
                color: colors.blue[500],
              },
              selectedBtnProps: {
                background: colors.blue[500],
                color: 'white',
              },
            },
            triggerBtnProps: {
              border: 'none',
              color: 'black',
              fontSize: '14px',
              fontWeight: 400,
              _hover: {
                backgroundColor: 'none',
              },
              _focus: {
                background: 'none',
              },
              padding: '0px !important',
            },
            weekdayLabelProps: {
              fontWeight: 400,
              fontSize: '14px',
            },
            dateHeadingProps: {
              fontWeight: 400,
              fontSize: '14px',
            },
          }}
        />
      </Box>

      <Menu>
        <MenuButton
          as={Button}
          borderRadius="md"
          borderWidth="1px"
          variant="unstyled"
          display="flex"
          pl={2}
          pr={2}
          bgColor="white"
          color={colors.gray[700]}
          border="1px"
          borderColor={colors['secondary-dark']}
          sx={{
            '> span:first-of-type': {
              whiteSpace: 'nowrap',
              overflow: ' hidden',
              textOverflow: 'ellipsis',
            },
          }}
          rightIcon={<Icon as={BsChevronDown} />}
          w="220px"
          textAlign="start"
        >
          {cohorts.map(cohort => cohort.name).join(', ')}
        </MenuButton>
        <MenuList p={2}>
          <Stack mt={1} spacing={1}>
            <Text size="sm" color={colors.gray[500]}>
              <FormattedMessage
                id="dashboard.filters.cohorts.selectionLimit"
                values={{ maxCohorts: MAX_NUMBER_OF_COHORTS_FOR_SELECTION }}
              />
            </Text>
            <Stack maxH="500px" overflow="auto" p={1}>
              {allCohorts.map(opt => (
                <Checkbox
                  isChecked={tempSelectedCohorts.includes(opt.extId)}
                  key={opt.extId}
                  value={opt.extId}
                  onChange={handleOnChange}
                  isDisabled={
                    tempSelectedCohorts.length >= MAX_NUMBER_OF_COHORTS_FOR_SELECTION &&
                    !tempSelectedCohorts.includes(opt.extId)
                  }
                >
                  {truncate(opt.name)}
                </Checkbox>
              ))}
            </Stack>
            <Divider />
            <Button
              color={colors.blue[500]}
              variant="ghost"
              isDisabled={!tempSelectedCohorts.length}
              onClick={() => setTempSelectCohorts([])}
            >
              <FormattedMessage
                id="dashboard.filters.clear.selection.button"
                values={{
                  n: tempSelectedCohorts.length,
                }}
              />
            </Button>
          </Stack>
        </MenuList>
      </Menu>
      <Button isLoading={isLoading} isDisabled={isSubmitDisabled} onClick={handleOnApply}>
        <FormattedMessage id="dashboard.filters.form-control.apply" />
      </Button>
    </HStack>
  )
}
