import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchBenchmarkProfiles } from './effects'
import { selectBenchmarkProfiles } from './selectors'

export const useRecruitingBenchmarkProfiles = () => {
  const dispatch = useDispatch()
  const benchmarkProfiles = useSelector(selectBenchmarkProfiles)

  useEffect(() => {
    dispatch(fetchBenchmarkProfiles())
  }, [dispatch])

  // we can say it is initializing if the array is empty, because there are default benchmark profiles even if the user hasn't created any, and users cannot delete them
  const isInitializing = benchmarkProfiles.length === 0

  return { benchmarkProfiles, isInitializing }
}
