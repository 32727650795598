import {
  Button,
  Icon,
  type LayoutProps,
  MenuButton,
  type MenuButtonProps,
} from '@chakra-ui/react'
import { type ReactElement } from 'react'
import { RiArrowDownSLine } from 'react-icons/ri'

export type CustomSelectButtonProps = {
  width?: LayoutProps['width']
  maxWidth?: LayoutProps['maxWidth']
  icon?: ReactElement
  onClick?: () => void
} & MenuButtonProps

export const CustomSelectButton = ({
  children,
  width,
  maxWidth,
  icon,
  onClick,
  ...props
}: CustomSelectButtonProps) => (
  <MenuButton
    onClick={onClick}
    as={Button}
    rightIcon={icon ?? <Icon as={RiArrowDownSLine} />}
    variant="unstyled"
    display="flex"
    pl={2}
    pr={2}
    color="gray.700"
    border="1px"
    borderColor="secondary-dark"
    width={width}
    maxWidth={maxWidth}
    sx={{
      '> span:first-of-type': {
        whiteSpace: 'nowrap',
        overflow: ' hidden',
        textOverflow: 'ellipsis',
      },
    }}
    {...props}
  >
    {children}
  </MenuButton>
)
