import types from './private/actions'

const findAndReplace = (items, data) => [
  ...items.map(item => (item.id === data.id ? { ...item, ...data } : item)),
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: Array<any> = []

// eslint-disable-next-line import/no-default-export
export default (state = initialState, { type, data }) => {
  switch (type) {
    case types.RECEIVE_QUESTIONNAIRES:
      return [...data]
    case types.CREATE_QUESTIONNAIRE_SUCCESS:
      return [...state, { ...data, questions: [] }]
    case types.RECEIVE_QUESTIONNAIRE:
      return state.find(q => q.id === data.id)
        ? findAndReplace(state, data)
        : [...state, data]
    case types.UPDATE_QUESTIONNAIRE:
      return findAndReplace(state, data)
    default:
      return state
  }
}
