import { type Analytics, logEvent } from 'firebase/analytics'

type EventParam = null | Record<string, unknown>

type EventParams = Record<string, EventParam>

type EventNames<T extends EventParams> = keyof T

type EventNamesWithoutParams<T extends EventParams> = keyof {
  [EventName in keyof T as T[EventName] extends null ? EventName : never]: T[EventName]
}

type EventNamesWithParams<T extends EventParams> = Exclude<
  keyof T,
  EventNamesWithoutParams<T>
>

export const createAnalyticsLogger = <T extends EventParams>(
  analytics: Analytics,
  eventPrefix: string
) => {
  function logAnalyticsEvent<EventName extends EventNamesWithoutParams<T>>(
    name: EventName
  ): void
  function logAnalyticsEvent<EventName extends EventNamesWithParams<T>>(
    name: EventName,
    params: T[EventName]
  ): void
  function logAnalyticsEvent<EventName extends EventNames<T>>(
    name: EventName,
    params?: T[EventName]
  ) {
    const eventName = `${eventPrefix}_${name as string}`

    // eslint-disable-next-line no-console
    console.log('Fired event:', { name: eventName, params })

    logEvent<EventNames<EventParams>>(analytics, eventName, params ?? undefined)
  }

  return { logAnalyticsEvent }
}
