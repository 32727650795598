import { Button } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useFormikContext } from 'formik'

import { type CoachingStepFormFields } from '../../../../../builder/steps/api'

export const StepFormButton = () => {
  const { submitForm, isValid, dirty, isSubmitting } =
    useFormikContext<CoachingStepFormFields>()

  return (
    <Button
      colorScheme="blue"
      mr={3}
      onClick={submitForm}
      isDisabled={!isValid || !dirty}
      isLoading={isSubmitting}
      position="absolute"
      bottom={0}
      right={0}
    >
      <FormattedMessage id="common.save" />
    </Button>
  )
}
