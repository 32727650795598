/* eslint-disable @typescript-eslint/no-explicit-any */
import types from '../../entities/questionnaires/types'

const initialState: {
  id: any
  questionnaireIds: Array<any>
} = {
  id: null,
  questionnaireIds: [],
}

// eslint-disable-next-line import/no-default-export
export default (state = initialState, { type, data }) => {
  switch (type) {
    case types.ON_RECEIVE_QUESTIONNAIRES:
      return {
        ...state,
        questionnaireIds: [...data.map(({ id }) => id)],
      }
    case types.ON_RECEIVE_QUESTIONNAIRE: {
      const questionnaireIds = [...state.questionnaireIds]
      const exists = questionnaireIds.find(({ id }) => id === data.id)

      if (!exists) {
        questionnaireIds.push(data.id)
      }

      return { ...state, questionnaireIds }
    }

    default:
      return state
  }
}
