import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { type SubmitHandler } from 'react-hook-form'

import { type Trainee, type TraineeFormValues, type TraineeStepKey } from '../types'
import { orderedTraineeSteps } from './shared/ordered-trainee-steps'

const isFormDataComplete = (
  data: Partial<TraineeFormValues>
): data is TraineeFormValues => [...orderedTraineeSteps].every(s => !!data[s])

type TraineeFormContextType = {
  formData: Partial<TraineeFormValues>
  currentStep: TraineeStepKey
  setCurrentStep: Dispatch<SetStateAction<keyof TraineeFormValues>>
  getSubmitHandler: <T extends TraineeStepKey>(
    stepKey: T
  ) => SubmitHandler<TraineeFormValues[T]>
  mode: 'create' | 'edit'
}

const TraineeFormContext = createContext<TraineeFormContextType | null>(null)

export type TraineeFormContextProviderProps = PropsWithChildren<{
  trainee?: Trainee
  onSubmit: (data: TraineeFormValues) => Promise<void>
}>

export const TraineeFormContextProvider = ({
  children,
  trainee,
  onSubmit,
}: TraineeFormContextProviderProps) => {
  const [currentStep, setCurrentStep] = useState<TraineeStepKey>('general')
  const [formData, setFormData] = useState<Partial<TraineeFormValues>>(
    trainee
      ? {
          general: { name: trainee.name, imageUrl: trainee.imageUrl },
          company: {
            department: trainee.department,
            industry: trainee.industry,
            workplace: trainee.workplace,
          },
          professional: {
            jobRole: trainee.jobRole,
            hierarchy: trainee.hierarchy,
            hasLeadershipResponsibility: trainee.hasLeadershipResponsibility
              ? 'yes'
              : 'no',
            seniority: trainee.seniority,
          },
        }
      : {}
  )

  const getSubmitHandler = useCallback<TraineeFormContextType['getSubmitHandler']>(
    step => async data => {
      const isLastStep = step === orderedTraineeSteps[orderedTraineeSteps.length - 1]

      if (isLastStep) {
        const finalFormData = { ...formData, [step]: data }

        if (!isFormDataComplete(finalFormData)) {
          // this is a sanity check, and should never happen
          // if it does, it's a bug
          throw new Error('formData is not complete for submitting')
        }

        return onSubmit(finalFormData)
      }

      setFormData(prev => ({ ...prev, [step]: data }))
      setCurrentStep(orderedTraineeSteps[orderedTraineeSteps.indexOf(step) + 1]!)
    },
    [formData, onSubmit]
  )

  const mode = useMemo(() => (trainee ? 'edit' : 'create'), [trainee])

  const value = useMemo<TraineeFormContextType>(
    () => ({
      currentStep,
      setCurrentStep,
      getSubmitHandler,
      formData,
      mode,
    }),
    [currentStep, getSubmitHandler, formData, mode]
  )

  return (
    <TraineeFormContext.Provider value={value}>{children}</TraineeFormContext.Provider>
  )
}

export const useTraineeFormContext = () => {
  const context = useContext(TraineeFormContext)

  if (!context) {
    throw new Error(
      'useTraineeFormContext must be used within a TraineeFormContextProvider'
    )
  }

  return context
}
