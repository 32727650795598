import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { isNil } from '@repo/utils'
import { useEffect, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'

import { useAuth } from '../authentication/auth-context'

type WithEmail = { email: string | null }

const getFirstLoginLocalStorageKey = (user: WithEmail): string =>
  `${user.email}_first-login`

const loginLocalStorageValue = 'newOrg'

export const setFirstLoginLocalStorageValue = (
  user: WithEmail,
  isNewOrganization: boolean
) =>
  window.localStorage.setItem(
    getFirstLoginLocalStorageKey(user),
    isNewOrganization ? loginLocalStorageValue : ''
  )

const getFirstLoginLocalStorageValue = (user: WithEmail) =>
  window.localStorage.getItem(getFirstLoginLocalStorageKey(user)) as
    | null
    | typeof loginLocalStorageValue

const removeFirstLoginLocalStorageKey = (user: WithEmail) =>
  window.localStorage.removeItem(getFirstLoginLocalStorageKey(user))

export const FirstLoginModal = () => {
  const { user } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [firstLoginKey, setFirstLoginKey] = useState<string | null>(null)

  useEffect(() => {
    if (!user) {
      return
    }

    const loginKey = getFirstLoginLocalStorageValue(user)

    if (isNil(loginKey)) {
      return
    }

    setFirstLoginKey(loginKey)
    onOpen()
  }, [user, onOpen])

  const handleOnClose = () => {
    if (user) {
      removeFirstLoginLocalStorageKey(user)
    }

    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalCloseButton />

        <ModalHeader mt={1} textAlign="center" fontSize="3xl">
          <Icon as={MdCheckCircle} boxSize="1.5em" color="green.300" mr={2} />
          <FormattedMessage id="onboarding.congratulation.title" />
        </ModalHeader>

        <ModalBody>
          <Text textAlign="center">
            <FormattedMessage id="onboarding.congratulation.description" />
          </Text>
          {firstLoginKey === 'newOrg' && (
            <Text textAlign="center" mt={4}>
              <FormattedMessage id="onboarding.congratulation.dummyData.info" />
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={handleOnClose} width="full" size="lg">
            <FormattedMessage id="onboarding.letsGO" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
