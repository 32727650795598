import { type FC } from 'react'

import { HorizontalStepper } from '../../components/horizontal-stepper'
import { CustomizationStep } from './manual-steps/customization-step'
import { DetailsStep } from './manual-steps/details-step'
import { DynamicsStep } from './manual-steps/dynamics-step'
import { ReviewStep } from './manual-steps/review-step'
import { useManualBuilderContext } from './shared/manual-builder-context'
import {
  type ManualBuilderStepKey,
  orderedManualBuilderSteps,
} from './shared/manual-builder-step-key'

const manualBuilderFormFieldComponents: Record<ManualBuilderStepKey, FC> = {
  dynamics: DynamicsStep,
  details: DetailsStep,
  customization: CustomizationStep,
  review: ReviewStep,
}

export const ManualSteps = () => {
  const { currentStep } = useManualBuilderContext()

  const FormFieldComponent = manualBuilderFormFieldComponents[currentStep]

  return (
    <>
      <HorizontalStepper
        currentStep={currentStep}
        stepsWithTitles={orderedManualBuilderSteps.map(step => ({
          key: step,
          titleKey: `scenario.manual.builder.steps.${step}`,
        }))}
        sx={{ px: 10, pb: 10, w: '550px' }}
      />
      <FormFieldComponent />
    </>
  )
}
