import { useIntl } from '@repo/i18n'

import { ImageWithFallback } from '../../../../components/image-with-fall-back'
import { useGoogleStorageAsset } from '../../../../hooks/use-google-storage-asset'
import { SteppedModalPreviewArea } from '../../stepped-modal-preview-area'
import { useTraineeFormContext } from './trainee-form-context'

export const TraineeFormPreview = () => {
  const { formData } = useTraineeFormContext()
  const imageHttpUrl = useGoogleStorageAsset(formData.general?.imageUrl)
  const { formatMessage } = useIntl()

  return (
    <SteppedModalPreviewArea
      imageContent={
        formData.general?.imageUrl ? (
          <ImageWithFallback width="full" height="full" src={imageHttpUrl} />
        ) : null
      }
      imagePlaceholderKey="scenario.builder.ai.steps.trainee.traineeModal.traineePreview"
      title={formData.general?.name}
      titlePlaceholderKey="common.name"
      propertyCards={[
        {
          labelKey: 'scenario.builder.ai.steps.trainee.formLabel.department',
          value: formData.company?.department,
        },
        {
          labelKey: 'common.industry',
          value: formData.company?.industry,
        },
        {
          labelKey: 'scenario.builder.ai.steps.trainee.formLabel.jobRole',
          value: formData.professional?.jobRole,
        },
        {
          labelKey: 'scenario.builder.ai.steps.trainee.formLabel.experience',
          value: formData.professional
            ? formatMessage({
                id: `common.amount.${formData.professional.seniority}` satisfies I18nKey,
              })
            : undefined,
        },
      ]}
    />
  )
}
