import { type ReactNode } from 'react'

const extractTextFromNode = (element: ReactNode): string => {
  if (typeof element === 'string') {
    return element
  }

  if (element && typeof element === 'object' && 'props' in element) {
    const { children } = element.props

    if (Array.isArray(children)) {
      return children.map(extractTextFromNode).join('')
    }

    return extractTextFromNode(children)
  }

  return ''
}

export const transformReactNodesToString = (nodes: string | Array<ReactNode>) => {
  if (typeof nodes === 'string') {
    return nodes
  }

  return nodes.reduce((acc: string, node) => {
    if (typeof node === 'string') {
      return acc + node
    }

    if (node && typeof node === 'object' && 'props' in node) {
      return acc + extractTextFromNode(node)
    }

    return acc
  }, '')
}
