import { Button, Flex } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useModal } from '@repo/ui'
import { MdOutlinePeopleOutline } from 'react-icons/md'

import { SharedProgramTabs } from '../../coaching/program-list/shared-program-tabs'
import { CenteredSpinner } from '../../components/centered-spinner'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'

export const SharedProgramsButton = () => {
  const { data: sharedProgramsData } = useOpenapiSWR('getSharedPrograms')
  const isInitializing = sharedProgramsData === undefined

  const [receivedProgramsModal, showReceivedProgramsModal] = useModal(
    () => (
      <Flex minH="180px" justifyContent="center">
        {isInitializing ? (
          <Flex>
            <CenteredSpinner />
          </Flex>
        ) : (
          <SharedProgramTabs sharedProgramsData={sharedProgramsData} type="receiver" />
        )}
      </Flex>
    ),
    {
      titleKey: 'coaching.programList.button.sharedPrograms',
      bodySx: { paddingBlockStart: '0' },
      modalProps: { size: 'xl' },
    }
  )

  return (
    <>
      <Button
        variant="outline"
        leftIcon={<MdOutlinePeopleOutline size="1.25em" />}
        iconSpacing="1"
        onClick={showReceivedProgramsModal}
      >
        <FormattedMessage id="coaching.programList.button.sharedPrograms" />
      </Button>
      {receivedProgramsModal}
    </>
  )
}
