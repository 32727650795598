import { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'

const defaultMapState = () => ({})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useThunkFetching = ({ action, mapState, deps }: any) => {
  const [fetching, setFetching] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>(null)
  const mappedStoreState = useSelector(mapState || defaultMapState)
  const dispatch = useDispatch()
  const store = useStore()

  const dispatchAction = async () => {
    setFetching(true)
    setError(null)

    try {
      const dispatchResult = dispatch(action(store.getState()))

      if (dispatchResult instanceof Promise) {
        await dispatchResult
      }
    } catch (err) {
      console.error(err)

      setError(err)
    }

    setFetching(false)
  }

  useEffect(() => {
    dispatchAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || [])

  return [fetching, mappedStoreState, error, dispatchAction]
}
