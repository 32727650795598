import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { Field, type FormikProps } from 'formik'
import { type PropsWithChildren } from 'react'

import { CenteredSpinner } from '../../components/centered-spinner'
import { useRecruitingBenchmarkProfiles } from '../../store/entities/benchmark-profiles/use-recruiting-benchmark-profiles'

type FormProps = FormikProps<{
  benchmarkProfileId: string
  name: string
}>

type BenchmarkProfile = {
  /* eslint-disable react/no-unused-prop-types */
  id: string
  title: string
  /* eslint-enable */
}

const FolderFormLabel = ({ children }: PropsWithChildren) => (
  <FormLabel fontWeight="400" fontSize="sm" color="gray.700">
    {children}
  </FormLabel>
)

export const FolderForm = ({ handleChange, values, errors, touched }: FormProps) => {
  const { benchmarkProfileId } = values
  const { formatMessage } = useIntl()
  // It does not make sense normally, to edit the target profile in "Edit/Create Folder", since
  // the list is bound to "dashboards" not "benchmark_profiles". But for now, we allow it, but
  // should be changed as soon as we have custom dashboards available.

  const { benchmarkProfiles, isInitializing } = useRecruitingBenchmarkProfiles()

  if (isInitializing) {
    return <CenteredSpinner />
  }

  return (
    <Stack spacing={2}>
      <FormControl isInvalid={!!errors.name && touched.name}>
        <FolderFormLabel>
          <FormattedMessage id="common.name" />
        </FolderFormLabel>
        <Input
          as={Field}
          label={formatMessage({
            id: 'common.name',
          })}
          name="name"
          placeholder={formatMessage({
            id: 'positions.form.field.title.placeholder',
          })}
        />
        <FormErrorMessage>{errors.name}</FormErrorMessage>
      </FormControl>

      <FormControl>
        <FolderFormLabel>
          <FormattedMessage id="positions.form.field.benchmarkProfile.label" />
        </FolderFormLabel>
        <Select
          name="benchmarkProfileId"
          onChange={handleChange}
          value={benchmarkProfileId}
        >
          {benchmarkProfiles.map(({ id, title }: BenchmarkProfile) => (
            <option key={id} value={id}>
              {title}
            </option>
          ))}
        </Select>
      </FormControl>
    </Stack>
  )
}
