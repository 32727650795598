import { Box, Button } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { type FieldArrayRenderProps, useFormikContext } from 'formik'
import { useCallback } from 'react'
import { RiAddFill } from 'react-icons/ri'

import { QuestionCard } from './question-card'
import { type FormValues, type Question } from './types'

const questionObj: Question = {
  active: true,
  text: '',
  note: '',
  repeatable: 0,
  timeLimit: 60,
  type: 'standard',
  order: 0,
}

export const QuestionList = ({ arrayProps }: { arrayProps: FieldArrayRenderProps }) => {
  const { push, replace, remove } = arrayProps
  const {
    values: { questions },
  } = useFormikContext<FormValues>()

  const handlePush = useCallback(() => {
    // Set `active` of previous questions to `false`.
    questions?.forEach((question, index) => {
      if (question.active) {
        replace(index, { ...question, active: false })
      }
    })

    push({ ...questionObj })
  }, [questions, replace, push])

  return (
    <>
      <Box pb={2}>
        <Button
          leftIcon={<RiAddFill size="1.25em" />}
          onClick={handlePush}
          minW="70px"
          float="right"
        >
          <FormattedMessage id="questionnaire.form.questionList.addBtn.label" />
        </Button>
      </Box>
      <Box mt={4}>
        {questions?.map((question, index) => (
          <QuestionCard
            handleRemove={remove}
            data={question}
            // eslint-disable-next-line react/no-array-index-key
            key={`question.${index}`}
            index={index}
          />
        ))}
      </Box>
    </>
  )
}
