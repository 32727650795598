import { IconButton, Select, type TableCellProps, Td, Tr } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { type ChangeEvent, useCallback, useMemo, useState } from 'react'
import { RiAddFill, RiDeleteBinLine } from 'react-icons/ri'
import { useDispatch } from 'react-redux'

import { useCurrentTokenData } from '../../hooks/use-current-token-data'
import {
  deleteMemberOrInvitation,
  updateMember,
} from '../../store/entities/organization/effects'
import {
  type OrganizationMemberRole,
  type OrganizationMemberUpdate,
} from '../../store/entities/organization/organization-types'
import {
  isStatusInvitationPending,
  isStatusMember,
} from '../../store/entities/organization/organization-utilities'
import { RoleEditorModal } from '../role-list/role-editor-modal'
import { type LoadingState, type MemberListRowProps } from './member-list-row-types'
import {
  isLoadingStateDelete,
  isLoadingStateMemberRoleChange,
  isLoadingStateRoleChange,
} from './member-list-row-utilities'
import { MemberRoles } from './member-roles'

const SelectContainer = styled.div`
  display: flex;
`

/**
 * Table cell vertically aligned.
 */
const TdV = (props: TableCellProps) => <Td verticalAlign="middle" {...props} />

export const MemberListRow = ({ member, roles }: MemberListRowProps) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const { email, role, memberRole } = member

  const { isOwner } = useCurrentTokenData()

  const noRoleText = useMemo(
    () => formatMessage({ id: 'members.role.noRole' }),
    [formatMessage]
  )

  // LOADING STATE
  const [loadingState, setLoadingState] = useState<LoadingState | null>(null)
  const isDeleting = useMemo(() => isLoadingStateDelete(loadingState), [loadingState])
  const isRoleChanging = useMemo(
    () => isLoadingStateRoleChange(loadingState),
    [loadingState]
  )

  const isMemberRoleChanging = useMemo(
    () => isLoadingStateMemberRoleChange(loadingState),
    [loadingState]
  )

  const isBusy = isDeleting || isRoleChanging || isMemberRoleChanging

  // DELETE
  const handleDelete = useCallback(async () => {
    setLoadingState('delete')
    await dispatch(deleteMemberOrInvitation(member))
    setLoadingState(null)
  }, [dispatch, member])

  // ROLE CHANGE
  const handleRoleChange = useCallback(
    async (r: OrganizationMemberRole) => {
      setLoadingState('role-change')
      const memberPayload: OrganizationMemberUpdate = { role: r }

      await dispatch(updateMember(member.id, memberPayload))
      setLoadingState(null)
    },
    [dispatch, member]
  )

  // MEMBER ROLE CHANGE
  const handleMemberRoleChange = useCallback(
    async ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
      setLoadingState('member-role-change')
      // TODO: We should update the API to accept nil values instead of '-'
      // in order to remove the memberRole
      const mRole = value || '-'
      const memberPayload: OrganizationMemberUpdate = { memberRole: mRole }

      await dispatch(updateMember(member.id, memberPayload))
      setLoadingState(null)
    },
    [dispatch, member]
  )

  const [showRoleModal, setShowRoleModal] = useState(false)

  return (
    <Tr>
      {showRoleModal && (
        <RoleEditorModal
          mode={{ type: 'create' }}
          onClose={() => setShowRoleModal(false)}
        />
      )}
      <TdV>
        <div>
          <div>
            <b>{email}</b>
          </div>
          {isStatusMember(member) && (
            <MemberRoles
              value={role}
              onChange={handleRoleChange}
              isLoading={isRoleChanging}
              isDisabled={isBusy}
            />
          )}
        </div>
      </TdV>
      <TdV>
        {isStatusMember(member) && (
          <SelectContainer>
            <Select
              placeholder={noRoleText}
              onChange={handleMemberRoleChange}
              value={memberRole}
              size="sm"
              borderRightRadius="none"
              width="auto"
              disabled={isBusy}
            >
              {roles.map(({ id: roleId, name }) => (
                <option key={roleId} value={roleId}>
                  {name}
                </option>
              ))}
            </Select>
            <IconButton
              aria-label="Create role"
              colorScheme="green"
              textColor="white"
              borderLeftRadius="none"
              icon={<RiAddFill size="1.25em" />}
              isLoading={isMemberRoleChanging}
              isDisabled={isBusy}
              onClick={() => setShowRoleModal(true)}
            />
          </SelectContainer>
        )}
        {isStatusInvitationPending(member) && (
          <FormattedMessage id="members.invite.sent" />
        )}
      </TdV>
      {isOwner && (
        <TdV>
          <IconButton
            aria-label="Remove"
            colorScheme="red"
            onClick={handleDelete}
            isLoading={isDeleting}
            isDisabled={isBusy}
            icon={<RiDeleteBinLine size="1.25em" />}
          />
        </TdV>
      )}
    </Tr>
  )
}
