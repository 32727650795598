import moment from 'moment'

import { type DashboardFilterDates, type DataSlotType } from './types'

export const createDashboardDateRange = (
  type: Extract<DataSlotType, 'lastWeek' | 'lastMonth'>
): DashboardFilterDates => ({
  startDate: moment()
    .utc()
    .subtract(1, type === 'lastWeek' ? 'week' : 'month')
    .startOf('day')
    .toDate(),
  endDate: moment().utc().startOf('day').toDate(),
})
