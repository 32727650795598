import { get } from 'lodash-es'
import { useEffect, useState } from 'react'

import { getCurrentUserTokenData } from '../utils/api'

export const useCurrentTokenData = () => {
  const [tokenData, setTokenData] = useState(null)

  useEffect(() => {
    getCurrentUserTokenData().then(setTokenData)

    return () => setTokenData(null)
  }, [])

  const isOwner = get(tokenData, 'role', null) === 'owner'

  return { isOwner, tokenData }
}
