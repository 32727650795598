import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'

import {
  type CoachingStep,
  deleteCoachingStep,
  saveCoachingStep,
  saveStepsOrder,
} from '../../builder/steps/api'
import {
  type DataLoaderResponse,
  type DataLoaderType,
  useDataLoader,
} from '../../builder/use-data-loader'

const coachingProgramApiUrl = `/admin/programs`

type StepsContextType =
  | undefined
  | (Omit<DataLoaderResponse<CoachingStep>, 'data' | 'save' | 'getById'> & {
      steps: Array<DataLoaderType<CoachingStep>>
      save: (
        uuid: string,
        data: RecordWithOptionalId<CoachingStep>
      ) => Promise<CoachingStep>
      programId: string
      trainingId: string
      scenarioId: string
      move: (id: string, toIndex: number, saveOrder?: boolean) => Promise<void>
      moveNewStep: (
        step: CoachingStep,
        toIndex: number,
        saveOrder?: boolean
      ) => Promise<void>
    })

const StepsContext = createContext<StepsContextType>(undefined)

export const useStepsNew = () => {
  const context = useContext(StepsContext)

  if (!context) {
    throw new Error('useStepsNew must be used within a StepsProviderNew')
  }

  return context
}

export const useStepNew = (uuid: string) => {
  const stepsCtx = useStepsNew()
  const { remove: removeStep, save: saveStep, steps, scenarioId } = stepsCtx

  const step = useMemo(
    () => steps.find(stepItem => stepItem.uuid === uuid),
    [uuid, steps]
  )

  const save = useCallback(
    async (stepData: CoachingStep) => {
      if (!step) {
        throw new Error('Step not found')
      }

      return saveStep(uuid, stepData)
    },
    [saveStep, step, uuid]
  )

  const remove = useCallback(async () => {
    if (!step || !uuid) {
      return Promise.reject(new Error('Step not found'))
    }

    return removeStep(uuid)
  }, [step, uuid, removeStep])

  return {
    step,
    save,
    remove,
    scenarioId,
  }
}

type StepsProviderProps = {
  programId: string
  trainingId: string
  scenarioId: string
}

export const StepsProviderNew = ({
  children,
  programId,
  trainingId,
  scenarioId,
}: PropsWithChildren<StepsProviderProps>) => {
  const stepsUrl = useMemo(
    () =>
      scenarioId && scenarioId !== 'create'
        ? `${coachingProgramApiUrl}/${programId}/trainings/${trainingId}/scenarios/${scenarioId}/steps`
        : null,
    [programId, scenarioId, trainingId]
  )

  const {
    data: steps,
    save,
    mutate,
    editingUuid,
    ...loaderResp
  } = useDataLoader<CoachingStep>(
    stepsUrl,
    _ => Promise.resolve({ order: steps.length, step: {} } as Omit<CoachingStep, 'id'>),
    async step => {
      const { id, ...dataToUpdate } = step

      return saveCoachingStep(programId, trainingId, scenarioId, dataToUpdate, id)
    },
    async id => {
      await deleteCoachingStep(programId, trainingId, scenarioId, id)
    }
  )

  const move = useCallback(
    async (id: string, toIndex: number, saveOrder?: boolean) => {
      const currentSteps = editingUuid
        ? steps.filter(({ uuid }) => uuid !== editingUuid)
        : steps

      const fromIndex = currentSteps.findIndex(t => t.uuid === id)
      const step1 = currentSteps[fromIndex]
      const step2 = currentSteps[toIndex]

      if (!step1 || !step2) {
        throw new Error('Order cannot be saved')
      }

      const stepsReorder = currentSteps.map((step, index, stepsList) => {
        if (index === fromIndex) {
          return stepsList[toIndex]?.data ?? step.data
        }

        if (index === toIndex) {
          return stepsList[fromIndex]?.data ?? step.data
        }

        return step.data
      }) as Array<CoachingStep>

      if (stepsReorder.filter(t => !t?.id).length !== 0) {
        throw new Error('You have unsaved steps, not present in the backend')
      }

      try {
        mutate(stepsReorder, false)
        if (saveOrder) {
          await saveStepsOrder(programId, trainingId, scenarioId, stepsReorder)
          mutate(stepsReorder)
        }
      } catch (err) {
        mutate(stepsReorder)
      }
    },
    [mutate, programId, scenarioId, steps, trainingId, editingUuid]
  )

  const moveNewStep = useCallback(
    async (step: CoachingStep, toIndex: number, saveOrder?: boolean) => {
      const newSteps = steps
        .filter(({ data }) => data.id)
        .map(({ data }) => data as CoachingStep)

      newSteps.splice(toIndex, 0, step)

      try {
        mutate(newSteps, false)
        if (saveOrder) {
          await saveStepsOrder(programId, trainingId, scenarioId, newSteps)
          mutate(newSteps)
        }
      } catch (err) {
        mutate(newSteps)
      }
    },
    [mutate, programId, scenarioId, steps, trainingId]
  )

  const value = useMemo(
    () => ({
      ...loaderResp,
      editingUuid,
      programId,
      mutate,
      trainingId,
      scenarioId,
      steps,
      save,
      move,
      moveNewStep,
    }),
    [
      loaderResp,
      editingUuid,
      programId,
      trainingId,
      scenarioId,
      steps,
      save,
      move,
      mutate,
      moveNewStep,
    ]
  )

  return <StepsContext.Provider value={value}>{children}</StepsContext.Provider>
}
