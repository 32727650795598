import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { FormattedMessage } from '@repo/i18n'

import { MemberList } from '../containers/member-list/member-list'
import { PageList } from '../containers/page-list'
import { RoleList } from '../containers/role-list/role-list'
import { AppHeaderTitle } from './app-header-title'
import { Gate } from './gate'

const flexColumnCss = css`
  display: flex;
  flex-direction: column;
`

const flexWithGrowCss = css`
  ${flexColumnCss}
  flex-grow: 1;
`

export const OrganizationTeam = () => (
  <PageList css={flexColumnCss}>
    <AppHeaderTitle formattedMessageId="organization.team.title" />

    <Tabs isLazy css={flexWithGrowCss}>
      <TabList>
        <Tab>
          <FormattedMessage id="organization.team.members.title" />
        </Tab>
        <Tab>
          <Gate>
            <FormattedMessage id="organization.team.roles.title" />
          </Gate>
        </Tab>
      </TabList>

      <TabPanels css={flexWithGrowCss}>
        <TabPanel css={flexWithGrowCss}>
          <MemberList />
        </TabPanel>
        <TabPanel css={flexWithGrowCss}>
          <RoleList />
        </TabPanel>
      </TabPanels>
    </Tabs>
  </PageList>
)
