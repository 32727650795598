import { Box, Center, Image } from '@chakra-ui/react'

import { colors } from '../theme/main'
import { Spinner } from './Spinner'

export const PersonaThumbnail = ({
  avatarPreviewUrl,
  bgUrl,
  bgColor,
  useGoogleStorageAsset,
}: {
  avatarPreviewUrl?: string | null
  bgUrl?: string | null
  bgColor?: string | null
  useGoogleStorageAsset: (mediaUrl: string | undefined | null) => string | undefined
}) => {
  const avatar = useGoogleStorageAsset(avatarPreviewUrl)
  const bg = useGoogleStorageAsset(bgUrl)

  return (
    <Center pos="relative" w="full" h="full" bg={bgColor ?? colors.blue[50]}>
      {!!bg && <Image src={bg} w="full" h="full" objectFit="cover" />}

      {!!avatarPreviewUrl && (
        <Box position="absolute" top={0} bottom={0} aspectRatio="1/1">
          <Image
            src={avatar}
            objectFit="cover"
            fallback={
              <Center w="full" h="full">
                <Spinner />
              </Center>
            }
          />
        </Box>
      )}
    </Center>
  )
}
