import { Box, Button, Heading, HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { MdCheckCircle } from 'react-icons/md'
import { Link as RouterLink } from 'react-router-dom'

export const VerifyEmail = () => (
  <Stack align="center" spacing={4} textAlign="center">
    <HStack color="primary-dark">
      <Heading as="h1" fontSize="2xl" fontWeight="medium">
        <Icon as={MdCheckCircle} color="#78e296" w={8} h={8} mr={2} />
        <FormattedMessage id="signup.verifyEmail.title.1" />
        <br />
        <FormattedMessage id="signup.verifyEmail.title.2" />
      </Heading>
    </HStack>
    <Box fontSize="sm" color="primary-dark">
      <Text>
        <FormattedMessage id="signup.verifyEmail.text.1" />
      </Text>
      <Text>
        <FormattedMessage id="signup.verifyEmail.text.2" />
      </Text>
      <Text>
        <FormattedMessage id="signup.verifyEmail.text.3" />
      </Text>
    </Box>
    <Button type="button" size="lg" width="full" as={RouterLink} to="/login">
      <FormattedMessage id="onboarding.backToLogin" />
    </Button>
    <Button
      type="button"
      variant="ghost"
      size="lg"
      width="full"
      as={RouterLink}
      to="/resend-email"
    >
      <FormattedMessage id="signup.verifyEmail.button.resendEmail" />
    </Button>
  </Stack>
)
