import {
  Box,
  Center,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { Tooltip } from '@repo/ui'
import { useEffect, useMemo, useState } from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import iconVideo from '../assets/icon-youtube.svg'

// eslint-disable-next-line react-hooks/rules-of-hooks
SwiperCore.use([Navigation])

type Question = {
  id: string
  text: string
  note: string
  order: number
}

type Video = {
  id: string
  previewUrl: string
  videoUrl: string
  question: Question
}

type VideoGalleryProps = {
  onSelect?: (video: Video) => void
  videos?: Array<Video>
}

export const VideoGallery = ({
  onSelect = () => null,
  videos = [],
}: VideoGalleryProps) => {
  const [canPlay, setCanPlay] = useState(false)
  const videosSorted = useMemo(
    () => [...videos].sort((a, b) => a.question.order - b.question.order),
    [videos]
  )

  const initialVideo = useMemo(() => videosSorted[0], [videosSorted])

  const [selectedVideo, setSelectedVideo] = useState<Video | typeof initialVideo>(
    initialVideo
  )

  const numberOfSlide = videos.length > 2 ? 4 : 2

  const handleSelect = (item: Video) => {
    if (selectedVideo?.id !== item.id) {
      setCanPlay(false)
      setSelectedVideo(item)
      onSelect(item)
    }
  }

  useEffect(() => {
    if (initialVideo && !selectedVideo) {
      setSelectedVideo(initialVideo)
    }
  }, [selectedVideo, initialVideo])

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Stack w="full" border="2px" borderColor="gray.200" borderRadius="md">
      <Flex pos="relative" align="center" justify="center" margin={4} marginBottom={0}>
        {selectedVideo ? (
          <Box>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              key={selectedVideo.id}
              src={selectedVideo.videoUrl}
              controls={canPlay}
              onCanPlay={() => setCanPlay(true)}
              style={{ marginBottom: '10px' }}
            />
            {selectedVideo.question && (
              <Box>
                <Text color="gray.500">
                  {`${selectedVideo.question.order + 1}/${videos.length} `}
                  <FormattedMessage id="Video.Gallery.Questions" />
                </Text>
                {selectedVideo.question?.note.startsWith('http') ? (
                  <Box>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      display="flex"
                      justify-content="space-between"
                      flex-direction="row"
                      fontWeight="bold"
                      onClick={onOpen}
                    >
                      <Box>
                        <img src={iconVideo} />
                      </Box>
                      <Box marginLeft="3px">
                        <Text color="#17459C">
                          {selectedVideo.question?.text || (
                            <FormattedMessage id="video.Gallery.video.question" />
                          )}
                        </Text>
                      </Box>
                    </Link>
                    <Modal isOpen={isOpen} onClose={onClose}>
                      <ModalOverlay />
                      <ModalContent height="350">
                        <ModalHeader>
                          {selectedVideo.question?.text || (
                            <FormattedMessage id="video.Gallery.video.question" />
                          )}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                          <video width="100%" height="100%" controls>
                            <source src={selectedVideo.question?.note} />
                          </video>
                        </ModalBody>
                      </ModalContent>
                    </Modal>
                  </Box>
                ) : (
                  <Box>
                    <Text fontWeight="bold">{selectedVideo.question?.text}</Text>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        ) : (
          'No video selected.'
        )}
        {!canPlay && (
          <Box pos="absolute" left="50%" top="50%" transform="translate(-50%)">
            <Spinner thickness="5px" />
          </Box>
        )}
      </Flex>
      {videos.length > 1 && (
        <Swiper slidesPerView={numberOfSlide} navigation style={{ width: '100%' }}>
          {videosSorted.map(item => {
            const isSelected = item.id === selectedVideo?.id

            return (
              <SwiperSlide key={item.id}>
                <Tooltip
                  openDelay={500}
                  label={
                    item.question?.text || (
                      <FormattedMessage id="video.Gallery.video.question" />
                    )
                  }
                >
                  <Box
                    key={item.id}
                    pos="relative"
                    cursor="pointer"
                    transition="all .15s ease"
                    _hover={{
                      transform: 'scale(0.95)',
                      opacity: isSelected ? 1 : 0.6,
                    }}
                    onClick={() => handleSelect(item)}
                    role="group"
                  >
                    <Box background={isSelected ? 'white' : '#E4EFFF'}>
                      <Center>
                        <Image
                          p={numberOfSlide === 2 ? '20px' : '5px'}
                          src={item.previewUrl}
                        />
                      </Center>
                      <Center>
                        <Text
                          noOfLines={1}
                          wordBreak="break-all"
                          color={isSelected ? 'black' : 'gray.500'}
                          p="5px"
                        >
                          {item.question?.text || (
                            <FormattedMessage id="video.Gallery.video.question" />
                          )}
                        </Text>
                      </Center>
                    </Box>
                  </Box>
                </Tooltip>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </Stack>
  )
}
