import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { isNotNil, passwordSchemaValidator } from '@repo/utils'
import { Form, Formik } from 'formik'
import { debounce } from 'lodash-es'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { setFirstLoginLocalStorageValue } from '../components/first-login-modal'
import { useErrorHandler } from '../error-handling/use-error-handler'
import { useInvitation } from '../invitation/invitation-api'
import { api } from '../utils/api'
import { login, sendEmailVerification, signup } from './authentication'
import { BackToLogin } from './back-to-login'
import { SignupForm, type SignupFormValue } from './signup-form'

const checkForSpecialChars = /^[a-zA-Z0-9\-_.]*$/g

const validateOrganizationName = debounce(
  async (value: string, resolve: (val: boolean) => void) => {
    const org = await api.organizations.getByName(value)

    resolve(!org)
  },
  1000
)

export const Signup = () => {
  const navigate = useNavigate()
  const { invitationId } = useParams<{ invitationId?: string }>()

  const { translate } = useErrorHandler()

  const { data: invitation, error: invitationError } = useInvitation(invitationId)
  const { invitationEmail, organization } = invitation ?? {}
  const validationSchema: yup.SchemaOf<SignupFormValue> = useMemo(
    () =>
      yup.object().shape({
        ...passwordSchemaValidator,
        firstName: yup.string().required('signup.form.field.firstName.error.required'),
        lastName: yup.string().required('signup.form.field.lastName.error.required'),
        organizationName: invitationId
          ? yup.string().required('signup.form.field.organizationName.error.required')
          : yup
              .string()
              .matches(
                checkForSpecialChars,
                'signup.form.field.organizationName.error.invalid'
              )
              .test(
                'checkDuplicateOrganizationName',
                'signup.form.field.organizationName.error.exist',
                async value =>
                  value?.match(checkForSpecialChars)
                    ? new Promise(resolve => {
                        validateOrganizationName(value, resolve)
                      })
                    : true
              )
              .required('signup.form.field.organizationName.error.required'),
        email: yup
          .string()
          .email('signup.form.field.email.error.invalid')
          .required('signup.form.field.email.error.required'),
        contactConsent: yup.boolean().default(false),
        legalConsent: yup
          .boolean()
          .required('signup.form.field.legalConsent.error.required')
          .oneOf([true], 'signup.form.field.legalConsent.error.required'),
      }),
    [invitationId]
  )

  const isInvitationInexistent = Boolean(
    invitationId && invitationError?.status === 'NOT_FOUND'
  )

  const handleSubmit = async (value: SignupFormValue) => {
    try {
      await signup({
        ...value,
        invitationId,
        organizationName: value.organizationName,
      })

      const { user } = await login(value.email, value.password)

      // Used to display a "congratulation" modal after logging in
      // for the first time
      const isNewOrganization = !invitationId

      setFirstLoginLocalStorageValue(user, isNewOrganization)

      if (!user.emailVerified) {
        await sendEmailVerification(user)
        navigate('/verify-email')
      } else if (user.emailVerified) {
        navigate('/')
      }
    } catch (error) {
      showToast({ status: 'error', message: translate(error) })
    }
  }

  return (
    <>
      <BackToLogin />

      {isInvitationInexistent ? (
        <Text fontSize="lg" mt={5}>
          <FormattedMessage id="signup.form.invite.missing" />
        </Text>
      ) : (
        <>
          <Heading
            mt={5}
            as="h1"
            fontSize="x-large"
            color="primary-dark"
            textAlign="center"
            fontWeight="medium"
          >
            <FormattedMessage id="signup.form.title" />
          </Heading>
          <Text mt={5} mb={8} fontSize="sm" color="primary-dark" textAlign="center">
            <FormattedMessage id="signup.form.subtitle" />
          </Text>
          <Formik<SignupFormValue>
            initialValues={{
              firstName: '',
              lastName: '',
              organizationName: organization?.name || '',
              email: invitationEmail ?? '',
              password: '',
              contactConsent: false,
              legalConsent: false,
            }}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({ isSubmitting }) => (
              <VStack as={Form} noValidate spacing={5}>
                <SignupForm hasInvitation={isNotNil(invitation)} />

                <Text fontSize="sm" color="gray.400" ml={1}>
                  <FormattedMessage id="signup.form.field.itemsRequired" />
                </Text>

                <Box w="full">
                  <Button
                    type="submit"
                    size="lg"
                    width="full"
                    mt={5}
                    isLoading={isSubmitting}
                  >
                    <FormattedMessage id="signup.form.button.submit" />
                  </Button>
                </Box>
              </VStack>
            )}
          </Formik>
        </>
      )}
    </>
  )
}
