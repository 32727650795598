/* eslint-disable @typescript-eslint/no-explicit-any */
import { Log } from '@repo/utils'
import { parse } from 'qs'

import config from '../config'
import { getCurrentToken } from './swr-fetcher'

/**
 * A simple function to transform a query to a string (required for Sentry).
 *
 * { foo: 'bar', sort: 'desc' } -> "foo:bar,sort:desc"
 *
 * @param {*} query
 * @returns {string}
 */
const transformQuery = (query = {}) =>
  Object.entries(query)
    .reduce((output, [key, val]) => {
      output.push(`${key}:${val}`)

      return output
    }, [] as any)
    .join(',')

const makeParam = (key, val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`

const renderQueryValues = (key, value) =>
  Array.isArray(value)
    ? value.map(val => makeParam(key, val)).join('&')
    : makeParam(key, value)

const getDataFromToken = token => {
  if (typeof token !== 'string') {
    return null
  }

  const base64Data = token.split('.')[1]

  return base64Data ? JSON.parse(window.atob(base64Data)) : null
}

export const getCurrentUserTokenData = () => getCurrentToken().then(getDataFromToken)

const request = async (endpoint, { list, isFetchingBinaryData, ...params }: any) => {
  const fetchOptions = { ...params }
  const token = await getCurrentToken()
  const hasUrlToken = Boolean(parse(window.location.search)?.['?token'])

  const defaultHeaders: any = {
    'Content-Type': 'application/json; charset=utf-8',
  }

  let query = ''

  if ((params.method && params.method === 'GET' && params.query) || hasUrlToken) {
    query += '?'

    if (params.query) {
      query += Object.entries(params.query)
        .map(([key, value]) => renderQueryValues(key, value))
        .join('&')
    }

    // Tokens are generally not applied programmatically, but by the user via the URL.
    // So it makes sense to make an exception and apply it regardless of `query.params`.
    if (hasUrlToken) {
      query += `${query === '?' ? '' : '&'}token=${token}`
    }
  }

  if (fetchOptions.body) {
    fetchOptions.body = JSON.stringify(fetchOptions.body)
  }

  if (token && !hasUrlToken) {
    defaultHeaders.Authorization = `Bearer ${token}`
  }

  fetchOptions.headers = {
    ...defaultHeaders,
    ...fetchOptions.headers,
  }

  const fetchUrl = `${config.apiBaseUrl}${endpoint}${query}`
  const res = await fetch(fetchUrl, fetchOptions)

  if (res.ok) {
    if (res.status === 204) {
      return undefined
    }

    if (isFetchingBinaryData) {
      return res.blob()
    }

    const json = await res.json()

    if (list && Array.isArray(json)) {
      return {
        sortBy: res.headers.get('retorio-sorted-by'),
        sortDirection: res.headers.get('retorio-sort-direction'),
        start: Number.parseInt(res.headers.get('retorio-list-start') as any, 10),
        limit: Number.parseInt(res.headers.get('retorio-list-limit') as any, 10),
        total: Number.parseInt(res.headers.get('retorio-total-count') as any, 10),
        items: json,
      }
    }

    return json
  }

  const functionName = endpoint.split('/')[1]

  Log.error(new Error(`API error, ${functionName}, ${res.status}`), {
    extra: {
      query: transformQuery(fetchOptions.query),
      method: fetchOptions.method,
      token,
      url: res.url,
      status: res.status,
      type: res.type,
      requestBody: fetchOptions.body || null,
    },
  })

  const json = await res.json()

  // usually error message is in this json
  // our backend returns json for erroneous cases as well
  const error = json != null ? json : res.toString()

  console.error('API returned error response:', {
    fetchUrl,
    fetchOptions,
    error,
  })

  throw error
}

export const api = {
  invitations: {
    get: id => request(`/invitations/${id}`, { method: 'GET' }),
  },
  reports: {
    analysis_results: {
      get: query =>
        request('/reports/analysis_results', {
          method: 'GET',
          query,
          isFetchingBinaryData: true,
        }),
    },
  },
  folders: {
    create: body => request('/folders', { method: 'POST', body }),
    delete: id => request(`/folders/${id}`, { method: 'DELETE' }),
    getAll: () => request('/folders', { method: 'GET' }),
    update: (id, body) => request(`/folders/${id}`, { method: 'PATCH', body }),
  },
  organizations: {
    create: body => request('/organizations', { method: 'POST', body }),
    get: () => request('/organizations/current', { method: 'GET' }),
    getByName: name => request(`/organizations/data/${name}`, { method: 'GET' }),
    update: values => request('/organizations/current', { method: 'PUT', body: values }),
    updateGeneralSettings: values =>
      request('/organizations/current/general_settings', { method: 'PUT', body: values }),
    updateProgramNotificationsFlag: body =>
      request('/organizations/update_program_notifications_flag', {
        method: 'PUT',
        body,
      }),
    updateNotificationSettings: body =>
      request('/organizations/current/notification_settings', {
        method: 'PATCH',
        body,
      }),
    invitations: {
      create: body =>
        request('/organizations/current/invitations', { method: 'POST', body }),
      delete: id =>
        request(`/organizations/current/invitations/${id}`, {
          method: 'DELETE',
        }),
    },
    members: {
      delete: id => request(`/organizations/current/members/${id}`, { method: 'DELETE' }),
      update: (id, body) =>
        request(`/organizations/current/members/${id}`, {
          method: 'PATCH',
          body,
        }),
    },
  },
  plugins: {
    getAll: () => request('/plugins', { method: 'GET' }),
    getById: id => request(`/plugins/data/${id}`, { method: 'GET' }),
    update: (id, body) => request(`/plugins/${id}`, { method: 'PATCH', body }),
    notifications: {
      getAll: pluginId =>
        request(`/plugins/${pluginId}/notifications`, { method: 'GET' }),
      update: (pluginId, body) =>
        request(`/plugins/${pluginId}/notifications`, {
          method: 'PATCH',
          body,
        }),
    },
  },
  recordings: {
    get: (id, query) => request(`/recordings/${id}`, { method: 'GET', query }),
    getAll: query => request('/recordings', { method: 'GET', query }),
    // swr provides the query already stringified in the url
    getList: urlWithFilters => request(urlWithFilters, { method: 'GET', list: true }),
    getToken: id => request(`/recordings/${id}/token`, { method: 'GET' }),
    deleteRecording: id => request(`/recordings/${id}`, { method: 'DELETE' }),
    share: id => request(`/recordings/${id}/tokens`, { method: 'POST' }),
    move: (id, body) => request(`/recordings/${id}`, { method: 'PATCH', body }),
  },
  users: {
    create: body => request('/users', { method: 'POST', body }),
    updateCurrent: body => request('/users/current', { method: 'PATCH', body }),
    resetPassword: body => request('/users/reset_password', { method: 'POST', body }),
    requestResetPassword: body =>
      request('/users/request_password_reset', { method: 'POST', body }),
    createCustomToken: () => request('/users/custom_token', { method: 'POST' }),
    getUserInfo: body => request(`/users/get_user_info`, { method: 'POST', body }),
    logout: () => request('/users/logout', { method: 'POST' }),
  },
  questionnaires: {
    get: id => request(`/questionnaires/${id}`, { method: 'GET' }),
    getAll: () => request('/questionnaires', { method: 'GET' }),
    getQuestions: id => request(`/questionnaires/${id}/questions`, { method: 'GET' }),
    create: body => request('/questionnaires', { method: 'POST', body }),
    update: (id, body) => request(`/questionnaires/${id}`, { method: 'PATCH', body }),
  },
  assessmentPrograms: {
    inviteParticipant: (id, body) =>
      request(`/assessment_programs/${id}/participants`, { method: 'POST', body }),
  },
  benchmarkProfiles: {
    create: body => request('/benchmark_profiles', { method: 'POST', body }),
    update: (id, body) => request(`/benchmark_profiles/${id}`, { method: 'PATCH', body }),
    getAll: () => request('/benchmark_profiles', { method: 'GET' }),
  },
  roles: {
    create: body => request('/roles', { method: 'POST', body }),
    update: (id, body) => request(`/roles/${id}`, { method: 'PATCH', body }),
    getAll: () => request('/roles', { method: 'GET' }),
    delete: id => request(`/roles/${id}`, { method: 'DELETE' }),
  },
  dashboard: {
    get: query =>
      request(`/coaching_statistics`, {
        method: 'GET',
        query,
      }),
  },
  userActivitySessions: {
    create: () => request('/user_activity_sessions', { method: 'POST' }),
  },
}

export const apiRequest = request
