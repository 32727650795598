import { IconButton, Text, useDisclosure } from '@chakra-ui/react'
import { Menu, MenuItem } from '@mui/material'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, useConfirmationModal } from '@repo/ui'
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { type ReactNode, useState } from 'react'
import { RiMoreFill } from 'react-icons/ri'

import { AddCohortParticipantModal } from './add-cohort-participant-modal'
import { CohortParticipantStatusModal } from './cohort-participant-status-modal'

interface CohortParticipantRowMenuProps {
  deleteParticipant: (userId: string) => Promise<void>
  updateParticipantsStatus?: (
    userId: string,
    isDisabled: boolean,
    cohortId: string
  ) => Promise<void>
  removeParticipantFromCohort?: (userId: Array<string>, cohortId: string) => Promise<void>
  reAddEmailParticipant?: (userEmail: string, cohortId: string) => Promise<void>
  modalLabel?: ReactNode
  modalTitle?: ReactNode
  modalDescription?: ReactNode
  userId: string
  userEmail?: string
  isDisabled?: boolean
  cohortId: string
  removed?: boolean
  isCohortEmail?: boolean
}

export const CohortParticipantRowMenu = ({
  deleteParticipant,
  updateParticipantsStatus,
  removeParticipantFromCohort,
  reAddEmailParticipant,
  modalLabel,
  modalTitle,
  modalDescription,
  userId,
  userEmail,
  isDisabled,
  cohortId,
  removed,
  isCohortEmail,
}: CohortParticipantRowMenuProps) => {
  const { formatMessage } = useIntl()
  const cohortParticipantStatusModal = useDisclosure()
  const addCohortParticipantModal = useDisclosure()
  const [_isRemoveFromCohortButtonLoading, setIsRemoveFromCohortButtonLoading] =
    useState(false)

  const [
    _isDeleteFromOrganizationButtonLoading,
    setIsDeleteFromOrganizationButtonLoading,
  ] = useState(false)

  const hasModalDetails = modalLabel && modalTitle && modalDescription

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `participant-menu-${userId}`,
  })

  const handleRemoveFromCohort = async () => {
    setIsRemoveFromCohortButtonLoading(true)
    try {
      if (removeParticipantFromCohort) {
        await removeParticipantFromCohort([userId], cohortId)
      }
    } finally {
      setIsRemoveFromCohortButtonLoading(false)
    }
  }

  const handleDeleteParticipant = async () => {
    setIsDeleteFromOrganizationButtonLoading(true)
    try {
      await deleteParticipant(userId)
    } finally {
      setIsDeleteFromOrganizationButtonLoading(false)
    }
  }

  const [confirmRemoveModal, showConfirmRemoveModal] = useConfirmationModal({
    onConfirm: handleRemoveFromCohort,
    confirmButtonKey: 'cohort.participant.delete.confirmModal.cta',
    title: modalTitle,
    description: modalDescription,
  })

  const [confirmDeleteModal, showConfirmDeleteModal] = useConfirmationModal({
    onConfirm: handleDeleteParticipant,
    confirmButtonKey: 'common.delete',
    title: formatMessage({ id: 'cohort.participant.delete.modal.title' }),
    description: formatMessage({ id: 'cohort.participant.delete.modal.description' }),
    isDestructive: true,
  })

  return (
    <>
      <IconButton
        variant="ghost"
        icon={<RiMoreFill />}
        aria-label="menu"
        {...bindTrigger(popupState)}
      />
      <Menu
        {...bindMenu(popupState)}
        MenuListProps={{
          onClick: e => e.stopPropagation(),
        }}
      >
        {cohortId !== null && isDisabled !== undefined && (
          <MenuItem
            onClick={() => {
              popupState.close()
              cohortParticipantStatusModal.onOpen()
            }}
          >
            <Text>
              {isDisabled ? (
                <FormattedMessage id="cohort.participant.updateStatus.confirmModal.enable" />
              ) : (
                <FormattedMessage id="cohort.participant.updateStatus.confirmModal.disable" />
              )}
            </Text>
          </MenuItem>
        )}
        {cohortId !== null && reAddEmailParticipant && removed && userEmail && (
          <MenuItem
            onClick={() => {
              popupState.close()
              addCohortParticipantModal.onOpen()
            }}
          >
            <Text>
              <FormattedMessage id="cohort.participant.reAdd.participant.confirmModal.button" />
            </Text>
          </MenuItem>
        )}
        {removeParticipantFromCohort && !removed && hasModalDetails && (
          <MenuItem
            onClick={() => {
              popupState.close()
              showConfirmRemoveModal()
            }}
          >
            <Text>{modalLabel}</Text>
          </MenuItem>
        )}

        {!isCohortEmail && (
          <MenuItem
            sx={{ color: colors.indicator['red-dark'] }}
            onClick={() => {
              popupState.close()
              showConfirmDeleteModal()
            }}
          >
            <Text>
              <FormattedMessage id="common.delete" />
            </Text>
          </MenuItem>
        )}
      </Menu>

      {confirmRemoveModal}
      {confirmDeleteModal}

      {cohortParticipantStatusModal.isOpen && (
        <CohortParticipantStatusModal
          isDisabled={isDisabled}
          isOpen={cohortParticipantStatusModal.isOpen}
          onClose={cohortParticipantStatusModal.onClose}
          onConfirm={async () => {
            const updatedStatus = !isDisabled

            if (updateParticipantsStatus) {
              await updateParticipantsStatus(userId, updatedStatus, cohortId)
            }
          }}
        />
      )}

      <AddCohortParticipantModal
        isOpen={addCohortParticipantModal.isOpen}
        onClose={addCohortParticipantModal.onClose}
        onConfirm={async () => {
          if (reAddEmailParticipant && userEmail) {
            await reAddEmailParticipant(userEmail, cohortId)
          }
        }}
      />
    </>
  )
}
