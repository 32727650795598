import { combineReducers } from 'redux'

import folderEditor from './folder-editor'
import pluginEditor from './plugin-editor'
import sidebar from './sidebar'

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  folderEditor,
  pluginEditor,
  sidebar,
})
