import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

export const MissingGDPR = ({ onClose }: { onClose: () => void }) => (
  <Modal onClose={onClose} isOpen isCentered>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <FormattedMessage id="plugin.modal.gdpr.missing.title" />
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={4}>
        <FormattedMessage id="plugin.modal.gdpr.missing" />
      </ModalBody>
    </ModalContent>
  </Modal>
)
