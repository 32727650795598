import { Button, Center, HStack, Stack, Switch, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { usePromisingModal } from '@repo/ui'
import { useState } from 'react'

import { DashboardInfoBox } from '../../../dashboard/dashboard-page/dashboard-router/dashboard-overview/shared/dashboard-info-box'
import { useOrganization } from '../../../hooks/use-organization'

export const useShouldNotifyModal = () => {
  const { hasProgramNotificationsEnabled } = useOrganization()
  const [shouldNotify, setShouldNotify] = useState(() => !!hasProgramNotificationsEnabled)

  const modal = usePromisingModal<boolean>(
    ({ resolve }) => (
      <Stack gap={8}>
        <FormattedMessage id="action.bar.publish.modal.header.description" />

        <Stack>
          <Text fontWeight="bold">
            <FormattedMessage id="action.bar.publish.modal.subtitle" />
          </Text>
          <Stack>
            <FormattedMessage id="action.bar.publish.modal.question" />

            <HStack>
              <Switch
                size="sm"
                isChecked={shouldNotify}
                onChange={() => setShouldNotify(!shouldNotify)}
              />
              <Text>
                <FormattedMessage id={shouldNotify ? 'common.yes' : 'common.no'} />
              </Text>
            </HStack>
          </Stack>
        </Stack>

        <DashboardInfoBox sx={{ fontSize: 'sm', lineHeight: 'short' }}>
          <FormattedMessage id="action.bar.publish.modal.alert" />
        </DashboardInfoBox>

        <Center>
          <Button onClick={() => resolve(shouldNotify)}>
            <FormattedMessage id="common.publish" />
          </Button>
        </Center>
      </Stack>
    ),
    {
      modalProps: { size: 'lg' },
      titleKey: 'action.bar.publish.modal.header.title',
      onOpen: () => setShouldNotify(!!hasProgramNotificationsEnabled),
    }
  )

  return modal
}
