import { Select } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { NotificationIntervals } from '../../store/entities/plugins/constants'

export const IntervalSelector = ({ index, interval, disabled, onChange }) => {
  const handleChange = e => {
    onChange(index, { interval: e.target.value })
  }

  return (
    <Select isDisabled={disabled} onChange={handleChange} value={interval}>
      {Object.values(NotificationIntervals).map(value => (
        <option key={value} value={value} selected={value === interval}>
          {value}
        </option>
      ))}
    </Select>
  )
}

IntervalSelector.propTypes = {
  index: PropTypes.number.isRequired,
  interval: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

IntervalSelector.defaultProps = {
  disabled: false,
}
