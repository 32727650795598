import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Select,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { Field, useFormikContext } from 'formik'
import { type PropsWithChildren, useMemo } from 'react'
import { RiListCheck2 } from 'react-icons/ri'
import { v4 as generateUuid } from 'uuid'

import { InputControl } from '../../../../../builder/components/form-controls/input-control'
import { TextareaControl } from '../../../../../builder/components/form-controls/textarea-control'
import { VideoPicker } from '../../../../../builder/components/video-picker'
import {
  getDefaultPoints,
  type QuestionType,
  type QuizStepFormFields,
  type QuizType,
} from '../../../../../builder/steps/api'
import { ImageOptions } from './quiz-step/image-options'
import { TextOptions } from './quiz-step/text-options'
import { VideoOptions } from './quiz-step/video-options'
import { StepFormButton } from './step-form-button'
import { useVideoUploader } from './use-video-uploader'

interface QuizStepProps {
  disabled: boolean
}

const questionTypes: Array<QuestionType> = ['text', 'textVideo']

const quizTypes: Array<QuizType> = [
  'singleChoice',
  'multiChoice',
  'videoComparison',
  'imageRegion',
]

export const QuizStep = ({ disabled, children }: PropsWithChildren<QuizStepProps>) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<{
    step: QuizStepFormFields
    order: number
  }>()

  const { step } = values

  if (step.stepType !== 'coachingStepQuiz') {
    throw new Error('Wrong step type')
  }

  const isDisabled = useMemo(() => !!step.id || disabled, [step.id, disabled])
  const { formatMessage } = useIntl()
  const {
    clearError,
    handleVideoUpload,
    error,
    setVideoUrlFormValue,
    loading,
    videoHttpUrl,
  } = useVideoUploader('step.questionVideo', undefined, isDisabled)

  const handleChange = event => {
    const quizType = event.target.value as QuizType

    setFieldValue('step.quizType', quizType)

    if (quizType === 'videoComparison') {
      setFieldValue('step.optionsData', [
        { id: generateUuid(), isCorrect: true, videoUrl: '' },
        { id: generateUuid(), isCorrect: false, videoUrl: '' },
      ])
    } else if (quizType === 'imageRegion') {
      setFieldValue('step.optionsData', [
        {
          id: generateUuid(),
          isCorrect: true,
          imageRegion: getDefaultPoints(),
        },
      ])
    } else {
      setFieldValue('step.optionsData', [
        { id: generateUuid(), isCorrect: true, text: '' },
        { id: generateUuid(), isCorrect: false, text: '' },
      ])
    }
  }

  return (
    <VStack w="full" py={3}>
      <Box bg="white" p={4} w="80%">
        <HStack mb={9}>
          <Icon as={RiListCheck2} size="48px" />
          <Heading as="h3" size="md">
            <FormattedMessage id="step.quiz.form.title" />
          </Heading>
        </HStack>
        <Box mb="8">
          <FormControl mb="4">
            <FormLabel>
              <FormattedMessage id="step.quiz.form.questionType.label" />
            </FormLabel>

            <Field
              as={Select}
              onChange={event => setFieldValue('step.questionType', event.target.value)}
              rounded="md"
              name="step.questionType"
              disabled={disabled}
            >
              {questionTypes.map(key => (
                <option key={key} value={key}>
                  {formatMessage({
                    id: `step.quiz.form.questionType.${key}` satisfies I18nKey,
                  })}
                </option>
              ))}
            </Field>
          </FormControl>
          <FormControl
            isInvalid={!!errors?.step?.questionText && touched?.step?.questionText}
          >
            <InputControl
              name="step.questionText"
              label={formatMessage({ id: 'step.quiz.form.questionText.label' })}
              labelSize="sm"
              bg="white"
              placeholder={formatMessage({
                id: 'step.quiz.form.questionText.placeholder',
              })}
              disabled={disabled}
            />
          </FormControl>
          {step.questionType === 'textVideo' && (
            <FormControl mt="4">
              <FormLabel fontSize="sm">
                <FormattedMessage id="step.quiz.form.questionVideo.label" />
              </FormLabel>
              <VideoPicker
                name="textVideo"
                clearError={clearError}
                handleVideoUpload={handleVideoUpload}
                error={error}
                isDisabled={disabled}
                buttonText={formatMessage({
                  id: 'common.upload',
                })}
                setVideoUrlFormValue={setVideoUrlFormValue}
                loading={loading}
                videoHttpUrl={videoHttpUrl}
              />
            </FormControl>
          )}
        </Box>

        <Box mb="8">
          <FormControl mb="4">
            <FormLabel>
              <FormattedMessage id="step.quiz.form.answerType.label" />
            </FormLabel>

            <Field
              as={Select}
              onChange={handleChange}
              disabled={isDisabled}
              rounded="md"
              name="step.quizType"
            >
              {quizTypes.map(key => (
                <option key={key} value={key}>
                  {formatMessage({
                    id: `step.quiz.form.answerType.${key}` satisfies I18nKey,
                  })}
                </option>
              ))}
            </Field>
          </FormControl>
          {step.quizType === 'videoComparison' && <VideoOptions isDisabled={disabled} />}

          {(step.quizType === 'multiChoice' || step.quizType === 'singleChoice') && (
            <TextOptions readOnly={disabled} disabled={isDisabled} />
          )}
          {step.quizType === 'imageRegion' && <ImageOptions readOnly={disabled} />}
        </Box>
        <Box mb="8">
          <TextareaControl
            label={formatMessage({ id: 'step.quiz.form.feedback.label' })}
            subLabel={formatMessage({ id: 'step.quiz.form.feedback.subLabel' })}
            bg="white"
            mt={2}
            name="step.feedback"
            disabled={disabled}
          />
        </Box>

        {children}
      </Box>
      <StepFormButton />
    </VStack>
  )
}
