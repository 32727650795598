import { Button, Icon, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { useEffect } from 'react'
import { BsInbox } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

import { ContentLoader } from '../../../builder/components/content-loader'
import { useCohorts } from '../../../coaching-participants/use-cohorts'
import { CohortSelectScreen } from './cohort-select-screen'

type ParticipantsScreenWrapperProps = {
  handleContinue: VoidFunction
  handleCancel: VoidFunction
  selectedCohort: string | undefined
  setSelectedCohort: (cohort: string) => void
}

const NoCohortState = ({ openCohortCreation }: { openCohortCreation: () => void }) => (
  <Stack align="center" justify="center" h="full" spacing={8}>
    <Icon as={BsInbox} color={colors.gray[400]} fontSize={24} boxSize="1.25em" />
    <Text align="center">
      <FormattedMessage id="coaching.benchmarking.wizard.participants.cohort.empty.description" />
    </Text>
    <Button size="md" onClick={openCohortCreation}>
      <FormattedMessage id="coaching.benchmarking.wizard.participants.cohort.empty.action" />
    </Button>
  </Stack>
)

export const ParticipantsScreenWrapper = ({
  handleContinue,
  handleCancel,
  selectedCohort,
  setSelectedCohort,
}: ParticipantsScreenWrapperProps) => {
  const { useCohortsList } = useCohorts()
  const { data: cohortList, status } = useCohortsList({ isBenchmarking: true })
  const navigate = useNavigate()

  const hasCohorts = !!cohortList?.length

  // set first cohort as selected by default
  useEffect(() => {
    if (status === 'loaded') {
      if (cohortList?.[0]) {
        setSelectedCohort(cohortList[0].extId)
      }
    }
  }, [status, cohortList, setSelectedCohort, hasCohorts])

  const openCohortCreation = () => {
    navigate('/coaching/benchmarking/cohorts')
  }

  return (
    <ContentLoader status={status}>
      {hasCohorts && selectedCohort ? (
        <CohortSelectScreen
          cohorts={[...cohortList]}
          handleOpenCohortCreate={openCohortCreation}
          handleCancel={handleCancel}
          handleContinue={handleContinue}
          selectedCohort={selectedCohort}
          setSelectedCohort={setSelectedCohort}
        />
      ) : (
        <NoCohortState openCohortCreation={openCohortCreation} />
      )}
    </ContentLoader>
  )
}
