import moment from 'moment'

import { api } from '../../utils/api'
import { type DashboardFilterDates } from './types'

const MONTHLY_FORMAT = 'MMM YYYY'
const DAILY_FORMAT = 'D MMM'

const requestCache = new Map<
  string,
  {
    data: unknown
    fetchDate: string
  }
>()

export const fetchDashboardData = async ({
  cohorts,
  dates,
}: {
  dates: DashboardFilterDates
  cohorts: Array<string>
}) => {
  // the end of the day of endDate is the beginning of the next date
  const endDate = moment(dates.endDate).add(1, 'day').toISOString()
  const startDate = dates.startDate.toISOString()

  const dashboardFetchArgs: {
    startDate: string
    endDate: string
    'cohorts[]'?: Array<string>
  } = {
    startDate,
    endDate,
  }

  if (cohorts.length > 0) {
    dashboardFetchArgs['cohorts[]'] = cohorts.toSorted()
  }

  const key = JSON.stringify(dashboardFetchArgs)

  const cachedRequest = requestCache.get(key)
  const isCachedAtLastHour = moment().isSame(cachedRequest?.fetchDate, 'hour')

  if (cachedRequest && isCachedAtLastHour) {
    return cachedRequest.data
  }

  const data = await api.dashboard.get(dashboardFetchArgs)

  // Format start dates based on the date intervals. For monthly and quarterly intervals,
  // we display dates in `MONTHLY_FORMAT`. Otherwise (for daily and weekly intervals), we display
  // dates in `DAILY_FORMAT`

  const dateFormat =
    data.startDates.length >= 2 &&
    // The duration between first days of two months as months does not give an integer, that's why we round here
    Math.round(
      moment
        .duration(moment(data.startDates[1]).diff(moment(data.startDates[0])))
        .asMonths()
    ) >= 1
      ? // we have monthly or quarterly intervals
        MONTHLY_FORMAT
      : // we have daily or weekly intervals
        DAILY_FORMAT

  const startDatesFormatted = data.startDates.map((date: string) =>
    moment.utc(date).format(dateFormat)
  )

  const dataWithStartDatesFormatted = { ...data, startDatesFormatted }

  requestCache.set(key, {
    data: dataWithStartDatesFormatted,
    fetchDate: moment().toISOString(),
  })

  return dataWithStartDatesFormatted
}
