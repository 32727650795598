import {
  Box,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  useCheckboxGroup,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { useMemo, useState } from 'react'
import { IoSettingsOutline } from 'react-icons/io5'

import { CustomLineChart } from '../shared/custom-line-chart'
import { DashboardCard } from '../shared/dashboard-card'
import { ToggleList, type ToggleListItem } from '../shared/toggle-list'
import { ToggleListSwitch } from '../shared/toggle-list/toggle-list-switch'
import { useDashboardCardData } from '../shared/use-dashboard-card-data'
import { NoDataInformation } from './shared/no-data-information'

type DataKey =
  | 'communicationAndLanguage'
  | 'messaging'
  | 'knowledgeCheck'
  | 'overallScore'

const dataConfig: Record<DataKey, ToggleListItem<DataKey>> = {
  communicationAndLanguage: {
    labelKey: 'dashboard.cards.behavioralAdaptation.data.communicationAndLanguage',
    color: colors.lineChartColors[300],
    dataKey: 'communicationAndLanguage',
  },
  messaging: {
    labelKey: 'dashboard.cards.behavioralAdaptation.data.messaging',
    color: colors.lineChartColors[200],
    dataKey: 'messaging',
  },
  knowledgeCheck: {
    labelKey: 'dashboard.cards.behavioralAdaptation.data.knowledgeCheck',
    color: colors.lineChartColors[600],
    dataKey: 'knowledgeCheck',
  },
  overallScore: {
    labelKey: 'dashboard.cards.behavioralAdaptation.data.overallScore',
    color: colors.lineChartColors[500],
    dataKey: 'overallScore',
  },
}

type SMADataKey = 'thirtyDaysSMA' | 'hundredAndTwentyDaysSMA' | 'hundredAndEightyDaysSMA'

const smaDataConfig: Record<SMADataKey, ToggleListItem<SMADataKey>> = {
  thirtyDaysSMA: {
    labelKey: 'dashboard.cards.behavioralAdaptation.data.thirtyDaysSMA',
    color: colors.teal[300],
    dataKey: 'thirtyDaysSMA',
  },
  hundredAndTwentyDaysSMA: {
    labelKey: 'dashboard.cards.behavioralAdaptation.data.hundredAndTwentyDaysSMA',
    color: colors.purple[400],
    dataKey: 'hundredAndTwentyDaysSMA',
  },
  hundredAndEightyDaysSMA: {
    labelKey: 'dashboard.cards.behavioralAdaptation.data.hundredAndEightyDaysSMA',
    color: colors.orange[300],
    dataKey: 'hundredAndEightyDaysSMA',
  },
}

const dataConfigCombined = { ...dataConfig, ...smaDataConfig }

export const BehavioralAdaptationCard = () => {
  const { dataSlotSelector, dataSlot } = useDashboardCardData('behavioralAdaptation')

  const [activeDataKeys, setActiveDataKeys] = useState<Array<DataKey>>([
    'communicationAndLanguage',
    'knowledgeCheck',
    'messaging',
  ])

  const [activeSMAKeys, setActiveSMAKeys] = useState<Array<SMADataKey>>([])
  const [isSMAConfigDisabled, setIsSMAConfigDisabled] = useState<boolean>(true)

  const { getCheckboxProps } = useCheckboxGroup({
    value: activeSMAKeys,
    onChange: (newDataKeys: Array<SMADataKey>) => setActiveSMAKeys(newDataKeys),
  })

  const behavioralAdaptationData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    return data.startDatesFormatted.map((date, index) => ({
      date,
      knowledgeCheck: data.knowledgeCheckScoreOverallPerTimestamp[index]!,
      messaging: data.messagingScoreOverallPerTimestamp[index]!,
      communicationAndLanguage: data.communicationScoreOverallPerTimestamp[index]!,
      overallScore: data.overallScoreOverallPerTimestamp[index]!,
      thirtyDaysSMA: data.overallScoreOverallSMAPerTimestamp[30][index]!,
      hundredAndTwentyDaysSMA: data.overallScoreOverallSMAPerTimestamp[120][index]!,
      hundredAndEightyDaysSMA: data.overallScoreOverallSMAPerTimestamp[180][index]!,
    }))
  }, [dataSlot])

  const isEmptyData =
    dataSlot.status === 'loaded' && dataSlot.data.overallScoreOverall === null

  const showChart = behavioralAdaptationData.length && !isEmptyData

  const handleActiveDataKeysChange = (newDataKeys: Array<DataKey>) => {
    // if user selects a new data key and overallScore is already selected, remove overallScore
    if (activeDataKeys.length && newDataKeys[0] === 'overallScore') {
      // SMA keys should only be active if the overall score is active
      setActiveSMAKeys([])
      setIsSMAConfigDisabled(true)

      return setActiveDataKeys([newDataKeys.pop() as DataKey])
    }

    const isSelectedOverallScore = newDataKeys[newDataKeys.length - 1] === 'overallScore'

    setIsSMAConfigDisabled(!isSelectedOverallScore)
    if (isSelectedOverallScore) {
      // Show no SMA keys by default if the overall score option is selected
      setActiveSMAKeys([])

      return setActiveDataKeys(['overallScore'])
    }

    setActiveSMAKeys([])
    setActiveDataKeys(newDataKeys)
  }

  return (
    <DashboardCard
      titleKey="dashboard.cards.behavioralAdaptation.title"
      descriptionKey="dashboard.cards.behavioralAdaptation.subtitle"
      colSpan={3}
      wrapperProps={{ pt: 5 }}
      dataSlotSelector={dataSlotSelector}
      isLoading={dataSlot.status !== 'loaded'}
      titleEndContent={
        <Popover placement="left-start">
          <PopoverTrigger>
            <IconButton
              mt={-3}
              colorScheme="gray"
              aria-label="SMA configuration button"
              variant="ghost"
              isDisabled={isSMAConfigDisabled || !showChart}
              icon={<IoSettingsOutline size={27} />}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent w={250}>
              <PopoverArrow />
              <PopoverHeader border={0}>
                <FormattedMessage id="dashboard.cards.behavioralAdaptation.sma.popover.header" />
              </PopoverHeader>
              <PopoverBody mb={5}>
                <Stack gap={3}>
                  {Object.values(smaDataConfig).map(item => (
                    <Box key={item.dataKey}>
                      <ToggleListSwitch
                        labelKey={item.labelKey}
                        color={item.color}
                        useCheckboxProps={getCheckboxProps({ value: item.dataKey })}
                        showToggleFirst={false}
                        toggleColorSx={{ h: '15px', w: '15px', alignSelf: 'center' }}
                      />
                    </Box>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      }
    >
      {showChart ? (
        <>
          <Box height={300} mb={10}>
            <CustomLineChart
              data={behavioralAdaptationData}
              yAxisTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              yAxisLabelKey="dashboard.cards.behavioralAdaptation.yaxis.label"
              dataConfig={[...activeDataKeys, ...activeSMAKeys].map(dataKey => {
                const { color } = dataConfigCombined[dataKey]

                return { dataKey, color }
              })}
              valueSuffix="%"
              showLineNamesInTooltip={!isSMAConfigDisabled}
            />
          </Box>

          <Box px={5}>
            <ToggleList
              items={Object.values(dataConfig)}
              value={activeDataKeys}
              onChange={handleActiveDataKeysChange}
            />
          </Box>
        </>
      ) : (
        <NoDataInformation />
      )}
    </DashboardCard>
  )
}
