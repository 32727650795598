import { type AnyObject, calculateVideoLength } from '@repo/utils'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { last } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'

import config from './config'

const firebaseApp = initializeApp(config.firebase)

export const firebaseAuth = getAuth(firebaseApp)

export const firebaseUsersStorage = getStorage(
  firebaseApp,
  `gs://${config.cloudStorage.usersFileStorageBucketName}`
)
const firebaseUsersTemporaryStorage = getStorage(
  firebaseApp,
  `gs://${config.cloudStorage.usersTemporaryFileStorageBucketName}`
)

const usersFileStorageUrlPrefix = `gs://${config.cloudStorage.usersFileStorageBucketName}}/`

const imageMimeTypes = ['image/jpeg', 'image/png']
const videoMimeTypes = ['video/mp4']

const allowedMimeTypes = [...imageMimeTypes, ...videoMimeTypes]

const isImage = (file: File) => imageMimeTypes.includes(file.type)
const isVideo = (file: File) => videoMimeTypes.includes(file.type)

const exceedSizeLimit = (file: File, limitInMb: number) => file.size / 1000000 > limitInMb

const imageSizeLimitInMb = 10
const videoSizeLimitInMb = 300

export const uploadMediaFile = async (file: File, organizationId: string) => {
  if (!allowedMimeTypes.includes(file.type)) {
    throw new Error('File type is not supported for uplaod')
  }

  if (isImage(file) && exceedSizeLimit(file, imageSizeLimitInMb)) {
    throw new Error(`Images can't be bigger than ${imageSizeLimitInMb}MB.`)
  }

  if (isVideo(file) && exceedSizeLimit(file, videoSizeLimitInMb)) {
    throw new Error(`Videos can't be bigger than ${videoSizeLimitInMb}MB.`)
  }

  const fileExtension = last(file.name.split('.'))
  const filePath = `${organizationId}/assets/${uuidv4()}.${fileExtension}`

  const storageRef = ref(firebaseUsersTemporaryStorage, filePath)

  const metadata: AnyObject = {
    customMetadata: {
      name: file.name,
    },
  }
  // eslint-disable-next-line
  if (isVideo(file)) metadata.customMetadata.duration = await calculateVideoLength(file)

  const firebaseFile = await uploadBytes(storageRef, file, metadata)
  const fileGSUrl = firebaseFile.ref.toString()

  return fileGSUrl
}

export const getHttpLink = async (gsUrl: string) => {
  const bucket = gsUrl.startsWith(usersFileStorageUrlPrefix)
    ? firebaseUsersStorage
    : firebaseUsersTemporaryStorage

  const fileUrl = await getDownloadURL(ref(bucket, gsUrl))

  return fileUrl
}
