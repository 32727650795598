import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { deleteFolder, editFolder } from '../store/entities/folders/effects'
import {
  makeSelectFolder,
  makeSelectFoldersList,
} from '../store/entities/folders/selectors'

// If something here is changed, one needs to change
// the types in ./useFolderReturn.types.ts accordingly
export const useFolder = id => {
  const dispatch = useDispatch()
  const selectFolder = useMemo(makeSelectFolder, [])
  const folder = useSelector(state => selectFolder(state, id))
  const selectList = useMemo(makeSelectFoldersList, [])
  const list = useSelector(state => selectList(state, id))
  const navigate = useNavigate()

  const remove = async () => {
    dispatch(deleteFolder(id))
    navigate('/folders')
  }

  const update = async data => dispatch(editFolder(id, data))

  return { data: folder, list, remove, update }
}
