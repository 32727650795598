import { Button } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { Formik } from 'formik'
import { type Dispatch, type SetStateAction } from 'react'

import { benchmarkProfileFormValidator } from '../../helpers/benchmark-profiles-form-validator'
import { BenchmarkProfileForm } from '../benchmark-profile-form'
import { ButtonContainer } from './button-container'
import { ContentContainer } from './content-container'

type NameScreenProps = {
  handleContinue: VoidFunction
  handleCancel: VoidFunction
  setBenchmarkProfileName: Dispatch<SetStateAction<string | null>>
}

export const NameScreen = ({
  handleContinue,
  handleCancel,
  setBenchmarkProfileName,
}: NameScreenProps) => {
  const { formatMessage } = useIntl()
  const handleSubmit = values => {
    setBenchmarkProfileName(values.title)
    handleContinue()
  }

  return (
    <ContentContainer
      title={formatMessage({ id: 'coaching.benchmarking.wizard.name.title' })}
    >
      <Formik<{ title: string }>
        onSubmit={handleSubmit}
        initialValues={{ title: '' }}
        validationSchema={benchmarkProfileFormValidator}
        validateOnBlur={false}
      >
        {({ submitForm, dirty, isValid, isSubmitting }) => (
          <>
            <BenchmarkProfileForm mode="create" showActionButtons={false} />
            <ButtonContainer>
              <Button variant="outline" onClick={handleCancel}>
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button
                isLoading={isSubmitting}
                onClick={submitForm}
                isDisabled={!(dirty && isValid)}
              >
                <FormattedMessage id="coaching.benchmarking.wizard.name.continue" />
              </Button>
            </ButtonContainer>
          </>
        )}
      </Formik>
    </ContentContainer>
  )
}
