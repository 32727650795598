import { Box, Progress, Stack } from '@chakra-ui/react'
import { type ApiSchemas } from '@repo/api'
import { SectionHeader, showToast, useFormRules } from '@repo/ui'
import { assertExists } from '@repo/utils'
import { useCallback, useEffect, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { CenteredSpinner } from '../../../../../components/centered-spinner'
import { useUserSessionId } from '../../../../../store/entities/user-activity-session/use-user-session-id'
import { client } from '../../../../../utils/openapi-client'
import { StickyFooter } from '../../../../shared/sticky-footer'
import { TextAreaWithTones } from '../../../../shared/textarea-with-tones'
import { type Tone } from '../../../../shared/textarea-with-tones/types'
import { useNextStepModal } from '../../../../shared/use-next-step-modal'
import { useAIBuilderContext } from '../../../shared/ai-builder-context'
import { useInteractionStepContext } from '../interaction-step-context'

type FormValues = {
  goal: string
  description: string
}

export const StepScenarioGoal = () => {
  const {
    mutateScenario,
    scenario,
    updateScenarioDetails,
    programExtId,
    scenarioExtId,
    trainingExtId,
    isBuilderReadonly,
  } = useAIBuilderContext()

  const userSessionId = useUserSessionId()

  const { control, formState, handleSubmit, resetField, trigger, watch, setValue } =
    useForm<FormValues>({
      mode: 'onTouched',
      disabled: isBuilderReadonly,
      defaultValues: {
        goal: scenario.goal ?? '',
        description: scenario.description ?? '',
      },
    })

  const rules = useFormRules()
  const { setCurrentStep } = useInteractionStepContext()

  const [atFirstStep, setAtFirstStep] = useState(true)
  const [isFetchingAiSuggestions, setIsFetchingAiSuggestions] = useState(true)

  const [suggestions, setSuggestions] = useState<Record<keyof FormValues, Array<Tone>>>({
    description: [],
    goal: [],
  })

  const [nextStepModal, confirmProceedingViaModal] = useNextStepModal({
    titleKey:
      'scenario.ai.builder.steps.conversationGeneration.timeToGenerateModal.title',
    descriptionKey:
      'scenario.ai.builder.steps.conversationGeneration.timeToGenerateModal.description',
    continueLabelKey:
      'scenario.ai.builder.steps.conversationGeneration.timeToGenerateModal.button',
  })

  const fetchAISuggestions = useCallback(
    async (field: ApiSchemas['generateScenarioContentRequestAiSupported']['field']) => {
      if (isBuilderReadonly) {
        return setIsFetchingAiSuggestions(false)
      }

      try {
        const formFieldName: keyof FormValues =
          field === 'learning_goal' ? 'goal' : 'description'

        const alreadyFetched = suggestions[formFieldName].length

        if (alreadyFetched) {
          return
        }

        setIsFetchingAiSuggestions(true)

        const params = { path: { programExtId, trainingExtId, scenarioExtId } }

        const responses = await Promise.all(
          (['professional', 'catchy'] as const).map(tone =>
            client.post('generateScenarioContentForAiSupportedScenario', {
              body: {
                field,
                tone,
                userActivitySessionExtId: userSessionId,
              },
              params,
            })
          )
        )

        // throw error if any of the responses has an error
        assertExists(responses[0]?.data)
        assertExists(responses[1]?.data)

        const [
          {
            data: { suggestion: professionalSuggestion },
          },
          {
            data: { suggestion: catchySuggestion },
          },
        ] = responses

        const newTonesArray: Array<Tone> = [
          {
            value: professionalSuggestion,
            labelKey: 'common.tone.professional',
          },
          {
            value: catchySuggestion,
            labelKey: 'common.tone.catchy',
          },
        ]

        setSuggestions(prev => ({
          ...prev,
          [formFieldName]: newTonesArray,
        }))

        const shouldSetFormValue = !scenario[formFieldName]

        if (shouldSetFormValue) {
          setValue(formFieldName, newTonesArray[0]?.value ?? '', {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
      } catch (error) {
        showToast({
          status: 'warning',
          messageKey:
            'scenario.ai.builder.steps.conversationGeneration.suggestions.failed',
        })
      } finally {
        setIsFetchingAiSuggestions(false)
      }
    },
    [
      programExtId,
      scenarioExtId,
      trainingExtId,
      setValue,
      scenario,
      suggestions,
      isBuilderReadonly,
      userSessionId,
    ]
  )

  const goToNextStep = useCallback(async () => {
    if (atFirstStep) {
      setAtFirstStep(false)
      await fetchAISuggestions('scenario_description')
      trigger('description')

      return
    }

    setCurrentStep('conversation-generation')
  }, [atFirstStep, fetchAISuggestions, trigger, setCurrentStep])

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async values => {
      if (!formState.isDirty) {
        return goToNextStep()
      }

      if (!atFirstStep) {
        // confirm before updating and proceeding
        const isConfirmed = await confirmProceedingViaModal()

        if (!isConfirmed) {
          return
        }
      }

      const res = await updateScenarioDetails(
        atFirstStep
          ? {
              goal: values.goal,
            }
          : {
              description: values.description,
            }
      )

      if (res.error) {
        return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }

      mutateScenario(res.data, false)

      goToNextStep()
    },
    [
      atFirstStep,
      formState.isDirty,
      updateScenarioDetails,
      mutateScenario,
      confirmProceedingViaModal,
      goToNextStep,
    ]
  )

  useEffect(() => {
    fetchAISuggestions('learning_goal')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack as="form" flex={1} onSubmit={handleSubmit(onSubmit)}>
      {nextStepModal}
      <SectionHeader
        titleKey="scenario.manual.builder.details.step.subtitle"
        sx={{ p: { fontSize: '18px' } }}
      />
      <Progress
        value={atFirstStep ? 50 : 100}
        borderRadius="full"
        maxW="500px"
        minH={2}
        size="sm"
        mb={8}
        mt={3}
      />

      {isFetchingAiSuggestions ? (
        <Box h={48}>
          <CenteredSpinner />
        </Box>
      ) : (
        <Stack gap={16} maxW="800px">
          {atFirstStep && (
            <TextAreaWithTones
              control={control}
              name="goal"
              rules={rules.stringLongRequired}
              watch={watch}
              setValue={setValue}
              tones={suggestions.goal}
              labelKey="scenario.manual.builder.details.step.goal.label"
            />
          )}

          {!atFirstStep && (
            <TextAreaWithTones
              control={control}
              name="description"
              rules={rules.stringCustomLengthRequired(2000)}
              watch={watch}
              setValue={setValue}
              tones={suggestions.description}
              labelKey="scenario.description.label"
            />
          )}
        </Stack>
      )}

      <StickyFooter
        onBack={() => {
          if (atFirstStep) {
            return setCurrentStep('conversation-dynamics')
          }

          setAtFirstStep(true)
          resetField('description')
        }}
        onNext={isBuilderReadonly ? goToNextStep : undefined}
        isNextDisabled={
          !isBuilderReadonly && (!formState.isValid || isFetchingAiSuggestions)
        }
        isNextLoading={formState.isSubmitting}
      />
    </Stack>
  )
}
