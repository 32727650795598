import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { BiInfoCircle } from 'react-icons/bi'

import { type ExtendedProgram, type SharedProgram } from '../../types/api-types'

const getStatusCount = (data: Array<SharedProgram>, state: SharedProgram['status']) =>
  data?.filter(sharedProgram => sharedProgram.status === state).length

export const SharedProgramSummaryPopover = ({
  program,
}: {
  program: ExtendedProgram
}) => {
  const { sharedPrograms } = program

  if (!sharedPrograms?.length) {
    return null
  }

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Button
          borderRadius="3xl"
          bgColor="secondary"
          textColor={colors.blue[400]}
          rightIcon={<BiInfoCircle />}
          _hover={{ bg: colors.secondary }}
          fontWeight={400}
          fontSize="16px"
          p="12px"
        >
          <FormattedMessage id="coaching.program.send.programList.sent" />
        </Button>
      </PopoverTrigger>
      <PopoverContent w="250px">
        <PopoverArrow />

        <PopoverBody fontWeight={400} fontSize="14px" textColor={colors.gray[900]}>
          <VStack alignItems="flex-start" gap={0}>
            <Text fontWeight={500} p={2} fontSize="16px">
              <FormattedMessage id="coaching.program.send.modal.tabs.requestsStatus" />:
            </Text>
            {(
              [
                { state: 'pending', color: colors.blue[400] },
                { state: 'accepted', color: colors.green[400] },
                { state: 'rejected', color: colors.red[400] },
                { state: 'expired', color: colors.gray[400] },
              ] as const
            ).map(({ state, color }) => (
              <HStack key={state} gap={1} pb={1}>
                <Box m={2} borderRadius="full" bgColor={color} width="8px" height="8px" />
                <Text fontSize="16px" fontWeight={400}>
                  <FormattedMessage
                    id={`coaching.program.send.modal.tabs.requestsStatus.${state}`}
                  />
                  :
                </Text>
                <Text fontSize="16px" fontWeight={700}>
                  {getStatusCount(sharedPrograms, state)}
                </Text>
              </HStack>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
