import { Box, FormControl, FormLabel, Select } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'
import { getHumanReadableTime } from '@repo/utils'
import { Field, useFormikContext } from 'formik'
import { type ChangeEvent, useEffect, useMemo } from 'react'
import { RiQuestionnaireLine } from 'react-icons/ri'

import { type CoachingStepFormFields } from '../../../../../builder/steps/api'
import { RecordIntro } from './record-intro'
import { useVideoUploader } from './use-video-uploader'

type AnswerStepProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  hasUnderstanding: boolean
}
// Max allowed recording times in seconds
// For understanding, we allow up to 60 seconds
const MAX_RECORDING_TIME_SECS = [30, 60, 90, 120, 150, 180, 210, 240, 270, 300]

export const AnswerStep = ({
  handleChange,
  disabled,
  hasUnderstanding,
}: AnswerStepProps) => {
  const { formatMessage } = useIntl()
  const { values, setFieldValue } = useFormikContext<CoachingStepFormFields>()
  const { step } = values

  if (step.stepType !== 'coachingStepQA') {
    throw new Error('Wrong step type')
  }

  const filteredOptions = useMemo(
    () =>
      hasUnderstanding ? MAX_RECORDING_TIME_SECS.slice(0, 2) : MAX_RECORDING_TIME_SECS,
    [hasUnderstanding]
  )

  const options = useMemo(
    () =>
      filteredOptions.map(value => (
        <option key={value} value={value}>
          {formatMessage(
            { id: 'step.answer.maxRecordingTime.minutes' },
            {
              time: getHumanReadableTime(value),
            }
          )}
        </option>
      )),
    [filteredOptions, formatMessage]
  )

  useEffect(() => {
    if (!filteredOptions.includes(step.maxRecordingTime)) {
      setFieldValue('step.maxRecordingTime', 60)
    }
  }, [filteredOptions, setFieldValue, step.maxRecordingTime])

  const { videoHttpUrl } = useVideoUploader('step.answerUrl')

  return (
    <RecordIntro
      title={formatMessage({ id: 'step.answer.intro.title' })}
      description={formatMessage({ id: 'step.answer.intro.description' })}
      buttonUploadText={formatMessage({ id: 'common.upload' })}
      hints={[
        formatMessage({ id: 'step.question.intro.hint.1' }),
        formatMessage({ id: 'step.question.intro.hint.2' }),
        formatMessage({ id: 'step.answer.intro.hint.1' }),
      ]}
      icon={RiQuestionnaireLine}
      disabled={disabled}
      fieldName="step.answerUrl"
    >
      {videoHttpUrl && (
        <Box
          w="250px"
          p="2"
          pl="3"
          bg={colors.gray[50]}
          borderRadius="md"
          alignSelf="start"
          m={3}
          marginTop="16px !important"
        >
          <FormControl id="answer-recording-time">
            <FormLabel>
              {formatMessage({ id: 'step.answer.recordingTime.label' })}
            </FormLabel>
            <Field
              as={Select}
              name="step.maxRecordingTime"
              onChange={handleChange}
              disabled={disabled}
            >
              {options}
            </Field>
          </FormControl>
        </Box>
      )}
    </RecordIntro>
  )
}
