import { type ProgramLanguages } from '@repo/api'
import { fromPairs, mapValues } from 'lodash-es'

export type Industry =
  | 'retail'
  | 'hospitality'
  | 'transportation'
  | 'healthcare'
  | 'financialServices'
  | 'education'
  | 'entertainment'
  | 'rnd'
  | 'it'
  | 'educationAndTraining'
  | 'consulting'
  | 'creative'
  | 'telecom'

export type UseCase =
  | 'insurance'
  | 'electricCar'
  | 'leadership'
  | 'conflict'
  | 'sales'
  | 'coachingConversation'
  | 'employeeDevelopment'
  | 'overAndUnderPerformance'
  | 'coachingEmployees'
  | 'employeeMotivation'
  | 'resistanceToChange'
  | 'turnIntoUpsell'
  | 'complaint'
  | 'productKnowledge'
  | 'influencingOthers'
  | 'effectiveCommunication'
  | 'selfAwareness'
  | 'medicc'
  | 'coldCallingPitches'
  | 'strategiesToLowerProcess'
  | 'publicSpeaking'
  | 'valuePropositionNeedsAnalysis'
  | 'valuePropositionStorytelling'
  | 'valuePropositionConfirmValue'

export type Department =
  | 'customerService'
  | 'salesAndMarketing'
  | 'opsAndLogistics'
  | 'hr'
  | 'financeAndAccounting'
  | 'it'
  | 'qa'
  | 'legal'
  | 'rnd'
  | 'itDev'
  | 'knowledgeManagement'
  | 'innovationAndProduct'
  | 'consultingAndAdvisory'
  | 'educationAndTraining'
  | 'marketResearchAndAnalytics'
  | 'projectManagement'

export type Filters = {
  industry: Array<`industry.${Industry}`>
  useCases: Array<`useCases.${UseCase}`>
  department: Array<`department.${Department}`>
  language: Array<`language.${ProgramLanguages}`>
}

export type FilterType = keyof Filters

export type FiltersOnlyValues = {
  industry: Industry
  useCases: UseCase
  department: Department
  language: ProgramLanguages
}

export const orderedFilterTypes: Array<FilterType> = [
  'language',
  'industry',
  'department',
  'useCases',
]

export const initialFilters = mapValues(
  fromPairs(orderedFilterTypes.map(type => [type, []])),
  () => []
  // TS is losing the type of the object here, so need to manually assign a type to this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) as any as Filters
