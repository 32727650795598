import styled from '@emotion/styled'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import { useAuth } from '../authentication/auth-context'
import { AppHeader, AppHeaderTitleProvider } from '../components/app-header'
import { FirstLoginModal } from '../components/first-login-modal'
import { GDPRAlertModal } from '../components/gdpr-alert-modal'
import { RouteNotAvailableError } from '../components/route-not-available-error'
import { Sidebar } from '../components/sidebar'
import {
  selectOrganizationHasCoaching,
  selectOrganizationHasRecruiting,
} from '../store/entities/organization/selectors'

const AppLayoutContainer = styled.div`
  display: grid;
  grid-template-areas:
    'sidebar header'
    'sidebar main'
    'sidebar footer';
  grid-template-rows: max-content 2fr;
  grid-template-columns: max-content 1fr;
  height: 100%;

  > header {
    grid-area: header;
  }
  > aside {
    grid-area: sidebar;
    z-index: 7;
  }
  > .main {
    grid-area: main;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }
  > footer {
    grid-area: footer;
  }
`

export const AppLayout = ({
  guard,
}: {
  guard: 'loggedIn' | 'coachingEnabled' | 'recruitingEnabled'
}) => {
  const { user } = useAuth()

  const hasRecruiting = useSelector(selectOrganizationHasRecruiting)
  const hasCoaching = useSelector(selectOrganizationHasCoaching)

  const mainContent = useMemo(() => {
    if (guard === 'coachingEnabled' && !hasCoaching) {
      return <RouteNotAvailableError intlKey="error.coaching.not.available" />
    }

    if (guard === 'recruitingEnabled' && !hasRecruiting) {
      return <RouteNotAvailableError intlKey="error.recruiting.not.available" />
    }

    return <Outlet />
  }, [guard, hasCoaching, hasRecruiting])

  if (!user) {
    return <Navigate to="/login" />
  }

  if (!user.emailVerified) {
    // Due to GDPR requirements, users have to verify their email address
    // before accessing the platform
    return <Navigate to="/verify-email" />
  }

  return (
    <AppLayoutContainer className="app-layout">
      <AppHeaderTitleProvider>
        <AppHeader />
        <Sidebar />
        <main className="main">{mainContent}</main>
        <GDPRAlertModal />
        <FirstLoginModal />
      </AppHeaderTitleProvider>
    </AppLayoutContainer>
  )
}
