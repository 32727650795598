import { get } from 'lodash-es'

import types from './private/actions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = null

// eslint-disable-next-line import/no-default-export
export default (state = initialState, { type, data }) => {
  switch (type) {
    case types.DELETE_INVITATION:
      return {
        ...state,
        invitations: state.invitations.filter(({ id }) => id !== data),
      }
    case types.DELETE_MEMBER:
      return {
        ...state,
        members: state.members.filter(({ id }) => id !== data),
      }
    case types.UPDATE_MEMBER:
      return {
        ...state,
        members: state.members.map(m =>
          m.id === data.id ? { ...m, ...data.payload } : m
        ),
      }
    case types.RECEIVE_INVITATION:
      return {
        ...state,
        invitations: state.invitations.concat([data]),
      }
    case types.RECEIVE_ORGANIZATION: {
      if (!data) {
        return state
      }

      const timestamp = new Date().getTime()

      return {
        id: get(data, 'id', ''),
        createdAt: get(data, 'createdAt', timestamp),
        updatedAt: get(data, 'updatedAt', timestamp),
        deletedAt: get(data, 'deletedAt', timestamp),
        name: get(data, 'name', ''),
        members: get(data, 'members', []).map(member => ({
          ...member,
          memberRole: get(member, ['roles', 0, 'id'], '-'),
        })),
        invitations: get(data, 'invitations', []),
        companyName: get(data, 'companyName', ''),
        logoUrl: get(data, 'logoUrl', ''),
        gdprCompany: get(data, 'gdprCompany', {}),
        privacyPolicy: data.privacyPolicy || '',
        gdprEnabled: get(data, 'gdprEnabled', true),
        settings: {
          ...data.settings,
          hasRecruiting: get(data, 'settings.hasRecruiting', true),
          hasCoaching: get(data, 'settings.hasCoaching', false),
          programNotificationsEnabled: get(data, 'settings.programNotificationsEnabled'),
        },
      }
    }

    case types.UPDATE_PRIVACY: {
      const { privacyPolicy, ...companyData } = data || {}

      return {
        ...state,
        gpdrCompany: {
          name: get(companyData, 'name', ''),
          address: get(companyData, 'address', ''),
          postcode: get(companyData, 'postcode', ''),
          city: get(companyData, 'city', ''),
          email: get(companyData, 'email', ''),
        },
        privacyPolicy: privacyPolicy || '',
      }
    }

    default:
      return state
  }
}
