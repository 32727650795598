import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { RiMoreFill } from 'react-icons/ri'

import { useDuplicateProgramModal } from '../../coaching/program-list/use-duplicate-program-modal'
import { useShareProgramModal } from '../../coaching/program-list/use-share-program-modal'
import { type ExtendedProgram } from '../../types/api-types'
import { client } from '../../utils/openapi-client'
import { useProgramsList } from '../shared/api'
import { useConfirmDeleteProgram } from './options-menu/use-confirm-delete-program'
import { useEditProgramModal } from './options-menu/use-edit-program-modal'

type OptionsMenuProps = {
  program: ExtendedProgram
}

export const OptionsMenu = ({ program }: OptionsMenuProps) => {
  const { formatMessage } = useIntl()
  const { mutate } = useProgramsList()
  const buttonAriaLabel = formatMessage({
    id: 'coaching.programList.table.options.button.ariaLabel',
  })

  const [editProgramModal, showEditProgramModal] = useEditProgramModal(program)
  const [duplicateProgramModal, showDuplicateProgramModal] =
    useDuplicateProgramModal(program)

  const [shareProgramModal, showShareProgramModal] = useShareProgramModal(program)

  const pendingSharedPrograms = program.sharedPrograms?.filter(
    ({ status }) => status === 'pending'
  )

  const isShared =
    program.sharedPrograms?.length !== 0 && pendingSharedPrograms?.length !== 0

  const [confirmDeleteModal, confirmDeleteProgramViaModal] =
    useConfirmDeleteProgram(isShared)

  const handleDeleteProgram = async () => {
    const confirmed = await confirmDeleteProgramViaModal()

    if (!confirmed) {
      return
    }

    try {
      const { error } = await client.delete('deleteProgram', {
        params: { path: { extId: program.extId } },
      })

      if (error) {
        throw new Error()
      }

      mutate(d => d?.filter(i => i.extId !== program.extId), false)
      showToast({ messageKey: 'common.alert.deleted', status: 'success' })
    } catch (_error) {
      showToast({ messageKey: 'general.error.deleting.data', status: 'error' })
    }
  }

  return (
    <Menu>
      {confirmDeleteModal}
      {editProgramModal}
      {duplicateProgramModal}
      {shareProgramModal}
      <MenuButton
        as={IconButton}
        variant="ghost"
        icon={<RiMoreFill />}
        aria-label={buttonAriaLabel}
        onClick={e => e.stopPropagation()}
      />
      <MenuList onClick={e => e.stopPropagation()}>
        <MenuItem
          onClick={showEditProgramModal}
          isDisabled={program.status !== 'unpublished'}
        >
          <FormattedMessage id="coaching.programList.table.options.edit" />
        </MenuItem>
        <MenuItem onClick={showDuplicateProgramModal}>
          <FormattedMessage id="coaching.programList.table.options.duplicate" />
        </MenuItem>
        <MenuItem onClick={showShareProgramModal}>
          <FormattedMessage id="coaching.programList.table.options.send" />
        </MenuItem>
        <MenuItem color="indicator.red-dark" onClick={handleDeleteProgram}>
          <FormattedMessage id="program.button.delete" />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
