import { Stack, type StackProps, Text } from '@chakra-ui/react'
import { FormattedMessage, type ReactNodeOrI18nKey } from '@repo/i18n'

import { colors } from '../theme/main'

type SectionHeaderProps = {
  sx?: StackProps['sx']
} & ReactNodeOrI18nKey<'title'> &
  ReactNodeOrI18nKey<'subtitle'>

export const SectionHeader = ({
  subtitle,
  subtitleKey,
  sx,
  title,
  titleKey,
}: SectionHeaderProps) => {
  if (!title && !titleKey && !subtitle && !subtitleKey) {
    return null
  }

  return (
    <Stack alignItems="flex-start" gap={0} sx={sx}>
      {(titleKey || title) && (
        <Text
          fontSize="24px"
          maxW={800}
          fontWeight={700}
          color={colors.gray[900]}
          lineHeight="base"
        >
          {titleKey ? <FormattedMessage id={titleKey} /> : title}
        </Text>
      )}

      {(subtitleKey || subtitle) && (
        <Text color={colors.gray[700]} lineHeight="base">
          {subtitleKey ? <FormattedMessage id={subtitleKey} /> : subtitle}
        </Text>
      )}
    </Stack>
  )
}
