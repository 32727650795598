import { Box, Button, Center, Heading, Stack, Text, Textarea } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { FormikFormField, showToast } from '@repo/ui'
import { Form, Formik, type FormikHelpers, type FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { boolean, object, type SchemaOf, string } from 'yup'

import { CheckboxSliderField } from '../../components/checkbox-slider-field'
import { savePrivacy } from '../../store/entities/organization/effects'
import { selectOrganization } from '../../store/entities/organization/selectors'

type FormValues = {
  email: string | undefined
  address: string | undefined
  postcode: string | undefined
  city: string | undefined
  phone: string | undefined
  register: string | undefined
  court: string | undefined
  privacyPolicy: string | undefined
  enabled: boolean
}

const GDPRFormInner = ({
  isSubmitting,
  setFieldValue,
  values: { enabled },
  errors,
}: FormikProps<FormValues>) => {
  const { formatMessage } = useIntl()

  return (
    <Form>
      <Stack>
        <CheckboxSliderField
          isChecked={enabled}
          name="enabled"
          onChange={() => setFieldValue('enabled', !enabled)}
        >
          <FormattedMessage id="organization.settings.gdpr.form.field.gdprEnabled" />
        </CheckboxSliderField>
        <Box pt={2}>
          <Heading as="h6" color="gray.600" margin="3px" size="xs">
            <FormattedMessage id="organization.settings.gdpr.form.company.title" />
          </Heading>
          <Text as="h6" color="gray.600" margin="3px" size="xs">
            <FormattedMessage id="organization.settings.gdpr.form.company.info" />
          </Text>
        </Box>

        <FormikFormField
          errors={errors.email}
          name="email"
          label={formatMessage({
            id: 'organization.settings.gdpr.form.company.field.email',
          })}
        />

        <FormikFormField
          errors={errors.address}
          name="address"
          label={formatMessage({
            id: 'organization.settings.gdpr.form.company.field.address',
          })}
        />

        <Stack spacing={8} direction="row">
          <Box>
            <FormikFormField
              errors={errors.postcode}
              name="postcode"
              label={formatMessage({
                id: 'organization.settings.gdpr.form.company.field.postcode',
              })}
            />
          </Box>
          <Box w="300px">
            <FormikFormField
              errors={errors.city}
              name="city"
              label={formatMessage({
                id: 'organization.settings.gdpr.form.company.field.city',
              })}
            />
          </Box>
        </Stack>

        <FormikFormField
          errors={errors.phone}
          name="phone"
          label={formatMessage({
            id: 'organization.settings.gdpr.form.company.field.phone',
          })}
        />

        <FormikFormField
          errors={errors.register}
          name="register"
          label={formatMessage({
            id: 'organization.settings.gdpr.form.company.field.register',
          })}
        />

        <FormikFormField
          errors={errors.court}
          name="court"
          label={formatMessage({
            id: 'organization.settings.gdpr.form.company.field.court',
          })}
        />

        <Heading as="h6" color="gray.600" margin="3px" size="xs">
          <FormattedMessage id="organization.settings.gdpr.form.privacy.title" />
        </Heading>

        <Text as="h6" color="gray.600" margin="3px" size="xs">
          <FormattedMessage id="organization.settings.gdpr.form.privacy.info" />
        </Text>

        <FormikFormField
          errors={errors.privacyPolicy}
          name="privacyPolicy"
          label=""
          as={Textarea}
          maxLength={10000}
          cols={40}
          rows={10}
        />

        <Center gap={4}>
          <Button type="submit" isLoading={isSubmitting} isDisabled={isSubmitting}>
            <FormattedMessage id="common.save" />
          </Button>
        </Center>
      </Stack>
    </Form>
  )
}

const defaultValues: Partial<FormValues> = {
  enabled: true,
  address: '',
  city: '',
  email: '',
  postcode: '',
  privacyPolicy: '',
}

export const GDPRForm = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const validation = string()
    .notRequired()
    .when('enabled', {
      is: true,
      then: string().required(formatMessage({ id: 'common.validation.required' })),
    })

  const {
    gdprEnabled: enabled,
    gdprCompany,
    privacyPolicy,
  } = useSelector(selectOrganization)

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      await dispatch(savePrivacy(values))
      actions.setSubmitting(false)
      showToast({ messageKey: 'common.alert.created', status: 'success' })
    } catch (err) {
      actions.setSubmitting(false)
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }
  }

  const validationSchema: SchemaOf<FormValues> = object().shape({
    enabled: boolean().default(true),
    email: validation.email(
      formatMessage({ id: 'organization.settings.general.form.invalid.email' })
    ),
    address: validation,
    postcode: validation,
    city: validation,
    phone: validation,
    register: validation,
    court: validation,
    privacyPolicy: validation,
  })

  return (
    <Formik<FormValues>
      initialValues={{
        ...defaultValues,
        ...gdprCompany,
        enabled,
        privacyPolicy,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {GDPRFormInner}
    </Formik>
  )
}
