import types from './private/actions'

// eslint-disable-next-line import/no-default-export
export default {
  ON_CREATE_FOLDER_REQUEST: types.CREATE_FOLDER_REQUEST,
  ON_CREATE_FOLDER_SUCCESS: types.CREATE_FOLDER_SUCCESS,
  ON_DELETE_FOLDER_REQUEST: types.DELETE_FOLDER_REQUEST,
  ON_DELETE_FOLDER_SUCCESS: types.DELETE_FOLDER_SUCCESS,
  ON_RECEIVE_FOLDERS: types.RECEIVE_FOLDERS,
  ON_UPDATE_FOLDER_REQUEST: types.UPDATE_FOLDER_REQUEST,
  ON_UPDATE_FOLDER_SUCCESS: types.UPDATE_FOLDER_SUCCESS,
}
