import { Button, Center, Icon, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, showToast } from '@repo/ui'
import { Form, useFormikContext } from 'formik'
import { useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'

import { useCohorts } from '../../../../use-cohorts'
import { type CohortCreationValues } from '../../types'
import { StepActions } from '../step-actions'
import { RadioCard } from './components/radio-card'

interface ModeStepProps {
  isBenchmarking: boolean
  onStepBack: () => void
}

export const ModeStep = ({ isBenchmarking, onStepBack }: ModeStepProps) => {
  const { formatMessage } = useIntl()
  const { createCohort } = useCohorts()
  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    values: { type, name },
  } = useFormikContext<CohortCreationValues>()

  const { useCohortsList } = useCohorts()
  const { refresh: refreshCohortList } = useCohortsList({ isBenchmarking })
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onChange = (nextValue: string) => {
    setFieldValue('type', nextValue)
    setFieldTouched('type', true)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      const cohort = await createCohort({
        name,
        type,
        isForBenchmarking: isBenchmarking,
      })

      setFieldValue('id', cohort?.extId)
      setShowSuccessMessage(true)
      refreshCohortList()
    } catch (error) {
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }

    setIsLoading(false)
  }

  if (showSuccessMessage) {
    return (
      <Center height={250}>
        <Stack>
          <Center>
            <Icon as={MdCheckCircle} color={colors.blue[500]} w={7} h={7} mr={5} />
          </Center>
          <Stack
            lineHeight={0.8}
            textAlign="center"
            fontSize="md"
            fontWeight={500}
            mt={3}
          >
            <Text>
              <FormattedMessage id="cohort.creation.mode.step.confirmation.message.1" />
            </Text>
            <Text>
              <FormattedMessage id="cohort.creation.mode.step.confirmation.message.2" />
            </Text>
          </Stack>
          <Center>
            <Button minWidth={90} mt={6} onClick={() => handleSubmit()}>
              <FormattedMessage id="cohort.creation.mode.step.confirmation.message.button" />
            </Button>
          </Center>
        </Stack>
      </Center>
    )
  }

  return (
    <Form>
      <RadioGroup mt={7} onChange={onChange} value={type}>
        <Stack spacing={3}>
          <RadioCard
            value="email"
            title={formatMessage({ id: 'cohort.creation.mode.step.email.card.title' })}
            description={formatMessage({
              id: 'cohort.creation.mode.step.email.card.description',
            })}
            isChecked={type === 'email'}
          />
          <RadioCard
            value="token"
            title={formatMessage({ id: 'cohort.creation.mode.step.token.card.title' })}
            description={formatMessage({
              id: 'cohort.creation.mode.step.token.card.description',
            })}
            isChecked={type === 'token'}
          />
        </Stack>
      </RadioGroup>
      <StepActions onSubmit={onSubmit} onStepBack={onStepBack} isSubmitting={isLoading} />
    </Form>
  )
}
