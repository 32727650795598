import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, PersonaThumbnail } from '@repo/ui'

import { useGoogleStorageAsset } from '../../../hooks/use-google-storage-asset'
import { useRealtimeBuilderContext } from '../../scenario-realtime-builder-page/shared/realtime-builder-context'

const PREVIEW_WIDTH = '380px'

export const RealTimeStep = () => {
  const { scenario } = useRealtimeBuilderContext()

  return (
    <HStack gap={3} alignItems="flex-start">
      <Stack position="relative" alignItems="flex-start">
        <Box w={PREVIEW_WIDTH} aspectRatio="16/9" pointerEvents="none" overflow="hidden">
          <PersonaThumbnail
            avatarPreviewUrl={scenario.persona?.videoGenerationAvatar?.imageUrl}
            bgColor="#CBD5E0"
            useGoogleStorageAsset={useGoogleStorageAsset}
          />
        </Box>
      </Stack>
      <VStack alignItems="flex-start" maxW="612px">
        <Box
          minW={8}
          minH={8}
          borderRadius="full"
          border="1px solid"
          color="white"
          bg={colors.gray[700]}
          px="16px"
          py="4px"
          fontSize="14px"
          fontWeight={500}
        >
          <FormattedMessage id="scenario.realtime.builder.title" />
        </Box>

        <Text fontSize="14px" fontWeight={500}>
          <FormattedMessage id="scenario.builder.realtime.sessionGoal.label" />
        </Text>

        <Text fontSize="14px">{scenario.missionGoal}</Text>
      </VStack>
    </HStack>
  )
}
