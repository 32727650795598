import { AiFillIdcard } from 'react-icons/ai'
import { BsStars } from 'react-icons/bs'
import { HiUserGroup, HiViewGrid, HiViewList } from 'react-icons/hi'
import { IoAnalyticsSharp, IoPerson } from 'react-icons/io5'
import { MdPlayLesson } from 'react-icons/md'
import { PiUserFocusFill } from 'react-icons/pi'

import { useCreateProgramModal } from '../../new-program-builder/programs-page/create-program-menu/use-create-program-modal'
import { SidebarItem } from './shared/sidebar-item'
import { SidebarSection } from './shared/sidebar-section'
import { useGeneratorModal } from './sidebar-group-coaching/use-generator-modal'

export const SidebarGroupCoaching = () => {
  const [createProgramModal, showCreateProgramModal] = useCreateProgramModal()

  const [generatorModal, showGeneratorModal] = useGeneratorModal({
    showCreateProgramModal,
  })

  return (
    <>
      {createProgramModal}
      {generatorModal}

      <SidebarSection labelKey="sidebar.item.coach">
        <SidebarItem
          onClick={showGeneratorModal}
          labelKey="sidebar.item.coachingGenerator"
          icon={BsStars}
        />
        <SidebarItem
          to="/training_programs"
          labelKey="sidebar.item.coachingPrograms"
          icon={HiViewList}
        />

        <SidebarItem
          to="/participants/participants"
          labelKey="sidebar.item.participants"
          icon={IoPerson}
        />
        <SidebarItem
          to="/participants/cohorts"
          labelKey="coaching.benchmarking.tabs.cohorts"
          icon={HiUserGroup}
        />
      </SidebarSection>

      <SidebarSection labelKey="sidebar.item.hub">
        <SidebarItem
          to="/best_practices"
          labelKey="sidebar.item.coachingProgramTemplates"
          icon={HiViewGrid}
        />

        <SidebarItem
          to="/exercises"
          labelKey="sidebar.item.Exercises"
          icon={MdPlayLesson}
        />
      </SidebarSection>

      <SidebarSection labelKey="sidebar.item.analytics">
        <SidebarItem
          to="/coaching/benchmarking/create/wizard"
          labelKey="sidebar.item.skillsanalysis"
          icon={PiUserFocusFill}
        />
        <SidebarItem
          to="/coaching/benchmarking"
          labelKey="sidebar.item.coaching.benchmarkProfiles"
          icon={AiFillIdcard}
        />
        <SidebarItem
          to="/coaching/analytics"
          labelKey="sidebar.item.coachingDashboard"
          icon={IoAnalyticsSharp}
        />
      </SidebarSection>
    </>
  )
}
