import { Log } from '@repo/utils'
import { useLocalStorage } from '@uidotdev/usehooks'
import moment from 'moment'
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import {
  createIntl,
  createIntlCache,
  type IntlConfig,
  IntlProvider as IntlProviderLib,
  type IntlShape,
} from 'react-intl'

import { detectDefaultLanguage } from './intl-provider/detect-default-language'
import de from './locales/de.json'
import en from './locales/en.json'
import es from './locales/es.json'
import fr from './locales/fr.json'
import it from './locales/it.json'
import pt from './locales/pt.json'
import { type LanguageKey } from './types'

const allMessages: Record<LanguageKey, Record<string, string>> = {
  'de-DE': de,
  'en-US': en,
  'es-ES': es,
  'fr-FR': fr,
  'it-IT': it,
  'pt-PT': pt,
}

const cache = createIntlCache()

export const intlObjectsForEachLocale: Record<LanguageKey, IntlShape> = {
  'en-US': createIntl({ locale: 'en-US', messages: allMessages['en-US'] }, cache),
  'de-DE': createIntl({ locale: 'de-DE', messages: allMessages['de-DE'] }, cache),
  'es-ES': createIntl({ locale: 'es-ES', messages: allMessages['es-ES'] }, cache),
  'pt-PT': createIntl({ locale: 'pt-PT', messages: allMessages['pt-PT'] }, cache),
  'it-IT': createIntl({ locale: 'it-IT', messages: allMessages['it-IT'] }, cache),
  'fr-FR': createIntl({ locale: 'fr-FR', messages: allMessages['fr-FR'] }, cache),
}

const defaultRichTextElements: IntlConfig['defaultRichTextElements'] = {
  b: chunks => <b>{chunks}</b>,
  i: chunks => <i>{chunks}</i>,
}

type LanguageContextType = {
  language: LanguageKey
  setLanguage: (locale: LanguageKey) => void
}

const LanguageContext = createContext<LanguageContextType | null>(null)

const defaultLanguage = detectDefaultLanguage()

export const IntlProvider = ({ children }: PropsWithChildren) => {
  const [language, setLanguage] = useLocalStorage<LanguageKey>(
    'retorio-language',
    defaultLanguage
  )

  useEffect(() => {
    Log.setTag('locale', language)
    moment.locale(language)
  }, [language])

  const value = useMemo<LanguageContextType | null>(
    () => ({
      language,
      setLanguage,
    }),
    [language, setLanguage]
  )

  return (
    <LanguageContext.Provider value={value}>
      <IntlProviderLib
        messages={allMessages[language]}
        locale={language}
        defaultLocale={defaultLanguage}
        onError={error => Log.error(error, { fingerprint: ['intl-error'] })}
        defaultRichTextElements={defaultRichTextElements}
      >
        {children}
      </IntlProviderLib>
    </LanguageContext.Provider>
  )
}

export const useLanguageContext = () => {
  const context = useContext(LanguageContext)

  if (!context) {
    throw new Error('useLanguageContext must be used within a LanguageContextProvider')
  }

  return context
}
