import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, RHF, showToast, useFormRules } from '@repo/ui'
import { assertValueEqualsTarget } from '@repo/utils'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdOutlineTipsAndUpdates } from 'react-icons/md'

import IntroStage from '../../../../../assets/intro-stage.svg'
import { CenteredSpinner } from '../../../../../components/centered-spinner'
import { useUserSessionId } from '../../../../../store/entities/user-activity-session/use-user-session-id'
import { client } from '../../../../../utils/openapi-client'
import { StickyFooter } from '../../../../shared/sticky-footer'
import { useRealtimeBuilderContext } from '../../../shared/realtime-builder-context'
import { useInteractionStepContext } from '../interaction-step-context'
import { type Stage, stageConfigs } from './shared/stage-configs'
import { BehavioralAttitudeItem } from './step-behavioral-attitude/behavioral-attitude-item'
import { BehavioralAttitudeSection } from './step-behavioral-attitude/behavioral-attitude-section'

export const StepBehavioralAttitude = () => {
  const {
    scenarioExtId,
    programExtId,
    trainingExtId,
    scenario,
    mutateScenario,
    isBuilderReadonly,
  } = useRealtimeBuilderContext()

  const { goToPreviousStep, goToNextStep } = useInteractionStepContext()
  const { formatMessage } = useIntl()

  const userSessionId = useUserSessionId()

  const [isFetchingAiSuggestions, setIsFetchingAiSuggestions] = useState(true)

  type FormValues = {
    beginning: string
    mainPart: string
    end: string
    final: string
    intro: string
  }

  const orderedStages: Array<Stage> = ['beginning', 'mainPart', 'end', 'final']

  const { control, handleSubmit, formState, setValue } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      intro: scenario?.personaOpeningStatement ?? '',
      beginning: scenario.missionStages?.[0]?.personaAttitude ?? '',
      mainPart: scenario.missionStages?.[1]?.personaAttitude ?? '',
      end: scenario.missionStages?.[2]?.personaAttitude ?? '',
      final: scenario?.finalPersonaAttitude ?? '',
    },
    disabled: isBuilderReadonly,
  })

  const fetchAiSuggestions = useCallback(async () => {
    try {
      const res = await client.post('generateScenarioContentForRealtimeScenario', {
        body: {
          field: 'initial_statements',
          userActivitySessionExtId: userSessionId,
        },
        params: {
          path: {
            programExtId,
            trainingExtId,
            scenarioExtId,
          },
        },
      })

      if (res.error) {
        throw new Error()
      }

      assertValueEqualsTarget(res.data.field, 'initial_statements')

      setValue('intro', res.data.suggestion, { shouldValidate: true, shouldDirty: true })
    } catch (error) {
      showToast({
        messageKey: 'scenario.ai.builder.steps.conversationGeneration.suggestions.failed',
        status: 'error',
      })
    } finally {
      setIsFetchingAiSuggestions(false)
    }
  }, [programExtId, scenarioExtId, trainingExtId, setValue, userSessionId])

  useEffect(() => {
    const shouldFetchSuggestions = !scenario?.personaOpeningStatement

    if (shouldFetchSuggestions) {
      fetchAiSuggestions()
    } else {
      setIsFetchingAiSuggestions(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rules = useFormRules()

  const onSubmit = useCallback(
    async (data: FormValues) => {
      if (!formState.isDirty) {
        return goToNextStep()
      }

      const res = await client.post('saveBehavioralAttitudes', {
        body: {
          personaOpeningStatement: data.intro,
          personaAttitudes: {
            0: data.beginning,
            1: data.mainPart,
            2: data.end,
          },
          finalPersonaAttitude: data.final,
        },
        params: {
          path: {
            extId: scenarioExtId,
            programExtId,
            trainingExtId,
          },
        },
      })

      if (res.error) {
        return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }

      // TODO make optimistic update
      await mutateScenario()

      goToNextStep()
    },
    [
      formState.isDirty,
      goToNextStep,
      mutateScenario,
      scenarioExtId,
      programExtId,
      trainingExtId,
    ]
  )

  if (isFetchingAiSuggestions) {
    return <CenteredSpinner />
  }

  return (
    <Stack as="form" flex={1} onSubmit={handleSubmit(onSubmit)}>
      <Stack alignItems="flex-start" maxW="1000px">
        <BehavioralAttitudeItem color={colors.blue[500]} icon={MdOutlineTipsAndUpdates}>
          <Box
            p="24px"
            flex={1}
            borderRadius="8px"
            bg={colors['secondary-light']}
            alignItems="flex-start"
          >
            <Text fontSize="16px" color={colors.blue[700]}>
              <FormattedMessage id="scenario.builder.ai.steps.behavioral.attitude.info.text" />
            </Text>
          </Box>
        </BehavioralAttitudeItem>
        {orderedStages.map((stage, index) => {
          const { name, colorScheme, number, color } = stageConfigs[stage]

          const isBeforeLastStage = index === orderedStages.length - 2

          return (
            <Fragment key={stage}>
              <BehavioralAttitudeItem
                colorScheme={colorScheme}
                name={name}
                color={color}
                stageIndex={number}
              >
                {name === 'beginning' && (
                  <HStack gap={2} alignItems="flex-start" w="full" mb={4}>
                    <Image src={IntroStage} mt={6} />
                    <RHF.TextArea
                      control={control}
                      name="intro"
                      componentProps={{
                        placeholder: formatMessage({
                          id: 'scenario.builder.ai.steps.behavioral.attitude.intro.label.text',
                        }),
                      }}
                      rules={rules.stringLongRequired}
                      helperTextKey="scenario.builder.ai.steps.behavioral.attitude.intro.label.edit"
                      labelKey="scenario.builder.ai.steps.behavioral.attitude.intro.label.text"
                    />
                  </HStack>
                )}
                <BehavioralAttitudeSection name={name} control={control} />
              </BehavioralAttitudeItem>

              {isBeforeLastStage && (
                <BehavioralAttitudeItem
                  color={colors.blue[500]}
                  icon={MdOutlineTipsAndUpdates}
                >
                  <Box
                    p="24px"
                    flex={1}
                    borderRadius="8px"
                    bg={colors['secondary-light']}
                    alignItems="flex-start"
                  >
                    <Text fontSize="16px" color={colors.blue[700]}>
                      <FormattedMessage id="scenario.builder.ai.steps.behavioral.attitude.info.text2" />
                    </Text>
                  </Box>
                </BehavioralAttitudeItem>
              )}
            </Fragment>
          )
        })}
      </Stack>
      <StickyFooter
        onBack={goToPreviousStep}
        isNextDisabled={!formState.isValid}
        isNextLoading={formState.isSubmitting}
      />
    </Stack>
  )
}
