import { Button, Center, Checkbox, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { type ChangeEvent, useMemo, useState } from 'react'

import { type Question, type ScenarioTree } from '../../../../../shared/types'

// TODO: empty state, when there is no quiz in selected scenarios
export const SelectQuestionsStep = ({
  handleSubmit,
  selectedScenarioExtIds,
  scenarioTree,
}: {
  handleSubmit: (questions: Array<Question>) => void
  selectedScenarioExtIds: Array<string>
  scenarioTree: Array<ScenarioTree>
}) => {
  const filteredScenarioTree = useMemo(
    () =>
      scenarioTree
        .map(program => ({
          ...program,
          trainings: program.trainings
            .map(training => ({
              ...training,
              scenarios: training.scenarios.filter(scenario =>
                selectedScenarioExtIds.includes(scenario.extId)
              ),
            }))
            .filter(training => training.scenarios.length > 0),
        }))
        .filter(program => program.trainings.length > 0),
    [scenarioTree, selectedScenarioExtIds]
  )

  const [selectedQuestions, setSelectedQuestions] = useState<Array<Question>>([])

  const questions = filteredScenarioTree
    .map(program =>
      program.trainings.map(training =>
        training.scenarios.map(scenario => scenario.quizSteps)
      )
    )
    .flat(3)
    .flat(2)

  const totalQuizCount = questions.length

  const handleSelectAll = () => {
    if (selectedQuestions.length === totalQuizCount) {
      return setSelectedQuestions([])
    }

    setSelectedQuestions(questions)
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (selectedQuestions.some(question => question.extId === value)) {
      setSelectedQuestions(selectedQuestions.filter(question => question.extId !== value))
    } else {
      setSelectedQuestions([
        ...selectedQuestions,
        questions.find(q => q.extId === value)!,
      ])
    }
  }

  return (
    <>
      <Stack gap={6}>
        <Checkbox
          ml="44px"
          isChecked={totalQuizCount === selectedQuestions.length}
          onChange={handleSelectAll}
        >
          <FormattedMessage id="dashboard.resultPerQuestion.modal.selectAll" />
        </Checkbox>

        <Stack overflowY="auto" maxHeight="360px" px={6}>
          {filteredScenarioTree.map(program => (
            <Stack key={program.extId}>
              {program.trainings.map(training => (
                <Stack key={training.extId}>
                  {training.scenarios.map(scenario => (
                    <Stack key={scenario.extId}>
                      <Text fontWeight="medium">
                        {`${program.title} > ${training.title} > `}
                        <Text as="span" fontWeight="bold">
                          {scenario.title}
                        </Text>
                      </Text>
                      <Stack pl={5}>
                        {scenario.quizSteps.map(step => (
                          <Checkbox
                            isChecked={selectedQuestions.some(
                              question => question.extId === step.extId
                            )}
                            onChange={handleCheckboxChange}
                            key={step.extId}
                            value={step.extId}
                          >
                            {step.questionText}
                          </Checkbox>
                        ))}
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              ))}
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Center>
        <Button
          isDisabled={!selectedQuestions.length}
          onClick={() => {
            handleSubmit(selectedQuestions)
          }}
        >
          <FormattedMessage id="dashboard.resultPerQuestion.modal.submit" />
        </Button>
      </Center>
    </>
  )
}
