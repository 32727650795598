import {
  Tab,
  TabList,
  TabPanel as ChakraTabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppHeaderTitle } from '../../components/app-header-title'
import { PageList } from '../../containers/page-list'
import { CohortsTable } from './cohorts-table'
import { UserTable } from './user-table'

type Params = {
  tab?: string // Define the parameter and its type
}

const TabPanel = ({ children, ...props }) => (
  <ChakraTabPanel {...props} height="100%" display="flex" flexDir="column" pb={0}>
    {children}
  </ChakraTabPanel>
)

const getTabParamFromIndex = (index: number) => (index === 0 ? 'cohorts' : 'participants')

// Map URL parameter to tab index
const getTabIndexFromParam = (param: string) => (param === 'cohorts' ? 0 : 1)

export const ParticipantsPage = () => {
  const { tab } = useParams<Params>()
  const navigate = useNavigate() // Use useHistory instead of useNavigate

  const [tabIndex, setTabIndex] = useState(getTabIndexFromParam(tab || 'cohorts'))

  useEffect(() => {
    setTabIndex(getTabIndexFromParam(tab || 'cohorts'))
  }, [tab])

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
    const newTabParam = getTabParamFromIndex(index)

    navigate(`/participants/${newTabParam}`)
  }

  return (
    <PageList display="flex" flexDir="column">
      <AppHeaderTitle formattedMessageId="coaching.participants.title" />

      <Tabs
        h="100%"
        w="100%"
        display="flex"
        flexDir="column"
        isLazy
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <TabList>
          <Tab>
            <FormattedMessage id="coaching.benchmarking.tabs.cohorts" />
          </Tab>
          <Tab>
            <FormattedMessage id="coaching.benchmarking.tabs.participants" />
          </Tab>
        </TabList>
        <TabPanels overflow="auto">
          <TabPanel>
            <CohortsTable isBenchmarking={false} />
          </TabPanel>
          <TabPanel>
            <UserTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageList>
  )
}
