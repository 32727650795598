import { type DataSlotType, type SelectedDataSlotPerCard } from './types'

export const generateSelectedDataSlotPerCard = (
  dataSlot: DataSlotType
): SelectedDataSlotPerCard => ({
  behavioralAdaptation: dataSlot,
  traineeCount: dataSlot,
  recordingCount: dataSlot,
  learningDevelopmentFunnel: dataSlot,
  scenarioRepetitions: dataSlot,
  popularScenarios: dataSlot,
  competencyTime: dataSlot,
  communicationAndLanguage: dataSlot,
  knowledgeCheck: dataSlot,
  messaging: dataSlot,
  cohortPerformance: dataSlot,
})
