import {
  Button,
  ButtonGroup,
  HStack,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { HelpTooltip } from '@repo/ui'
import { useFormikContext } from 'formik'
import { type ChangeEvent, useCallback, useState } from 'react'
import { RiQuestionnaireLine } from 'react-icons/ri'

import { type CoachingStepFormFields } from '../../../../../builder/steps/api'
import { useOrganization } from '../../../../../hooks/use-organization'
import { AnswerStep } from './answer-step'
import { GoalStep } from './question-and-answer-step/goal-step'
import { RecordIntro } from './record-intro'

interface QuestionAndAnswerStepProps {
  disabled: boolean
}

export const QuestionAndAnswerStep = ({ disabled }: QuestionAndAnswerStepProps) => {
  const { formatMessage } = useIntl()
  const { hasUnderstanding } = useOrganization()

  const { setFieldValue, values, submitForm, isSubmitting, isValid, dirty } =
    useFormikContext<CoachingStepFormFields>()

  const { step } = values

  if (step.stepType !== 'coachingStepQA') {
    throw new Error('Wrong step type')
  }

  const [tabIndex, setTabIndex] = useState(0)
  const [showDonts, setShowDonts] = useState(!!step.donts?.length)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('step.maxRecordingTime', Number.parseInt(event.target.value, 10))
  }

  const handleDontsClose = useCallback(() => {
    setShowDonts(false)
    setFieldValue('step.donts', [])
  }, [setFieldValue])

  const maxIndex = hasUnderstanding ? 2 : 1

  return (
    <Stack p={5}>
      <HStack>
        <Text>
          <FormattedMessage id="step.questionAnswer.header" />
        </Text>
        <HelpTooltip label={formatMessage({ id: 'step.questionAnswer.tooltip' })} />
      </HStack>

      <VStack spacing={3} align="stretch" w="full">
        <Tabs
          onChange={index => setTabIndex(index)}
          position="relative"
          index={tabIndex}
          isFitted
          variant="unstyled"
          // Keeping both question video and answer video tabs in memory helps with issues with starting parallel uploads
          // Until we make a change which solves this issue, we will remove `isLazy` parameter
          // https://gitlab.com/retorio/platform/js-clients/-/issues/1088
          // isLazy
        >
          <TabList>
            <Tab _focus={{ boxShadow: 'none' }} flexDir="column">
              <Text>
                <FormattedMessage id="step.questionAndAnswer.step.1" />
              </Text>
              <Text>
                <FormattedMessage id="step.question.button.title" />
              </Text>
            </Tab>
            <Tab _focus={{ boxShadow: 'none' }} flexDir="column">
              <Text>
                <FormattedMessage id="step.questionAndAnswer.step.2" />
              </Text>
              <Text>
                <FormattedMessage id="step.answer.button.title" />
              </Text>
            </Tab>

            {hasUnderstanding && (
              <Tab _focus={{ boxShadow: 'none' }} flexDir="column">
                <Text>
                  <FormattedMessage id="step.questionAndAnswer.step.3" />
                </Text>
                <Text>
                  <FormattedMessage id="step.goals.button.title" />
                </Text>
              </Tab>
            )}
          </TabList>
          <TabIndicator mt="-1.5px" height="2px" bg="blue.500" />
          <TabPanels>
            <TabPanel>
              <RecordIntro
                disabled={disabled}
                title={formatMessage({ id: 'step.question.intro.title' })}
                description={formatMessage({ id: 'step.question.intro.description' })}
                buttonUploadText={formatMessage({ id: 'common.upload' })}
                hints={[
                  formatMessage({ id: 'step.question.intro.hint.1' }),
                  formatMessage({ id: 'step.question.intro.hint.2' }),
                ]}
                icon={RiQuestionnaireLine}
                fieldName="step.questionUrl"
                videoDurationLimit={60}
              />
            </TabPanel>
            <TabPanel>
              <AnswerStep
                handleChange={handleChange}
                disabled={disabled}
                hasUnderstanding={hasUnderstanding}
              />
            </TabPanel>
            {hasUnderstanding && (
              <TabPanel>
                <GoalStep
                  disabled={disabled}
                  handleClose={handleDontsClose}
                  hasUnderstanding={hasUnderstanding}
                  setShowDonts={setShowDonts}
                  showDonts={showDonts}
                />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </VStack>
      <ButtonGroup pos="absolute" bottom={3} right={0}>
        {tabIndex < maxIndex && (
          <Button colorScheme="blue" mr={3} onClick={() => setTabIndex(tabIndex + 1)}>
            <FormattedMessage id="common.continue" />
          </Button>
        )}
        {tabIndex === maxIndex && (
          <Button
            colorScheme="blue"
            mr={3}
            onClick={submitForm}
            isLoading={isSubmitting}
            isDisabled={!isValid || !dirty}
          >
            <FormattedMessage id="common.save" />
          </Button>
        )}
      </ButtonGroup>
    </Stack>
  )
}
