import { Box, Flex, Text, useToken } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { type AnyObject } from '@repo/utils'
import { useState } from 'react'
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart } from 'recharts'

import { Dot } from '../recording-details/dot'

export type DataItem = {
  subject: string
  applicant: number
  company: number
}

type TargetMatchChartProps = {
  data: Array<DataItem>
  width?: number
  height?: number
  selectedTick?: string | number
  onLabelClick?: (label: string) => void
  showTargetProfile: boolean
  candidateView: boolean
}
type LegendProps = {
  showTargetProfile: boolean
  candidateView: boolean
}

export const Legend = ({ candidateView, showTargetProfile }: LegendProps) => (
  <Flex fontSize="14px" fontWeight={500}>
    {showTargetProfile && (
      <Flex alignItems="center">
        <Dot backgroundColor="#78E296" />
        <Text ml={2}>
          <FormattedMessage id="recording.details.big5PersonalityProfile.targetProfile.label" />
        </Text>
      </Flex>
    )}
    <Flex alignItems="center" ml={showTargetProfile ? 10 : 0}>
      <Dot backgroundColor="#17459C" />
      <Text ml={2}>
        {candidateView ? (
          <FormattedMessage id="recording.details.big5PersonalityProfile.you.candidateResult.label" />
        ) : (
          <FormattedMessage id="recording.details.big5PersonalityProfile.candidateResult.label" />
        )}
      </Text>
    </Flex>
  </Flex>
)

export const TargetMatchChart = ({
  data,
  width,
  selectedTick,
  height,
  onLabelClick,
  showTargetProfile,
  candidateView,
}: TargetMatchChartProps) => {
  const { formatMessage } = useIntl()
  const [hoveredTick, setHoveredTick] = useState(undefined)
  const [primaryBlue] = useToken('colors', ['primary'])

  return (
    <Box
      sx={{
        'div > svg': {
          overflow: 'visible',
        },
      }}
    >
      <RadarChart width={width} height={height} data={data}>
        <PolarGrid />
        <PolarAngleAxis
          dataKey="subject"
          fontSize="14px"
          fontWeight="500"
          onMouseOver={target => setHoveredTick(target.value)}
          onMouseOut={() => setHoveredTick(undefined)}
          onClick={target => onLabelClick?.(target.value)}
          tick={({ payload, ...props }: AnyObject) => (
            <text
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{
                ...props,
                cursor: onLabelClick ? 'pointer' : 'default',
                fill:
                  selectedTick === payload.value ||
                  (selectedTick && hoveredTick === payload.value)
                    ? primaryBlue
                    : 'inherit',
                fontWeight: selectedTick === payload.value ? 'bold' : 'inherit',
                fontSize:
                  selectedTick === payload.value ||
                  (selectedTick && hoveredTick === payload.value)
                    ? '1rem'
                    : 'inherit',
              }}
            >
              <FormattedMessage
                id={`recording.list.filters.${payload.value}` as I18nKey}
              />
            </text>
          )}
          tickFormatter={(value: string) =>
            formatMessage({ id: `recording.list.filters.${value}` as I18nKey })
          }
        />
        <PolarRadiusAxis axisLine={false} tick={false} domain={[0, 1]} />
        {showTargetProfile && (
          <Radar
            name={formatMessage({ id: 'targetMatchChart.legend.company' })}
            dataKey="company"
            stroke="#68D391"
            fill="#68D391"
            fillOpacity={0.6}
            strokeWidth={3}
          />
        )}
        <Radar
          name={formatMessage({ id: 'targetMatchChart.legend.applicant' })}
          dataKey="applicant"
          stroke="#2B6CB0"
          fill="#2B6CB0"
          fillOpacity={0.3}
          strokeWidth={3}
        />
      </RadarChart>
      <Flex width={width} justify="center">
        <Legend candidateView={candidateView} showTargetProfile={showTargetProfile} />
      </Flex>
    </Box>
  )
}
