const getScrollbarStyles = (color: string) => ({
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: color,
    borderRadius: '10px',
  },

  scrollbarWidth: 'thin', // For Firefox
  scrollbarColor: `${color} transparent`,
})

export const scrollbarStylesBlack = getScrollbarStyles('rgba(0, 0, 0, 0.3)')

export const scrollbarStylesWhite = getScrollbarStyles('rgba(255, 255, 255, 0.5)')
