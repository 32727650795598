import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

import { NoResultsCell } from '../../shared/no-results-cell'

type Item = {
  name: string
  value: string | number | null
  isHiddenMetric?: boolean
}

interface DashboardNameValueListProps {
  data: Array<Item>
  tableHeadLabelOne: I18nKey
  tableHeadLabelTow: I18nKey
  hideNumbers?: boolean
}

export const DashboardNameValueList = ({
  data,
  tableHeadLabelOne,
  tableHeadLabelTow,
  hideNumbers,
}: DashboardNameValueListProps) => (
  <TableContainer width="full">
    <Table fontSize="14px" layout="fixed">
      <Thead>
        <Tr whiteSpace="normal">
          <Th width="60%" pl={0} fontSize="14px">
            <FormattedMessage id={tableHeadLabelOne} />
          </Th>
          <Th px={0} isNumeric>
            <Text fontSize="14px">
              <FormattedMessage id={tableHeadLabelTow} />
            </Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Tr key={index}>
            <Td display="flex" flexDirection="row" px={0}>
              {!hideNumbers && (
                <Box
                  minW="20px"
                  w="20px"
                  h="20px"
                  mr="16px"
                  borderRadius="50%"
                  bg="blue.400"
                >
                  <Text textAlign="center" fontSize="12px" color="white">
                    {index + 1}
                  </Text>
                </Box>
              )}
              <Text title={item.name} overflow="hidden" textOverflow="ellipsis">
                {item.name}
              </Text>
            </Td>
            <Td pr={0} isNumeric>
              {item.value === null ? (
                <NoResultsCell isHiddenMetric={item.isHiddenMetric} />
              ) : (
                <Text>{item.value}</Text>
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </TableContainer>
)
