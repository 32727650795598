import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast, useConfirmationModal } from '@repo/ui'
import { RiMoreFill } from 'react-icons/ri'

import { type BehavioralGoal } from '../../../../types/api-types'
import { client } from '../../../../utils/openapi-client'
import { useRealtimeBuilderContext } from '../../shared/realtime-builder-context'
import { useEditBehavioralGoalModal } from './use-edit-behavioral-goal-modal'

type OptionsMenuProps = {
  goal: BehavioralGoal
}

export const OptionsMenu = ({ goal }: OptionsMenuProps) => {
  const {
    programExtId,
    scenarioExtId,
    trainingExtId,
    mutateScenario,
    isBuilderReadonly,
  } = useRealtimeBuilderContext()

  const [editModal, showEditModal] = useEditBehavioralGoalModal(goal)

  const [deleteModal, showDeleteModal] = useConfirmationModal({
    onConfirm: async () => {
      try {
        const res = await client.delete('deleteWinningBehaviorRealtime', {
          params: {
            path: {
              extId: goal.extId,
              programExtId,
              scenarioExtId,
              trainingExtId,
            },
          },
        })

        if (res.error) {
          throw new Error()
        }

        // TODO optimistic update
        await mutateScenario()

        showToast({ messageKey: 'common.alert.deleted', status: 'success' })
      } catch (error) {
        showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }
    },
    titleKey: 'scenario.builder.realtime.steps.goal.delete.title',
    descriptionKey: 'scenario.builder.realtime.steps.goal.delete.text',
    isDestructive: true,
  })

  return (
    <Menu>
      {deleteModal}
      {editModal}

      <MenuButton
        as={IconButton}
        variant="ghost"
        icon={<RiMoreFill />}
        onClick={e => e.stopPropagation()}
      />
      <MenuList onClick={e => e.stopPropagation()}>
        <MenuItem onClick={showEditModal} isDisabled={isBuilderReadonly}>
          <FormattedMessage id="common.edit" />
        </MenuItem>
        <MenuItem
          color="indicator.red-dark"
          onClick={showDeleteModal}
          isDisabled={isBuilderReadonly}
        >
          <FormattedMessage id="common.delete" />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
