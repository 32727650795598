import { Box, Flex, Stack } from '@chakra-ui/react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { FormattedMessage } from '@repo/i18n'
import { type PropsWithChildren } from 'react'

import { DragHandle } from '../../../../components/drag-handle'
import { type DashboardSectionId } from '../../../shared/types'

export const SortableSection = ({
  children,
  sectionId,
}: PropsWithChildren<{
  sectionId: DashboardSectionId
}>) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: sectionId,
  })

  return (
    <Stack
      transition={transition}
      transform={CSS.Translate.toString(transform)}
      ref={setNodeRef}
    >
      <Flex align="center" cursor="grab" {...listeners} {...attributes}>
        <DragHandle />
        <Box fontWeight="medium">
          <FormattedMessage
            id={`dashboard.sections.${sectionId}.title` satisfies I18nKey}
          />
        </Box>
      </Flex>

      <Stack>{children}</Stack>
    </Stack>
  )
}
