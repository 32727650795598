import { isNotNil } from './type'
import { type Nil } from './typescript'

type StringDictionary<T extends string> = {
  [K in T]: K
}

/**
 * Transforms an array of string to an Object with the same props/values.
 */
export const stringArrToObj = <T extends string>(
  a: ReadonlyArray<T>
): StringDictionary<T> =>
  a.reduce(
    (acc, item) => (item ? { ...acc, [item]: item } : acc),
    {} as StringDictionary<T>
  )

export const removeAt = <T>(index: number, list: Array<T>): Array<T> => [
  ...list.slice(0, index),
  ...list.slice(index + 1),
]

export const updateAt = <T>(index: number, item: T, list: Array<T>): Array<T> =>
  Object.assign([...list], { [index]: item })

/**
 * Map and filter not nil values
 */
export const mapAndFilterNotNil = <T, TR>(
  mapFn: (a: T, index: number) => Nil<TR>,
  list: Array<T>
): Array<TR> =>
  list.reduce((acc, item, index) => {
    const mappedItem = mapFn(item, index)

    return isNotNil(mappedItem) ? [...acc, mappedItem] : acc
  }, [] as Array<TR>)

export const generateSequenceArray = (length: number): Array<number> =>
  Array.from({ length }).map((_, i) => i + 1)
