import { type ComponentMultiStyleConfig, type SystemStyleObject } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

// Source: https://chakra-ui.com/docs/theming/component-style

// Most of the styles have been copied from the Chakra Button
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/button.ts

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = Record<string, any>

type AccessibleColor = {
  bg?: string
  color?: string
  hoverBg?: string
  activeBg?: string
  checkedBg?: string
}
type AccessibleColorMap = { [key: string]: AccessibleColor }

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: AccessibleColorMap = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
}

const baseStyleRadioButtonObj: SystemStyleObject = {
  lineHeight: '1.2',
  fontWeight: 'medium',
  borderRadius: 'base',
  _focus: {
    zIndex: 1,
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
  _hover: {
    _disabled: {
      bg: 'initial',
    },
  },
}

const baseStyleRadioButton = (props: Dict) => {
  const { colorScheme: c } = props

  if (c === 'gray') {
    const bg = mode(`gray.100`, `whiteAlpha.200`)(props)

    return {
      ...baseStyleRadioButtonObj,
      bg,
      _hover: {
        bg: mode(`gray.200`, `whiteAlpha.300`)(props),
        _disabled: {
          bg,
        },
      },
      _active: { bg: mode(`gray.300`, `whiteAlpha.400`)(props) },
      _checked: {
        cursor: 'default',
        bg: mode(`gray.300`, `whiteAlpha.400`)(props),
      },
    }
  }

  const {
    bg = `${c}.400`,
    color = 'white',
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`,
    checkedBg = `${c}.700`,
  } = accessibleColorMap[c] || {}

  const background = mode(bg, `${c}.200`)(props)

  return {
    ...baseStyleRadioButtonObj,
    bg: background,
    color: mode(color, `gray.800`)(props),
    _hover: {
      bg: mode(hoverBg, `${c}.300`)(props),
      _disabled: {
        bg: background,
      },
    },
    _active: { bg: mode(activeBg, `${c}.400`)(props) },
    _checked: {
      cursor: 'default',
      bg: mode(checkedBg, `${c}.400`)(props),
    },
  }
}

const baseStyle = (props: Dict) => ({
  'radio-button': baseStyleRadioButton(props),
})

const radioButtonTheme: ComponentMultiStyleConfig = {
  parts: ['radio-button-group', 'radio-button'],
  baseStyle,
  sizes: {
    lg: {
      'radio-button': {
        h: 12,
        minW: 12,
        fontSize: 'lg',
        px: 6,
      },
    },
    md: {
      'radio-button': {
        h: 10,
        minW: 10,
        fontSize: 'md',
        px: 4,
      },
    },
    sm: {
      'radio-button': {
        h: 8,
        minW: 8,
        fontSize: 'sm',
        px: 3,
      },
    },
    xs: {
      'radio-button': {
        h: 6,
        minW: 6,
        fontSize: 'xs',
        px: 2,
      },
    },
  },

  defaultProps: {
    size: 'sm',
    colorScheme: 'blue',
  },
}

export { radioButtonTheme }
