import { round } from 'lodash-es'

import { type BenchmarkProfile } from '../recruiting/recording-sessions-api'

// convert valuesV2 format to values format
// TODO: instead of changing format to old version, update BenchmarkProfileEditor component
export const getValues = (valuesV2: BenchmarkProfile) =>
  valuesV2.reduce(
    (o: { [key: string]: number }, value) => ({
      ...o,
      [value.name]: round(value.ideal / 100, 3),
    }),
    {}
  )

export const getWeights = (valuesV2: BenchmarkProfile) =>
  valuesV2.reduce(
    (o: { [key: string]: number }, value) => ({
      ...o,
      [value.name]: value.weight,
    }),
    {}
  )
