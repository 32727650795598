import { Box, Spacer } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { useMemo } from 'react'

import { CustomLineChart } from '../shared/custom-line-chart'
import { DashboardCard } from '../shared/dashboard-card'
import { ScoreCircle } from '../shared/score-circle'
import { useDashboardCardData } from '../shared/use-dashboard-card-data'
import { DetailPageLink } from './shared/detail-page-link'
import { NoDataInformation } from './shared/no-data-information'

export const KnowledgeCheckCard = () => {
  const { dataSlotSelector, dataSlot } = useDashboardCardData('knowledgeCheck')

  const shouldShowEmptyState = dataSlot.status !== 'loaded'

  const knowledgeCheckData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    return data.startDatesFormatted.map((date, index) => ({
      date,
      knowledgeCheck: data.knowledgeCheckScoreOverallPerTimestamp[index]!,
    }))
  }, [dataSlot])

  const isEmptyData =
    dataSlot.status === 'loaded' && dataSlot.data.knowledgeCheckScoreOverall === null

  return (
    <DashboardCard
      titleKey="dashboard.cards.knowledgeCheck.title"
      descriptionKey="dashboard.cards.knowledgeCheck.subtitle"
      colSpan={3}
      wrapperProps={{ pt: 5, flex: 1 }}
      titleEndContent={
        !shouldShowEmptyState &&
        dataSlot.data.knowledgeCheckScoreOverall !== null && (
          <ScoreCircle value={dataSlot.data.knowledgeCheckScoreOverall} />
        )
      }
      dataSlotSelector={dataSlotSelector}
      isLoading={dataSlot.status !== 'loaded'}
    >
      {knowledgeCheckData.length && !isEmptyData ? (
        <>
          <Box height={300} mb={14}>
            <CustomLineChart
              data={knowledgeCheckData}
              yAxisTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              yAxisLabelKey="dashboard.cards.behavioralAdaptation.yaxis.label"
              dataConfig={[{ dataKey: 'knowledgeCheck', color: colors.blue[400] }]}
              valueSuffix="%"
            />
          </Box>

          <Spacer />
          <DetailPageLink
            page="knowledge_check_details"
            i18nKey="dashboard.page.compareCohortResults"
          />
        </>
      ) : (
        <NoDataInformation />
      )}
    </DashboardCard>
  )
}
