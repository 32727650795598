import { type ReactNode, useMemo } from 'react'
import {
  type MessageDescriptor,
  type PrimitiveType,
  useIntl as useIntlLib,
} from 'react-intl'

import { defaultFormattingValues } from './default-formatting-values'
import { transformReactNodesToString } from './use-intl/transform-react-nodes-to-string'

type MessageValues = Record<string, ReactNode | PrimitiveType>

export const useIntl = () => {
  const intl = useIntlLib()

  const memoized = useMemo(() => {
    const { formatMessage: formatMessageRaw, ...rest } = intl

    const formatMessage = (message: MessageDescriptor, values?: MessageValues) =>
      transformReactNodesToString(
        formatMessageRaw(message, { ...defaultFormattingValues, ...values })
      )

    return {
      ...rest,
      formatMessage,
    }
  }, [intl])

  return memoized
}
