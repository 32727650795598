import { css, Global } from '@emotion/react'

export const GlobalStyles = () => (
  <Global
    styles={css`
      html {
        overflow: hidden;
      }

      #app {
        height: 100vh;
        width: 100vw;
        overflow: auto;
      }
    `}
  />
)
