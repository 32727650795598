import {
  applyActionCode,
  sendEmailVerification as sendEmailVerificationFirebase,
  signInWithEmailAndPassword,
  signOut,
  type User as FirebaseUser,
} from 'firebase/auth'

import { firebaseAuth } from '../firebase'
import { api } from '../utils/api'

type SignupData = {
  firstName: string
  lastName: string
  email: string
  password: string
  contactConsent?: boolean
  legalConsent: boolean
  organizationName: string
  invitationId?: string
}

type User = {
  id: string
  createdAt: string
  updatedAt: string
  email: string
  profile: {
    firstName: string
    lastName: string
  }
  role: string
  contactConsent: false
  legalConsent: true
  organizationId: string
}

export const login = async (email: string, password: string) => {
  const loginData = await signInWithEmailAndPassword(firebaseAuth, email, password)

  return loginData
}

export const logout = async () => {
  await api.users.logout()

  return signOut(firebaseAuth)
}

export const sendEmailVerification = (user: FirebaseUser) =>
  sendEmailVerificationFirebase(user)

export const verifyEmail = (actionCode: string) =>
  applyActionCode(firebaseAuth, actionCode)

export const signup = async ({
  firstName,
  lastName,
  email,
  password,
  legalConsent,
  contactConsent = false,
  organizationName,
  invitationId,
}: SignupData): Promise<User> => {
  const user = await api.users.create({
    email,
    password,
    legalConsent,
    contactConsent,
    profile: {
      firstName,
      lastName,
    },
    organization: {
      name: organizationName,
    },
    invitation: invitationId,
  })

  return user
}
