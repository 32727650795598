import { Box, Flex } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { isEmpty, pick } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'

import { DashboardCard } from '../shared/dashboard-card'
import { useDashboardCardData } from '../shared/use-dashboard-card-data'
import { CustomSelectWithLabel } from './scenario-repetitions-card/custom-select-with-label'
import { RepetitionChart } from './scenario-repetitions-card/repetition-chart'
import { DashboardInfoBox } from './shared/dashboard-info-box'
import { NoDataInformation } from './shared/no-data-information'

export const ScenarioRepetitionsCard = () => {
  const [selectedCohort, setSelectedCohort] = useState<string>()
  const [selectedScenario, setSelectedScenario] = useState<string>()
  const { formatMessage } = useIntl()

  const { dataSlot } = useDashboardCardData('scenarioRepetitions')

  const repetitionForExcellenceCardData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return undefined
    }

    const { data } = dataSlot

    return {
      cohortNames: data.cohortNames,
      repetitionForExcellence: data.repetitionsForExcellence,
      scenariosMetadata: data.scenariosMetadata,
      cohortExtIds: data.cohortExtIds,
    }
  }, [dataSlot])

  const scenarioList = useMemo(() => {
    if (!repetitionForExcellenceCardData) {
      return {}
    }

    return repetitionForExcellenceCardData.cohortExtIds.reduce<
      Record<string, Record<string, string>>
    >((acc, cohortExtId, cohortIndex) => {
      const repetitionForExcellence =
        repetitionForExcellenceCardData.repetitionForExcellence[cohortIndex]

      if (repetitionForExcellence) {
        acc[cohortExtId] = Object.keys(repetitionForExcellence).reduce(
          (scenarioNames, scenarioExtId) => {
            scenarioNames[scenarioExtId] =
              repetitionForExcellenceCardData.scenariosMetadata[scenarioExtId]
                ?.scenarioName ?? ''

            return scenarioNames
          },
          {}
        )
      }

      return acc
    }, {})
  }, [repetitionForExcellenceCardData])

  const cohortsWithData = useMemo(
    () =>
      repetitionForExcellenceCardData?.cohortExtIds
        .map((cohortExtId, index) => ({
          value: cohortExtId,
          label: repetitionForExcellenceCardData.cohortNames[index] ?? '',
          data: repetitionForExcellenceCardData.repetitionForExcellence[index],
        }))
        .filter(item => !isEmpty(item.data))
        .map(item => pick(item, ['value', 'label'])),
    [repetitionForExcellenceCardData]
  )

  useEffect(() => {
    if (repetitionForExcellenceCardData && cohortsWithData) {
      const initialCohort = cohortsWithData[0]?.value

      if (initialCohort) {
        setSelectedCohort(initialCohort)
        setSelectedScenario(
          Object.keys(scenarioList[initialCohort] as Record<string, string>)[0]
        )
      }
    }
  }, [repetitionForExcellenceCardData, scenarioList, cohortsWithData])

  const repetitionForExcellenceChartData = useMemo(() => {
    if (!selectedCohort || !selectedScenario || !repetitionForExcellenceCardData) {
      return []
    }

    const cohortIndex =
      repetitionForExcellenceCardData.cohortExtIds.indexOf(selectedCohort)

    if (cohortIndex === -1) {
      return []
    }

    const predictedLabel = formatMessage({
      id: 'dashboard.cards.scenarioRepetitions.prediction',
    })

    const repetitionForExcellence =
      repetitionForExcellenceCardData.repetitionForExcellence[cohortIndex]?.[
        selectedScenario
      ]

    if (!repetitionForExcellence) {
      return []
    }

    return [
      ...repetitionForExcellence.repetitions.map((value, index) => ({
        repetition: index + 1,
        score: value,
        isPredicted: false,
        customLabel: undefined,
      })),
      ...repetitionForExcellence.predictions.map((value, index) => ({
        repetition: index + repetitionForExcellence.repetitions.length + 1,
        score: value,
        isPredicted: true,
        customLabel: predictedLabel,
      })),
    ]
  }, [formatMessage, repetitionForExcellenceCardData, selectedCohort, selectedScenario])

  const predictions = repetitionForExcellenceChartData?.filter(item => item.isPredicted)

  const handleCohortChange = (cohortExtId: string) => {
    setSelectedCohort(cohortExtId)
    setSelectedScenario(
      Object.keys(scenarioList[cohortExtId] as Record<string, string>)[0]
    )
  }

  const hasCohorts = cohortsWithData && cohortsWithData.length > 0

  return (
    <DashboardCard
      titleKey="dashboard.cards.scenarioRepetitions.title"
      descriptionKey="dashboard.cards.scenarioRepetitions.subtitle"
      colSpan={3}
      wrapperProps={{ alignItems: 'center' }}
      isLoading={dataSlot.status !== 'loaded'}
    >
      <Flex mt={4} w="full" flexWrap="wrap" align="flex-end" columnGap={10} rowGap={4}>
        {hasCohorts && (
          <>
            <CustomSelectWithLabel
              label={cohortsWithData?.find(item => item.value === selectedCohort)?.label}
              onChange={handleCohortChange}
              value={selectedCohort}
              options={cohortsWithData ?? []}
              labelKey="dashboard.cards.scenarioRepetitions.select.cohort.label"
            />

            {selectedCohort && (
              <CustomSelectWithLabel
                label={scenarioList[selectedCohort]?.[selectedScenario ?? '']}
                onChange={setSelectedScenario}
                value={selectedScenario}
                options={Object.entries(scenarioList[selectedCohort] ?? {}).map(
                  ([value, scenarioName]) => ({
                    value,
                    label: scenarioName,
                  })
                )}
                labelKey="dashboard.cards.scenarioRepetitions.select.scenario.label"
              />
            )}
          </>
        )}
      </Flex>

      {repetitionForExcellenceChartData?.length ? (
        <>
          <Box w="full" my={8}>
            <RepetitionChart data={repetitionForExcellenceChartData} />
          </Box>

          {predictions.length > 0 && (
            <DashboardInfoBox variant="success">
              <FormattedMessage
                id={
                  predictions.length > 1
                    ? 'dashboard.cards.scenarioRepetitions.infoBox.two'
                    : 'dashboard.cards.scenarioRepetitions.infoBox'
                }
                values={{ prediction: predictions.at(-1)?.score ?? 0 }}
              />
            </DashboardInfoBox>
          )}
        </>
      ) : (
        <NoDataInformation customInfoKey="dashboard.cards.scenarioRepetitions.empty.info" />
      )}
    </DashboardCard>
  )
}
