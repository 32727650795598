import {
  Box,
  Flex,
  Slider,
  SliderFilledTrack,
  type SliderProps,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'

type VideoSliderProps = {
  value?: number
  dividers: Array<number>
  hideThumb?: boolean
  onChange?: (val: number) => void
} & Pick<SliderProps, 'max' | 'step' | 'defaultValue'>

export const VideoSlider = ({
  onChange,
  dividers,
  value: valueProp,
  defaultValue,
  hideThumb,
  ...sliderProps
}: VideoSliderProps) => {
  const [value, setValue] = useState<number>(defaultValue || valueProp || 0)

  useEffect(() => {
    setValue(valueProp || 0)
  }, [valueProp])

  const onChangeHandler = useCallback(
    (newValue: number) => {
      setValue(newValue)
      if (onChange) {
        onChange(newValue)
      }
    },
    [onChange]
  )

  return (
    <Flex w="full" p="4" bg="#3333">
      <Slider
        {...sliderProps}
        value={value}
        flex="1"
        focusThumbOnChange={false}
        onChange={onChangeHandler}
      >
        <SliderTrack>
          <SliderFilledTrack background="gray.50" />
        </SliderTrack>
        {!!dividers?.length &&
          dividers.map(divider => (
            <Box
              key={divider}
              position="absolute"
              top="50%"
              left={`${divider}%`}
              h={4}
              w={1}
              bg="gray.50"
              shadow="sm"
              transform="translateY(-50%)"
            />
          ))}
        {!hideThumb && <SliderThumb />}
      </Slider>
    </Flex>
  )
}
