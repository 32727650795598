import { RHF, useFormRules } from '@repo/ui'
import { useFormContext } from 'react-hook-form'

import { useSelectOptions } from '../../../use-select-options'
import { type Persona, type PersonaFormValues } from '../../types'

const conversationContexts: Array<Persona['conversationContext']> = [
  'b2b',
  'b2c',
  'internal',
]

const jobRoleSuggestionsB2B = ['cmo', 'headOfProductMarketing', 'headOfSales'] as const
const jobRoleSuggestionsInternal = ['pm', 'headOfTraining', 'headOfLnD'] as const

const industrySuggestions = [
  'telecommunication',
  'insurance',
  'retailMobility',
  'travel',
  'energyAndLogistic',
  'fmcg',
  'financialServices',
  'healthCare',
  'it',
  'fashion',
] as const

export const StepProfessional = () => {
  const { control, watch } = useFormContext<PersonaFormValues['professional']>()
  const rules = useFormRules()
  const { getSelectOptions } = useSelectOptions()

  const selectedConversationContext = watch('conversationContext')

  const showJobRole =
    selectedConversationContext === 'b2b' || selectedConversationContext === 'internal'

  const isB2BSelected = selectedConversationContext === 'b2b'

  return (
    <>
      <RHF.AutocompleteSelect
        control={control}
        name="conversationContext"
        labelKey="scenario.builder.ai.steps.conversation-partner.formLabel.conversationContext"
        rules={rules.required}
        componentProps={{
          placeholderKey: 'common.select',
          options: getSelectOptions(
            conversationContexts,
            'persona.conversationContext',
            false
          ),
        }}
        tooltipKey="persona.conversation.tooltip.text"
      />

      {showJobRole && (
        <RHF.AutocompleteSelect
          control={control}
          name="jobRole"
          labelKey="scenario.builder.ai.steps.conversation-partner.formLabel.jobRole"
          rules={rules.stringShortRequired}
          shouldUnregister
          componentProps={{
            allowCustomValues: true,
            options: isB2BSelected
              ? getSelectOptions(jobRoleSuggestionsB2B, 'persona.jobRole', true)
              : getSelectOptions(jobRoleSuggestionsInternal, 'persona.jobRole', true),
          }}
          tooltipKey="persona.jobRole.tooltip.text"
        />
      )}

      {isB2BSelected && (
        <RHF.AutocompleteSelect
          control={control}
          name="industry"
          labelKey="common.industry"
          rules={rules.stringShortRequired}
          shouldUnregister
          componentProps={{
            allowCustomValues: true,
            options: getSelectOptions(industrySuggestions, 'persona.industry', true),
          }}
          tooltipKey="persona.industry.tooltip.text"
        />
      )}
    </>
  )
}
