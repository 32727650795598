import { Card, type CardProps, forwardRef } from '@chakra-ui/react'
import { colors } from '@repo/ui'

export const BaseCard = forwardRef((props: CardProps, ref) => (
  <Card
    ref={ref}
    border="1px solid"
    borderColor={colors.gray[300]}
    borderRadius="8px"
    overflow="hidden"
    transition="box-shadow 0.2s ease"
    _hover={{ shadow: 'lg' }}
    {...props}
  />
))
