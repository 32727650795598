import { Box, Flex, HStack, Switch } from '@chakra-ui/react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'

import { DragHandle } from '../../../../components/drag-handle'
import { type DashboardCard } from '../../../shared/types'

export const SortableCard = ({
  card,
  onToggleVisibility,
}: {
  card: DashboardCard
  onToggleVisibility: () => void
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: card.id,
  })

  return (
    <HStack
      transition={transition}
      transform={CSS.Translate.toString(transform)}
      ref={setNodeRef}
      gap={4}
    >
      <Flex
        align="center"
        flex={1}
        opacity={card.visible ? 1 : 0.5}
        borderRadius={4}
        border="1px"
        borderColor={colors.gray[300]}
        height="40px"
        cursor="grab"
        {...listeners}
        {...attributes}
      >
        <DragHandle h="full" />
        <Box>
          <FormattedMessage id={`dashboard.cards.${card.id}.title` satisfies I18nKey} />
        </Box>
      </Flex>

      <Switch isChecked={card.visible} onChange={onToggleVisibility} colorScheme="blue" />
    </HStack>
  )
}
