import { type FC } from 'react'

import { useInteractionStepContext } from './interaction-step-context'
import { StepConversationCustomization } from './interaction-step-renderer/step-conversation-customization'
import { StepConversationDynamics } from './interaction-step-renderer/step-conversation-dynamics'
import { StepConversationGeneration } from './interaction-step-renderer/step-conversation-generation'
import { StepReview } from './interaction-step-renderer/step-review'
import { StepScenarioGoal } from './interaction-step-renderer/step-scenario-goal'
import { type InteractionStepKey } from './ordered-interaction-steps'

const interactionStepComponents: Record<InteractionStepKey, FC> = {
  'conversation-dynamics': StepConversationDynamics,
  'scenario-goal': StepScenarioGoal,
  'conversation-generation': StepConversationGeneration,
  'conversation-customization': StepConversationCustomization,
  review: StepReview,
}

export const InteractionStepRenderer = () => {
  const { currentStep } = useInteractionStepContext()

  const Component = interactionStepComponents[currentStep]

  return <Component />
}
