import { type Result } from '../../hooks/use-result'
import { type DataItem } from '../charts/target-match-chart'

export const getBig5Data = (result: Result) =>
  ['agreeableness', 'conscientiousness', 'extraversion', 'neuroticism', 'openness'].map(
    field => ({
      subject: field,
      applicant: result.rawValues[field],
      company: result.benchmarkWeights[field] ? result.benchmarkValues[field] : 0,
    })
  ) as Array<DataItem>
