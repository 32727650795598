import { Button, Flex } from '@chakra-ui/react'
import { GoPlus } from 'react-icons/go'

import { useScenarioBuilderData } from '../../../../shared/use-scenario-builder-data'
import { useRealtimeBuilderContext } from '../../../shared/realtime-builder-context'
import { useStageStepperContext } from '../../shared/stage-stepper-context'

export type Suggestion = {
  nameKey: I18nKey
  skillName: string
}

const suggestions: Array<Suggestion> = [
  {
    nameKey: 'behavioral.goal.form.description.suggestion.1',
    skillName: '<poeditor>winning_behavior.skill.empathy',
  },
  {
    nameKey: 'behavioral.goal.form.description.suggestion.2',
    skillName: '<poeditor>winning_behavior.skill.objection_handling',
  },
  {
    nameKey: 'behavioral.goal.form.description.suggestion.3',
    skillName: '<poeditor>winning_behavior.skill.negotiation',
  },
]

export const SuggestionButtons = ({
  onSuggestionClick,
}: {
  onSuggestionClick: (suggestion: Suggestion) => void
}) => {
  const [, , , , , formatInProgramLanguage] = useScenarioBuilderData()
  const { scenario } = useRealtimeBuilderContext()
  const { activeStageIndex } = useStageStepperContext()

  const existingDescriptions =
    scenario.missionStages?.[activeStageIndex]?.winningBehaviorsRealtime?.map(
      goal => goal.description
    ) ?? []

  const filteredSuggestions = suggestions.filter(suggestion => {
    const formattedSuggestion = formatInProgramLanguage(suggestion.nameKey)

    return !existingDescriptions.includes(formattedSuggestion)
  })

  return (
    <Flex gap={2} flexWrap="wrap">
      {filteredSuggestions.map(suggestion => (
        <Button
          key={suggestion.nameKey}
          onClick={() => onSuggestionClick(suggestion)}
          leftIcon={<GoPlus />}
          variant="outline"
          borderRadius="full"
          whiteSpace="normal"
          height="unset"
          py={2}
          lineHeight={1.2}
          textAlign="left"
        >
          {formatInProgramLanguage(suggestion.nameKey)}
        </Button>
      ))}
    </Flex>
  )
}
