import { PageWrapper, type PageWrapperProps } from '@repo/ui/src/components/page-wrapper'
import { useEffect } from 'react'

import { logAnalyticsEvent } from '../../../../utils/analytics'
import { type DashboardCardId } from '../../../shared/types'

type DetailPageWrapperProps = Pick<
  PageWrapperProps,
  'children' | 'sx' | 'titleStartContent'
> & {
  cardId: DashboardCardId
}

export const DetailPageWrapper = ({
  cardId,
  children,
  sx,
  titleStartContent,
}: DetailPageWrapperProps) => {
  useEffect(() => {
    logAnalyticsEvent('page_view_dashboard_widget_detail', { widgetId: cardId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWrapper
      titleKey={`dashboard.cards.${cardId}.title`}
      subtitleKey={`dashboard.cards.${cardId}.subtitle`}
      backTo="/coaching/analytics"
      backLabelKey="dashboard.page.goBackLabel"
      sx={{ overflow: 'hidden', ...sx }}
      titleStartContent={titleStartContent}
    >
      {children}
    </PageWrapper>
  )
}
