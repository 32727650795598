import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, showToast, useModal } from '@repo/ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { client } from '../../utils/openapi-client'

const AcceptSharedProgramForm = ({
  programName,
  senderOrganizationName,
  sharedProgramId,
  closeModal,
}: {
  programName: string | undefined
  senderOrganizationName: string
  sharedProgramId: string | undefined
  closeModal: () => void
}) => {
  const [isLoadingAcceptButton, setIsLoadingAcceptButton] = useState(false)
  const [isLoadingRejectButton, setIsLoadingRejectButton] = useState(false)

  const onClickAcceptSharedProgram = async () => {
    try {
      setIsLoadingAcceptButton(true)
      if (sharedProgramId) {
        await client.put('acceptSharedProgram', {
          params: { path: { sharedExtId: sharedProgramId } },
        })
      }

      setIsLoadingAcceptButton(false)
      showToast({
        messageKey: 'coaching.program.received.toast.accept.success',
        status: 'success',
      })

      closeModal()
    } catch (err) {
      setIsLoadingAcceptButton(false)
      showToast({
        messageKey: 'coaching.program.received.toast.accept.error',
        status: 'error',
      })
    }
  }

  const onClickRejectSharedProgram = async () => {
    try {
      setIsLoadingRejectButton(true)
      if (sharedProgramId) {
        const { error } = await client.put('rejectSharedProgram', {
          params: { path: { sharedExtId: sharedProgramId } },
        })

        if (error) {
          throw new Error()
        }
      }

      setIsLoadingRejectButton(false)
      showToast({
        messageKey: 'coaching.program.received.toast.rejected.success',
        status: 'success',
      })

      closeModal()
    } catch (err) {
      setIsLoadingRejectButton(false)
      showToast({
        messageKey: 'coaching.program.received.toast.rejected.error',
        status: 'error',
      })
    }
  }

  return (
    <Stack pb={4} gap="24px" display="flex" flexDirection="column">
      <Text align="start" fontSize="14px">
        <span
          style={{
            fontSize: '14px',
            fontWeight: 700,
            color: colors.gray[800],
            marginRight: '4px',
          }}
        >
          {senderOrganizationName}
        </span>
        <FormattedMessage id="coaching.program.received.modal.description" />
      </Text>

      <HStack gap="2px">
        <Box m={2} borderRadius="full" bgColor="black" width="6px" height="6px" />
        <Text align="start" fontSize="14px" fontWeight={700} color={colors.gray[800]}>
          {programName}
        </Text>
      </HStack>
      <Text align="start" fontSize="14px">
        <FormattedMessage id="coaching.program.received.modal.description.bottom" />
      </Text>
      <HStack mt="28px" spacing={4} justify="center">
        <Button
          variant="outline"
          type="submit"
          isLoading={isLoadingRejectButton}
          onClick={onClickRejectSharedProgram}
        >
          <FormattedMessage id="coaching.program.received.modal.button.reject" />
        </Button>
        <Button
          type="submit"
          isLoading={isLoadingAcceptButton}
          onClick={onClickAcceptSharedProgram}
        >
          <FormattedMessage id="coaching.program.received.modal.button.accept" />
        </Button>
      </HStack>
    </Stack>
  )
}

export const useAcceptSharedProgramModal = (
  programName: string | undefined,
  senderOrganizationName: string,
  sharedProgramId: string | undefined
) => {
  const navigate = useNavigate()

  const modal = useModal(
    ({ closeModal }) => (
      <AcceptSharedProgramForm
        programName={programName}
        senderOrganizationName={senderOrganizationName}
        sharedProgramId={sharedProgramId}
        closeModal={() => {
          navigate('/training_programs')
          closeModal()
        }}
      />
    ),
    {
      titleKey: 'coaching.program.received.modal.title',
      onClose: () => {
        navigate('/training_programs')
      },
    }
  )

  return modal
}
