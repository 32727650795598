import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { TbTargetArrow } from 'react-icons/tb'

export const StageGoalCard = ({ goal }: { goal: string }) => (
  <Flex
    maxW="560px"
    fontSize={14}
    bg={colors.gray[50]}
    borderRadius="8px"
    p={5}
    gap={4}
    alignItems="center"
    textAlign="start"
  >
    <Icon as={TbTargetArrow} fontSize={36} color={colors.gray[300]} />
    <Box>
      <Text fontWeight="semibold">
        <FormattedMessage id="scenario.realtime.builder.stageGoal" />
      </Text>
      <Text>{goal}</Text>
    </Box>
  </Flex>
)
