import { Button, Heading, Icon, Input, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { useCallback, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { Link as RouterLink, Navigate } from 'react-router-dom'

import { useErrorHandler } from '../error-handling/use-error-handler'
import { useAuth } from './auth-context'
import { sendEmailVerification } from './authentication'

export const ResendEmail = () => {
  const { translate } = useErrorHandler()

  const { user } = useAuth()

  const [state, setState] = useState<'idle' | 'sending' | 'sent'>('idle')

  const handleOnResendEmailClick = useCallback(() => {
    const fn = async () => {
      if (user) {
        setState('sending')
        try {
          await sendEmailVerification(user)
          setState('sent')
        } catch (error: unknown) {
          showToast({ status: 'error', message: translate(error) })
          setState('idle')
        }
      }
    }

    fn()
  }, [user, translate])

  if (!user) {
    // Firebase limitation
    // In order to send a verification email, the user has to be logged in
    return <Navigate to="/login" />
  }

  return (
    <Stack align="stretch" spacing={4} textAlign="center">
      {state === 'sent' ? (
        <>
          <Heading as="h1" fontSize="2xl" fontWeight="medium" color="primary-dark">
            <Icon as={MdCheckCircle} color="#78e296" w={8} h={8} mr={2} />
            <FormattedMessage id="signup.resendEmail.success.title" />
          </Heading>
          <Text fontSize="sm" color="primary-dark">
            <FormattedMessage id="signup.resendEmail.success.text" />
          </Text>

          <Button type="button" size="lg" width="full" as={RouterLink} to="/login">
            <FormattedMessage id="onboarding.backToLogin" />
          </Button>
        </>
      ) : (
        <>
          <Heading as="h1" fontSize="2xl" fontWeight="medium" color="primary-dark">
            <FormattedMessage id="signup.resendEmail.title" />
          </Heading>
          <Text fontSize="sm" color="primary-dark">
            <FormattedMessage id="signup.resendEmail.text" />
          </Text>

          <Input
            size="lg"
            isDisabled
            value={user.email || ''}
            variant="filled"
            color="black!important"
          />

          <Button
            type="button"
            size="lg"
            width="full"
            isLoading={state === 'sending'}
            onClick={handleOnResendEmailClick}
          >
            <FormattedMessage id="signup.verifyEmail.button.resendEmail" />
          </Button>
        </>
      )}
    </Stack>
  )
}
