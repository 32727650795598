import { Flex, HStack } from '@chakra-ui/react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { match } from 'ts-pattern'

import { aiBuilderStepComponents } from '../scenario-ai-builder-page/ai-builder-step-components'
import { realtimeBuilderStepComponents } from '../scenario-realtime-builder-page/realtime-builder-step-components'
import {
  checkIsStepKeyAvailableForRouting,
  orderedBuilderStepKeys,
} from './builder-steps'
import { StepCard } from './step-card'
import { type BuilderType } from './types'
import { useResumeStepKey } from './use-resume-step-key'

export const BuilderStepRenderer = ({ builderType }: { builderType: BuilderType }) => {
  const resumeStepKey = useResumeStepKey(builderType)

  const { stepKey: currentStepKey = '' } = useParams<{ stepKey: string }>()

  const isStepKeyAvailableForRouting = checkIsStepKeyAvailableForRouting(
    currentStepKey,
    resumeStepKey
  )

  const navigate = useNavigate()

  if (!isStepKeyAvailableForRouting) {
    return <Navigate to=".." replace />
  }

  const currentStepIndex = orderedBuilderStepKeys.indexOf(currentStepKey)
  const lastStepIndex = orderedBuilderStepKeys.indexOf(resumeStepKey)

  const StepComponent = match(builderType)
    .with('ai', () => aiBuilderStepComponents[currentStepKey])
    .with('realtime', () => realtimeBuilderStepComponents[currentStepKey])
    .exhaustive()

  return (
    <>
      <HStack gap={6}>
        {orderedBuilderStepKeys.map((stepKey, index) => {
          const isStepCompleted = index < lastStepIndex
          const isStepDisabled = index > lastStepIndex

          return (
            <StepCard
              key={stepKey}
              hideBorder={!isStepDisabled && index !== currentStepIndex}
              isDisabled={isStepDisabled}
              isCompleted={isStepCompleted}
              titleKey={`scenario.builder.ai.steps.${stepKey}.title`}
              descriptionKey={`scenario.builder.ai.steps.${stepKey}.description`}
              number={index + 1}
              onClick={() => navigate(`../${stepKey}`)}
            />
          )
        })}
      </HStack>

      <Flex direction="column" mt="72px" flex={1}>
        <StepComponent />
      </Flex>
    </>
  )
}
