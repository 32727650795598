import { Flex, Stack } from '@chakra-ui/react'
import { type ModalContentRenderer, SectionHeader, useModal } from '@repo/ui'
import { useCallback } from 'react'

import { type Trainee, type TraineeFormValues } from './types'
import {
  TraineeFormContextProvider,
  type TraineeFormContextProviderProps,
} from './use-trainee-form-modal/trainee-form-context'
import { TraineeFormPreview } from './use-trainee-form-modal/trainee-form-preview'
import { TraineeFormStepRenderer } from './use-trainee-form-modal/trainee-form-step-renderer'
import { TraineeFormStepper } from './use-trainee-form-modal/trainee-form-stepper'

const TraineeForm = ({ onSubmit, trainee }: TraineeFormContextProviderProps) => (
  <TraineeFormContextProvider onSubmit={onSubmit} trainee={trainee}>
    <Flex minHeight="700px">
      <Stack flex={1} px={10} py={8} gap={8}>
        <SectionHeader
          titleKey="scenario.builder.ai.steps.trainee.traineeModal.title"
          subtitleKey="scenario.builder.ai.steps.trainee.traineeModal.subtitle"
        />

        <TraineeFormStepper />

        <TraineeFormStepRenderer />
      </Stack>

      <TraineeFormPreview />
    </Flex>
  </TraineeFormContextProvider>
)

export type TraineeFormModalSubmitHandler = (
  data: TraineeFormValues,
  closeModal: () => void
) => Promise<void>

export const useTraineeFormModal = (
  onSubmit: TraineeFormModalSubmitHandler,
  trainee?: Trainee
) => {
  const renderer = useCallback<ModalContentRenderer>(
    ({ closeModal }) => (
      <TraineeForm
        trainee={trainee}
        onSubmit={(data: TraineeFormValues) => onSubmit(data, closeModal)}
      />
    ),
    [onSubmit, trainee]
  )

  return useModal(renderer, {
    modalProps: { size: '6xl', closeOnEsc: false, closeOnOverlayClick: false },
    headerSx: { pos: 'absolute', right: 0 },
    bodySx: { p: 0 },
  })
}
