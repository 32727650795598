import { Alert, AlertIcon, type AlertProps } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'
import { IoCaretUpOutline } from 'react-icons/io5'

type Variant = 'info' | 'success'

export const DashboardInfoBox = ({
  children,
  variant = 'info',
  sx,
}: PropsWithChildren<{
  variant?: Variant
  sx?: AlertProps['sx']
}>) => (
  <Alert
    status={variant}
    borderRadius={8}
    fontSize={12}
    width="fit-content"
    paddingY={2}
    sx={sx}
  >
    <AlertIcon as={variant === 'success' ? IoCaretUpOutline : undefined} width="24px" />
    {children}
  </Alert>
)
