import { Flex, Stack } from '@chakra-ui/react'
import { type ModalContentRenderer, SectionHeader, useModal } from '@repo/ui'
import { useCallback } from 'react'

import { type Persona, type PersonaFormValues } from './types'
import {
  PersonaFormContextProvider,
  type PersonaFormContextProviderProps,
} from './use-persona-form-modal/persona-form-context'
import { PersonaFormPreview } from './use-persona-form-modal/persona-form-preview'
import { PersonaFormStepRenderer } from './use-persona-form-modal/persona-form-step-renderer'
import { PersonaFormStepper } from './use-persona-form-modal/persona-form-stepper'

const PersonaForm = ({ onSubmit, persona }: PersonaFormContextProviderProps) => (
  <PersonaFormContextProvider onSubmit={onSubmit} persona={persona}>
    <Flex minHeight="700px">
      <Stack flex={1} px={10} py={8} gap={8}>
        <SectionHeader
          titleKey="scenario.builder.ai.steps.conversation-partner.personaModal.title"
          subtitleKey="scenario.builder.ai.steps.conversation-partner.personaModal.subtitle"
        />

        <PersonaFormStepper />

        <PersonaFormStepRenderer />
      </Stack>

      <PersonaFormPreview />
    </Flex>
  </PersonaFormContextProvider>
)

export type PersonaFormModalSubmitHandler = (
  data: PersonaFormValues,
  closeModal: () => void
) => Promise<void>

export const usePersonaFormModal = (
  onSubmit: PersonaFormModalSubmitHandler,
  persona?: Persona
) => {
  const renderer = useCallback<ModalContentRenderer>(
    ({ closeModal }) => (
      <PersonaForm
        persona={persona}
        onSubmit={(data: PersonaFormValues) => onSubmit(data, closeModal)}
      />
    ),
    [onSubmit, persona]
  )

  return useModal(renderer, {
    modalProps: { size: '6xl', closeOnEsc: false, closeOnOverlayClick: false },
    headerSx: { pos: 'absolute', right: 0 },
    bodySx: { p: 0 },
  })
}
