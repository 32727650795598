import { type BoxProps, Grid, GridItem, type GridProps } from '@chakra-ui/react'
import { useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import { useDraggableStepsContext } from '../../draggable-steps-context'
import { DraggableStepItem } from './draggable-step-item'
import { SortableStepItem } from './sortable-step-item'
import { type SectionItem } from './types'

type DragDropSectionProps = {
  id: string
  items: Array<SectionItem>
  sx?: GridProps['sx']
  tagSx?: BoxProps['sx']
}

export const DraggableStepSection = ({ id, items, sx, tagSx }: DragDropSectionProps) => {
  const { isReadonly } = useDraggableStepsContext()
  const { setNodeRef } = useDroppable({
    id,
  })

  return (
    <SortableContext
      disabled={isReadonly}
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <Grid ref={setNodeRef} sx={sx}>
        {items.map(item => (
          <GridItem key={item.id}>
            <SortableStepItem id={item.id}>
              <DraggableStepItem item={item} sx={tagSx} />
            </SortableStepItem>
          </GridItem>
        ))}
      </Grid>
    </SortableContext>
  )
}
