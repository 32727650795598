import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  type NumberInputProps as ChakNumberInputProps,
  NumberInputStepper,
} from '@chakra-ui/react'

export type NumberInputProps = ChakNumberInputProps & {
  enableSteppers?: boolean
}

export const NumberInput = ({
  enableSteppers = true,
  ...restProps
}: NumberInputProps) => (
  <ChakraNumberInput {...restProps}>
    <NumberInputField />
    {enableSteppers && (
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    )}
  </ChakraNumberInput>
)
