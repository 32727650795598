import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { isEqual, sortBy } from 'lodash-es'
import { useMemo, useState } from 'react'

import { useProgramsList } from '../../new-program-builder/shared/api'
import { type ExerciseWithConnectedPrograms } from '../../types/api-types'

export const ConnectExerciseModal = ({
  exercises,
  onSubmit,
  isOpen,
  onClose,
  isSubmitting,
}: {
  exercises: Array<ExerciseWithConnectedPrograms>
  isOpen: boolean
  onClose: () => void
  onSubmit: (programExtIds: Array<string>) => Promise<void>
  isSubmitting: boolean
}) => {
  const { data } = useProgramsList()

  const programs = data?.filter(pgr => pgr.language === exercises[0]?.language) || []
  const programExtIds = useMemo(
    () => exercises.map(ex => ex.programExtIds).flat() ?? [],
    [exercises]
  )

  const [selectedPrograms, setSelectedPrograms] = useState<Array<string>>(
    () => programExtIds
  )

  const allProgramExtIds = programs.map(program => program.extId)

  const isAllProgramsSelected = useMemo(
    () => isEqual(sortBy(selectedPrograms), sortBy(allProgramExtIds)),
    [allProgramExtIds, selectedPrograms]
  )

  const toggleSelection = event => {
    const programExtId = event.target.value

    if (programExtId === 'all') {
      setSelectedPrograms(
        selectedPrograms.length === allProgramExtIds.length ? [] : allProgramExtIds
      )

      return
    }

    if (selectedPrograms.includes(programExtId)) {
      setSelectedPrograms(selectedPrograms.filter(id => id !== programExtId))

      return
    }

    setSelectedPrograms([...selectedPrograms, programExtId])
  }

  return (
    // TODO use useModal hook instead
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="exercise.form.modal.header" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          <Stack mt={3} spacing={4}>
            <Text>
              <FormattedMessage id="exercise.form.modal.description" />
            </Text>
            <Heading as="h6" color={colors.gray[600]} size="xs">
              <FormattedMessage id="coaching.program.assignForm.programs.label" />
            </Heading>
            {!!programs.length && (
              <Checkbox
                value="all"
                onChange={toggleSelection}
                isChecked={isAllProgramsSelected}
              >
                <FormattedMessage
                  id={
                    selectedPrograms.length === allProgramExtIds.length
                      ? 'coaching.exercise.assignForm.programs.unselectAll'
                      : 'coaching.exercise.assignForm.programs.selectAll'
                  }
                />
              </Checkbox>
            )}
            <Box maxH="40vh" overflow="auto" pl={1}>
              {programs.map(program => (
                <Flex justifyItems="center" key={program.extId}>
                  <Checkbox
                    value={program.extId}
                    onChange={toggleSelection}
                    isChecked={selectedPrograms.includes(program.extId)}
                  >
                    {program.title}
                  </Checkbox>
                </Flex>
              ))}
              {!programs.length && (
                <FormattedMessage id="coaching.program.assignForm.programs.noneAvailable" />
              )}
            </Box>
            <HStack justify="center">
              <Button variant="outline" onClick={onClose}>
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button
                isLoading={isSubmitting}
                onClick={() => onSubmit(selectedPrograms.filter(id => !!id))}
                isDisabled={
                  (!programExtIds.length &&
                    !selectedPrograms.length &&
                    // TODO not sure what this condition is for, needs clarification
                    exercises.some(ex => ex.createdAt)) ||
                  isEqual(programExtIds, selectedPrograms)
                }
              >
                <FormattedMessage id="exercise.form.button.connect" />
              </Button>
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
