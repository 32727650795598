import { Stack } from '@chakra-ui/react'
import { showToast, useModal } from '@repo/ui'
import { type SubmitHandler } from 'react-hook-form'

import { type Instruction } from '../../../../../../types/api-types'
import { client } from '../../../../../../utils/openapi-client'
import { StickyFooter } from '../../../../../shared/sticky-footer'
import { useRealtimeBuilderContext } from '../../../../shared/realtime-builder-context'
import { type InstructionFormValues, useInstructionForm } from './use-instruction-form'

const InstructionEditForm = ({
  instruction,
  closeModal,
}: {
  instruction: Instruction
  closeModal: () => void
}) => {
  const [form, formFieldsJsx] = useInstructionForm({
    description: instruction.description,
    skillName: instruction.skill.name,
    reference: instruction.references ? [{ value: instruction.references }] : [],
    hows: instruction.hows.map(how => ({
      description: how.description,
      reference: how.references ? [{ value: how.references }] : [],
    })),
  })

  const { handleSubmit, formState } = form
  const { programExtId, scenarioExtId, trainingExtId, mutateScenario } =
    useRealtimeBuilderContext()

  const onSubmit: SubmitHandler<InstructionFormValues> = async data => {
    try {
      const res = await client.put('updateInstruction', {
        params: {
          path: {
            extId: instruction.extId,
            programExtId,
            scenarioExtId,
            trainingExtId,
          },
        },
        body: {
          behaviorType: 'positive',
          description: data.description,
          skillName: data.skillName,
          references: data.reference[0]?.value?.trim() ? data.reference[0]?.value : null,
          hows: data.hows
            .filter(how => !!how.description.trim())
            .map(how => ({
              description: how.description,
              references: how.reference[0]?.value?.trim()
                ? how.reference[0]?.value
                : null,
            })),
        },
      })

      if (res.error) {
        throw new Error()
      }

      // TODO optimistic update
      await mutateScenario()

      showToast({ messageKey: 'common.alert.saved', status: 'success' })
      closeModal()
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} width="80%" mx="auto">
      {formFieldsJsx}

      <StickyFooter
        nextLabelKey="common.save"
        isNextDisabled={!formState.isValid}
        isNextLoading={formState.isSubmitting}
      />
    </Stack>
  )
}

export const useEditInstructionModal = (instruction: Instruction) =>
  useModal(
    ({ closeModal }) => (
      <InstructionEditForm instruction={instruction} closeModal={closeModal} />
    ),
    {
      titleKey: 'scenario.builder.realtime.steps.instructions.editModal.title',
      modalProps: { size: 'full', scrollBehavior: 'inside' },
      bodySx: { width: '100%', overflowX: 'hidden', py: '0' },
      headerSx: { alignSelf: 'center', width: '80%', pt: '60px' },
      contentSx: { borderRadius: '0px' },
    }
  )
