import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as folderEffects from './effects'
import { selectFoldersInitialized } from './selectors'

export const useInitFolders = () => {
  const dispatch = useDispatch()
  const initialized = useSelector(selectFoldersInitialized)

  useEffect(() => {
    dispatch(folderEffects.initializeFolders())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return initialized
}
