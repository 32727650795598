import { RHF, useFormRules } from '@repo/ui'
import { useFormContext } from 'react-hook-form'

import { useSelectOptions } from '../../../use-select-options'
import { type PersonaFormValues } from '../../types'

const personalities = [
  'talkative',
  'untalkative',
  'overwhelming',
  'shy',
  'bold',
  'unbold',
  'softHearted',
  'ironHearted',
  'charitable',
  'unexcitable',
  'considerate',
  'cynical',
  'organized',
  'disorganized',
  'tidy',
  'untidy',
  'planful',
  'unsystematic',
  'calm',
  'irritable',
  'relaxed',
  'nervous',
  'patient',
  'moody',
  'creative',
  'conventional',
  'flexible',
  'narrowMinded',
  'intellectuallyCurious',
  'routineOriented',
] as const

const personalValues = [
  'integrity',
  'respect',
  'responsibility',
  'kindness',
  'fairness',
  'loyalty',
  'authenticity',
  'courage',
  'perseverance',
  'wisdom',
  'gratitude',
  'humility',
  'empathy',
  'freedom',
  'joy',
  'creativity',
  'growth',
  'community',
  'health',
  'adventure',
  'balance',
  'generosity',
  'optimism',
  'curiosity',
  'selfDiscipline',
  'simplicity',
] as const

const attitudes = ['positive', 'neutral', 'negative'] as const

export const StepCharacter = () => {
  const { control } = useFormContext<PersonaFormValues['character']>()
  const rules = useFormRules()
  const { getSelectOptions } = useSelectOptions()

  return (
    <>
      <RHF.RadioButtonGroup
        control={control}
        name="attitude"
        labelKey="common.attitude"
        rules={rules.required}
        componentProps={{
          options: attitudes.map(value => ({
            value,
            labelKey: `persona.attitude.${value}`,
          })),
        }}
        tooltipKey="persona.attitude.tooltip.text"
      />

      <RHF.AutocompleteSelect
        control={control}
        name="personality"
        labelKey="common.personality"
        rules={rules.required}
        componentProps={{
          options: getSelectOptions(personalities, 'persona.personality', true),
        }}
        tooltipKey="persona.personality.tooltip.text"
      />
      <RHF.AutocompleteSelect
        control={control}
        name="personalValues"
        labelKey="scenario.builder.ai.steps.conversation-partner.formLabel.personalValues"
        rules={rules.required}
        componentProps={{
          options: getSelectOptions(personalValues, 'persona.personalValue', true),
        }}
        tooltipKey="persona.personal.values.tooltip.text"
      />
    </>
  )
}
