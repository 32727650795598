import {
  Box,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'
import { MdArrowBack } from 'react-icons/md'

import { ImageWithFallback } from '../../../../components/image-with-fall-back'
import { useGoogleStorageAsset } from '../../../../hooks/use-google-storage-asset'
import { KeyValueWithButton } from '../../key-value-with-button'
import { KeyValueWithInput } from '../../key-value-with-input'
import { type Trainee } from '../types'

interface TraineePreviewModalProps {
  onClose: () => void
  isOpen: boolean
  trainee: Trainee
}

export const TraineePreviewModal = ({
  onClose,
  trainee,
  isOpen,
}: TraineePreviewModalProps) => {
  const imageHttpUrl = useGoogleStorageAsset(trainee.imageUrl)
  const { formatMessage } = useIntl()

  return (
    <Modal
      scrollBehavior="inside"
      size="4xl"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent overflow="hidden">
        <IconButton
          onClick={onClose}
          aria-label="close"
          position="absolute"
          top={4}
          left={4}
          zIndex={1}
          isRound
          fontSize="22px"
          icon={<MdArrowBack />}
        />

        <Box minH="216px" h="216px">
          <ImageWithFallback
            width="full"
            height="full"
            objectFit="cover"
            src={imageHttpUrl}
          />
        </Box>

        <ModalBody px="60px" py="40px">
          <Text fontSize="24px" mb={10} fontWeight={500}>
            {trainee.name}
          </Text>

          <SimpleGrid columns={2} spacing={10}>
            <VStack spacing={4} alignItems="flex-start">
              <Text fontSize="18px" color={colors.blue[500]} fontWeight={500}>
                <FormattedMessage id="scenario.builder.ai.steps.trainee.traineeModal.company" />
              </Text>
              <KeyValueWithInput
                labelKey="scenario.builder.ai.steps.trainee.formLabel.department"
                value={trainee.department}
              />
              <KeyValueWithInput labelKey="common.industry" value={trainee.industry} />
              {trainee.workplace && (
                <KeyValueWithButton
                  labelKey="scenario.builder.ai.steps.trainee.formLabel.workplace"
                  value={formatMessage({
                    id: `trainee.workplace.${trainee.workplace}` satisfies I18nKey,
                  })}
                />
              )}
            </VStack>

            <VStack spacing={4} alignItems="flex-start">
              <Text fontSize="18px" color={colors.blue[500]} fontWeight={500}>
                <FormattedMessage id="scenario.builder.ai.steps.trainee.traineeModal.professional" />
              </Text>
              <KeyValueWithInput
                labelKey="scenario.builder.ai.steps.trainee.formLabel.jobRole"
                value={trainee.jobRole}
              />
              <HStack gap={8}>
                <KeyValueWithButton
                  labelKey="scenario.builder.ai.steps.trainee.formLabel.hierarchy"
                  value={formatMessage({
                    id: `common.amount.${trainee.hierarchy}` satisfies I18nKey,
                  })}
                />
                <KeyValueWithButton
                  labelKey="scenario.builder.ai.steps.trainee.formLabel.leadershipResponsibility"
                  value={
                    trainee.hasLeadershipResponsibility
                      ? formatMessage({
                          id: 'common.yes',
                        })
                      : formatMessage({
                          id: 'common.no',
                        })
                  }
                />
              </HStack>
              <KeyValueWithButton
                labelKey="scenario.builder.ai.steps.trainee.formLabel.experience"
                value={formatMessage({
                  id: `common.amount.${trainee.seniority}` satisfies I18nKey,
                })}
              />
            </VStack>
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
