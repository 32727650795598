import { IconButton, type InputProps, InputRightElement } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { BiHide, BiShow } from 'react-icons/bi'

export const usePasswordInputControl = (inputProps: InputProps) => {
  const [isHidden, setIsHidden] = useState(true)

  const showHideButton = useMemo(
    () => (
      <InputRightElement h="full">
        <IconButton
          aria-label="Show password"
          onClick={() => setIsHidden(!isHidden)}
          icon={isHidden ? <BiShow /> : <BiHide />}
          variant="ghost"
          tabIndex={-1}
        />
      </InputRightElement>
    ),
    [isHidden]
  )

  const passwordInputProps = useMemo<InputProps>(
    () => ({
      size: 'md',
      type: isHidden ? 'password' : 'text',
      isRequired: true,
      variant: 'filled',
      ...inputProps,
    }),
    [isHidden, inputProps]
  )

  return { showHideButton, passwordInputProps }
}
