import { IconButton } from '@chakra-ui/react'
import { Menu, MenuItem } from '@mui/material'
import { FormattedMessage } from '@repo/i18n'
import { colors, showToast, useConfirmationModal } from '@repo/ui'
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { useState } from 'react'
import { RiMoreFill } from 'react-icons/ri'

import { useBenchmarkProfiles } from '../../hooks/use-benchmark-profiles'
import { type BenchmarkProfile } from '../../types/benchmark-profile'
import { BenchmarkProfileEditModal } from '../benchmark-profile-edit-modal'
import { LinkScenariosModal } from '../link-scenarios-modal'

export const ActionMenu = ({ profile }: { profile: BenchmarkProfile }) => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenLinkScenarioModal, setIsOpenLinkScenarioModal] = useState(false)
  const { deleteProfile, useGetAll } = useBenchmarkProfiles()

  // Use the hook approach instead of the render props pattern
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'benchmark-profile-menu',
  })

  const { refresh } = useGetAll()

  const hasLinkedScenarios =
    profile.linkedScenarios.length > profile.scenariosToLink.length

  const showDeleteWarning = () =>
    showToast({
      messageKey: 'benchmarkProfile.delete.hasLinkedScenarios',
      status: 'error',
    })

  const handleDelete = async () => {
    try {
      await deleteProfile(profile.extId)
      showToast({
        messageKey: 'common.alert.deleted',
        status: 'success',
      })

      refresh()
    } catch (err) {
      showToast({
        messageKey: 'general.error.deleting.data',
        status: 'error',
      })
    }
  }

  const [confirmDeleteModal, showConfirmDeleteModal] = useConfirmationModal({
    onConfirm: handleDelete,
    confirmButtonKey: 'common.delete',
    title: (
      <FormattedMessage
        id="benchmarkProfile.delete.confirmModal.title"
        values={{ profileTitle: profile.title }}
      />
    ),
    descriptionKey: 'benchmarkProfile.delete.confirmModal.description',
    isDestructive: true,
  })

  return (
    <>
      <IconButton
        variant="ghost"
        icon={<RiMoreFill />}
        aria-label="actions"
        {...bindTrigger(popupState)}
      />
      <Menu {...bindMenu(popupState)}>
        <MenuItem
          onClick={() => {
            popupState.close()
            setIsOpenEditModal(true)
          }}
        >
          <FormattedMessage id="coaching.benchmarking.profiles.table.actions.rename" />
        </MenuItem>
        <MenuItem
          disabled={profile.status !== 'done'}
          onClick={() => {
            popupState.close()
            setIsOpenLinkScenarioModal(true)
          }}
        >
          <FormattedMessage id="coaching.benchmarking.profiles.table.actions.link" />
        </MenuItem>
        <MenuItem
          sx={{ color: colors.indicator['red-dark'] }}
          onClick={() => {
            popupState.close()
            if (hasLinkedScenarios) {
              showDeleteWarning()
            } else {
              showConfirmDeleteModal()
            }
          }}
        >
          <FormattedMessage id="common.delete" />
        </MenuItem>
      </Menu>

      {confirmDeleteModal}

      {isOpenEditModal && (
        <BenchmarkProfileEditModal
          onClose={() => setIsOpenEditModal(false)}
          title={profile.title}
          extId={profile.extId}
        />
      )}

      {isOpenLinkScenarioModal && (
        <LinkScenariosModal
          extId={profile.extId}
          language={profile.language}
          onClose={() => setIsOpenLinkScenarioModal(false)}
          lockedScenarioExtIds={profile.linkedScenarios}
        />
      )}
    </>
  )
}
