import {
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type FieldArrayRenderProps, useFormikContext } from 'formik'
import { type PropsWithChildren, useCallback } from 'react'
import { CgRemove } from 'react-icons/cg'
import { MdOutlineDragIndicator } from 'react-icons/md'

import { DragItem } from '../../../../../../builder/components/drag-item'
import {
  type CoachingStepFormFields,
  type QuizStepFormFields,
} from '../../../../../../builder/steps/api'

type QuizOptionProps = {
  optIndex: number
  option: {
    id: string
    text?: string | undefined
    videoUrl?: string | undefined
    isCorrect: boolean
  }
  arrayHelpers: FieldArrayRenderProps
  isReadOnly: boolean | undefined
  disabled: boolean | undefined
}
const OPTION_DROP_TYPE = 'text-option'

export const QuizOption = ({
  optIndex,
  option,
  arrayHelpers,
  isReadOnly,
  disabled,
  children,
}: PropsWithChildren<QuizOptionProps>) => {
  const { formatMessage } = useIntl()
  const { touched, handleChange, handleBlur, errors, values } =
    useFormikContext<CoachingStepFormFields>()

  const { step } = values

  if (step.stepType !== 'coachingStepQuiz') {
    throw new Error('Wrong step type')
  }

  const { optionsData } = step

  const error = errors.step as QuizStepFormFields
  const touchedField =
    touched.step && 'optionsData' in touched.step && touched.step.optionsData

  const findItem = useCallback(
    (id: string) => {
      const opt = optionsData.find(item => item.id === id)

      return {
        opt,
        index: opt ? optionsData.indexOf(opt) : -1,
      }
    },
    [optionsData]
  )

  const moveItem = useCallback(
    (id: string, atIndex: number) => {
      const { index } = findItem(id)

      arrayHelpers.swap(index, atIndex)
      arrayHelpers.form.validateForm()
    },
    [arrayHelpers, findItem]
  )

  return (
    <FormControl
      mt={2}
      id={option.id}
      isInvalid={
        touchedField &&
        touched?.step?.optionsData?.[optIndex]?.text &&
        !!error?.optionsData?.[optIndex]?.text
      }
    >
      <DragItem
        id={option.id}
        findItem={findItem}
        moveItem={moveItem}
        canDrag={!isReadOnly}
        pos="relative"
        dragIcon={false}
        dragItemType={OPTION_DROP_TYPE}
      >
        <HStack spacing="2">
          {children}
          <InputGroup>
            <InputLeftElement>
              <Icon
                color={colors.gray[500]}
                as={MdOutlineDragIndicator}
                cursor={isReadOnly ? 'not-allowed' : 'grab'}
              />
            </InputLeftElement>
            <Input
              size="sm"
              name={`step.optionsData.${optIndex}.text`}
              type="text"
              placeholder={formatMessage({
                id: option.isCorrect
                  ? 'step.option.correct.placeholder'
                  : 'step.option.wrong.placeholder',
              })}
              value={option.text}
              borderColor={option.isCorrect ? colors.green[500] : colors.indicator.red}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isReadOnly}
            />
          </InputGroup>
          <IconButton
            onClick={() => arrayHelpers.remove(optIndex)}
            isDisabled={optionsData.length < 3 || disabled}
            variant="ghost"
            colorScheme="gray"
            aria-label={formatMessage({ id: 'step.option.delete' })}
            icon={<CgRemove size="1.5rem" />}
          />
        </HStack>
      </DragItem>
      <FormErrorMessage>
        {error?.optionsData?.[optIndex]?.text ? (
          <FormattedMessage id={error.optionsData[optIndex].text as I18nKey} />
        ) : (
          ''
        )}
      </FormErrorMessage>
    </FormControl>
  )
}
