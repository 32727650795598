// eslint-disable-next-line no-restricted-imports
import { useLocalStorage as libUseLocalStorage } from '@uidotdev/usehooks'

import { type RecordingView } from '../containers/recording-list/use-recording-view'
import {
  type ColumnSortableItemValue,
  type SortingState,
} from '../recruiting/recording-filters/column-control-data'
import { type FilterItemValue } from '../recruiting/recording-filters/filter-control-data'

type LocalStorageConfig = {
  ['recording-list-view']: RecordingView
  ['recording-list-filter']: FilterItemValue
  ['recording-list-column']: Array<ColumnSortableItemValue>
  ['recording-list-sorting']: SortingState
  hideDevOnlySidebar: boolean
  hasSeenBuilderIntro: boolean
  hasCompletedScenarioBuilder: boolean
}

export const useLocalStorage = <
  TKey extends keyof LocalStorageConfig,
  TType extends LocalStorageConfig[TKey],
>(
  key: TKey,
  initialValue?: NoInfer<TType>
) => libUseLocalStorage<TType>(key, initialValue)
