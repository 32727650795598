import { Box, GridItem, type GridItemProps, Image } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'

import videoPlaceholder from '../../assets/images/video-placeholder.svg'

export const SessionCard = ({
  onClick,
  imageUrl,
  children,
  ...props
}: PropsWithChildren<
  {
    onClick?: () => void
    imageUrl: string
  } & GridItemProps
>) => (
  <GridItem
    className="session-card"
    colSpan={{ sm: 12, md: 12, lg: 6, xl: 4, '2xl': 3 }}
    border="1px"
    maxW={80}
    onClick={onClick}
    borderColor="gray.200"
    borderRadius="lg"
    overflow="hidden"
    position="relative"
    pb={2}
    _hover={{
      borderColor: onClick && 'secondary-dark',
      cursor: onClick && 'pointer',
      '.action-item': {
        display: 'flex',
      },
    }}
    {...props}
  >
    <Image
      height={40}
      w="full"
      objectFit="cover"
      src={imageUrl}
      fallbackSrc={videoPlaceholder}
    />
    <Box p={5}>{children}</Box>
  </GridItem>
)
