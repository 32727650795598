import { Button, Center, Image, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { usePromisingModal } from '@repo/ui'
import { useMemo } from 'react'

import dragDropGif from '../../assets/drag-drop.gif'
import { useLocalStorage } from '../../hooks/use-local-storage'

export const useCustomizationStepIntroModal = () => {
  const promisingModal = usePromisingModal<boolean>(({ resolve }) => (
    <>
      <Text fontWeight="semibold" position="absolute" top="10px">
        <FormattedMessage id="scenario.builder.ai.steps.conversationGeneration.next.step.modal.title" />
      </Text>
      <Image src={dragDropGif} mt={5} px={4} />
      <Center>
        <Button
          alignSelf="flex-end"
          size="md"
          onClick={() => resolve(true)}
          mt={10}
          mb={2}
        >
          <FormattedMessage id="scenario.builder.ai.steps.conversationGeneration.next.step.modal.button" />
        </Button>
      </Center>
    </>
  ))

  const fakePromisingModal = useMemo(
    () => [null, () => Promise.resolve(true)] as const,
    []
  )

  const [hasCompletedScenarioBuilder] = useLocalStorage('hasCompletedScenarioBuilder')

  /**
   * if user has completed a scenario builder at least once, we don't need to show the intro modal,
   * so we return a fake modal that resolves immediately
   */
  return hasCompletedScenarioBuilder ? fakePromisingModal : promisingModal
}
