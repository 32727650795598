import { Skeleton, TableCell, TableRow } from '@mui/material'
import { range } from 'lodash-es'

export const TableLoadingState = ({ columnCount }: { columnCount: number }) =>
  range(5).map(index => (
    <TableRow key={index}>
      <TableCell colSpan={columnCount}>
        <Skeleton height={32} width="100%" animation="wave" />
      </TableCell>
    </TableRow>
  ))
