import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { HelpTooltip, Spinner } from '@repo/ui'
import { isNil } from '@repo/utils'
import { useParams } from 'react-router-dom'

import { ScoreBar } from '../../components/score-bar'
import { VideoGallery } from '../../components/video-gallery'
import { useResult } from '../../hooks/use-result'
import { useRecordingSession } from '../recording-sessions-api'
import { CandidateMotivation } from './candidate-motivation'
import { CandidatePersonality } from './candidate-personality'
import { CandidatePreferences } from './candidate-preferences'
import { Overview } from './overview'
import { getBig5Data } from './recording-details-utils'

export const RecordingDetailsCandidateWithTargetProfile = () => {
  const { id: recordingId } = useParams<{ id: string }>()
  const { data: recordingSession, error } = useRecordingSession(recordingId)
  const result = useResult(recordingSession)
  const big5Data = getBig5Data(result)

  if (error) {
    return (
      <Flex alignSelf="center">
        <Text>
          <FormattedMessage id="recording.error.noAccess" />
        </Text>
      </Flex>
    )
  }

  if (isNil(recordingSession)) {
    return (
      <Flex alignSelf="center">
        <Spinner />
      </Flex>
    )
  }

  return (
    result && (
      <Box p={20} maxW="container.xl" margin="0 auto">
        <Stack
          direction="row"
          w="100%"
          spacing={6}
          bg="white"
          px={20}
          pt={20}
          borderRadius={4}
        >
          <Flex w="50%" align="center" justify="center">
            <VideoGallery videos={recordingSession?.recordings} />
          </Flex>
          <Flex direction="column" w="50%">
            <Heading>
              {recordingSession?.meta?.name ?? (
                <FormattedMessage id="recording.title.anonymous" />
              )}
            </Heading>

            <Flex mt={10}>
              <ScoreBar
                startLabel={
                  <Text fontWeight="semibold">
                    <FormattedMessage id="recording.details.scoreBar.low" />
                  </Text>
                }
                endLabel={
                  <Text fontWeight="semibold">
                    <FormattedMessage id="recording.details.scoreBar.high" />
                  </Text>
                }
                value={result.overallScore}
                lowerLimit={result.benchmarkThresholds[0]}
                upperLimit={result.benchmarkThresholds[2]}
              />
              <HelpTooltip
                label={
                  <FormattedMessage id="recording.details.overview.overall-relevance.graph.hover" />
                }
              />
            </Flex>
          </Flex>
        </Stack>
        <Stack spacing={20} bg="white" p={20}>
          <Overview
            candidateView
            showTargetProfile
            scores={result.scores}
            big5Data={big5Data}
          />
          <CandidatePersonality
            candidateView
            showTargetProfile
            result={result}
            big5Data={big5Data}
          />
          <CandidatePreferences candidateView showTargetProfile result={result} />
          <CandidateMotivation candidateView rawValues={result.rawValues} />
        </Stack>
      </Box>
    )
  )
}
