import { Alert, AlertIcon, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

interface CohortEmailInvitationsAlertProps {
  messageKey: I18nKey
}

export const CohortEmailInvitationsAlert = ({
  messageKey,
}: CohortEmailInvitationsAlertProps) => (
  <Alert status="warning" py={2} mt={3}>
    <AlertIcon />
    <Stack spacing={0}>
      <Text fontSize="xs" fontWeight="bold">
        <FormattedMessage id="cohort.creation.email.step.alert.title" />
      </Text>
      <Text fontSize="xs" as="span">
        <FormattedMessage id={messageKey} />
      </Text>
    </Stack>
  </Alert>
)
