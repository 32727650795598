import { TableCell, TableSortLabel } from '@mui/material'
import { flexRender, type Header, type RowData } from '@tanstack/react-table'

import { CHECKBOX_COLUMN_ID } from './constants'

type CustomTableHeadCellProps<TData extends RowData> = {
  header: Header<TData, unknown>
}

export const CustomTableHeadCell = <TData extends RowData>({
  header,
}: CustomTableHeadCellProps<TData>) => {
  const isCheckboxColumn = header.id === CHECKBOX_COLUMN_ID

  if (isCheckboxColumn) {
    return (
      <TableCell padding="checkbox" key={header.id} colSpan={header.colSpan}>
        {flexRender(header.column.columnDef.header, header.getContext())}
      </TableCell>
    )
  }

  const sortDirection = header.column.getIsSorted() || undefined
  const isSortable = header.column.getCanSort()
  const align = header.column.columnDef.meta?.align

  const renderedContent = flexRender(header.column.columnDef.header, header.getContext())

  return (
    <TableCell key={header.id} colSpan={header.colSpan} align={align}>
      {isSortable ? (
        <TableSortLabel
          disabled={!isSortable}
          active={!!sortDirection}
          direction={sortDirection}
          onClick={header.column.getToggleSortingHandler()}
          sx={{ textTransform: 'capitalize' }}
        >
          {renderedContent}
        </TableSortLabel>
      ) : (
        renderedContent
      )}
    </TableCell>
  )
}
