import { Box } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { FiPercent } from 'react-icons/fi'

import { AdvancedTable } from '../../../components/advanced-table'
import { useDashboardContext } from '../../shared/dashboard-context'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { ToggleBetweenTwo } from './shared/toggle-between-two'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

type FunnelData = {
  id: string
  cohortName: string
  completedAll: number
  finishedAtLeastOne: number
  registeredNoRecordings: number
  notRegistered: number
}

const columnHelper = createColumnHelper<FunnelData>()

export const LearningDevelopmentFunnelCardDetails = () => {
  const { dataSlot } = useDashboardCardData('learningDevelopmentFunnel')
  const { pageState } = useDashboardContext()
  const [isPercentageActive, setIsPercentageActive] = useState(false)

  const renderNumberOrPercentage = (value: number) =>
    isPercentageActive ? `${value}%` : value

  const columns = [
    columnHelper.accessor('cohortName', {
      header: () => (
        <FormattedMessage id="dashboard.cards.traineeCount.details.tab.cohortName" />
      ),
    }),
    columnHelper.accessor('completedAll', {
      header: () => (
        <FormattedMessage id="dashboard.cards.learningDevelopmentFunnel.data.completedAll" />
      ),
      cell: props => renderNumberOrPercentage(props.getValue()),
      meta: {
        align: 'right',
      },
    }),
    columnHelper.accessor('finishedAtLeastOne', {
      header: () => (
        <FormattedMessage id="dashboard.cards.learningDevelopmentFunnel.data.finishedAtLeastOne" />
      ),
      cell: props => renderNumberOrPercentage(props.getValue()),
      meta: {
        align: 'right',
      },
    }),
    columnHelper.accessor('registeredNoRecordings', {
      header: () => (
        <FormattedMessage id="dashboard.cards.learningDevelopmentFunnel.data.registeredNoRecordings" />
      ),
      cell: props => renderNumberOrPercentage(props.getValue()),
      meta: {
        align: 'right',
      },
    }),
    columnHelper.accessor('notRegistered', {
      header: () => (
        <FormattedMessage id="dashboard.cards.learningDevelopmentFunnel.data.notRegistered" />
      ),
      cell: props => renderNumberOrPercentage(props.getValue()),
      meta: {
        align: 'right',
      },
    }),
  ]

  const tableData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    const dataType = isPercentageActive
      ? data.usersProgressPercentagePerTimestamp
      : data.usersProgressValuePerTimestamp

    return data.cohortNames.map((cohortName, index) => ({
      id: data.cohortExtIds[index]!,
      cohortName,
      completedAll: dataType.completed[index]!.at(-1) ?? 0,
      registeredNoRecordings: dataType.withoutProgress[index]!.at(-1) ?? 0,
      finishedAtLeastOne: dataType.inProgress[index]!.at(-1) ?? 0,
      notRegistered: dataType.open[index]!.at(-1) ?? 0,
    }))
  }, [dataSlot, isPercentageActive])

  return (
    <DetailPageWrapper cardId="learningDevelopmentFunnel">
      <Box w="fit-content" mb={4}>
        <ToggleBetweenTwo
          isLeftActive={!isPercentageActive}
          toggleValue={() => setIsPercentageActive(!isPercentageActive)}
          left="123"
          right={<FiPercent />}
        />
      </Box>

      <AdvancedTable
        columns={columns}
        data={tableData}
        isLoading={pageState === 'loading'}
      />
    </DetailPageWrapper>
  )
}
