import {
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react'
import { type ComponentProps, type ReactNode } from 'react'
import { Controller, type FieldPath, type FieldValues } from 'react-hook-form'

import { FormControlWrapper } from './shared/form-control-wrapper'
import { type FieldBaseProps } from './shared/types'

type ExtraProps = {
  rightAddon?: ReactNode
}

export const FormFieldNumberInput = <T extends FieldValues, TName extends FieldPath<T>>(
  props: FieldBaseProps<T, ComponentProps<typeof NumberInput> & ExtraProps, TName>
) => {
  const { name, control, rules, shouldUnregister, componentProps } = props
  const { rightAddon, ...componentPropsRest } = componentProps ?? {}

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FormControlWrapper fieldBaseProps={props} error={fieldState.error}>
          <InputGroup>
            <NumberInput
              {...field}
              {...componentPropsRest}
              sx={{ flex: 1, ...componentPropsRest?.sx }}
              onChange={(valueAsString, valueAsNumber) => {
                field.onChange(valueAsString)
                componentPropsRest?.onChange?.(valueAsString, valueAsNumber)
              }}
            >
              <NumberInputField />
            </NumberInput>
            {!!rightAddon && <InputRightAddon>{rightAddon}</InputRightAddon>}
          </InputGroup>
        </FormControlWrapper>
      )}
    />
  )
}
