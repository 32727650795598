import { Icon, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { BsInbox } from 'react-icons/bs'

import { type ExtendedProgram, type SharedProgram } from '../../types/api-types'
import { SharedProgramItem } from './shared-program-item'

export const SharedProgramList = ({
  sharedPrograms,
  program,
  isReceivedModal = false,
}: {
  sharedPrograms: Array<SharedProgram> | undefined
  program?: ExtendedProgram
  isReceivedModal?: boolean
}) => {
  if (sharedPrograms?.length) {
    return (
      <VStack gap={4} overflowY="auto" pr={4} maxH="180px">
        {sharedPrograms.map(sharedProgram => (
          <SharedProgramItem
            key={sharedProgram.extId}
            sharedProgram={sharedProgram}
            program={program}
            isReceivedModal={isReceivedModal}
          />
        ))}
      </VStack>
    )
  }

  return (
    <VStack p={2} mx={2} my={4} alignItems="center">
      <Icon as={BsInbox} color={colors.gray[400]} />
      <Text textAlign="center" color={colors.gray[400]}>
        <FormattedMessage id="coaching.program.send.modal.requestsStatus.listItem.empty" />
      </Text>
    </VStack>
  )
}
