// Interim helper functions to distribute the overall score more equally
export const trimValue = (value, leftThreshold, rightThreshold) => {
  switch (true) {
    case value < leftThreshold:
      return leftThreshold
    case value > rightThreshold:
      return rightThreshold
    default:
      return value
  }
}

export const normalize = (value, leftThreshold, rightThreshold) =>
  (value - leftThreshold) / (rightThreshold - leftThreshold)
