import {
  Checkbox,
  Stack,
  type SxProps,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { scrollbarStylesBlack } from '@repo/ui'
import { assertExists } from '@repo/utils'
import {
  type ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  type RowData,
  type RowSelectionState,
  type TableOptions,
  useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useDebounce } from '@uidotdev/usehooks'
import { type Dispatch, type SetStateAction, useMemo, useRef, useState } from 'react'
import { match } from 'ts-pattern'

import { CHECKBOX_COLUMN_ID } from './advanced-table/constants'
import { CustomTableHeadCell } from './advanced-table/custom-table-head-cell'
import { CustomTableRow, MemoizedCustomTableRow } from './advanced-table/custom-table-row'
import { TableEmptyState } from './advanced-table/table-empty-state'
import { TableLoadingState } from './advanced-table/table-loading-state'
import { TableSearchInput } from './advanced-table/table-search-input'

// Define the meta type for columns
declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    align?: 'left' | 'right'
  }
}

type AdvancedTableProps<TData extends RowData> = Pick<
  TableOptions<TData>,
  'columns' | 'data'
> & {
  sx?: SxProps
  emptyStateLabelKey?: I18nKey
  isLoading?: boolean
  onRowClick?: (row: TData) => void
  selectionState?: [RowSelectionState, Dispatch<SetStateAction<RowSelectionState>>]
  getRowId?: (row: TData) => string
  isVirtualized?: boolean
  virtualizedRowHeight?: number
  disableSearch?: boolean
}

export const AdvancedTable = <TData extends RowData>({
  columns,
  data,
  sx,
  emptyStateLabelKey,
  isLoading,
  onRowClick,
  selectionState,
  getRowId,
  isVirtualized = false,
  virtualizedRowHeight = 60,
  disableSearch = false,
}: AdvancedTableProps<TData>) => {
  const [rowSelection, setRowSelection] = selectionState ?? [undefined, undefined]
  const [globalFilter, setGlobalFilter] = useState('')
  const debouncedGlobalFilter = useDebounce(globalFilter, 200)

  const extendedColumns = useMemo<Array<ColumnDef<TData>>>(() => {
    if (!selectionState) {
      return columns
    }

    return [
      {
        id: CHECKBOX_COLUMN_ID,
        // TODO this can be enabled with a boolean flag in future if needed
        // header: ({ table }) => (
        //   <Checkbox
        //     checked={table.getIsAllRowsSelected()}
        //     indeterminate={table.getIsSomeRowsSelected()}
        //     onChange={table.getToggleAllRowsSelectedHandler()}
        //   />
        // ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      ...columns,
    ]
  }, [columns, selectionState])

  const table = useReactTable({
    columns: extendedColumns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableGlobalFilter: true,
    state: {
      globalFilter: debouncedGlobalFilter,
      rowSelection,
    },
    enableRowSelection: !!selectionState,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getRowId,
  })

  const { rows } = table.getRowModel()
  const columnCount = table.getAllColumns().length

  const scrollRef = useRef<HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => virtualizedRowHeight,
    getItemKey: index => rows[index]?.id ?? index,
    enabled: isVirtualized,
  })

  return (
    <Stack
      spacing={2}
      sx={[
        {
          // `minHeight: 0` is to make the table container shrink to show scrollbar when overflows, but does not cut the shadows like overflow: hidden does
          minHeight: 0,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {!disableSearch && (
        <TableSearchInput value={globalFilter} setValue={setGlobalFilter} />
      )}
      <TableContainer ref={scrollRef} sx={scrollbarStylesBlack}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {table.getFlatHeaders().map(header => (
                <CustomTableHeadCell key={header.id} header={header} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {match({
              isLoading,
              hasRows: rows.length > 0,
              isVirtualized,
            })
              .with({ isLoading: true }, () => (
                <TableLoadingState columnCount={columnCount} />
              ))
              .with({ hasRows: false }, () => (
                <TableEmptyState
                  emptyStateLabelKey={emptyStateLabelKey}
                  hasSearchFilter={!!debouncedGlobalFilter.length}
                  columnCount={columnCount}
                />
              ))
              .with({ isVirtualized: false }, () =>
                rows.map(row => (
                  <MemoizedCustomTableRow
                    key={row.id}
                    row={row}
                    onRowClick={onRowClick}
                    isSelectable={row.getCanSelect()}
                    isSelected={row.getCanSelect() && row.getIsSelected()}
                  />
                ))
              )
              .with({ isVirtualized: true }, () =>
                virtualizer.getVirtualItems().map((virtualRow, index) => {
                  const row = rows[virtualRow.index]

                  assertExists(row, 'row')

                  return (
                    <CustomTableRow
                      key={row.id}
                      row={row}
                      virtualRow={virtualRow}
                      index={index}
                      onRowClick={onRowClick}
                      isSelectable={row.getCanSelect()}
                      isSelected={row.getCanSelect() && row.getIsSelected()}
                    />
                  )
                })
              )
              .exhaustive()}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
