import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  List,
  ListIcon,
  ListItem,
  Stack,
  Tag,
  Text,
  type TextProps,
  useMediaQuery,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import {
  BriefCaseIcon,
  HeartIcon,
  HelpTooltip,
  NetworkUsersIcon,
  theme as retorioTheme,
  Tooltip,
} from '@repo/ui'
import {
  type Dispatch,
  type PropsWithChildren,
  type ReactNode,
  type SetStateAction,
  useMemo,
  useState,
} from 'react'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { VscCircleFilled } from 'react-icons/vsc'

import { get5SegmentDescription, type Result } from '../../hooks/use-result'
import { Legend } from '../charts/target-match-chart'
import { type FieldName } from '../recording-sessions-api'

type TagDimensions =
  | 'tfDealing'
  | 'tfIdentity'
  | 'tfVariety'
  | 'tfAutonomy'
  | 'tfSocialRelations'
  | 'cfCompetitive'
  | 'cfInnovative'
  | 'cfDetailOriented'
  | 'cfOutcomeOriented'
  | 'cfTeamOriented'
  | 'cfSupportive'
  | 'cfStability'

type HeadingProps = {
  icon?: ReactNode
  size?: 'md' | 'lg'
  color?: TextProps['color']
}

const Heading = ({
  children,
  icon,
  color = 'primary-dark',
  size = 'md',
}: PropsWithChildren<HeadingProps>) => {
  const sizeProps: {
    md: Pick<TextProps, 'fontSize' | 'fontWeight'>
    lg: Pick<TextProps, 'fontSize' | 'fontWeight'>
  } = {
    md: {
      fontSize: 'lg',
      fontWeight: 'semibold',
    },
    lg: {
      fontSize: '3xl',
      fontWeight: 'semibold',
    },
  }

  return (
    <Stack direction="row" align="center" mb={4}>
      {icon}
      <Text {...sizeProps[size]} color={color}>
        {children}
      </Text>
    </Stack>
  )
}

type DimensionScoreChartProps = {
  dimension: string
  rawValue: number
  benchmarkValue: number
  showTargetProfile: boolean
  keyStart: string
}

const DimensionScoreChart = ({
  dimension,
  rawValue,
  benchmarkValue,
  showTargetProfile,
  keyStart,
}: DimensionScoreChartProps) => {
  const { formatMessage } = useIntl()
  const segments = ['low', 'mediumLow', 'medium', 'mediumHigh', 'high']
  const valueSegment = get5SegmentDescription(rawValue)
  const benchmarkSegment = get5SegmentDescription(benchmarkValue)

  return (
    <Box w="100%" mt={10}>
      <Text size="lg" fontWeight="medium">
        <FormattedMessage
          id={`meta.benchmarkProfile.field.${dimension}.title.result` as I18nKey}
        />
        <HelpTooltip
          label={
            <FormattedMessage
              id={`recording.details.overview.${dimension}.hover` as I18nKey}
            />
          }
        />
      </Text>

      <Flex mt={4} mb={2} justify="space-between">
        <Text>
          <FormattedMessage
            id={`recording.details.preferences.${dimension}.chart.label.low` as I18nKey}
          />
        </Text>

        <Text>
          <FormattedMessage
            id={`recording.details.preferences.${dimension}.chart.label.high` as I18nKey}
          />
        </Text>
      </Flex>
      {showTargetProfile && (
        <Grid templateColumns="repeat(5, 1fr)" gap={1}>
          {segments.map(segment =>
            benchmarkSegment === segment ? (
              <Box key={segment} bg="indicator.green" borderRadius={2} h="4px" />
            ) : (
              <Box key={segment} h="4px" />
            )
          )}
        </Grid>
      )}
      <Grid templateColumns="repeat(5, 1fr)" gap={1} mt="1px">
        {segments.map(segment =>
          valueSegment === segment ? (
            <Tooltip
              key={segment}
              label={formatMessage({
                id: `${keyStart}.${dimension}.chart.description.${segment}` as I18nKey,
              })}
            >
              <Box cursor="pointer" bg="primary" borderRadius={2} h="4px" />
            </Tooltip>
          ) : (
            <Box key={segment} bg="indicator.gray" borderRadius={2} h="4px" />
          )
        )}
      </Grid>
    </Box>
  )
}

type PreferencesDetailsProps = {
  result: Result
  category: 'tf' | 'cf'
  showTargetProfile: boolean
  keyStart: string
  candidateView: boolean
}

const PreferencesDetails = ({
  result,
  category,
  showTargetProfile,
  keyStart,
  candidateView,
}: PreferencesDetailsProps) => {
  const { rawValues, benchmarkValues, isFieldInBenchmark } = result
  const categoryDimensions = Object.keys(rawValues)
    .filter(value => isFieldInBenchmark(value as FieldName))
    .filter((value: string) => value.startsWith(category))

  return (
    <Box bg="secondary-light" margin={4} marginTop={0} p={8} borderRadius="md">
      <Flex justify="flex-end">
        <Legend candidateView={candidateView} showTargetProfile={showTargetProfile} />
      </Flex>
      {categoryDimensions.map(dimension => (
        <DimensionScoreChart
          keyStart={keyStart}
          dimension={dimension}
          showTargetProfile={showTargetProfile}
          key={dimension}
          rawValue={rawValues[dimension] || 0}
          benchmarkValue={benchmarkValues[dimension] || 0}
        />
      ))}
    </Box>
  )
}

type PreferencesContainerProps = {
  scores: Result['scores']
  category: 'cf' | 'tf'
  heading: ReactNode
  showDetails: boolean
  setShowDetails: Dispatch<SetStateAction<boolean>>
  keyStart: string
}

const PreferencesContainer = ({
  category,
  heading,
  scores,
  showDetails,
  setShowDetails,
  keyStart,
}: PreferencesContainerProps) => {
  const { formatMessage } = useIntl()
  const RiIcon = showDetails ? RiArrowDropUpLine : RiArrowDropDownLine
  const top3 = useMemo(
    () =>
      scores
        .filter(score => score.id.startsWith(category))
        .slice(0, 3)
        .map(({ id, rawValue }) => ({
          id: id as TagDimensions,
          text: formatMessage({
            id: `${keyStart}.${id}.${get5SegmentDescription(rawValue)}` as I18nKey,
          }),
        })),
    [category, formatMessage, scores, keyStart]
  )

  return (
    <GridItem
      borderRadius="md"
      borderWidth="2px"
      borderColor="gray.200"
      borderBottomWidth={showDetails ? '0px' : '2px'}
      borderBottomRadius={showDetails ? '0px' : 'md'}
      colSpan={{ sm: 2, md: 2, lg: 2, xl: 1 }}
    >
      <Flex flexDirection="column" p={6} h="100%">
        {heading}
        {top3.length ? (
          <>
            <List flexGrow={2} spacing={4}>
              {top3.map(({ id, text }) => (
                <ListItem key={id}>
                  <Flex>
                    <ListIcon as={VscCircleFilled} color="primary" mt={1} />
                    <Box>
                      <Text>{text}</Text>
                      <Tag variant="secondary" fontWeight="400" m="0.5rem">
                        <FormattedMessage
                          id={`meta.benchmarkProfile.field.${id}.title.result`}
                        />
                      </Tag>
                    </Box>
                  </Flex>
                </ListItem>
              ))}
            </List>
            <Flex justify="center">
              <Box
                width="1.5em"
                height="1.5em"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={() => setShowDetails(!showDetails)}
                color="primary"
                sx={{ _hover: { color: 'blue.400' } }}
              >
                <Icon as={RiIcon} boxSize={6} />
                <FormattedMessage id={`${keyStart}.details` as I18nKey} />
              </Box>
            </Flex>
          </>
        ) : (
          <FormattedMessage id={`${keyStart}.noScores` as I18nKey} />
        )}
      </Flex>
    </GridItem>
  )
}

type PreferencesContainerDetailsProps = {
  category: 'cf' | 'tf'
  showDetails: boolean
  result: Result
  showTargetProfile: boolean
  keyStart: string
  candidateView: boolean
}

const PreferencesContainerDetails = ({
  category,
  showDetails,
  result,
  showTargetProfile,
  keyStart,
  candidateView,
}: PreferencesContainerDetailsProps) => (
  <GridItem
    borderColor="gray.200"
    borderWidth={showDetails ? '2px' : '0px'}
    borderTopWidth="0px"
    borderBottomRadius="md"
    colSpan={{ sm: 2, md: 2, lg: 2, xl: 1 }}
  >
    {showDetails ? (
      <PreferencesDetails
        keyStart={keyStart}
        candidateView={candidateView}
        showTargetProfile={showTargetProfile}
        result={result}
        category={category}
      />
    ) : null}
  </GridItem>
)

type CandidatePreferencesProps = {
  result: Result
  showTargetProfile: boolean
  candidateView: boolean
}

export const CandidatePreferences = ({
  result,
  showTargetProfile,
  candidateView,
}: CandidatePreferencesProps) => {
  const [showDetailsTf, setShowDetailsTf] = useState(false)
  const [showDetailsCf, setShowDetailsCf] = useState(false)
  const [isWidthXl] = useMediaQuery(`(min-width: ${retorioTheme.breakpoints.xl}`)
  const keyStart = candidateView
    ? 'recording.details.you.preferences'
    : 'recording.details.preferences'

  return (
    <Box>
      <Box py={5}>
        <Heading icon={<HeartIcon boxSize={5} />} color="black" size="lg">
          <FormattedMessage id={`${keyStart}.heading`} />
        </Heading>
        <Text>
          <FormattedMessage id={`${keyStart}.subheading`} />
        </Text>
      </Box>
      {isWidthXl ? (
        <Grid templateColumns="repeat(2, 1fr)" columnGap={3}>
          <PreferencesContainer
            category="tf"
            keyStart={keyStart}
            scores={result.scores}
            showDetails={showDetailsTf}
            setShowDetails={setShowDetailsTf}
            heading={
              <Heading icon={<BriefCaseIcon />}>
                <FormattedMessage id={`${keyStart}.heading.taskFit`} />
              </Heading>
            }
          />

          <PreferencesContainer
            category="cf"
            keyStart={keyStart}
            scores={result.scores}
            showDetails={showDetailsCf}
            setShowDetails={setShowDetailsCf}
            heading={
              <Heading icon={<NetworkUsersIcon />}>
                <FormattedMessage id={`${keyStart}.heading.cultureFit`} />
              </Heading>
            }
          />
          {(showDetailsTf || showDetailsCf) && (
            <>
              <PreferencesContainerDetails
                category="tf"
                keyStart={keyStart}
                candidateView={candidateView}
                result={result}
                showDetails={showDetailsTf}
                showTargetProfile={showTargetProfile}
              />

              <PreferencesContainerDetails
                category="cf"
                keyStart={keyStart}
                candidateView={candidateView}
                result={result}
                showDetails={showDetailsCf}
                showTargetProfile={showTargetProfile}
              />
            </>
          )}
        </Grid>
      ) : (
        <Grid
          templateColumns="repeat(2, 1fr)"
          rowGap={3}
          display="flex"
          flexDirection="column"
        >
          <Box>
            <PreferencesContainer
              category="tf"
              keyStart={keyStart}
              scores={result.scores}
              showDetails={showDetailsTf}
              setShowDetails={setShowDetailsTf}
              heading={
                <Heading icon={<BriefCaseIcon />}>
                  <FormattedMessage id={`${keyStart}.heading.taskFit`} />
                </Heading>
              }
            />
            {showDetailsTf && (
              <PreferencesContainerDetails
                category="tf"
                keyStart={keyStart}
                candidateView={candidateView}
                result={result}
                showTargetProfile={showTargetProfile}
                showDetails={showDetailsTf}
              />
            )}
          </Box>
          <Box>
            <PreferencesContainer
              category="cf"
              keyStart={keyStart}
              scores={result.scores}
              showDetails={showDetailsCf}
              setShowDetails={setShowDetailsCf}
              heading={
                <Heading icon={<NetworkUsersIcon />}>
                  <FormattedMessage id={`${keyStart}.heading.cultureFit`} />
                </Heading>
              }
            />
            {showDetailsCf && (
              <PreferencesContainerDetails
                category="cf"
                keyStart={keyStart}
                candidateView={candidateView}
                result={result}
                showDetails={showDetailsCf}
                showTargetProfile={showTargetProfile}
              />
            )}
          </Box>
        </Grid>
      )}
    </Box>
  )
}
