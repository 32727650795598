import { Box, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { FieldArray, useFormikContext } from 'formik'

import { QuestionList } from './question-list'
import { type FormValues } from './types'

export const QuestionsForm = () => {
  const {
    values: { questions },
  } = useFormikContext<FormValues>()

  return (
    <Stack>
      <FieldArray name="questions" validateOnChange={false}>
        {arrayProps => <QuestionList arrayProps={arrayProps} />}
      </FieldArray>
      {questions.length === 0 && (
        <Box bgColor={colors.secondary} borderRadius="sm" p="4">
          <FormattedMessage id="questionnaire.form.questionList.empty.text" />
        </Box>
      )}
    </Stack>
  )
}

export const initialValues = {
  questions: [
    {
      active: true,
      text: '',
      note: '',
      repeatable: 0,
      timeLimit: 60,
      type: 'standard',
      order: 0,
    },
  ],
}
