import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { FormikFormField, Spinner } from '@repo/ui'
import { Form, useFormikContext } from 'formik'

import { DateTimePicker } from '../../components/date-time-picker'
import { type AvailableProgram } from '../types/available-program'
import { type CohortProgramAssignFormValues } from '../types/cohort-program-assign-form-values'

export const CohortProgramAssignForm = ({
  availablePrograms,
  isLoading,
  showActionsButtons = true,
  maxHeight = 400,
}: {
  availablePrograms: Array<AvailableProgram>
  isLoading: boolean
  showActionsButtons?: boolean
  maxHeight?: number
}) => {
  const {
    errors,
    touched,
    isValid,
    dirty,
    isSubmitting,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<CohortProgramAssignFormValues>()

  const onChangeDateField = (fieldName: string, value: Date | undefined) => {
    setFieldValue(fieldName, value)
    setFieldTouched(fieldName, true)
  }

  return (
    <Form>
      <Heading as="h6" color="gray.600" size="xs">
        <FormattedMessage id="cohort.program.assignForm.period.label" />
      </Heading>

      <Text size="xs">
        <FormattedMessage id="cohort.program.assignForm.period.subLabel" />
      </Text>

      <HStack spacing={4}>
        <Box>
          <Text color="gray" size="xs" my={2}>
            <FormattedMessage id="cohort.program.assignForm.startDate.label" />
          </Text>
          <DateTimePicker
            value={values.startDate}
            onChange={value => onChangeDateField('startDate', value)}
          />
        </Box>
        <Box>
          <Text color="gray" size="xs" my={2}>
            <FormattedMessage id="cohort.program.assignForm.endDate.label" />
          </Text>
          <DateTimePicker
            value={values.endDate}
            onChange={value => onChangeDateField('endDate', value)}
          />
        </Box>
      </HStack>
      {touched.startDate && errors.startDate && (
        <Box color="red">
          <FormattedMessage id="cohort.program.assignForm.period.error.startDate" />
        </Box>
      )}
      {touched.endDate && errors.endDate && (
        <Box color="red">
          <FormattedMessage id="cohort.program.assignForm.period.error.endDate" />
        </Box>
      )}
      <Heading as="h6" color="gray.600" size="xs" mt={8} mb={2}>
        <FormattedMessage id="coaching.program.assignForm.programs.label" />
      </Heading>
      {isLoading ? (
        <Center h={200}>
          <Spinner size="lg" />
        </Center>
      ) : (
        <Box className="training-list" maxH={maxHeight} overflow="auto">
          <Box>
            {availablePrograms.map(program => (
              <Flex alignItems="center" key={program.id}>
                <FormikFormField
                  as={Checkbox}
                  name="programIds"
                  customValue={program.id}
                />
                <Box ml={2}>
                  <Text display="inline">{program.title}</Text>
                </Box>
              </Flex>
            ))}
            {!availablePrograms.length && (
              <FormattedMessage id="coaching.program.assignForm.programs.noneAvailable" />
            )}
          </Box>
        </Box>
      )}

      {showActionsButtons && (
        <HStack mt={12} spacing={4} justify="center">
          <Button variant="outline" type="reset">
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button type="submit" isLoading={isSubmitting} isDisabled={!(dirty && isValid)}>
            <FormattedMessage id="cohort.program.assignForm.save" />
          </Button>
        </HStack>
      )}
    </Form>
  )
}
