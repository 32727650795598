import { Input, type InputProps } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { useFormikContext } from 'formik'
import { memo, useMemo } from 'react'

import { FormControlInputField } from '../form/form-control-input-field'
import { usePasswordInputControl } from '../hooks/use-password-input-control'

export type LoginFormValue = {
  email: string
  password: string
}

type LoginFormProps = Pick<InputProps, 'size'>

export const LoginForm = memo(({ size }: LoginFormProps) => {
  const { formatMessage } = useIntl()
  const { isSubmitting, errors, touched } = useFormikContext<LoginFormValue>()
  const { passwordInputProps, showHideButton } = usePasswordInputControl({
    placeholder: formatMessage({ id: 'login.form.field.password.placeholder' }),
    isDisabled: isSubmitting,
  })

  const emailInputProps = useMemo<InputProps>(
    () => ({
      size,
      type: 'email',
      placeholder: formatMessage({ id: 'login.form.field.email.placeholder' }),
      isDisabled: isSubmitting,
      isRequired: true,
      autoFocus: true,
      variant: 'filled',
    }),
    [size, formatMessage, isSubmitting]
  )

  return (
    <>
      <FormControlInputField<LoginFormValue>
        errors={errors}
        touched={touched}
        inputType={Input}
        errorMessageKey={errors.email}
        prop="email"
        label={formatMessage({ id: 'login.form.field.email.label' })}
        inputProps={emailInputProps}
      />
      <FormControlInputField<LoginFormValue>
        errors={errors}
        inputType={Input}
        touched={touched}
        errorMessageKey={errors.password}
        prop="password"
        label={formatMessage({ id: 'login.form.field.password.label' })}
        inputProps={passwordInputProps}
      >
        {showHideButton}
      </FormControlInputField>
    </>
  )
})
