import { Box } from '@chakra-ui/react'
import { Controller, type FieldPath, type FieldValues } from 'react-hook-form'

import {
  RadioButtonGroup,
  type RadioButtonGroupProps,
} from '../../components/radio-button-group'
import { FormControlWrapper } from './shared/form-control-wrapper'
import { type FieldBaseProps } from './shared/types'

export const FormFieldRadioButtonGroup = <
  T extends FieldValues,
  TName extends FieldPath<T>,
>(
  props: FieldBaseProps<T, Pick<RadioButtonGroupProps, 'options'>, TName, true>
) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    componentProps: { options },
  } = props

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FormControlWrapper fieldBaseProps={props} error={fieldState.error}>
          <Box w="max-content">
            <RadioButtonGroup
              options={options}
              radioGroupProps={{
                name,
                value: field.value,
                isDisabled: field.disabled,
                onChange: value => field.onChange(value),
              }}
            />
          </Box>
        </FormControlWrapper>
      )}
    />
  )
}
