import { Skeleton, Stack } from '@chakra-ui/react'

import { BaseCard } from './shared/base-card'

export const GridSkeletonCard = () => (
  <BaseCard _hover={undefined}>
    <Skeleton w="full" aspectRatio="16/9" />
    <Stack flex={1} p={4} gap={3}>
      <Skeleton height="20px" w="50%" />

      <Stack gap={1}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    </Stack>
  </BaseCard>
)
