import { Button, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FormattedMessage } from '@repo/i18n'
import { ClickableTr, colors, PageHeader } from '@repo/ui'
import { memo } from 'react'
import { RiAddFill } from 'react-icons/ri'
import { Link, useNavigate } from 'react-router-dom'

import { AppHeaderTitle } from '../../components/app-header-title'
import { CenteredSpinner } from '../../components/centered-spinner'
import { useThunkFetching } from '../../hooks/thunk-fetching'
import { fetchQuestionnaires } from '../../store/entities/questionnaires/effects'
import { makeSelectQuestionnaires } from '../../store/entities/questionnaires/selectors'
import { selectQuestionnaireIds } from '../../store/ui/plugin-editor/selectors'
import { formatDateTime } from '../../utils/dates'
import { PageList } from '../page-list'

const flexColumnCss = css`
  display: flex;
  flex-direction: column;
`

const TableContainer = styled.div`
  ${flexColumnCss}
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;
`

const selectQuestionnaires = makeSelectQuestionnaires()

export const QuestionnaireList = memo(
  () => {
    const navigate = useNavigate()

    const [fetching, questionnaires, error] = useThunkFetching({
      action: fetchQuestionnaires,
      mapState: state => selectQuestionnaires(state, selectQuestionnaireIds),
      deps: '',
    })

    if (error) {
      return <div>{error.message}</div>
    }

    return (
      <PageList css={flexColumnCss}>
        <AppHeaderTitle formattedMessageId="sidebar.item.questionnaires" />
        <PageHeader
          buttons={
            <Button
              as={Link}
              to="create"
              leftIcon={<RiAddFill size="1.25em" />}
              iconSpacing="1"
              _hover={{
                bgColor: colors['primary-dark'],
                color: colors.buttonColor,
              }}
            >
              <FormattedMessage id="coaching.programList.button.create" />
            </Button>
          }
        />
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <FormattedMessage id="common.name" />
                </Th>
                <Th>
                  <FormattedMessage id="questionnaire.QuestionnaireList.number.questions" />
                </Th>
                <Th>
                  <FormattedMessage id="coaching.programList.table.update" />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {questionnaires.map(questionnaire => (
                <ClickableTr
                  key={questionnaire.id}
                  _hover={{ bgColor: colors.lightBlue }}
                  onClick={() => navigate(questionnaire.id)}
                >
                  <Td>{questionnaire.name}</Td>
                  <Td>{questionnaire.questions.length}</Td>
                  <Td>{formatDateTime(questionnaire.updatedAt)}</Td>
                </ClickableTr>
              ))}
            </Tbody>
          </Table>
          {fetching && <CenteredSpinner />}
        </TableContainer>
      </PageList>
    )
  },
  // Since there are no props, the components never needs to be re-rendered.
  // This optimization is in place because we use ReactRouter render prop (https://reactrouter.com/web/api/Route/render-func),
  // that will trigger a re-render everytime one of the Route props change (https://reactrouter.com/web/api/Route/route-props).
  () => true // propsAreEqual
)
