// DO NOT EDIT THIS FILE DIRECTLY. Autogenerated by "codegen:openapi"

interface paths {
  '/:extId/feedbacks': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['createFeedbackForRecording']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/exercises': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getExercises']
    put?: never
    post: operations['createExercise']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/exercises/:extId': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['updateExercise']
    post?: never
    delete: operations['deleteExercise']
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/exercises/:extId/connect': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['updateConnectedProgramsOfExercise']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/personas': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['createPersona']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/personas/:personaExtId': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['updatePersona']
    post?: never
    delete: operations['deletePersona']
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/personas/:personaExtId/duplicate': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['duplicatePersona']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/personas/avatarsAndVoices': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getAvatarsAndVoices']
    put?: never
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/program_templates': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getAllProgramTemplates']
    put?: never
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/program_templates/:extId': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['createProgramUsingTemplate']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getAllPrograms']
    put?: never
    post: operations['createProgram']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:extId': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getProgramByExtId']
    put: operations['updateProgram']
    post?: never
    delete: operations['deleteProgram']
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:extId/clone': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['cloneProgram']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:extId/publish': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['publishProgram']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:extId/share': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['shareProgram']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:extId/share/:sharedExtId': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post?: never
    delete: operations['deleteSharedProgram']
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:extId/unpublish': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['unpublishProgram']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getTrainings']
    put?: never
    post: operations['createTraining']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:extId': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getTrainingByExtId']
    put: operations['updateTraining']
    post?: never
    delete: operations['deleteTraining']
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getScenarios']
    put?: never
    post: operations['createScenario']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getScenarioByExtId']
    put?: never
    post?: never
    delete: operations['deleteScenarioByExtId']
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/behavioral_attitudes': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['saveBehavioralAttitudes']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/details': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['updateScenarioDetails']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/goal': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['updateScenarioAtGoalStep']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/persona': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['selectPersona']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/personas': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getPersonas']
    put?: never
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/stage_goals': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['saveStageGoals']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/startVideoGenerations': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['startVideoGenerations']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/trainee': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['selectTrainee']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/trainees': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getTrainees']
    put?: never
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/updateVideoGenerationData': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['updateVideoGenerationData']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/data_generations/ai_supported/scenario_content': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['generateScenarioContentForAiSupportedScenario']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/data_generations/realtime/instructions': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['generateInstructions']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/data_generations/realtime/scenario_content': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['generateScenarioContentForRealtimeScenario']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/instructions': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['createInstruction']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/instructions/:extId': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['updateInstruction']
    post?: never
    delete: operations['deleteInstruction']
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/steps/:id/aiQaStep': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['updateAiQaStep']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/steps/:id/generateContent': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['generateStepContent']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/steps/aiQaStep': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['createAiQaStep']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/winning_behaviors_realtime': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['createWinningBehaviorRealtime']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/winning_behaviors_realtime/:extId': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['updateWinningBehaviorRealtime']
    post?: never
    delete: operations['deleteWinningBehaviorRealtime']
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/order': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['reorderScenarios']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/:programExtId/trainings/order': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['reorderTrainings']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/sharedPrograms': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getSharedPrograms']
    put?: never
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/sharedPrograms/:sharedExtId/accept': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['acceptSharedProgram']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/programs/sharedPrograms/:sharedExtId/reject': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['rejectSharedProgram']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/skills': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getSkills']
    put?: never
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/trainees': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['createTrainee']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/trainees/:traineeExtId': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['updateTrainee']
    post?: never
    delete: operations['deleteTrainee']
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/trainees/:traineeExtId/duplicate': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['duplicateTrainee']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/admin/winning_behaviors/templates': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['getWinningBehaviorTemplates']
    put?: never
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/ai/analysis_results': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['registerAnalysisResults']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/ai/benchmark_profile_results': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['createOrUpdateBenchmarkProfileResults']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/general/user_info': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['getUserInfo']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/user_activity_sessions': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['createUserActivitySession']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/user_activity/active_session': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put: operations['updateActiveSession']
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/user_activity/clean_up': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get: operations['cleanUpInactiveSessions']
    put?: never
    post?: never
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
  '/user_activity/create_active_session': {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    get?: never
    put?: never
    post: operations['createActiveSession']
    delete?: never
    options?: never
    head?: never
    patch?: never
    trace?: never
  }
}
type webhooks = Record<string, never>
interface components {
  schemas: {
    addNewEmailsEndpoint: {
      /** @description List of emails to invite into cohort */
      emails: string[]
      language: components['schemas']['languageEnum']
    }
    AiAnalysisResultsEndpoint: {
      /** @description AI Analysis ID */
      analysisId: string
      /** @description Analysis results or error response */
      results:
        | {
            id: string
            metadata: {
              analysis_created: string
              analysis_finished: string
              analysis_started: string
              language: string
              video_duration: number
              video_size: number
              video_uploaded: string
            }
            predictions: unknown[]
          }
        | {
            error: Record<string, never>
          }
    }
    AiBenchmarkProfileResultsEndpoint: {
      /** @description AI Main ID */
      benchmarkMainId: string
      /** @description AI Profile ID */
      benchmarkProfileId: string
      /** @description Benchmark profile results or error response */
      results:
        | {
            profile: {
              analyses: unknown[]
              created_at: string
              description?: string | null
              environment: string
              features: Record<string, never>
              is_active: boolean
              is_current: boolean
              main_id: string
              parent_id?: string | null
              profile_id: string
              version: string
            }
          }
        | {
            error_code: number
            message: string
          }
    }
    aiQaStepEntity: {
      answerVideoGenerationData?:
        | components['schemas']['videoGenerationDataEntity']
        | null
      behavioralGoals?: string | null
      createdAt: string
      donts?: components['schemas']['dontsEntity'] | null
      dos?: components['schemas']['dosEntity'] | null
      id: string
      isVideoGenerationDataChanged: boolean
      maxRecordingTime: number
      questionTranscript?: string | null
      questionVideoGenerationData?:
        | components['schemas']['videoGenerationDataEntity']
        | null
      /** @enum {string} */
      stepType: 'coachingStepQA'
      /** @enum {string} */
      type: 'aiSupported'
      updatedAt: string
    }
    availablePersonas: (components['schemas']['personaEntity'] & {
      editable: boolean
      scenariosAttached: number
    })[]
    availableTrainees: (components['schemas']['traineeEntity'] & {
      editable: boolean
      scenariosAttached: number
    })[]
    cloneProgram: {
      title: string
    }
    coachingScenariosEntity: {
      createdAt: string
      degreeOfRelationship?: string | null
      description?: string | null
      durationInMinutes: number
      editable: boolean | null
      extId: string
      finalPersonaAttitude?: string | null
      furtherDetails?: string | null
      goal?: string | null
      hasRecordingSessions: boolean
      interlocutorRole?: string | null
      missionGoal?: string | null
      missionStages: components['schemas']['missionStageEntity'][] | null
      order: number
      persona: components['schemas']['personaEntity'] | null
      personaOpeningStatement?: string | null
      realtimeCallType?: components['schemas']['realtimeCallType'] | null
      thumbnailImgUrl?: string | null
      title?: string | null
      trainee: components['schemas']['traineeEntity'] | null
      traineeRole?: string | null
      type: components['schemas']['scenarioType']
      updatedAt: string
      winningBehavior: components['schemas']['winningBehaviorsEntity'] | null
    }
    createExercise: {
      exerciseData: components['schemas']['createExerciseData']
      programExtIds?: string[] | null
    }
    createExerciseData: {
      description: string
      durationInMinutes: number
      execution: string
      imageUrl: string
      language: components['schemas']['languageEnum']
      preparationItems: {
        text: string
      }[]
      shortDescription: string
      title: string
      type: components['schemas']['exerciseType']
    }
    createInstruction: {
      /** @enum {string} */
      behaviorType: 'positive' | 'negative'
      description: string
      hows: components['schemas']['createInstructionHow'][]
      missionStageOrder: number
      references?: string | null
      skillName: string
    }
    createInstructionHow: {
      description: string
      references?: string | null
    }
    createOrUpdatePersona: {
      /** @enum {string|null} */
      age?: 'youngAdult' | 'adult' | 'olderAdult' | null
      /** @enum {string} */
      attitude: 'positive' | 'neutral' | 'negative'
      backgroundUrl?: string | null
      /** @enum {string} */
      conversationContext: 'b2c' | 'b2b' | 'internal'
      goals?: string | null
      industry?: string | null
      jobRole?: string | null
      manners?: string | null
      name: string
      needsAndPains?: string | null
      personality: string
      personalValues: string
      /** @enum {string} */
      sex: 'male' | 'female'
      videoGenerationAvatarExtId: string
      videoGenerationVoiceExtId: string
    }
    createOrUpdateTrainee: {
      department: string
      hasLeadershipResponsibility: boolean
      /** @enum {string} */
      hierarchy: 'low' | 'mid' | 'high'
      imageUrl?: string | null
      industry: string
      jobRole: string
      name: string
      /** @enum {string} */
      seniority: 'low' | 'mid' | 'high' | 'veryHigh'
      /** @enum {string|null} */
      workplace?: 'hybrid' | 'remote' | 'onSite' | null
    }
    createProgram: {
      description?: string | null
      imageUrl?: string | null
      language: components['schemas']['languageEnum']
      title: string
    }
    createQAStepSchema: {
      order: number
    }
    createScenario: {
      type: components['schemas']['scenarioType']
    }
    createTraining: {
      description?: string | null
      order: number
      title: string
    }
    createWinningBehaviorRealtime: {
      /** @enum {string} */
      behaviorType: 'positive' | 'negative'
      description: string
      missionStageOrder: number
      skillName: string
    }
    dontsEntity: {
      instruction: string
    }[]
    dosEntity: {
      instruction: string
    }[]
    exerciseEntity: {
      createdAt: string
      description: string
      durationInMinutes: number
      execution: string
      extId: string
      imageUrl: string
      language: components['schemas']['languageEnum']
      preparationItems: {
        text: string
      }[]
      shortDescription: string
      title: string
      type: components['schemas']['exerciseType']
      updatedAt: string
    }
    /** @enum {string} */
    exerciseType:
      | 'facialExpressions'
      | 'speakingSpeed'
      | 'understandability'
      | 'pauses'
      | 'socialFocus'
      | 'positiveLanguage'
      | 'sentenceLength'
      | 'adjectives'
    extendedProgramEntity: components['schemas']['programEntity'] & {
      hasBreakingChanges: boolean
      hasRecordingSessions: boolean
      hasRegisteredCoachingUsers: boolean
      sharedPrograms: components['schemas']['sharedProgramEntity'][]
      trainingsCount: number
    }
    generateScenarioContentRequestAiSupported: {
      /** @enum {string} */
      field: 'learning_goal' | 'scenario_description'
      /** @enum {string} */
      tone: 'professional' | 'catchy'
      userActivitySessionExtId: string
    }
    generateScenarioContentRequestRealtime:
      | {
          /** @enum {string} */
          field: 'learning_goal'
          /** @enum {string} */
          tone: 'professional' | 'catchy'
          userActivitySessionExtId: string
        }
      | {
          /** @enum {string} */
          field: 'scenario_description'
          /** @enum {string} */
          tone: 'professional' | 'catchy'
          userActivitySessionExtId: string
        }
      | {
          /** @enum {string} */
          field: 'mission_goal'
          userActivitySessionExtId: string
        }
      | {
          /** @enum {string} */
          field: 'stage_goals'
          userActivitySessionExtId: string
        }
      | {
          /** @enum {string} */
          field: 'initial_statements'
          userActivitySessionExtId: string
        }
    generateScenarioContentResponseAiSupported: {
      /** @enum {string} */
      field: 'learning_goal' | 'scenario_description'
      suggestion: string
      /** @enum {string} */
      tone: 'professional' | 'catchy'
    }
    generateScenarioContentResponseRealtime:
      | {
          /** @enum {string} */
          field: 'learning_goal'
          suggestion: string
          /** @enum {string} */
          tone: 'professional' | 'catchy'
        }
      | {
          /** @enum {string} */
          field: 'scenario_description'
          suggestion: string
          /** @enum {string} */
          tone: 'professional' | 'catchy'
        }
      | {
          /** @enum {string} */
          field: 'mission_goal'
          suggestion: string
        }
      | {
          /** @enum {string} */
          field: 'stage_goals'
          suggestion: string[]
        }
      | {
          /** @enum {string} */
          field: 'initial_statements'
          suggestion: string
        }
    generateStepContentRequest: {
      /** @enum {string} */
      field: 'interlocutor_statement' | 'trainee_expected_behavior' | 'dos' | 'donts'
      userActivitySessionExtId: string
    }
    generateStepContentResponse:
      | {
          /** @enum {string} */
          field: 'interlocutor_statement' | 'trainee_expected_behavior'
          suggestion: string
        }
      | {
          /** @enum {string} */
          field: 'dos' | 'donts'
          suggestion: string[]
        }
    instructionEntity: {
      /** @enum {string} */
      behaviorType: 'positive' | 'negative'
      createdAt: string
      description: string
      extId: string
      hows: components['schemas']['instructionHowEntity'][]
      references?: string | null
      skill: components['schemas']['skillEntity']
      updatedAt: string
    }
    instructionHowEntity: {
      description: string
      references?: string | null
    }
    /** @enum {string} */
    languageEnum: 'en-US' | 'fr-FR' | 'de-DE' | 'es-ES' | 'it-IT' | 'pt-PT'
    missionStageEntity: {
      createdAt: string
      extId: string
      instructions: components['schemas']['instructionEntity'][] | null
      order: number
      personaAttitude?: string | null
      stageGoal?: string | null
      updatedAt: string
      winningBehaviorsRealtime:
        | components['schemas']['winningBehaviorRealtimeEntity'][]
        | null
    }
    personaEntity: {
      /** @enum {string|null} */
      age?: 'youngAdult' | 'adult' | 'olderAdult' | null
      /** @enum {string} */
      attitude: 'positive' | 'neutral' | 'negative'
      backgroundUrl?: string | null
      /** @enum {string} */
      conversationContext: 'b2c' | 'b2b' | 'internal'
      createdAt: string
      extId: string
      firstVersionExtId?: string | null
      goals?: string | null
      industry?: string | null
      jobRole?: string | null
      language: string
      manners?: string | null
      name: string
      needsAndPains?: string | null
      nextVersionExtId?: string | null
      personality: string
      personalValues: string
      /** @enum {string} */
      sex: 'male' | 'female'
      /** @enum {string} */
      type: 'template' | 'custom'
      updatedAt: string
      videoGenerationAvatar: components['schemas']['videoGenerationAvatarEntity'] | null
      videoGenerationVoice: components['schemas']['videoGenerationVoiceEntity'] | null
    }
    ProcessVideoSchema: {
      mirrorVideo: boolean
      payloadId: string
      /** @enum {string} */
      product: 'recruiting' | 'coaching'
      userMediaData?: string[]
      userNavigatorData?: string
      videoUrl: string
    }
    programEntity: {
      createdAt: string
      description?: string | null
      extId: string
      imageUrl?: string | null
      language: components['schemas']['languageEnum']
      status: components['schemas']['programStatus']
      title: string
      understandingEnabled: boolean
      updatedAt: string
    }
    /** @enum {string} */
    programStatus: 'unpublished' | 'publishing' | 'published'
    programTemplateEntity: {
      createdAt: string
      departments: string[]
      extId: string
      industries: string[]
      program: {
        description?: string | null
        extId: string
        imageUrl?: string | null
        language: components['schemas']['languageEnum']
        title: string
        trainings: {
          extId: string
          scenarios: {
            description?: string | null
            durationInMinutes: number
            extId: string
            persona: {
              backgroundUrl?: string | null
              videoGenerationAvatar: {
                imageUrl?: string | null
              }
            } | null
            title?: string | null
          }[]
          title: string
        }[]
      }
      submittedSessionsCount: number
      /** @enum {string} */
      tag?: 'new' | 'popular'
      useCases: string[]
    }
    publishProgram: {
      shouldNotify: boolean
    }
    /** @enum {string} */
    realtimeCallType: 'videoCall' | 'phoneCall'
    reorderScenarios: {
      extId: string
      order: number
    }[]
    reorderTrainings: {
      extId: string
      order: number
    }[]
    requestPasswordResetEndpoint: {
      /** Format: email */
      email: string
    }
    saveBehavioralAttitudes: {
      finalPersonaAttitude?: string | null
      personaAttitudes: {
        0?: string | null
        1?: string | null
        2?: string | null
      }
      personaOpeningStatement: string
    }
    saveStageGoals: {
      stageGoals: string[]
    }
    /** @enum {string} */
    scenarioType: 'manual' | 'aiSupported' | 'realtimeBuilder'
    sharedProgramEntity: {
      createdAt: string
      extId: string
      receiverEmail: string
      status: components['schemas']['sharedProgramStatus']
      updatedAt: string
    }
    sharedProgramStatus: ('pending' | 'accepted' | 'rejected') | 'expired'
    shareProgram: {
      /** Format: email */
      email: string
    }
    skillEntity: {
      createdAt: string
      extId: string
      language: components['schemas']['languageEnum'] | null
      name: string
      /** @enum {string} */
      type: 'template' | 'custom'
      updatedAt: string
    }
    stepEntity: {
      createdAt: string
      id: string
      order: number
      step?: components['schemas']['aiQaStepEntity'] | null
      updatedAt: string
    }
    traineeEntity: {
      createdAt: string
      department: string
      extId: string
      firstVersionExtId?: string | null
      hasLeadershipResponsibility: boolean
      /** @enum {string} */
      hierarchy: 'low' | 'mid' | 'high'
      imageUrl?: string | null
      industry: string
      jobRole: string
      language: string
      name: string
      nextVersionExtId?: string | null
      /** @enum {string} */
      seniority: 'low' | 'mid' | 'high' | 'veryHigh'
      /** @enum {string} */
      type: 'template' | 'custom'
      updatedAt: string
      /** @enum {string|null} */
      workplace?: 'hybrid' | 'remote' | 'onSite' | null
    }
    trainingEntity: {
      createdAt: string
      description?: string | null
      extId: string
      title: string
      updatedAt: string
    }
    updateInstruction: {
      /** @enum {string} */
      behaviorType: 'positive' | 'negative'
      description: string
      hows: components['schemas']['createInstructionHow'][]
      references?: string | null
      skillName: string
    }
    updateProgram: {
      description?: string | null
      imageUrl?: string | null
      title: string
    }
    updateProgramNotificationsFlagEndpoint: {
      isEnabled: boolean
    }
    updateQAStepSchema: {
      behavioralGoals?: string | null
      donts?: components['schemas']['dontsEntity'] | null
      dos?: components['schemas']['dosEntity'] | null
      questionTranscript: string
    }
    updateScenarioAtGoalStep: {
      furtherDetails?: string | null
    } & (
      | {
          badExamples?: string | null
          description: string
          goodExamples: string
          title: string
          /** @enum {string} */
          winningBehaviorType: 'custom'
        }
      | {
          winningBehaviorExtId: string
          /** @enum {string} */
          winningBehaviorType: 'template'
        }
    )
    updateScenarioDetails: {
      degreeOfRelationship: string
      description?: string | null
      furtherDetails?: string | null
      goal?: string | null
      interlocutorRole: string
      missionGoal?: string | null
      realtimeCallType?: components['schemas']['realtimeCallType'] | null
      title?: string | null
      traineeRole?: string | null
    }
    updateTraining: {
      description?: string | null
      title: string
    }
    updateUserLanguageEndpoint: {
      language: components['schemas']['languageEnum']
    }
    updateUserStatusEndpoint: {
      isDisabled: boolean
    }
    updateVideoDataRequest: {
      stepExtIds: string[]
    }
    updateWinningBehaviorRealtime: {
      /** @enum {string} */
      behaviorType: 'positive' | 'negative'
      description: string
      skillName: string
    }
    userExtIdsList: {
      /** @description List of user ext ids */
      userExtIds: string[]
    }
    userFeedbackEndpoint: {
      /** @description user customFeedback */
      customFeedback?: string | null
      /**
       * @description user selectedOption
       * @enum {string}
       */
      selectedOption: 'acceptedAnswer' | 'rejectedAnswer' | 'other'
    }
    videoGenerationAvatarEntity: {
      extId: string
      gender: string
      imageUrl?: string | null
      name?: string | null
      previewUrl: string
      thumbnailUrl?: string | null
    }
    videoGenerationDataEntity: {
      errorPayload?: unknown
      extId: string
      /** @enum {string} */
      status: 'init' | 'pending' | 'done' | 'failed'
      videoUrl?: string | null
    }
    videoGenerationVoiceEntity: {
      extId: string
      gender: string
      labelKey?: string | null
      name: string
      previewUrl?: string | null
    }
    winningBehaviorRealtimeEntity: {
      /** @enum {string} */
      behaviorType: 'positive' | 'negative'
      createdAt: string
      description: string
      extId: string
      skill: components['schemas']['skillEntity']
      updatedAt: string
    }
    winningBehaviorsCustomEntity: {
      badExamples?: string | null
      description: string
      goodExamples: string
      title: string
    }
    winningBehaviorsEntity: {
      createdAt: string
      extId: string
      isTemplate: boolean
      updatedAt: string
      winningBehaviorsCustom: components['schemas']['winningBehaviorsCustomEntity'] | null
      winningBehaviorsTemplates:
        | components['schemas']['winningBehaviorsTemplatesEntity']
        | null
    }
    winningBehaviorsTemplatesEntity: {
      skill: string
      tactic: string
    }
  }
  responses: never
  parameters: never
  requestBodies: never
  headers: never
  pathItems: never
}
type $defs = Record<string, never>
interface operations {
  createFeedbackForRecording: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: {
      content: {
        'application/json': components['schemas']['userFeedbackEndpoint']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  getExercises: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': (components['schemas']['exerciseEntity'] & {
            programExtIds: string[]
          })[]
        }
      }
    }
  }
  createExercise: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['createExercise']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['exerciseEntity']
        }
      }
    }
  }
  updateExercise: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['createExerciseData']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['exerciseEntity']
        }
      }
    }
  }
  deleteExercise: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  updateConnectedProgramsOfExercise: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': {
          programExtIds: string[]
        }
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  createPersona: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['createOrUpdatePersona']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['personaEntity']
        }
      }
    }
  }
  updatePersona: {
    parameters: {
      query?: never
      header?: never
      path: {
        personaExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['createOrUpdatePersona']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['personaEntity']
        }
      }
    }
  }
  deletePersona: {
    parameters: {
      query?: never
      header?: never
      path: {
        personaExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  duplicatePersona: {
    parameters: {
      query?: never
      header?: never
      path: {
        personaExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['personaEntity']
        }
      }
    }
  }
  getAvatarsAndVoices: {
    parameters: {
      query: {
        language: components['schemas']['languageEnum']
      }
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': {
            avatars: components['schemas']['videoGenerationAvatarEntity'][]
            voices: components['schemas']['videoGenerationVoiceEntity'][]
          }
        }
      }
    }
  }
  getAllProgramTemplates: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['programTemplateEntity'][]
        }
      }
    }
  }
  createProgramUsingTemplate: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': string
        }
      }
    }
  }
  getAllPrograms: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['extendedProgramEntity'][]
        }
      }
    }
  }
  createProgram: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['createProgram']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['programEntity']
        }
      }
    }
  }
  getProgramByExtId: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['programEntity'] & {
            hasBreakingChanges: boolean
            hasRegisteredCoachingUsers: boolean
          }
        }
      }
    }
  }
  updateProgram: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['updateProgram']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['programEntity']
        }
      }
    }
  }
  deleteProgram: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  cloneProgram: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['cloneProgram']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['programEntity']
        }
      }
    }
  }
  publishProgram: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['publishProgram']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  shareProgram: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['shareProgram']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['sharedProgramEntity']
        }
      }
    }
  }
  deleteSharedProgram: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        sharedExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  unpublishProgram: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  getTrainings: {
    parameters: {
      query?: never
      header?: never
      path: {
        programExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': (components['schemas']['trainingEntity'] & {
            scenariosCount: number
          })[]
        }
      }
    }
  }
  createTraining: {
    parameters: {
      query?: never
      header?: never
      path: {
        programExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['createTraining']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['trainingEntity']
        }
      }
    }
  }
  getTrainingByExtId: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['trainingEntity']
        }
      }
    }
  }
  updateTraining: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['updateTraining']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['trainingEntity']
        }
      }
    }
  }
  deleteTraining: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  getScenarios: {
    parameters: {
      query?: never
      header?: never
      path: {
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['coachingScenariosEntity'][]
        }
      }
    }
  }
  createScenario: {
    parameters: {
      query?: never
      header?: never
      path: {
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['createScenario']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['coachingScenariosEntity']
        }
      }
    }
  }
  getScenarioByExtId: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['coachingScenariosEntity']
        }
      }
    }
  }
  deleteScenarioByExtId: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  saveBehavioralAttitudes: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['saveBehavioralAttitudes']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  updateScenarioDetails: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['updateScenarioDetails']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['coachingScenariosEntity']
        }
      }
    }
  }
  updateScenarioAtGoalStep: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['updateScenarioAtGoalStep']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['coachingScenariosEntity']
        }
      }
    }
  }
  selectPersona: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': {
          personaExtId: string
        }
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  getPersonas: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['availablePersonas']
        }
      }
    }
  }
  saveStageGoals: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['saveStageGoals']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  startVideoGenerations: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  selectTrainee: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': {
          traineeExtId: string
        }
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  getTrainees: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['availableTrainees']
        }
      }
    }
  }
  updateVideoGenerationData: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['updateVideoDataRequest']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  generateScenarioContentForAiSupportedScenario: {
    parameters: {
      query?: never
      header?: never
      path: {
        programExtId: string
        scenarioExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['generateScenarioContentRequestAiSupported']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['generateScenarioContentResponseAiSupported']
        }
      }
    }
  }
  generateInstructions: {
    parameters: {
      query?: never
      header?: never
      path: {
        programExtId: string
        scenarioExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': {
          userActivitySessionExtId: string
        }
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['instructionEntity'][][]
        }
      }
    }
  }
  generateScenarioContentForRealtimeScenario: {
    parameters: {
      query?: never
      header?: never
      path: {
        programExtId: string
        scenarioExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['generateScenarioContentRequestRealtime']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['generateScenarioContentResponseRealtime']
        }
      }
    }
  }
  createInstruction: {
    parameters: {
      query?: never
      header?: never
      path: {
        programExtId: string
        scenarioExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['createInstruction']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['instructionEntity']
        }
      }
    }
  }
  updateInstruction: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        scenarioExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['updateInstruction']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['instructionEntity']
        }
      }
    }
  }
  deleteInstruction: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        scenarioExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  updateAiQaStep: {
    parameters: {
      query?: never
      header?: never
      path: {
        id: string
        programExtId: string
        scenarioExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['updateQAStepSchema']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['stepEntity']
        }
      }
    }
  }
  generateStepContent: {
    parameters: {
      query?: never
      header?: never
      path: {
        id: string
        programExtId: string
        scenarioExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['generateStepContentRequest']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['generateStepContentResponse']
        }
      }
    }
  }
  createAiQaStep: {
    parameters: {
      query?: never
      header?: never
      path: {
        programExtId: string
        scenarioExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['createQAStepSchema']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['stepEntity']
        }
      }
    }
  }
  createWinningBehaviorRealtime: {
    parameters: {
      query?: never
      header?: never
      path: {
        programExtId: string
        scenarioExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['createWinningBehaviorRealtime']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['winningBehaviorRealtimeEntity']
        }
      }
    }
  }
  updateWinningBehaviorRealtime: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        scenarioExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['updateWinningBehaviorRealtime']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['winningBehaviorRealtimeEntity']
        }
      }
    }
  }
  deleteWinningBehaviorRealtime: {
    parameters: {
      query?: never
      header?: never
      path: {
        extId: string
        programExtId: string
        scenarioExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  reorderScenarios: {
    parameters: {
      query?: never
      header?: never
      path: {
        programExtId: string
        trainingExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['reorderScenarios']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  reorderTrainings: {
    parameters: {
      query?: never
      header?: never
      path: {
        programExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['reorderTrainings']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  getSharedPrograms: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': (components['schemas']['sharedProgramEntity'] & {
            senderEmail: string
          })[]
        }
      }
    }
  }
  acceptSharedProgram: {
    parameters: {
      query?: never
      header?: never
      path: {
        sharedExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['sharedProgramEntity']
        }
      }
    }
  }
  rejectSharedProgram: {
    parameters: {
      query?: never
      header?: never
      path: {
        sharedExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['sharedProgramEntity']
        }
      }
    }
  }
  getSkills: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['skillEntity'][]
        }
      }
    }
  }
  createTrainee: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['createOrUpdateTrainee'] & {
          language: components['schemas']['languageEnum']
        }
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['traineeEntity']
        }
      }
    }
  }
  updateTrainee: {
    parameters: {
      query?: never
      header?: never
      path: {
        traineeExtId: string
      }
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': components['schemas']['createOrUpdateTrainee']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['traineeEntity']
        }
      }
    }
  }
  deleteTrainee: {
    parameters: {
      query?: never
      header?: never
      path: {
        traineeExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  duplicateTrainee: {
    parameters: {
      query?: never
      header?: never
      path: {
        traineeExtId: string
      }
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['traineeEntity']
        }
      }
    }
  }
  getWinningBehaviorTemplates: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': components['schemas']['winningBehaviorsEntity'][]
        }
      }
    }
  }
  registerAnalysisResults: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: {
      content: {
        'application/json': components['schemas']['AiAnalysisResultsEndpoint']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  createOrUpdateBenchmarkProfileResults: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: {
      content: {
        'application/json': components['schemas']['AiBenchmarkProfileResultsEndpoint']
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  getUserInfo: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  createUserActivitySession: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content: {
          'application/json': {
            createdAt: string
            extId: string
          }
        }
      }
    }
  }
  updateActiveSession: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': {
          aiHeraAllocationSessionId: string
          aiLastStateId?: string
          /** @enum {string} */
          state?: 'SUSPENDED'
          userExtId: string
        }
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  cleanUpInactiveSessions: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody?: never
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
  createActiveSession: {
    parameters: {
      query?: never
      header?: never
      path?: never
      cookie?: never
    }
    requestBody: {
      content: {
        'application/json': {
          aiHeraAllocationSessionId: string
          aiMissionId: string
          aiSessionId: string
          aiStateId: string
          userExtId: string
        }
      }
    }
    responses: {
      /** @description OK */
      200: {
        headers: {
          [name: string]: unknown
        }
        content?: never
      }
    }
  }
}

export type ApiSchemas = components['schemas']
export type ApiPaths = paths
export type ApiOperations = operations

export const operationIdToPathMap = {
  delete: {
    deleteExercise: '/admin/exercises/:extId',
    deleteInstruction:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/instructions/:extId',
    deletePersona: '/admin/personas/:personaExtId',
    deleteProgram: '/admin/programs/:extId',
    deleteScenarioByExtId:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId',
    deleteSharedProgram: '/admin/programs/:extId/share/:sharedExtId',
    deleteTrainee: '/admin/trainees/:traineeExtId',
    deleteTraining: '/admin/programs/:programExtId/trainings/:extId',
    deleteWinningBehaviorRealtime:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/winning_behaviors_realtime/:extId',
  },
  get: {
    cleanUpInactiveSessions: '/user_activity/clean_up',
    getAllPrograms: '/admin/programs',
    getAllProgramTemplates: '/admin/program_templates',
    getAvatarsAndVoices: '/admin/personas/avatarsAndVoices',
    getExercises: '/admin/exercises',
    getPersonas:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/personas',
    getProgramByExtId: '/admin/programs/:extId',
    getScenarioByExtId:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId',
    getScenarios: '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios',
    getSharedPrograms: '/admin/programs/sharedPrograms',
    getSkills: '/admin/skills',
    getTrainees:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/trainees',
    getTrainingByExtId: '/admin/programs/:programExtId/trainings/:extId',
    getTrainings: '/admin/programs/:programExtId/trainings',
    getWinningBehaviorTemplates: '/admin/winning_behaviors/templates',
  },
  post: {
    cloneProgram: '/admin/programs/:extId/clone',
    createActiveSession: '/user_activity/create_active_session',
    createAiQaStep:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/steps/aiQaStep',
    createExercise: '/admin/exercises',
    createFeedbackForRecording: '/:extId/feedbacks',
    createInstruction:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/instructions',
    createOrUpdateBenchmarkProfileResults: '/ai/benchmark_profile_results',
    createPersona: '/admin/personas',
    createProgram: '/admin/programs',
    createProgramUsingTemplate: '/admin/program_templates/:extId',
    createScenario: '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios',
    createTrainee: '/admin/trainees',
    createTraining: '/admin/programs/:programExtId/trainings',
    createUserActivitySession: '/user_activity_sessions',
    createWinningBehaviorRealtime:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/winning_behaviors_realtime',
    generateInstructions:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/data_generations/realtime/instructions',
    generateScenarioContentForAiSupportedScenario:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/data_generations/ai_supported/scenario_content',
    generateScenarioContentForRealtimeScenario:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/data_generations/realtime/scenario_content',
    generateStepContent:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/steps/:id/generateContent',
    getUserInfo: '/general/user_info',
    registerAnalysisResults: '/ai/analysis_results',
    saveBehavioralAttitudes:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/behavioral_attitudes',
    saveStageGoals:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/stage_goals',
    shareProgram: '/admin/programs/:extId/share',
    startVideoGenerations:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/startVideoGenerations',
  },
  put: {
    acceptSharedProgram: '/admin/programs/sharedPrograms/:sharedExtId/accept',
    duplicatePersona: '/admin/personas/:personaExtId/duplicate',
    duplicateTrainee: '/admin/trainees/:traineeExtId/duplicate',
    publishProgram: '/admin/programs/:extId/publish',
    rejectSharedProgram: '/admin/programs/sharedPrograms/:sharedExtId/reject',
    reorderScenarios:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/order',
    reorderTrainings: '/admin/programs/:programExtId/trainings/order',
    selectPersona:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/persona',
    selectTrainee:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/trainee',
    unpublishProgram: '/admin/programs/:extId/unpublish',
    updateActiveSession: '/user_activity/active_session',
    updateAiQaStep:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/steps/:id/aiQaStep',
    updateConnectedProgramsOfExercise: '/admin/exercises/:extId/connect',
    updateExercise: '/admin/exercises/:extId',
    updateInstruction:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/instructions/:extId',
    updatePersona: '/admin/personas/:personaExtId',
    updateProgram: '/admin/programs/:extId',
    updateScenarioAtGoalStep:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/goal',
    updateScenarioDetails:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/details',
    updateTrainee: '/admin/trainees/:traineeExtId',
    updateTraining: '/admin/programs/:programExtId/trainings/:extId',
    updateVideoGenerationData:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:extId/updateVideoGenerationData',
    updateWinningBehaviorRealtime:
      '/admin/programs/:programExtId/trainings/:trainingExtId/scenarios/:scenarioExtId/winning_behaviors_realtime/:extId',
  },
} as const
