import {
  Box,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { shuffle } from 'lodash-es'
import { memo, type ReactNode, useMemo } from 'react'
import { VscCircleFilled } from 'react-icons/vsc'

import {
  get3SegmentDescription,
  get5SegmentDescription,
  type Scores,
  type SegmentEnum,
  type Values,
} from '../../hooks/use-result'

type Big5Dimensions =
  | 'agreeableness'
  | 'conscientiousness'
  | 'extraversion'
  | 'neuroticism'
  | 'openness'

type TagDimensions =
  | 'tfDealing'
  | 'tfIdentity'
  | 'tfVariety'
  | 'tfAutonomy'
  | 'tfSocialRelations'
  | 'cfCompetitive'
  | 'cfInnovative'
  | 'cfDetailOriented'
  | 'cfOutcomeOriented'
  | 'cfTeamOriented'
  | 'cfSupportive'
  | 'cfStability'

const BIG_5_DIMENSIONS = [
  'extraversion',
  'conscientiousness',
  'agreeableness',
  'openness',
  'neuroticism',
]

const getRandomRecommendation = (
  dimension: TagDimensions | Big5Dimensions,
  segment: SegmentEnum,
  intl: ReturnType<typeof useIntl>,
  recommendations: Array<string> = [],
  counter = 1
): string => {
  const { formatMessage } = intl
  const recommendation = formatMessage({
    id: `recording.details.interviewRecommendations.${dimension}.${segment}.${counter}` as I18nKey,
  })

  // if recommendation starts with 'recording.', it means that all
  // available recommendations where already fetched
  if (!recommendation.startsWith('recording.')) {
    return getRandomRecommendation(
      dimension,
      segment,
      intl,
      [...recommendations, recommendation],
      counter + 1
    )
  }

  const [randomMessage = 'No recommendation found'] = shuffle(recommendations)

  return randomMessage
}

type RecommendationsContainerProps = {
  category: 'cf' | 'tf' | 'big5'
  scores: Scores
  rawValues: Values
  heading: ReactNode
}

const RecommendationsContainer = ({
  category,
  heading,
  scores,
  rawValues,
}: RecommendationsContainerProps) => {
  const intl = useIntl()
  const categoryDimensions =
    category === 'big5'
      ? scores.filter(({ id }) => BIG_5_DIMENSIONS.includes(id))
      : scores.filter(({ id }) => id.startsWith(category))

  const bottom3 = useMemo(
    () =>
      categoryDimensions
        .slice(-3)
        .map(({ id }) => {
          const typedId = id as TagDimensions | Big5Dimensions
          const segment =
            category === 'big5'
              ? get5SegmentDescription(rawValues[id] as number)
              : get3SegmentDescription(rawValues[id] as number)

          return {
            id: typedId,
            value: rawValues[id],
            text: getRandomRecommendation(typedId, segment, intl),
          }
        })
        .filter(Boolean),
    [category, categoryDimensions, rawValues, intl]
  )

  return (
    <Box mb={3}>
      <Heading color="primary-dark" fontSize="xl" fontWeight="semibold" pb={3}>
        {heading}
      </Heading>
      <List spacing={4}>
        {bottom3.length ? (
          bottom3.map(({ id, text }) => (
            <ListItem key={id}>
              <Flex>
                <ListIcon as={VscCircleFilled} color="primary" mt={1} />
                <Box>
                  <Text>{text}</Text>
                </Box>
              </Flex>
            </ListItem>
          ))
        ) : (
          <FormattedMessage id="recording.details.interviewRecommendations.noScores" />
        )}
      </List>
    </Box>
  )
}

type InterviewRecommendationsProps = {
  scores: Scores
  rawValues: Values
}

export const InterviewRecommendations = memo(
  ({ scores, rawValues }: InterviewRecommendationsProps) => (
    <Box mb={10}>
      <Box py={5}>
        <Heading mb={6}>
          <FormattedMessage id="recording.details.interviewRecommendations.heading" />
        </Heading>

        <Text>
          <FormattedMessage id="recording.details.interviewRecommendations.description" />
        </Text>
        <Text mt={4}>
          <FormattedMessage id="recording.details.interviewRecommendations.tip" />
        </Text>
      </Box>
      <Stack pt={6} spacing={12}>
        <RecommendationsContainer
          category="big5"
          scores={scores}
          rawValues={rawValues}
          heading={<FormattedMessage id="common.personality" />}
        />

        <RecommendationsContainer
          category="tf"
          scores={scores}
          rawValues={rawValues}
          heading={
            <FormattedMessage id="recording.details.interviewRecommendations.taskFit.heading" />
          }
        />

        <RecommendationsContainer
          category="cf"
          scores={scores}
          rawValues={rawValues}
          heading={
            <FormattedMessage id="recording.details.interviewRecommendations.cultureFit.heading" />
          }
        />
      </Stack>
    </Box>
  )
)
