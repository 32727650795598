import { Box, Flex } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'

const LegendItem = ({ color, label }: { color: string; label: string }) => (
  <Flex alignItems="center" gap={2} fontSize="xs">
    <Box minW="4" minH="4" borderRadius="50%" bg={color} />
    {label}
  </Flex>
)

export const AnswerAccuracyGraphLegend = () => {
  const { formatMessage } = useIntl()

  return (
    <Flex gap={4}>
      <LegendItem
        label={formatMessage({ id: 'dashboard.knowledgeCheck.answers.allCorrect' })}
        color={colors.green[500]}
      />
      <LegendItem
        label={formatMessage({ id: 'dashboard.knowledgeCheck.answers.partlyCorrect' })}
        color={colors.yellow[500]}
      />
      <LegendItem
        label={formatMessage({ id: 'dashboard.knowledgeCheck.answers.allWrong' })}
        color={colors.red[500]}
      />
    </Flex>
  )
}
