import { combineReducers } from 'redux'

import authTypes from './auth/types'
import entities from './entities'
import errors from './errors'
import ui from './ui'

const rootReducer = combineReducers({
  entities,
  errors,
  ui,
})

// eslint-disable-next-line import/no-default-export
export default (state, action) => {
  // Reset store on logout
  if (action.type === authTypes.ON_LOGOUT) {
    return rootReducer(undefined, action)
  }

  return rootReducer(state, action)
}
