import { chakra, type HTMLChakraProps } from '@chakra-ui/react'

export const Header = ({ children, ...rest }: HTMLChakraProps<'header'>) => (
  <chakra.header
    display="flex"
    alignItems="center"
    height="3.25rem"
    padding="0 0.75rem"
    {...rest}
  >
    {children}
  </chakra.header>
)
