import { isDevelopment } from '@repo/utils'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useAuth } from '../authentication/auth-context'
import { FirebaseEmailActionHandler } from '../authentication/firebase-email-action-handler'
import { Login } from '../authentication/login'
import { RequestPassword } from '../authentication/request-password'
import { ResendEmail } from '../authentication/resend-email'
import { Signup } from '../authentication/signup'
import { VerifyEmail } from '../authentication/verify-email'
import { ExerciseList } from '../coaching/exercise-list/exercise-list'
import { ImportExercises } from '../coaching/exercise-list/import-exercises'
import { CreateProgramFromTemplate } from '../coaching/program-template-library/create-program-from-template'
import { BenchmarkPage } from '../coaching-benchmark-profiles/components/benchmark-page'
import { TemplateSection } from '../coaching-benchmark-profiles/components/template-section'
import { CohortDetails } from '../coaching-participants/components/cohort-details'
import { ParticipantsPage } from '../coaching-participants/components/participants-page'
import { ComponentBookPage } from '../components/component-book-page'
import { OrganizationTeam } from '../components/organization-team'
import { RouteNotFoundError } from '../components/route-not-found-error'
import { DashboardPage } from '../dashboard/dashboard-page'
import { useRetorioIntercom } from '../hooks/use-retorio-intercom'
import { AppLayout } from '../layout/app-layout'
import { AuthLayout } from '../layout/auth-layout'
import { PublicLayout } from '../layout/public-layout'
import { ProgramDetailPage } from '../new-program-builder/program-detail-page'
import { ProgramsPage } from '../new-program-builder/programs-page'
import { ScenarioManualBuilderPage } from '../new-program-builder/scenario-manual-builder-page'
import { ScenarioSharedBuilderLayout } from '../new-program-builder/scenario-shared-builder-layout'
import { BuilderIntro } from '../new-program-builder/shared/builder-intro'
import { BuilderStepRenderer } from '../new-program-builder/shared/builder-step-renderer'
import { RedirectToBuilderResumeStep } from '../new-program-builder/shared/redirect-to-builder-resume-step'
import { TrainingDetailPage } from '../new-program-builder/training-detail-page'
import { RecordingDetailsCandidateWithTargetProfile } from '../recruiting/recording-details/recording-details-candidate-with-target-profile'
import { RecordingDetailsWithTargetProfile } from '../recruiting/recording-details/recording-details-with-target-profile'
import { RecordingDetailsWithoutTargetProfile } from '../recruiting/recording-details/recording-details-without-target-profile'
import { selectOrganizationHasCoaching } from '../store/entities/organization/selectors'
import { BenchmarkProfileEditor } from './benchmark-profile-editor/benchmark-profile-editor'
import { BenchmarkProfileList } from './benchmark-profile-list'
import { QuestionnaireEditor } from './questionnaire-editor/questionnaire-editor'
import { QuestionnaireList } from './questionnaire-editor/questionnaire-list'
import { RecordingListWithFolderNavigation } from './recording-list/recording-list-with-folder-navigation'

export const App = () => {
  useRetorioIntercom()

  const { user } = useAuth()
  const hasCoaching = useSelector(selectOrganizationHasCoaching)

  return (
    <Routes>
      {/* Auth routes */}
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/account/:invitationId" element={<Signup />} />
        <Route path="/email-handler" element={<FirebaseEmailActionHandler />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/resend-email" element={<ResendEmail />} />
        <Route path="/request-password" element={<RequestPassword />} />
      </Route>

      {/* Public routes */}
      <Route element={<PublicLayout />}>
        <Route path="/recordings/:id" element={<RecordingDetailsWithTargetProfile />} />
        <Route
          path="/share/recordings/:id"
          element={<RecordingDetailsWithoutTargetProfile />}
        />
        <Route
          path="/candidate/recordings/:id"
          element={<RecordingDetailsCandidateWithTargetProfile />}
        />
      </Route>

      {/* Routes for all logged in users */}
      <Route element={<AppLayout guard="loggedIn" />}>
        <Route
          path="/"
          element={<Navigate to={hasCoaching ? '/training_programs' : '/folders'} />}
        />

        <Route path="/company/team" element={<OrganizationTeam />} />
      </Route>

      {/* Coaching routes */}
      <Route element={<AppLayout guard="coachingEnabled" />}>
        <Route path="/training_programs">
          <Route index element={<ProgramsPage />} />
          <Route path=":programExtId">
            <Route index element={<ProgramDetailPage />} />
            <Route path="trainings/:trainingExtId">
              <Route index element={<TrainingDetailPage />} />

              <Route
                path="scenarios/:scenarioExtId/manual"
                element={<ScenarioManualBuilderPage />}
              />

              <Route
                path="scenarios/:scenarioExtId/realtime"
                element={<ScenarioSharedBuilderLayout builderType="realtime" />}
              >
                <Route
                  index
                  element={<RedirectToBuilderResumeStep builderType="realtime" />}
                />
                <Route
                  path="*"
                  element={<RedirectToBuilderResumeStep builderType="realtime" />}
                />
                <Route path="intro" element={<BuilderIntro />} />
                <Route
                  path=":stepKey"
                  element={<BuilderStepRenderer builderType="realtime" />}
                />
              </Route>

              <Route
                path="scenarios/:scenarioExtId/scripted"
                element={<ScenarioSharedBuilderLayout builderType="ai" />}
              >
                <Route index element={<RedirectToBuilderResumeStep builderType="ai" />} />
                <Route
                  path="*"
                  element={<RedirectToBuilderResumeStep builderType="ai" />}
                />
                <Route path="intro" element={<BuilderIntro />} />
                <Route
                  path=":stepKey"
                  element={<BuilderStepRenderer builderType="ai" />}
                />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="/share/program/:sharedProgramId" element={<ProgramsPage />} />

        <Route path="/best_practices" element={<CreateProgramFromTemplate />} />

        <Route path="/participants" element={<ParticipantsPage />} />
        <Route path="/participants/:tab" element={<ParticipantsPage />} />
        <Route
          path="/participants/cohorts/:id"
          element={<CohortDetails isBenchmarking={false} />}
        />
        <Route path="/exercises" element={<ExerciseList />} />
        <Route path="/exercises/import" element={<ImportExercises />} />

        <Route
          path="/coaching/benchmarking/cohorts/:id"
          element={<CohortDetails isBenchmarking />}
        />
        <Route path="/coaching/benchmarking/create/:wizard" element={<BenchmarkPage />} />
        <Route path="/coaching/benchmarking/" element={<BenchmarkPage />} />
        <Route path="/coaching/benchmarking/:tab" element={<BenchmarkPage />} />
        <Route
          path="/coaching/benchmarking/profiles/templates"
          element={<TemplateSection />}
        />

        <Route path="/coaching/analytics/*" element={<DashboardPage />} />
      </Route>

      {/* Recruiting routes */}
      <Route element={<AppLayout guard="recruitingEnabled" />}>
        <Route path="/folders/*" element={<RecordingListWithFolderNavigation />} />
        <Route path="/questionnaires/:id" element={<QuestionnaireEditor />} />
        <Route path="/questionnaires" element={<QuestionnaireList />} />
        <Route path="/target_profiles" element={<BenchmarkProfileList />} />
        <Route path="/target_profiles/create" element={<BenchmarkProfileEditor />} />
        <Route path="/target_profiles/:id" element={<BenchmarkProfileEditor />} />
      </Route>

      {isDevelopment && <Route path="/components" element={<ComponentBookPage />} />}

      <Route element={user ? <AppLayout guard="loggedIn" /> : undefined}>
        <Route path="*" element={<RouteNotFoundError />} />
      </Route>
    </Routes>
  )
}
