import { Box } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useMemo } from 'react'

import { type CoachingStepFormFields } from '../../../../builder/steps/api'
import { getStepTypeForNewBuilder } from './get-step-type-for-new-builder'
import { QuestionAndAnswerStep } from './step-forms/question-and-answer-step'
import { QuizStep } from './step-forms/quiz-step'
import { TextStep } from './step-forms/text-step'
import { VideoStep } from './step-forms/video-step'

export const StepForms = () => {
  const { values } = useFormikContext<CoachingStepFormFields>()

  const stepType = useMemo(() => getStepTypeForNewBuilder(values), [values])

  return (
    <Box w="full" h="full" minH="500" justifyContent="center" position="relative">
      {stepType === 'coachingStepQA' && <QuestionAndAnswerStep disabled={false} />}

      {stepType === 'video' && <VideoStep disabled={false} />}

      {stepType === 'text' && <TextStep disabled={false} />}

      {stepType === 'coachingStepQuiz' && <QuizStep disabled={false} />}
    </Box>
  )
}
