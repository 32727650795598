import { type PropsWithChildren } from 'react'

import { ContentEditorManagerContext } from './content-editor-manager-context'
import { useContentEditorManagerHook } from './use-content-editor-manager-hook'

export const ContentEditorManagerProvider = ({ children }: PropsWithChildren) => (
  <ContentEditorManagerContext.Provider value={useContentEditorManagerHook()}>
    {children}
  </ContentEditorManagerContext.Provider>
)
