import { showToast } from '@repo/ui'
import { signOut } from 'firebase/auth'

import { firebaseAuth } from '../../../firebase'
import { api } from '../../../utils/api'
import { wait } from '../../../utils/wait'
import * as actions from './private/actions'

export const fetchOrganization = () => async dispatch => {
  try {
    const organization = await api.organizations.get()

    dispatch(actions.receiveOrganization(organization))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.message === 'unauthenticated') {
      signOut(firebaseAuth)
      showToast({ messageKey: 'general.error.fallback', status: 'error' })
    }
  }
}

export const inviteMember = values => async dispatch => {
  try {
    const invitation = await api.organizations.invitations.create(values)

    dispatch(actions.receiveInvitation(invitation))
  } catch (error) {
    console.error(error)
    throw new Error(`Error! status: ${error}`)
  }
}

export const deleteMemberOrInvitation =
  ({ id, status }) =>
  async dispatch => {
    try {
      if (status === 'member') {
        await wait(api.organizations.members.delete(id))
        dispatch(actions.deleteMember(id))
      } else {
        await wait(api.organizations.invitations.delete(id))
        dispatch(actions.deleteInvitation(id))
      }
    } catch (error) {
      // TODO: error handling

      console.error(error)
    }
  }

export const updateMember = (id, payload) => async dispatch => {
  try {
    await wait(api.organizations.members.update(id, payload))
    dispatch(actions.updateMember(id, payload))
  } catch (error) {
    // TODO: error handling

    console.error(error)
  }
}

export const savePrivacy = values => async dispatch => {
  const { enabled: gdprEnabled, privacyPolicy, ...gdprCompany } = values
  const data = { gdprCompany, gdprEnabled, privacyPolicy }

  try {
    const updated = await api.organizations.update(data)

    dispatch(actions.receiveOrganization(updated))
  } catch (error) {
    console.error(error)
    throw new Error(`Error! status: ${error}`)
  }
}

export const saveGeneralSettings = values => async dispatch => {
  const { companyName, logoUrl } = values
  const data = { companyName, logoUrl }

  try {
    const updated = await api.organizations.updateGeneralSettings(data)

    dispatch(actions.receiveOrganization(updated))
  } catch (error) {
    console.error(error)
    throw new Error(`Error! status: ${error}`)
  }
}

export const saveProgramNotificationsSettings = values => async dispatch => {
  const { isEnabled } = values

  try {
    const updated = await api.organizations.updateProgramNotificationsFlag({ isEnabled })

    dispatch(actions.receiveOrganization(updated))
  } catch (error) {
    console.error(error)
    throw new Error(`Error! status: ${error}`)
  }
}
