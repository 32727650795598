import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { Form, Formik } from 'formik'
import { type SWRInfiniteKeyedMutator } from 'swr/infinite'

import { Gate } from '../../components/gate'
import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { useFolder } from '../../hooks/use-folder'
import { type UseFolderReturn } from '../../hooks/use-folder-types'
import { type RecordingSession } from '../recording-sessions-api'
import { getValidationSchema, onSavingError } from './folder-editor'
import { FolderForm } from './folder-form'

type FormData = {
  name: string
  benchmarkProfileId: string
}

type EditFolderModalProps = {
  id: string
  isOpen: boolean
  onClose: () => void
  updateRecordings: SWRInfiniteKeyedMutator<Array<Array<RecordingSession>>>
}

export const EditFolderModal = ({
  id,
  isOpen,
  onClose,
  updateRecordings,
}: EditFolderModalProps) => {
  const intl = useIntl()
  const useFolderReturn: unknown = useFolder(id)
  const { data, list, ...folder } = useFolderReturn as UseFolderReturn
  const validationSchema = getValidationSchema(intl)

  const handleDelete = async () => {
    try {
      await folder.remove()
      onClose()
    } catch (error) {
      showToast({ messageKey: 'positions.errors.deleting', status: 'error' })
    }
  }

  const handleSave = async (formData: FormData) => {
    try {
      await folder.update(formData)
      // refetch recordings with new results if benchmark profile was changed
      // we need to do this after folder is updated with the new benchmark profile
      if (list.dashboard.benchmark.id !== formData.benchmarkProfileId) {
        updateRecordings()
      }

      onClose()
    } catch (error) {
      onSavingError(error)
    }
  }

  return (
    <Gate>
      <InterceptAndConfirm
        description={<FormattedMessage id="positions.list.delete.message" />}
        onConfirm={handleDelete}
        title={<FormattedMessage id="positions.list.delete.title" />}
      >
        {({ openModal }) => (
          <Modal isOpen={isOpen} isCentered onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <FormattedMessage
                  id="positions.list.edit.title"
                  values={{ title: data.name }}
                />
              </ModalHeader>

              <ModalCloseButton />

              {!list ? (
                <FormattedMessage id="positions.list.edit.error" />
              ) : (
                <Formik
                  initialValues={{
                    benchmarkProfileId: list.dashboard.benchmark.id,
                    name: data.name,
                  }}
                  onSubmit={handleSave}
                  validateOnBlur={false}
                  validationSchema={validationSchema}
                >
                  {formProps => (
                    <Form>
                      <ModalBody>
                        <FolderForm {...formProps} />
                      </ModalBody>

                      <ModalFooter justifyContent="center">
                        <ButtonGroup>
                          <Button colorScheme="red" onClick={openModal}>
                            <FormattedMessage id="common.delete" />
                          </Button>

                          <Button variant="outline" onClick={onClose}>
                            <FormattedMessage id="common.cancel" />
                          </Button>
                          <Button
                            type="submit"
                            isDisabled={formProps.isSubmitting}
                            isLoading={formProps.isSubmitting}
                          >
                            <FormattedMessage id="common.save" />
                          </Button>
                        </ButtonGroup>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              )}
            </ModalContent>
          </Modal>
        )}
      </InterceptAndConfirm>
    </Gate>
  )
}
