import 'react-datepicker/dist/react-datepicker.css'

import { Box, IconButton } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import DatePicker from 'react-datepicker'
import { MdClose } from 'react-icons/md'

type DatePickerProps = {
  value: Date | undefined | null
  onChange: (value) => void
}

export const DateTimePicker = ({ value, onChange }: DatePickerProps) => {
  const { formatMessage } = useIntl()

  return (
    <Box
      display="flex"
      alignItems="center"
      p={2}
      borderRadius="md"
      border="1px solid"
      borderColor="inherit"
      sx={{ 'div > .react-datepicker__header--time': { pt: '20px', pb: '20.5px' } }}
    >
      <DatePicker
        selected={value}
        onChange={onChange}
        showTimeSelect
        dateFormat="dd/MM/yyyy HH:mm"
        timeFormat="HH:mm"
        placeholderText={formatMessage({ id: 'datepicker.placeholder' })}
      />
      <IconButton
        color="gray"
        size="xs"
        variant="ghost"
        aria-label="close"
        icon={<MdClose />}
        onClick={() => onChange(undefined)}
      />
    </Box>
  )
}
