import { Button } from '@chakra-ui/react'
import { type SxProps } from '@mui/material'
import { FormattedMessage } from '@repo/i18n'
import { PageWrapper } from '@repo/ui'
import { assertExists } from '@repo/utils'
import { createColumnHelper } from '@tanstack/react-table'
import { RiAddFill } from 'react-icons/ri'
import { useParams } from 'react-router-dom'

import { AdvancedTable } from '../components/advanced-table'
import { AppHeaderTitle } from '../components/app-header-title'
import { CenteredSpinner } from '../components/centered-spinner'
import { type Training } from '../types/api-types'
import { useOpenapiSWR } from '../utils/use-openapi-swr'
import { TrainingRowActions } from './program-detail-page/training-row-actions'
import { useCreateTrainingModal } from './program-detail-page/use-create-training-modal'

const tableSx: SxProps = { minHeight: '500px' }

type TrainingWithScenariosCount = Training & { scenariosCount: number }

const columnHelper = createColumnHelper<TrainingWithScenariosCount>()
const columns = [
  columnHelper.accessor('title', {
    header: () => <FormattedMessage id="common.name" />,
  }),
  columnHelper.accessor('scenariosCount', {
    header: () => 'Scenarios',
    cell: props => props.getValue() ?? 0,
  }),
  columnHelper.display({
    id: 'actions',
    header: () => '',
    cell: props => <TrainingRowActions training={props.row.original} />,
  }),
]

export const ProgramDetailPage = () => {
  const { programExtId } = useParams()

  assertExists(programExtId, 'programExtId')

  const [modal, showModal] = useCreateTrainingModal(programExtId)

  const { data: trainings } = useOpenapiSWR('getTrainings', { params: { programExtId } })

  const { data: program } = useOpenapiSWR('getProgramByExtId', {
    params: { extId: programExtId },
  })

  if (!program) {
    return <CenteredSpinner />
  }

  const isReadonly = program?.status !== 'unpublished'

  return (
    <PageWrapper
      backLabelKey="program.goBack"
      backTo=".."
      sx={{ overflowY: 'auto' }}
      title={program.title}
      subtitleKey="program.page.subtitle"
      pageActions={
        <Button
          onClick={showModal}
          leftIcon={<RiAddFill size="1.25em" />}
          isDisabled={isReadonly}
        >
          <FormattedMessage id="training.create" />
        </Button>
      }
    >
      {modal}
      <AppHeaderTitle formattedMessageId="coaching.programList.title" />

      <AdvancedTable
        columns={columns}
        data={trainings ?? []}
        sx={tableSx}
        emptyStateLabelKey="training.table.emptyState"
      />
    </PageWrapper>
  )
}
