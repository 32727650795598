import { Box, Button, Grid } from '@chakra-ui/react'
import { type ProgramLanguages } from '@repo/api'
import { FormattedMessage } from '@repo/i18n'
import { concat, difference } from 'lodash-es'
import { useCallback, useState } from 'react'

import { type CoachingRecordingSession } from '../types/coaching-recording'
import { type ProgramTrainingScenariosTree } from '../types/program-training-scenario-tree'
import { ActionButtonContainer } from './action-button-container'
import { BenchmarkSessionCard } from './benchmark-session-card'
import {
  RecordingsTabFilters,
  type RecordingsTabFiltersType,
} from './recordings-tab-filters'
import {
  MIN_REQUIRED_SESSIONS,
  SessionSelectionWarnings,
} from './session-selection-warnings'
import { SecondWizardModal } from './wizard/second-wizard-modal'

export const RecordingsTab = ({
  sessions,
  programTrainingScenarioTree,
}: {
  sessions: Array<CoachingRecordingSession>
  programTrainingScenarioTree: ProgramTrainingScenariosTree
}) => {
  const [selectedSessions, setSelectedSessions] = useState<Array<string>>([])
  const [isOpenSecondWizardModal, setIsOpenSecondWizardModal] = useState<boolean>(false)
  const isSessionSelected = useCallback(
    (sessionExtId: string) => selectedSessions.includes(sessionExtId),
    [selectedSessions]
  )

  const [filteredData, setFilteredData] = useState<Array<CoachingRecordingSession>>([])
  const [filters, setFilters] = useState<RecordingsTabFiltersType>({
    cohortExtIds: [],
    scenarioExtIds: [],
    language: null,
  })

  const handleOnChangeSessionSelection = (sessionExtId: string) => {
    if (isSessionSelected(sessionExtId)) {
      setSelectedSessions(difference(selectedSessions, [sessionExtId]))
    } else {
      setSelectedSessions(concat(selectedSessions, [sessionExtId]))
    }
  }

  return (
    <>
      <ActionButtonContainer>
        <Button
          isDisabled={selectedSessions.length < MIN_REQUIRED_SESSIONS}
          onClick={() => setIsOpenSecondWizardModal(true)}
        >
          <FormattedMessage id="coaching.benchmarking.recordings.select" />
        </Button>
      </ActionButtonContainer>
      <SessionSelectionWarnings sessions={sessions} selectedSessions={selectedSessions} />

      <RecordingsTabFilters
        sessions={sessions}
        selectedSessions={selectedSessions}
        setSelectedSessions={setSelectedSessions}
        setFilteredData={setFilteredData}
        programTrainingScenarioTree={programTrainingScenarioTree}
        filters={filters}
        setFilters={setFilters}
      />
      {!filteredData.length && (
        <Box>
          <FormattedMessage id="coaching.benchmarking.recordings.filters.noSessionsLeft" />
        </Box>
      )}

      <Box height="100%" overflow="auto">
        <Grid templateColumns="repeat(12, 1fr)" gap={6} mb={6}>
          {filteredData.map(session => (
            <BenchmarkSessionCard
              key={session.extId}
              session={session}
              isSelected={isSessionSelected(session.extId)}
              onSelect={() => handleOnChangeSessionSelection(session.extId)}
            />
          ))}
        </Grid>
      </Box>
      {isOpenSecondWizardModal && (
        <SecondWizardModal
          onClose={() => setIsOpenSecondWizardModal(false)}
          sessionExtIds={selectedSessions}
          language={filters.language as ProgramLanguages}
        />
      )}
    </>
  )
}
