import { FormattedMessage } from '@repo/i18n'
import { type FieldBaseProps } from '@repo/ui/src/react-hook-forms/rhf/shared/types'
import {
  Controller,
  type FieldPath,
  type FieldValues,
  type UseFormClearErrors,
  type UseFormSetError,
} from 'react-hook-form'

import { ImageUploader, type ImageUploaderProps } from './image-uploader'

// We cannot move this to @repo/ui because it ImageUploader depends on a lot of components/logic from admin package
export const RHFImageUploader = <T extends FieldValues, TName extends FieldPath<T>>({
  name,
  control,
  rules,
  label,
  labelKey,
  shouldUnregister,
  componentProps,
  setError,
  clearErrors,
}: FieldBaseProps<T, Omit<ImageUploaderProps, 'setValue'>, TName> & {
  setError: UseFormSetError<T>
  clearErrors: UseFormClearErrors<T>
}) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    shouldUnregister={shouldUnregister}
    render={({ field, fieldState }) => (
      <ImageUploader
        value={field.value}
        setValue={field.onChange}
        error={fieldState.error}
        label={labelKey ? <FormattedMessage id={labelKey} /> : label}
        isRequired={!!rules?.required}
        onFormError={error => {
          if (error) {
            setError(name, { message: error })
          } else {
            clearErrors(name)
          }
        }}
        {...componentProps}
      />
    )}
  />
)
