// Detecting browser language
// https://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference
//
// So the basic issue when trying to detect the user language, is that the browser language
// is not necessarily the language the user can read or speak. It's the language the browser
// displayed in.
//
// To get the actual user language we have to get the value set in the regional language
// options in the respective browser. And that is quite a difference if we want to support

import { type LanguageKey } from '../types'
import { AVAILABLE_LANGUAGES } from '../utils'

// IE 11 for example.
export const detectDefaultLanguage = (): LanguageKey => {
  // `navigator.userLanguage` is IE only
  // In Other browsers, the user spoken language is generally the language
  // which is at the top of the `languages` list.
  const userLanguage =
    // eslint-disable-next-line dot-notation
    navigator['userLanguage'] || navigator.languages?.[0] || navigator.language

  if (userLanguage) {
    const userLanguageCode = userLanguage.split('-')[0]
    const availableLanguage = AVAILABLE_LANGUAGES.find(
      ({ value }) => value.split('-')[0] === userLanguageCode
    )

    if (availableLanguage) {
      return availableLanguage.value
    }
  }

  return 'en-US'
}
