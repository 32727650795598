import { Menu, useControllableState } from '@chakra-ui/react'
import { removeAt, updateAt } from '@repo/utils'
import { type PropsWithChildren, useCallback } from 'react'

import { type Sortable } from '../sortable'
import {
  type CheckboxSortableSelectContext,
  CheckboxSortableSelectProvider,
  makeSortableFromChecked,
} from './utils'

type CheckboxSortableSelectProps = {
  defalutValue?: Array<Sortable>
  value?: Array<Sortable>
  onClose?: (v: Array<Sortable>) => void
  onChange?: (v: Array<Sortable>) => void
  isOpen?: boolean
}

export const CheckboxSortableSelect = ({
  children,
  defalutValue: defalutValueProp = [],
  value: valueProp,
  onClose,
  onChange,
  isOpen,
}: PropsWithChildren<CheckboxSortableSelectProps>) => {
  const onChangeInternal = useCallback((v: Array<Sortable>) => onChange?.(v), [onChange])

  const [value, setValue] = useControllableState<Array<Sortable>>({
    defaultValue: defalutValueProp,
    value: valueProp,
    onChange: onChangeInternal,
  })

  const handleItemChange: CheckboxSortableSelectContext['onItemChange'] = useCallback(
    sortableCheckbox => {
      const index = value.findIndex(s => s.value === sortableCheckbox.item)

      if (sortableCheckbox.checked && index < 0) {
        // ADD
        const newItem = makeSortableFromChecked(sortableCheckbox)

        setValue(vv => [...vv, newItem])
      } else if (sortableCheckbox.checked && index >= 0) {
        // UPDATE
        const newItem = makeSortableFromChecked(sortableCheckbox)

        setValue(vv => updateAt(index, newItem, vv))
      } else if (!sortableCheckbox.checked && index >= 0) {
        // REMOVE
        setValue(vv => removeAt(index, vv))
      }
    },
    [value, setValue]
  )

  const contextValue: CheckboxSortableSelectContext = {
    onItemChange: handleItemChange,
    sortableValues: value,
  }

  const handleOnClose = () => onClose?.(value)

  return (
    <Menu closeOnSelect={false} isOpen={isOpen} onClose={handleOnClose} isLazy>
      <CheckboxSortableSelectProvider value={contextValue}>
        {children}
      </CheckboxSortableSelectProvider>
    </Menu>
  )
}
