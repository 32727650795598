import {
  Badge,
  Box,
  Center,
  HStack,
  Icon,
  Text,
  type ThemeTypings,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type PropsWithChildren } from 'react'
import { type IconType } from 'react-icons'

export const BehavioralAttitudeItem = ({
  children,
  stageIndex,
  color,
  icon,
  name,
  colorScheme,
}: PropsWithChildren<{
  color: string
  stageIndex?: number
  icon?: IconType
  colorScheme?: ThemeTypings['colorSchemes']
  name?: string
}>) => (
  <HStack
    gap={5}
    alignItems="stretch"
    sx={{
      '&:not(:last-of-type) .step-card': {
        mb: 12,
      },
    }}
    w="full"
  >
    <Center flexDirection="column">
      <Center
        minW="40px"
        minH="40px"
        fontSize={18}
        borderRadius="full"
        border="1px solid"
        color="white"
        bg={color}
      >
        {icon ? (
          <Icon as={icon} boxSize="20px" />
        ) : (
          <Text fontSize="18px" w="20px" textAlign="center">
            {stageIndex}
          </Text>
        )}
      </Center>
      <Box
        borderLeft="4px dashed"
        borderColor={name === 'final' ? 'transparent' : 'gray.100'}
        h="100%"
      />
    </Center>
    <VStack w="full" className="step-card" mt={2} alignItems="flex-start" gap={4}>
      {stageIndex && (
        <Badge
          colorScheme={colorScheme}
          alignSelf="flex-start"
          color={colors.purple[700]}
          minW="76px"
          textAlign="center"
        >
          <FormattedMessage id={`scenario.realtime.builder.stages.${name}` as I18nKey} />
        </Badge>
      )}

      {children}
    </VStack>
  </HStack>
)
