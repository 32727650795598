import {
  Alert,
  AlertDescription,
  Box,
  Center,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { HelpTooltip } from '@repo/ui'
import { isNil } from '@repo/utils'
import { useParams } from 'react-router-dom'

import { CenteredSpinner } from '../../components/centered-spinner'
import { ScoreBar } from '../../components/score-bar'
import { VideoGallery } from '../../components/video-gallery'
import { useResult } from '../../hooks/use-result'
import { useRecordingSession } from '../recording-sessions-api'
import { CandidateMotivation } from './candidate-motivation'
import { CandidatePersonality } from './candidate-personality'
import { CandidatePreferences } from './candidate-preferences'
import { InterviewRecommendations } from './interview-recommendations'
import { Overview } from './overview'
import { getBig5Data } from './recording-details-utils'

export const RecordingDetailsWithTargetProfile = () => {
  const { id } = useParams<{ id: string }>()
  const { data: recordingSession, error } = useRecordingSession(id)
  const result = useResult(recordingSession)
  const big5Data = getBig5Data(result)
  const candidateView = false

  if (error) {
    return (
      <Center>
        <FormattedMessage id="recording.error.noAccess" />
      </Center>
    )
  }

  if (isNil(recordingSession)) {
    return <CenteredSpinner />
  }

  if (!result) {
    return (
      <Center>
        <FormattedMessage id="common.error.unexpected" />
      </Center>
    )
  }

  return (
    <Box p={20} maxW="container.xl" margin="0 auto">
      <Stack
        direction="row"
        w="100%"
        spacing={6}
        bg="white"
        px={20}
        pt={20}
        borderRadius={4}
      >
        <Flex w="50%" align="center" justify="center">
          <VideoGallery videos={recordingSession?.recordings} />
        </Flex>
        <Flex direction="column" w="50%">
          <Heading>
            {recordingSession?.meta?.name ?? (
              <FormattedMessage id="recording.title.anonymous" />
            )}
          </Heading>
          <Alert
            color="black"
            bgColor={result.recommendation.color}
            mt={{ md: 0, lg: 10 }}
            mb={5}
            borderRadius={5}
          >
            <AlertDescription fontSize={18} fontWeight="medium">
              {result.recommendation.text}
            </AlertDescription>
          </Alert>
          <Flex>
            <ScoreBar
              startLabel={
                <Text fontWeight="semibold">
                  <FormattedMessage id="recording.details.scoreBar.low" />
                </Text>
              }
              endLabel={
                <Text fontWeight="semibold">
                  <FormattedMessage id="recording.details.scoreBar.high" />
                </Text>
              }
              value={result.overallScore}
              lowerLimit={result.benchmarkThresholds[0]}
              upperLimit={result.benchmarkThresholds[2]}
            />
            <HelpTooltip
              label={
                <FormattedMessage id="recording.details.overview.overall-relevance.graph.hover" />
              }
            />
          </Flex>
        </Flex>
      </Stack>
      <Stack spacing={20} bg="white" p={20}>
        <Overview
          candidateView={candidateView}
          showTargetProfile
          scores={result.scores}
          big5Data={big5Data}
        />
        <CandidatePersonality
          candidateView={candidateView}
          showTargetProfile
          big5Data={big5Data}
          result={result}
        />
        <CandidatePreferences
          candidateView={candidateView}
          showTargetProfile
          result={result}
        />
        <CandidateMotivation candidateView={candidateView} rawValues={result.rawValues} />
        <InterviewRecommendations scores={result.scores} rawValues={result.rawValues} />
      </Stack>
    </Box>
  )
}
