import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { markdownPreviewOptions } from '@repo/ui'
import MDEditor from '@uiw/react-md-editor'
import { useFormikContext } from 'formik'
import { type PropsWithChildren } from 'react'
import { RiText } from 'react-icons/ri'

import { InputControl } from '../../../../../builder/components/form-controls/input-control'
import { type CoachingStepFormFields } from '../../../../../builder/steps/api'
import { type SubFormContent } from '../../../../../builder/types'
import { StepFormButton } from './step-form-button'

export const TextStep = ({
  disabled,
  children,
}: PropsWithChildren<Partial<SubFormContent>>) => {
  const { touched, errors, setFieldValue, values, setFieldTouched } =
    useFormikContext<CoachingStepFormFields>()

  const { step } = values

  if (step.stepType !== 'coachingStepBasic') {
    throw new Error('Wrong step type')
  }

  const { formatMessage } = useIntl()

  const textFieldTouched = touched.step && 'text' in touched.step && touched.step.text
  const textFieldError = errors.step && 'text' in errors.step && errors.step.text

  return (
    <VStack
      my={4}
      mr={4}
      display="flex"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      align="stretch"
      w="full"
    >
      <Box bgColor="white" mb={5} mx={5} w="full" data-color-mode="light">
        <Box m={4}>
          <HStack>
            <Icon as={RiText} size="48px" />
            <Heading as="h1" size="md">
              <FormattedMessage id="common.text" />
            </Heading>
          </HStack>
          <Text m={2}>
            <FormattedMessage id="step.text.description" />
          </Text>
          <InputControl
            name="step.heading"
            bg="white"
            label="Heading"
            disabled={disabled}
          />
          <FormControl mt={2} isInvalid={!!textFieldError && textFieldTouched}>
            <FormLabel>{formatMessage({ id: 'step.text.content.label' })}</FormLabel>
            <MDEditor
              height={200}
              preview="edit"
              onBlur={() => setFieldTouched('step.text', true)}
              style={{
                border: textFieldError && textFieldTouched ? '1px solid red' : 'initial',
              }}
              commandsFilter={cmd => {
                if (
                  /(live|edit|preview|fullscreen|code|codeBlock|image|checked-list|help)/.test(
                    cmd.name ?? ''
                  )
                ) {
                  return false
                }

                return cmd
              }}
              textareaProps={{
                placeholder: formatMessage({ id: 'step.text.content.placeholder' }),
                name: 'step.text',
                disabled,
              }}
              previewOptions={markdownPreviewOptions}
              onChange={value => setFieldValue('step.text', value)}
              value={step.text ? step.text : undefined}
            />
            <FormErrorMessage>
              {textFieldError && formatMessage({ id: textFieldError as I18nKey })}
            </FormErrorMessage>
          </FormControl>
          {children}
        </Box>
      </Box>
      <StepFormButton />
    </VStack>
  )
}
