import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'
import useSWR from 'swr'

import {
  type AIQuestionAndAnswerStep,
  type CoachingStep,
  type CoachingStepAIQA,
} from '../../../../builder/steps/api'
import { type Scenario } from '../../../../types/api-types'
import { useAIBuilderContext } from '../../shared/ai-builder-context'
import { MAX_QUESTION_COUNT } from './interaction-step-renderer/step-conversation-generation/question-form-utils'
import { type InteractionStepKey } from './ordered-interaction-steps'

type InteractionStepContextType = {
  currentStep: InteractionStepKey
  setCurrentStep: Dispatch<SetStateAction<InteractionStepKey>>
  initialQuestionIndex: number | undefined // this is used to select a question in the questions list when user navigates back to the conversation generation step from the review step via clicking a generation error
  setInitialQuestionIndex: Dispatch<SetStateAction<number | undefined>>
}

const InteractionStepContext = createContext<InteractionStepContextType | null>(null)

const getLastInteractionStepKeyFromScenario = (
  scenario: Scenario,
  aiCompletedQuestions: Array<CoachingStep>,
  stepsWithNotNullVideo: boolean | undefined
): InteractionStepKey => {
  if (!scenario.degreeOfRelationship) {
    return 'conversation-dynamics'
  }

  if (!scenario.goal || !scenario.description) {
    return 'scenario-goal'
  }

  if (aiCompletedQuestions.length < MAX_QUESTION_COUNT) {
    return 'conversation-generation'
  }

  if (scenario.title || stepsWithNotNullVideo) {
    return 'review'
  }

  return 'conversation-customization'
}

export const InteractionStepContextProvider = ({ children }: PropsWithChildren) => {
  const { scenario, programExtId, trainingExtId, scenarioExtId } = useAIBuilderContext()
  const { data: steps } = useSWR<Array<CoachingStep>>(
    `/admin/programs/${programExtId}/trainings/${trainingExtId}/scenarios/${scenarioExtId}/steps`
  )

  const [isStepsLoaded, setIsStepsLoaded] = useState<boolean>(false)
  const [initialQuestionIndex, setInitialQuestionIndex] = useState<number>()

  const aiCompletedQuestions = useMemo<Array<CoachingStepAIQA>>(
    () =>
      (steps?.filter(
        ({ step }) =>
          'type' in step &&
          step.type === 'aiSupported' &&
          step.questionTranscript &&
          step.behavioralGoals &&
          step.dos
      ) as Array<CoachingStepAIQA>) ?? [],
    [steps]
  )

  const stepsWithNotNullVideo = steps?.some(
    step =>
      step.step.stepType === 'coachingStepQA' &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (step.step as any).type === 'aiSupported' &&
      (step.step as AIQuestionAndAnswerStep).questionVideoGenerationData !== null
  )

  const [currentStep, setCurrentStep] = useState<InteractionStepKey>(() =>
    getLastInteractionStepKeyFromScenario(
      scenario,
      aiCompletedQuestions,
      stepsWithNotNullVideo
    )
  )

  const value = useMemo<InteractionStepContextType>(
    () => ({
      currentStep,
      setCurrentStep,
      initialQuestionIndex,
      setInitialQuestionIndex,
    }),
    [currentStep, initialQuestionIndex]
  )

  // This should be executed only if prevSteps initially is undefined and the steps are now loaded
  if (!isStepsLoaded && steps?.length) {
    setIsStepsLoaded(true)
    setCurrentStep(
      getLastInteractionStepKeyFromScenario(
        scenario,
        aiCompletedQuestions,
        stepsWithNotNullVideo
      )
    )
  }

  return (
    <InteractionStepContext.Provider value={value}>
      {children}
    </InteractionStepContext.Provider>
  )
}

export const useInteractionStepContext = () => {
  const context = useContext(InteractionStepContext)

  if (!context) {
    throw new Error(
      'useInteractionStepContext must be used within a InteractionStepContextProvider'
    )
  }

  return context
}
