import { Avatar, AvatarGroup, HStack, Text, Tooltip, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import moment from 'moment'

import {
  type ExtendedProgram,
  type SharedProgram,
  type SharedProgramWithSenderEmail,
} from '../../types/api-types'
import { SharedProgramItemStatus } from './shared-program-item-status'

type SharedProgramItemProps = {
  sharedProgram: SharedProgram | SharedProgramWithSenderEmail
  program?: ExtendedProgram
  isReceivedModal: boolean
}

export const SharedProgramItem = ({
  sharedProgram,
  program,
  isReceivedModal,
}: SharedProgramItemProps) => {
  const userEmail =
    isReceivedModal && 'senderEmail' in sharedProgram
      ? sharedProgram.senderEmail
      : sharedProgram.receiverEmail

  const initials = userEmail.slice(0, 2).toUpperCase()

  return (
    <HStack width="full">
      <AvatarGroup size="md" max={2}>
        <Avatar getInitials={() => initials} name={initials} size="md" fontSize="10px" />
      </AvatarGroup>
      <VStack flex="1" alignItems="flex-start" gap={0}>
        <Tooltip label={userEmail} closeOnClick={false}>
          <Text
            fontSize="14px"
            fontWeight={600}
            color={colors.gray[900]}
            noOfLines={1}
            wordBreak="break-all"
          >
            {userEmail}
          </Text>
        </Tooltip>
        <Text fontSize="14px" fontWeight={400} color={colors.gray[700]}>
          <FormattedMessage id="coaching.program.send.modal.tabs.requests.date" />{' '}
          {moment(sharedProgram.createdAt).format('DD/MM/YYYY, HH:mm:ss')}
        </Text>
      </VStack>

      <SharedProgramItemStatus
        sharedProgram={sharedProgram}
        program={program}
        isReceivedModal={isReceivedModal}
      />
    </HStack>
  )
}
