import { BsQuestionCircleFill } from 'react-icons/bs'
import { FiTarget } from 'react-icons/fi'
import { MdWork } from 'react-icons/md'

import { SidebarItem } from './shared/sidebar-item'
import { SidebarSection } from './shared/sidebar-section'

export const SidebarGroupRecruiting = () => (
  <SidebarSection labelKey="sidebar.item.recruiting">
    <SidebarItem to="/folders" labelKey="sidebar.item.job" icon={MdWork} />

    <SidebarItem
      to="/target_profiles"
      labelKey="sidebar.item.benchmarkProfiles"
      icon={FiTarget}
    />
    <SidebarItem
      to="/questionnaires"
      labelKey="sidebar.item.questionnaires"
      icon={BsQuestionCircleFill}
    />
  </SidebarSection>
)
