/* eslint-disable @typescript-eslint/no-explicit-any */
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './reducer'

const middleware = applyMiddleware(thunk)
/* eslint-disable no-underscore-dangle */
const enhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION__
  ? compose(middleware, (window as any).__REDUX_DEVTOOLS_EXTENSION__())
  : middleware
/* eslint-enable */

const store = createStore(rootReducer, enhancer)

// eslint-disable-next-line import/no-default-export
export default store
