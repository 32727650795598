import { Box, Button, Flex, Icon, Spacer, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { useCallback, useState } from 'react'
import { RiAddFill, RiErrorWarningLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom'

import { ContentLoader } from '../../builder/components/content-loader'
import { type CohortProgram } from '../types/cohort-program'
import { useCohortPrograms } from '../use-cohort-programs'
import { useCohorts } from '../use-cohorts'
import { CohortProgramAssignModal } from './cohort-program-assign-modal'
import { CohortProgramEditModal } from './cohort-program-edit-modal'
import { CohortProgramTable } from './cohort-program-table'

export const CohortPrograms = () => {
  const { useCohortProgramsList, deleteCohortProgram, saveCohortProgramsOrder } =
    useCohortPrograms()

  const { id: cohortExtId } = useParams<{ id: string }>() as {
    id: string
  }

  const {
    data,
    status,
    refresh: refreshCohortProgramList,
    mutate,
  } = useCohortProgramsList(cohortExtId)

  const { useCohort } = useCohorts()
  const { refresh: refreshCohortDetails } = useCohort(cohortExtId)
  const [isOpenCohortProgramAssignModal, setIsOpenCohortProgramAssignModal] =
    useState(false)

  const [cohortProgramEditModalState, setCohortProgramEditModalState] = useState<{
    isOpen: boolean
    cohortProgram: null | CohortProgram
  }>({ isOpen: false, cohortProgram: null })

  const onOpenCohortProgramEditModal = (cohortProgramId: string) => {
    const cohortProgram = data?.find(({ extId }) => extId === cohortProgramId)

    if (!cohortProgram) {
      showToast({ messageKey: 'general.error.fallback', status: 'error' })

      return
    }

    setCohortProgramEditModalState({ isOpen: true, cohortProgram })
  }

  const onCloseCohortProgramEditModal = () => {
    setCohortProgramEditModalState({ isOpen: false, cohortProgram: null })
  }

  const swapCohortProgramRows = useCallback(
    async (
      fromCohortProgramExtId: string,
      toCohortProgramExtId: string,
      storeInDatabase?: boolean
    ) => {
      const getRowIndexByExtId = (extId: string) =>
        data?.findIndex(cohortProgram => cohortProgram.extId === extId) ?? -1

      const fromIndex = getRowIndexByExtId(fromCohortProgramExtId)
      const toIndex = getRowIndexByExtId(toCohortProgramExtId)

      if (fromIndex < 0 || toIndex < 0) {
        throw new Error('Order cannot be saved')
      }

      const cohortProgramsReordered = data?.map((_, index) => {
        if (index === fromIndex) {
          return { ...data[toIndex], order: index }
        }

        if (index === toIndex) {
          return { ...data[fromIndex], order: index }
        }

        return data[index]
      }) as Array<CohortProgram>

      try {
        mutate(cohortProgramsReordered, false)

        if (storeInDatabase) {
          await saveCohortProgramsOrder(cohortExtId, cohortProgramsReordered)
        }
      } catch (err) {
        refreshCohortDetails()
      }
    },
    [cohortExtId, data, mutate, saveCohortProgramsOrder, refreshCohortDetails]
  )

  return (
    <>
      <Flex pl={2} pb={4} alignItems="center">
        <Text color="gray.500">
          <FormattedMessage id="cohort.programs.orderInfo" />
        </Text>
        <Spacer />
        <Button
          onClick={() => setIsOpenCohortProgramAssignModal(true)}
          leftIcon={<RiAddFill size="1.25em" />}
          iconSpacing="1"
        >
          <FormattedMessage id="cohort.programs.button.assignProgram" />
        </Button>
      </Flex>
      <ContentLoader status={status}>
        {data && (
          <CohortProgramTable
            data={data}
            onOpenCohortProgramEditModal={onOpenCohortProgramEditModal}
            refreshCohortProgramList={refreshCohortProgramList}
            refreshCohortDetails={refreshCohortDetails}
            deleteCohortProgram={deleteCohortProgram}
            cohortId={cohortExtId}
            swapRows={swapCohortProgramRows}
          />
        )}
      </ContentLoader>
      {status === 'loaded' && !data?.length && (
        <Box display="flex" alignItems="center" maxW="800px" mt={8}>
          <Box h="30px" w="30px" mr={4}>
            <Icon as={RiErrorWarningLine} boxSize="1.25em" />
          </Box>
          <FormattedMessage id="cohort.programs.list.noPrograms" />
        </Box>
      )}
      <CohortProgramAssignModal
        isOpen={isOpenCohortProgramAssignModal}
        onClose={() => setIsOpenCohortProgramAssignModal(false)}
        cohortId={cohortExtId}
      />

      {cohortProgramEditModalState.isOpen && (
        <CohortProgramEditModal
          onClose={onCloseCohortProgramEditModal}
          cohortId={cohortExtId}
          cohortProgram={cohortProgramEditModalState.cohortProgram as CohortProgram}
          refreshProgramList={refreshCohortProgramList}
        />
      )}
    </>
  )
}
