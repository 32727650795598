import { uniq } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'

import { ContentLoader } from '../../builder/components/content-loader'
import { useBenchmarkProfiles } from '../hooks/use-benchmark-profiles'
import { useBenchmarkRecordings } from '../hooks/use-benchmark-recordings'
import { type ProgramTrainingScenariosTree } from '../types/program-training-scenario-tree'
import { RecordingsTab } from './recordings-tab'

export const RecordingsTabDataLoader = () => {
  const { data, status } = useBenchmarkRecordings()
  const memoizedSessions = useMemo(() => data, [data])

  const [programTrainingScenarioTree, setProgramTrainingScenarioTree] =
    useState<ProgramTrainingScenariosTree>([])

  // fetch program scenario tree used in filters
  const { getProgramTrainingScenariosTree } = useBenchmarkProfiles()

  useEffect(() => {
    const fetchTree = async () => {
      const scenarioExtIds = uniq(
        memoizedSessions?.map(session => session.scenario.extId)
      )

      const { scenariosTree: tree } =
        (await getProgramTrainingScenariosTree({
          scenarioExtIds,
        })) || {}

      setProgramTrainingScenarioTree(tree || [])
    }

    if (memoizedSessions?.length && !programTrainingScenarioTree.length) {
      fetchTree()
    }
  }, [programTrainingScenarioTree, getProgramTrainingScenariosTree, memoizedSessions])

  return (
    <ContentLoader status={status}>
      {!!memoizedSessions?.length && !!programTrainingScenarioTree.length && (
        <RecordingsTab
          sessions={memoizedSessions}
          programTrainingScenarioTree={programTrainingScenarioTree}
        />
      )}
    </ContentLoader>
  )
}
