import { Flex, Spacer } from '@chakra-ui/react'
import { type ReactNode } from 'react'

import { Heading } from '../Heading'

type PageHeaderProps = {
  title?: ReactNode
  buttons?: ReactNode
}

export const PageHeader = ({ title, buttons }: PageHeaderProps) => (
  <Flex alignItems="center" minHeight={10} mb={4}>
    <Heading size="md">{title}</Heading>
    <Spacer />
    {buttons}
  </Flex>
)
