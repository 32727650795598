import { AspectRatio, Box, Button, Center, Spinner } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { isNotNil } from '@repo/utils'
import { type PropsWithChildren, useCallback, useEffect } from 'react'

import { type VideoSource } from './types'
import { useVideoManager } from './use-video-manager'
import { VideoPlayer } from './video-player'
import { VideoSlider } from './video-slider'

export type VideoManagerProps = {
  autoPlay?: boolean
  autoPlayNext?: boolean
  sources: Array<VideoSource>
  onProgressUpdate?: (progress: number) => void
  onDividersUpdate?: (dividers: Array<number>) => void
  aspectRatio?: string
}

export const VideoManager = ({
  sources,
  autoPlay,
  autoPlayNext,
  onProgressUpdate,
  onDividersUpdate,
  aspectRatio,
  children,
}: PropsWithChildren<VideoManagerProps>) => {
  const {
    state,
    send,
    videoPlayerRef,
    currentVideoUrl,
    dividers,
    isLoading,
    isReady,
    isPlaying,
    isPaused,
    isEnded,
    videoSize,
  } = useVideoManager(sources, autoPlay, autoPlayNext)

  useEffect(() => {
    if (isNotNil(onProgressUpdate)) {
      onProgressUpdate((state.context.progress * 100) / state.context.duration)
    }
  }, [state.context.progress, onProgressUpdate, state.context.duration])

  useEffect(() => {
    if (isNotNil(onDividersUpdate)) {
      onDividersUpdate(dividers)
    }
  }, [dividers, onDividersUpdate])

  const onPlayHandler = useCallback(() => send({ type: 'ON_PLAY_START' }), [send])
  const onEndHandler = useCallback(() => send({ type: 'ON_VIDEO_END' }), [send])
  const onCanPlayHandler = useCallback(
    () =>
      videoPlayerRef.current &&
      send({ type: 'READY', videoPlayerHandle: videoPlayerRef.current }),
    [videoPlayerRef, send]
  )

  const onTimeUpdateHandler = useCallback(
    (time: number) =>
      send({
        type: 'TIME',
        time,
      }),
    [send]
  )

  return (
    <AspectRatio ratio={videoSize.width / videoSize.height}>
      <Box bg="gray.700">
        {isLoading && (
          <Center w="full" h="full" p="10">
            <Spinner />
          </Center>
        )}
        {state.value === 'error' && (
          <Center w="full" h="full" p="10">
            <FormattedMessage id="videoManager.error" />
            <br />
            <Button onClick={() => send({ type: 'RETRY' })}>
              <FormattedMessage id="videoManager.retry" />
            </Button>
          </Center>
        )}
        {(state.matches({ loading: 'loadingVideo' }) || isReady || isPlaying) && (
          <VideoPlayer
            ref={videoPlayerRef}
            url={currentVideoUrl}
            onPlay={onPlayHandler}
            onEnd={onEndHandler}
            onCanPlay={onCanPlayHandler}
            onTimeUpdate={onTimeUpdateHandler}
            aspectRatio={aspectRatio}
            videoWidth="100%"
          />
        )}

        {(state.matches({ loading: 'loadingVideo' }) || isReady) && (
          <Box position="absolute" bottom="0" left="0" right="0">
            <VideoSlider
              step={1}
              dividers={dividers}
              max={state.context.duration}
              value={state.context.progress}
              onChange={progress => send({ type: 'SEEK', progress })}
            />
          </Box>
        )}
        <Box position="absolute" bottom="10" p="5" left="0">
          {(isPaused || isEnded) && (
            <Button onClick={() => send({ type: 'PLAY' })} isDisabled={isPlaying}>
              <FormattedMessage id="videoManager.play" />
            </Button>
          )}
          {isPlaying && (
            <Button onClick={() => send({ type: 'PAUSE' })} isDisabled={isPaused}>
              <FormattedMessage id="videoManager.pause" />
            </Button>
          )}
          {children}
        </Box>
      </Box>
    </AspectRatio>
  )
}
