import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { Link } from 'react-router-dom'

export const RouteNotFoundError = () => (
  <Flex w="100%" h="100%" justify="center" align="center" flexDir="column" p={20}>
    <Heading as="h6" color="gray.600" margin="3px" size="md">
      <FormattedMessage id="common.error.unexpected" />
    </Heading>

    <Text mt={4} maxW="700px">
      <FormattedMessage id="errors.notfound.description" />
    </Text>

    <Box mt={4}>
      <Button as={Link} to="/" color="white !important">
        <FormattedMessage id="errors.notfound.goHome" />
      </Button>
    </Box>
  </Flex>
)
