import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import { type Dictionary, isNotNil } from '@repo/utils'
import { initial } from 'lodash-es'
import { memo } from 'react'
import { Link } from 'react-router-dom'

import { Interpolated } from '../components/interpolated'
import { useOrganization } from '../hooks/use-organization'
import { type Folder } from '../store/entities/folders/folders-types'

const findRootFolder = (organizationId: string, folders: Dictionary<Folder>) =>
  Object.values(folders)
    .filter(isNotNil)
    .find(f => f.path === organizationId)

const getFoldersByPath = (
  folderPath: string,
  rootFolder: Folder | undefined,
  folders: Dictionary<Folder>,
  showCurrentFolder: boolean
) => {
  const ids = folderPath.split('.')
  const childFolders = ids.reduce((acc, id) => {
    const fld = folders[id]

    return fld ? [...acc, fld] : acc
  }, [] as Array<Folder>)

  const breadcrumbsFolders = rootFolder ? [rootFolder, ...childFolders] : childFolders

  return showCurrentFolder ? breadcrumbsFolders : initial(breadcrumbsFolders)
}

type FolderBreadcrumbItemProps = {
  id: string
  name: string
  appendLastSeparator: boolean
}
const FolderBreadcrumbItem = ({
  id,
  name,
  appendLastSeparator,
}: FolderBreadcrumbItemProps) => (
  <BreadcrumbItem
    separator={appendLastSeparator ? undefined : '>'}
    spacing={2}
    fontSize="sm"
  >
    <BreadcrumbLink as={Link} to={`/folders/${id}`}>
      <Interpolated text={name} />
    </BreadcrumbLink>
  </BreadcrumbItem>
)

type FolderBreadcrumbsProps = {
  folders: Dictionary<Folder>
  folder: Folder
  showCurrentFolder?: boolean
}

export const FolderBreadcrumbs = memo(
  ({ folders, folder, showCurrentFolder = false }: FolderBreadcrumbsProps) => {
    const { organization } = useOrganization()

    if (!organization) {
      return null
    }

    const rootFolder = findRootFolder(organization.id, folders)
    const breadcrumbFolders = getFoldersByPath(
      folder.path,
      rootFolder,
      folders,
      showCurrentFolder
    )

    const breadcrumbItems = breadcrumbFolders.map((f, index) => (
      <FolderBreadcrumbItem
        key={f.id}
        id={f.id}
        name={f.name}
        appendLastSeparator={showCurrentFolder && breadcrumbFolders.length - 1 === index}
      />
    ))

    return <Breadcrumb>{breadcrumbItems}</Breadcrumb>
  }
)
