import { type CheckboxProps, Input, type InputProps, Link } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { useFormikContext } from 'formik'
import { memo, useMemo } from 'react'

import { FormControlCheckboxField } from '../form/form-control-checkbox-field'
import { FormControlInputField } from '../form/form-control-input-field'
import { usePasswordInputControl } from '../hooks/use-password-input-control'

export type SignupFormValue = {
  firstName: string
  lastName: string
  organizationName: string
  email: string
  password: string
  contactConsent: boolean
  legalConsent: boolean
}

type SignupFormProps = {
  hasInvitation: boolean
}

export const SignupForm = memo(({ hasInvitation }: SignupFormProps) => {
  const { formatMessage } = useIntl()
  const { isSubmitting, touched, errors } = useFormikContext<SignupFormValue>()
  const { passwordInputProps, showHideButton } = usePasswordInputControl({
    placeholder: formatMessage({ id: 'signup.form.field.password.placeholder' }),
    isDisabled: isSubmitting,
  })

  const firstNameInputProps = useMemo<InputProps>(
    () => ({
      size: 'md',
      placeholder: formatMessage({
        id: 'signup.form.field.firstName.placeholder',
      }),
      isDisabled: isSubmitting,
      isRequired: true,
      autoFocus: true,
      variant: 'filled',
    }),
    [formatMessage, isSubmitting]
  )

  const lastNameInputProps = useMemo<InputProps>(
    () => ({
      size: 'md',
      placeholder: formatMessage({
        id: 'signup.form.field.lastName.placeholder',
      }),
      isDisabled: isSubmitting,
      isRequired: true,
      variant: 'filled',
    }),
    [formatMessage, isSubmitting]
  )

  const emailInputProps = useMemo<InputProps>(
    () => ({
      size: 'md',
      type: 'email',
      placeholder: formatMessage({ id: 'signup.form.field.email.placeholder' }),
      isDisabled: isSubmitting || hasInvitation,
      isRequired: true,
      variant: 'filled',
    }),
    [formatMessage, isSubmitting, hasInvitation]
  )

  const organizationNameInputProps = useMemo<InputProps>(
    () => ({
      size: 'md',
      placeholder: formatMessage({
        id: 'signup.form.field.organizationName.placeholder',
      }),
      isDisabled: isSubmitting || hasInvitation,
      isRequired: true,
      variant: 'filled',
    }),
    [formatMessage, isSubmitting, hasInvitation]
  )

  const contactConsentInputProps = useMemo(
    (): CheckboxProps => ({
      size: 'md',
      isDisabled: isSubmitting,
    }),
    [isSubmitting]
  )

  const legalConsentInputProps = useMemo(
    (): CheckboxProps => ({
      size: 'md',
      isDisabled: isSubmitting,
      isRequired: true,
    }),
    [isSubmitting]
  )

  return (
    <>
      <FormControlInputField<SignupFormValue>
        errors={errors}
        touched={touched}
        inputType={Input}
        prop="firstName"
        label={formatMessage({ id: 'signup.form.field.firstName.label' })}
        errorMessageKey={errors.firstName}
        inputProps={firstNameInputProps}
      />
      <FormControlInputField<SignupFormValue>
        errors={errors}
        touched={touched}
        prop="lastName"
        inputType={Input}
        label={formatMessage({ id: 'signup.form.field.lastName.label' })}
        errorMessageKey={errors.lastName}
        inputProps={lastNameInputProps}
      />
      <FormControlInputField<SignupFormValue>
        errors={errors}
        touched={touched}
        prop="organizationName"
        inputType={Input}
        label={formatMessage({ id: 'signup.form.field.organizationName.label' })}
        errorMessageKey={errors.organizationName}
        inputProps={organizationNameInputProps}
      />
      <FormControlInputField<SignupFormValue>
        errors={errors}
        inputType={Input}
        touched={touched}
        prop="email"
        label={formatMessage({ id: 'signup.form.field.email.label' })}
        errorMessageKey={errors.email}
        inputProps={emailInputProps}
      />
      <FormControlInputField<SignupFormValue>
        errors={errors}
        inputType={Input}
        touched={touched}
        prop="password"
        label={formatMessage({ id: 'signup.form.field.password.label' })}
        errorMessageKey={errors.password}
        inputProps={passwordInputProps}
      >
        {showHideButton}
      </FormControlInputField>

      <FormControlCheckboxField<SignupFormValue>
        errors={errors}
        touched={touched}
        errorMessageKey={errors.contactConsent}
        prop="contactConsent"
        description={formatMessage({ id: 'signup.form.field.contactConsent.label' })}
        checkboxProps={contactConsentInputProps}
      />
      <FormControlCheckboxField<SignupFormValue>
        errors={errors}
        touched={touched}
        errorMessageKey={errors.legalConsent}
        prop="legalConsent"
        description={
          <FormattedMessage
            id="signup.form.field.legalConsent.label"
            values={{
              tos: (
                <Link
                  href="https://www.retorio.com/tos"
                  isExternal
                  variant="content-primary-dark"
                >
                  <FormattedMessage id="signup.form.field.legalConsent.label.tos" />
                </Link>
              ),
              privacy: (
                <Link
                  href="https://www.retorio.com/privacy-policy"
                  isExternal
                  variant="content-primary-dark"
                >
                  <FormattedMessage id="signup.form.field.legalConsent.label.privacy" />
                </Link>
              ),
            }}
          />
        }
        checkboxProps={legalConsentInputProps}
      />
    </>
  )
})
