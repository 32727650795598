import { Box, Button, Select } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import {
  FormikFormField,
  FormikFormFieldErrorBox,
  FormikFormFieldHeader,
  showToast,
} from '@repo/ui'
import { Form, Formik, type FormikHelpers, useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'
import { object, type SchemaOf, string } from 'yup'

import { inviteMember } from '../../store/entities/organization/effects'

type InviteFormValues = {
  email: string
  role: string
}

const InviteFormInner = () => {
  const { formatMessage } = useIntl()
  const availableRole = ['member', 'owner']
  const { isSubmitting, errors, touched, setFieldValue } =
    useFormikContext<InviteFormValues>()

  return (
    <Form>
      <FormikFormField
        errors={errors.email}
        name="email"
        placeholder={formatMessage({
          id: 'members.form.field.email.placeholder',
        })}
        label={formatMessage({
          id: 'organization.invite.member',
        })}
      />

      <Box mt={4}>
        <FormikFormFieldHeader
          label={formatMessage({
            id: 'organization.invite.member.role',
          })}
        />
        <Select
          size="md"
          name="role"
          onChange={option => setFieldValue('role', option.target.value)}
          defaultValue="member"
        >
          {availableRole.map(selectedRole => (
            <option key={selectedRole} value={selectedRole}>
              {formatMessage({ id: `members.role.${selectedRole}` as I18nKey })}
            </option>
          ))}
        </Select>
        <FormikFormFieldErrorBox touched={touched.role} errors={errors.role} />
      </Box>
      <Button
        mt={4}
        type="submit"
        isLoading={isSubmitting}
        isDisabled={isSubmitting || !!errors.role || !!errors.email}
      >
        <FormattedMessage id="members.form.button.invite" />
      </Button>
    </Form>
  )
}

const defaultValues: InviteFormValues = {
  role: 'member',
  email: '',
}

export const InviteForm = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const handleSubmit = async (
    values: InviteFormValues,
    { setSubmitting, resetForm }: FormikHelpers<InviteFormValues>
  ) => {
    try {
      await dispatch(inviteMember(values))
      showToast({ messageKey: 'members.invite.saving', status: 'success' })
      resetForm()
    } catch (err) {
      showToast({ messageKey: 'members.errors.saving', status: 'error' })
    }

    setSubmitting(false)
  }

  const validationSchema: SchemaOf<InviteFormValues> = object().shape({
    email: string()
      .email(formatMessage({ id: 'organization.settings.general.form.invalid.email' }))
      .required(formatMessage({ id: 'common.validation.required' })),
    role: string().required(),
  })

  return (
    <Formik<InviteFormValues>
      initialValues={{
        ...defaultValues,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      <InviteFormInner />
    </Formik>
  )
}
