import { type ApiPaths, operationIdToPathMap } from '@repo/api'
import qs from 'qs'
import useSWR, { type SWRConfiguration, type SWRResponse } from 'swr'

// Utility to extract only GET operations with defined responses from paths
type GetPaths = {
  [K in keyof ApiPaths]: ApiPaths[K]['get'] extends { responses: { 200: unknown } }
    ? K
    : never
}[keyof ApiPaths]

// Utility to extract response type for a given path
type GetResponseType<T extends keyof ApiPaths> = ApiPaths[T]['get'] extends {
  responses: { 200: { content: { 'application/json': infer R } } }
}
  ? R
  : never

// Utility to extract dynamic path parameters
type ExtractPathParams<T extends string> =
  T extends `${infer _Start}:${infer Param}/${infer Rest}`
    ? Param | ExtractPathParams<`/${Rest}`>
    : T extends `${infer _Start}:${infer Param}`
      ? Param
      : never

// Type for query parameters
type ExtractQueryParams<Path extends GetPaths> =
  ApiPaths[Path]['get']['parameters']['query'] extends infer Q ? Q : never

// Type for the second argument in useOpenapiSWR
type UseOpenapiSWROptions<
  Path extends GetPaths,
  HasPathParams = ExtractPathParams<Path> extends never ? false : true,
  HasQueryParams = ExtractQueryParams<Path> extends undefined ? false : true,
> = HasPathParams extends true
  ? HasQueryParams extends true
    ? {
        // TODO the type of params can be more specific by using the generated openapi types, instead of `string | number` here
        params: Record<ExtractPathParams<Path>, string | number>
        query: ExtractQueryParams<Path>
      }
    : { params: Record<ExtractPathParams<Path>, string | number> }
  : HasQueryParams extends true
    ? { query: ExtractQueryParams<Path> }
    : null

// Replace dynamic segments in path with actual values
const replacePathParams = (
  path: string,
  params: Record<string, string | number> = {}
) => {
  let replacedPath = path

  for (const [key, value] of Object.entries(params)) {
    replacedPath = replacedPath.replace(`:${key}`, encodeURIComponent(String(value)))
  }

  return replacedPath
}

const toQueryString = (query: Record<string, unknown> = {}): string =>
  qs.stringify(query, { addQueryPrefix: true })

type GetOperations = (typeof operationIdToPathMap)['get']

export function useOpenapiSWR<
  TOperation extends keyof GetOperations,
  TPath extends GetOperations[TOperation],
>(
  operationId: TOperation | null,
  options?: UseOpenapiSWROptions<TPath>,
  config?: SWRConfiguration
): SWRResponse<GetResponseType<TPath>> {
  const path = operationId ? operationIdToPathMap.get[operationId] : null

  let finalPath: string | null = path

  if (path && options && 'params' in options) {
    finalPath = replacePathParams(path, options.params)
  }

  if (path && options && 'query' in options && options.query) {
    finalPath += toQueryString(options.query)
  }

  return useSWR(finalPath, config)
}
