import { type Scenario } from '../../../../types/api-types'
import { type BuilderStepKey } from '../../../shared/builder-steps'

export const getResumeStepForRealtimeBuilder = (scenario: Scenario): BuilderStepKey => {
  const allStagesHaveWinningBehaviors = scenario.missionStages?.every(
    stage => stage.winningBehaviorsRealtime?.length
  )

  if (!allStagesHaveWinningBehaviors) {
    return 'goal'
  }

  if (!scenario.persona) {
    return 'conversation-partner'
  }

  if (!scenario.trainee) {
    return 'trainee'
  }

  return 'interaction'
}
