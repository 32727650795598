import {
  chakra,
  type ComponentWithAs,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  type InputProps,
  Textarea,
  type TextareaProps,
} from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { HelpTooltip } from '@repo/ui'
import { Field, type FormikErrors, type FormikTouched } from 'formik'
import { memo, type ReactNode } from 'react'
import { BiErrorCircle } from 'react-icons/bi'

type FormControlInputFieldProps<
  TValue extends { [key: string]: string | boolean | number | Array<string> },
  TProp extends keyof TValue,
> = {
  prop: TProp
  touched?: FormikTouched<TValue>
  errors?: FormikErrors<TValue>
  label: ReactNode
  placeholder?: I18nKey
  tooltipLabel?: I18nKey
  inputType?:
    | ComponentWithAs<'input', InputProps>
    | ComponentWithAs<'textarea', TextareaProps>
  errorMessageKey?: string
  readOnly?: boolean
  inputProps?: InputProps | TextareaProps
  children?: ReactNode
}

const FormControlInputFieldNoMemo = <
  T extends { [key: string]: string | boolean | number | Array<string> },
>({
  prop: p,
  touched,
  errors,
  label,
  tooltipLabel,
  placeholder,
  inputType = Input,
  inputProps,
  errorMessageKey,
  readOnly,
  children,
}: FormControlInputFieldProps<T, keyof T>) => {
  const prop = p as string

  const { formatMessage } = useIntl()

  // IMPORTANT We are not using Formik `useField` hook because it would re-render
  // the whole compnent every time a field in the form changes.
  // That would lead to huge performance issues on slow machines.
  // By using the memo + props the component will re-render only when
  // `touched` and `errors` change.

  return (
    <FormControl id={prop} isInvalid={!!errors?.[prop] && !!touched?.[prop]}>
      <FormLabel htmlFor={prop} fontSize="md" fontWeight="medium">
        {inputProps?.isRequired && '*'}
        {label}
        {tooltipLabel && <HelpTooltip label={formatMessage({ id: tooltipLabel })} />}
      </FormLabel>
      <InputGroup>
        <Field
          name={prop}
          as={inputType}
          placeholder={placeholder && formatMessage({ id: placeholder })}
          isReadOnly={readOnly}
          {...(inputType === Textarea && {
            w: 'full',
            minWidth: '10rem',
            minH: '10rem',
          })}
          {...inputProps}
        />
        {children}
      </InputGroup>
      {errors?.[prop] && errorMessageKey && (
        <FormErrorMessage as={HStack} spacing={1}>
          <BiErrorCircle />
          <chakra.span>{formatMessage({ id: errorMessageKey as I18nKey })}</chakra.span>
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export const FormControlInputField = memo(
  FormControlInputFieldNoMemo
) as typeof FormControlInputFieldNoMemo
