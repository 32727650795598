import useSWR from 'swr'

import { customSwrFetcher } from '../utils/swr-fetcher'

const userNotificationsApiUrl = '/notifications'

export type UserNotificationsType = {
  extId: string
  createdAt: string
  notificationType: string
  messageKey: string
  actionUrl: string | null
  payload: {
    [dynamicKey: string]: string
  }
  isViewed: boolean
}

export const useNotifications = () =>
  useSWR<Array<UserNotificationsType>>(userNotificationsApiUrl)

export const setUserNotificationsHasViewed = async (notificationsExtIds: Array<string>) =>
  customSwrFetcher<UserNotificationsType>(userNotificationsApiUrl, {
    method: 'PATCH',
    body: JSON.stringify(notificationsExtIds),
  })

export const deleteUserNotifications = async (notificationsExtIds: Array<string>) =>
  customSwrFetcher<UserNotificationsType>(userNotificationsApiUrl, {
    method: 'DELETE',
    body: JSON.stringify(notificationsExtIds),
  })
