import { Box, Button, HStack } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { FormikFormField } from '@repo/ui'
import { Form, useFormikContext } from 'formik'

import {
  type BenchmarkProfileFormMode,
  type BenchmarkProfileFormValues,
} from '../types/benchmark-profile-form'

type BenchmarkProfileFormProps = {
  mode: BenchmarkProfileFormMode
  showActionButtons?: boolean
}

export const BenchmarkProfileForm = ({
  mode,
  showActionButtons = true,
}: BenchmarkProfileFormProps) => {
  const { formatMessage } = useIntl()
  const { isValid, dirty, errors, isSubmitting } =
    useFormikContext<BenchmarkProfileFormValues>()

  return (
    <Form>
      <Box pb={4}>
        <FormikFormField
          errors={errors.title && formatMessage({ id: errors.title as I18nKey })}
          label={formatMessage({ id: 'benchmarkProfile.form.name.title' })}
          subLabel={formatMessage({ id: 'benchmarkProfile.form.name.subtitle' })}
          name="title"
          placeholder={formatMessage({
            id: 'common.name',
          })}
        />
      </Box>
      {showActionButtons && (
        <HStack mt={12} spacing={4} justify="center">
          <Button variant="outline" type="reset">
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button isLoading={isSubmitting} type="submit" isDisabled={!(dirty && isValid)}>
            {mode === 'create' && (
              <FormattedMessage id="benchmarkProfile.form.create.submit" />
            )}
            {mode === 'edit' && (
              <FormattedMessage id="benchmarkProfile.form.update.submit" />
            )}
          </Button>
        </HStack>
      )}
    </Form>
  )
}
