import { Box, Button, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { Form, type FormikProps } from 'formik'

import { QuestionsForm } from './questions-form'
import { SettingsForm } from './settings-form'
import { type FormValues } from './types'

export const QuestionnaireEditorForm = (formikProps: FormikProps<FormValues>) => {
  const { isSubmitting, isValid } = formikProps

  return (
    <Form>
      <VStack gap={8} display="flex" alignItems="stretch">
        <SettingsForm />
        <QuestionsForm />
      </VStack>
      <Box mt={4}>
        <Button
          isDisabled={isSubmitting || !isValid}
          isLoading={isSubmitting}
          type="submit"
          float="right"
        >
          <FormattedMessage id="common.save" />
        </Button>
      </Box>
    </Form>
  )
}
