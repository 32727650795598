import { isProduction, isStaging } from '@repo/utils'

export const traineeImageNames = [
  'account-managers_1',
  'account-managers_2',
  'elite-sales_1',
  'elite-sales_2',
  'healthcare_1',
  'healthcare_2',
  'healthcare_3',
  'sales_1',
  'sales_2',
  'sales_3',
  'senior-leaders_1',
  'senior-leaders_2',
  'young-leaders_1',
  'young-leaders_2',
] as const

const imageFolder = (() => {
  if (isProduction) {
    return 'gs://retorio-assets/trainee-images'
  }

  if (isStaging) {
    return 'gs://retorio-assets-staging/trainee-images'
  }

  return 'gs://retorio-assets-dev/trainee-images'
})()

export const traineeImageNameToUrl = (name: string) => `${imageFolder}/${name}.jpg`
