import {
  Box,
  Button,
  type ChakraProps,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { type ReactNode, useState } from 'react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'

interface Page {
  content: ReactNode
  label?: string
}

interface PageNavigatorProps {
  pages: Array<Page>
  wrapperSx?: ChakraProps['sx']
  showLabelAndArrowsAtTop?: boolean
}

const LabelText = ({ label }: { label?: string }) =>
  !label ? null : (
    <Tooltip openDelay={500} label={label}>
      <Text
        w="150px"
        textAlign="center"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        fontWeight="medium"
        fontSize="lg"
      >
        {label}
      </Text>
    </Tooltip>
  )

export const PageNavigator = ({
  pages,
  wrapperSx,
  showLabelAndArrowsAtTop,
}: PageNavigatorProps) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [prevPageLength, setPrevPageLength] = useState(pages.length)

  const currentPage = pages[currentPageIndex]
  const currentLabel = currentPage?.label?.length ? currentPage?.label : undefined

  const dots = pages.map((_, index) => {
    const isCurrentPage = index === currentPageIndex

    return (
      <Button
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        variant="ghost"
        bg="none !important"
        size="xs"
        padding={0}
        onClick={() => setCurrentPageIndex(index)}
      >
        <Box
          w={isCurrentPage ? '10px' : '6px'}
          sx={{ aspectRatio: '1' }}
          borderRadius="50%"
          bg={isCurrentPage ? 'blue.500' : 'blue.200'}
        />
      </Button>
    )
  })

  const prevButton = (
    <IconButton
      aria-label="Previous page"
      isRound
      icon={<Icon as={BiChevronLeft} fontSize={24} boxSize="1.25em" />}
      isDisabled={currentPageIndex === 0}
      onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
    />
  )

  const nextButton = (
    <IconButton
      aria-label="Next page"
      isRound
      icon={<Icon as={BiChevronRight} fontSize={24} boxSize="1.25em" />}
      isDisabled={currentPageIndex === pages.length - 1}
      onClick={() => setCurrentPageIndex(currentPageIndex + 1)}
    />
  )

  // Reset page index if pages length changes to prevent unexpected behavior
  if (prevPageLength !== pages.length) {
    setPrevPageLength(pages.length)
    setCurrentPageIndex(0)
  }

  return (
    <Flex direction="column" gap={4} sx={wrapperSx}>
      {showLabelAndArrowsAtTop && (
        <Flex
          alignItems="center"
          justifyContent="center"
          height="50px"
          gap={4}
          bg="secondary-light"
          borderRadius="lg"
        >
          {prevButton}
          <LabelText label={currentLabel} />
          {nextButton}
        </Flex>
      )}
      <Box>{currentPage?.content}</Box>

      <Flex flexDirection="column" alignItems="center">
        <Flex alignItems="center" gap={4}>
          {!showLabelAndArrowsAtTop && prevButton}
          <Box>{dots}</Box>
          {!showLabelAndArrowsAtTop && nextButton}
        </Flex>

        {!showLabelAndArrowsAtTop && <LabelText label={currentLabel} />}
      </Flex>
    </Flex>
  )
}
