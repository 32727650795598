import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { type ReactNode, useState } from 'react'

type InterceptAndConfirmProps = {
  children?: (obj: { openModal: () => void }) => JSX.Element
  isOpen?: boolean
  onConfirm: () => Promise<void> | void
  onCancel?: () => void
  description: ReactNode
  title: ReactNode
  ctaLabel?: ReactNode
  size?: ModalProps['size']
  isButtonDisabled?: boolean
  isButtonLoading?: boolean
}

/**
 * This component can be used in two ways. Either by passing children that receive the openModal function
 * or by passing the isOpen prop. The latter is useful when you want to control the modal behavior from
 * outside of the children's scope.
 */

export const InterceptAndConfirm = ({
  children,
  isOpen = false,
  description,
  onConfirm,
  onCancel,
  title,
  ctaLabel,
  size,
  isButtonDisabled,
  isButtonLoading,
}: InterceptAndConfirmProps) => {
  const [open, setOpen] = useState(isOpen)
  const onClose = async () => {
    await onConfirm()
    setOpen(false)
  }

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel()
    }

    setOpen(false)
  }

  const { formatMessage } = useIntl()
  const confirmLabel = ctaLabel || formatMessage({ id: 'common.confirm' })

  if (open || isOpen) {
    return (
      <Modal onClose={handleOnCancel} isCentered isOpen size={size}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{description}</ModalBody>
          <ModalFooter justifyContent="center">
            <ButtonGroup>
              <Button variant="outline" onClick={handleOnCancel}>
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button
                colorScheme="red"
                onClick={onClose}
                isDisabled={isButtonDisabled}
                isLoading={isButtonLoading}
              >
                {confirmLabel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  if (children) {
    return children({ openModal: () => setOpen(true) })
  }

  return null
}
