import { Box, Button, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

export const DashboardErrorState = ({ onRetry }: { onRetry: () => void }) => (
  <Box display="flex" w="100%" justifyContent="center">
    <VStack shadow="md" display="flex" bgColor="white" p={4} mt={4}>
      <FormattedMessage id="dashboard.error.fetchError" />
      <Button mt={4} onClick={onRetry}>
        <FormattedMessage id="dashboard.error.fetchError.button" />
      </Button>
    </VStack>
  </Box>
)
