import { Formik, type FormikHelpers } from 'formik'
import { type PropsWithChildren, useCallback } from 'react'
import { type SchemaOf } from 'yup'

type FormikOnSubmitHandler<T> = (values: T, formikHelpers: FormikHelpers<T>) => void

type StepFormEditorProps<T> = {
  onSubmit?: FormikOnSubmitHandler<T>
  initialData: T
  validationSchema: SchemaOf<T>
}

export function StepFormEditor<T>({
  onSubmit: onSubmitHandler,
  initialData,
  children,
  validationSchema,
}: PropsWithChildren<StepFormEditorProps<T>>) {
  const onSubmit: (
    values: T,
    formikHelpers: FormikHelpers<T>
  ) => void | Promise<unknown> = useCallback(
    async (values, formikHelpers) => {
      if (onSubmitHandler) {
        try {
          const savedVal = (await (onSubmitHandler(
            values,
            formikHelpers
          ) as unknown)) as T

          // TODO:-quizzes re implement auto save {
          formikHelpers.setFormikState(state => {
            formikHelpers.resetForm({ values: savedVal })

            return {
              ...state,
            }
          })
        } catch (e) {
          throw new Error('Failed to save')
        }
      }
    },
    [onSubmitHandler]
  )

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Formik<any>
      initialValues={initialData}
      onSubmit={onSubmit}
      validateOnBlur
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  )
}
