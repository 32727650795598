import { WarningIcon } from '@chakra-ui/icons'
import { Box, Flex, Tag, TagCloseButton, TagLeftIcon, Text } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import {
  type FocusEvent,
  forwardRef,
  type KeyboardEvent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { v4 as generateUuid } from 'uuid'

interface MultiTagInputProps {
  onChange: (values: Array<string>, modifiedValue?: string) => void
  errors: Array<string>
  placeholder?: string
  initialValues?: Array<string>
  errorTagColor?: string
}

export interface MultiTagInputHandle {
  resetErrors: () => void
}

export const MultiTagInput = forwardRef<MultiTagInputHandle, MultiTagInputProps>(
  ({ onChange, errors, placeholder, initialValues = [], errorTagColor }, ref) => {
    const [values, setValues] = useState<Array<string>>(initialValues)
    const inputRef = useRef<HTMLInputElement>(null)
    const isEditMode = initialValues.length === 0

    useImperativeHandle(ref, () => ({
      resetErrors: () => {
        const valuesWithoutErrors = values.filter(value => errors.indexOf(value) === -1)

        setValues(valuesWithoutErrors)
        onChange(valuesWithoutErrors)
      },
    }))

    const handleChange = event => {
      const {
        nativeEvent: { inputType },
      } = event

      if (!inputRef.current || inputType !== 'insertFromPaste') {
        return
      }

      const inputValues = inputRef.current.value
        .trim()
        .split(/[ ,]+/)
        .filter(Boolean)
        .map(value => value.toLowerCase())

      const newValues = [...values, ...inputValues]

      setValues(newValues)
      onChange(newValues)
      inputRef.current.value = ''
    }

    const onDelete = (index: number) => {
      const tempValues = [...values]
      const modifiedValue = tempValues[index]

      tempValues.splice(index, 1)
      setValues([...tempValues])
      onChange(tempValues, modifiedValue)
    }

    const addNewValue = (value: string) => {
      const newValues = [...values, value.toLowerCase()]

      setValues(newValues)
      onChange(newValues)

      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (!inputRef.current) {
        return
      }

      const value = inputRef.current.value.trim() ?? ''

      switch (event.key) {
        case ',':
          event.preventDefault()
          if (value?.length > 1) {
            const inputValue = value?.replace(',', '')

            addNewValue(inputValue)
          }

          break
        case ' ':
        case 'Enter':
          event.preventDefault()
          if (value?.length > 1) {
            addNewValue(value)
          }

          break
        case 'Backspace':
          if (values.length > 0 && value?.length === 0) {
            const tempValues = [...values]

            tempValues.pop()
            setValues([...tempValues])
            onChange(tempValues)
          }

          break
        default:
          break
      }
    }

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      const value = event.target.value.trim()

      if (value.length) {
        addNewValue(event.target.value)
      }
    }

    useEffect(() => {
      const filteredValues = values.filter(value => errors?.indexOf(value) === -1)

      setValues([...errors, ...filteredValues])
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors])

    return (
      <Flex
        border="2px"
        borderColor={
          errors.length > 0 && !errorTagColor ? colors.red[500] : colors.blue[500]
        }
        borderRadius="md"
        p={2}
        mb={1}
        h={120}
        overflowY="auto"
        onClick={() => inputRef.current?.focus()}
      >
        <Box w="full">
          {values.map((value, index) => {
            const hasError = index < errors.length

            return hasError ? (
              <Tag
                // TODO: This uuid is generated here to add a unique key to prevent issues with the functionality of the multi tag input.
                // TODO: We need to create an array of objects with the email as value and an (already generated) id to avoid calculating the uuid every time we render a tag.
                key={generateUuid()}
                bg={errorTagColor ?? colors.indicator['red-light']}
                color={colors.gray[700]}
                pt={1}
                mr={1}
                mb={1}
              >
                {!errorTagColor && (
                  <TagLeftIcon
                    fontSize="md"
                    color={colors.red[500]}
                    as={WarningIcon}
                    paddingBottom={1}
                  />
                )}
                <Text mb={1}>{value}</Text>
                <TagCloseButton
                  color={colors.gray[700]}
                  opacity={1}
                  fontSize="sm"
                  pb={1}
                  onClick={() => onDelete(index)}
                />
              </Tag>
            ) : (
              <Tag
                key={generateUuid()}
                bg={colors.blue[100]}
                color={colors.blue[500]}
                pt={1}
                mr={1}
                mb={1}
              >
                <Text mb={1}>{value}</Text>
                <TagCloseButton
                  color={colors.blue[500]}
                  opacity={1}
                  fontSize="sm"
                  pb={1}
                  onClick={() => onDelete(index)}
                />
              </Tag>
            )
          })}
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            style={
              isEditMode
                ? { width: '100%', outline: 'none' }
                : { width: '0', outline: 'none' }
            }
            ref={inputRef}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            placeholder={values.length ? undefined : placeholder}
            onBlur={handleBlur}
            disabled={!isEditMode}
          />
        </Box>
      </Flex>
    )
  }
)
