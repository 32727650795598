/**
 * Retrieves the length of a video file in seconds.
 *
 * @param {File | string} src - The video file.
 * @returns {Promise<number>} A promise that resolves with the video length in seconds.
 */
export const calculateVideoLength = (src: File | string): Promise<number> => {
  const video = document.createElement('video')

  video.src = typeof src === 'string' ? src : URL.createObjectURL(src)
  video.preload = 'metadata'

  return new Promise((resolve, _) => {
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src)
      const { duration } = video

      resolve(duration)
    }
  })
}
