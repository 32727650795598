import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { Formik } from 'formik'

import { benchmarkProfileFormValidator } from '../helpers/benchmark-profiles-form-validator'
import { useBenchmarkProfiles } from '../hooks/use-benchmark-profiles'
import { BenchmarkProfileForm } from './benchmark-profile-form'

type BenchmarkProfileEditModalProps = {
  extId: string
  title: string
  onClose: VoidFunction
}

export const BenchmarkProfileEditModal = ({
  extId,
  title,
  onClose,
}: BenchmarkProfileEditModalProps) => {
  const { useGetAll, update } = useBenchmarkProfiles()
  const { refresh: refreshList } = useGetAll()

  const onSubmit = async formData => {
    try {
      await update(extId, formData)
      refreshList()
      showToast({ messageKey: 'common.alert.created', status: 'success' })
    } catch (err) {
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }

    onClose()
  }

  return (
    <Modal onClose={onClose} isOpen isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="benchmarkProfile.editModal.title" />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody pb={4}>
          <Formik
            onSubmit={onSubmit}
            onReset={onClose}
            initialValues={{ title }}
            validationSchema={benchmarkProfileFormValidator}
            validateOnBlur={false}
          >
            <BenchmarkProfileForm mode="edit" />
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
