import { Box } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import PropTypes from 'prop-types'

import { Node } from './node'

const TreeNode = ({ data, isNodeSelected, children }) => {
  const { children: nodeChildren, ...nodeProps } = data

  const isSelected = isNodeSelected(data.id)

  // broken items are only possible during short phase
  // of deletion process. To prevent visual glitches
  // we don't render broken items
  if (!data.id) {
    return null
  }

  return (
    <Node
      key={nodeProps.id}
      {...nodeProps}
      labelEl={children(nodeProps)}
      isSelected={isSelected}
    >
      {nodeChildren?.map(child => (
        <TreeNode key={child.id} data={child} isNodeSelected={isNodeSelected}>
          {children}
        </TreeNode>
      ))}
    </Node>
  )
}

TreeNode.propTypes = {
  isNodeSelected: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    children: PropTypes.array,
  }),
  children: PropTypes.func.isRequired,
}

TreeNode.defaultProps = {
  isNodeSelected: () => false,
}

const defaultNodeRenderFn = ({ label }) => label

export const AnimatedTree = ({ data, isNodeSelected, children }) => (
  <Box position="relative" color={colors.blue[800]} fill={colors.blue[800]} width="100%">
    <TreeNode data={data} isNodeSelected={isNodeSelected}>
      {children}
    </TreeNode>
  </Box>
)

AnimatedTree.propTypes = {
  isNodeSelected: PropTypes.func,
  data: PropTypes.object.isRequired,
  children: PropTypes.func,
}

AnimatedTree.defaultProps = {
  isNodeSelected: () => false,
  children: defaultNodeRenderFn,
}
