import { type Scenario } from '../../../types/api-types'
import { type BuilderStepKey } from '../../shared/builder-steps'

export const getResumeStepForAIBuilder = (scenario: Scenario): BuilderStepKey => {
  if (!scenario.winningBehavior) {
    return 'goal'
  }

  if (!scenario.persona) {
    return 'conversation-partner'
  }

  if (!scenario.trainee) {
    return 'trainee'
  }

  return 'interaction'
}
