import { Alert, Center } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { type PropsWithChildren } from 'react'
import { MdOutlineTipsAndUpdates } from 'react-icons/md'

export const CustomInfoBox = ({
  children,
  hideIcon,
}: PropsWithChildren<{ hideIcon?: boolean }>) => (
  <Alert status="info" borderRadius={16} color={colors.blue[700]}>
    {!hideIcon && (
      <Center
        color="white"
        borderRadius="full"
        minW="40px"
        aspectRatio="1/1"
        bg={colors.blue[500]}
        mr={4}
        fontSize={20}
      >
        <MdOutlineTipsAndUpdates style={{ marginLeft: 2 }} />
      </Center>
    )}
    {children}
  </Alert>
)
