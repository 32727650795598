import { useIntl } from '@repo/i18n'
import {
  SessionCard,
  SessionCardSelect,
  SessionCardText,
  SessionCardTitle,
} from '@repo/ui'
import { useState } from 'react'

import { formatDateTime } from '../../utils/dates'
import { type CoachingRecordingSession } from '../types/coaching-recording'
import { SessionPlayerModal } from './session-player-modal'

type BenchmarkSessionCardProps = {
  session: CoachingRecordingSession
  isSelected: boolean
  onSelect: VoidFunction
}

export const BenchmarkSessionCard = ({
  session,
  isSelected,
  onSelect,
}: BenchmarkSessionCardProps) => {
  const { formatMessage } = useIntl()

  const [isOpenVideoPlayer, setIsOpenVideoPlayer] = useState(false)
  const { userIdentifier, recordings, cohort, program, training, scenario, createdAt } =
    session

  const fields = [
    cohort.name,
    program.title,
    training.title,
    scenario.title,
    formatDateTime(createdAt),
  ]

  return (
    <SessionCard
      imageUrl={recordings[0]?.previewImageUrl || ''}
      boxShadow={isSelected ? 'lg' : 'none'}
      onClick={() => setIsOpenVideoPlayer(true)}
      colSpan={{ sm: 12, md: 6, lg: 4, xl: 3, '2xl': 3 }}
    >
      <SessionCardTitle
        title={`${formatMessage({ id: 'coaching.benchmarking.recordings.participant' })}: ${userIdentifier}`}
      />
      <SessionCardText fields={fields} />
      <SessionCardSelect isSelected={isSelected} onSelect={onSelect} />
      {isOpenVideoPlayer && (
        <SessionPlayerModal
          onClose={() => setIsOpenVideoPlayer(false)}
          recordingUrls={recordings}
        />
      )}
    </SessionCard>
  )
}
