import { Box, GridItem, Skeleton, Stack } from '@chakra-ui/react'

const ProgramTemplateCardSkeleton = () => (
  <GridItem colSpan={1}>
    <Skeleton h={160} borderTopRadius={8} />

    <Box p={5}>
      <Stack mb={8}>
        <Skeleton h={5} w="70%" />
        <Skeleton h={5} w="40%" />
        <Skeleton h={12} w="full" />
      </Stack>

      <Stack mb={12}>
        <Skeleton h={5} w="40%" />
        <Skeleton h={5} w="70%" />
      </Stack>

      <Skeleton mx="auto" h={6} w="40%" />
    </Box>
  </GridItem>
)

export const SkeletonProgramTemplateCards = ({ count }: { count: number }) =>
  Array.from({ length: count }, (_, i) => <ProgramTemplateCardSkeleton key={i} />)
