import { Button, HStack, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { usePromisingModal } from '@repo/ui'

export const useConfirmBreakingChangeModal = () =>
  usePromisingModal<boolean>(
    ({ resolve }) => (
      <Stack>
        <Text align="center">
          <FormattedMessage id="coaching.program.edit.warning" />
        </Text>
        <Text align="center">
          <FormattedMessage id="coaching.program.edit.warning.proceed" />
        </Text>
        <HStack mt={8} spacing={4} justify="center">
          <Button variant="outline" type="reset" onClick={() => resolve(false)}>
            <FormattedMessage id="coaching.program.edit.warning.goBack" />
          </Button>
          <Button type="submit" onClick={() => resolve(true)}>
            <FormattedMessage id="coaching.program.edit.warning.continue" />
          </Button>
        </HStack>
      </Stack>
    ),
    { titleKey: 'common.warning' }
  )
