import {
  Alert,
  AlertDescription,
  Box,
  Container,
  Flex,
  Heading,
  Spacer,
  Stack,
  Tab as ChakraTab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { HelpTooltip } from '@repo/ui'
import { type Dictionary } from '@repo/utils'
import { type PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Interpolated } from '../../components/interpolated'
import { ScoreBar } from '../../components/score-bar'
import { VideoGallery } from '../../components/video-gallery'
import { useResult } from '../../hooks/use-result'
import { type Folder } from '../../store/entities/folders/folders-types'
import {
  makeSelectFolder,
  selectFolderHash,
} from '../../store/entities/folders/selectors'
import { type SelectFolderFn } from '../../store/entities/folders/selectors-types'
import { formatDateTime } from '../../utils/dates'
import { useRecordingSession } from '../recording-sessions-api'
import { CandidateMotivation } from './candidate-motivation'
import { CandidatePersonality } from './candidate-personality'
import { CandidatePreferences } from './candidate-preferences'
import { InterviewRecommendations } from './interview-recommendations'
import { Overview } from './overview'
import { RecordingDetailsHeader } from './recording-details-header'
import { getBig5Data } from './recording-details-utils'

const selectFolder: SelectFolderFn = makeSelectFolder()

const ALL_RECORDINGS_FOLDER = '${labels:folders.root.name}' // eslint-disable-line no-template-curly-in-string

const Tab = ({ children }: PropsWithChildren) => (
  <ChakraTab color="primary-dark" fontWeight="semibold" _selected={{ bg: 'white' }}>
    {children}
  </ChakraTab>
)

export const RecordingDetails = () => {
  const { folderId, recordingId } = useParams<{ folderId: string; recordingId: string }>()

  const folder: Folder = useSelector(state => selectFolder(state, folderId))
  const allFolders: Dictionary<Folder> = useSelector(selectFolderHash)

  const { data: session } = useRecordingSession(recordingId)
  const result = useResult(session)
  const big5Data = getBig5Data(result)
  const candidateView = false

  return (
    <Flex flexGrow={1} bg="secondary-light" overflowY="auto">
      <Container maxW="container.xl" p={10} pt={0}>
        {recordingId && folder && (
          <RecordingDetailsHeader
            recordingId={recordingId}
            folder={folder}
            folders={allFolders}
          />
        )}
        <Stack flexGrow={1} spacing={10}>
          <Stack
            direction="row"
            w="100%"
            spacing={6}
            bg="white"
            py={8}
            px={20}
            borderRadius={4}
          >
            <Flex w="50%" align="center" justify="center">
              <VideoGallery videos={session?.recordings} />
            </Flex>
            <Flex direction="column" w="50%">
              <Heading mb={{ md: 2, lg: 4 }}>
                {session?.meta?.name ?? (
                  <FormattedMessage id="recording.title.anonymous" />
                )}
              </Heading>
              {folder.name !== ALL_RECORDINGS_FOLDER && (
                <Heading
                  size="md"
                  fontWeight="medium"
                  color="gray.500"
                  mb={{ md: 2, lg: 6 }}
                >
                  <FormattedMessage id="recording.details.isApplyingFor" />{' '}
                  <Text as="span" color="primary">
                    <Interpolated text={folder.name} />
                  </Text>
                  <Spacer />
                  <Text color="gray.500" as="span">
                    <FormattedMessage id="recording.details.onDate" />{' '}
                    {formatDateTime(session?.createdAt)}
                  </Text>
                </Heading>
              )}
              <Alert
                color="black"
                bgColor={result.recommendation.color}
                mb={{ md: 2, lg: 10 }}
                borderRadius={5}
              >
                <AlertDescription fontSize={18} fontWeight="medium">
                  {result.recommendation.text}
                </AlertDescription>
              </Alert>
              <Box pb={2}>
                <Heading size="md" fontWeight="medium">
                  <FormattedMessage id="recording.details.scoreBar.explanation.title" />
                </Heading>
                <Text pt={4} pb={2} fontWeight="semibold" color="gray.500">
                  <FormattedMessage id="recording.details.scoreBar.explanation.subtitle" />
                </Text>
              </Box>

              <Flex>
                <ScoreBar
                  startLabel={
                    <Text fontWeight="semibold">
                      <FormattedMessage id="recording.details.scoreBar.low" />
                    </Text>
                  }
                  endLabel={
                    <Text fontWeight="semibold">
                      <FormattedMessage id="recording.details.scoreBar.high" />
                    </Text>
                  }
                  value={result.overallScore}
                  lowerLimit={result.benchmarkThresholds[0]}
                  upperLimit={result.benchmarkThresholds[2]}
                />
                <HelpTooltip
                  label={
                    <FormattedMessage id="recording.details.overview.overall-relevance.graph.hover" />
                  }
                />
              </Flex>
            </Flex>
          </Stack>
          <Flex flexGrow={1}>
            <Tabs w="100%" isFitted bg="white" borderRadius={4}>
              <TabList bg="secondary" borderRadius="0.3rem 0.3rem 0 0">
                <Tab>
                  <FormattedMessage id="recording.details.navigation.overview" />
                </Tab>
                <Tab>
                  <FormattedMessage id="recording.details.navigation.details" />
                </Tab>
                <Tab>
                  <FormattedMessage id="recording.details.navigation.recommendations" />
                </Tab>
              </TabList>
              <TabPanels px={5} pb={10}>
                <TabPanel>
                  <Overview
                    candidateView={candidateView}
                    showTargetProfile
                    scores={result.scores}
                    big5Data={big5Data}
                  />
                </TabPanel>
                <TabPanel>
                  <Stack spacing={10}>
                    <CandidatePersonality
                      candidateView={candidateView}
                      showTargetProfile
                      result={result}
                      big5Data={big5Data}
                    />
                    <CandidatePreferences
                      candidateView={candidateView}
                      showTargetProfile
                      result={result}
                    />
                    <CandidateMotivation
                      candidateView={candidateView}
                      rawValues={result.rawValues}
                    />
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <InterviewRecommendations
                    scores={result.scores}
                    rawValues={result.rawValues}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Stack>
        <Flex h={12} />
      </Container>
    </Flex>
  )
}
