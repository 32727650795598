import {
  Box,
  type BoxProps,
  HStack,
  Switch,
  Text,
  useCheckbox,
  type UseCheckboxProps,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

interface ToggleListSwitchProps {
  labelKey: I18nKey
  color: string
  useCheckboxProps: UseCheckboxProps
  showToggleFirst: boolean
  toggleColorSx?: BoxProps
}

export const ToggleListSwitch = ({
  labelKey,
  color,
  useCheckboxProps,
  showToggleFirst,
  toggleColorSx,
}: ToggleListSwitchProps) => {
  const { state, getInputProps, htmlProps } = useCheckbox(useCheckboxProps)

  const inputProps = getInputProps()

  const toggleDetails = (
    <HStack alignItems="baseline">
      <Box minW="8px" w="8px" h="8px" borderRadius="50%" bg={color} sx={toggleColorSx} />
      <Text fontSize="16px" fontWeight={400} lineHeight={1} mt="px">
        <FormattedMessage id={labelKey} />
      </Text>
    </HStack>
  )

  const toggleSwitch = (
    <Switch
      isChecked={state.isChecked}
      onChange={inputProps.onChange}
      value={inputProps.value}
      colorScheme="blue"
      size="sm"
      mt={0.5}
    />
  )

  return (
    <HStack
      as="label"
      display="flex"
      {...htmlProps}
      align="flex-start"
      cursor="pointer"
      justifyContent={showToggleFirst ? 'normal' : 'space-between'}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...inputProps} hidden />
      {showToggleFirst ? (
        <>
          {toggleSwitch}
          {toggleDetails}
        </>
      ) : (
        <>
          {toggleDetails}
          {toggleSwitch}
        </>
      )}
    </HStack>
  )
}
