const types = {
  CREATE_USER_SESSION_REQUEST: 'user-activity-session/create_request',
  CREATE_USER_SESSION_SUCCESS: 'user-activity-session/create_success',
}

// eslint-disable-next-line import/no-default-export
export default types

export const createRequest = () => ({ type: types.CREATE_USER_SESSION_REQUEST })

export const createSuccess = session => ({
  type: types.CREATE_USER_SESSION_SUCCESS,
  data: session,
})
