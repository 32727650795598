import { createStandaloneToast, type UseToastOptions } from '@chakra-ui/react'
import { FormattedMessage, type ReactNodeOrI18nKey } from '@repo/i18n'

import { theme } from '../theme/main'

const { toast } = createStandaloneToast({
  theme,
  defaultOptions: { isClosable: true, position: 'top' },
})

export const showToast = ({
  status,
  message,
  messageKey,
  options,
}: {
  status: UseToastOptions['status']
  options?: Omit<UseToastOptions, 'description' | 'title' | 'status'>
} & ReactNodeOrI18nKey<'message', true>) =>
  toast({
    description: messageKey ? <FormattedMessage id={messageKey} /> : message,
    status,
    ...options,
  })

export const closeToast = toast.close
