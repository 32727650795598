import {
  Box,
  Grid,
  GridItem,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { HelpTooltip } from '@repo/ui'
import { type AnyObject } from '@repo/utils'
import { pick } from 'lodash-es'
import { VscCircleFilled } from 'react-icons/vsc'

import { type Result } from '../../hooks/use-result'
import { type DataItem, TargetMatchChart } from '../charts/target-match-chart'
import { Legend, PersonVSRequirements, type ScoresObj } from './person-vs-requirements'

type OverviewProps = {
  scores: Result['scores']
  big5Data: Array<DataItem>
  showTargetProfile: boolean
  candidateView: boolean
}

export const Overview = ({
  scores,
  big5Data,
  showTargetProfile,
  candidateView,
}: OverviewProps) => {
  const scoresObj: ScoresObj = scores.reduce(
    (res, { id, score }) => ({ ...res, [id]: score }),
    {} as ScoresObj
  )

  const keyStart = candidateView
    ? 'recording.details.you.overview'
    : 'recording.details.overview'

  return (
    <Stack>
      <Box pt={5}>
        <Heading mb={6}>
          <FormattedMessage id="recording.details.overview.title" />
        </Heading>
      </Box>
      <Stack spacing={8}>
        <Grid templateColumns="repeat(2, 1fr)">
          <GridItem p={2} mb={8} colSpan={{ sm: 2, md: 2, lg: 2, xl: 1, '2xl': 1 }}>
            <Heading display="inline-flex" size="md" fontWeight="semibold" mb={6}>
              <FormattedMessage id="recording.details.overview.strengths.title" />
              <HelpTooltip
                label={
                  <FormattedMessage id="recording.details.overview.strengths.hover" />
                }
              />
            </Heading>
            <List spacing={5} fontSize="lg" pl={2}>
              {scores.slice(0, 3).map(({ id, description }: AnyObject) => (
                <ListItem key={id} display="flex" textAlign="left">
                  <ListIcon as={VscCircleFilled} color="primary" mt={1} />
                  <Text>{description}</Text>
                </ListItem>
              ))}
            </List>
          </GridItem>
          <GridItem p={2} mb={8} colSpan={{ sm: 2, md: 2, lg: 2, xl: 1, '2xl': 1 }}>
            <Heading display="inline-flex" size="md" fontWeight="semibold" mb={8}>
              <FormattedMessage id="recording.details.overview.big5.title" />
              <HelpTooltip
                label={<FormattedMessage id="recording.details.overview.big5.hover" />}
              />
            </Heading>

            <TargetMatchChart
              data={big5Data}
              width={400}
              height={250}
              showTargetProfile={showTargetProfile}
              candidateView={candidateView}
            />
          </GridItem>
        </Grid>
        <Box>
          <Heading display="inline-flex" size="md" fontWeight="semibold" mb={8}>
            <FormattedMessage id={`${keyStart}.requirements.title`} />
            <HelpTooltip
              label={<FormattedMessage id={`${keyStart}.requirements.hover`} />}
            />
          </Heading>
          <Box pl={5}>
            <Legend />
          </Box>
          <Grid p={5} templateColumns="repeat(3, 1fr)">
            <GridItem colSpan={{ sm: 3, md: 3, lg: 3, xl: 1, '2xl': 1 }}>
              <PersonVSRequirements
                label={
                  <FormattedMessage id="recording.details.overview.requirements.personality.title" />
                }
                scores={pick(scoresObj, [
                  'agreeableness',
                  'extraversion',
                  'openness',
                  'conscientiousness',
                  'neuroticism',
                ])}
              />
            </GridItem>
            <GridItem colSpan={{ sm: 3, md: 3, lg: 3, xl: 1, '2xl': 1 }}>
              <PersonVSRequirements
                label={
                  <FormattedMessage id="recording.details.overview.requirements.jobrole.title" />
                }
                scores={pick(scoresObj, [
                  'tfDealing',
                  'tfSocialRelations',
                  'tfAutonomy',
                  'tfIdentity',
                  'tfVariety',
                ])}
              />
            </GridItem>
            <GridItem colSpan={{ sm: 3, md: 3, lg: 3, xl: 1, '2xl': 1 }}>
              <PersonVSRequirements
                label={
                  <FormattedMessage id="recording.details.overview.requirements.culture.title" />
                }
                scores={pick(scoresObj, [
                  'cfCompetitive',
                  'cfInnovative',
                  'cfDetailOriented',
                  'cfOutcomeOriented',
                  'cfTeamOriented',
                  'cfSupportive',
                  'cfStability',
                ])}
              />
            </GridItem>
          </Grid>
        </Box>
      </Stack>
    </Stack>
  )
}
