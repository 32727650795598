import { Spacer } from '@chakra-ui/react'
import { take } from 'lodash-es'
import { useMemo } from 'react'

import { DashboardCard } from '../shared/dashboard-card'
import { useDashboardCardData } from '../shared/use-dashboard-card-data'
import { DashboardNameValueList } from './shared/dashboard-name-value-list'
import { DetailPageLink } from './shared/detail-page-link'
import { NoDataInformation } from './shared/no-data-information'

export const CohortPerformanceCard = () => {
  const { dataSlot } = useDashboardCardData('cohortPerformance')

  const tableRows = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    // we are mapping the cohort names here, because it is possible to have this empty even if the overall score is not
    const cohortNamesAndScores = data.cohortNames.map((name, index) => ({
      score: data.overallScore[index]!,
      isHiddenMetric: data.metricsHidden[index],
      name,
    }))

    const cohortNamesAndScoresSorted = cohortNamesAndScores.sort(
      (a, b) => b.score - a.score
    )

    return take(cohortNamesAndScoresSorted, 4).map(({ name, score, isHiddenMetric }) => ({
      name,
      value: score ? `${score}%` : null,
      isHiddenMetric,
    }))
  }, [dataSlot])

  const isEmptyData =
    dataSlot.status === 'loaded' && dataSlot.data.overallScoreOverall === null

  return (
    <DashboardCard
      titleKey="dashboard.cards.cohortPerformance.title"
      descriptionKey="dashboard.cards.cohortPerformance.subtitle"
      colSpan={3}
      wrapperProps={{ alignItems: 'center', flex: 1 }}
    >
      {tableRows.length && !isEmptyData ? (
        <>
          <DashboardNameValueList
            data={tableRows}
            tableHeadLabelOne="dashboard.cards.cohortPerformance.tab.column.cohort.name"
            tableHeadLabelTow="dashboard.cards.cohortPerformance.tab.column.overall.score"
            hideNumbers
          />
          <Spacer />
          {tableRows.length > 2 && (
            <DetailPageLink sx={{ mt: 6 }} page="cohort_performance_details" />
          )}
        </>
      ) : (
        <NoDataInformation />
      )}
    </DashboardCard>
  )
}
