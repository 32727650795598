import { Box, Button, HStack, Table, Tbody, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useEffect, useState } from 'react'
import { RiAddFill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'

import { type OrganizationMember } from '../../store/entities/organization/organization-types'
import { selectCombinedMembers } from '../../store/entities/organization/selectors'
import { fetchRoles } from '../../store/entities/roles/effects'
import { type Role } from '../../store/entities/roles/roles-types'
import { selectRoles } from '../../store/entities/roles/selectors'
import { MemberInviteModal } from './member-invite-modal'
import { MemberListRow } from './member-list-row'

export const MemberList = () => {
  const dispatch = useDispatch()

  const members = useSelector<unknown, ReadonlyArray<OrganizationMember>>(
    selectCombinedMembers
  )

  const roles = useSelector<unknown, ReadonlyArray<Role>>(selectRoles)

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchRoles())
  }, [dispatch])

  const handleOpenInviteModal = () => {
    setIsInviteModalOpen(true)
  }

  return (
    <>
      <MemberInviteModal
        isOpen={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
      />
      <HStack justify="space-between">
        <Text>
          <FormattedMessage id="members.invite.subtitle" />
        </Text>
        <Button
          leftIcon={<RiAddFill size="1.25em" />}
          onClick={handleOpenInviteModal}
          iconSpacing="1"
          flexShrink={0}
          alignSelf="flex-end"
        >
          <FormattedMessage id="members.button.invite" />
        </Button>
      </HStack>
      <Box flexBasis={0} flexGrow={1} overflowY="auto">
        <Table>
          <Tbody>
            {members.map(m => (
              <MemberListRow key={m.id} member={m} roles={roles} />
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  )
}
