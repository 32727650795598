import {
  Badge,
  Box,
  Circle,
  Image,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type ReactNode } from 'react'

import {
  type Stage,
  stageConfigs,
} from '../interaction-step/interaction-step-renderer/shared/stage-configs'

type StageTabsProps = {
  activeStep: number
  renderPanels: () => ReactNode
  isTabDisabled?: (index: number) => boolean
}

export const StageTabs = ({
  activeStep,
  renderPanels,
  isTabDisabled,
}: StageTabsProps) => {
  const stageKeys: Array<Stage> = ['beginning', 'mainPart', 'end']

  return (
    <Tabs index={activeStep} variant="unstyled" align="center" isLazy>
      <TabList justifyContent="flex-start">
        {stageKeys.map((stage, index) => {
          const isActive = activeStep === index
          const { image, colorScheme } = stageConfigs[stage]

          return (
            <Tab
              key={stage}
              _focus={{ boxShadow: 'none' }}
              pl={0}
              pr={4}
              isDisabled={isTabDisabled?.(index)}
            >
              <VStack spacing={6}>
                <Box
                  position="relative"
                  borderRadius="md"
                  bg={isActive ? '#C2E5F7' : colors['secondary-light']}
                  pt="12px"
                  px={6}
                  pb={0}
                  w="176px"
                  cursor={isTabDisabled?.(index) ? 'not-allowed' : 'pointer'}
                >
                  <Circle
                    size="30px"
                    position="absolute"
                    top="12px"
                    right="12px"
                    bg={isActive ? colors.blue[700] : colors['secondary-light']}
                    border={!isActive ? '1px solid black' : 'none'}
                    color={isActive ? 'white' : 'black'}
                    fontSize="sm"
                    fontWeight="bold"
                  >
                    {index + 1}
                  </Circle>

                  <Image src={image} boxSize="112px" transition="opacity 0.3s" />
                </Box>
                <Badge colorScheme={colorScheme}>
                  <FormattedMessage
                    id={`scenario.realtime.builder.stages.${stage}` satisfies I18nKey}
                  />
                </Badge>
              </VStack>
            </Tab>
          )
        })}
      </TabList>

      <TabPanels mt={6}>{renderPanels()}</TabPanels>
    </Tabs>
  )
}
