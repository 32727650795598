import { showToast } from '@repo/ui'
import { Log } from '@repo/utils'
import { useEffect } from 'react'

const executeCopyCommand = (onSuccess = Function.prototype) => {
  try {
    const successful = document.execCommand('copy')

    if (successful) {
      onSuccess()
    } else {
      Log.error(new Error('Unable to copy text area contents to clipboard'))
    }
  } catch (err) {
    Log.error(new Error('Crashed during "copy text area contents to clipboard" attempt'))
  }
}

const copyFromTextArea = (textArea, toastMessageId) => {
  if (!textArea) {
    return
  }

  textArea.select()
  executeCopyCommand(() => showToast({ messageKey: toastMessageId, status: 'success' }))
  textArea.setSelectionRange(0, 0)
  textArea.blur()
}

const fallbackCopyTextToClipboard = (text, toastMessageId) => {
  const textArea = document.createElement('textarea')

  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  executeCopyCommand(() => showToast({ messageKey: toastMessageId, status: 'success' }))

  document.body.removeChild(textArea)
}

const copyTextToClipboard = (text, toastMessageId) => {
  navigator.clipboard.writeText(text).then(
    () => showToast({ messageKey: toastMessageId, status: 'success' }),
    () => Log.error(new Error('Unable to write text to clipboard'))
  )
}

// eslint-disable-next-line functional/no-let
let copyTextToClipboardFn = copyTextToClipboard

export const useClipboard = () => {
  useEffect(() => {
    // Check if browser supports clipboard API
    if (!navigator.clipboard) {
      copyTextToClipboardFn = fallbackCopyTextToClipboard
    }
  }, [])

  return {
    copyTextToClipboard: copyTextToClipboardFn,
    copyFromTextArea,
  }
}
