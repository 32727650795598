import { RHF, useFormRules } from '@repo/ui'
import { useFormContext } from 'react-hook-form'

import { useSelectOptions } from '../../../use-select-options'
import { type Trainee, type TraineeFormValues } from '../../types'

const workplaceOptions: Array<NonNullable<Trainee['workplace']>> = [
  'onSite',
  'remote',
  'hybrid',
] as const

const departmentSuggestions = [
  'customerService',
  'sales',
  'marketing',
  'operationsAndLogistics',
  'humanResources',
  'financeAndAccounting',
  'informationTechnologyDevelopment',
  'administrative',
  'qualityAssuranceQualityControl',
  'legalAndCompliance',
  'researchAndDevelopment',
  'consultingAndAdvisoryServices',
  'educationAndTrainingDevelopment',
  'marketResearchAndAnalytics',
  'projectManagement',
] as const

const industrySuggestions = [
  'telecommunication',
  'insurance',
  'retailMobility',
  'travel',
  'energyAndLogistic',
  'fmcg',
  'financialServices',
  'healthCare',
  'it',
  'fashion',
] as const

export const StepCompany = () => {
  const { control } = useFormContext<TraineeFormValues['company']>()
  const rules = useFormRules()

  const { getSelectOptions } = useSelectOptions()

  return (
    <>
      <RHF.AutocompleteSelect
        control={control}
        name="department"
        labelKey="scenario.builder.ai.steps.trainee.formLabel.department"
        rules={rules.stringShortRequired}
        componentProps={{
          allowCustomValues: true,
          options: getSelectOptions(departmentSuggestions, 'trainee.department', true),
        }}
      />

      <RHF.AutocompleteSelect
        control={control}
        name="industry"
        labelKey="common.industry"
        rules={rules.stringShortRequired}
        componentProps={{
          allowCustomValues: true,
          options: getSelectOptions(industrySuggestions, 'trainee.industry', true),
        }}
      />

      <RHF.RadioButtonGroup
        control={control}
        name="workplace"
        labelKey="scenario.builder.ai.steps.trainee.formLabel.workplace"
        componentProps={{
          options: workplaceOptions.map(value => ({
            value,
            labelKey: `trainee.workplace.${value}`,
          })),
        }}
      />
    </>
  )
}
