import data from './catalog-data.json'
import type { Catalog, CatalogCategories } from './types'

const catalog = data as unknown as Catalog

export const getItem = (categoryId: CatalogCategories, itemId: string) => {
  const code = catalog[categoryId][itemId]

  return code ? `${categoryId}.${code}` : `error.${catalog.error.unknownError as string}`
}
