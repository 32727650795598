import { Flex } from '@chakra-ui/react'
import { type ProgramLanguages } from '@repo/api'
import { FormattedMessage } from '@repo/i18n'
import { showToast, useModal } from '@repo/ui'
import { assertExists } from '@repo/utils'
import { useNavigate, useParams } from 'react-router-dom'

import { CardGrid } from '../../components/card-grid'
import { useLocalStorage } from '../../hooks/use-local-storage'
import { useOrganization } from '../../hooks/use-organization'
import { type ScenarioType } from '../../types/api-types'
import { client } from '../../utils/openapi-client'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'
import { BuilderModeCard } from './create-scenario-card/builder-mode-card'
import { scenarioBuilderPaths } from './scenario-card'

const languagesWithAIBuilder: Array<ProgramLanguages> = ['en-US', 'de-DE']

export const CreateScenarioCard = () => {
  const { hasUnderstanding, hasAiContentGen, hasRealtimeConversationStep } =
    useOrganization()

  const { programExtId, trainingExtId } = useParams()

  assertExists(programExtId)
  assertExists(trainingExtId)

  const { data: program } = useOpenapiSWR('getProgramByExtId', {
    params: { extId: programExtId },
  })

  const navigate = useNavigate()

  const { mutate: mutateScenarios } = useOpenapiSWR('getScenarios', {
    params: { programExtId, trainingExtId },
  })

  const [hasSeenBuilderIntro] = useLocalStorage('hasSeenBuilderIntro')

  const createScenarioAndNavigate = async (type: ScenarioType) => {
    const res = await client.post('createScenario', {
      body: { type },
      params: { path: { programExtId, trainingExtId } },
    })

    if (res.error) {
      return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }

    mutateScenarios(scenarios => {
      const newScenario = {
        ...res.data,
        hasRecordingSessions: false,
      }

      return [...(scenarios ?? []), newScenario]
    }, false)

    const builderPath = scenarioBuilderPaths[type]

    let scenarioFullPath = `scenarios/${res.data.extId}/${builderPath}`

    const shouldShowIntro =
      !hasSeenBuilderIntro && (type === 'aiSupported' || type === 'realtimeBuilder')

    if (shouldShowIntro) {
      scenarioFullPath = `${scenarioFullPath}/intro`
    }

    navigate(scenarioFullPath)
  }

  const hasConsentForAIBuilder = hasUnderstanding && hasAiContentGen
  const isAIBuilderDisabled =
    !hasConsentForAIBuilder ||
    !program ||
    !languagesWithAIBuilder.includes(program.language)

  const isRealtimeBuilderDisabled = !hasRealtimeConversationStep || !program

  const [modal, showModal] = useModal(
    () => (
      <Flex gap="8" mb={6}>
        <BuilderModeCard
          isDisabled={isRealtimeBuilderDisabled}
          mode="realtimeOld"
          onClick={() => createScenarioAndNavigate('realtimeBuilder')}
          tooltip={
            !hasRealtimeConversationStep && (
              <FormattedMessage id="coaching.generator.modal.title" />
            )
          }
        />

        <BuilderModeCard
          tooltip={
            !hasConsentForAIBuilder && (
              <FormattedMessage id="coaching.generator.modal.title" />
            )
          }
          isDisabled={isAIBuilderDisabled}
          mode="ai"
          onClick={() => createScenarioAndNavigate('aiSupported')}
          showInfo={
            hasConsentForAIBuilder &&
            (!program || !languagesWithAIBuilder.includes(program.language))
          }
        />

        <BuilderModeCard
          mode="manual"
          onClick={() => createScenarioAndNavigate('manual')}
        />
      </Flex>
    ),
    {
      titleKey: 'scenario.createNewModal.title',
      modalProps: {
        size: '5xl',
      },
    }
  )

  return (
    <>
      {modal}
      <CardGrid.CreateCard onClick={showModal} labelKey="scenario.editor.add" />
    </>
  )
}
