import { Box, Flex, Grid, GridItem, Stack, Text, type TextProps } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import {
  CheckCircleIcon,
  LoveFireIcon,
  RemoveCircleIcon,
  SnailIcon,
  SpaceRocketFlyingIcon,
} from '@repo/ui'
import { type PropsWithChildren, type ReactNode, useMemo } from 'react'

import { get5SegmentDescription, type Values } from '../../hooks/use-result'

type HeadingProps = {
  icon?: ReactNode
  size?: 'md' | 'lg'
  color?: TextProps['color']
}

const Heading = ({
  children,
  icon,
  color = 'primary-dark',
  size = 'md',
}: PropsWithChildren<HeadingProps>) => {
  const sizeProps: {
    md: Pick<TextProps, 'fontSize' | 'fontWeight'>
    lg: Pick<TextProps, 'fontSize' | 'fontWeight'>
  } = {
    md: {
      fontSize: 'lg',
      fontWeight: 'semibold',
    },
    lg: {
      fontSize: '3xl',
      fontWeight: 'semibold',
    },
  }

  return (
    <Stack direction="row" align="center" mb={4}>
      {icon}
      <Text {...sizeProps[size]} color={color}>
        {children}
      </Text>
    </Stack>
  )
}

type MotivationContainerProps = {
  category: 'motivators' | 'demotivators'
  rawValues: Values
  heading: ReactNode
  keyStart: string
}

const big5dimensions = [
  'extraversion',
  'conscientiousness',
  'agreeableness',
  'openness',
  'neuroticism',
]

const MotivationContainer = ({
  category,
  heading,
  rawValues,
  keyStart,
}: MotivationContainerProps) => {
  const { formatMessage } = useIntl()

  const aspects = useMemo(
    () =>
      Object.keys(rawValues)
        .map(dimension =>
          big5dimensions.includes(dimension)
            ? {
                id: dimension,
                text: formatMessage({
                  id: `${keyStart}.${dimension}.${category}.${get5SegmentDescription(
                    rawValues[dimension] as number
                  )}` as I18nKey,
                }),
              }
            : null
        )
        .filter(Boolean),
    [category, formatMessage, keyStart, rawValues]
  )

  return (
    <Box border="2px" borderColor="gray.200" borderRadius="md" p={6} h="100%">
      {heading}
      <Stack spacing={4} pl={6}>
        {aspects.filter(Boolean).map((aspect, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Flex key={`${aspect?.id}-${index}`}>
            <Box pr={4}>
              {category === 'motivators' ? <CheckCircleIcon /> : <RemoveCircleIcon />}
            </Box>
            <Text>{aspect?.text}</Text>
          </Flex>
        ))}
      </Stack>
    </Box>
  )
}

type CandidateMotivationProps = {
  rawValues: Values
  candidateView: boolean
}

export const CandidateMotivation = ({
  rawValues,
  candidateView,
}: CandidateMotivationProps) => {
  const keyStart = candidateView
    ? 'recording.details.you.motivation'
    : 'recording.details.motivation'

  return (
    <Box>
      <Box py={5}>
        <Heading icon={<LoveFireIcon boxSize={5} />} color="black" size="lg">
          <FormattedMessage id={`${keyStart}.heading`} />
        </Heading>
        <Text>
          <FormattedMessage id={`${keyStart}.subheading`} />
        </Text>
      </Box>
      <Grid templateColumns="repeat(2, 1fr)" gap={3}>
        <GridItem colSpan={{ sm: 2, md: 2, lg: 2, xl: 1 }}>
          <MotivationContainer
            category="motivators"
            rawValues={rawValues}
            keyStart={keyStart}
            heading={
              <Heading icon={<SpaceRocketFlyingIcon />}>
                <FormattedMessage id={`${keyStart}.heading.motivators`} />
              </Heading>
            }
          />
        </GridItem>
        <GridItem colSpan={{ sm: 2, md: 2, lg: 2, xl: 1 }}>
          <MotivationContainer
            category="demotivators"
            rawValues={rawValues}
            keyStart={keyStart}
            heading={
              <Heading icon={<SnailIcon />}>
                <FormattedMessage id={`${keyStart}.heading.demotivators`} />
              </Heading>
            }
          />
        </GridItem>
      </Grid>
    </Box>
  )
}
