import { chakra, Flex } from '@chakra-ui/react'
import { colors } from '@repo/ui'

import { type QuestionWithRatios } from '../../../../shared/types'

const ColoredBox = chakra(Flex, {
  baseStyle: {
    h: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
  },
})

export const AnswerAccuracyGraph = ({
  data: { correctRatio, partiallyCorrectRatio, wrongRatio },
}: {
  data: Pick<QuestionWithRatios, 'correctRatio' | 'partiallyCorrectRatio' | 'wrongRatio'>
}) => (
  <Flex borderRadius="md" height="26px" overflow="hidden">
    {[
      { ratio: correctRatio, color: colors.green[500] },
      { ratio: partiallyCorrectRatio, color: colors.yellow[500] },
      { ratio: wrongRatio, color: colors.red[500] },
    ]
      .filter(({ ratio }) => !!ratio)
      .map(({ ratio, color }) => (
        <ColoredBox key={color} bg={color} w={`${ratio}%`}>
          {ratio}%
        </ColoredBox>
      ))}
  </Flex>
)
