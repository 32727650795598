import { Box, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'

import { type ProgramTemplate } from '../../../../../shared/types'
import { ScenarioCard } from './training-section/scenario-card'

export const TrainingSection = ({
  training: { scenarios, title },
}: {
  training: ProgramTemplate['program']['trainings'][number]
}) => (
  <Stack gap={6}>
    <Text fontWeight={600}>
      <Box as="span" color={colors.blue[500]}>
        <FormattedMessage id="training.title" />
      </Box>
      {` ${title}`}
    </Text>
    <SimpleGrid gap={6} templateColumns="repeat(auto-fill, minmax(200px, 1fr))">
      {scenarios.map(scenario => (
        <ScenarioCard key={scenario.extId} scenario={scenario} />
      ))}
    </SimpleGrid>
  </Stack>
)
