import { isString } from '@repo/utils'
import { Navigate, useSearchParams } from 'react-router-dom'

import { EmailVerified } from './email-verified'
import { ResetPassword } from './reset-password'

type FirebaseActionMode = 'resetPassword' | 'recoverEmail' | 'verifyEmail'

const isFirebaseActionMode = (v: unknown): v is FirebaseActionMode =>
  isString(v) && (v === 'resetPassword' || v === 'recoverEmail' || v === 'verifyEmail')

/**
 * Firebase documentation: https://firebase.google.com/docs/auth/custom-email-handler
 */
export const FirebaseEmailActionHandler = () => {
  const [searchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const actionCode = searchParams.get('oobCode')

  if (isFirebaseActionMode(mode) && isString(actionCode)) {
    switch (mode) {
      case 'resetPassword':
        return <ResetPassword actionCode={actionCode} />
      case 'verifyEmail':
        return <EmailVerified actionCode={actionCode} />
      default:
        break
    }
  }

  return <Navigate to="/login" />
}
