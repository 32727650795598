import { Stack } from '@chakra-ui/react'
import { showToast, useModal } from '@repo/ui'
import { type SubmitHandler } from 'react-hook-form'

import { client } from '../../../../../../utils/openapi-client'
import { StickyFooter } from '../../../../../shared/sticky-footer'
import { useRealtimeBuilderContext } from '../../../../shared/realtime-builder-context'
import { useStageStepperContext } from '../../../shared/stage-stepper-context'
import { type InstructionFormValues, useInstructionForm } from './use-instruction-form'

const InstructionCreateForm = ({ closeModal }) => {
  const { activeStageIndex } = useStageStepperContext()
  const [form, formFieldsJsx] = useInstructionForm()
  const { handleSubmit, formState } = form
  const { programExtId, scenarioExtId, trainingExtId, mutateScenario } =
    useRealtimeBuilderContext()

  const onSubmit: SubmitHandler<InstructionFormValues> = async data => {
    try {
      const res = await client.post('createInstruction', {
        params: {
          path: {
            programExtId,
            scenarioExtId,
            trainingExtId,
          },
        },
        body: {
          behaviorType: 'positive',
          description: data.description,
          missionStageOrder: activeStageIndex,
          skillName: data.skillName,
          references: data.reference[0]?.value?.trim() ? data.reference[0]?.value : null,
          hows: data.hows
            .filter(how => !!how.description.trim())
            .map(how => ({
              description: how.description,
              references: how.reference[0]?.value?.trim()
                ? how.reference[0]?.value
                : null,
            })),
        },
      })

      if (res.error) {
        throw new Error()
      }

      // TODO optimistic update
      await mutateScenario()

      showToast({ messageKey: 'common.alert.created', status: 'success' })
      closeModal()
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} width="80%" mx="auto">
      {formFieldsJsx}

      <StickyFooter
        nextLabelKey="common.create"
        isNextDisabled={!formState.isValid}
        isNextLoading={formState.isSubmitting}
      />
    </Stack>
  )
}

export const useCreateInstructionModal = () =>
  useModal(InstructionCreateForm, {
    titleKey: 'scenario.builder.realtime.steps.instructions.createModal.title',
    modalProps: { size: 'full', scrollBehavior: 'inside' },
    bodySx: { width: '100%', overflowX: 'hidden', py: '0' },
    headerSx: { alignSelf: 'center', width: '80%', pt: '60px', pl: '18px' },
    contentSx: { borderRadius: '0px' },
  })
