import { css, Global } from '@emotion/react'

import RobotoMono400Latin from '../assets/fonts/Roboto Mono - 400 - latin.woff2'
import RobotoMono500Latin from '../assets/fonts/Roboto Mono - 500 - latin.woff2'
import RobotoMono600Latin from '../assets/fonts/Roboto Mono - 600 - latin.woff2'

const globalStyle = css`
  /* Copied from https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600&display=swap */

  /* latin */
  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${RobotoMono400Latin}') format('woff2');
    unicode-range:
      U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
      U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('${RobotoMono500Latin}') format('woff2');
    unicode-range:
      U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
      U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('${RobotoMono600Latin}') format('woff2');
    unicode-range:
      U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
      U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
`

const Fonts = () => <Global styles={globalStyle} />

export { Fonts }
