import { Box } from '@chakra-ui/react'
import { type MDEditorProps } from '@uiw/react-md-editor'
// eslint-disable-next-line import/no-extraneous-dependencies
import MDEditor from '@uiw/react-md-editor'
import { Controller, type FieldPath, type FieldValues } from 'react-hook-form'

import { markdownPreviewOptions } from '../../markdown-preview-options'
import { FormControlWrapper } from './shared/form-control-wrapper'
import { type FieldBaseProps } from './shared/types'

type FormFieldMarkdownProps = MDEditorProps & {
  excluded?: RegExp
}

export const FormFieldMarkdown = <T extends FieldValues, TName extends FieldPath<T>>(
  props: FieldBaseProps<T, FormFieldMarkdownProps, TName>
) => {
  const { name, control, rules, shouldUnregister, componentProps } = props

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FormControlWrapper fieldBaseProps={props} error={fieldState.error}>
          <Box>
            <MDEditor
              data-color-mode="light"
              height={200}
              preview="edit"
              previewOptions={markdownPreviewOptions}
              commandsFilter={cmd =>
                componentProps?.excluded?.test(cmd.name ?? '') ? false : cmd
              }
              style={{
                border: fieldState.error && fieldState.isTouched ? '1px solid red' : '',
                ...(componentProps?.style || {}),
              }}
              {...field}
              // prevent passing ref to MDEditor, otherwise rhf tries to execute ref.focus(), which does not exist
              ref={null}
              {...componentProps}
              onChange={value => {
                field.onChange(value)
                componentProps?.onChange?.(value)
              }}
              value={field.value}
            />
          </Box>
        </FormControlWrapper>
      )}
    />
  )
}
