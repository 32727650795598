import { Box, Center, Flex, HStack, Link } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { LanguageSwitch, Logo } from '@repo/ui'
import { Outlet } from 'react-router-dom'

import LoginBackground from '../assets/login_background.jpg'

const LegalFooter = () => (
  <HStack
    spacing={10}
    justifyContent="center"
    textAlign="center"
    mt={5}
    className="legal-footer"
  >
    <Link
      fontWeight="medium"
      variant="content-primary-dark"
      isExternal
      href="https://www.retorio.com/privacy-policy"
    >
      <FormattedMessage id="signup.form.field.legalConsent.label.privacy" />
    </Link>
    <Link
      fontWeight="medium"
      variant="content-primary-dark"
      isExternal
      href="https://www.retorio.com/imprint"
    >
      <FormattedMessage id="signup.form.field.legalConsent.label.legal" />
    </Link>
    <Link
      fontWeight="medium"
      variant="content-primary-dark"
      isExternal
      href="https://www.retorio.com/tos"
    >
      <FormattedMessage id="signup.form.field.legalConsent.label.tos" />
    </Link>
  </HStack>
)

export const AuthLayout = () => (
  <Flex height="full">
    <Flex
      className="left-column"
      flex="0 1 50%"
      display={{ base: 'none', sm: 'none', md: 'flex' }}
      justifyContent="center"
      alignItems="center"
      bgImage={LoginBackground}
      bgRepeat="no-repeat"
      bgSize="cover"
      bgPosition="center"
    >
      <Box position="absolute" left={0} top={0} margin={8} w="200px">
        <Logo type="light" />
      </Box>
    </Flex>
    <Flex
      className="right-column"
      flexDir="column"
      flex={{ base: '1', sm: '1', md: '0 1 50%' }}
      p={4}
      overflowY="auto"
    >
      <Flex justifyContent="flex-end" className="language-switch-container">
        <LanguageSwitch color="primary" />
      </Flex>
      <Center
        className="form-container"
        flexGrow={1}
        margin="0 auto"
        width={{ md: '80%', lg: '60%' }}
      >
        <Box flexGrow={1}>
          <Outlet />
        </Box>
      </Center>
      <LegalFooter />
    </Flex>
  </Flex>
)
