import {
  Checkbox as ChakraCheckbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import { useField } from 'formik'
import { omit } from 'lodash-es'
import PropTypes from 'prop-types'

export const Checkbox = ({ help, label, checked, name, ...restProps }) => {
  const [field, meta] = useField(name)

  return (
    <FormControl>
      <ChakraCheckbox
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        isInvalid={meta.touched && (meta.error as any)}
        {...omit(field, ['value'])}
        {...restProps}
        isChecked={checked}
      >
        {label}
      </ChakraCheckbox>

      {meta.touched && meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      {help && <FormHelperText>{help}</FormHelperText>}
    </FormControl>
  )
}

Checkbox.propTypes = {
  help: PropTypes.node,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
}
