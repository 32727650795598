import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useMemo,
  useState,
} from 'react'

interface AppContextProps {
  videoDevice: string | undefined
  setVideoDevice: Dispatch<SetStateAction<string | undefined>>
  audioDevice: string | undefined
  setAudioDevice: Dispatch<SetStateAction<string | undefined>>
}

export const DeviceContext = createContext<AppContextProps>({
  videoDevice: undefined,

  setVideoDevice: () => {},
  audioDevice: undefined,

  setAudioDevice: () => {},
})

export const DeviceProvider = ({ children }: PropsWithChildren) => {
  const [videoDevice, setVideoDevice] = useState<string | undefined>()
  const [audioDevice, setAudioDevice] = useState<string | undefined>()

  const value = useMemo(
    () => ({ videoDevice, setVideoDevice, audioDevice, setAudioDevice }),
    [videoDevice, setVideoDevice, audioDevice, setAudioDevice]
  )

  return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>
}
