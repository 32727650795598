import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  fetchPlugin,
  updateNotifications,
  updatePlugin,
} from '../store/entities/plugins/effects'
import { makeSelectPlugin } from '../store/entities/plugins/selectors'
import { useThunkFetching } from './thunk-fetching'

const defaultMakeMapStateToProps = (pluginId, selectPlugin) => state =>
  selectPlugin(state, pluginId)

export const usePlugin = (pluginId, makeMapStateToProps = defaultMakeMapStateToProps) => {
  const dispatch = useDispatch()
  const selectPlugin = useMemo(makeSelectPlugin, [])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [saveError, setSaveError] = useState<any>(null)
  const [isSaving, setIsSaving] = useState(false)

  const [isFetching, plugin, fetchError] = useThunkFetching({
    action: () => fetchPlugin(pluginId),
    mapState: makeMapStateToProps(pluginId, selectPlugin),
  })

  const save = useCallback(
    async formData => {
      setSaveError(null)
      setIsSaving(true)
      try {
        await dispatch(updatePlugin(plugin.id, formData))
        setIsSaving(false)

        return 'success'
      } catch (error) {
        setSaveError(error)
        setIsSaving(false)

        return error
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plugin]
  )

  const update = useCallback(
    async notifications => {
      setSaveError(null)
      setIsSaving(true)
      try {
        await dispatch(updateNotifications(plugin.id, notifications))
        setIsSaving(false)
      } catch (error) {
        setSaveError(error)
        setIsSaving(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plugin]
  )

  return {
    isFetching,
    isSaving,
    plugin,
    save,
    updateNotifications: update,
    fetchError,
    saveError,
  }
}
