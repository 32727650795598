import { Text } from '@chakra-ui/react'
import { type ApiOperations, type ApiSchemas } from '@repo/api'
import { FormattedMessage } from '@repo/i18n'
import { type FetchResponse } from 'openapi-fetch'
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { type KeyedMutator } from 'swr'

import { CenteredSpinner } from '../../../components/centered-spinner'
import { useOrganization } from '../../../hooks/use-organization'
import { type Program, type Scenario, type Training } from '../../../types/api-types'
import { type BuilderStepKey } from '../../shared/builder-steps'
import { useScenarioBuilderData } from '../../shared/use-scenario-builder-data'

type AIBuilderContextType = {
  program: Program
  training: Training
  scenario: Scenario
  scenarioExtId: string
  programExtId: string
  trainingExtId: string
  mutateScenario: KeyedMutator<Scenario>
  goToStep: (stepKey: BuilderStepKey) => void
  formatInProgramLanguage: (key: I18nKey) => string
  updateScenarioDetails: (
    data: Partial<ApiSchemas['updateScenarioDetails']>
  ) => Promise<
    FetchResponse<ApiOperations['updateScenarioDetails'], 'put', `${string}/${string}`>
  >
  isBuilderReadonly: boolean
}

const AIBuilderContext = createContext<AIBuilderContextType | null>(null)

export const AIBuilderContextProvider = ({ children }: PropsWithChildren) => {
  const [
    { programExtId, scenarioExtId, trainingExtId },
    { data: program, error: programError },
    { data: training, error: trainingError },
    { data: scenario, error: scenarioError, mutate: mutateScenario },
    updateScenarioDetails,
    formatInProgramLanguage,
  ] = useScenarioBuilderData()

  const { hasAiContentGen } = useOrganization()

  const navigate = useNavigate()
  const goToStep = useCallback<AIBuilderContextType['goToStep']>(
    stepKey => navigate(stepKey),
    [navigate]
  )

  const value = useMemo<AIBuilderContextType | null>(() => {
    if (!program || !training) {
      return null
    }

    if (!scenario) {
      return null
    }

    return {
      program,
      training,
      programExtId,
      trainingExtId,
      scenario,
      scenarioExtId,
      mutateScenario,
      goToStep,
      formatInProgramLanguage,
      updateScenarioDetails,
      isBuilderReadonly: program.status !== 'unpublished' || !hasAiContentGen,
    }
  }, [
    program,
    training,
    programExtId,
    trainingExtId,
    scenarioExtId,
    scenario,
    mutateScenario,
    goToStep,
    formatInProgramLanguage,
    updateScenarioDetails,
    hasAiContentGen,
  ])

  if (programError || trainingError || scenarioError) {
    console.error('Failed to fetch data', { programError, trainingError, scenarioError })

    return (
      <Text>
        <FormattedMessage id="common.error.unexpected" />
      </Text>
    )
  }

  if (!value) {
    return <CenteredSpinner />
  }

  return <AIBuilderContext.Provider value={value}>{children}</AIBuilderContext.Provider>
}

export const useAIBuilderContext = () => {
  const context = useContext(AIBuilderContext)

  if (!context) {
    throw new Error('useAIBuilderContext must be used within a AIBuilderContextProvider')
  }

  return context
}
