import { Button, Flex, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage, type ReactNodeOrI18nKey } from '@repo/i18n'
import { usePromisingModal } from '@repo/ui'
import { useMemo } from 'react'

import { useLocalStorage } from '../../hooks/use-local-storage'
import { ClappingAnimation } from './clapping-animation'

type Props = ReactNodeOrI18nKey<'continueLabel'> &
  ReactNodeOrI18nKey<'title'> &
  ReactNodeOrI18nKey<'description'>

export const useNextStepModal = ({
  continueLabel,
  continueLabelKey,
  title,
  titleKey,
  description,
  descriptionKey,
}: Props) => {
  const promisingModal = usePromisingModal<boolean>(({ resolve }) => (
    <Flex direction="column">
      <Stack px={6}>
        <ClappingAnimation />

        <Text fontWeight={600} fontSize={18}>
          {titleKey ? <FormattedMessage id={titleKey} /> : title}
        </Text>
        <Text>
          {descriptionKey ? <FormattedMessage id={descriptionKey} /> : description}
        </Text>
      </Stack>

      <Button
        alignSelf="flex-end"
        size="md"
        onClick={() => {
          resolve(true)
        }}
        mt={9}
      >
        {continueLabel || <FormattedMessage id={continueLabelKey || 'common.continue'} />}
      </Button>
    </Flex>
  ))

  const fakePromisingModal = useMemo(
    () => [null, () => Promise.resolve(true)] as const,
    []
  )

  const [hasCompletedScenarioBuilder] = useLocalStorage('hasCompletedScenarioBuilder')

  /**
   * if user has completed a scenario builder at least once, we don't need to show the confirmation modal for proceeding to the next step,
   * so we return a fake modal that resolves immediately
   */
  return hasCompletedScenarioBuilder ? fakePromisingModal : promisingModal
}
