import types from './actions'

// eslint-disable-next-line import/no-default-export
export default (state = {}, { type, data }) => {
  if (type === types.SET_ERROR) {
    return { ...state, [data.type]: data }
  }

  return state
}
