import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Link,
  Spacer,
  Switch,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { Form, useFormikContext } from 'formik'

import { FormControlCheckboxField } from '../../../form/form-control-checkbox-field'
import { FormControlDateInputField } from '../../../form/form-control-date-input-field'
import { useClipboard } from '../../../hooks/use-clipboard'
import { QuestionnaireSelection } from '../questionnaire-selection'
import { type PluginFormValues } from './plugin-form-utils'

type PluginFormProps = {
  isSaving: boolean
  onCancel: () => void
  onOpenNotificationSettings: () => void
  pluginUrl: string
}

export const PluginForm = ({
  isSaving,
  onCancel,
  onOpenNotificationSettings,
  pluginUrl,
}: PluginFormProps) => {
  const { copyTextToClipboard } = useClipboard()
  const { formatMessage } = useIntl()
  const { values, setFieldValue, touched, errors } = useFormikContext<PluginFormValues>()
  const mailTemplate = useIntl().formatMessage(
    { id: 'plugin.modal.invitation.mail.template' },
    { link: pluginUrl }
  )

  return (
    <Form>
      <FormLabel>
        <FormattedMessage id="plugin.form.field.question.label" />
      </FormLabel>
      <QuestionnaireSelection questionnaireId={values.questionnaireId} />

      <FormLabel mt={6}>
        <FormattedMessage id="plugin.form.recorderSettings.label" />
      </FormLabel>
      <FormControlCheckboxField
        prop="identityRequired"
        description={formatMessage({
          id: 'plugin.form.field.identityRequired.label',
        })}
      />
      {values.hasShareResult && values.identityRequired && (
        <FormControlCheckboxField
          prop="notifyApplicant"
          description={formatMessage({
            id: 'plugin.form.field.shareResultToggle.label',
          })}
        />
      )}
      {values.hasShareResult && values.identityRequired && values.notifyApplicant && (
        <>
          <Switch
            mr={4}
            name="shareWithTargetProfile"
            isChecked={values.shareWithTargetProfile}
            onChange={() =>
              setFieldValue('shareWithTargetProfile', !values.shareWithTargetProfile)
            }
          />
          {values.shareWithTargetProfile ? (
            <FormattedMessage id="plugin.form.field.shareWithTargetProfile.label" />
          ) : (
            <FormattedMessage id="plugin.form.field.shareWithoutTargetProfile.label" />
          )}
        </>
      )}

      <FormControlCheckboxField
        prop="preventUpload"
        description={formatMessage({
          id: 'plugin.form.field.preventUpload.label',
        })}
      />

      <FormLabel mt={6}>
        <FormattedMessage id="plugin.form.field.expirationDate.label" />
      </FormLabel>

      <Flex alignItems="center">
        <FormControl width="auto" mr={1}>
          <Switch
            mr={2}
            id="isActiveRecorder"
            isChecked={values.isActiveRecorder}
            onChange={() => setFieldValue('isActiveRecorder', !values.isActiveRecorder)}
          />
          {values.isActiveRecorder ? (
            <FormattedMessage id="plugin.form.field.expirationDate.isActive" />
          ) : (
            <FormattedMessage id="plugin.form.field.expirationDate.isDeactive" />
          )}
        </FormControl>

        {values.isActiveRecorder && (
          <>
            <FormattedMessage id="plugin.form.field.expirationDate.preText" />
            <Box ml={2}>
              <FormControlDateInputField
                prop="expirationDate"
                placeholder={formatMessage({
                  id: 'plugin.form.field.expirationDate.placeholder',
                })}
                touched={touched}
                errors={errors}
                numberOfMonths={1}
                small
                showDefaultInputIcon
                inputIconPosition="after"
                disabled={!values.isActiveRecorder}
              />
            </Box>
          </>
        )}
      </Flex>

      <Flex mt={6}>
        <FormLabel>
          <FormattedMessage id="plugin.modal.invitation.mail.title" />
        </FormLabel>
        <Spacer />
        <Button
          variant="ghost"
          color="link"
          onClick={() => {
            copyTextToClipboard(mailTemplate, 'plugin.toast.clipboard.copied.mail')
          }}
        >
          <FormattedMessage id="plugin.modal.copyToClipboard" />
        </Button>
      </Flex>
      <Link color="primary" href={mailTemplate} isExternal>
        {mailTemplate}
      </Link>

      <HStack mt={12} spacing={4}>
        <Button
          variant="outline"
          color="primary"
          onClick={onOpenNotificationSettings}
          isDisabled={isSaving}
        >
          <FormattedMessage id="plugin.modal.button.notifications" />
        </Button>
        <Spacer />

        <Button
          variant="outline"
          color="gray"
          onClick={onCancel}
          isDisabled={isSaving}
          type="reset"
        >
          <FormattedMessage id="plugin.modal.button.close" />
        </Button>
        <Button isLoading={isSaving} type="submit">
          <FormattedMessage id="common.save" />
        </Button>
      </HStack>
    </Form>
  )
}
