import { useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { CustomChartTooltip } from './custom-line-chart/custom-chart-tooltip'

type LineItem<TDataKey extends string> = Record<TDataKey, number | null> & {
  date: string
}

interface CustomLineChartProps<TDataKey extends string> {
  data: Array<LineItem<TDataKey>>
  yAxisLabelKey?: I18nKey
  yAxisTicks?: Array<number>
  dataConfig: Array<{
    dataKey: TDataKey
    color: string
  }>
  valueSuffix?: string
  showLineNamesInTooltip?: boolean
}

export const CustomLineChart = <TDataKey extends string>({
  data,
  yAxisTicks,
  yAxisLabelKey,
  dataConfig,
  valueSuffix,
  showLineNamesInTooltip = false,
}: CustomLineChartProps<TDataKey>) => {
  const { formatMessage, formatNumber } = useIntl()

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        style={{ fontSize: 12 }}
        margin={{ top: 30, left: -20, right: 0, bottom: 0 }}
      >
        <CartesianGrid stroke={colors.gray[100]} />
        <XAxis
          dataKey="date"
          tick={{ fill: 'black' }}
          minTickGap={0}
          padding={{ left: 40, right: 40 }}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          tickFormatter={value =>
            formatNumber(value, { notation: 'compact', compactDisplay: 'short' })
          }
          ticks={yAxisTicks}
          minTickGap={-1}
          tick={{ fill: 'black' }}
        >
          {!!yAxisLabelKey && (
            <Label
              style={{
                fill: 'black',
              }}
              offset={-25}
              dx={50}
              position="insideTopLeft"
              value={formatMessage({
                id: 'dashboard.cards.behavioralAdaptation.yaxis.label',
              })}
            />
          )}
        </YAxis>
        <Tooltip
          content={
            <CustomChartTooltip
              valueSuffix={valueSuffix}
              showLineNamesInTooltip={showLineNamesInTooltip}
            />
          }
        />

        {dataConfig.map(({ color = colors.blue[700], dataKey }) => (
          <Line
            connectNulls
            key={dataKey}
            dataKey={dataKey}
            stroke={color}
            strokeWidth={2}
            dot={{ fill: color }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}
