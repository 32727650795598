import { type ComponentProps, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { editFolder } from '../../store/entities/folders/effects'
import { selectFolderTree } from '../../store/ui/sidebar/selectors'
import { type FolderTree } from '../../store/ui/sidebar/sidebar-types'
import { api } from '../../utils/api'
import { isDroppableFolder, isDroppableRecording } from './folder-and-recording-dnd'
import { FolderNavigationTree } from './folder-navigation-tree'
import { type FolderNode } from './folder-node'

export const FolderNavigation = () => {
  const dispatch = useDispatch()
  const foldersData: FolderTree = useSelector(selectFolderTree)
  const onDrop: ComponentProps<typeof FolderNode>['onDrop'] = useCallback(
    async (item, destinationFolder) => {
      if (isDroppableFolder(item)) {
        dispatch(editFolder(item.folder.id, { path: destinationFolder.path }))
      }

      if (isDroppableRecording(item)) {
        try {
          await api.recordings.move(item.session.id, {
            folder: destinationFolder.id,
          })

          item.onAfterMove?.()
        } catch (error) {
          console.error('FolderNavigation onDrop error', error)
          // TODO: Error handling
        }
      }
    },
    [dispatch]
  )

  const { id } = useParams<{ id: string }>()
  const isNodeSelected = useCallback((folderId: string) => id === folderId, [id])

  return (
    <FolderNavigationTree
      folder={foldersData}
      isNodeSelected={isNodeSelected}
      onDrop={onDrop}
    />
  )
}
