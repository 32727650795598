import { Center, useToken } from '@chakra-ui/react'
import { fabric } from 'fabric'
import { useEffect, useRef } from 'react'

import { type QuizStep } from '../../../../builder/steps/api'
import { useGoogleStorageAsset } from '../../../../hooks/use-google-storage-asset'
import { loadImage } from '../../../../utils/load-image'
import { createPolygon } from '../../../../utils/polygon'

type ImageAnswerProps = {
  step: QuizStep
}

export const ImageRegionResult = ({ step }: ImageAnswerProps) => {
  const [errorFeedbackBorderColor, correctFeedbackBorderColor] = useToken('colors', [
    'indicator.red-dark',
    'green.500',
  ])

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageHttpLink = useGoogleStorageAsset(step.questionImage)

  useEffect(() => {
    if (
      step.questionImage &&
      imageHttpLink &&
      step.optionsData[0]?.imageRegion &&
      step.optionsData[0].imageRegion.length > 0 &&
      step.optionsData[0]
    ) {
      const fabricCanvas = new fabric.Canvas(canvasRef.current)

      fabricCanvas.setHeight(320)
      fabricCanvas.setWidth(480)
      loadImage(fabricCanvas, imageHttpLink)
      const poly = createPolygon(
        step.optionsData[0].imageRegion,
        correctFeedbackBorderColor
      )

      fabricCanvas.add(poly)

      fabricCanvas.wrapperEl.style.cursor = 'not-allowed'
      fabricCanvas.wrapperEl.style.pointerEvents = 'none'

      fabricCanvas.requestRenderAll()
    }
  }, [correctFeedbackBorderColor, errorFeedbackBorderColor, step, imageHttpLink])

  return (
    <Center>
      <canvas ref={canvasRef} id={step.id} width="480" height="320" />
    </Center>
  )
}
