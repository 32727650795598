import { Accordion, Box, Button, Center, Flex, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type Dispatch, type SetStateAction } from 'react'

import { CenteredSpinner } from '../../../components/centered-spinner'
import { type Filters, initialFilters, orderedFilterTypes } from '../shared/filters'
import { FilterSection } from './template-filters/filter-section'

interface TemplateFiltersProps {
  filters: Filters
  setFilters: Dispatch<SetStateAction<Filters>>
  hasFilters: boolean
  availableFilterOptions: Filters
  isLoading: boolean
}

export const TemplateFilters = ({
  filters,
  setFilters,
  hasFilters,
  availableFilterOptions,
  isLoading,
}: TemplateFiltersProps) => {
  const resetFilters = () => setFilters(initialFilters)

  const getContent = () => {
    if (isLoading) {
      return <CenteredSpinner />
    }

    return (
      <>
        <Text fontSize={18} fontWeight={600} mb={6}>
          <FormattedMessage id="coaching.program.templates.page.filters.title" />
        </Text>
        <Stack flex="1" overflowY="auto" pb={6} pe={6} gap={10}>
          <Accordion
            // defaultIndex array makes all the filter categories expanded by default
            defaultIndex={Array.from({ length: orderedFilterTypes.length }, (_, i) => i)}
            allowMultiple
            allowToggle
          >
            {orderedFilterTypes.map(filterType => {
              const filterValues = filters[filterType]

              if (availableFilterOptions[filterType].length === 0) {
                return null
              }

              return (
                <FilterSection
                  key={filterType}
                  onChange={value =>
                    setFilters(prevFilters => ({ ...prevFilters, [filterType]: value }))
                  }
                  value={filterValues}
                  title={
                    <>
                      <FormattedMessage
                        id="coaching.program.templates.page.filters.filterBy"
                        values={{
                          filterName: (
                            <Box as="span" textTransform="lowercase">
                              <FormattedMessage
                                id={
                                  `coaching.program.templates.${filterType}` satisfies I18nKey
                                }
                              />
                            </Box>
                          ),
                        }}
                      />
                      {!!filterValues.length && (
                        <span>{` (${filterValues.length})`}</span>
                      )}
                    </>
                  }
                  options={availableFilterOptions[filterType]}
                />
              )
            })}
          </Accordion>
        </Stack>
        {hasFilters && (
          <Center py={5} boxShadow="0px -3px 14px 0px rgba(0, 0, 0, 0.25)" ms={-6}>
            <Button
              color={colors.blue[500]}
              variant="ghost"
              size="md"
              onClick={resetFilters}
            >
              <FormattedMessage
                id="coaching.program.templates.page.filters.reset"
                values={{ n: Object.values(filters).flat().length }}
              />
            </Button>
          </Center>
        )}
      </>
    )
  }

  return (
    <Flex
      flexDir="column"
      pt={6}
      ps={6}
      overflow="hidden"
      borderRight="2px solid"
      borderColor={colors.gray[200]}
      width={340}
    >
      {getContent()}
    </Flex>
  )
}
