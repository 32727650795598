import { Box } from '@chakra-ui/react'
import { useToggle } from '@repo/utils'
import { type PropsWithChildren, type ReactNode } from 'react'

import { AnimatedNode } from './animated-node'
import { TreeToggle } from './tree-toggle'

const Element = (props: PropsWithChildren) => (
  <Box
    width="full"
    display="flex"
    p={1}
    borderTopLeftRadius="md"
    borderBottomLeftRadius="md"
    border="1px solid transparent"
    {...props}
  />
)

const LabelContainer = (props: PropsWithChildren) => (
  <Box
    flex={1}
    pl={1}
    // 24px is the width of the toggle icon
    maxWidth="calc(100% - 24px)"
    {...props}
  />
)

type NodeProps = {
  open?: boolean
  labelEl: ReactNode
}

export const Node = ({
  open = false,
  labelEl,
  children,
}: PropsWithChildren<NodeProps>) => {
  const [isOpen, toggle] = useToggle(open)

  return (
    <div>
      <Element>
        <TreeToggle onClick={toggle} isOpen={isOpen} isIconVisible={!!children} />
        <LabelContainer>{labelEl}</LabelContainer>
      </Element>
      <Box ml={2}>
        <AnimatedNode isOpen={isOpen}>{children}</AnimatedNode>
      </Box>
    </div>
  )
}
