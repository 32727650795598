import { HStack, Icon, MenuItem, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import moment from 'moment'
import { type ReactNode } from 'react'
import { BsDot } from 'react-icons/bs'

import { type UserNotificationsType } from '../../types/user-notifications'

export const NotificationMenuItem = ({
  notification,
  handleOnClick,
  renderShareProgramNotification,
}: {
  notification: UserNotificationsType
  handleOnClick: (notification: UserNotificationsType) => void
  renderShareProgramNotification?: (notification: UserNotificationsType) => ReactNode
}) => {
  const isShareProgramSuccess = notification.notificationType === 'shareProgramSuccess'

  return (
    <MenuItem
      justifyContent="flex-start"
      as="div"
      sx={{ cursor: 'pointer' }}
      key={notification.extId}
      onClick={() => handleOnClick(notification)}
      _focus={{
        bg: 'unset',
        '> div': {
          bg: 'gray.100',
        },
      }}
    >
      <VStack
        mb={1}
        p={2}
        mx={1}
        borderRadius="md"
        bgColor={notification.isViewed ? '' : 'secondary-light'}
        alignItems="flex-start"
        width="full"
      >
        <HStack spacing="2px">
          {!notification.isViewed && <Icon as={BsDot} color="red" boxSize={6} />}
          <Text pl={notification.isViewed ? 2 : ''} fontSize="sm" color="gray.500">
            {moment(notification.createdAt).format('MMM DD [at] h:mm a')}
          </Text>
        </HStack>
        <VStack pl={2} alignItems="flex-start">
          {isShareProgramSuccess && notification.payload.organizationName && (
            <Text fontWeight="bold">{notification.payload.organizationName}</Text>
          )}
          <Text>
            <FormattedMessage
              id={notification.messageKey as I18nKey}
              values={notification.payload}
            />
          </Text>
          {isShareProgramSuccess && notification.payload.programName && (
            <Text pt={2} fontWeight="bold">
              {notification.payload.programName}
            </Text>
          )}
        </VStack>
        {isShareProgramSuccess && renderShareProgramNotification?.(notification)}
      </VStack>
    </MenuItem>
  )
}
