import { pick } from 'lodash-es'

import { api } from '../../../utils/api'
import * as actions from './private/actions'

export const fetchQuestionnaires = () => async dispatch => {
  const questionnaires = await api.questionnaires.getAll()

  dispatch(actions.receiveQuestionnaires(questionnaires))
}

export const fetchQuestionnaire = id => async dispatch => {
  try {
    const questionnaire = await api.questionnaires.get(id)
    const questions = await api.questionnaires.getQuestions(id)

    dispatch(actions.receiveQuestionnaire(questionnaire, questions))
  } catch (err) {
    console.error(err)
  }
}

const extractQuestionnaireData = formData => pick(formData, ['introduction', 'name'])
const extractQuestionsData = formData =>
  (pick(formData, ['questions']).questions || []).map((question, index) => ({
    ...question,
    order: index,
    repeatable: parseInt(question.repeatable, 10),
    timeLimit: parseInt(question.timeLimit, 10),
  }))

export const saveQuestionnaire = (questionnaire, formData) => async dispatch => {
  // eslint-disable-next-line no-useless-catch
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const extractedData: any = extractQuestionnaireData(formData)
    const questions = extractQuestionsData(formData)

    extractedData.questions = questions

    let updatedQuestionnaire

    if (questionnaire) {
      updatedQuestionnaire = await api.questionnaires.update(
        questionnaire.id,
        extractedData
      )
    } else {
      updatedQuestionnaire = await api.questionnaires.create(extractedData)
    }

    dispatch(actions.updateQuestionnaire(updatedQuestionnaire))

    return updatedQuestionnaire
  } catch (error) {
    // TODO: error handling?
    // We need to rethrow here to display the error in questionnaire form
    throw error
  }
}
