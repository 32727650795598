/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { FormikFormField } from '@repo/ui'
import { Form } from 'formik'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import * as yup from 'yup'

import { useFormatWithInterpolation } from '../../components/use-format-with-interpolation'
import { AttributeField } from './attribute-field'

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .required(<FormattedMessage id="benchmarkProfile.editor.error.title.required" />),
})

const getRangeThreeSegments = value => {
  const activeSegment = Math.floor((value - value * Number.EPSILON) * 3)
  const segments = ['low', 'medium', 'high']

  return segments[activeSegment]
}

const getRangeFiveSegments = value => {
  const activeSegment = Math.floor((value - value * Number.EPSILON) * 5)
  const segments = ['low', 'mediumLow', 'medium', 'mediumHigh', 'high']

  return segments[activeSegment] as any
}

const ProfileField = ({
  id,
  onValueChange,
  onWeightChange,
  fieldValue,
  weightValue,
  validateWeigthField,
  category,
  explanations,
  hasScores,
  expertMode,
}: any) => {
  const formatWithInterpolation = useFormatWithInterpolation()

  const range =
    category === 'big5'
      ? getRangeFiveSegments(fieldValue)
      : getRangeThreeSegments(fieldValue)

  const title = formatWithInterpolation(explanations.setup.title)
  const description = formatWithInterpolation(explanations.setup.description)
  const adjectives = explanations.setup[range].adjectives
    .reduce((acc, adjectivId) => acc.concat(formatWithInterpolation(adjectivId)), [])
    .join(', ')

  const details = formatWithInterpolation(explanations.setup[range].description)

  return (
    <AttributeField
      id={id}
      label={title}
      minFieldValue={fieldValue}
      weightValue={weightValue}
      onValueChange={val => onValueChange(id, val)}
      onWeightChange={weight => onWeightChange(id, weight)}
      description={description}
      adjectives={adjectives}
      details={details}
      hasScores={hasScores}
      expertMode={expertMode}
      validateWeigthField={weight => validateWeigthField(id, weight)}
    />
  )
}

ProfileField.defaultProps = {
  onValueChange: Function.prototype,
  onWeightChange: Function.prototype,
}

ProfileField.propTypes = {
  id: PropTypes.string.isRequired,
  onValueChange: PropTypes.func,
  onWeightChange: PropTypes.func,
  fieldValue: PropTypes.number,
  weightValue: PropTypes.node,
  validateWeigthField: PropTypes.func,
  category: PropTypes.string.isRequired,
  explanations: PropTypes.object.isRequired,
  hasScores: PropTypes.bool.isRequired,
  expertMode: PropTypes.bool.isRequired,
}

export const ProfileForm = ({
  categories,
  isSubmitting,
  onCancel,
  setFieldValue,
  values: allValues,
  hasScores,
  expertMode,
}) => {
  const { values, scores } = allValues
  const adjustableCategories = categories.filter(category => category.id !== 'language')
  const { formatMessage } = useIntl()

  const handleValueUpdate = useCallback(
    (id, val) => setFieldValue(`values.${id}`, val, false),
    []
  )

  const handleWeightUpdate = useCallback(
    (id, val) => setFieldValue(`scores.${id}`, val, false),
    []
  )

  const [hasInvalidScores, setHasInvalidScores] = useState(false)
  const validateWeigthField = useCallback((id, score) => {
    // allow number between 0 and 1 with max two decimal places
    const regex = /^[0-1](\.0{1,2})?$|^0\.[0-9]{1,2}$/
    const isValidFormat = score.match(regex)
    const weight = parseFloat(score)
    const isValidNumber = weight >= 0 && weight <= 1
    const isValid = isValidFormat && isValidNumber

    setHasInvalidScores(!isValid)
    if (isValid) {
      setFieldValue(`scores.${id}`, weight, false)
    }

    return isValid
  }, [])

  return (
    <Form>
      <FormikFormField
        name="title"
        label={formatMessage({
          id: 'common.name',
        })}
      />
      {adjustableCategories.map(({ fields, id: categoryId }) => (
        <Box py={4} key={categoryId}>
          <VStack mb={8} align="flex-start">
            <Heading as="h6" color="gray.600" margin="3px" size="xs">
              <FormattedMessage
                id={`benchmarkProfile.category.${categoryId}` as I18nKey}
              />
            </Heading>
            <Text>
              <FormattedMessage
                id={`benchmarkProfile.category.${categoryId}.description` as I18nKey}
                values={{ br: <br /> }}
              />
            </Text>
          </VStack>
          <SimpleGrid columns={2} spacing={10}>
            {fields.map(field => (
              <ProfileField
                key={field.id}
                {...field}
                category={categoryId}
                fieldValue={values[field.id]}
                weightValue={scores ? scores[field.id] : null}
                onValueChange={handleValueUpdate}
                onWeightChange={handleWeightUpdate}
                validateWeigthField={validateWeigthField}
                hasScores={hasScores}
                expertMode={expertMode}
              />
            ))}
          </SimpleGrid>
        </Box>
      ))}

      <ButtonGroup float="right" pb={8}>
        <Button variant="outline" onClick={onCancel}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button type="submit" isDisabled={isSubmitting || hasInvalidScores}>
          <FormattedMessage
            id={`benchmarkProfile.editor.${isSubmitting ? 'saving' : 'save'}`}
          />
        </Button>
      </ButtonGroup>
    </Form>
  )
}

ProfileForm.defaultProps = {
  onCancel: Function.prototype,
}

ProfileForm.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }).isRequired
      ).isRequired,
    })
  ).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    scores: PropTypes.object,
  }).isRequired,
  hasScores: PropTypes.bool.isRequired,
  expertMode: PropTypes.bool.isRequired,
}
