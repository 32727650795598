import { useState } from 'react'

import { ContentLoader } from '../../../builder/components/content-loader'
import { useBenchmarkRecordings } from '../../hooks/use-benchmark-recordings'
import { IntroScreen } from './intro-screen'
import { JumpToRecordingSelectionScreen } from './jump-to-recording-selection-screen'

type IntroScreenWrapperProps = {
  handleContinue: VoidFunction
  handleCancel: VoidFunction
}

export const IntroScreenWrapper = ({
  handleContinue,
  handleCancel,
}: IntroScreenWrapperProps) => {
  const [showJumpToRecordingSelectionScreen, setShowJumpToRecordingSelectionScreen] =
    useState(false)

  const { data: recordings, status } = useBenchmarkRecordings()
  const hasBenchmarkingSessions = (recordings?.length || 0) > 0

  return showJumpToRecordingSelectionScreen ? (
    <JumpToRecordingSelectionScreen handleContinue={handleContinue} />
  ) : (
    <ContentLoader status={status}>
      <IntroScreen
        handleCancel={handleCancel}
        handleContinue={
          hasBenchmarkingSessions
            ? () => setShowJumpToRecordingSelectionScreen(true)
            : handleContinue
        }
      />
    </ContentLoader>
  )
}
