import { useCallback, useEffect, useState } from 'react'

export const useToggle = (initialValue = false): [boolean, () => void] => {
  const [isOpen, setIsOpen] = useState(initialValue)

  const toggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  useEffect(() => {
    setIsOpen(initialValue)
  }, [initialValue])

  return [isOpen, toggle]
}
