import { Input, type InputProps } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { useFormikContext } from 'formik'
import { memo, useMemo } from 'react'

import { FormControlInputField } from '../form/form-control-input-field'

export type RequestPasswordFormValue = {
  email: string
}

type RequestPasswordFormProps = Pick<InputProps, 'size'>

export const RequestPasswordForm = memo(({ size }: RequestPasswordFormProps) => {
  const { formatMessage } = useIntl()

  const { isSubmitting, touched, errors } = useFormikContext<RequestPasswordFormValue>()

  const emailInputProps = useMemo<InputProps>(
    () => ({
      size,
      type: 'email',
      placeholder: formatMessage({ id: 'login.form.field.email.placeholder' }),
      isDisabled: isSubmitting,
      isRequired: true,
      autoFocus: true,
      variant: 'filled',
    }),
    [size, formatMessage, isSubmitting]
  )

  return (
    <FormControlInputField<RequestPasswordFormValue>
      errors={errors}
      touched={touched}
      inputType={Input}
      errorMessageKey={errors.email}
      prop="email"
      label={formatMessage({ id: 'login.form.field.email.label' })}
      inputProps={emailInputProps}
    />
  )
})
