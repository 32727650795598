import { Box, Image } from '@chakra-ui/react'

import { type RecordingSession } from '../../recruiting/recording-sessions-api'
import { getPreviewUrl } from '../../utils/recordings'

type ListViewRowPreviewProps = {
  session: RecordingSession
}

export const ListViewRowPreview = ({ session }: ListViewRowPreviewProps) => (
  <Box>
    <Image
      boxSize={20}
      minW={20}
      borderRadius="full"
      objectFit="cover"
      src={getPreviewUrl(session)}
      alt="Recording preview"
    />
  </Box>
)
