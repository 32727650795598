import { Button, useDisclosure } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { MdDashboardCustomize } from 'react-icons/md'

import { useDashboardContext } from '../shared/dashboard-context'
import { CustomizeDashboardModal } from './customize-dashboard-button/customize-dashboard-modal'

export const CustomizeDashboardButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { pageState } = useDashboardContext()

  return (
    <>
      <Button
        isDisabled={pageState !== 'loaded'}
        onClick={onOpen}
        leftIcon={<MdDashboardCustomize />}
      >
        <FormattedMessage id="dashboard.page.customize" />
      </Button>
      <CustomizeDashboardModal onClose={onClose} isOpen={isOpen} />
    </>
  )
}
