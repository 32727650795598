import types from './private/actions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: Array<any> = []

// eslint-disable-next-line import/no-default-export, @typescript-eslint/no-explicit-any
export default (state = initialState, { type, data }: any = {}) => {
  switch (type) {
    case types.CREATE_BENCHMARK_PROFILE_SUCCESS:
      return [...state, data]
    case types.RECEIVE_BENCHMARK_PROFILES:
      return [...data]
    case types.UPDATE_BENCHMARK_PROFILE_SUCCESS: {
      const newList = [...state]
      const index = newList.findIndex(({ id }) => id === data.id)

      newList.splice(index, 1, data)

      return newList
    }

    default:
      return state
  }
}
