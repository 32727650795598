import { FormattedMessage } from '@repo/i18n'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'

import { AdvancedTable } from '../../../components/advanced-table'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

type TableRow = {
  id: string
  cohortName: string
  videoRecordedNumber: number
}

const columnHelper = createColumnHelper<TableRow>()
const columns = [
  columnHelper.accessor('cohortName', {
    header: () => (
      <FormattedMessage id="dashboard.cards.traineeCount.details.tab.cohortName" />
    ),
  }),
  columnHelper.accessor('videoRecordedNumber', {
    header: () => (
      <FormattedMessage id="dashboard.cards.recordingCount.details.tab.videoRecordedNumber" />
    ),
    meta: {
      align: 'right',
    },
  }),
]

export const RecordingCountCardDetails = () => {
  const { dataSlot } = useDashboardCardData('recordingCount')

  const tableRows = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    return data.cohortNames.map((cohortName, index) => ({
      id: data.cohortExtIds[index]!,
      cohortName,
      videoRecordedNumber: data.numRecordingsAcc[index]!,
    }))
  }, [dataSlot])

  return (
    <DetailPageWrapper cardId="recordingCount">
      <AdvancedTable columns={columns} data={tableRows} />
    </DetailPageWrapper>
  )
}
