import { useIntl } from '@repo/i18n'

export type FilterItemValue =
  | 'top-10'
  | 'top-20'
  | 'top-30'
  | 'all'
  | 'retorio-recommended'
  | 'retorio-medium-relevance'
  | 'retorio-low-relevance'

type FilterSelectItemData = {
  text: string
  selectedText: string
  value: FilterItemValue
}

type FilterSelectGroupData = {
  title?: string
  items: Array<FilterSelectItemData>
}

type FilterSelectData = Array<FilterSelectGroupData>

type FilterSelectDictionary = {
  [key in FilterItemValue]: Omit<FilterSelectItemData, 'value'>
}

export const makeFilterSelectDictionaryData = (
  data: FilterSelectData
): FilterSelectDictionary =>
  data
    .flatMap(g => g.items)
    .reduce(
      (acc, { value, ...item }) => ({ ...acc, [value]: item }),
      {} as FilterSelectDictionary
    )

export const useRadioSelectData = (): FilterSelectData => {
  const { formatMessage } = useIntl()

  return [
    {
      title: formatMessage({ id: 'recording.list.filters.title.top-candidate' }),
      items: [
        {
          text: '10',
          selectedText: formatMessage({ id: 'recording.list.filters.top-10' }),
          value: 'top-10',
        },
        {
          text: '20',
          selectedText: formatMessage({ id: 'recording.list.filters.top-20' }),
          value: 'top-20',
        },
        {
          text: '30',
          selectedText: formatMessage({ id: 'recording.list.filters.top-30' }),
          value: 'top-30',
        },
        {
          text: formatMessage({ id: 'recording.list.filters.all' }),
          selectedText: formatMessage({ id: 'recording.list.filters.all' }),
          value: 'all',
        },
      ],
    },
  ]
}
