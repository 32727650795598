import { Box, HStack, Icon, type StackProps } from '@chakra-ui/react'
import { type ReactNode } from 'react'
import { type IconType } from 'react-icons'

export type PillTagProps = {
  icon?: IconType
  sx?: StackProps['sx']
  cut?: 'left' | 'right'
  text: ReactNode
}

export const PillTag = ({ text, icon, cut, sx }: PillTagProps) => (
  <HStack
    width="fit-content"
    height={8}
    px={4}
    borderRadius="full"
    borderEndRadius={cut === 'right' ? 0 : 'full'}
    borderStartRadius={cut === 'left' ? 0 : 'full'}
    shadow="md"
    sx={sx}
  >
    {icon && <Icon fontSize={20} as={icon} />}
    <Box fontWeight={500}>{text}</Box>
  </HStack>
)
