import { Badge, Center, HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { useState } from 'react'
import { BsPatchCheckFill } from 'react-icons/bs'

import { CardGrid } from '../../../../components/card-grid'
import { type UseCase } from '../../shared/filters'
import { type ProgramTemplate } from '../../shared/types'
import { ProgramTemplateModal } from './program-template-card/program-template-modal'
import { templateTagVariants } from './program-template-card/template-tag-variants'

interface ProgramTemplateCardProps {
  template: ProgramTemplate
}

export const ProgramTemplateCard = ({ template }: ProgramTemplateCardProps) => {
  const [showModal, setShowModal] = useState(false)

  const {
    program: { imageUrl, description, title },
    tag,
    useCases,
    submittedSessionsCount,
  } = template

  return (
    <>
      <CardGrid.Card
        image={imageUrl ?? undefined}
        title={title}
        onClick={() => setShowModal(true)}
        tag={
          tag
            ? {
                icon: templateTagVariants[tag].icon,
                sx: {
                  bgColor: templateTagVariants[tag].color,
                  pos: 'absolute',
                  top: 4,
                  right: 0,
                },
                text: <FormattedMessage id={templateTagVariants[tag].labelKey} />,
                cut: 'right',
              }
            : undefined
        }
        footer={{
          type: 'custom',
          content: submittedSessionsCount >= 100 && (
            <Center
              mt={10}
              lineHeight={1}
              fontWeight={500}
              color={colors.gray[300]}
              gap={1}
              pb={4}
            >
              <Icon as={BsPatchCheckFill} />
              <FormattedMessage
                id="coaching.program.templates.card.peopleTrained"
                values={{ n: submittedSessionsCount }}
              />
            </Center>
          ),
        }}
      >
        <Stack gap={3} h="full" justify="space-between">
          <Text noOfLines={2} fontSize={14}>
            {description}
          </Text>

          <Stack gap={3}>
            <Text fontWeight={500} fontSize={14} textTransform="uppercase">
              <FormattedMessage id="coaching.program.templates.useCases" />
            </Text>

            <HStack wrap="wrap">
              {useCases.length ? (
                (useCases as Array<UseCase>).map(useCase => (
                  <Badge key={useCase} colorScheme="blue">
                    <FormattedMessage
                      id={
                        `coaching.program.templates.useCases.${useCase}` satisfies I18nKey
                      }
                    />
                  </Badge>
                ))
              ) : (
                <Badge colorScheme="blue">
                  <FormattedMessage id="coaching.program.templates.suitableForAll" />
                </Badge>
              )}
            </HStack>
          </Stack>
        </Stack>
      </CardGrid.Card>
      {showModal && (
        <ProgramTemplateModal onClose={() => setShowModal(false)} template={template} />
      )}
    </>
  )
}
