import { Box, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

import { type ProgramTemplate } from '../../../../shared/types'
import { TrainingSection } from './what-is-included-section/training-section'

export const WhatIsIncludedSection = ({
  trainings,
}: {
  trainings: ProgramTemplate['program']['trainings']
}) => (
  <Box>
    <Text mb={10} fontSize={18} fontWeight={500} textTransform="uppercase">
      <FormattedMessage id="coaching.program.templates.modal.trainingsTitle" />
    </Text>
    <Stack spacing={10}>
      {trainings.map(training => (
        <TrainingSection key={training.extId} training={training} />
      ))}
    </Stack>
  </Box>
)
