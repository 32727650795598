import {
  type DragSourceHookSpec,
  type DropTargetHookSpec,
  useDrag,
  useDrop,
} from 'react-dnd'

import { type Folder } from '../../store/entities/folders/folders-types'
import { type RecordingSession } from '../recording-sessions-api'

interface DraggableTypes {
  folder: 'folder'
  recording: 'recording'
}

type DroppableFolder = {
  folder: Folder
  type: DraggableTypes['folder']
}

export type DroppableRecording = {
  session: RecordingSession
  type: DraggableTypes['recording']
  onAfterMove?: () => void
}

export type DroppableObject = DroppableFolder | DroppableRecording

export const isDroppableFolder = (o: DroppableObject): o is DroppableFolder =>
  o.type === 'folder'

export const isDroppableRecording = (o: DroppableObject): o is DroppableRecording =>
  o.type === 'recording'

// ===============================================

export type DropStatus = 'idle' | 'accept-drop' | 'will-drop' | 'wont-drop'

export const canDropItem =
  (isOwner: boolean, folder: Folder) =>
  (item: DroppableObject): boolean => {
    if (!isOwner) {
      return false
    }

    if (isDroppableFolder(item)) {
      return folder.path.indexOf(item.folder.path) !== 0
    }

    if (isDroppableRecording(item)) {
      const sameTags = item.session.tags.every(
        (tag: string) => folder.path.indexOf(tag) !== -1
      )

      const sameFolder =
        sameTags && item.session.tags.length === folder.path.split('.').length

      return !sameFolder
    }

    return true
  }

const getDropStatus = (canDrop: boolean, isOver: boolean): DropStatus => {
  if (canDrop && !isOver) {
    return 'accept-drop'
  }

  if (canDrop && isOver) {
    return 'will-drop'
  }

  if (!canDrop && isOver) {
    return 'wont-drop'
  }

  return 'idle'
}

type DroppableCollectedProps = {
  dropStatus: DropStatus
}

type UseDroppableParams = Pick<
  DropTargetHookSpec<DroppableObject, unknown, DroppableCollectedProps>,
  'canDrop' | 'drop'
>

export const useDroppable = (params: UseDroppableParams) => {
  const { canDrop, drop } = params

  return useDrop<DroppableObject, unknown, DroppableCollectedProps>({
    accept: ['folder', 'recording'] satisfies Array<keyof DraggableTypes>,
    canDrop,
    drop,
    collect: monitor => ({
      dropStatus: getDropStatus(monitor.canDrop(), monitor.isOver()),
    }),
  })
}

// ===============================================

type DraggableCollectedProps = {
  isDragging: boolean
}

type UseDraggableParams<T extends DroppableObject> = {
  item: T
} & Pick<DragSourceHookSpec<T, unknown, DraggableCollectedProps>, 'canDrag' | 'end'>

export const useDraggable = <T extends DroppableObject>(
  params: UseDraggableParams<T>
) => {
  const { canDrag, item, end } = params

  return useDrag<T, unknown, DraggableCollectedProps>({
    item,
    type: item.type,
    canDrag,
    end,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
}
