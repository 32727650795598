import { Button, HStack, Image, Select } from '@chakra-ui/react'
import { type SxProps } from '@mui/material'
import { AVAILABLE_PROGRAM_LANGUAGES, type ProgramLanguages } from '@repo/api'
import { FormattedMessage, languageLabels, useIntl } from '@repo/i18n'
import { colors, PageHeader, showToast } from '@repo/ui'
import { createColumnHelper, type RowSelectionState } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { RiAddFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { ConnectExerciseModal } from '../../builder/exercises/connect-exercise-modal'
import { AdvancedTable } from '../../components/advanced-table'
import { AppHeaderTitle } from '../../components/app-header-title'
import { PageList } from '../../containers/page-list'
import { type Exercise } from '../../types/api-types'
import { client } from '../../utils/openapi-client'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'
import { EnglishExercisesData } from './exercise-data/english-exercises-data'
import { GermanExercisesData } from './exercise-data/german-exercises-data'
import { ItalianExercisesData } from './exercise-data/italian-exercises-data'
import { PortugueseExercisesData } from './exercise-data/portuguese-exercise-data'
import { SpanishExercisesData } from './exercise-data/spanish-exercises-data'

const tableSx: SxProps = { minHeight: '90%' }
const columnHelper =
  createColumnHelper<Pick<Exercise, 'title' | 'type' | 'extId' | 'imageUrl'>>()

const columns = [
  columnHelper.display({
    id: 'imageUrl',
    header: () => <FormattedMessage id="coaching.exerciseList.table.image" />,
    cell: ({ row }) => <Image w="100px" src={row.original.imageUrl} />,
    enableSorting: false,
  }),
  columnHelper.accessor('title', {
    header: () => <FormattedMessage id="coaching.exerciseList.table.title" />,
  }),
  columnHelper.accessor('type', {
    header: () => <FormattedMessage id="coaching.exerciseList.table.variable" />,
    cell: ({ getValue }) => <FormattedMessage id={`exercise.type.${getValue()}`} />,
  }),
]

export const ImportExercises = () => {
  const { mutate } = useOpenapiSWR('getExercises')
  const navigate = useNavigate()
  const [exerciseData, setExerciseData] = useState(EnglishExercisesData)
  const [isOpenConnectToProgramModal, setIsOpenConnectToProgramModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [selectedLanguage, setSelectedLanguage] = useState<ProgramLanguages>('en-US')
  const { formatMessage } = useIntl()

  const selectionState = useState<RowSelectionState>({})
  const [rowSelection, setRowSelection] = selectionState
  const selectedExercises = Object.keys(rowSelection).filter(key => rowSelection[key])

  const selectLanguage = event => {
    switch (event.target.value as ProgramLanguages) {
      case 'en-US':
        setExerciseData(EnglishExercisesData)
        break
      case 'de-DE':
        setExerciseData(GermanExercisesData)
        break
      case 'es-ES':
        setExerciseData(SpanishExercisesData)
        break
      case 'it-IT':
        setExerciseData(ItalianExercisesData)
        break
      case 'pt-PT':
        setExerciseData(PortugueseExercisesData)
        break
      default:
        // If we select a language without data, we should clear the list
        setExerciseData([])
        break
    }

    setSelectedLanguage(event.target.value)
    setRowSelection({})
  }

  const selectedExercisesData = useMemo(
    () => exerciseData.filter(ex => selectedExercises.includes(ex.extId)),
    [exerciseData, selectedExercises]
  )

  const createExercises = async (programExtIds: Array<string>) => {
    try {
      setIsSubmitting(true)
      const responses = await Promise.all(
        selectedExercisesData.map(exercise =>
          client.post('createExercise', {
            body: { programExtIds, exerciseData: exercise },
          })
        )
      )

      if (responses.some(r => r.error)) {
        throw new Error()
      }

      await mutate()
      setIsOpenConnectToProgramModal(false)
      navigate('/exercises')
      showToast({ messageKey: 'common.alert.created', status: 'success' })
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <PageList display="flex" flexDir="column">
      <AppHeaderTitle formattedMessageId="coaching.exerciseList.title.import" />
      <PageHeader
        buttons={
          <HStack>
            <Select
              placeholder={formatMessage({ id: 'coaching.exercise.language.import' })}
              onChange={selectLanguage}
              value={selectedLanguage}
            >
              {AVAILABLE_PROGRAM_LANGUAGES.map(key => (
                <option key={key} value={key}>
                  {languageLabels[key]}
                </option>
              ))}
            </Select>
            <Button
              onClick={() => setIsOpenConnectToProgramModal(true)}
              leftIcon={<RiAddFill size="1.25em" />}
              iconSpacing="1"
              flexShrink={0}
              alignSelf="flex-end"
              isDisabled={!selectedExercises.length}
              _hover={{
                bgColor: colors['primary-dark'],
                color: colors.buttonColor,
              }}
            >
              <FormattedMessage id="coaching.exerciseList.button.add" />
            </Button>
          </HStack>
        }
      />
      <ConnectExerciseModal
        exercises={selectedExercisesData.map(e => ({
          ...e,
          programExtIds: [],
          createdAt: '',
          updatedAt: '',
        }))}
        onSubmit={createExercises}
        isOpen={isOpenConnectToProgramModal}
        onClose={() => setIsOpenConnectToProgramModal(false)}
        isSubmitting={isSubmitting}
      />
      <AdvancedTable
        data={exerciseData}
        columns={columns}
        sx={tableSx}
        selectionState={selectionState}
      />
    </PageList>
  )
}
