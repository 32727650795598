import { Center, HStack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'

import { AdvancedTable } from '../../../components/advanced-table'
import { useDashboardContext } from '../../shared/dashboard-context'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

type ScenarioData = {
  id: number
  scenarioName: string
  completions: number
  index: number
}

const columnHelper = createColumnHelper<ScenarioData>()

const columns = [
  columnHelper.accessor('scenarioName', {
    header: () => (
      <FormattedMessage id="dashboard.cards.popularScenarios.details.tab.scenarioName" />
    ),
    cell: props => (
      <HStack gap="12px">
        <Center
          fontSize={12}
          color="white"
          minW="20px"
          w="20px"
          h="20px"
          borderRadius="50%"
          bg={colors.blue[500]}
        >
          {props.row.original.index}
        </Center>
        <Text fontSize="14px">{props.getValue()}</Text>
      </HStack>
    ),
  }),
  columnHelper.accessor('completions', {
    header: () => (
      <FormattedMessage id="dashboard.cards.popularScenarios.details.tab.completions" />
    ),
    meta: {
      align: 'right',
    },
  }),
]

export const PopularScenariosCardDetails = () => {
  const { pageState } = useDashboardContext()
  const { dataSlot } = useDashboardCardData('popularScenarios')

  const mostPopularScenariosData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    const scenarioNameMap = Object.entries(data.scenariosMetadata).reduce(
      (map, [extId, scenarioData]) => {
        map[extId] = scenarioData.scenarioName

        return map
      },
      {}
    )

    return data.mostPopularScenariosOverall.map((scenario, index) => ({
      id: index,
      scenarioName: scenarioNameMap[scenario.scenarioExtId],
      completions: scenario.count,
      index: index + 1,
    }))
  }, [dataSlot])

  return (
    <DetailPageWrapper cardId="popularScenarios">
      <AdvancedTable
        columns={columns}
        data={mostPopularScenariosData}
        isLoading={pageState === 'loading'}
      />
    </DetailPageWrapper>
  )
}
