const types = {
  CREATE_ROLE_REQUEST: 'roles/create_request',
  CREATE_ROLE_SUCCESS: 'roles/create_success',
  RECEIVE_ROLES: 'roles/receive-list',
  UPDATE_ROLE_REQUEST: 'roles/update_request',
  UPDATE_ROLE_SUCCESS: 'roles/update_success',
  DELETE_ROLE_REQUEST: 'roles/delete_request',
  DELETE_ROLE_SUCCESS: 'roles/delete_success',
}

// eslint-disable-next-line import/no-default-export
export default types

export const receiveRoles = roleList => ({
  type: types.RECEIVE_ROLES,
  data: roleList,
})

export const updateRequest = id => ({
  type: types.UPDATE_ROLE_REQUEST,
  data: id,
})

export const updateSuccess = role => ({
  type: types.UPDATE_ROLE_SUCCESS,
  data: role,
})

export const deleteRequest = id => ({
  type: types.DELETE_ROLE_REQUEST,
  data: id,
})

export const deleteSuccess = id => ({
  type: types.DELETE_ROLE_SUCCESS,
  data: id,
})

export const createRequest = () => ({ type: types.CREATE_ROLE_REQUEST })

export const createSuccess = role => ({
  type: types.CREATE_ROLE_SUCCESS,
  data: role,
})
