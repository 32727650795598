import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useModal } from '@repo/ui'
import { type ReactNode } from 'react'

import { GDPRForm } from './gdpr-form'
import { GeneralFormContainer } from './general-form'
import { NotificationsSettings } from './notifications-settings'

export const useOrganizationSettingsModal = () =>
  useModal(
    ({ closeModal }) => {
      const tabs: Array<{
        titleKey: I18nKey
        content: ReactNode
      }> = [
        {
          titleKey: 'organization.settings.general',
          content: <GeneralFormContainer onCancel={closeModal} />,
        },
        {
          titleKey: 'organization.settings.gdpr',
          content: <GDPRForm />,
        },
        {
          titleKey: 'organization.settings.notifications',
          content: <NotificationsSettings />,
        },
      ]

      return (
        <Flex flexGrow={1} overflowY="auto">
          <Tabs w="full" ml="4px" mt="4px">
            <TabList>
              {tabs.map(({ titleKey }) => (
                <Tab key={titleKey}>
                  <FormattedMessage id={titleKey} />
                </Tab>
              ))}
            </TabList>
            <TabPanels px={5} pb={10}>
              {tabs.map(({ titleKey, content }) => (
                <TabPanel key={titleKey}>{content}</TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Flex>
      )
    },
    {
      titleKey: 'organization.settings.title',
      modalProps: { size: '2xl', isCentered: false },
    }
  )
