import { createContext } from '@repo/utils'

import { type SortOptions } from '../../SortDirectionToggle'
import { type Sortable } from '../sortable'

type SortableCheckboxValue<T> = {
  item: T
  checked: boolean
  sortBy: SortOptions
}

export const makeSortableFromChecked = (
  checked: SortableCheckboxValue<string>
): Sortable => ({
  value: checked.item,
  sortBy: checked.sortBy,
})

export const makeSortableChecked = <T>(
  item: T,
  sortBy: SortOptions
): SortableCheckboxValue<T> => ({
  checked: true,
  item,
  sortBy,
})

export const makeSortableNotChecked = <T>(
  item: T,
  sortBy: SortOptions
): SortableCheckboxValue<T> => ({
  checked: false,
  item,
  sortBy,
})

export type CheckboxSortableSelectContext = {
  onItemChange: (v: SortableCheckboxValue<string>) => void
  sortableValues: Array<Sortable>
}

const [CheckboxSortableSelectProvider, useCheckboxSortableSelectContext] =
  createContext<CheckboxSortableSelectContext>({
    name: 'CheckboxSortableSelectContext',
    strict: true,
  })

export { CheckboxSortableSelectProvider, useCheckboxSortableSelectContext }
