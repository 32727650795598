import { css } from '@emotion/react'
import { showToast } from '@repo/ui'
import { type Undefined } from '@repo/utils'
import { Formik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { AppHeaderTitle } from '../../components/app-header-title'
import {
  fetchQuestionnaire,
  saveQuestionnaire,
} from '../../store/entities/questionnaires/effects'
import { makeSelectQuestionnaire } from '../../store/entities/questionnaires/selectors'
import { type SelectQuestionFn } from '../../store/entities/questionnaires/selectors-types'
import { wait } from '../../utils/wait'
import { PageList } from '../page-list'
import { QuestionnaireEditorForm } from './questionnaire-editor-form'
import { initialValues as initialQuestionsValues } from './questions-form'
import { initialValues as initialSettingsValues, validationSchema } from './settings-form'
import { type Question } from './types'

const initialValues = {
  ...initialSettingsValues,
  ...initialQuestionsValues,
}

const flexColumnCss = css`
  display: flex;
  flex-direction: column;
`

export const QuestionnaireEditor = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const selectQuestionnaire: SelectQuestionFn = useMemo(makeSelectQuestionnaire, [])
  const questionnaire: Undefined<Question> = useSelector(state =>
    selectQuestionnaire(state, id)
  )

  const location = useLocation()

  const dispatch = useDispatch()
  const isCreateMode = id === 'create'

  useEffect(() => {
    if (!isCreateMode && !questionnaire) {
      try {
        dispatch(fetchQuestionnaire(id))
      } catch (e) {
        showToast({ messageKey: 'questionnaire.error.fetching', status: 'success' })
      }
    }
  }, [dispatch, id, isCreateMode, questionnaire])

  const handleSubmitForm = async ({ questions, ...values }) => {
    const cleanQuestions = questions.map(
      ({ active: _redundantActive, type: _redundantType, ...question }) => question
    )

    try {
      const updatedQuestionnaire = await wait(
        dispatch(
          saveQuestionnaire(questionnaire, {
            ...values,
            questions: cleanQuestions,
          })
        ),
        1000
      )

      showToast({
        messageKey: 'questionnaire.editor.notification.success.save',
        status: 'success',
      })

      if (location?.state) {
        navigate(-1)
      }

      if (isCreateMode) {
        navigate(`/questionnaires/${updatedQuestionnaire.id}`, {
          replace: true,
          state: { updatedQuestionnaire: updatedQuestionnaire.id },
        })
      }
    } catch (formError) {
      showToast({
        messageKey: 'questionnaire.editor.notification.error.title',
        status: 'error',
      })
    }
  }

  const isFormReady = (!isCreateMode && !!questionnaire) || isCreateMode

  return (
    <PageList css={flexColumnCss} overflow="auto">
      <AppHeaderTitle formattedMessageId="questionnaire.editor.heading.title" />

      {isFormReady && (
        <Formik
          initialValues={{ ...initialValues, ...questionnaire }}
          onSubmit={handleSubmitForm}
          validateOnBlur={false}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {QuestionnaireEditorForm}
        </Formik>
      )}
    </PageList>
  )
}
