import { HStack, Icon, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type IconType } from 'react-icons'
import { NavLink, type NavLinkProps } from 'react-router-dom'

type SidebarItemProps = {
  icon: IconType
  labelKey: I18nKey
  to?: NavLinkProps['to']
  onClick?: () => void
}

export const SidebarItem = ({ to, icon, labelKey, onClick }: SidebarItemProps) => (
  <HStack
    as={to ? NavLink : undefined}
    to={to}
    onClick={
      onClick
        ? e => {
            e.preventDefault()
            onClick()
          }
        : undefined
    }
    sx={{
      '&.active, &:hover, &:focus': {
        backgroundColor: colors['secondary-light'],
        color: colors.blue[700],
      },
      px: 4,
      py: 2,
      borderRadius: '6px',
      alignItems: 'center',
      transition: 'all 0.2s',
      color: colors['secondary-light'],
    }}
  >
    <Icon as={icon} fontSize="20px" />
    <Text whiteSpace="pre-wrap" lineHeight={1}>
      <FormattedMessage id={labelKey} />
    </Text>
  </HStack>
)
