import { type ApiSchemas } from '@repo/api'
import { assertExists } from '@repo/utils'
import { BiText } from 'react-icons/bi'
import { FaVideo } from 'react-icons/fa'
import { IoIosList } from 'react-icons/io'
import { IoPersonSharp } from 'react-icons/io5'
import { PiChats } from 'react-icons/pi'
import { v4 as uuidv4 } from 'uuid'

import {
  type BasicStepFormFields,
  type BasicStepTypes,
  type CoachingStep,
  type QuestionAndAnswerStepFormFields,
  type QuizStepFormFields,
  type StepTypes,
} from '../../../../builder/steps/api'
import { type DataLoaderType } from '../../../../builder/use-data-loader'
import { getStepTypeForNewBuilder } from './get-step-type-for-new-builder'
import { type SectionItem } from './types'

export const toolboxItems: Array<SectionItem> = [
  {
    id: uuidv4(),
    title: 'customization.step.customQA.item.title',
    isCreated: false,
    icon: PiChats,
    type: 'coachingStepQA',
  },
  {
    id: uuidv4(),
    title: 'common.video',
    isCreated: false,
    icon: FaVideo,
    type: 'video',
  },
  {
    id: uuidv4(),
    title: 'common.text',
    isCreated: false,
    icon: BiText,
    type: 'text',
  },
  {
    id: uuidv4(),
    title: 'customization.step.knowledgeCheck.item.title',
    isCreated: false,
    icon: IoIosList,
    type: 'coachingStepQuiz',
  },
]

const stepProps: Record<
  | Extract<StepTypes, 'coachingStepQuiz' | 'coachingStepQA' | 'coachingStepRealtime'>
  | BasicStepTypes,
  Pick<SectionItem, 'title' | 'icon' | 'type'>
> = {
  video: {
    title: 'common.video',
    icon: FaVideo,
    type: 'video',
  },
  text: {
    title: 'common.text',
    icon: BiText,
    type: 'text',
  },
  coachingStepQuiz: {
    title: 'customization.step.knowledgeCheck.item.title',
    icon: IoIosList,
    type: 'coachingStepQuiz',
  },
  coachingStepQA: {
    title: 'customization.step.customQA.item.title',
    icon: PiChats,
    type: 'coachingStepQA',
  },
  coachingStepRealtime: {
    title: 'scenario.realtime.builder.title',
    icon: IoPersonSharp,
    type: 'coachingStepRealtime',
  },
}

export const getSectionStep = (
  step: DataLoaderType<CoachingStep>,
  missionGoalForRealtimeBuilder: string | null
): SectionItem => {
  const { data, uuid } = step

  const extraStepProps = stepProps[getStepTypeForNewBuilder(data)]

  assertExists(extraStepProps, 'extraStepProps')

  const isAISupportedQA =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data.step.stepType === 'coachingStepQA' && (data.step as any).type === 'aiSupported'

  const sectionStep = {
    id: uuid,
    isCreated: true,
    ...extraStepProps,
  }

  // TODO this is a temporary solution, we will to refactor this when we all types here from OpenAPI spec
  // also we should stop using the weird `getStepTypeForNewBuilder` logic
  if (isAISupportedQA) {
    return {
      ...sectionStep,
      ...{
        isEditingDisabled: true,
        description: (data.step as ApiSchemas['aiQaStepEntity']).questionTranscript ?? '',
        title: 'common.q&a',
      },
    }
  }

  if (data.step.stepType === 'coachingStepBasic' && data.step.type === 'text') {
    return {
      ...sectionStep,
      ...{
        description: data.step.heading ?? '',
      },
    }
  }

  if (data.step.stepType === 'coachingStepQuiz') {
    return {
      ...sectionStep,
      ...{
        description: data.step.questionText ?? '',
      },
    }
  }

  if (data.step.stepType === 'coachingStepRealtime') {
    return {
      ...sectionStep,
      description: missionGoalForRealtimeBuilder ?? '',
      isEditingDisabled: true,
    }
  }

  return sectionStep
}

// BasicStepFormFields
// QuestionAndAnswerStepFormFields
// QuizStepFormFields

export const getStepInitialData = (
  hasUnderstanding: boolean
): Record<
  | Extract<StepTypes, 'coachingStepQuiz' | 'coachingStepQA' | 'coachingStepRealtime'>
  | BasicStepTypes,
  object
> => ({
  video: {
    stepType: 'coachingStepBasic',
    type: 'video',
    videoStepUrl: '',
  } satisfies Partial<BasicStepFormFields>,
  text: {
    stepType: 'coachingStepBasic',
    type: 'text',
    heading: '',
    text: '',
  } satisfies Partial<BasicStepFormFields>,
  coachingStepQuiz: {
    stepType: 'coachingStepQuiz',
    questionType: 'text',
    quizType: 'singleChoice',
    questionText: '',
    optionsData: [
      { id: uuidv4(), isCorrect: true, text: '' },
      { id: uuidv4(), isCorrect: false, text: '' },
    ],
  } satisfies QuizStepFormFields,
  coachingStepQA: {
    stepType: 'coachingStepQA',
    maxRecordingTime: hasUnderstanding ? 60 : 300,
    answerUrl: '',
    questionUrl: '',
    dos: hasUnderstanding ? [{ extId: uuidv4(), instruction: '' }] : [],
    donts: [],
  } satisfies QuestionAndAnswerStepFormFields,
  coachingStepRealtime: {
    stepType: 'coachingStepRealtime' satisfies StepTypes,
    missionId: '',
  },
})
