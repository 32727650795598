import { Button, Center, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast, useModal } from '@repo/ui'
import { uniqueId } from 'lodash-es'
import { type SubmitHandler } from 'react-hook-form'

import { type Exercise } from '../../../types/api-types'
import { client } from '../../../utils/openapi-client'
import { useOpenapiSWR } from '../../../utils/use-openapi-swr'
import { type ExerciseFormValues } from '../../exercise-form-values'
import { useExerciseForm } from './use-exercise-form'

const EditExerciseForm = ({
  closeModal,
  exercise,
}: {
  closeModal: () => void
  exercise: Exercise
}) => {
  const [form, formFieldsJsx] = useExerciseForm({
    ...exercise,
    preparationItems: exercise.preparationItems.map(({ text }) => ({
      text,
      id: uniqueId(),
    })),
  })

  const { handleSubmit, formState } = form
  const { mutate } = useOpenapiSWR('getExercises')

  const onSubmit: SubmitHandler<ExerciseFormValues> = async data => {
    try {
      const { data: updatedExercise } = await client.put('updateExercise', {
        body: { ...data, durationInMinutes: Number(data.durationInMinutes) },
        params: { path: { extId: exercise.extId } },
      })

      if (!updatedExercise) {
        throw new Error()
      }

      closeModal()
      showToast({ messageKey: 'common.alert.saved', status: 'success' })
      mutate(
        prev =>
          prev?.map(e => (e.extId === exercise.extId ? { ...e, ...updatedExercise } : e)),
        false
      )
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
      {formFieldsJsx}

      <Center gap={3} mt={6}>
        <Button variant="outline" onClick={closeModal}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          type="submit"
          isDisabled={
            !formState.isValid || !formState.isDirty || !!formState.errors.imageUrl
          }
          isLoading={formState.isSubmitting}
        >
          <FormattedMessage id="common.save" />
        </Button>
      </Center>
    </Stack>
  )
}

export const useEditExerciseModal = (exercise: Exercise) => {
  const modal = useModal(
    ({ closeModal }) => <EditExerciseForm exercise={exercise} closeModal={closeModal} />,
    {
      titleKey: 'exercise.editor.title.default',
      modalProps: { size: '2xl' },
    }
  )

  return modal
}
