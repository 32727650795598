import {
  Box,
  HStack,
  useRadio,
  useRadioGroup,
  type UseRadioProps,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type PropsWithChildren } from 'react'

import { type DataSlotType } from '../../../../shared/types'

const CustomRadioButton = ({ children, ...rest }: PropsWithChildren<UseRadioProps>) => {
  const { getInputProps, getRadioProps } = useRadio(rest)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as="label" maxW="45%">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...input} hidden />
      <Box
        {...checkbox}
        textAlign="center"
        cursor="pointer"
        borderRadius="full"
        _checked={{
          bg: colors.blue[500],
          color: 'white',
        }}
        px="16px"
        py="4px"
        fontSize="14px"
        fontWeight={500}
      >
        {children}
      </Box>
    </Box>
  )
}

const timeRanges: Array<{ labelKey: I18nKey; value: DataSlotType }> = [
  { value: 'lastWeek', labelKey: 'dashboard.page.timeRanges.lastWeek' },
  { value: 'lastMonth', labelKey: 'dashboard.page.timeRanges.lastMonth' },
  { value: 'custom', labelKey: 'dashboard.page.timeRanges.custom' },
]

export const useDashboardDataSlotSelector = ({
  onChange,
  value,
}: {
  onChange: (value: DataSlotType) => void
  value: DataSlotType
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'timeRanges',
    value,
    onChange,
  })

  const group = getRootProps()

  const dataSlotSelector = (
    <HStack {...group} mb={6}>
      {timeRanges.map(option => (
        <CustomRadioButton key={option.value} {...getRadioProps({ value: option.value })}>
          <FormattedMessage id={option.labelKey} />
        </CustomRadioButton>
      ))}
    </HStack>
  )

  return dataSlotSelector
}
