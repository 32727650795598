import { Icon, IconButton, type IconButtonProps } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { MdOutlineDragIndicator } from 'react-icons/md'

export const DragHandle = (props: Partial<IconButtonProps>) => (
  <IconButton
    aria-label="Drag handle"
    icon={<Icon as={MdOutlineDragIndicator} width={4} height={4} />}
    variant="ghost"
    colorScheme="gray"
    color={colors.gray[400]}
    cursor="grab"
    {...props}
  />
)
