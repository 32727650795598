import { Box, Button, Flex, HStack, Icon, Tooltip, useDisclosure } from '@chakra-ui/react'
import { type SxProps } from '@mui/material'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, showToast } from '@repo/ui'
import { assertExists } from '@repo/utils'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { RiAddFill, RiErrorWarningLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom'

import { ContentLoader } from '../../../builder/components/content-loader'
import { AdvancedTable } from '../../../components/advanced-table'
import { formatDateTime, getDateDifferenceInHours } from '../../../utils/dates'
import { isInvitationExpired } from '../../helpers/utils'
import {
  type EmailParticipant,
  type EmailParticipantStatus,
} from '../../types/cohort-email-participants'
import { useCohortParticipants } from '../../use-cohort-participants'
import { useCohorts } from '../../use-cohorts'
import { useParticipants } from '../../use-participants'
import { CohortParticipantRowMenu } from '../cohort-participant-row-menu'
import { EmailParticipantsModal } from './components/email-participants-modal'
import { ResendEmailInvitationsModal } from './components/resend-email-invitations-modal'

const tableSx: SxProps = { minHeight: '90%' }

interface KeyAndColor {
  messageKey: I18nKey
  statusColor: string
}
const categorizeInvitedEmailUsers = (users?: Array<EmailParticipant>) => {
  const invitedLessThen1DayAgo: Array<string> = []
  const invitedMoreThen1DayAgo: Array<string> = []

  users?.forEach(({ lastInvitedAt, state, email, isDisabled }) => {
    if (state !== 'INVITED') {
      return
    }

    const isMoreThen1DayAgo =
      getDateDifferenceInHours(new Date(), new Date(lastInvitedAt)) > 24

    if (isMoreThen1DayAgo && !isDisabled) {
      return invitedMoreThen1DayAgo.push(email)
    }

    invitedLessThen1DayAgo.push(email)
  })

  return { invitedLessThen1DayAgo, invitedMoreThen1DayAgo }
}

const getMessageKeyAndColor = (
  state: EmailParticipantStatus,
  isDisabledUser: boolean,
  lastInvitedDate: string
): KeyAndColor => {
  if (state === 'ACTIVATED' && !isDisabledUser) {
    return {
      messageKey: 'cohort.creation.email.participant.row.activated.status',
      statusColor: colors.green[500],
    }
  }

  if (state === 'REMOVED') {
    return {
      messageKey: 'cohort.creation.email.participant.row.removed.status',
      statusColor: colors.red[500],
    }
  }

  if (isDisabledUser) {
    return {
      messageKey: 'cohort.creation.email.participant.row.disabled.status',
      statusColor: colors.yellow[500],
    }
  }

  if (isInvitationExpired(lastInvitedDate)) {
    return {
      messageKey: 'cohort.creation.email.participant.row.invitation.expired.status',
      statusColor: colors.blue[500],
    }
  }

  return {
    messageKey: 'cohort.creation.email.participant.row.not.activated.status',
    statusColor: colors.gray[500],
  }
}

export const CohortEmailParticipants = () => {
  const { formatMessage } = useIntl()
  const { id: cohortId } = useParams<{ id: string }>()
  const { useEmailParticipantsList } = useCohortParticipants()
  const { removeUsers } = useParticipants()

  const {
    isOpen: isAddModalOpen,
    onOpen: onAddModalOpen,
    onClose: onAddModalClose,
  } = useDisclosure()

  const {
    isOpen: isResendModalOpen,
    onOpen: onResendModalOpen,
    onClose: onResendModalClose,
  } = useDisclosure()

  assertExists(cohortId, 'cohortId')

  const {
    data,
    status,
    refresh: refreshEmailParticipantsList,
  } = useEmailParticipantsList(cohortId)

  const { removeParticipants, updateParticipantsActivationStatus, addEmails } =
    useCohortParticipants()

  const { useCohort } = useCohorts()
  const { data: cohortData, refresh: refreshCohortDetails } = useCohort(cohortId)
  const isTokenCohort = cohortData?.type === 'token'

  const deleteParticipant = useMemo(
    () => async (userId: string) => {
      try {
        await removeUsers([userId])
        refreshEmailParticipantsList()
        refreshCohortDetails()
        showToast({ messageKey: 'common.alert.deleted', status: 'success' })
      } catch (err) {
        showToast({ messageKey: 'general.error.deleting.data', status: 'error' })
      }
    },
    [removeUsers, refreshEmailParticipantsList, refreshCohortDetails]
  )

  const removeParticipantFromCohort = useMemo(
    () => async (userIds: Array<string>, cohortExtId: string) => {
      try {
        await removeParticipants(cohortExtId, userIds)
        refreshEmailParticipantsList()
        refreshCohortDetails()
        showToast({ messageKey: 'common.alert.deleted', status: 'success' })
      } catch (err) {
        showToast({ messageKey: 'general.error.deleting.data', status: 'error' })
      }
    },
    [removeParticipants, refreshEmailParticipantsList, refreshCohortDetails]
  )

  const { invitedLessThen1DayAgo, invitedMoreThen1DayAgo } = useMemo(
    () => categorizeInvitedEmailUsers(data),
    [data]
  )

  const disabledResendEmailTooltip = useMemo<I18nKey | undefined>(() => {
    const allUsersAreActivated = !data?.some(({ state }) => state === 'INVITED')

    if (allUsersAreActivated) {
      return 'cohort.creation.email.participants.activated.participants.tooltip.message'
    }

    if (invitedMoreThen1DayAgo.length === 0) {
      return 'cohort.creation.email.participants.invitations.recently.sent.tooltip.message'
    }
  }, [data, invitedMoreThen1DayAgo])

  const updateParticipantsStatus = useMemo(
    () => async (userId: string, isDisabled: boolean) => {
      try {
        await updateParticipantsActivationStatus(cohortId, userId, isDisabled)
        refreshEmailParticipantsList()
        refreshCohortDetails()
        showToast({
          messageKey: 'cohort.participant.updateStatus.confirmModal.success',
          status: 'success',
        })
      } catch (err) {
        showToast({ messageKey: 'general.error.saving.data', status: 'error' })
      }
    },
    [
      cohortId,
      updateParticipantsActivationStatus,
      refreshEmailParticipantsList,
      refreshCohortDetails,
    ]
  )

  const addEmailParticipant = useMemo(
    () => async (userEmail: string, cohortExtId: string) => {
      try {
        // Language we pass here is not important
        await addEmails([userEmail], cohortExtId, 'en-US')
        refreshEmailParticipantsList()
        refreshCohortDetails()
        showToast({
          messageKey: 'cohort.participant.updateStatus.confirmModal.success',
          status: 'success',
        })
      } catch (err) {
        showToast({ messageKey: 'general.error.saving.data', status: 'error' })
      }
    },
    [addEmails, refreshEmailParticipantsList, refreshCohortDetails]
  )

  const columnHelper = createColumnHelper<EmailParticipant>()

  const columns = useMemo(
    () => [
      columnHelper.accessor('email', {
        header: () => <FormattedMessage id="participant.cohorts.list.table.name" />,
        cell: props => {
          const record = props.row.original

          return (
            <Box>
              {record.email}
              {record.profile && (
                <Box fontSize="sm" color={colors.gray[500]} mt={1}>
                  {record.profile.firstName} {record.profile.lastName}
                </Box>
              )}
            </Box>
          )
        },
      }),
      columnHelper.accessor('lastInvitedAt', {
        header: () => <FormattedMessage id="cohort.details.tokens.list.createdAt" />,
        cell: props => formatDateTime(props.row.original.createdAt),
      }),
      columnHelper.accessor('state', {
        header: () => <FormattedMessage id="participant.cohorts.list.table.state" />,
        cell: props => {
          const record = props.row.original
          const { messageKey, statusColor } = getMessageKeyAndColor(
            record.state,
            record.isDisabled,
            record.lastInvitedAt
          )

          return (
            <HStack gap={1} alignContent="center">
              <Box w="12px" h="12px" borderRadius="50%" bg={statusColor} />
              <FormattedMessage id={messageKey} />
            </HStack>
          )
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: props => {
          const record = props.row.original

          return (
            <CohortParticipantRowMenu
              deleteParticipant={deleteParticipant}
              removeParticipantFromCohort={removeParticipantFromCohort}
              updateParticipantsStatus={updateParticipantsStatus}
              reAddEmailParticipant={addEmailParticipant}
              isDisabled={record.isDisabled}
              modalTitle={formatMessage({
                id: 'cohort.creation.email.participants.remove.participant.modal.title',
              })}
              modalLabel={formatMessage({
                id: 'cohort.participant.delete.confirmModal.remove.from.cohort',
              })}
              modalDescription={formatMessage({
                id: 'cohort.creation.email.participants.remove.participant.modal.description',
              })}
              userId={record.userId}
              userEmail={record.email}
              cohortId={cohortId}
              removed={record.state === 'REMOVED'}
              isCohortEmail
            />
          )
        },
        meta: {
          align: 'right',
        },
      }),
    ],
    [
      columnHelper,
      deleteParticipant,
      removeParticipantFromCohort,
      updateParticipantsStatus,
      addEmailParticipant,
      formatMessage,
      cohortId,
    ]
  )

  return (
    <>
      <Flex pb={4} justify="right">
        <HStack spacing={4} h="fit-content">
          {!!data?.length && (
            <Tooltip
              hasArrow
              label={
                disabledResendEmailTooltip &&
                formatMessage({
                  id: disabledResendEmailTooltip,
                })
              }
              bg={colors.blue[100]}
              color={colors.blue[500]}
              fontWeight="normal"
              placement="top"
            >
              <Button
                isDisabled={!!disabledResendEmailTooltip}
                variant="outline"
                onClick={onResendModalOpen}
              >
                <FormattedMessage id="cohort.creation.email.participants.resend.email.invitations.button" />
              </Button>
            </Tooltip>
          )}
          <Button
            onClick={onAddModalOpen}
            leftIcon={<RiAddFill size="1.25em" />}
            iconSpacing="1"
          >
            <FormattedMessage id="cohort.creation.email.participants.title" />
          </Button>
        </HStack>
      </Flex>
      {status === 'loaded' && data?.length ? (
        <ContentLoader status={status}>
          <AdvancedTable data={data} columns={columns} sx={tableSx} />
        </ContentLoader>
      ) : (
        <Box display="flex" alignItems="center" maxW="800px" mt={8}>
          <Box h="30px" w="30px" mr={4}>
            <Icon as={RiErrorWarningLine} />
          </Box>
          <FormattedMessage
            id={
              isTokenCohort
                ? 'cohort.programs.list.noParticipants'
                : 'cohort.programs.list.no.email.participants'
            }
          />
        </Box>
      )}
      <EmailParticipantsModal
        cohortId={cohortId}
        isOpen={isAddModalOpen}
        onClose={onAddModalClose}
        refreshParticipantsList={refreshEmailParticipantsList}
      />
      {!!data?.length && (
        <ResendEmailInvitationsModal
          emailParticipants={data}
          isOpen={isResendModalOpen}
          onClose={onResendModalClose}
          cohortId={cohortId}
          invitedLessThen1DayAgo={invitedLessThen1DayAgo}
          invitedMoreThen1DayAgo={invitedMoreThen1DayAgo}
          refreshParticipants={refreshEmailParticipantsList}
        />
      )}
    </>
  )
}
