import { HorizontalStepper } from '../../../../components/horizontal-stepper'
import { useInteractionStepContext } from './interaction-step-context'
import { orderedInteractionSteps } from './ordered-interaction-steps'

export const InteractionStepStepper = () => {
  const { currentStep } = useInteractionStepContext()

  return (
    <HorizontalStepper
      currentStep={currentStep}
      stepsWithTitles={orderedInteractionSteps}
      sx={{ maxW: '500px', px: 4 }}
    />
  )
}
