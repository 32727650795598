import { saveAs } from 'file-saver'
import moment from 'moment'

import { useSWRLoader } from '../hooks/use-swr-loader'
import { apiRequest } from '../utils/api'
import { customSwrFetcher } from '../utils/swr-fetcher'
import { type EmailParticipant } from './types/cohort-email-participants'
import { type UserToken } from './types/user-token'

const getCohortParticipantsBaseUrl = (cohortId: string) =>
  `/cohorts/${cohortId}/participants`

export const useCohortParticipants = () => {
  const addTokens = (numberTokens: number, cohortId: string) =>
    customSwrFetcher(`${getCohortParticipantsBaseUrl(cohortId)}/tokens`, {
      method: 'POST',
      body: JSON.stringify({ numberTokens }),
    })

  const addEmails = (emails: Array<string>, cohortId: string, language: string) =>
    customSwrFetcher(`${getCohortParticipantsBaseUrl(cohortId)}/emails`, {
      method: 'POST',
      body: JSON.stringify({ emails, language }),
    })

  const resendEmailInvitations = (userExtIds: Array<string>, cohortId: string) =>
    customSwrFetcher(`${getCohortParticipantsBaseUrl(cohortId)}/resendEmails`, {
      method: 'POST',
      body: JSON.stringify({ userExtIds }),
    })

  const downloadAvailableTokens = async (cohortId: string) => {
    const data = await apiRequest(getCohortParticipantsBaseUrl(cohortId), {
      method: 'GET',
      isFetchingBinaryData: true,
      query: { onlyTokens: true, onlyAvailableTokens: true, format: 'xlsx' },
      list: false,
    })

    saveAs(data, `Tokens-${moment().format('YYYY_MM_DD')}.xlsx`)
  }

  const useParticipantsList = (cohortId: string) =>
    useSWRLoader<Array<UserToken>>(getCohortParticipantsBaseUrl(cohortId))

  const removeParticipants = (cohortId: string, userExtIds: Array<string>) =>
    customSwrFetcher(getCohortParticipantsBaseUrl(cohortId), {
      method: 'DELETE',
      body: JSON.stringify({ userExtIds }),
    })

  const updateParticipantsActivationStatus = (
    cohortId: string,
    userId: string,
    isDisabled: boolean
  ) =>
    customSwrFetcher(
      `${getCohortParticipantsBaseUrl(cohortId)}/${userId}/updateUserActivationStatus`,
      {
        method: 'PUT',
        body: JSON.stringify({ isDisabled }),
      }
    )

  const useEmailParticipantsList = (cohortId: string) =>
    useSWRLoader<Array<EmailParticipant>>(
      `${getCohortParticipantsBaseUrl(cohortId)}/emailParticipants`
    )

  return {
    addTokens,
    addEmails,
    resendEmailInvitations,
    downloadAvailableTokens,
    useParticipantsList,
    removeParticipants,
    useEmailParticipantsList,
    updateParticipantsActivationStatus,
  }
}
