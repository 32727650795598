import { combineReducers } from 'redux'

import benchmarkProfiles from './benchmark-profiles/reducer'
import folders from './folders/reducer'
import organization from './organization/reducer'
import plugins from './plugins/reducer'
import questionnaires from './questionnaires/reducer'
import roles from './roles/reducer'
import userActivitySession from './user-activity-session/reducer'

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  benchmarkProfiles,
  folders,
  organization,
  plugins,
  questionnaires,
  roles,
  userActivitySession,
})
