import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { type IntercomProps, useIntercom } from 'react-use-intercom'

import { useAuth } from '../authentication/auth-context'
import config from '../config'
import { selectFolderCount } from '../store/entities/folders/selectors'
import {
  selectOrganization,
  selectOrganizationInvitationCount,
  selectOrganizationMemberCount,
} from '../store/entities/organization/selectors'

export const useRetorioIntercom = () => {
  const [hasBooted, setHasBooted] = useState(false)
  const { boot, update } = useIntercom()

  const organization = useSelector(selectOrganization)
  const invitationCount = useSelector(selectOrganizationInvitationCount)
  const memberCount = useSelector(selectOrganizationMemberCount)
  const { user } = useAuth()
  const folderCount = useSelector(selectFolderCount)

  useEffect(() => {
    if (config.intercom.appID && organization?.id && user?.uid && user?.email) {
      const intercomAttributes: IntercomProps = {
        // Intercom needs the timestamp in seconds
        createdAt: Math.floor(Number(user.createdAt) / 1000).toString(),
        userId: user.uid,
        customAttributes: {
          email: user.email.endsWith('@retorio.com') ? '' : user.email,
          company: {
            id: organization.id,
            name: organization.name,
            created_at: organization.createdAt,
            people: memberCount,
            invited_users: invitationCount,
            lists: folderCount,
          },
        },
      }

      if (hasBooted) {
        update(intercomAttributes)
      } else {
        boot(intercomAttributes)
        setHasBooted(true)
      }
    }
  }, [
    boot,
    update,
    hasBooted,
    user?.uid,
    user?.email,
    user?.createdAt,
    organization?.id,
    organization?.name,
    organization?.createdAt,
    invitationCount,
    memberCount,
    folderCount,
  ])
}
