import { Button, Heading, Icon, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { Spinner } from '@repo/ui'
import { useEffect, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { Link as RouterLink } from 'react-router-dom'

import { verifyEmail } from './authentication'

type EmailVerifiedProps = {
  actionCode: string
}

export const EmailVerified = ({ actionCode }: EmailVerifiedProps) => {
  const [state, setState] = useState<'loading' | 'successful' | 'error'>('loading')

  useEffect(() => {
    const fn = async () => {
      try {
        await verifyEmail(actionCode)
        setState('successful')
      } catch (error) {
        setState('error')
      }
    }

    fn()
  }, [actionCode])

  return (
    <>
      {state === 'loading' && (
        <Stack align="center">
          <Spinner />
        </Stack>
      )}
      <Stack align="stretch" spacing={4} textAlign="center">
        {state === 'successful' && (
          <>
            <Heading as="h1" fontSize="2xl" fontWeight="medium" color="primary-dark">
              <Icon as={MdCheckCircle} color="#78e296" w={8} h={8} mr={2} />
              <FormattedMessage id="signup.emailVerified.title" />
            </Heading>
            <Text fontSize="sm" color="primary-dark">
              <FormattedMessage id="signup.emailVerified.text" />
            </Text>
            <Button type="button" size="lg" as={RouterLink} to="/login">
              <FormattedMessage id="onboarding.backToLogin" />
            </Button>
          </>
        )}
        {state === 'error' && (
          <>
            <Text fontSize="sm" color="primary-dark">
              <FormattedMessage id="signup.emailVerified.error" />
            </Text>
            <Button type="button" size="lg" as={RouterLink} to="/login">
              <FormattedMessage id="onboarding.backToLogin" />
            </Button>
          </>
        )}
      </Stack>
    </>
  )
}
