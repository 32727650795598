import { HorizontalStepper } from '../../../../components/horizontal-stepper'
import { useInteractionStepContext } from './interaction-step-context'
import {
  interactionStepConfig,
  orderedInteractionStepKeys,
} from './ordered-interaction-steps'

export const InteractionStepStepper = () => {
  const { currentStep } = useInteractionStepContext()

  return (
    <HorizontalStepper
      currentStep={currentStep}
      stepsWithTitles={orderedInteractionStepKeys.map(key => ({
        key,
        titleKey: interactionStepConfig[key].stepperKey,
      }))}
      sx={{ maxW: '942px', px: 4 }}
    />
  )
}
