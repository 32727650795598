import { Button, Heading, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useFormikContext } from 'formik'
import { type Dispatch, type SetStateAction, useEffect } from 'react'
import { CgAdd } from 'react-icons/cg'
import { v4 } from 'uuid'

import { type CoachingStepFormFields } from '../../../../../../builder/steps/api'
import { Goals } from '../../../../../../builder/steps/forms/goals'

type GoalStepProps = {
  hasUnderstanding: boolean
  showDonts: boolean
  disabled?: boolean
  setShowDonts: Dispatch<SetStateAction<boolean>>
  handleClose: () => void
}

export const GoalStep = ({
  hasUnderstanding,
  showDonts,
  disabled,
  setShowDonts,
  handleClose,
}: GoalStepProps) => {
  const { setFieldValue, values } = useFormikContext<CoachingStepFormFields>()
  const { step } = values

  if (step.stepType !== 'coachingStepQA') {
    throw new Error('Wrong step type')
  }

  useEffect(() => {
    if (!step.dos) {
      setFieldValue(`step.dos`, [{ extId: v4(), instruction: '' }])
      setFieldValue(`step.donts`, [])
    }
  }, [setFieldValue, step])

  return (
    <VStack p={5} align="stretch" mt="auto !important">
      {hasUnderstanding && (
        <>
          <Heading as="h3" size="md" mt={10}>
            <FormattedMessage id="step.questionAnswer.dos.header" />
          </Heading>
          <Goals disabled={disabled} fieldPrefix="dos" />
          {!showDonts && (
            <Button
              alignSelf="center"
              leftIcon={<CgAdd size="1.5rem" />}
              variant="ghost"
              colorScheme="blue"
              isDisabled={disabled}
              onClick={() => {
                setShowDonts(true)
                setFieldValue('step.donts', [{ extId: v4(), instruction: '' }])
              }}
            >
              <FormattedMessage id="step.questionAnswer.donts.header" />
            </Button>
          )}
          {showDonts && (
            <Goals disabled={disabled} fieldPrefix="donts" handleClose={handleClose} />
          )}
        </>
      )}
    </VStack>
  )
}
