const types = {
  CREATE_BENCHMARK_PROFILE_REQUEST: 'benchmark-profiles/create_request',
  CREATE_BENCHMARK_PROFILE_SUCCESS: 'benchmark-profiles/create_success',
  RECEIVE_BENCHMARK_PROFILES: 'benchmark-profiles/receive-list',
  UPDATE_BENCHMARK_PROFILE_REQUEST: 'benchmark-profiles/update_request',
  UPDATE_BENCHMARK_PROFILE_SUCCESS: 'benchmark-profiles/update_success',
}

// eslint-disable-next-line import/no-default-export
export default types

export const receiveBenchmarkProfiles = list => ({
  type: types.RECEIVE_BENCHMARK_PROFILES,
  data: list,
})

export const updateRequest = id => ({
  type: types.UPDATE_BENCHMARK_PROFILE_REQUEST,
  data: id,
})

export const updateSuccess = benchmark => ({
  type: types.UPDATE_BENCHMARK_PROFILE_SUCCESS,
  data: benchmark,
})

export const createRequest = () => ({
  type: types.CREATE_BENCHMARK_PROFILE_REQUEST,
})

export const createSuccess = benchmark => ({
  type: types.CREATE_BENCHMARK_PROFILE_SUCCESS,
  data: benchmark,
})
