import { RHF, useFormRules } from '@repo/ui'
import { useFormContext } from 'react-hook-form'

import { useSelectOptions } from '../../../use-select-options'
import { type Trainee, type TraineeFormValues } from '../../types'

const jobRoleSuggestions = [
  'customerServiceRepresentative',
  'salesRepresentative',
  'manager',
] as const

const hierarchyOptions: Array<Trainee['hierarchy']> = ['low', 'mid', 'high'] as const

const hasLeadershipResponsibilityOptions = ['yes', 'no'] as const

const seniorityOptions: Array<Trainee['seniority']> = ['low', 'mid', 'high', 'veryHigh']

export const StepProfessional = () => {
  const { control } = useFormContext<TraineeFormValues['professional']>()
  const rules = useFormRules()

  const { getSelectOptions } = useSelectOptions()

  return (
    <>
      <RHF.AutocompleteSelect
        control={control}
        name="jobRole"
        labelKey="scenario.builder.ai.steps.trainee.formLabel.jobRole"
        rules={rules.stringShortRequired}
        componentProps={{
          allowCustomValues: true,
          options: getSelectOptions(jobRoleSuggestions, 'trainee.jobRole', true),
        }}
      />

      <RHF.RadioButtonGroup
        control={control}
        name="hierarchy"
        labelKey="scenario.builder.ai.steps.trainee.formLabel.hierarchy"
        rules={rules.required}
        componentProps={{
          options: hierarchyOptions.map(value => ({
            value,
            labelKey: `common.amount.${value}`,
          })),
        }}
        tooltipKey="trainee.hierarchy.tooltip.text"
      />

      <RHF.RadioButtonGroup
        control={control}
        name="hasLeadershipResponsibility"
        labelKey="scenario.builder.ai.steps.trainee.formLabel.leadershipResponsibility"
        rules={rules.required}
        componentProps={{
          options: hasLeadershipResponsibilityOptions.map(value => ({
            value,
            labelKey: `common.${value}`,
          })),
        }}
      />

      <RHF.RadioButtonGroup
        control={control}
        name="seniority"
        labelKey="scenario.builder.ai.steps.trainee.formLabel.experience"
        rules={rules.required}
        componentProps={{
          options: seniorityOptions.map(value => ({
            value,
            labelKey: `common.amount.${value}`,
          })),
        }}
      />
    </>
  )
}
