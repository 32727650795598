import { Box, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, VideoPlayer } from '@repo/ui'

import { type BasicStep } from '../../../builder/steps/api'
import { useGoogleStorageAsset } from '../../../hooks/use-google-storage-asset'

export const BasicStepReview = ({ step }: { step: BasicStep }) => {
  const videoHttpLink = useGoogleStorageAsset(step.videoStepUrl)

  return (
    <VStack gap="14px" alignItems="flex-start" w="full">
      <Box
        w="fit-content"
        minH={8}
        borderRadius="full"
        border="1px solid"
        color="white"
        bg={colors.gray[700]}
        px="16px"
        py="4px"
        fontSize="14px"
        fontWeight={500}
        textAlign="center"
      >
        <FormattedMessage id={`scenario.builder.ai.steps.review.step.${step.type}`} />
      </Box>
      {step.type === 'video' && videoHttpLink ? (
        <Box maxW="562px">
          <VideoPlayer
            url={videoHttpLink ?? ''}
            nativeControls
            aspectRatio="16/9"
            videoHeight="300px"
          />
        </Box>
      ) : (
        <VStack gap={4} w="full" alignItems="flex-start">
          <Text fontSize="16px" fontWeight={500}>
            {step.heading}
          </Text>
          <Text bg="white" w="full" p={2} borderRadius="6px">
            {step.text}
          </Text>
        </VStack>
      )}
    </VStack>
  )
}
