import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { colors, showToast } from '@repo/ui'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { type Row } from '@tanstack/react-table'
import { useDrag, useDrop } from 'react-dnd'
import { MdOutlineDragIndicator } from 'react-icons/md'
import { RiMoreFill } from 'react-icons/ri'

import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { ProgramStateBadge } from '../../components/program-state-badge'
import { formatDateTime } from '../../utils/dates'
import { type Cohort } from '../types/cohort'
import { type CohortProgram } from '../types/cohort-program'
import { CohortProgramStateColumn } from './cohort-program-state-column'

const DRAG_AND_DROP_TYPE = 'TABLE_ROW'

const OrderNumber = ({ position }: { position: number }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    width="24px"
    height="24px"
    borderRadius="50%"
    backgroundColor={colors['secondary-dark']}
    color={colors.primary}
    fontSize="12px"
    fontWeight="bold"
    mr={2}
  >
    {position}
  </Box>
)

type DraggableTableRowProps<RowType> = {
  row: Row<RowType>
  swapRows: (rowId: string, targetRowId: string, saveOrder: boolean) => void
  resetOrder: () => void
  showOrderNumber?: boolean
}

const DraggableTableRow = <T extends { order: number }>({
  row,
  swapRows,
  resetOrder,
  showOrderNumber = false,
}: DraggableTableRowProps<T>) => {
  const [, dropRef] = useDrop({
    accept: DRAG_AND_DROP_TYPE,
    hover({ id: draggedRowId }: { id: string }) {
      if (draggedRowId !== row.id) {
        swapRows(draggedRowId, row.id, false)
      }
    },
  })

  const [{ isDragging }, dragRef, previewRef] = useDrag(
    () => ({
      type: DRAG_AND_DROP_TYPE,
      item: () => row,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const isDroppedValid = monitor.didDrop()

        if (isDroppedValid) {
          swapRows(item.id, row.id, true)
        } else {
          resetOrder()
        }
      },
    }),
    [swapRows]
  )

  return (
    <Tr
      key={row.id}
      ref={node => previewRef(dropRef(node))}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <Td width="fit-content">
        <HStack>
          {showOrderNumber && <OrderNumber position={row.original.order + 1} />}
          <Flex cursor="grab" ref={dragRef} alignContent="center">
            <Icon color={colors.gray[500]} as={MdOutlineDragIndicator} />
          </Flex>
        </HStack>
      </Td>
      {row.getVisibleCells().map(cell => (
        <Td
          key={cell.id}
          sx={{
            // Drag and drop preview is broken without this transform
            // https://github.com/react-dnd/react-dnd/issues/832
            transform: 'translate3d(0, 0, 0)',
            // When adding this transform hack, then the chakra menu popup gets
            // Rendered in the background. Therefore we use the selector to target the
            // menu and fix the display
            ':last-of-type': {
              '& > :last-child': {
                marginRight: '100% !important',
                transform: 'none !important',
              },
            },
          }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Td>
      ))}
    </Tr>
  )
}

export const CohortProgramTable = ({
  cohortId,
  data,
  onOpenCohortProgramEditModal,
  deleteCohortProgram,
  refreshCohortDetails,
  refreshCohortProgramList,
  swapRows,
}: {
  data: Array<CohortProgram>
  cohortId: string
  deleteCohortProgram: (cohortId, cohortProgramId) => Promise<Cohort | undefined>
  refreshCohortProgramList: () => void
  refreshCohortDetails: () => void
  onOpenCohortProgramEditModal: (cohortProgramId: string) => void
  swapRows: (rowId: string, targetRowId: string) => void
}) => {
  const { formatMessage } = useIntl()
  const columnHelper = createColumnHelper<CohortProgram>()

  const handleDeleteCohortProgram = async (extId: string) => {
    try {
      await deleteCohortProgram(cohortId, extId)
      refreshCohortProgramList()
      refreshCohortDetails()
      showToast({ messageKey: 'common.alert.deleted', status: 'success' })
    } catch (err) {
      showToast({ messageKey: 'general.error.deleting.data', status: 'error' })
    }
  }

  const columns = [
    columnHelper.accessor(row => row.programStatus, {
      id: 'programStatus',
      header: () => formatMessage({ id: 'cohort.programs.list.status' }),
      cell: props => <ProgramStateBadge state={props.getValue()} />,
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => formatMessage({ id: 'common.name' }),
      cell: props => <Text mr={2}>{props.getValue()}</Text>,
    }),
    columnHelper.accessor(row => row.cohortProgramStatus, {
      id: 'cohortProgramStatus',
      header: () => formatMessage({ id: 'cohort.programs.list.state' }),
      cell: props => <CohortProgramStateColumn status={props.getValue()} />,
    }),
    columnHelper.accessor(row => row.startDate, {
      id: 'startDate',
      header: () => formatMessage({ id: 'cohort.programs.list.startDate' }),
      cell: props => formatDateTime(props.getValue()),
    }),
    columnHelper.accessor(row => row.endDate, {
      id: 'endDate',
      header: () => formatMessage({ id: 'cohort.programs.list.endDate' }),
      cell: props =>
        props.getValue()
          ? formatDateTime(props.getValue())
          : formatMessage({
              id: 'cohort.programs.list.endDate.unlimited',
            }),
    }),
    columnHelper.display({
      id: 'menu',
      cell: props => (
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            icon={<RiMoreFill />}
            aria-label={formatMessage({
              id: 'cohort.programs.list.options.ariaLabel',
            })}
            onClick={e => e.stopPropagation()}
          />
          <MenuList onClick={e => e.stopPropagation()}>
            <MenuItem
              onClick={() => onOpenCohortProgramEditModal(props.row.original.extId)}
            >
              {formatMessage({
                id: 'cohort.programs.list.options.edit',
              })}
            </MenuItem>
            <InterceptAndConfirm
              onConfirm={() => handleDeleteCohortProgram(props.row.original.extId)}
              title={formatMessage(
                {
                  id: 'cohort.program.remove.confirmModal.title',
                },
                { programName: props.row.original.title }
              )}
              description={formatMessage({
                id: 'cohort.program.remove.confirmModal.description',
              })}
              ctaLabel={formatMessage({
                id: 'cohort.program.remove.confirmModal.cta',
              })}
            >
              {({ openModal }) => (
                <MenuItem color="indicator.red-dark" onClick={openModal}>
                  <Text>
                    {formatMessage({
                      id: 'cohort.programs.list.options.delete',
                    })}
                  </Text>
                </MenuItem>
              )}
            </InterceptAndConfirm>
          </MenuList>
        </Menu>
      ),
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.extId,
  })

  return (
    <TableContainer overflowY="auto">
      <Table overflow="hidden">
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {/* Add empty table header for the drag and drop column */}
              <Th />
              {headerGroup.headers.map(header => (
                <Th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map(row => (
            <DraggableTableRow<CohortProgram>
              key={row.id}
              row={row}
              swapRows={swapRows}
              resetOrder={refreshCohortProgramList}
              showOrderNumber
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
