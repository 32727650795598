import {
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react'
import { AVAILABLE_LANGUAGES, type LanguageKey, useLanguageContext } from '@repo/i18n'
import { IoLanguage } from 'react-icons/io5'

import { colors } from '../main'

export const LanguageSwitch = ({ color }: { color: 'white' | 'primary' }) => {
  const { language, setLanguage } = useLanguageContext()

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Language"
        bg="transparent !important"
        color={color === 'primary' ? colors['primary-dark'] : 'white'}
        size="md"
        icon={<IoLanguage size="20px" />}
      />

      <MenuList zIndex={100}>
        <MenuOptionGroup
          type="radio"
          onChange={value => setLanguage(value as LanguageKey)}
          value={language}
        >
          {AVAILABLE_LANGUAGES.map(({ label, value }) => (
            <MenuItemOption value={value} key={value}>
              {label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
