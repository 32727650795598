import {
  Button,
  ButtonGroup,
  type ButtonProps,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { useState } from 'react'
import { HiClock, HiThumbDown, HiThumbUp } from 'react-icons/hi'

type GoalBehaviorType = 'shown' | 'avoided'

interface BehaviorButtonGroupProps {
  currentItemCount: number
  maxItemCount: number
  onClick: (goalBehavior: GoalBehaviorType) => void
  labelKey: I18nKey
}

export const BehaviorButtonGroup = ({
  currentItemCount,
  maxItemCount,
  onClick,
  labelKey,
}: BehaviorButtonGroupProps) => {
  const [selected, setSelected] = useState<GoalBehaviorType>('shown')

  const commonStyles = (isActive: boolean): Partial<ButtonProps> => ({
    borderRadius: '96px',
    variant: isActive ? 'solid' : 'outline',
    color: isActive ? 'white' : colors.gray[500],
    _active: { bg: colors.blue[500] },
  })

  const onBehaviorClick = (option: GoalBehaviorType) => {
    setSelected(option)
    onClick(option)
  }

  return (
    <Stack>
      <Text fontWeight={500} fontSize="14px">
        <FormattedMessage id={labelKey} />
      </Text>
      <ButtonGroup w="250px">
        <Stack>
          <Button
            isActive={selected === 'shown'}
            onClick={() => onBehaviorClick('shown')}
            {...commonStyles(selected === 'shown')}
            leftIcon={<HiThumbUp />}
          >
            <FormattedMessage id="behavioral.goal.form.shown.option" />
          </Button>
          <Text fontSize="12px" color={colors.blue[500]}>
            <FormattedMessage
              id="scenario.realtime.builder.availableItems"
              values={{
                current: maxItemCount - currentItemCount,
                max: maxItemCount,
              }}
            />
          </Text>
        </Stack>
        <Stack>
          <Button
            isDisabled
            isActive={selected === 'avoided'}
            onClick={() => onBehaviorClick('avoided')}
            {...commonStyles(selected === 'avoided')}
            leftIcon={<HiThumbDown />}
          >
            <FormattedMessage id="behavioral.goal.form.avoided.option" />
          </Button>
          <HStack gap={1} alignSelf="center" fontSize="12px" color={colors.gray[400]}>
            <Icon as={HiClock} />
            <FormattedMessage id="behavioral.goal.form.behavior.button.group.coming.soon.label" />
          </HStack>
        </Stack>
      </ButtonGroup>
    </Stack>
  )
}
