import { GridItem, HStack, Skeleton, Stack } from '@chakra-ui/react'

import { DashboardSection } from '../shared/dashboard-section'

const DashboardCardSkeleton = ({
  colSpan,
  height,
}: {
  colSpan: 2 | 3 | 4
  height: string
}) => {
  const baseColSpan = colSpan === 2 ? 1 : 2

  return (
    <GridItem
      colSpan={{ base: baseColSpan, lg: colSpan }}
      p={4}
      height={height}
      display="flex"
      flexDir="column"
    >
      <Stack gap={1}>
        <Skeleton height={5} width="40%" />
        <Skeleton height={5} width="80%" />
      </Stack>
      <HStack mt={3}>
        <Skeleton maxW="120px" flex={1} height="28px" />
        <Skeleton maxW="120px" flex={1} height="28px" />
        <Skeleton maxW="120px" flex={1} height="28px" />
      </HStack>
      <Skeleton mt={3} flex={1} />
    </GridItem>
  )
}

export const DashboardSectionsSkeleton = () => (
  <>
    <DashboardSection
      titleKey="dashboard.sections.overallOverview.title"
      subtitleKey="dashboard.sections.overallOverview.subtitle"
    >
      <DashboardCardSkeleton height="600px" colSpan={3} />
      <DashboardCardSkeleton height="600px" colSpan={3} />
      <DashboardCardSkeleton height="600px" colSpan={3} />
    </DashboardSection>

    <DashboardSection
      titleKey="dashboard.sections.behavioralAdaptionIndicators.title"
      subtitleKey="dashboard.sections.behavioralAdaptionIndicators.subtitle"
    >
      <DashboardCardSkeleton height="600px" colSpan={3} />
      <DashboardCardSkeleton height="600px" colSpan={3} />
      <DashboardCardSkeleton height="430px" colSpan={2} />
      <DashboardCardSkeleton height="430px" colSpan={2} />
      <DashboardCardSkeleton height="450px" colSpan={4} />
    </DashboardSection>

    <DashboardSection
      titleKey="dashboard.sections.behavioralSuccessPattern.title"
      subtitleKey="dashboard.sections.behavioralSuccessPattern.subtitle"
    >
      <DashboardCardSkeleton height="600px" colSpan={3} />
      <DashboardCardSkeleton height="600px" colSpan={3} />
      <DashboardCardSkeleton height="540px" colSpan={3} />
      <DashboardCardSkeleton height="540px" colSpan={3} />
    </DashboardSection>
  </>
)
