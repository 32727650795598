import { useIntl } from '@repo/i18n'

// ================= //
// Logic required for formatWithInterpolation, only used twice...
//  lifted from former hooks / useIntl.js file

const interpolateRegEx = /\$\{([a-zA-z0-9]+:)([a-zA-Z0-9_.[\]]+)\}/g

const interpolateFieldMeta = (path, result) => {
  const expMatch = path.match(/^([^.]+)\.explanations\.(.+)$/)

  if (expMatch) {
    return result.getExplanation(expMatch[1], expMatch[2])
  }

  const match = path.match(/^([^.]+)\.(.+)$/)

  if (match) {
    return result.getFieldMeta(match[1], match[2].split('.'))
  }

  return ''
}

const interpolateResultMeta = (path, result) => result.getMeta(path.split('.'))

const interpolateMessage = (id, intl) => intl.formatMessage({ id })

const interpolateSummaryValue = (field, result) => result.getSummaryValue(field)

const interpolate = (prefixRaw, value, payload, intl) => {
  const prefix = prefixRaw ? prefixRaw.substr(0, prefixRaw.length - 1) : ''

  switch (prefix) {
    case 'fields':
      return interpolateFieldMeta(value, payload)
    case 'meta': {
      const fromMeta = interpolateResultMeta(value, payload)

      return interpolateRegEx.test(fromMeta)
        ? fromMeta.replace(interpolateRegEx, (_, p, v) =>
            interpolate(p, v, payload, intl)
          )
        : fromMeta
    }

    case 'message':
      return interpolateMessage(value, intl)
    case 'summary':
      return interpolateSummaryValue(value, payload)
    default:
      return intl.formatMessage({ id: `${prefix}.${value}` }, payload)
  }
}

export const useFormatWithInterpolation = () => {
  const intlObject = useIntl()

  return (text: string, payload = {}) =>
    text.replace(interpolateRegEx, (_, prefix, value) =>
      interpolate(prefix, value, payload, intlObject)
    )
}
