import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createRole, deleteRole, updateRole } from '../store/entities/roles/effects'
import { makeSelectRole } from '../store/entities/roles/selectors'

export const useRole = id => {
  const dispatch = useDispatch()
  const selectRole = useMemo(makeSelectRole, [])
  const role = useSelector(state => selectRole(state, { id }))

  const remove = async () => dispatch(deleteRole(id))

  const save = async data => dispatch(id ? updateRole(id, data) : createRole(data))

  return { role, remove, save }
}
