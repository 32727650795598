import { Button, Center, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast, useModal } from '@repo/ui'
import { type SubmitHandler } from 'react-hook-form'

import { client } from '../../utils/openapi-client'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'
import { useProgramsList } from '../shared/api'
import { type TrainingFormValues } from '../shared/types'
import { useTrainingForm } from './use-training-form'

const CreateTrainingForm = ({
  closeModal,
  programExtId,
}: {
  programExtId: string
  closeModal: () => void
}) => {
  const [form, formFieldsJsx] = useTrainingForm()
  const { handleSubmit, formState } = form

  const { mutate } = useOpenapiSWR('getTrainings', { params: { programExtId } })
  const { mutate: mutatePrograms } = useProgramsList()

  const onSubmit: SubmitHandler<TrainingFormValues> = async data => {
    try {
      const { data: training } = await client.post('createTraining', {
        params: { path: { programExtId } },
        body: {
          ...data,
          order: 0,
        },
      })

      if (!training) {
        throw new Error()
      }

      mutate(
        trainings => [...(trainings ?? []), { ...training, scenariosCount: 0 }],
        false
      )

      mutatePrograms(
        programs =>
          programs?.map(p => {
            if (p.extId !== programExtId || p.trainingsCount === undefined) {
              return p
            }

            return {
              ...p,
              trainingsCount: p.trainingsCount + 1,
            }
          }),
        false
      )

      showToast({ messageKey: 'training.createModal.success', status: 'success' })
      closeModal()
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
      {formFieldsJsx}

      <Center gap={3} mt={6}>
        <Button variant="outline" onClick={closeModal}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          type="submit"
          isDisabled={!formState.isValid}
          isLoading={formState.isSubmitting}
        >
          <FormattedMessage id="common.create" />
        </Button>
      </Center>
    </Stack>
  )
}

export const useCreateTrainingModal = (programExtId: string) => {
  const modal = useModal(
    ({ closeModal }) => (
      <CreateTrainingForm closeModal={closeModal} programExtId={programExtId} />
    ),
    {
      titleKey: 'training.create',
    }
  )

  return modal
}
