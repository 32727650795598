import { type ProgramLanguages } from '@repo/api'

import { useSWRLoader } from '../../hooks/use-swr-loader'
import { customSwrFetcher } from '../../utils/swr-fetcher'
import { type BenchmarkProfile } from '../types/benchmark-profile'
import { type BenchmarkProfileFormValues } from '../types/benchmark-profile-form'
import { type ProgramTrainingScenariosTreeResponse } from '../types/program-training-scenario-tree'

const BENCHMARK_PROFILES_URL = '/coaching/benchmark_profiles'
const PROGRAMS_URL = '/coaching/programs'

const getProgramTrainingScenariosTree = (args?: {
  scenarioExtIds?: Array<string>
  benchmarkProfileToFilterFor?: string
  language?: ProgramLanguages
}) =>
  customSwrFetcher<ProgramTrainingScenariosTreeResponse>(`${PROGRAMS_URL}/tree`, {
    method: 'POST',
    body: JSON.stringify({ ...args }),
  })

const linkScenarios = async (
  benchmarkProfileExtId: string,
  scenarioExtIds: Array<string>
) =>
  customSwrFetcher<BenchmarkProfile>(
    `${BENCHMARK_PROFILES_URL}/${benchmarkProfileExtId}/linkScenarios`,
    {
      method: 'POST',
      body: JSON.stringify({ scenarioExtIds }),
    }
  )

const create = async (data: {
  title: string
  sessionExtIds: Array<string>
  scenariosToLink: Array<string>
}) =>
  customSwrFetcher<BenchmarkProfile>(`${BENCHMARK_PROFILES_URL}`, {
    method: 'POST',
    body: JSON.stringify(data),
  })

const update = async (extId: string, data: BenchmarkProfileFormValues) =>
  customSwrFetcher<BenchmarkProfile>(`${BENCHMARK_PROFILES_URL}/${extId}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  })

const deleteProfile = async (extId: string) =>
  customSwrFetcher(`${BENCHMARK_PROFILES_URL}/${extId}`, {
    method: 'DELETE',
  })

const duplicateTemplate = async (extId: string) =>
  customSwrFetcher<BenchmarkProfile>(`${BENCHMARK_PROFILES_URL}/copy`, {
    method: 'POST',
    body: JSON.stringify({ extId }),
  })

export const useBenchmarkProfiles = () => {
  const useGetAll = () => useSWRLoader<Array<BenchmarkProfile>>(BENCHMARK_PROFILES_URL)

  const useTemplateProfiles = () =>
    useSWRLoader<Array<BenchmarkProfile>>(`${BENCHMARK_PROFILES_URL}/templates`)

  return {
    useGetAll,
    useTemplateProfiles,
    create,
    update,
    deleteProfile,
    duplicateTemplate,
    linkScenarios,
    getProgramTrainingScenariosTree,
  }
}
