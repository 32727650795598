import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

type StageStepperContextType = {
  activeStageIndex: number
  setActiveStageIndex: (index: number) => void
  goToNextStage: () => void
  goToPreviousStage: () => void
}

const StageStepperContext = createContext<StageStepperContextType | null>(null)

export const StageStepperProvider = ({ children }: PropsWithChildren) => {
  const [activeStageIndex, setActiveStageIndex] = useState(0)

  const goToNextStage = useCallback(() => {
    setActiveStageIndex(prev => prev + 1)
  }, [])

  const goToPreviousStage = useCallback(() => {
    setActiveStageIndex(prev => Math.max(0, prev - 1))
  }, [])

  const value = useMemo(
    () => ({
      activeStageIndex,
      setActiveStageIndex,
      goToNextStage,
      goToPreviousStage,
    }),
    [activeStageIndex, goToNextStage, goToPreviousStage]
  )

  return (
    <StageStepperContext.Provider value={value}>{children}</StageStepperContext.Provider>
  )
}

export const useStageStepperContext = () => {
  const context = useContext(StageStepperContext)

  if (!context) {
    throw new Error('useStageStepperContext must be used within a StageStepperProvider')
  }

  return context
}
