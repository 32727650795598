import { Box, Button, Heading, HStack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { Form, useFormikContext } from 'formik'

import { DateTimePicker } from '../../components/date-time-picker'
import { type CohortProgramEditFormValues } from '../types/cohort-program-edit-form-values'

export const CohortProgramEditForm = () => {
  const {
    errors,
    touched,
    isValid,
    dirty,
    isSubmitting,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<CohortProgramEditFormValues>()

  const onChangeDateField = (fieldName: string, value: Date | undefined) => {
    setFieldValue(fieldName, value)
    setFieldTouched(fieldName, true)
  }

  return (
    <Form>
      <Heading as="h6" color="gray.600" margin="3px" size="xs">
        <FormattedMessage id="cohort.program.assignForm.period.label" />
      </Heading>

      <Text margin="3px" size="xs">
        <FormattedMessage id="cohort.program.assignForm.period.subLabel" />
      </Text>

      <HStack spacing={4}>
        <Box>
          <Text color="gray" size="xs" mx="3px" my={2}>
            <FormattedMessage id="cohort.program.assignForm.startDate.label" />
          </Text>
          <DateTimePicker
            value={values.startDate}
            onChange={value => onChangeDateField('startDate', value)}
          />
        </Box>
        <Box>
          <Text color="gray" size="xs" mx="3px" my={2}>
            <FormattedMessage id="cohort.program.assignForm.endDate.label" />
          </Text>
          <DateTimePicker
            value={values.endDate}
            onChange={value => onChangeDateField('endDate', value)}
          />
        </Box>
      </HStack>
      {touched.startDate && errors.startDate && (
        <Box p={1} color="red">
          <FormattedMessage id="cohort.program.assignForm.period.error.startDate" />
        </Box>
      )}
      {touched.endDate && errors.endDate && (
        <Box p={1} color="red">
          <FormattedMessage id="cohort.program.assignForm.period.error.endDate" />
        </Box>
      )}
      <HStack mt={12} spacing={4} justify="center">
        <Button variant="outline" type="reset">
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button type="submit" isLoading={isSubmitting} isDisabled={!(dirty && isValid)}>
          <FormattedMessage id="common.save" />
        </Button>
      </HStack>
    </Form>
  )
}
