import { Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import { type CoachingStep } from '../../../../../builder/steps/api'
import { CenteredSpinner } from '../../../../../components/centered-spinner'
import { StepReviewList } from '../../../../shared/step-review-section'
import { StickyFooter } from '../../../../shared/sticky-footer'
import { useRenameScenarioModal } from '../../../../shared/use-rename-scenario-modal'
import { useRealtimeBuilderContext } from '../../../shared/realtime-builder-context'
import { useInteractionStepContext } from '../interaction-step-context'

export const StepReview = () => {
  const { goToPreviousStep } = useInteractionStepContext()

  const { isBuilderReadonly, programExtId, trainingExtId, scenarioExtId } =
    useRealtimeBuilderContext()

  const { data: steps, isLoading } = useSWR<Array<CoachingStep>>(
    `/admin/programs/${programExtId}/trainings/${trainingExtId}/scenarios/${scenarioExtId}/steps`
  )

  const [renameScenarioModal, renameScenarioViaModal] = useRenameScenarioModal()

  const navigate = useNavigate()

  const handleNext = async () => {
    if (!isBuilderReadonly) {
      const isRenamed = await renameScenarioViaModal()

      if (!isRenamed) {
        return
      }
    }

    navigate(`/training_programs/${programExtId}/trainings/${trainingExtId}`)
  }

  const isStepsInitializing = !steps && isLoading

  return (
    <Stack gap={0} flex={1}>
      {renameScenarioModal}

      {isStepsInitializing ? <CenteredSpinner /> : <StepReviewList steps={steps} />}

      <StickyFooter
        nextLabelKey={isBuilderReadonly ? 'common.continue' : 'common.saveAndFinalize'}
        onBack={goToPreviousStep}
        onNext={handleNext}
      />
    </Stack>
  )
}
