import {
  Box,
  type BoxProps,
  Step,
  StepIcon,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepStatus,
  Text,
} from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'

type HorizontalStepperProps<T extends string> = {
  currentStep: NoInfer<T>
  stepsWithTitles: Array<{
    key: T
    titleKey: I18nKey
  }>
  sx?: BoxProps['sx']
}

export const HorizontalStepper = <T extends string>({
  currentStep,
  stepsWithTitles,
  sx,
}: HorizontalStepperProps<T>) => {
  const { formatMessage } = useIntl()

  const stepsKeys = stepsWithTitles.map(({ key }) => key)
  const currentStepIndex = stepsKeys.indexOf(currentStep)

  return (
    <Box mb={10} pos="relative" sx={sx}>
      <Stepper index={currentStepIndex} size="sm">
        {stepsWithTitles.map(({ key, titleKey }) => {
          const stepTitle = formatMessage({ id: titleKey })

          return (
            <Step key={key}>
              <StepIndicator
                bg="white"
                sx={{
                  '[data-status=complete] & span, [data-status=active] & span': {
                    color: colors.blue[500],
                  },
                }}
              >
                <StepStatus
                  complete={<StepIcon />}
                  active={
                    <Box
                      sx={{
                        w: '10px',
                        h: '10px',
                        bg: colors.blue[500],
                        borderRadius: 'full',
                      }}
                    />
                  }
                />

                <Text
                  as="span"
                  pos="absolute"
                  bottom={-2}
                  transform="translateY(100%)"
                  maxW="100px"
                  color={colors.gray[400]}
                  textAlign="center"
                  noOfLines={2}
                  fontSize={14}
                  lineHeight={1}
                  title={stepTitle}
                >
                  {stepTitle}
                </Text>
              </StepIndicator>

              <StepSeparator />
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}
