import { chakra } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'

export const ContextBar = ({ children }: PropsWithChildren) => (
  <chakra.div
    sx={{
      bg: 'white',
      width: '250px',
      padding: ' 1.5rem',
      overflowY: 'auto',
    }}
  >
    {children}
  </chakra.div>
)
