import { Box, Circle, Flex, Progress, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'

type WizardProgressBarProps<T extends string> = {
  steps: Array<T>
  currentStep: T
}

export const WizardProgressBar = <T extends string>({
  steps,
  currentStep,
}: WizardProgressBarProps<T>) => {
  const numberOfSteps = steps.length
  const currentStepIndex = steps.indexOf(currentStep)
  const progressValue = currentStepIndex / (numberOfSteps - 1)

  const getStepColor = (step: T) => {
    if (step === currentStep) {
      return colors['primary-dark']
    }

    if (steps.indexOf(step) < currentStepIndex) {
      return 'black'
    }

    return colors.gray[400]
  }

  return (
    <Flex w="90%">
      <Flex w="100%" justifyContent="center">
        <Box w="90%">
          <Flex justifyContent="space-between" mb={2}>
            {steps.map(step => (
              <Box key={step} w="12px">
                <Box w="max-content" transform="translate(-50%)">
                  <Text fontSize="small" fontStyle="normal" color={getStepColor(step)}>
                    <FormattedMessage
                      id={`coaching.benchmarking.wizard.steps.${step}.label` as I18nKey}
                    />
                  </Text>
                </Box>
              </Box>
            ))}
          </Flex>
          <Box sx={{ '> div': { '> div': { borderRightRadius: 0 } } }}>
            <Progress w="100%" borderRadius={8} value={progressValue} max={1} />
          </Box>
          <Flex justifyContent="space-between" position="relative" top="-12px">
            {steps.map(step => (
              <Flex key={step}>
                <Circle
                  size="12px"
                  bg={
                    steps.indexOf(step) > currentStepIndex
                      ? colors.gray[200]
                      : colors.blue[200]
                  }
                />
                <Circle position="absolute" m="3px" size="6px" bg={getStepColor(step)} />
              </Flex>
            ))}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}
