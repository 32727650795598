import { type ComponentProps } from 'react'
import { FormattedMessage as FormattedMessageLib } from 'react-intl'

import { defaultFormattingValues } from './default-formatting-values'

export const FormattedMessage = ({
  values,
  ...rest
}: ComponentProps<typeof FormattedMessageLib>) => (
  <FormattedMessageLib values={{ ...defaultFormattingValues, ...values }} {...rest} />
)
