import { useSWRLoader } from '../hooks/use-swr-loader'
import { customSwrFetcher } from '../utils/swr-fetcher'
import { type UserParticipant } from './types/cohort-email-participants'

const getUsersBaseUrl = () => `/cohorts/participants`

export const useParticipants = () => {
  const removeUsers = (userExtIds: Array<string>) =>
    customSwrFetcher(getUsersBaseUrl(), {
      method: 'DELETE',
      body: JSON.stringify({ userExtIds }),
    })

  const useEmailUsersList = () => useSWRLoader<Array<UserParticipant>>(getUsersBaseUrl())

  return {
    removeUsers,
    useEmailUsersList,
  }
}
