import { logAnalyticsEvent } from '../../../../utils/analytics'
import { createDashboardDateRange } from '../../../shared/create-dashboard-date-range'
import { useDashboardContext } from '../../../shared/dashboard-context'
import { fetchDashboardData } from '../../../shared/fetch-dashboard-data'
import { type DashboardCardId, type DataSlotType } from '../../../shared/types'
import { useDashboardDataSlotSelector } from './use-dashboard-card-data/use-dashboard-data-slot-selector'

export const useDashboardCardData = (cardId: DashboardCardId) => {
  const {
    dataSlots,
    setDataSlots,
    setSelectedDataSlotPerCard,
    selectedDataSlotPerCard,
    selectedCohorts,
    setPageState,
  } = useDashboardContext()

  const handleChange = async (newDataSlot: DataSlotType) => {
    logAnalyticsEvent('change_widget_time_slot', {
      widgetId: cardId,
      timeSlot: newDataSlot,
    })

    setSelectedDataSlotPerCard(prev => ({
      ...prev,
      [cardId]: newDataSlot,
    }))

    if (newDataSlot === 'custom') {
      // we don't need to fetch data if its custom, custom slot always have data
      return
    }

    // if new data slot is empty, trigger data fetching logic
    if (dataSlots[newDataSlot].status === 'empty') {
      try {
        setDataSlots(prev => ({
          ...prev,
          [newDataSlot]: {
            status: 'loading',
          },
        }))

        const dashboardData = await fetchDashboardData({
          dates: createDashboardDateRange(newDataSlot),
          cohorts: selectedCohorts,
        })

        setDataSlots(prev => ({
          ...prev,
          [newDataSlot]: {
            status: 'loaded',
            data: dashboardData,
          },
        }))
      } catch (error) {
        setPageState('error')
      }
    }
  }

  const dataSlotSelector = useDashboardDataSlotSelector({
    value: selectedDataSlotPerCard[cardId],
    onChange: value => {
      handleChange(value)
    },
  })

  const dataSlot = dataSlots[selectedDataSlotPerCard[cardId]]

  return {
    dataSlotSelector,
    dataSlot,
  }
}
