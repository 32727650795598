import { Flex, Text } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'

import { type QuestionWithRatios } from '../../../../shared/types'
import { QuestionHighlightSection } from './compare-best-and-worst-view/question-highlight-section'

export const CompareBestAndWorstView = ({
  title,
  best,
  worst,
}: {
  title?: string
  best: Array<QuestionWithRatios>
  worst: Array<QuestionWithRatios>
}) => {
  const { formatMessage } = useIntl()

  return (
    <>
      {!!title && (
        <Text fontWeight="bold" fontSize="sm" mb={2}>
          {title}
        </Text>
      )}
      <Flex
        sx={{
          flexWrap: 'wrap',
          gap: 7,
          '> div': {
            minWidth: '450px',
            flex: 1,
          },
        }}
      >
        <QuestionHighlightSection
          title={formatMessage({
            id: 'dashboard.knowledgeCheck.strengthsWeaknessesCard.strengths',
          })}
          subtitle={formatMessage({
            id: 'dashboard.knowledgeCheck.strengthsWeaknessesCard.strengthsDescription',
          })}
          questionsWithRatios={best}
        />

        <QuestionHighlightSection
          title={formatMessage({
            id: 'dashboard.knowledgeCheck.strengthsWeaknessesCard.weaknesses',
          })}
          subtitle={formatMessage({
            id: 'dashboard.knowledgeCheck.strengthsWeaknessesCard.weaknessesDescription',
          })}
          questionsWithRatios={worst}
        />
      </Flex>
    </>
  )
}
