import { Box, Button, Heading, Icon, Stack, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { Form, Formik } from 'formik'
import { useMemo, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import { api } from '../utils/api'
import { BackToLogin } from './back-to-login'
import {
  RequestPasswordForm,
  type RequestPasswordFormValue,
} from './request-password-form'

export const RequestPassword = () => {
  const [isDone, setIsDone] = useState(false)

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email('login.form.field.email.errors.invalid')
          .required('login.form.field.email.errors.required'),
      }),
    []
  )

  const handleSubmit = async ({ email }: RequestPasswordFormValue) => {
    try {
      await api.users.requestResetPassword({ email })
    } catch (error) {
      // We don't want to show an error if the user doesn't exists
    } finally {
      setIsDone(true)
    }
  }

  if (isDone) {
    return (
      <Stack align="center" spacing={4} textAlign="center">
        <Heading as="h1" fontSize="2xl" fontWeight="medium" color="primary-dark">
          <Icon as={MdCheckCircle} color="#78e296" w={8} h={8} mr={2} />
          <FormattedMessage id="requestPassword.message.success.title" />
        </Heading>
        <Text fontSize="sm" color="primary-dark">
          <FormattedMessage id="requestPassword.message.success.text" />
        </Text>
        <Button type="button" size="lg" width="full" as={RouterLink} to="/login">
          <FormattedMessage id="onboarding.backToLogin" />
        </Button>
      </Stack>
    )
  }

  return (
    <>
      <BackToLogin />
      <Heading
        mt={5}
        as="h1"
        fontSize="x-large"
        color="primary-dark"
        textAlign="center"
        fontWeight="medium"
      >
        <FormattedMessage id="requestPassword.form.title" />
      </Heading>
      <Text mt={5} mb={8} fontSize="sm" color="primary-dark" textAlign="center">
        <FormattedMessage id="requestPassword.form.subtitle" />
      </Text>
      <Formik<RequestPasswordFormValue>
        initialValues={{
          email: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <VStack as={Form} noValidate spacing={5}>
            <RequestPasswordForm size="md" />

            <Box w="full">
              <Button
                type="submit"
                size="lg"
                width="full"
                mt={5}
                isLoading={isSubmitting}
              >
                <FormattedMessage id="requestPassword.form.button.submit.label" />
              </Button>
            </Box>
          </VStack>
        )}
      </Formik>
    </>
  )
}
