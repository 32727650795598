import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

type RecordingProcessingProps = {
  isOpen: boolean
  onClose: () => void
}

export const RecordingProcessingModal = ({
  isOpen,
  onClose,
}: RecordingProcessingProps) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <FormattedMessage id="recording.beingProcessed.title" />
      </ModalHeader>

      <ModalCloseButton />

      <ModalBody>
        <FormattedMessage id="recording.beingProcessed.text" />
      </ModalBody>

      <ModalFooter justifyContent="center">
        <Button onClick={onClose}>
          <FormattedMessage id="recording.beingProcessed.button.ok" />
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
)
