import { Box, Button } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { HiPlusSm } from 'react-icons/hi'

import type { Instruction } from '../../../../../../types/api-types'
import { useRealtimeBuilderContext } from '../../../../shared/realtime-builder-context'
import { useEditInstructionModal } from './use-edit-instruction-modal'

type HowToColumnProps = {
  instruction: Instruction
}

export const HowToColumn = ({ instruction }: HowToColumnProps) => {
  const { isBuilderReadonly } = useRealtimeBuilderContext()

  const [editModal, showEditModal] = useEditInstructionModal(instruction)

  const hasHows = instruction.hows.length > 0

  return (
    <Box>
      {editModal}

      {hasHows ? (
        <FormattedMessage id="scenario.builder.realtime.steps.instructions.specified" />
      ) : (
        <Button
          variant="outline"
          leftIcon={<HiPlusSm size="14px" />}
          onClick={showEditModal}
          isDisabled={isBuilderReadonly}
        >
          <FormattedMessage id="scenario.builder.realtime.steps.instructions.specify" />
        </Button>
      )}
    </Box>
  )
}
