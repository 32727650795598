import { Box, Text, useDisclosure } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { PersonaThumbnail, type PillTagProps, useConfirmationModal } from '@repo/ui'
import { colors, showToast } from '@repo/ui'
import { useCallback, useMemo } from 'react'
import { type KeyedMutator } from 'swr'

import { CardGrid } from '../../../components/card-grid'
import { useGoogleStorageAsset } from '../../../hooks/use-google-storage-asset'
import { type Scenario } from '../../../types/api-types'
import { client } from '../../../utils/openapi-client'
import { useOpenapiSWR } from '../../../utils/use-openapi-swr'
import { useScenarioBuilderData } from '../use-scenario-builder-data'
import { PersonaPreviewModal } from './persona-card/persona-preview-modal'
import { type AvailablePersona } from './types'
import {
  type PersonaFormModalSubmitHandler,
  usePersonaFormModal,
} from './use-persona-form-modal'

export const PersonaCard = ({
  persona,
  isSelected,
  onSelect,
  isBuilderReadonly,
  mutateScenario,
}: {
  persona: AvailablePersona
  onSelect: () => void
  isSelected: boolean
  isBuilderReadonly: boolean
  mutateScenario: KeyedMutator<Scenario>
}) => {
  const { name, attitude, personality } = persona
  const previewModal = useDisclosure()
  const [{ programExtId, scenarioExtId, trainingExtId }] = useScenarioBuilderData()

  const { mutate: mutatePersonas } = useOpenapiSWR('getPersonas', {
    params: { extId: scenarioExtId, programExtId, trainingExtId },
  })

  const handlePersonaEdit = useCallback<PersonaFormModalSubmitHandler>(
    async (data, closeModal) => {
      try {
        const { character, demographic, insights, look, professional } = data

        const res = await client.put('updatePersona', {
          params: { path: { personaExtId: persona.extId } },
          body: {
            ...demographic,
            ...professional,
            ...character,
            ...look,
            ...insights,
          },
        })

        if (res.error) {
          showToast({ messageKey: 'common.error.unexpected', status: 'error' })

          return
        }

        const updatedPersona = res.data

        mutatePersonas(
          previous =>
            previous?.map(p =>
              p.extId === updatedPersona.extId ? { ...p, ...updatedPersona } : p
            ),
          false
        )

        if (isSelected) {
          // if persona is selected, refresh the scenario to reflect the changes in the persona
          await mutateScenario()
        }

        showToast({ messageKey: 'common.alert.saved', status: 'success' })
        closeModal()
      } catch (error) {
        showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }
    },
    [mutatePersonas, persona.extId, mutateScenario, isSelected]
  )

  const [editPersonaModal, showEditPersonaModal] = usePersonaFormModal(
    handlePersonaEdit,
    persona
  )

  const [deleteModal, showDeleteModal] = useConfirmationModal({
    onConfirm: async () => {
      try {
        const res = await client.delete('deletePersona', {
          params: { path: { personaExtId: persona.extId } },
        })

        if (res.error) {
          return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
        }

        mutatePersonas(
          personas => personas?.filter(p => p.extId !== persona.extId),
          false
        )

        showToast({ messageKey: 'persona.deleteModal.success', status: 'success' })
      } catch (error) {
        showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }
    },
    titleKey: 'persona.delete.title',
    descriptionKey: 'persona.delete.description',
    isDestructive: true,
  })

  const tag = useMemo<PillTagProps | undefined>(() => {
    if (persona.type === 'template') {
      return {
        text: <FormattedMessage id="common.retorio" />,
        sx: {
          bgColor: colors.blue[700],
          color: 'white',
          pos: 'absolute',
          top: 4,
          right: 0,
        },
        cut: 'right',
      }
    }
  }, [persona.type])

  return (
    <>
      {editPersonaModal}
      {deleteModal}
      <PersonaPreviewModal
        persona={persona}
        isOpen={previewModal.isOpen}
        onClose={previewModal.onClose}
        tag={tag}
      />
      <CardGrid.Card
        title={name}
        footer={{
          type: 'selectable',
          isSelected,
          onSelect,
        }}
        tag={tag}
        menuItems={[
          {
            labelKey: 'common.preview',
            onClick: previewModal.onOpen,
          },
          {
            labelKey: 'common.duplicate',
            isDisabled: isBuilderReadonly,
            onClick: async () => {
              try {
                const res = await client.put('duplicatePersona', {
                  params: { path: { personaExtId: persona.extId } },
                })

                if (res.error) {
                  return showToast({
                    messageKey: 'common.error.unexpected',
                    status: 'error',
                  })
                }

                await mutatePersonas(personas => {
                  const newPersona = res.data

                  return [
                    { ...newPersona, scenariosAttached: 0, editable: true },
                    ...(personas ?? []),
                  ]
                }, false)

                showToast({
                  messageKey: 'persona.duplicateModal.success',
                  status: 'success',
                })
              } catch (error) {
                showToast({ messageKey: 'common.error.unexpected', status: 'error' })
              }
            },
          },
          {
            isDisabled:
              isBuilderReadonly || persona.type === 'template' || !persona.editable,
            // if user is trying to edit a disabled custom persona, show a tooltip
            tooltipKey:
              persona.type === 'custom' && !persona.editable
                ? 'persona.info.cannotChange'
                : undefined,
            labelKey: 'common.edit',
            onClick: showEditPersonaModal,
          },
          {
            labelKey: 'common.delete',
            tooltipKey:
              // eslint-disable-next-line no-nested-ternary
              persona.type === 'template'
                ? 'persona.info.cannotDeleteTemplate'
                : persona.scenariosAttached
                  ? 'persona.info.cannotDeleteAttached'
                  : undefined,
            isDisabled:
              isBuilderReadonly ||
              persona.type === 'template' ||
              !!persona.scenariosAttached ||
              !persona.editable,
            onClick: showDeleteModal,
            isDestructive: true,
          },
        ]}
        imageComponent={
          <Box
            w="full"
            pointerEvents="none"
            bg="white"
            aspectRatio="16/9"
            overflow="hidden"
          >
            <PersonaThumbnail
              avatarPreviewUrl={persona.videoGenerationAvatar?.imageUrl}
              bgUrl={persona.backgroundUrl}
              useGoogleStorageAsset={useGoogleStorageAsset}
            />
          </Box>
        }
      >
        <Text>
          <FormattedMessage id="common.personality" />: {personality}
        </Text>
        <Text>
          <FormattedMessage id="common.attitude" />:{' '}
          <FormattedMessage id={`persona.attitude.${attitude}` satisfies I18nKey} />
        </Text>
      </CardGrid.Card>
    </>
  )
}
