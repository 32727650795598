import {
  Box,
  Button,
  Divider,
  FormControl,
  type HTMLChakraProps,
  MenuGroup,
  MenuList,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import {
  CheckboxSortableSelect,
  CheckboxSortableSelectItem,
  CustomSelectButton,
  eqBySortOptions,
  eqByValue,
  type Sortable,
} from '@repo/ui'
import { mapAndFilterNotNil } from '@repo/utils'
import { useCallback, useState } from 'react'

import { type ColumnSortableItemValue, type SortingState } from './column-control-data'
import { Placeholder } from './placeholder'
import { useColumnSelectData } from './recording-filters-hooks'

export type ColumnSortableControlProps = {
  value?: Array<ColumnSortableItemValue>
  onChange?: (v: Array<ColumnSortableItemValue>) => void
  onApply?: (v: Array<ColumnSortableItemValue>) => void
  onClose?: (v: Array<ColumnSortableItemValue>) => void
  isRootFolder: boolean
  sorting: SortingState
} & Omit<HTMLChakraProps<'div'>, 'onChange'>

export const ColumnSortableControl = ({
  value: valueProp,
  onChange,
  onClose,
  onApply,
  isRootFolder,
  sorting,
  ...rest
}: ColumnSortableControlProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOnChange = (value: Array<Sortable>) => {
    // Only allow one item at the same time to be sortable
    if (valueProp && onChange) {
      const prevValue = valueProp
      const updatedItem = value.find(item => {
        const itemInPrev = prevValue.find(eqByValue(item))

        return itemInPrev ? !eqBySortOptions(itemInPrev, item) : item.sortBy !== 'default'
      })

      const newValue = updatedItem
        ? value.map(item =>
            !eqByValue(item, updatedItem) && item.sortBy !== 'default'
              ? { ...item, sortBy: 'default' }
              : item
          )
        : value

      onChange(newValue as Array<ColumnSortableItemValue>)
    }
  }

  const handleOnApply = useCallback(() => {
    onApply?.(valueProp as Array<ColumnSortableItemValue>)
    setIsOpen(false)
  }, [onApply, valueProp])

  const handleOnClose = useCallback(
    (v: Array<Sortable>) => {
      onClose?.(v as Array<ColumnSortableItemValue>)
      setIsOpen(false)
    },
    [onClose]
  )

  const { selectData, selectDictionary, selectRootData, selectDictionaryRoot } =
    useColumnSelectData()

  const selectedColumn = isRootFolder ? selectRootData : selectData
  const selectedDictionaryData = isRootFolder ? selectDictionaryRoot : selectDictionary

  const textOrNull = valueProp
    ? mapAndFilterNotNil(v => selectedDictionaryData[v.value], valueProp)
        .map(x => x.selectedText)
        .join(', ') || null
    : null

  const text = textOrNull ?? <Placeholder />

  return (
    <FormControl {...rest}>
      <CheckboxSortableSelect
        value={valueProp}
        isOpen={isOpen}
        onChange={handleOnChange}
        onClose={handleOnClose}
      >
        <CustomSelectButton onClick={() => setIsOpen(true)} maxWidth="full">
          {text}
        </CustomSelectButton>
        <MenuList>
          <Box maxHeight="50vh" overflowY="auto">
            {selectedColumn.map((group, gi) => (
              <MenuGroup fontWeight="medium" key={group.title || gi} title={group.title}>
                {group.subGroups.map((subGroup, sgi) => (
                  <MenuGroup
                    key={subGroup.title || sgi}
                    title={subGroup.title}
                    fontWeight="normal"
                  >
                    {subGroup.items.map(item => (
                      <CheckboxSortableSelectItem
                        key={item.value}
                        value={item.value}
                        sorting={sorting}
                      >
                        {item.text}
                      </CheckboxSortableSelectItem>
                    ))}
                  </MenuGroup>
                ))}
              </MenuGroup>
            ))}
          </Box>

          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Divider m={3} />
            <Button onClick={handleOnApply}>
              <FormattedMessage id="recording.list.filters.form-control.apply" />
            </Button>
          </Box>
        </MenuList>
      </CheckboxSortableSelect>
    </FormControl>
  )
}
