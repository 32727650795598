import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { Formik } from 'formik'

import { cohortProgramFormValidator } from '../helpers/cohort-program-form-validator'
import { type CohortProgram } from '../types/cohort-program'
import { type CohortProgramEditFormValues } from '../types/cohort-program-edit-form-values'
import { useCohortPrograms } from '../use-cohort-programs'
import { CohortProgramEditForm } from './cohort-program-edit-form'

export const CohortProgramEditModal = ({
  onClose,
  cohortId,
  cohortProgram,
  refreshProgramList,
}: {
  onClose: () => void
  cohortId: string
  cohortProgram: CohortProgram
  refreshProgramList: () => void
}) => {
  const { updateCohortProgram, useCohortProgram } = useCohortPrograms()
  const { refresh: refreshCohortProgram } = useCohortProgram(
    cohortId,
    cohortProgram.extId
  )

  const onSubmit = async formData => {
    const dates = {
      startDate: formData.startDate,
      endDate: formData.endDate || null,
    }

    try {
      await updateCohortProgram(dates, cohortId, cohortProgram.extId)
      refreshProgramList()
      refreshCohortProgram()
      showToast({ messageKey: 'common.alert.created', status: 'success' })
      onClose()
    } catch (err) {
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }
  }

  const initialValues: CohortProgramEditFormValues = {
    startDate: new Date(cohortProgram.startDate),
    endDate: cohortProgram.endDate ? new Date(cohortProgram.endDate) : null,
  }

  return (
    <Modal onClose={onClose} isOpen isCentered>
      <ModalOverlay />
      <ModalContent maxW="lg">
        <ModalHeader>
          <FormattedMessage
            id="cohort.program.editModal.title"
            values={{ programName: cohortProgram.title }}
          />
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody pb={4}>
          <Formik<CohortProgramEditFormValues>
            onSubmit={onSubmit}
            onReset={onClose}
            initialValues={initialValues}
            validationSchema={cohortProgramFormValidator}
            validateOnBlur={false}
          >
            <CohortProgramEditForm />
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
