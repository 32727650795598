import { Box, Checkbox } from '@chakra-ui/react'

export const SessionCardSelect = ({
  isSelected,
  onSelect,
}: {
  isSelected: boolean
  onSelect: () => void
}) => (
  <Box
    // required to suppress the onCLick event on the card
    onClick={e => e.stopPropagation()}
    position="absolute"
    top={2}
    left={2}
    _hover={{ cursor: 'pointer' }}
  >
    <Box onClick={onSelect} p={1}>
      <Checkbox size="lg" isChecked={isSelected} pointerEvents="none" />
    </Box>
  </Box>
)
