import { api } from '../../../utils/api'
import * as actions from './private/actions'

export const fetchRoles = () => async dispatch => {
  try {
    const data = await api.roles.getAll()

    dispatch(actions.receiveRoles(data))
  } catch (error) {
    console.error(error)
  }
}

export const createRole = data => async dispatch => {
  try {
    dispatch(actions.createRequest())
    const result = await api.roles.create(data)

    dispatch(actions.createSuccess(result))

    return result
  } catch (error) {
    console.error(error)
  }

  return null
}

export const updateRole = (id, data) => async dispatch => {
  try {
    dispatch(actions.updateRequest(id))
    const result = await api.roles.update(id, data)

    dispatch(actions.updateSuccess(result))

    return result
  } catch (error) {
    console.error(error)
  }

  return null
}

export const deleteRole = id => async dispatch => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dispatch(actions.deleteRequest())
  await api.roles.delete(id)
  dispatch(actions.deleteSuccess(id))
}
