import {
  type BoxProps,
  Flex,
  GridItem,
  HStack,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type PropsWithChildren, type ReactNode } from 'react'

const CardLoadingSkeleton = () => <Skeleton height={320} width="100%" borderRadius={8} />

export const DashboardCard = ({
  children,
  colSpan,
  titleKey,
  descriptionKey,
  wrapperProps,
  titleEndContent,
  isLoading,
  dataSlotSelector,
}: PropsWithChildren<{
  colSpan: 1 | 2 | 3 | 4
  titleKey: I18nKey
  descriptionKey?: I18nKey
  wrapperProps?: BoxProps
  titleEndContent?: ReactNode
  isLoading?: boolean
  dataSlotSelector?: ReactNode
}>) => {
  // for now, only cards with columnSpan = 2 can fit 2 in a row on small screens
  // wider cards should take a full row on small screens
  const baseColSpan = colSpan === 2 ? 1 : 2

  return (
    <GridItem
      colSpan={{ base: baseColSpan, lg: colSpan }}
      boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.25)"
      borderRadius="lg"
      bg="white"
      p={4}
      display="flex"
      flexDir="column"
    >
      <HStack>
        <Stack gap={1} flex={1}>
          <Text fontWeight={500}>
            <FormattedMessage id={titleKey} />
          </Text>
          {!!descriptionKey && (
            <Text color={colors.gray[600]} fontSize="14px">
              <FormattedMessage id={descriptionKey} />
            </Text>
          )}
        </Stack>
        {titleEndContent}
      </HStack>
      <Flex flexDir="column" {...wrapperProps}>
        {dataSlotSelector}
        {isLoading ? <CardLoadingSkeleton /> : children}
      </Flex>
    </GridItem>
  )
}
