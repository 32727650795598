import { Box, Checkbox, HStack, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, VideoPlayer } from '@repo/ui'
import { useEffect, useState } from 'react'

import { type QuizStep } from '../../../builder/steps/api'
import { getHttpLink } from '../../../firebase'
import { useGoogleStorageAsset } from '../../../hooks/use-google-storage-asset'
import { AnswerOptionContainer } from './knowledge-check-step/answer-option-container'
import { ImageRegionResult } from './knowledge-check-step/image-region-result'

type KnowledgeCheckStepProps = {
  stepQuiz: QuizStep
}

export const KnowledgeCheckStep = ({ stepQuiz }: KnowledgeCheckStepProps) => {
  const getIsCorrectAnswer = (optionExtId: string) => {
    const option = stepQuiz.optionsData?.find(o => o.id === optionExtId)

    if (!option) {
      return undefined
    }

    if (option.isCorrect) {
      // show option is correct
      return true
    }

    if (!option.isCorrect) {
      // show option is incorrect
      return false
    }

    // do not highlight
    return undefined
  }

  const videoHttpLink = useGoogleStorageAsset(stepQuiz.questionVideo)
  const [videoUrls, setVideoUrls] = useState<Array<string>>([])

  useEffect(() => {
    const fetchVideoUrls = async () => {
      const urls = await Promise.all(
        stepQuiz.optionsData.map(async opt => {
          const httpLink = await getHttpLink(opt.videoUrl ?? '')

          return httpLink
        })
      )

      setVideoUrls(urls)
    }

    if (stepQuiz.quizType === 'videoComparison') {
      fetchVideoUrls()
    }
  }, [stepQuiz.optionsData, stepQuiz.quizType])

  return (
    <VStack w="full" alignItems="stretch" spacing="24px">
      <Box
        w="fit-content"
        minH={8}
        borderRadius="full"
        border="1px solid"
        color="white"
        bg={colors.gray[700]}
        px="16px"
        py="4px"
        fontSize="14px"
        fontWeight={500}
        textAlign="center"
      >
        <FormattedMessage
          id={`scenario.builder.ai.steps.review.step.${stepQuiz.quizType}`}
        />
      </Box>

      {stepQuiz.questionType === 'textVideo' &&
      stepQuiz.quizType === 'videoComparison' ? (
        <VStack alignItems="flex-start" w="full" spacing={4}>
          {/* Video for text video */}
          {videoHttpLink && (
            <Box py={4}>
              <VideoPlayer
                url={videoHttpLink}
                nativeControls
                aspectRatio="16/9"
                videoHeight="300px"
              />
            </Box>
          )}

          {/* Video comparison options */}
          {videoUrls.length > 0 && (
            <HStack py={4} gap={2}>
              {stepQuiz.optionsData.map((opt, index) => (
                <AnswerOptionContainer
                  showFeedback
                  isCorrectAnswer={getIsCorrectAnswer(opt.id)}
                  key={opt.id}
                >
                  <VideoPlayer
                    url={videoUrls[index] ?? ''}
                    nativeControls
                    aspectRatio="16/9"
                    videoHeight="300px"
                  />
                </AnswerOptionContainer>
              ))}
            </HStack>
          )}
        </VStack>
      ) : (
        <HStack gap={2} alignItems="flex-start">
          {stepQuiz.questionType === 'textVideo' && videoHttpLink && (
            <Box py={4}>
              <VideoPlayer
                url={videoHttpLink}
                nativeControls
                aspectRatio="16/9"
                videoHeight="300px"
              />
            </Box>
          )}
          <VStack alignItems="flex-start" w={videoHttpLink ? '500px' : 'full'} p={4}>
            <Text fontSize="16px" fontWeight={500}>
              {stepQuiz.questionText}
            </Text>
            {stepQuiz.quizType === 'multiChoice' && (
              <VStack
                alignItems="stretch"
                className="content-step"
                w={videoHttpLink ? '500px' : 'full'}
                gap={0}
              >
                {stepQuiz.optionsData.map(opt => {
                  const key = `checkbox-list-${opt.id}`

                  return (
                    <AnswerOptionContainer
                      showFeedback
                      isCorrectAnswer={getIsCorrectAnswer(opt.id)}
                      key={key}
                    >
                      <Checkbox value={opt.id} isChecked={opt.isCorrect} isDisabled>
                        {opt.text}
                      </Checkbox>
                    </AnswerOptionContainer>
                  )
                })}
              </VStack>
            )}

            {stepQuiz.quizType === 'singleChoice' && (
              <RadioGroup w={videoHttpLink ? '500px' : 'full'}>
                {stepQuiz.optionsData.map(opt => {
                  const key = `checkbox-list-${opt.id}`

                  return (
                    <AnswerOptionContainer
                      showFeedback
                      isCorrectAnswer={getIsCorrectAnswer(opt.id)}
                      key={key}
                    >
                      <Radio isChecked={opt.isCorrect} isDisabled>
                        {opt.text}
                      </Radio>
                    </AnswerOptionContainer>
                  )
                })}
              </RadioGroup>
            )}
            {stepQuiz.quizType === 'videoComparison' && videoUrls.length > 0 && (
              <HStack py={4} gap={2}>
                {stepQuiz.optionsData.map((opt, index) => (
                  <AnswerOptionContainer
                    showFeedback
                    isCorrectAnswer={getIsCorrectAnswer(opt.id)}
                    key={opt.id}
                  >
                    <VideoPlayer
                      url={videoUrls[index] ?? ''}
                      nativeControls
                      aspectRatio="16/9"
                      videoHeight="300px"
                    />
                  </AnswerOptionContainer>
                ))}
              </HStack>
            )}
            {stepQuiz.quizType === 'imageRegion' && <ImageRegionResult step={stepQuiz} />}
          </VStack>
        </HStack>
      )}
    </VStack>
  )
}
