import { Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { assertExists, assertValueEqualsTarget } from '@repo/utils'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { CenteredSpinner } from '../../../../../components/centered-spinner'
import { useUserSessionId } from '../../../../../store/entities/user-activity-session/use-user-session-id'
import { client } from '../../../../../utils/openapi-client'
import { StickyFooter } from '../../../../shared/sticky-footer'
import { useRealtimeBuilderContext } from '../../../shared/realtime-builder-context'
import { useInteractionStepContext } from '../interaction-step-context'
import { CustomInfoBox } from './step-stage-goals/custom-info-box'
import { StageGoalSection } from './step-stage-goals/stage-goal-section'
import { type StageGoalFormValues } from './step-stage-goals/types'

const orderedStages: Array<keyof StageGoalFormValues> = ['beginning', 'mainPart', 'end']

export const StepStageGoals = () => {
  const { goToPreviousStep, goToNextStep } = useInteractionStepContext()
  const {
    mutateScenario,
    programExtId,
    trainingExtId,
    scenarioExtId,
    scenario,
    isBuilderReadonly,
  } = useRealtimeBuilderContext()

  const userSessionId = useUserSessionId()

  const [isFetchingAiSuggestions, setIsFetchingAiSuggestions] = useState(true)

  const { control, handleSubmit, formState, setValue } = useForm<StageGoalFormValues>({
    mode: 'onTouched',
    defaultValues: {
      beginning: scenario?.missionStages?.[0]?.stageGoal ?? '',
      mainPart: scenario?.missionStages?.[1]?.stageGoal ?? '',
      end: scenario?.missionStages?.[2]?.stageGoal ?? '',
    },
    disabled: isBuilderReadonly,
  })

  const fetchAiSuggestions = useCallback(async () => {
    try {
      const res = await client.post('generateScenarioContentForRealtimeScenario', {
        body: {
          field: 'stage_goals',
          userActivitySessionExtId: userSessionId,
        },
        params: {
          path: {
            programExtId,
            trainingExtId,
            scenarioExtId,
          },
        },
      })

      if (res.error) {
        throw new Error()
      }

      assertValueEqualsTarget(res.data.field, 'stage_goals')

      const [beginning, mainPart, end] = res.data.suggestion

      assertExists(beginning)
      assertExists(mainPart)
      assertExists(end)

      setValue('beginning', beginning, { shouldDirty: true })
      setValue('mainPart', mainPart, { shouldDirty: true })
      setValue('end', end, { shouldDirty: true })
    } catch (error) {
      showToast({
        messageKey: 'scenario.ai.builder.steps.conversationGeneration.suggestions.failed',
        status: 'error',
      })
    }

    setIsFetchingAiSuggestions(false)
  }, [programExtId, scenarioExtId, trainingExtId, setValue, userSessionId])

  useEffect(() => {
    const shouldFetchSuggestions = !scenario?.missionStages?.[0]?.stageGoal

    if (shouldFetchSuggestions) {
      fetchAiSuggestions()
    } else {
      setIsFetchingAiSuggestions(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = useCallback(
    async ({ beginning, end, mainPart }: StageGoalFormValues) => {
      if (!formState.isDirty) {
        return goToNextStep()
      }

      const res = await client.post('saveStageGoals', {
        body: {
          stageGoals: [beginning, mainPart, end],
        },
        params: {
          path: {
            extId: scenarioExtId,
            programExtId,
            trainingExtId,
          },
        },
      })

      if (res.error) {
        return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }

      // TODO make optimistic update
      await mutateScenario()

      goToNextStep()
    },
    [
      formState.isDirty,
      goToNextStep,
      mutateScenario,
      scenarioExtId,
      programExtId,
      trainingExtId,
    ]
  )

  if (isFetchingAiSuggestions) {
    return <CenteredSpinner />
  }

  return (
    <Stack as="form" flex={1} gap={12} onSubmit={handleSubmit(onSubmit)}>
      <CustomInfoBox>
        <FormattedMessage id="scenario.realtime.builder.steps.goal.info" />
      </CustomInfoBox>

      <Stack spacing={8} maxW="800px">
        {orderedStages.map(stage => (
          <StageGoalSection key={stage} stage={stage} control={control} />
        ))}
      </Stack>

      <StickyFooter
        onBack={goToPreviousStep}
        isNextDisabled={!formState.isValid}
        isNextLoading={formState.isSubmitting}
      />
    </Stack>
  )
}
