import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { BsStars } from 'react-icons/bs'
import { RiAddFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'

import { useCreateProgramModal } from './create-program-menu/use-create-program-modal'

export const CreateProgramMenu = () => {
  const [modal, showModal] = useCreateProgramModal()

  return (
    <Menu>
      {modal}
      <MenuButton
        as={Button}
        leftIcon={<RiAddFill size="1.25em" />}
        onClick={e => e.stopPropagation()}
      >
        <FormattedMessage id="coaching.programList.button.create" />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={showModal}>
          <FormattedMessage id="coaching.programList.button.create.fromScratch" />
          <BsStars color={colors.primary} />
        </MenuItem>

        <MenuItem as={Link} to="/best_practices">
          <FormattedMessage id="coaching.programList.button.create.fromTemplate" />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
