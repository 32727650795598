import { Box, HStack, Icon, IconButton, Spacer } from '@chakra-ui/react'
import { showToast } from '@repo/ui'
import { type Dictionary } from '@repo/utils'
import { memo, useCallback } from 'react'
import { RiCloseFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { useCurrentTokenData } from '../../hooks/use-current-token-data'
import { type Folder } from '../../store/entities/folders/folders-types'
import { api } from '../../utils/api'
import { FolderBreadcrumbs } from '../folder-breadcrumbs'
import { OptionsMenu } from './options-menu'

type RecordingDetailsHeaderProps = {
  recordingId: string
  folders: Dictionary<Folder>
  folder: Folder
}

export const RecordingDetailsHeader = memo(
  ({ recordingId, folder, folders }: RecordingDetailsHeaderProps) => {
    const navigate = useNavigate()
    const { isOwner } = useCurrentTokenData()

    const handleDelete = useCallback(async () => {
      try {
        await api.recordings.deleteRecording(recordingId)
        navigate(`/folders/${folder.id}`, { replace: true })
        showToast({ messageKey: 'recording.delete.success', status: 'success' })
      } catch (error) {
        showToast({ messageKey: 'recording.delete.error', status: 'error' })
      }
    }, [recordingId, navigate, folder])

    const handleOnClose = () => navigate(`/folders/${folder.id}`)

    return (
      <Box my={4}>
        <HStack mb={4}>
          <FolderBreadcrumbs folders={folders} folder={folder} showCurrentFolder />
          <Spacer />
          {isOwner && (
            <OptionsMenu handleDelete={handleDelete} recordingId={recordingId} />
          )}
          <IconButton
            variant="ghost"
            icon={<Icon boxSize="1.25em" as={RiCloseFill} />}
            aria-label="More"
            onClick={handleOnClose}
          />
        </HStack>
      </Box>
    )
  }
)
