import { Box } from '@chakra-ui/react'
import { showToast } from '@repo/ui'
import { useFormikContext } from 'formik'

import { useCohortPrograms } from '../../../use-cohort-programs'
import { useCohorts } from '../../../use-cohorts'
import { CohortProgramAssignForm } from '../../cohort-program-assign-form'
import { type CohortCreationValues } from '../types'
import { StepActions } from './step-actions'

export const ProgramsStep = () => {
  const {
    values: { id, programIds, startDate, endDate },
    handleSubmit,
  } = useFormikContext<CohortCreationValues>()

  const { createCohortPrograms, useCohortProgramsList, useAvailablePrograms } =
    useCohortPrograms()

  const { useCohort } = useCohorts()
  const { refresh: refreshCohortStatistics } = useCohort(id)
  const { refresh: refreshProgramList } = useCohortProgramsList(id)
  const { data, status, isLoading } = useAvailablePrograms(id)

  const availablePrograms = data || []
  const hasNoPrograms = status === 'error'
  const isButtonDisabled = hasNoPrograms || programIds.length < 1 || !startDate

  const onSubmit = async () => {
    try {
      await createCohortPrograms({ startDate, endDate, programExtIds: programIds }, id)
      refreshProgramList()
      refreshCohortStatistics()
      handleSubmit()
    } catch (error) {
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }
  }

  return (
    <Box h={520}>
      <CohortProgramAssignForm
        availablePrograms={availablePrograms}
        isLoading={isLoading}
        showActionsButtons={false}
        maxHeight={172}
      />
      <StepActions onSubmit={onSubmit} isDisabled={isButtonDisabled} />
    </Box>
  )
}
