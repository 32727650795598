import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { Form, Formik, type FormikProps } from 'formik'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Gate } from '../../components/gate'
import { useCreateFolder } from '../../hooks/use-create-folder'
import { makeSelectFoldersBenchmarkProfileId } from '../../store/entities/folders/selectors'
import { type SelectFolderBenchmarkId } from '../../store/entities/folders/selectors-types'
import { getValidationSchema, onSavingError } from './folder-editor'
import { FolderForm } from './folder-form'

type FormData = {
  name: string
  benchmarkProfileId: string
}

export const NewFolderModal = ({
  parent,
  onClose,
  isOpen,
}: {
  parent: string
  onClose: () => void
  isOpen: boolean
}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const createFolder = useCreateFolder()
  const validationSchema = getValidationSchema(intl)
  const selectFolderBenchmarkId: SelectFolderBenchmarkId = useMemo(
    makeSelectFoldersBenchmarkProfileId,
    []
  )

  const defaultBenchmark = useSelector(state =>
    selectFolderBenchmarkId(state, parent || null)
  )

  const handleOnSubmit = async (formData: FormData) => {
    try {
      const folder = await createFolder({ ...formData, parent })
      const { id } = folder

      navigate(`/folders/${id}`)
      onClose()
    } catch (error) {
      onSavingError(error)
    }
  }

  return (
    <Gate>
      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage id="positions.create.title" />
          </ModalHeader>

          <ModalCloseButton />

          <Formik
            initialValues={{
              benchmarkProfileId: defaultBenchmark,
              name: '',
            }}
            onSubmit={handleOnSubmit}
            validateOnBlur={false}
            validationSchema={validationSchema}
          >
            {(
              formProps: FormikProps<{
                benchmarkProfileId: string
                name: string
              }>
            ) => (
              <Form>
                <ModalBody>
                  <FolderForm {...formProps} />
                </ModalBody>

                <ModalFooter justifyContent="center">
                  <ButtonGroup>
                    <Button variant="outline" onClick={onClose}>
                      <FormattedMessage id="common.cancel" />
                    </Button>
                    <Button
                      type="submit"
                      isDisabled={formProps.isSubmitting}
                      isLoading={formProps.isSubmitting}
                    >
                      <FormattedMessage id="positions.form.button.create" />
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </Gate>
  )
}
