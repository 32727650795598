import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { ChakraProvider } from '@chakra-ui/react'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import Hotjar from '@hotjar/browser'
import { IntlProvider } from '@repo/i18n'
import { Fonts, MuiThemeProvider, theme } from '@repo/ui'
import { isDevelopment, Log, setConfig } from '@repo/utils'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import { SWRConfig, type SWRConfiguration } from 'swr'

import { AuthProvider } from './authentication/auth-context'
import { GlobalStyles } from './components/global-styles'
import { ReloadableErrorBoundary } from './components/reloadable-error-boundary'
import { StartupGate } from './components/startup-gate'
import config from './config'
import { App } from './containers/app'
import store from './store/store'
import { customSwrFetcher } from './utils/swr-fetcher'

setConfig(config)
Log.init()
if (config.hotjar?.siteId) {
  Hotjar.init(config.hotjar.siteId, 6)
}

const swrConfig: SWRConfiguration = {
  fetcher: customSwrFetcher,
  // SWR reties failed requests even if the error indicates that the next
  // try will fail as well. E.g. 400 Bad Request. We block these retries here.
  onErrorRetry: error => {
    if (error.status >= 400 && error.status <= 406) {
      // eslint-disable-next-line no-useless-return
      return
    }
  },
}

// https://emotion.sh/docs/cache-provider
const emotionCacheOptions = createCache({
  key: 'css',
  // This will remove the vendor prefixes, useful when developing
  ...(isDevelopment
    ? {
        stylisPlugins: [],
      }
    : {}),
})

const container = document.getElementById('app') as HTMLElement
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <IntercomProvider appId={config.intercom?.appID} initializeDelay={5000}>
      <BrowserRouter>
        <DndProvider backend={HTML5Backend}>
          <SWRConfig value={swrConfig}>
            <CacheProvider value={emotionCacheOptions}>
              <AuthProvider>
                <GlobalStyles />
                <Fonts />
                <IntlProvider>
                  <ChakraProvider resetScope="body" theme={theme}>
                    <MuiThemeProvider>
                      <ReloadableErrorBoundary>
                        <StartupGate>
                          <App />
                        </StartupGate>
                      </ReloadableErrorBoundary>
                    </MuiThemeProvider>
                  </ChakraProvider>
                </IntlProvider>
              </AuthProvider>
            </CacheProvider>
          </SWRConfig>
        </DndProvider>
      </BrowserRouter>
    </IntercomProvider>
  </Provider>
)
