import { createContext } from '@repo/utils'

import { type ColumnControlProps } from './column-control'
import { type ColumnSortableItemValue, type SortingState } from './column-control-data'
import { type ColumnSortableControlProps } from './column-sortable-control'
import { type FilterControlProps } from './filter-control'
import { type FilterItemValue } from './filter-control-data'

export type RecordingFiltersContext = {
  // Filter
  filterSelectValue: FilterItemValue
  filterSelectInternalValue: FilterItemValue
  onFilterSelectApply: FilterControlProps['onApply']
  onFilterSelectChange: FilterControlProps['onChange']
  onFilterSelectClose: FilterControlProps['onClose']

  // Column / Column Sortable
  columnSelectValue: Array<ColumnSortableItemValue>
  columnSelectValueRoot: Array<ColumnSortableItemValue>
  columnSelectInternalValue: Array<ColumnSortableItemValue>
  onColumnSelectChange: ColumnControlProps['onChange']
  onColumnSelectApply: ColumnControlProps['onApply']
  onColumnSelectClose: ColumnControlProps['onClose']
  onColumnSortableSelectChange: ColumnSortableControlProps['onChange']
  onColumnSortableSelectApply: ColumnSortableControlProps['onApply']
  onColumnSortableSelectClose: ColumnSortableControlProps['onClose']
  onColumnSortChangeAndApply: (value: ColumnSortableItemValue) => void
  sorting: SortingState
}

const [RecordingFiltersContextProvider, useRecordingFiltersContext] =
  createContext<RecordingFiltersContext>({
    name: 'RecordingFiltersContext',
    strict: true,
  })

export { RecordingFiltersContextProvider, useRecordingFiltersContext }
