import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

import { useOrganizationSettingsModal } from '../organization/organization-settings-modal'

export const InfoModalGDPR = ({ open, onClose }) => {
  const [organizationSettingsModal, showOrganizationSettingsModal] =
    useOrganizationSettingsModal()

  return (
    <>
      {organizationSettingsModal}
      <Modal isOpen={open} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage id="recording.list.gdprInfo.title" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormattedMessage id="recording.list.gdprInfo.text" />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button variant="outline" onClick={onClose}>
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button onClick={() => showOrganizationSettingsModal()}>
                <FormattedMessage id="recording.list.gdprInfo.button.settings" />
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
