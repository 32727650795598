import {
  createContext,
  type Dispatch,
  type MutableRefObject,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

import { type BasicStepTypes, type StepTypes } from '../../builder/steps/api'
import { CenteredSpinner } from '../../components/centered-spinner'
import { type SectionItem } from './drag-drop-step-editor/draggable-step-sections/types'

type DraggableStepsContextType = {
  isStepCreationMode: boolean
  setIsStepCreationMode: Dispatch<SetStateAction<boolean>>
  activeStepType: StepTypes | BasicStepTypes
  setActiveStepType: Dispatch<SetStateAction<StepTypes | BasicStepTypes>>
  activeStepId: string
  setActiveStepId: Dispatch<SetStateAction<string>>
  onCancel: MutableRefObject<() => void>
  onEdit: MutableRefObject<(item: SectionItem) => Promise<unknown> | void>
  onDelete: MutableRefObject<(id: string) => Promise<void>>
  isReadonly: boolean
  isEditEnabled: boolean
}

const DraggableStepsContext = createContext<DraggableStepsContextType | null>(null)

export const DraggableStepsContextProvider = ({
  children,
  isReadonly,
  isEditEnabled,
}: PropsWithChildren<{ isReadonly: boolean; isEditEnabled: boolean }>) => {
  const [isStepCreationMode, setIsStepCreationMode] = useState<boolean>(false)
  const [activeStepType, setActiveStepType] = useState<StepTypes | BasicStepTypes>(
    'coachingStepBasic'
  )

  const [activeStepId, setActiveStepId] = useState<string>('')

  const onCancel = useRef(() => {})
  const onEdit = useRef(() => {})
  const onDelete = useRef(() => new Promise<void>(() => {}))

  const value = useMemo<DraggableStepsContextType | null>(
    () => ({
      isStepCreationMode,
      setIsStepCreationMode,
      activeStepType,
      setActiveStepType,
      activeStepId,
      setActiveStepId,
      onCancel,
      onEdit,
      onDelete,
      isReadonly,
      isEditEnabled,
    }),
    [
      isStepCreationMode,
      setIsStepCreationMode,
      activeStepType,
      setActiveStepType,
      activeStepId,
      setActiveStepId,
      onCancel,
      onEdit,
      onDelete,
      isReadonly,
      isEditEnabled,
    ]
  )

  if (!value) {
    return <CenteredSpinner />
  }

  return (
    <DraggableStepsContext.Provider value={value}>
      {children}
    </DraggableStepsContext.Provider>
  )
}

export const useDraggableStepsContext = () => {
  const context = useContext(DraggableStepsContext)

  if (!context) {
    throw new Error(
      'useDraggableStepsContext must be used within a DraggableStepsContextProvider'
    )
  }

  return context
}
