/**
 * This is a pure UI component consider moving into ui package
 */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

import { type ContentEditorMessage } from './content-editor/content-editor-api'

type AlertMessageProps = {
  onClose?: () => void
  haveCloseButton?: boolean
} & ContentEditorMessage

export const EditorMessage = ({
  type,
  title,
  description,
  haveCloseButton = true,
  onClose,
}: AlertMessageProps) => (
  <Alert status={type}>
    <AlertIcon />
    <Box>
      <AlertTitle mr={2}>{title && <FormattedMessage id={title} />}</AlertTitle>
      <AlertDescription>
        {Array.isArray(description) ? (
          description.map((error, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              <FormattedMessage id={error.id} values={error.values} />
            </li>
          ))
        ) : (
          <FormattedMessage id={description} />
        )}
      </AlertDescription>
    </Box>
    {haveCloseButton && (
      <CloseButton position="absolute" right="8px" top="8px" onClick={onClose} />
    )}
  </Alert>
)
