import { Button, HStack, Icon, Select } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'
import { AiFillFilter } from 'react-icons/ai'

import { SearchInput } from '../../../components/search-input'
import { type TemplateSortOptions } from '../shared/types'

const sortOptions: Array<TemplateSortOptions> = ['none', 'usageCount', 'createdAt']

export const TemplatesTopBar = ({
  filtersVisible,
  toggleFiltersVisible,
  sorting,
  search,
  setSearch,
}: {
  filtersVisible: boolean
  toggleFiltersVisible: () => void
  sorting?: {
    sortBy: TemplateSortOptions
    setSortBy: (sortBy: TemplateSortOptions) => void
  }
  search: string
  setSearch: (search: string) => void
}) => {
  const { formatMessage } = useIntl()

  return (
    <HStack
      gap={4}
      justify="flex-end"
      pb={4}
      borderBottom="2px solid"
      borderColor={colors.gray[200]}
    >
      <Button
        variant="outline"
        isActive={filtersVisible}
        sx={{
          borderWidth: '1px',
          borderColor: filtersVisible ? colors.blue[50] : 'unset',
          bg: filtersVisible ? `${colors.blue[50]} !important` : 'transparent',
        }}
        rightIcon={<Icon as={AiFillFilter} />}
        onClick={toggleFiltersVisible}
      >
        {formatMessage({
          id: filtersVisible
            ? 'coaching.program.templates.page.filters.hide'
            : 'coaching.program.templates.page.filters.show',
        })}
      </Button>
      {sorting && (
        <Select
          maxW={220}
          value={sorting.sortBy}
          onChange={e => sorting.setSortBy(e.target.value as TemplateSortOptions)}
          borderColor={sorting.sortBy === 'none' ? colors.blue[100] : colors.blue[500]}
          borderRadius={4}
          _hover={{}}
          _focusVisible={{}}
        >
          {sortOptions.map(option => (
            <option key={option} value={option}>
              {formatMessage({
                id: `coaching.program.templates.page.sort.${option}` satisfies I18nKey,
              })}
            </option>
          ))}
        </Select>
      )}
      <SearchInput
        value={search}
        onChange={setSearch}
        placeholderKey="common.search"
        sx={{ alignSelf: 'center' }}
      />
    </HStack>
  )
}
