import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type PropsWithChildren } from 'react'

import {
  type ExtendedProgram,
  type SharedProgram,
  type SharedProgramWithSenderEmail,
} from '../../types/api-types'
import { SharedProgramList } from './shared-program-list'

const TabPanelWithProgramList = ({
  sharedPrograms,
  program,
  isReceivedModal,
}: {
  sharedPrograms: Array<SharedProgram> | undefined
  program?: ExtendedProgram
  isReceivedModal?: boolean
}) => (
  <TabPanel px="0 !important">
    <SharedProgramList
      sharedPrograms={sharedPrograms}
      program={program}
      isReceivedModal={isReceivedModal}
    />
  </TabPanel>
)

const StyledTab = ({ children }: PropsWithChildren) => (
  <Tab
    fontSize="14px"
    fontWeight={500}
    color={colors.gray[800]}
    _focus={{ textColor: colors.blue[700] }}
  >
    {children}
  </Tab>
)

type ReceiverProps = {
  sharedProgramsData: Array<SharedProgramWithSenderEmail>
  type: 'receiver'
}

type SenderProps = {
  sharedProgramsData: Array<SharedProgram>
  type: 'sender'
  program: ExtendedProgram
}

type SharedProgramTabsProps = ReceiverProps | SenderProps

export const SharedProgramTabs = (props: SharedProgramTabsProps) => {
  const { type, sharedProgramsData } = props

  const sharedProgramsWithPendingStatus = sharedProgramsData?.filter(
    sharedProgram => sharedProgram.status === 'pending'
  )

  const sharedProgramsWithAcceptedStatus = sharedProgramsData?.filter(
    sharedProgram => sharedProgram.status === 'accepted'
  )

  const sharedProgramsWithRejectedStatus = sharedProgramsData?.filter(
    sharedProgram => sharedProgram.status === 'rejected'
  )

  const sharedProgramsWithExpiredStatus = sharedProgramsData?.filter(
    sharedProgram => sharedProgram.status === 'expired'
  )

  return (
    <Tabs variant="soft-rounded" size="sm" pt={2}>
      <TabList>
        <StyledTab>
          <FormattedMessage id="coaching.program.send.modal.tabs.requestsStatus.pending" />
        </StyledTab>
        <StyledTab>
          <FormattedMessage id="coaching.program.send.modal.tabs.requestsStatus.accepted" />
        </StyledTab>
        <StyledTab>
          <FormattedMessage id="coaching.program.send.modal.tabs.requestsStatus.rejected" />
        </StyledTab>
        <StyledTab>
          <FormattedMessage id="coaching.program.send.modal.tabs.requestsStatus.expired" />
        </StyledTab>
      </TabList>
      <TabPanels>
        {[
          sharedProgramsWithPendingStatus,
          sharedProgramsWithAcceptedStatus,
          sharedProgramsWithRejectedStatus,
          sharedProgramsWithExpiredStatus,
        ].map((sharedPrograms, index) => (
          <TabPanelWithProgramList
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            sharedPrograms={sharedPrograms}
            isReceivedModal={type === 'receiver'}
            // eslint-disable-next-line react/destructuring-assignment
            program={type === 'sender' ? props.program : undefined}
          />
        ))}
      </TabPanels>
    </Tabs>
  )
}
