const types = {
  CREATE_FOLDER_REQUEST: 'folders/create_request',
  INITIALIZE_FOLDERS: 'folders/init',
  CREATE_FOLDER_SUCCESS: 'folders/create_success',
  DELETE_FOLDER_REQUEST: 'folders/delete_request',
  DELETE_FOLDER_SUCCESS: 'folders/delete_success',
  RECEIVE_FOLDERS: 'folders/receive',
  UPDATE_FOLDER_REQUEST: 'folders/update_request',
  UPDATE_FOLDER_SUCCESS: 'folders/update_success',
  SET_ACIVE_FOLDER: 'folders/SET_ACIVE_FOLDER',
}

// eslint-disable-next-line import/no-default-export
export default types

export const initializeFolders = () => ({
  type: types.INITIALIZE_FOLDERS,
})

export const receiveFolders = folders => ({
  type: types.RECEIVE_FOLDERS,
  data: folders,
})

export const deleteFolderRequest = id => ({
  type: types.DELETE_FOLDER_REQUEST,
  data: id,
})

export const deleteFolderSuccess = id => ({
  type: types.DELETE_FOLDER_SUCCESS,
  data: id,
})

export const updateFolderRequest = id => ({
  type: types.UPDATE_FOLDER_REQUEST,
  data: id,
})

export const updateFolderSuccess = id => ({
  type: types.UPDATE_FOLDER_SUCCESS,
  data: id,
})

export const createFolderRequest = () => ({ type: types.CREATE_FOLDER_REQUEST })

export const createFolderSuccess = folder => ({
  type: types.CREATE_FOLDER_REQUEST,
  data: folder,
})
