import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useParams } from 'react-router-dom'

import { ContentLoader } from '../../builder/components/content-loader'
import { AppHeaderTitle } from '../../components/app-header-title'
import { type Cohort } from '../types/cohort'
import { useCohorts } from '../use-cohorts'
import { CohortDetailsHeader } from './cohort-details-header'
import { CohortEmailParticipants } from './cohort-email-participants/cohort-email-participants'
import { CohortParticipants } from './cohort-participants'
import { CohortPrograms } from './cohort-programs'

export const CohortDetails = ({ isBenchmarking }: { isBenchmarking: boolean }) => {
  const { id: cohortId } = useParams<{ id: string }>() as {
    id: string
  }

  const { useCohort, deleteCohort } = useCohorts()
  const { data, status, refresh: refreshCohortList } = useCohort(cohortId)
  const isTokenCohort = data?.type === 'token'

  const deleteCohortById = async (cohortExtId: string) => {
    await deleteCohort(cohortExtId)
    refreshCohortList()
  }

  return (
    <Box display="flex" flexDir="column" w="100%">
      <AppHeaderTitle
        formattedMessageId={
          isBenchmarking ? 'coaching.benchmarking.title' : 'coaching.participants.title'
        }
      />
      <ContentLoader status={status}>
        <CohortDetailsHeader
          cohort={data as Cohort}
          deleteCohort={deleteCohortById}
          isBenchmarking={isBenchmarking}
        />
        <Flex alignItems="center" p={6} pb={0} overflow="hidden">
          <Tabs h="100%" w="100%" display="flex" flexDir="column">
            <TabList>
              <Tab>
                <FormattedMessage
                  id={
                    isTokenCohort
                      ? 'cohort.details.tokens'
                      : 'cohort.details.participants'
                  }
                />
              </Tab>
              <Tab>
                <FormattedMessage id="cohort.details.programs" />
              </Tab>
            </TabList>

            <TabPanels overflow="hidden">
              <TabPanel height="100%" display="flex" flexDir="column">
                {isTokenCohort ? <CohortParticipants /> : <CohortEmailParticipants />}
              </TabPanel>
              <TabPanel height="100%" display="flex" flexDir="column">
                <CohortPrograms />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </ContentLoader>
    </Box>
  )
}
