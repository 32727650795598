import { Box, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useMemo, useState } from 'react'

import {
  get5SegmentDescription,
  type Result,
  type SegmentEnum,
} from '../../hooks/use-result'
import { MoonChart } from '../charts/moon-chart'
import { type DataItem, TargetMatchChart } from '../charts/target-match-chart'
import { Dot } from './dot'

type CandidatePersonalityProps = {
  result: Result
  big5Data: Array<DataItem>
  showTargetProfile: boolean
  candidateView: boolean
}

const segments: Array<SegmentEnum> = ['low', 'mediumLow', 'medium', 'mediumHigh', 'high']

export const CandidatePersonality = ({
  result,
  big5Data,
  showTargetProfile,
  candidateView,
}: CandidatePersonalityProps) => {
  const { benchmarkValues, rawValues, benchmarkWeights } = result
  const [selectedPersonalityTrait, setSelectedPersonalityTrait] =
    useState('conscientiousness')

  const fieldKeyStart = candidateView
    ? 'meta.benchmarkProfile.you.field'
    : 'meta.benchmarkProfile.field'

  const keyStart = candidateView
    ? 'recording.details.big5PersonalityProfile.you'
    : 'recording.details.big5PersonalityProfile'

  const realSector = useMemo(
    () =>
      segments.findIndex(
        val =>
          val === get5SegmentDescription(rawValues[selectedPersonalityTrait] as number)
      ),
    [rawValues, selectedPersonalityTrait]
  )

  const targetSector = useMemo(
    () =>
      benchmarkWeights[selectedPersonalityTrait]
        ? segments.findIndex(
            val =>
              val ===
              get5SegmentDescription(benchmarkValues[selectedPersonalityTrait] as number)
          )
        : undefined,
    [selectedPersonalityTrait, benchmarkValues, benchmarkWeights]
  )

  return (
    <Box>
      <Box py={5}>
        <Heading mb={6}>
          <FormattedMessage id="recording.details.big5PersonalityProfile" />
        </Heading>
        <FormattedMessage id={`${keyStart}.description`} />
      </Box>
      <Grid templateColumns="repeat(2, 1fr)" gap={3}>
        <GridItem colSpan={{ sm: 2, md: 2, lg: 2, xl: 2, '2xl': 1 }}>
          <Flex justifyContent="center">
            <Box
              borderRadius={5}
              bg="#F2F7FF"
              px={8}
              py={5}
              maxW="600px"
              sx={{
                'div > svg': {
                  overflow: 'visible',
                },
              }}
            >
              <Heading fontSize="lg" color="#0E3170" mb={2} display="inline">
                <FormattedMessage
                  id={
                    `meta.benchmarkProfile.field.${selectedPersonalityTrait}.title.result` as I18nKey
                  }
                />
              </Heading>
              <Text fontSize="md" color="#BAD0F9" display="inline" ml={2} mr={2}>
                —
              </Text>
              <Text fontSize="md" color="#17459C" fontWeight={500} display="inline">
                <FormattedMessage
                  id={
                    `meta.benchmarkProfile.field.${selectedPersonalityTrait}.${get5SegmentDescription(
                      rawValues[selectedPersonalityTrait] as number
                    )}.adjectives.0.result` as I18nKey
                  }
                />
              </Text>
              <Text fontSize="sm" mt={5} mb={6}>
                <FormattedMessage
                  id={
                    `${fieldKeyStart}.${selectedPersonalityTrait}.${get5SegmentDescription(
                      rawValues[selectedPersonalityTrait] as number
                    )}.description.result` as I18nKey
                  }
                />
              </Text>
              <Flex alignItems="center" flexDir="column">
                <MoonChart
                  showTargetProfile={showTargetProfile}
                  segments={segments}
                  targetSegment={targetSector}
                  realSegment={realSector}
                  width={450}
                  height={160}
                  selectedDimension={selectedPersonalityTrait}
                />
                <Text color="#0E3170" fontWeight={500} fontSize="lg" mb={6}>
                  <FormattedMessage
                    id={
                      `meta.benchmarkProfile.field.${selectedPersonalityTrait}.title.result` as I18nKey
                    }
                  />
                </Text>
                <Grid
                  templateColumns={showTargetProfile ? 'repeat(2, 1fr)' : ''}
                  gap={5}
                  fontWeight={500}
                >
                  {showTargetProfile && (
                    <GridItem>
                      {targetSector !== undefined ? (
                        <Flex alignItems="center">
                          <Dot backgroundColor="#78E296" />
                          <Text ml={2}>
                            <FormattedMessage id="recording.details.big5PersonalityProfile.targetProfile.label" />
                          </Text>
                        </Flex>
                      ) : (
                        <FormattedMessage id="targetMatchChart.field.excluded" />
                      )}
                    </GridItem>
                  )}
                  <GridItem>
                    <Flex alignItems="center">
                      <Dot backgroundColor="#17459C" />
                      <Text ml={2}>
                        <FormattedMessage id={`${keyStart}.candidateResult.label`} />
                      </Text>
                    </Flex>
                  </GridItem>
                </Grid>
              </Flex>
            </Box>
          </Flex>
        </GridItem>
        <GridItem colSpan={{ sm: 2, md: 2, lg: 2, xl: 2, '2xl': 1 }}>
          <Flex justifyContent="center">
            <TargetMatchChart
              candidateView={candidateView}
              showTargetProfile={showTargetProfile}
              data={big5Data}
              width={350}
              height={400}
              onLabelClick={setSelectedPersonalityTrait}
              selectedTick={selectedPersonalityTrait}
            />
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  )
}
