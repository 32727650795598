import { Flex } from '@chakra-ui/react'
import { ScoreGraph } from '@repo/ui'
import { chunk } from 'lodash-es'
import { useMemo } from 'react'

import { PageNavigator } from '../../../../components/page-navigator'
import { useDashboardContext } from '../../../shared/dashboard-context'
import { type GeneralScore, type GeneralScoreData } from '../../../shared/types'
import { DashboardCard } from '../shared/dashboard-card'

type ScorePerCohortCardProps = {
  data: {
    averageScoreData: GeneralScoreData
    averageScore: GeneralScore
  }
}

const PAGE_COHORT_LIMIT = 3

export const ScorePerCohortCard = ({ data }: ScorePerCohortCardProps) => {
  const {
    filters: { selectedCohorts: cohorts },
  } = useDashboardContext()

  const cohortChunks = useMemo(() => chunk(cohorts, PAGE_COHORT_LIMIT), [cohorts])

  return (
    <DashboardCard
      colSpan={1}
      wrapperProps={{ pt: 5 }}
      titleKey="dashboard.generalScore.scorePerCohort"
    >
      <PageNavigator
        wrapperSx={{
          gap: '80px',
          width: '100%',
        }}
        pages={cohortChunks.map((cohortChunk, chunkIndex) => ({
          content: (
            <Flex justifyContent="space-evenly">
              {cohortChunk.map((cohort, indexInChunk) => {
                const cohortIndex = chunkIndex * PAGE_COHORT_LIMIT + indexInChunk
                const cohortScore = data.averageScoreData[cohortIndex]

                return (
                  <ScoreGraph
                    key={cohort.extId}
                    size={90}
                    maxWidth="80px"
                    value={cohortScore}
                    label={cohort.name}
                  />
                )
              })}
            </Flex>
          ),
        }))}
      />
    </DashboardCard>
  )
}
