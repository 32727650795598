import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Icon,
  IconButton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { PageHeader } from '@repo/ui'
import { memo } from 'react'
import { RiEditBoxLine, RiFileListLine, RiGridFill, RiShareBoxFill } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { type SWRInfiniteKeyedMutator } from 'swr/infinite'

import { Interpolated } from '../../components/interpolated'
import { useCurrentTokenData } from '../../hooks/use-current-token-data'
import { EditFolderModal } from '../../recruiting/folder-editor/edit-folder-modal'
import { type RecordingSession } from '../../recruiting/recording-sessions-api'
import { type Folder } from '../../store/entities/folders/folders-types'
import { selectRootFolderId } from '../../store/entities/folders/selectors'
import { ExportModal } from '../data-export/export-modal'
import { SetupInterviewButton } from './setup-interview-button'
import { type RecordingView, type SetRecordingView } from './use-recording-view'

type RecordingListHeaderProps = {
  folder: Folder
  onChangeRecordingView: SetRecordingView
  recordingView: RecordingView
  pluginId: string
  updateRecordings: SWRInfiniteKeyedMutator<Array<Array<RecordingSession>>>
}

export const RecordingListHeader = memo(
  ({
    folder,
    onChangeRecordingView,
    recordingView,
    pluginId,
    updateRecordings,
  }: RecordingListHeaderProps) => {
    const folderId = folder.id
    const { isOwner } = useCurrentTokenData()
    const {
      isOpen: isOpenEditFolderModal,
      onOpen: onOpenEditFolderModal,
      onClose: onCloseEditFolderModal,
    } = useDisclosure()

    const rootId = useSelector(selectRootFolderId)
    const isRootFolder = folderId === rootId
    const isFolderEditable = isOwner && !isRootFolder

    const exportModal = useDisclosure()

    return (
      <Box pt="24px">
        <ExportModal isOpen={exportModal.isOpen} onClose={exportModal.onClose} />
        <PageHeader
          title={
            <Flex direction="column" pb={['100px', '100px', '100px', '0px']}>
              <Interpolated text={folder.name} />
            </Flex>
          }
          buttons={
            <Stack direction={['column', 'column', 'column', 'row']} mb={4} mt={2}>
              <SetupInterviewButton disabled={isRootFolder} pluginId={pluginId} />
              <Button
                variant="outline"
                leftIcon={<Icon boxSize="1.25em" as={RiShareBoxFill} />}
                onClick={exportModal.onOpen}
              >
                <FormattedMessage id="export.modal.button.label" />
              </Button>
              <Button
                variant="outline"
                leftIcon={<Icon boxSize="1.25em" as={RiEditBoxLine} />}
                onClick={onOpenEditFolderModal}
                isDisabled={!isFolderEditable}
              >
                <FormattedMessage id="recording.list.button.editList" />
              </Button>

              <ButtonGroup isAttached variant="outline">
                <IconButton
                  aria-label=""
                  variant={recordingView === 'list' ? 'primary' : 'outline'}
                  icon={<RiFileListLine fontSize="1.25em" />}
                  onClick={() => onChangeRecordingView('list')}
                />
                <IconButton
                  aria-label=""
                  variant={recordingView === 'card' ? 'primary' : 'outline'}
                  icon={<RiGridFill fontSize="1.25em" />}
                  onClick={() => onChangeRecordingView('card')}
                />
              </ButtonGroup>

              <EditFolderModal
                id={folderId}
                isOpen={isOpenEditFolderModal}
                onClose={onCloseEditFolderModal}
                updateRecordings={updateRecordings}
              />
            </Stack>
          }
        />
        <Divider borderColor="gray.300" />
      </Box>
    )
  }
)
