import { useIntl } from '@repo/i18n'
import { type Nil } from '@repo/utils'
import { useCallback, useMemo } from 'react'

import {
  type FieldName,
  isMetaField,
  isSummaryField,
  type RecordingSession,
} from '../recruiting/recording-sessions-api'
import { getValues, getWeights } from '../utils/benchmark'
import { formatDateTime } from '../utils/dates'
import { normalize, trimValue } from '../utils/math'

// TODO: remove after percentiles
// 0.15, 0.50, 0.85 percentiles
const BENCHMARK_THRESHOLDS = [0.759405, 0.857022, 0.911956]

// TODO: Should be moved to a types.ts file
export type Values = {
  [key: string]: number
}

type Score = {
  id: string
  rawValue: number
  score: number
  description: string
}

export type Scores = Array<Score>

type Recommendation = {
  text: string
  color: string
}

type Meta = {
  name: string
  email: string
  id: string | undefined
  createdAt: string
}

export type Result = {
  meta: Meta
  recommendation: Recommendation
  benchmarkValues: Values
  benchmarkThresholds: Array<number>
  benchmarkWeights: Values
  overallScore: number
  scores: Scores
  rawValues: Values
  getResultFieldValue: (field: FieldName) => string
  isFieldInBenchmark: (field: FieldName) => boolean
}

export type SegmentEnum = 'low' | 'mediumLow' | 'medium' | 'mediumHigh' | 'high'

export type ScoreRatingEnum = 'noScores' | 'low' | 'medium' | 'good' | 'great'

export const getOverallScoreRating = (
  overallScore = 0
): Exclude<ScoreRatingEnum, 'noScores'> => {
  if (overallScore < 0.76) {
    return 'low'
  }

  if (overallScore < 0.86) {
    return 'medium'
  }

  if (overallScore < 0.91) {
    return 'good'
  }

  return 'great'
}

export const getScoreRating = (score: number): ScoreRatingEnum => {
  if (Number.isNaN(score)) {
    return 'noScores'
  }

  if (score < 0.5) {
    return 'low'
  }

  if (score < 0.68) {
    return 'medium'
  }

  if (score < 0.95) {
    return 'good'
  }

  return 'great'
}

export const get5SegmentDescription = (rawValue: number): SegmentEnum => {
  if (rawValue < 0.2) {
    return 'low'
  }

  if (rawValue < 0.4) {
    return 'mediumLow'
  }

  if (rawValue < 0.6) {
    return 'medium'
  }

  if (rawValue < 0.8) {
    return 'mediumHigh'
  }

  return 'high'
}

export const get3SegmentDescription = (rawValue: number): SegmentEnum => {
  if (rawValue < 0.4) {
    return 'low'
  }

  if (rawValue < 0.6) {
    return 'medium'
  }

  return 'high'
}

export const getAdjustedScore = (
  score: number,
  leftThreshold: number,
  rightThreshold: number
) => {
  const trimmedScore = trimValue(score, leftThreshold, rightThreshold)
  const adjustedScore = normalize(trimmedScore, leftThreshold, rightThreshold)

  return adjustedScore
}

export const useResult = (session: Nil<RecordingSession>) => {
  const { formatMessage } = useIntl()

  // TODO: instead of changing format to old version, update how this value is used
  const benchmarkValues = useMemo(
    () =>
      session?.benchmarkProfile !== undefined ? getValues(session.benchmarkProfile) : {},
    [session]
  )

  const benchmarkWeights = useMemo(
    () =>
      session?.benchmarkProfile !== undefined
        ? getWeights(session?.benchmarkProfile)
        : {},
    [session]
  )

  const isFieldInBenchmark = useCallback(
    (field: FieldName) => benchmarkWeights[field] !== 0,
    [benchmarkWeights]
  )

  // we get raw results from backend (except overallScore which is a score)
  const rawValues = useMemo(() => session?.result ?? {}, [session])
  const overallScore = useMemo(() => rawValues?.overallScore, [rawValues])

  const scores = useMemo(
    () =>
      Object.keys(benchmarkValues)
        .filter(attr => {
          const rawValue = rawValues?.[attr]
          const idealValue = benchmarkValues[attr] as number

          // don't erroneously filter out 0 raw values
          return rawValue !== undefined && idealValue !== undefined
        })
        .filter(attr => isFieldInBenchmark(attr as FieldName))
        .map(attr => {
          const rawValue = rawValues[attr] as number
          const idealValue = benchmarkValues[attr] as number

          return {
            id: attr,
            rawValue,
            // TODO: we should get scores from backend as well
            score:
              1 - Math.abs(idealValue - rawValue) / Math.max(idealValue, 1 - idealValue),
            description: formatMessage({
              id: `meta.${attr}.${get5SegmentDescription(rawValue)}` as I18nKey,
            }),
          }
        })
        .sort((a, b) => b.score - a.score),
    [benchmarkValues, formatMessage, rawValues, isFieldInBenchmark]
  )

  const meta = useMemo(
    () => ({
      name: session?.meta?.name || 'N/A',
      email: session?.meta?.email || 'N/A',
      id: session?.id,
      createdAt: session?.createdAt.substr(0, 10) || 'N/A',
    }),
    [session]
  )

  const recommendation: Recommendation = useMemo(() => {
    const label = getOverallScoreRating(overallScore)

    switch (label) {
      case 'great':
        return {
          text: formatMessage({ id: 'recording.result.recommendation.high' }),
          color: '#E6F9F7',
        }
      case 'good':
        return {
          text: formatMessage({ id: 'recording.result.recommendation.medium' }),
          color: '#F1FCF4',
        }
      case 'medium':
        return {
          text: formatMessage({ id: 'recording.result.recommendation.mediumLow' }),
          color: '#FFF8F1',
        }
      default:
        return {
          text: formatMessage({ id: 'recording.result.recommendation.low' }),
          color: '#FEF1F1',
        }
    }
  }, [overallScore, formatMessage])

  const getResultFieldValue = (field: FieldName): string => {
    if (field === 'overallScore') {
      return overallScore ? overallScore.toString() : 'N/A'
    }

    if (field === 'id') {
      return session ? session[field] : 'N/A'
    }

    if (field === 'createdAt') {
      return session ? formatDateTime(session[field]) : 'N/A'
    }

    if (isMetaField(field)) {
      return meta[field]?.toString() || 'N/A'
    }

    if (isSummaryField(field)) {
      return rawValues[field]?.toString() || 'N/A'
    }

    return 'N/A'
  }

  return {
    benchmarkValues,
    benchmarkWeights,
    benchmarkThresholds: BENCHMARK_THRESHOLDS,
    rawValues,
    scores,
    overallScore,
    meta,
    recommendation,
    getResultFieldValue,
    isFieldInBenchmark,
  } as Result
}
