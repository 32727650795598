import { Checkbox, type CheckboxProps } from '@chakra-ui/react'
import { Controller, type FieldPath, type FieldValues } from 'react-hook-form'

import { FormControlWrapper } from './shared/form-control-wrapper'
import { type FieldBaseProps } from './shared/types'

export const FormFieldCheckbox = <T extends FieldValues, TName extends FieldPath<T>>(
  props: FieldBaseProps<T, CheckboxProps, TName>
) => {
  const { name, control, rules, shouldUnregister, componentProps } = props

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FormControlWrapper fieldBaseProps={props} error={fieldState.error}>
          <Checkbox
            {...componentProps}
            {...field}
            isChecked={field.value}
            onChange={e => {
              field.onChange(e.target.checked)
              componentProps?.onChange?.(e)
            }}
          />
        </FormControlWrapper>
      )}
    />
  )
}
