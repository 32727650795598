import { Box, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { times } from 'lodash-es'
import { type ComponentType } from 'react'

import { CardGrid } from './card-grid'

type TemplateGridProps<T extends { extId: string }> = {
  templates: Array<T>
  isLoading: boolean
  title: I18nKey
  CardComponent: ComponentType<{ template: T }>
}

export const TemplateGrid = <T extends { extId: string }>({
  templates,
  isLoading,
  title,
  CardComponent,
}: TemplateGridProps<T>) => (
  <Box p={6} overflowY="auto" flex={1}>
    <Text mb={6} fontSize={18} fontWeight={600}>
      <FormattedMessage id={title} />
      {!isLoading ? ` (${templates.length})` : null}
    </Text>
    <CardGrid>
      {isLoading
        ? times(12, i => <CardGrid.SkeletonCard key={i} />)
        : templates.map(template => (
            <CardComponent key={template.extId} template={template} />
          ))}
    </CardGrid>
  </Box>
)
