import { Button, Center } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { FormikFormField } from '@repo/ui'
import { Form, useFormikContext } from 'formik'

import { type CohortCreationValues } from '../types'

export const NameStep = () => {
  const { formatMessage } = useIntl()
  const {
    errors: { name: nameError },
    handleSubmit,
    values: { name },
  } = useFormikContext<CohortCreationValues>()

  return (
    <Form onSubmit={handleSubmit}>
      <FormikFormField
        errors={nameError}
        subLabel={formatMessage({ id: 'cohort.creation.name.step.input.label' })}
        name="name"
        placeholder={formatMessage({
          id: 'cohort.creation.name.step.placeholder',
        })}
        customValue={name}
      />
      <Center>
        <Button type="submit" position="absolute" bottom={8} isDisabled={!name}>
          <FormattedMessage id="common.saveAndContinue" />
        </Button>
      </Center>
    </Form>
  )
}
