import { Button, Center, Code, Heading } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { isDevelopment, Log } from '@repo/utils'
import { type PropsWithChildren } from 'react'
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary'

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => (
  <Center gap={2} flexDir="column" h="full" w="full">
    <Heading as="h6" color="gray.600" size="md">
      {isDevelopment ? (
        error.name
      ) : (
        <FormattedMessage id="errors.componentFailed.prod.title" />
      )}
    </Heading>

    {isDevelopment ? (
      <>
        <FormattedMessage id="errors.componentFailed.dev.title" />
        {error.message && <Code>{error.message}</Code>}
        <FormattedMessage id="errors.componentFailed.dev.info" />
      </>
    ) : (
      <FormattedMessage id="errors.componentFailed.prod.info" />
    )}

    <Button type="button" onClick={resetErrorBoundary}>
      <FormattedMessage id="errors.componentFailed.retryBtn" />
    </Button>
  </Center>
)

export const ReloadableErrorBoundary = ({ children }: PropsWithChildren) => (
  <ErrorBoundary
    onError={error => {
      Log.error(new Error('Error caught in ErrorBoundary'), {
        extra: { cause: error },
      })
    }}
    FallbackComponent={ErrorFallback}
  >
    {children}
  </ErrorBoundary>
)
