import { TableCell, TableRow } from '@mui/material'
import { EmptyStateIndicator } from '@repo/ui'

export const TableEmptyState = ({
  emptyStateLabelKey,
  hasSearchFilter,
  columnCount,
}: {
  emptyStateLabelKey?: I18nKey
  hasSearchFilter: boolean
  columnCount: number
}) => (
  <TableRow>
    <TableCell colSpan={columnCount}>
      <EmptyStateIndicator
        messageKey={
          hasSearchFilter
            ? 'general.noSearchResult'
            : (emptyStateLabelKey ?? 'general.noDataAvailable')
        }
      />
    </TableCell>
  </TableRow>
)
