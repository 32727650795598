import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast, useConfirmationModal } from '@repo/ui'
import { RiMoreFill } from 'react-icons/ri'

import { type Instruction } from '../../../../../../types/api-types'
import { client } from '../../../../../../utils/openapi-client'
import { useRealtimeBuilderContext } from '../../../../shared/realtime-builder-context'
import { useEditInstructionModal } from './use-edit-instruction-modal'

type OptionsMenuProps = {
  instruction: Instruction
}

export const OptionsMenu = ({ instruction }: OptionsMenuProps) => {
  const {
    programExtId,
    scenarioExtId,
    trainingExtId,
    mutateScenario,
    isBuilderReadonly,
  } = useRealtimeBuilderContext()

  const [editModal, showEditModal] = useEditInstructionModal(instruction)

  const [deleteModal, showDeleteModal] = useConfirmationModal({
    onConfirm: async () => {
      try {
        const res = await client.delete('deleteInstruction', {
          params: {
            path: {
              extId: instruction.extId,
              programExtId,
              scenarioExtId,
              trainingExtId,
            },
          },
        })

        if (res.error) {
          throw new Error()
        }

        await mutateScenario()
        showToast({ messageKey: 'common.alert.deleted', status: 'success' })
      } catch (error) {
        showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }
    },
    titleKey: 'scenario.builder.realtime.steps.instruction.delete.title',
    descriptionKey: 'scenario.builder.realtime.steps.instruction.delete.text',
    isDestructive: true,
  })

  return (
    <Menu>
      {deleteModal}
      {editModal}

      <MenuButton
        as={IconButton}
        variant="ghost"
        icon={<RiMoreFill />}
        onClick={e => e.stopPropagation()}
      />
      <MenuList onClick={e => e.stopPropagation()}>
        <MenuItem onClick={showEditModal} isDisabled={isBuilderReadonly}>
          <FormattedMessage id="common.edit" />
        </MenuItem>
        <MenuItem
          color="indicator.red-dark"
          onClick={showDeleteModal}
          isDisabled={isBuilderReadonly}
        >
          <FormattedMessage id="common.delete" />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
