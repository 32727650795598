import {
  Tab,
  TabList,
  TabPanel as ChakraTabPanel,
  type TabPanelProps,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useNavigate, useParams } from 'react-router-dom'

import { AppHeaderTitle } from '../../components/app-header-title'
import { PageList } from '../../containers/page-list'
import { CohortsTab } from './cohorts-tab'
import { ProfilesTab } from './profiles-tab'
import { RecordingsTabDataLoader } from './recordings-tab-data-loader'

type TabType = 'profiles' | 'cohorts' | 'recordings'

const TabPanel = ({ ...props }: TabPanelProps) => (
  <ChakraTabPanel {...props} display="flex" flexDir="column" p={6} />
)

const getOpenTab = (tab?: TabType) => {
  switch (tab) {
    case 'cohorts':
      return 1
    case 'recordings':
      return 2
    default:
      return 0
  }
}

export const BenchmarkPage = () => {
  const navigate = useNavigate()
  // When navigating back from cohort detail view, this makes sure the cohort
  // tab is opened instead of the profiles tab
  const { tab } = useParams<{ tab: TabType }>()
  const openTab = getOpenTab(tab)

  return (
    <PageList display="flex" flexDir="column" pb={0}>
      <AppHeaderTitle formattedMessageId="coaching.benchmarking.title" />
      <Tabs h="100%" w="100%" display="flex" flexDir="column" isLazy index={openTab}>
        <TabList>
          <Tab onClick={() => navigate('/coaching/benchmarking/profiles')}>
            <FormattedMessage id="coaching.benchmarking.tabs.profiles" />
          </Tab>
          <Tab onClick={() => navigate('/coaching/benchmarking/cohorts')}>
            <FormattedMessage id="coaching.benchmarking.tabs.cohorts" />
          </Tab>
          <Tab onClick={() => navigate('/coaching/benchmarking/recordings')}>
            <FormattedMessage id="coaching.benchmarking.tabs.recordings" />
          </Tab>
        </TabList>
        <TabPanels overflow="auto">
          <TabPanel>
            <ProfilesTab />
          </TabPanel>
          <TabPanel>
            <CohortsTab />
          </TabPanel>
          <TabPanel>
            <RecordingsTabDataLoader />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageList>
  )
}
