// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const wait = (thenable, duration = 500): any => {
  const start = Date.now()

  return new Promise((resolve, reject) => {
    const call = async () => {
      try {
        const data = await thenable

        if (Date.now() - start <= duration) {
          setTimeout(() => resolve(data), duration)
        } else {
          resolve(data)
        }
      } catch (error) {
        setTimeout(() => reject(error), duration)
      }
    }

    call()
  })
}
