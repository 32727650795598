import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  type InputGroupProps,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'
import { FiSearch, FiX } from 'react-icons/fi'

export interface SearchInputProps {
  value: string
  onChange: (value: string) => void
  placeholderKey?: I18nKey
  sx?: InputGroupProps['sx']
}

export const SearchInput = ({
  value,
  onChange,
  placeholderKey = 'common.search',
  sx,
}: SearchInputProps) => {
  const { formatMessage } = useIntl()

  return (
    <InputGroup alignSelf="flex-end" width="fit-content" sx={sx}>
      <InputLeftElement pointerEvents="none">
        <Icon as={FiSearch} color={colors.blue[400]} />
      </InputLeftElement>
      <Input
        borderRadius="full"
        maxWidth="200px"
        value={value}
        onChange={event => onChange(event.target.value)}
        placeholder={formatMessage({ id: placeholderKey })}
      />

      {!!value && (
        <InputRightElement>
          <IconButton
            isRound
            size="xs"
            aria-label="Clear search input"
            onClick={() => onChange('')}
            icon={<FiX />}
            variant="ghost"
          />
        </InputRightElement>
      )}
    </InputGroup>
  )
}
