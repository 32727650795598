import { Box, Button, Flex, Link, Text } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, showToast } from '@repo/ui'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'

import { ContentLoader } from '../../../builder/components/content-loader'
import { CohortProgramAssignForm } from '../../../coaching-participants/components/cohort-program-assign-form'
import { cohortProgramFormValidator } from '../../../coaching-participants/helpers/cohort-program-form-validator'
import { type CohortProgramAssignFormValues } from '../../../coaching-participants/types/cohort-program-assign-form-values'
import { useCohortPrograms } from '../../../coaching-participants/use-cohort-programs'
import { ButtonContainer } from './button-container'
import { ContentContainer } from './content-container'

type TrainingProgramScreenProps = {
  handleCancel: VoidFunction
  handleContinue: VoidFunction
  cohortExtId: string
}

export const TrainingProgramScreen = ({
  cohortExtId,
  handleCancel,
  handleContinue,
}: TrainingProgramScreenProps) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { createCohortPrograms, useAvailablePrograms } = useCohortPrograms()
  const { data, status, isLoading } = useAvailablePrograms(cohortExtId)
  const availablePrograms = data || []

  const handleSubmit = async (formData: CohortProgramAssignFormValues) => {
    const dates = {
      startDate: formData.startDate,
      endDate: formData.endDate || null,
    }

    try {
      await createCohortPrograms(
        { ...dates, programExtIds: formData.programIds },
        cohortExtId
      )

      handleContinue()
    } catch (err) {
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }
  }

  return (
    <Box position="relative" top="-50">
      <ContentContainer
        title={formatMessage({
          id: 'coaching.benchmarking.wizard.trainingProgram.title',
        })}
        subtitle={formatMessage({
          id: 'coaching.benchmarking.wizard.trainingProgram.subtitle',
        })}
      >
        <Text
          color={colors.primary}
          as={Link}
          onClick={() => navigate('/training_programs?create=true')}
          mb={4}
        >
          <FormattedMessage id="coaching.benchmarking.wizard.trainingProgram.linkToProgramCreate" />
        </Text>
        <Flex mt={8} h="420px">
          <ContentLoader status={status}>
            <Formik<CohortProgramAssignFormValues>
              onSubmit={handleSubmit}
              initialValues={{
                programIds: [],
                startDate: new Date(),
                endDate: null,
              }}
              validationSchema={cohortProgramFormValidator}
              validateOnBlur={false}
            >
              {({ submitForm, isValid, dirty, isSubmitting }) => (
                <>
                  <Box
                    height="100%"
                    overflow="hidden"
                    sx={{ '.training-list': { maxH: '208px' } }}
                  >
                    <CohortProgramAssignForm
                      showActionsButtons={false}
                      availablePrograms={availablePrograms}
                      isLoading={isLoading}
                    />
                  </Box>
                  <ButtonContainer bottom="-10">
                    <Button variant="outline" onClick={handleCancel}>
                      <FormattedMessage id="common.cancel" />
                    </Button>
                    <Button
                      isLoading={isSubmitting}
                      isDisabled={!(dirty && isValid)}
                      onClick={submitForm}
                    >
                      <FormattedMessage id="common.continue" />
                    </Button>
                  </ButtonContainer>
                </>
              )}
            </Formik>
          </ContentLoader>
        </Flex>
      </ContentContainer>
    </Box>
  )
}
