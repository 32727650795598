export type Folder = {
  id: string
  name: string
  parent: string | null
  path: string
}

export const FolderStoreStatus = {
  IDLE: 0,
  INITIALIZING: 1,
  INITIALIZED: 2,
} as const
