import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { type ProgramLanguages } from '@repo/api'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { Formik } from 'formik'
import { difference } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'

import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { useBenchmarkProfiles } from '../hooks/use-benchmark-profiles'
import {
  type ProgramTrainingScenariosTree,
  type ProgramTrainingScenariosTreeResponse,
} from '../types/program-training-scenario-tree'
import {
  SelectScenariosForm,
  type SelectScenariosFormValues,
} from './select-scenarios-form'

type LinkScenariosModalProps = {
  extId: string
  language: ProgramLanguages
  onClose: VoidFunction
  lockedScenarioExtIds?: Array<string>
}

export const LinkScenariosModal = ({
  extId,
  language,
  onClose,
  lockedScenarioExtIds = [],
}: LinkScenariosModalProps) => {
  const { formatMessage } = useIntl()
  const { linkScenarios, useGetAll, getProgramTrainingScenariosTree } =
    useBenchmarkProfiles()

  const { refresh: refreshList } = useGetAll()
  const [programTrainingScenarioTree, setProgramTrainingScenarioTree] =
    useState<ProgramTrainingScenariosTree>([])

  const [scenarios, setScenarios] = useState<
    Array<ProgramTrainingScenariosTreeResponse['scenarios'][number]>
  >([])

  const [scenarioExtIdsToLinkState, setScenarioExtIdsToLinkState] = useState<
    Array<string>
  >([])

  const [scenariosWithSessionsTitles, setScenariosWithSessionsTitles] = useState<
    Array<string>
  >([])

  const fetchTree = useCallback(async () => {
    const { scenariosTree: tree, scenarios: scenarioData } =
      (await getProgramTrainingScenariosTree({
        benchmarkProfileToFilterFor: extId,
        language,
      })) || {}

    setProgramTrainingScenarioTree(tree || [])
    setScenarios(scenarioData || [])
  }, [extId, getProgramTrainingScenariosTree, language])

  useEffect(() => {
    if (!programTrainingScenarioTree.length) {
      fetchTree()
    }
  }, [programTrainingScenarioTree.length, getProgramTrainingScenariosTree, fetchTree])

  const linkScenariosHandler = useCallback(
    async (scenarioExtIds: Array<string>) => {
      try {
        await linkScenarios(extId, scenarioExtIds)
        refreshList()
        showToast({ messageKey: 'common.alert.created', status: 'success' })
      } catch (err) {
        showToast({ messageKey: 'general.error.saving.data', status: 'error' })
      }

      onClose()
    },
    [extId, linkScenarios, onClose, refreshList]
  )

  const onSubmit = async (
    formData: SelectScenariosFormValues,
    openInterceptAndConfirm: () => void
  ) => {
    // Don't link already linked scenarios again
    const scenariosToLink = difference(
      formData.selectedScenarioExtIds,
      lockedScenarioExtIds
    )

    setScenarioExtIdsToLinkState(scenariosToLink)
    // Get scenarios that have recording sessions
    const scenariosExtIdsWithSessions = scenariosToLink.filter(
      scenarioExtId =>
        !!scenarios.find(
          s => s.extId === scenarioExtId && s.hasRecordingSessions === true
        )
    )

    if (scenariosExtIdsWithSessions.length) {
      const titles = scenariosExtIdsWithSessions.map(
        scenarioExtId => scenarios.find(sc => sc.extId === scenarioExtId)?.title ?? ''
      )

      setScenariosWithSessionsTitles(titles)
      openInterceptAndConfirm()
    } else {
      linkScenariosHandler(scenariosToLink)
    }
  }

  return (
    <InterceptAndConfirm
      title={formatMessage({
        id: 'benchmarkProfile.linkScenario.modal.confirm.title',
      })}
      description={
        <FormattedMessage
          id="benchmarkProfile.linkScenario.modal.confirm.description"
          values={{
            scenarios: (
              <Text fontWeight="bold">{scenariosWithSessionsTitles.join(', ')}.</Text>
            ),
          }}
        />
      }
      onConfirm={() => linkScenariosHandler(scenarioExtIdsToLinkState)}
    >
      {({ openModal }) => (
        <Modal onClose={onClose} isOpen isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <FormattedMessage id="benchmarkProfile.linkScenario.modal.title" />
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody pb={4}>
              <Formik
                onSubmit={formData => onSubmit(formData, openModal)}
                onReset={onClose}
                initialValues={{ selectedScenarioExtIds: lockedScenarioExtIds }}
              >
                <SelectScenariosForm
                  programTrainingScenarioTree={programTrainingScenarioTree}
                  readOnlyScenarioExtIds={lockedScenarioExtIds}
                />
              </Formik>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </InterceptAndConfirm>
  )
}
