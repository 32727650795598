import { Route, Routes } from 'react-router-dom'

import { CenteredSpinner } from '../../components/centered-spinner'
import { RecordingDetails } from '../../recruiting/recording-details/recording-details'
import { RecordingFiltersProvider } from '../../recruiting/recording-filters/recording-filters-provider'
import { useInitFolders } from '../../store/entities/folders/use-init-folders'
import { FolderIndex } from './folder-index'
import { RecordingList } from './recording-list'

export const RecordingListWithFolderNavigation = () => {
  const initialized = useInitFolders()

  if (!initialized) {
    return <CenteredSpinner />
  }

  return (
    <Routes>
      <Route index element={<FolderIndex />} />

      <Route
        path=":folderId"
        element={
          <RecordingFiltersProvider>
            <RecordingList />
          </RecordingFiltersProvider>
        }
      />

      <Route path=":folderId/recordings/:recordingId" element={<RecordingDetails />} />
    </Routes>
  )
}
