import { object, type SchemaOf, string } from 'yup'

import { type CohortCreate } from '../types/cohort-create'

export const cohortsFormValidator: SchemaOf<
  Omit<CohortCreate, 'isForBenchmarking' | 'type'>
> = object().shape({
  name: string()
    .required('cohort.form.validation.name.required')
    .min(3, 'cohort.form.validation.name.min')
    .max(100, 'cohort.form.validation.name.max'),
})
