import { Stack, Textarea } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { FormikFormField } from '@repo/ui'
import { useFormikContext } from 'formik'
import * as yup from 'yup'

import { type FormValues } from './types'

export const SettingsForm = () => {
  const { errors } = useFormikContext<FormValues>()
  const { formatMessage } = useIntl()

  return (
    <Stack>
      <FormikFormField
        name="name"
        label={formatMessage({
          id: 'questionnaire.form.settings.name.label',
        })}
        errors={errors.name ? formatMessage({ id: errors.name as I18nKey }) : ''}
      />

      <FormikFormField
        name="introduction"
        errors={errors.introduction}
        as={Textarea}
        label={formatMessage({
          id: 'questionnaire.form.settings.intro.label',
        })}
        placeholder={formatMessage({
          id: 'common.optional',
        })}
      />
    </Stack>
  )
}

export const validationSchema = yup.object().shape({
  name: yup.string().required('questionnaire.form.error.name.required'),
  introduction: yup.string(),
  questions: yup.array().of(
    yup.object().shape({
      active: yup.boolean(),
      text: yup.string().required('step.form.validation.do.required'),
      note: yup.string(),
      repeatable: yup.number().required(),
      timeLimit: yup.number().required(),
      type: yup.string(),
      order: yup.number(),
    })
  ),
})

export const initialValues = {
  name: '',
  introduction: '',
}
