import { Center } from '@chakra-ui/react'
import { Spinner } from '@repo/ui'
import { type PropsWithChildren, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAuth } from '../authentication/auth-context'
import * as organizationEffects from '../store/entities/organization/effects'
import {
  selectOrganization,
  selectOrganizationHasRecruiting,
} from '../store/entities/organization/selectors'
import * as userSessionEffects from '../store/entities/user-activity-session/effects'
import { useUserSessionId } from '../store/entities/user-activity-session/use-user-session-id'
import { setUserPropertiesOnLogin } from '../utils/analytics'

/**
 * Component which ensures that the asynchronous parts of the app initialization have completed.
 * These include:
 *  - restoring auth from persistence
 *  - fetching some basic data for displaying the initial ui
 * It will show a loading indicator until the initialization is completed
 */
export const StartupGate = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch()
  const { user } = useAuth()
  const hasRecruiting = useSelector(selectOrganizationHasRecruiting)
  const organization = useSelector(selectOrganization)
  const userSessionId = useUserSessionId()

  useEffect(() => {
    const getOrganizationData = () => {
      if (user) {
        dispatch(organizationEffects.fetchOrganization())
      }
    }

    const initUserSession = () => {
      if (user) {
        dispatch(userSessionEffects.createUserSession())
      }
    }

    getOrganizationData()
    initUserSession()
  }, [user, dispatch])

  /**
   * It is important that `isReady` is calculated/set outside of above `useEffect`, so that we prevent a race condition
   *
   * TODO: find a better solution here instead of relying on useEffects / useMemo & their working order
   * The solution will be probably related with auth-context, firebaseAuth and StartupGate
   * And probably a cleaner solution would be using callbacks instead of useEffects
   * See https://react.dev/learn/you-might-not-need-an-effect#sharing-logic-between-event-handlers
   */
  const isReady = useMemo(() => {
    const isLoggedIn = !!user
    const hasUserSessionId = !!userSessionId
    const isFetchedOrg = hasRecruiting !== null

    if (!isLoggedIn) {
      return true
    }

    if (!hasUserSessionId) {
      return false
    }

    setUserPropertiesOnLogin({ organization_name: organization?.name })

    return isFetchedOrg
  }, [user, hasRecruiting, organization, userSessionId])

  return isReady ? (
    children
  ) : (
    <Center w="full" h="100vh">
      <Spinner />
    </Center>
  )
}
