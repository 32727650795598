import { createContext, type Dispatch, type SetStateAction, useContext } from 'react'

export type ContentEditorManagerContextType = {
  isDragging: boolean
  setIsDragging: Dispatch<SetStateAction<boolean>>
}

export const ContentEditorManagerContext = createContext<
  ContentEditorManagerContextType | undefined
>(undefined)

export const useContentEditorManager = () => {
  const context = useContext(ContentEditorManagerContext)

  if (context === undefined) {
    throw new Error(
      'useContentEditorManager must be used within a ContentEditorManagerProvider'
    )
  }

  return context
}
