import { useIntl } from '@repo/i18n'
import { useField } from 'formik'

import { ImageUploader, type ImageUploaderProps } from './image-uploader'

type FormikImageUploaderProps = Omit<
  ImageUploaderProps,
  'value' | 'setValue' | 'setTouched'
> & {
  name: string
  title?: I18nKey
}

export const FormikImageUploader = (props: FormikImageUploaderProps) => {
  const { name, title = 'imagepicker.select.photo', ...rest } = props

  const { formatMessage } = useIntl()

  const [imageUrlField, _, imageUrlHelpers] = useField(name)
  const { value: imageUrl } = imageUrlField
  const { setValue, setTouched } = imageUrlHelpers

  return (
    <ImageUploader
      {...rest}
      label={formatMessage({ id: title })}
      value={imageUrl}
      setValue={setValue}
      setTouched={setTouched}
    />
  )
}
