import { Stack } from '@chakra-ui/react'
import { type FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { SteppedModalFormStepFooter } from '../../stepped-modal-form-step-footer'
import { type PersonaFormValues, type PersonaStepKey } from '../types'
import { usePersonaFormContext } from './persona-form-context'
import { StepCharacter } from './persona-form-step-renderer/step-character'
import { StepDemographic } from './persona-form-step-renderer/step-demographic'
import { StepInsights } from './persona-form-step-renderer/step-insights'
import { StepLook } from './persona-form-step-renderer/step-look'
import { StepProfessional } from './persona-form-step-renderer/step-professional'
import { orderedPersonaSteps } from './shared/ordered-persona-steps'

const stepFormFieldComponents: Record<PersonaStepKey, FC> = {
  demographic: StepDemographic,
  professional: StepProfessional,
  character: StepCharacter,
  look: StepLook,
  insights: StepInsights,
}

const PersonaFormStepRendererInner = () => {
  const { getSubmitHandler, formData, currentStep, setCurrentStep, mode } =
    usePersonaFormContext()

  const form = useForm<PersonaFormValues[typeof currentStep]>({
    mode: 'onTouched',
    defaultValues: formData[currentStep],
  })

  const FormFieldComponent = stepFormFieldComponents[currentStep]

  return (
    <FormProvider {...form} key={currentStep}>
      <Stack
        as="form"
        onSubmit={form.handleSubmit(getSubmitHandler(currentStep))}
        h="full"
        gap={10}
      >
        <Stack gap={10}>
          <FormFieldComponent />
        </Stack>

        <SteppedModalFormStepFooter
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          steps={orderedPersonaSteps}
          entityNameKey="common.persona"
          mode={mode}
        />
      </Stack>
    </FormProvider>
  )
}

// this component re-renders the inner component using useForm() at each step, to make sure a new form instance is created for each step
export const PersonaFormStepRenderer = () => {
  const { currentStep } = usePersonaFormContext()

  return <PersonaFormStepRendererInner key={currentStep} />
}
