import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'

import { type SortingState } from '../../recruiting/recording-filters/column-control-data'
import { type FilterItemValue } from '../../recruiting/recording-filters/filter-control-data'
import {
  FILTER_NUMERICAL_VALUES,
  useColumnSelectData,
} from '../../recruiting/recording-filters/recording-filters-hooks'
import { isSummaryField } from '../../recruiting/recording-sessions-api'

type ListInfoHeaderProps = {
  numberSessionsTotal: number
  filterCriteria: FilterItemValue
  sortingCriteria: SortingState
  isRootFolder: boolean
}

const FilterCriteriaText = ({
  filterCriteria,
  numberSessionsTotal,
}: {
  filterCriteria: FilterItemValue
  numberSessionsTotal: number
}) => {
  if (
    !(filterCriteria && Object.keys(FILTER_NUMERICAL_VALUES).includes(filterCriteria))
  ) {
    return null
  }

  // E.g don't show 'Top 10' if there are less than 10 sessions
  if (numberSessionsTotal <= FILTER_NUMERICAL_VALUES[filterCriteria]) {
    return null
  }

  return (
    <>
      <Text as="span" fontWeight="medium">
        <FormattedMessage
          id={`recording.list.filters.${filterCriteria}` as I18nKey}
        />{' '}
      </Text>
      <FormattedMessage id="recording.list.infoHeader.outOf" />{' '}
    </>
  )
}

const SortingCriteriaText = ({
  sortingCriteria,
  isRootFolder,
}: {
  sortingCriteria: SortingState
  isRootFolder: boolean
}) => {
  const { formatMessage } = useIntl()
  const { sortBy, sortOrder } = sortingCriteria
  const { selectDictionary } = useColumnSelectData()
  const sortByText = formatMessage({ id: 'recording.list.infoHeader.sortyBy' })
  const sortByColumnName = selectDictionary[sortBy].text
  const sortOrderText = formatMessage({
    id: `recording.list.infoHeader.sortyBy.${sortOrder}`,
  })

  if (!sortBy) {
    return null
  }

  if (isRootFolder && isSummaryField(sortBy)) {
    return null
  }

  return (
    <Text as="span">
      {sortByText} {sortByColumnName} {sortOrderText}
    </Text>
  )
}

export const ListInfoHeader = ({
  numberSessionsTotal,
  filterCriteria,
  sortingCriteria,
  isRootFolder,
}: ListInfoHeaderProps) => (
  <Box pb={3}>
    <FilterCriteriaText
      filterCriteria={filterCriteria}
      numberSessionsTotal={numberSessionsTotal}
    />
    <Flex>
      <Text as="span" pr={1} fontWeight="medium">
        {numberSessionsTotal}
      </Text>
      <FormattedMessage id="recording.list.amount.text" /> <Spacer />
      <SortingCriteriaText
        sortingCriteria={sortingCriteria}
        isRootFolder={isRootFolder}
      />
    </Flex>
  </Box>
)
