import { Flex } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { BsCameraVideo } from 'react-icons/bs'
import { FaPhotoVideo } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { ContentContainer } from './content-container'
import { IconButton } from './icon-button'

type JumpToRecordingSelectionScreenProps = {
  handleContinue: VoidFunction
}

export const JumpToRecordingSelectionScreen = ({
  handleContinue,
}: JumpToRecordingSelectionScreenProps) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const RECORDINGS_URL = '/coaching/benchmarking/recordings'

  return (
    <ContentContainer
      title={formatMessage({
        id: 'coaching.benchmarking.wizard.jumpToRecordingSelection.title',
      })}
      subtitle={formatMessage({
        id: 'coaching.benchmarking.wizard.jumpToRecordingSelection.subtitle',
      })}
    >
      <Flex h="80%" w="100%" alignItems="center" justifyContent="center">
        <IconButton
          label={formatMessage({
            id: 'coaching.benchmarking.wizard.jumpToRecordingSelection.newRecordings',
          })}
          icon={BsCameraVideo}
          onClick={handleContinue}
        />
        <IconButton
          label={formatMessage({
            id: 'coaching.benchmarking.wizard.jumpToRecordingSelection.selectRecordings',
          })}
          icon={FaPhotoVideo}
          onClick={() => navigate(RECORDINGS_URL)}
        />
      </Flex>
    </ContentContainer>
  )
}
