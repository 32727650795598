/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from 'lodash-es'

import { FolderStoreStatus } from './folders-types'
import types from './private/actions'

const initialState: any = {
  idHash: {},
  pathHash: {},
  tree: {},
  activeFolderIndexPath: null,
  status: FolderStoreStatus.IDLE,
}

const addChildren = folders => root => {
  const { id } = root
  const children = folders.filter(({ parent }) => parent === id).map(addChildren(folders))

  return {
    ...root,
    children: !children.length ? null : children,
  }
}

function onReceiveFolders(state, data) {
  const idHash = data.reduce((acc, folder) => {
    acc[folder.id] = folder

    return acc
  }, {})

  const pathHash = data.reduce((acc, folder) => {
    acc[folder.path] = folder

    return acc
  }, {})

  const folders = data.map(folder => {
    const { path } = folder
    const isRoot = path.indexOf('.') === -1
    const parentPath = path.substring(0, path.lastIndexOf('.'))
    const parent = isRoot ? null : pathHash[parentPath].id

    return {
      ...folder,
      parent,
    }
  })

  const roots = folders.filter(({ parent }) => !parent)

  if (roots.length > 1) {
    throw new Error('Got corrupted folder data from backend. More than 1 root folder.')
  }

  const root = { ...roots[0] }

  return {
    ...state,
    idHash,
    pathHash,
    tree: addChildren(folders)(root),
    status: FolderStoreStatus.INITIALIZED,
  }
}

// eslint-disable-next-line import/no-default-export
export default (state = initialState, { type, data }) => {
  switch (type) {
    case types.SET_ACIVE_FOLDER: {
      const folderPath = get(state.idHash, [data, 'path'], null)

      if (typeof folderPath !== 'string') {
        return {
          ...state,
          activeFolderIndexPath: null,
        }
      }

      const [root, ...children] = folderPath.split('.')

      if (root !== state.tree.path) {
        console.warn('Wrong folder path:', folderPath)

        return {
          ...state,
          activeFolderIndexPath: null,
        }
      }

      // find indexes of all nodes in path
      const { indexPath } = children.reduce<any>(
        (acc, childId) => {
          const index = acc.node.children.findIndex(({ id }) => id === childId)

          return {
            indexPath: acc.indexPath.concat([index]),
            node: acc.node.children[index],
          }
        },
        { indexPath: [], node: state.tree }
      )

      return {
        ...state,
        activeFolderIndexPath: indexPath,
      }
    }

    case types.RECEIVE_FOLDERS:
      return onReceiveFolders(state, data)
    case types.INITIALIZE_FOLDERS:
      return {
        ...state,
        status: FolderStoreStatus.INITIALIZING,
      }

    default:
      return state
  }
}
