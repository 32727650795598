import { createSelector } from 'reselect'

const getValueOrNull = callback => organization =>
  organization ? callback(organization) : null

const getLocalState = state => state.entities.organization

export const selectOrganization = createSelector(
  getLocalState,
  organization => organization
)

export const selectOrganizationInvitationCount = createSelector(
  selectOrganization,
  getValueOrNull(organization => organization.invitations.length)
)

export const selectOrganizationMemberCount = createSelector(
  selectOrganization,
  getValueOrNull(organization => organization.members.length)
)

export const selectOrganizationName = createSelector(
  selectOrganization,
  getValueOrNull(organization => organization.name)
)

export const selectOrganizationHasReviewedGDPR = createSelector(
  selectOrganization,
  getValueOrNull(organization => organization.settings.gdprReviewed)
)

export const selectOrganizationHasUnderstanding = createSelector(
  selectOrganization,
  getValueOrNull(organization => organization.settings.hasUnderstanding)
)

export const selectOrganizationHasRealtimeConversationStep = createSelector(
  selectOrganization,
  getValueOrNull(organization => organization.settings.hasRealtimeConversationStep)
)

export const selectOrganizationHasAiContentGen = createSelector(
  selectOrganization,
  getValueOrNull(organization => organization.settings.hasAiContentGen)
)

const selectMembers = createSelector(
  selectOrganization,
  getValueOrNull(organization => organization.members)
)

const selectInvitations = createSelector(
  selectOrganization,
  getValueOrNull(organization => organization.invitations)
)

export const selectCombinedMembers = createSelector(
  selectMembers,
  selectInvitations,
  (members = [], invitations = []) =>
    members
      .map(member => ({
        id: member.id,
        createdAt: member.createdAt,
        updatedAt: member.updatedAt,
        email: member.email,
        role: member.role,
        status: 'member',
        memberRole: member.memberRole,
      }))
      .concat(
        invitations.map(invitation => ({
          id: invitation.id,
          createdAt: invitation.createdAt,
          updatedAt: invitation.updatedAt,
          email: invitation.invitationEmail,
          status: 'invitation-pending',
        }))
      )
      .sort((a, b) => a.email.localeCompare(b.email))
)

export const selectOrganizationHasRecruiting = createSelector(
  selectOrganization,
  getValueOrNull(organization => organization.settings.hasRecruiting)
)

export const selectOrganizationHasCoaching = createSelector(
  selectOrganization,
  getValueOrNull(organization => organization.settings.hasCoaching)
)

export const selectOrganizationHasProgramNotificationsEnabled = createSelector(
  selectOrganization,
  getValueOrNull(organization => organization.settings.programNotificationsEnabled)
)
