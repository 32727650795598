import { assertExists } from '@repo/utils'
import { match } from 'ts-pattern'

import { getResumeStepForAIBuilder } from '../scenario-ai-builder-page/shared/get-resume-step-for-ai-builder'
import { getResumeStepForRealtimeBuilder } from '../scenario-realtime-builder-page/step-router/shared/get-resume-step-for-realtime-builder'
import { type BuilderType } from './types'
import { useScenarioBuilderData } from './use-scenario-builder-data'

export const useResumeStepKey = (builderType: BuilderType) => {
  const [, , , { data: scenario }] = useScenarioBuilderData()

  assertExists(scenario, 'scenario')

  const resumeStepKey = match(builderType)
    .with('ai', () => getResumeStepForAIBuilder(scenario))
    .with('realtime', () => getResumeStepForRealtimeBuilder(scenario))
    .exhaustive()

  return resumeStepKey
}
