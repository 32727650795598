import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea as ChakraTextarea,
} from '@chakra-ui/react'
import { useField } from 'formik'
import PropTypes from 'prop-types'

export const Textarea = ({ label, help, name, ...props }) => {
  const [field, meta] = useField(name)

  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}

      <ChakraTextarea {...props} {...field} />

      {meta.touched && meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      {help && <FormHelperText>{help}</FormHelperText>}
    </FormControl>
  )
}

Textarea.propTypes = {
  help: PropTypes.node,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
}
