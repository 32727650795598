import {
  Box,
  Button,
  Divider,
  FormControl,
  type HTMLChakraProps,
  MenuGroup,
  MenuList,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { CheckboxSelect, CheckboxSelectItem, CustomSelectButton } from '@repo/ui'
import { mapAndFilterNotNil } from '@repo/utils'
import { useCallback, useState } from 'react'

import { type ColumnItemValue } from './column-control-data'
import { Placeholder } from './placeholder'
import { useColumnSelectData } from './recording-filters-hooks'

export type ColumnControlProps = {
  value?: Array<ColumnItemValue>
  onChange?: (v: Array<ColumnItemValue>) => void
  onApply?: (v: Array<ColumnItemValue>) => void
  onClose?: (v: Array<ColumnItemValue>) => void
  isRootFolder: boolean
} & Omit<HTMLChakraProps<'div'>, 'onChange'>

export const ColumnControl = ({
  value,
  onChange,
  onApply,
  onClose,
  isRootFolder,
  ...rest
}: ColumnControlProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOnChange = useCallback(
    (v: Array<string>) => onChange?.(v as Array<ColumnItemValue>),
    [onChange]
  )

  const handleOnApply = useCallback(() => {
    onApply?.(value as Array<ColumnItemValue>)
    setIsOpen(false)
  }, [onApply, value])

  const handleOnClose = useCallback(
    (v: Array<string>) => {
      onClose?.(v as Array<ColumnItemValue>)
      setIsOpen(false)
    },
    [onClose]
  )

  const { selectData, selectDictionary, selectRootData, selectDictionaryRoot } =
    useColumnSelectData()

  const selectedColumn = isRootFolder ? selectRootData : selectData
  const selectedDictionaryData = isRootFolder ? selectDictionaryRoot : selectDictionary
  const textOrNull = value
    ? mapAndFilterNotNil(v => selectedDictionaryData[v], value)
        .map(x => x.selectedText)
        .join(', ') || null
    : null

  const text = textOrNull ?? <Placeholder />

  return (
    <FormControl {...rest}>
      <CheckboxSelect
        onChange={handleOnChange}
        isOpen={isOpen}
        onClose={handleOnClose}
        value={value}
      >
        <CustomSelectButton onClick={() => setIsOpen(true)} maxWidth="full">
          {text}
        </CustomSelectButton>
        <MenuList>
          <Box maxHeight="50vh" overflowY="auto">
            {selectedColumn.map((group, gi) => (
              <MenuGroup fontWeight="medium" key={group.title || gi} title={group.title}>
                {group.subGroups.map((subGroup, sgi) => (
                  <MenuGroup
                    fontWeight="normal"
                    key={subGroup.title || sgi}
                    title={subGroup.title}
                  >
                    {subGroup.items.map(item => (
                      <CheckboxSelectItem key={item.value} value={item.value}>
                        {item.text}
                      </CheckboxSelectItem>
                    ))}
                  </MenuGroup>
                ))}
              </MenuGroup>
            ))}
          </Box>

          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Divider m={3} />
            <Button onClick={handleOnApply}>
              <FormattedMessage id="recording.list.filters.form-control.apply" />
            </Button>
          </Box>
        </MenuList>
      </CheckboxSelect>
    </FormControl>
  )
}
