import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { getHumanReadableTime } from '@repo/utils'

export type ErrorStates = 'maxDuration' | 'maxUploadSize' | 'uploadDimension'

type UploadErrorProps = {
  error: ErrorStates | null
  clearError: () => void
  sizeLimit: number
  maxVideoDurationInSeconds?: number
  maxWidth?: number
  maxHeight?: number
  minWidth?: number
  minHeight?: number
}

export const UploadError = ({
  error,
  clearError,
  sizeLimit,
  maxWidth = 800,
  maxHeight = 400,
  minHeight = 100,
  minWidth = 100,
  maxVideoDurationInSeconds = 0,
}: UploadErrorProps) => {
  const handleOnClose = () => {
    clearError()
  }

  if (!error) {
    return null
  }

  return (
    <Modal isOpen onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="error.upload.title" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormattedMessage
            id={`error.videoUpload.${error}`}
            values={{
              maxUploadSize: sizeLimit / 1024 / 1024,
              maxVideoDuration: getHumanReadableTime(maxVideoDurationInSeconds),
              maxHeight,
              maxWidth,
              minHeight,
              minWidth,
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button onClick={handleOnClose}>
            <FormattedMessage id="common.cancel" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
