import { FormControl } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { CustomFormLabel, useFormRules } from '@repo/ui'
import { type ComponentProps } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BiUserCircle } from 'react-icons/bi'

import { CenteredSpinner } from '../../../../../components/centered-spinner'
import { useOpenapiSWR } from '../../../../../utils/use-openapi-swr'
import { ImageSelector } from '../../../image-selector'
import { useScenarioBuilderData } from '../../../use-scenario-builder-data'
import { VoiceSelector } from '../../../voice-selector'
import { type PersonaFormValues } from '../../types'
import {
  personaBackgroundNames,
  personaBackgroundNameToUrl,
} from '../persona-background-utils'
import { usePersonaFormContext } from '../persona-form-context'

export const StepLook = () => {
  const { setFormData } = usePersonaFormContext()
  const [, programSWR] = useScenarioBuilderData()
  const programLanguage = programSWR.data?.language
  const { control } = useFormContext<PersonaFormValues['look']>()
  const rules = useFormRules()
  const { formatMessage } = useIntl()

  if (!programLanguage) {
    throw new Error('Program language is not set')
  }

  const { data: avatarsAndVoices, isLoading } = useOpenapiSWR('getAvatarsAndVoices', {
    query: { language: programLanguage },
  })

  const isInitializing = !avatarsAndVoices && isLoading

  if (isInitializing) {
    return <CenteredSpinner />
  }

  const { avatars, voices } = avatarsAndVoices ?? {}

  const avatarOptions =
    avatars?.map(avatar => ({
      key: avatar.extId,
      imageSrc: avatar.imageUrl ?? '',
      label: avatar.name ?? '',
    })) ?? []

  const voiceOptions: ComponentProps<typeof VoiceSelector>['voices'] =
    voices?.map(voice => ({
      audioUrl: voice.previewUrl ?? '',
      label: `${
        voice.labelKey?.startsWith('persona.voice')
          ? formatMessage({ id: voice.labelKey as I18nKey })
          : voice.labelKey
      } (${formatMessage({ id: `persona.sex.${voice.gender}` as I18nKey })})`,
      key: voice.extId,
    })) ?? []

  return (
    <>
      <Controller
        control={control}
        name="videoGenerationAvatarExtId"
        rules={rules.required}
        render={({ field, fieldState }) => {
          const currentOption = avatarOptions.find(o => o.key === field.value)

          return (
            <FormControl isRequired={!!rules?.required} isInvalid={!!fieldState.error}>
              <CustomFormLabel>
                <FormattedMessage id="common.avatar" />
              </CustomFormLabel>

              <ImageSelector
                titleKey="common.avatar"
                options={avatarOptions}
                value={currentOption}
                onChange={value => {
                  field.onChange(value.key)
                  // we update the form data on context, to see the preview immediately on modal
                  setFormData(prev => ({
                    ...prev,
                    look: {
                      backgroundUrl: prev.look?.backgroundUrl,
                      videoGenerationAvatarExtId: value.key,
                      videoGenerationVoiceExtId:
                        prev.look?.videoGenerationVoiceExtId ?? '',
                    },
                  }))
                }}
                placeholderKey="common.select"
                searchPlaceholderKey="common.search"
                imageAspectRatio="1/1"
                columnCount={3}
                icon={BiUserCircle}
              />
            </FormControl>
          )
        }}
      />

      <Controller
        control={control}
        name="videoGenerationVoiceExtId"
        rules={rules.required}
        render={({ field, fieldState }) => {
          const currentVoice = voiceOptions.find(value => value.key === field.value)

          return (
            <FormControl isRequired={!!rules?.required} isInvalid={!!fieldState.error}>
              <CustomFormLabel>
                <FormattedMessage id="common.voice" />
              </CustomFormLabel>

              <VoiceSelector
                voices={voiceOptions}
                value={currentVoice}
                onChange={value => field.onChange(value.key)}
              />
            </FormControl>
          )
        }}
      />

      <Controller
        control={control}
        name="backgroundUrl"
        render={({ field, fieldState }) => {
          let options = personaBackgroundNames.map(filename => ({
            key: personaBackgroundNameToUrl(filename),
            imageSrc: personaBackgroundNameToUrl(filename),
            label: formatMessage({
              id: `persona.bgImage.${filename}` satisfies I18nKey,
            }),
          }))

          options = [
            {
              imageSrc: '',
              key: '',
              label: formatMessage({ id: 'common.noBackground' }),
            },
            ...options,
          ]

          const currentOption = options.find(o => o.key === field.value)

          return (
            <FormControl isInvalid={!!fieldState.error}>
              <CustomFormLabel>
                <FormattedMessage id="common.background" />
              </CustomFormLabel>

              <ImageSelector
                titleKey="common.background"
                options={options}
                value={currentOption}
                onChange={value => {
                  field.onChange(value.key)
                  // we update the form data on context, to see the preview immediately on modal
                  setFormData(prev => ({
                    ...prev,
                    look: {
                      backgroundUrl: value.key,
                      videoGenerationAvatarExtId:
                        prev.look?.videoGenerationAvatarExtId ?? '',
                      videoGenerationVoiceExtId:
                        prev.look?.videoGenerationVoiceExtId ?? '',
                    },
                  }))
                }}
                placeholderKey="common.select"
                searchPlaceholderKey="common.search"
              />
            </FormControl>
          )
        }}
      />
    </>
  )
}
