import { Box, Circle, Flex, Icon, SimpleGrid, Spacer, Text } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import {
  ArrowTargetIcon,
  CheckMarkIcon,
  ClockIcon,
  colors,
  FingerTouchIcon,
  SendLetterIcon,
  VideoCarouselIcon,
  VideoPlayerIcon,
} from '@repo/ui'
import { type ElementType, type ReactNode } from 'react'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'

type InfoGraphProps = {
  currentStep: number
}

const GraphRowLeft = ({
  icon,
  label,
  currentStep,
}: {
  icon: ElementType
  label: ReactNode
  currentStep: number
}) => (
  <Flex justifyContent="center" alignItems="center" mb={2}>
    {currentStep === 1 ? (
      <Circle size="50px" bg="white" mr={3}>
        <Icon as={icon} boxSize="60%" fill={colors.primary} />
      </Circle>
    ) : (
      <Icon
        h="35px"
        w="35px"
        mr={1}
        as={IoIosCheckmarkCircleOutline}
        fill={colors.indicator.green}
      />
    )}
    <Text fontSize="12" fontWeight="medium">
      {label}
    </Text>
    <Spacer />
  </Flex>
)

const GraphRowRight = ({ icon, label }: { icon: ElementType; label: ReactNode }) => (
  <Flex justifyContent="center" alignItems="center" mb={2}>
    <Text fontSize="12" fontWeight="medium" textAlign="end">
      {label}
    </Text>
    <Circle size="50px" bg="white" ml={3}>
      <Icon as={icon} boxSize="60%" fill={colors.primary} />
    </Circle>
  </Flex>
)

const GraphHeading = ({ children, color }: { children: ReactNode; color: string }) => (
  <Text pt={6} fontWeight="medium" color={color} fontSize="xl" align="center">
    {children}
  </Text>
)

export const InfoGraph = ({ currentStep }: InfoGraphProps) => {
  const { formatMessage } = useIntl()

  return (
    <SimpleGrid columns={3}>
      <Box bg={colors.secondary} borderRadius={12}>
        <Flex justifyContent="center" alignItems="center">
          <GraphHeading color={colors.primary}>
            <FormattedMessage id="benchmarking.wizard.infograph.firstStep.title" />
          </GraphHeading>
        </Flex>
        <Box p={6}>
          <GraphRowLeft
            icon={FingerTouchIcon}
            label={formatMessage({ id: 'benchmarking.wizard.infograph.firstStep.1' })}
            currentStep={currentStep}
          />
          <GraphRowLeft
            icon={VideoPlayerIcon}
            label={formatMessage({ id: 'benchmarking.wizard.infograph.firstStep.2' })}
            currentStep={currentStep}
          />
          <GraphRowLeft
            icon={SendLetterIcon}
            label={formatMessage({ id: 'benchmarking.wizard.infograph.firstStep.3' })}
            currentStep={currentStep}
          />
        </Box>
      </Box>
      <Flex flexDir="column" justifyContent="center" alignItems="center" h="100%">
        <Circle bg={colors.primary} size="90px" mb={2}>
          <Icon as={ClockIcon} boxSize="65%" fill="white" />
        </Circle>
        <Text fontSize="sm" fontWeight="medium">
          <FormattedMessage id="benchmarking.wizard.infograph.center.title" />
        </Text>
      </Flex>
      <Box bg={colors.secondary} borderRadius={12}>
        <GraphHeading color={colors.primary}>
          <FormattedMessage id="benchmarking.wizard.infograph.secondStep.title" />
        </GraphHeading>
        <Box p={6}>
          <GraphRowRight
            icon={VideoCarouselIcon}
            label={formatMessage({ id: 'benchmarking.wizard.infograph.secondStep.1' })}
          />
          <GraphRowRight
            icon={ArrowTargetIcon}
            label={formatMessage({ id: 'benchmarking.wizard.infograph.secondStep.2' })}
          />
          <GraphRowRight
            icon={CheckMarkIcon}
            label={formatMessage({ id: 'benchmarking.wizard.infograph.secondStep.3' })}
          />
        </Box>
      </Box>
    </SimpleGrid>
  )
}
