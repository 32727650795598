import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type TooltipProps } from 'recharts'
import {
  type NameType,
  type ValueType,
} from 'recharts/types/component/DefaultTooltipContent'

type CustomChartTooltipProps = TooltipProps<ValueType, NameType> & {
  valueSuffix?: string
  showLineNamesInTooltip?: boolean
}

export const CustomChartTooltip = ({
  active,
  payload,
  label,
  valueSuffix,
  showLineNamesInTooltip = false,
}: CustomChartTooltipProps) => {
  if (!active || !payload) {
    return null
  }

  const shouldShowColors = payload.length > 1

  return (
    <Stack
      gap={3}
      boxShadow="0px 0px 28px 0px rgba(0, 0, 0, 0.2)"
      borderRadius={4}
      padding={4}
      bg="white"
      color={colors.gray[800]}
    >
      <Text fontSize={12} lineHeight={1}>
        {label}
      </Text>
      <Stack>
        {payload.map(entry => (
          <HStack key={entry.color} gap="6px">
            {shouldShowColors && (
              <Box
                background={entry.color}
                borderRadius="full"
                width="10px"
                height="10px"
              />
            )}
            <HStack
              fontSize="sm"
              lineHeight={1}
              justifyContent="space-between"
              width={showLineNamesInTooltip ? 'full' : ''}
            >
              {showLineNamesInTooltip && (
                <FormattedMessage
                  id={
                    `dashboard.cards.behavioralAdaptation.data.${entry.name}` as I18nKey
                  }
                />
              )}
              <Text>
                {entry.value}
                {valueSuffix}
              </Text>
            </HStack>
          </HStack>
        ))}
      </Stack>
    </Stack>
  )
}
