import { Button, Center, Flex, Icon, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { BsInbox } from 'react-icons/bs'
import { HiX } from 'react-icons/hi'

import { type Filters, type FilterType } from '../shared/filters'

type FilterItem = {
  type: FilterType
  value: Filters[FilterType][number]
}

interface NoFilterResultProps {
  currentFilters: Array<FilterItem>
  onRemoveFilter: (filter: FilterItem) => void
}

export const NoFilterResult = ({
  currentFilters,
  onRemoveFilter,
}: NoFilterResultProps) => (
  <Center px={4} flex={1}>
    <Center gap={4} flexDir="column" maxW={420}>
      <Icon as={BsInbox} w={8} h={8} color={colors.blue[500]} />
      <Text textAlign="center">
        <FormattedMessage id="coaching.program.templates.page.noResults.filter" />
      </Text>

      <Flex gap={2} flexWrap="wrap">
        {currentFilters.map(filter => (
          <Button
            key={`${filter.type}-${filter.value}`}
            borderRadius="full"
            borderWidth={1}
            variant="outline"
            leftIcon={<Icon as={HiX} />}
            onClick={() => onRemoveFilter(filter)}
          >
            <FormattedMessage
              id={`coaching.program.templates.${filter.value}` satisfies I18nKey}
            />
          </Button>
        ))}
      </Flex>
    </Center>
  </Center>
)
