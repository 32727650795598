import { Button, HStack, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage, type ReactNodeOrI18nKey } from '@repo/i18n'
import { useState } from 'react'

import { useModal } from './use-modal'

type Params = {
  onConfirm: () => unknown
  confirmButtonKey?: I18nKey
  isDestructive?: boolean
} & ReactNodeOrI18nKey<'description'> &
  ReactNodeOrI18nKey<'title'>

export const useConfirmationModal = ({
  onConfirm,
  confirmButtonKey,
  titleKey,
  title,
  isDestructive,
  description,
  descriptionKey,
}: Params) => {
  const [isConfirming, setIsConfirming] = useState(false)

  const modal = useModal(
    ({ closeModal }) => {
      const handleConfirm = async () => {
        try {
          setIsConfirming(true)
          await onConfirm()
          closeModal()
        } finally {
          setIsConfirming(false)
        }
      }

      return (
        <Stack gap={8}>
          {(descriptionKey || description) && (
            <Text>
              {descriptionKey ? <FormattedMessage id={descriptionKey} /> : description}
            </Text>
          )}
          <HStack gap={3} justify="center">
            <Button variant="outline" onClick={closeModal}>
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button
              colorScheme={isDestructive ? 'red' : 'blue'}
              onClick={handleConfirm}
              isLoading={isConfirming}
            >
              <FormattedMessage id={confirmButtonKey ?? 'common.confirm'} />
            </Button>
          </HStack>
        </Stack>
      )
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    { modalProps: { closeOnOverlayClick: false }, titleKey, title }
  )

  return modal
}
