import { Button, HStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { useCallback, useState } from 'react'

import { SharedProgramStatusBadge } from '../../components/shared-program-status-badge'
import { useProgramsList } from '../../new-program-builder/shared/api'
import { type ExtendedProgram, type SharedProgram } from '../../types/api-types'
import { client } from '../../utils/openapi-client'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'

const CancelSendingButton = ({
  sharedProgram,
  program,
}: {
  sharedProgram: SharedProgram
  program: ExtendedProgram
}) => {
  const { mutate } = useProgramsList()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    try {
      setIsLoading(true)
      await client.delete('deleteSharedProgram', {
        params: { path: { extId: program.extId, sharedExtId: sharedProgram.extId } },
      })

      mutate(prevData => {
        if (!prevData) {
          return prevData
        }

        const updatedPrograms = prevData.map(coachingProgram => {
          if (program && coachingProgram.extId === program.extId) {
            return {
              ...coachingProgram,
              sharedPrograms: coachingProgram.sharedPrograms.filter(
                item => item.extId !== sharedProgram.extId
              ),
            }
          }

          return coachingProgram
        })

        return updatedPrograms
      }, false)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button variant="outline" onClick={handleClick} isLoading={isLoading}>
      <FormattedMessage id="coaching.program.send.modal.requestsStatus.listItem.button.cancelSending" />
    </Button>
  )
}

const RejectOrAcceptButtons = ({ sharedProgram }: { sharedProgram: SharedProgram }) => {
  const [isLoadingRejectButton, setIsLoadingRejectButton] = useState(false)
  const [isLoadingAcceptButton, setIsLoadingAcceptButton] = useState(false)

  const { data: sharedProgramsData, mutate: mutateSharedProgram } =
    useOpenapiSWR('getSharedPrograms')

  const onClickAcceptSharedProgram = useCallback(async () => {
    try {
      setIsLoadingAcceptButton(true)
      const { data: newSharedProgram } = await client.put('acceptSharedProgram', {
        params: { path: { sharedExtId: sharedProgram.extId } },
      })

      if (!newSharedProgram) {
        throw new Error()
      }

      mutateSharedProgram(
        sharedProgramsData?.map(item =>
          item.extId === newSharedProgram?.extId ? { ...item, status: 'accepted' } : item
        ),
        false
      )

      setIsLoadingAcceptButton(false)
      showToast({
        messageKey: 'coaching.program.received.toast.accept.success',
        status: 'success',
      })
    } catch (err) {
      setIsLoadingAcceptButton(false)
      showToast({
        messageKey: 'coaching.program.received.toast.accept.error',
        status: 'error',
      })
    }
  }, [mutateSharedProgram, sharedProgram.extId, sharedProgramsData])

  const onClickRejectSharedProgram = useCallback(async () => {
    try {
      setIsLoadingRejectButton(true)
      const { data: newSharedProgram } = await client.put('rejectSharedProgram', {
        params: { path: { sharedExtId: sharedProgram.extId } },
      })

      if (!newSharedProgram) {
        throw new Error()
      }

      mutateSharedProgram(
        sharedProgramsData?.map(item =>
          item.extId === newSharedProgram.extId ? { ...item, status: 'rejected' } : item
        ),
        false
      )

      setIsLoadingRejectButton(false)
      showToast({
        messageKey: 'coaching.program.received.toast.rejected.success',
        status: 'success',
      })
    } catch (err) {
      setIsLoadingRejectButton(false)
      showToast({
        messageKey: 'coaching.program.received.toast.rejected.error',
        status: 'error',
      })
    }
  }, [mutateSharedProgram, sharedProgram.extId, sharedProgramsData])

  return (
    <HStack gap={2}>
      <Button
        type="submit"
        isLoading={isLoadingAcceptButton}
        onClick={onClickAcceptSharedProgram}
        size="xs"
        px="8px"
        minWidth="92px"
        height="24px"
      >
        <FormattedMessage id="coaching.program.received.modal.button.accept" />
      </Button>
      <Button
        type="submit"
        isLoading={isLoadingRejectButton}
        onClick={onClickRejectSharedProgram}
        variant="outline"
        size="xs"
        px="8px"
        minWidth="92px"
        height="24px"
      >
        <FormattedMessage id="coaching.program.received.modal.button.reject" />
      </Button>
    </HStack>
  )
}

type SharedProgramItemStatusProps = {
  sharedProgram: SharedProgram
  program?: ExtendedProgram
  isReceivedModal?: boolean
}

export const SharedProgramItemStatus = ({
  sharedProgram,
  isReceivedModal,
  program,
}: SharedProgramItemStatusProps) => {
  const { status } = sharedProgram

  if (status !== 'pending') {
    return <SharedProgramStatusBadge status={status} />
  }

  if (!isReceivedModal && program) {
    return <CancelSendingButton program={program} sharedProgram={sharedProgram} />
  }

  return <RejectOrAcceptButtons sharedProgram={sharedProgram} />
}
