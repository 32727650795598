import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { Formik } from 'formik'

import { EmailStep } from '../../cohort-creation-modal/components/participants-step/components/email-step/email-step'
import { cohortEmailsSchema } from '../../cohort-creation-modal/constants'

interface EmailParticipantsModalProps {
  isOpen: boolean
  onClose: () => void
  cohortId: string
  refreshParticipantsList: () => void
}

export const EmailParticipantsModal = ({
  isOpen,
  onClose,
  cohortId,
  refreshParticipantsList,
}: EmailParticipantsModalProps) => {
  const onSubmit = () => {
    onClose()
    refreshParticipantsList()
  }

  return (
    <Modal
      size="3xl"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent minH={450}>
        <ModalHeader fontSize="2xl">
          <FormattedMessage id="cohort.creation.email.participant.modal.title" />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody pb={4}>
          <Formik
            validationSchema={cohortEmailsSchema}
            onSubmit={onSubmit}
            initialValues={{ id: cohortId, emails: [] }}
            validateOnBlur={false}
          >
            <EmailStep isCohortCreation={false} />
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
