import { type AutocompleteOptions } from '@repo/ui/src/react-hook-forms/rhf/form-field-autocomplete-select'

import { useScenarioBuilderData } from './use-scenario-builder-data'

export const useSelectOptions = () => {
  const [, , , , , formatInProgramLanguage] = useScenarioBuilderData()

  const getSelectOptions = <
    TKeys extends ReadonlyArray<string>,
    TFixedKey extends string,
  >(
    keys: readonly [...TKeys],
    fixedKey: `${TFixedKey}.${TKeys[number]}` extends I18nKey
      ? TFixedKey
      : {
          error: 'When `values` and `fixedKey` are combined, the result should be `I18nKey`'
          hereIsTheCombinedValue: `${TFixedKey}.${TKeys[number]}`
        },
    useTranslatedLabelAsValue: boolean
  ): AutocompleteOptions =>
    keys.map(value => {
      const translatedLabel = formatInProgramLanguage(`${fixedKey}.${value}` as I18nKey)

      return {
        label: translatedLabel,
        value: useTranslatedLabelAsValue ? translatedLabel : value,
      }
    })

  return { getSelectOptions }
}
