import { useIntl } from '@repo/i18n'
import { array, date, object, string } from 'yup'

import { emailRegex } from '../../../utils/constants'
import { ModeStep } from './components/mode-step/mode-step'
import { NameStep } from './components/name-step'
import { ParticipantsStep } from './components/participants-step/participants-step'
import { ProgramsStep } from './components/programs-step'
import { type CohortCreationStep, type CohortCreationValues } from './types'

export const cohortCreationInitialValues: CohortCreationValues = {
  id: '',
  name: '',
  type: 'email',
  tokenCount: 0,
  emails: [],
  programIds: [],
  startDate: new Date(),
  endDate: null,
}

export const cohortEmailsSchema = object().shape({
  emails: array().of(string().matches(emailRegex, obj => obj.value)),
})

const cohortProgramsSchema = object().shape({
  startDate: date().required(),
  endDate: date()
    .nullable()
    .when('startDate', (startDate, schema) => startDate && schema.min(startDate)),
})

export const getCohortWizardSteps = () => [
  'cohortName',
  'invitationMode',
  'assignProgram',
  'addParticipants',
]

export const useCohortCreationSteps = (
  isBenchmarking: boolean,
  onStepBack: () => void
): Array<CohortCreationStep> => {
  const { formatMessage } = useIntl()

  return [
    {
      component: <NameStep />,
      schema: object().shape({
        name: string()
          .required(formatMessage({ id: 'cohort.creation.required.name' }))
          .min(3, formatMessage({ id: 'cohort.creation.min.required.name' }))
          .max(100, formatMessage({ id: 'cohort.creation.max.required.name' })),
      }),
    },
    {
      component: <ModeStep isBenchmarking={isBenchmarking} onStepBack={onStepBack} />,
    },
    {
      component: <ProgramsStep />,
      schema: cohortProgramsSchema,
    },
    {
      component: <ParticipantsStep />,
      schema: cohortEmailsSchema,
    },
  ]
}
