import { Button, Icon, useDisclosure } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useCallback, useState } from 'react'
import { RiCodeSSlashLine } from 'react-icons/ri'

import { useOrganization } from '../../hooks/use-organization'
import { PluginModal } from '../plugin-editor/plugin-modal/plugin-modal'
import { InfoModalGDPR } from './info-modal-gdpr'

type SetupInterviewButtonProps = {
  pluginId: string
  disabled: boolean
}

export const SetupInterviewButton = ({
  disabled,
  pluginId,
}: SetupInterviewButtonProps) => {
  const { isGDPRCompliant } = useOrganization()

  const [showGdprDisabledHelp, setShowGdprDisabledHelp] = useState<boolean>(false)

  const pluginModal = useDisclosure()

  const handleVideoInterviewClick = useCallback(() => {
    if (!isGDPRCompliant) {
      setShowGdprDisabledHelp(true)

      return
    }

    pluginModal.onOpen()
  }, [isGDPRCompliant, pluginModal])

  return (
    <>
      {pluginModal.isOpen && (
        <PluginModal
          // this key is to temporarily re-render modal to trigger onMount logic
          key={pluginId}
          pluginId={pluginId}
          onClose={pluginModal.onClose}
          isOpen
        />
      )}
      <Button
        variant="outline"
        leftIcon={<Icon boxSize="1.25em" as={RiCodeSSlashLine} />}
        onClick={handleVideoInterviewClick}
        isDisabled={disabled}
      >
        <FormattedMessage id="recording.list.button.videoInterview" />
      </Button>
      <InfoModalGDPR
        open={showGdprDisabledHelp}
        onClose={() => setShowGdprDisabledHelp(false)}
      />
    </>
  )
}
