export type InteractionStepKey =
  | 'conversation-dynamics'
  | 'scenario-goal'
  | 'conversation-generation'
  | 'conversation-customization'
  | 'review'

export const orderedInteractionSteps: Array<{
  key: InteractionStepKey
  titleKey: I18nKey
}> = [
  {
    key: 'conversation-dynamics',
    titleKey: 'scenario.manual.builder.steps.dynamics',
  },
  {
    key: 'scenario-goal',
    titleKey: 'scenario.manual.builder.steps.details',
  },
  {
    key: 'conversation-generation',
    titleKey: 'scenario.ai.builder.steps.conversationGeneration',
  },
  {
    key: 'conversation-customization',
    titleKey: 'scenario.manual.builder.steps.customization',
  },
  {
    key: 'review',
    titleKey: 'scenario.manual.builder.steps.review',
  },
]
