import {
  Button,
  Flex,
  Link,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'

import { type CohortsListItem } from '../../../coaching-participants/types/cohorts-list-item'
import { ButtonContainer } from './button-container'
import { ContentContainer } from './content-container'

type CohortSelectScreenProps = {
  cohorts: Array<CohortsListItem>
  handleCancel: VoidFunction
  handleContinue: VoidFunction
  handleOpenCohortCreate: VoidFunction
  selectedCohort: string
  setSelectedCohort: (cohort: string) => void
}

export const CohortSelectScreen = ({
  cohorts,
  handleCancel,
  handleContinue,
  handleOpenCohortCreate,
  selectedCohort,
  setSelectedCohort,
}: CohortSelectScreenProps) => {
  const { formatMessage } = useIntl()

  return (
    <ContentContainer
      title={formatMessage({
        id: 'coaching.benchmarking.wizard.participants.cohortSelect.title',
      })}
      subtitle={formatMessage({
        id: 'coaching.benchmarking.wizard.participants.cohortSelect.subtitle',
      })}
    >
      <Text color={colors.primary} as={Link} onClick={handleOpenCohortCreate}>
        <FormattedMessage id="coaching.benchmarking.wizard.participants.cohortCreate.linkToCohortCreate" />
      </Text>
      <Flex flexDirection="column" maxHeight={250} overflow="auto" mt={8}>
        <Stack p={2}>
          <RadioGroup onChange={setSelectedCohort} value={selectedCohort}>
            <Stack p={2}>
              {cohorts.map(cohort => (
                <Radio key={cohort.extId} value={cohort.extId}>
                  {cohort.name}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Stack>
        <Spacer />
        <ButtonContainer>
          <Button variant="outline" onClick={handleCancel}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button onClick={handleContinue}>
            <FormattedMessage id="common.continue" />
          </Button>
        </ButtonContainer>
      </Flex>
    </ContentContainer>
  )
}
