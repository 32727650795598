import { Box, Button, Link, Text } from '@chakra-ui/react'
import { type SxProps } from '@mui/material'
import { FormattedMessage } from '@repo/i18n'
import { colors, Heading, showToast } from '@repo/ui'
import { createColumnHelper } from '@tanstack/react-table'
import { times } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { RiAddFill } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentLoader } from '../../builder/components/content-loader'
import { AdvancedTable } from '../../components/advanced-table'
import { CardGrid } from '../../components/card-grid'
import { getContentStatusOfSWR } from '../../hooks/use-swr-loader'
import { useProgramsList } from '../../new-program-builder/shared/api'
import { formatDateTime } from '../../utils/dates'
import { useBenchmarkProfiles } from '../hooks/use-benchmark-profiles'
import { type BenchmarkProfile } from '../types/benchmark-profile'
import { ActionButtonContainer } from './action-button-container'
import { BenchmarkProfileStateBadge } from './benchmark-profile-state-badge'
import { InfoBox } from './info-box'
import { ActionMenu } from './profiles-tab/action-menu'
import { FirstWizardModal } from './wizard/first-wizard-modal'

type Params = {
  wizard?: string
}

const columnHelper = createColumnHelper<BenchmarkProfile>()

const tableSx: SxProps = { minHeight: '90%' }

const columns = [
  columnHelper.accessor('status', {
    header: () => <FormattedMessage id="coaching.benchmarking.profiles.table.status" />,
    cell: props => <BenchmarkProfileStateBadge state={props.getValue()} />,
  }),
  columnHelper.accessor('title', {
    header: () => <FormattedMessage id="common.name" />,
  }),
  columnHelper.accessor('type', {
    header: () => <FormattedMessage id="common.type" />,
  }),
  columnHelper.accessor('updatedAt', {
    header: () => <FormattedMessage id="coaching.programList.table.update" />,
    cell: props => formatDateTime(props.getValue()),
  }),
  columnHelper.accessor(
    row =>
      row.status === 'done' ? row.linkedScenarios.length : row.scenariosToLink.length,
    {
      id: 'scenarios',
      header: () => (
        <FormattedMessage id="coaching.benchmarking.profiles.table.scenarios" />
      ),
    }
  ),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => <ActionMenu profile={row.original} />,
  }),
]

export const ProfilesTab = () => {
  const { wizard } = useParams<Params>()

  const navigate = useNavigate()
  const { useGetAll, useTemplateProfiles, duplicateTemplate } = useBenchmarkProfiles()

  const { data: profileData, status: profileStatus, refresh } = useGetAll()
  const { data: programs, error: programsError } = useProgramsList()
  const programStatus = getContentStatusOfSWR(programs, programsError)

  const { data: templates, isLoading } = useTemplateProfiles()

  const refreshBenchmarkList = useCallback(() => refresh(), [refresh])
  const [isOpenWizardModal, setIsOpenWizardModal] = useState(false)
  const hasNoPrograms = useMemo(
    () => (programs ? programs.length === 0 : true),
    [programs]
  )

  const handleDuplicateTemplate = useCallback(
    async (extId: string) => {
      try {
        await duplicateTemplate(extId)
        refreshBenchmarkList?.()
        showToast({
          messageKey: 'coaching.benchmarking.templates.menu.duplicate.success',
          status: 'success',
        })
      } catch (err) {
        showToast({
          messageKey: 'coaching.benchmarking.templates.menu.duplicate.error',
          status: 'error',
        })
      }
    },
    [duplicateTemplate, refreshBenchmarkList]
  )

  return (
    <>
      <ContentLoader status={programStatus}>
        {hasNoPrograms && (
          <InfoBox>
            <FormattedMessage id="coaching.benchmarking.profiles.noPrograms" />
            <Text as={Link} color={colors.primary}>
              <FormattedMessage id="coaching.benchmarking.profiles.noPrograms.link" />
            </Text>
          </InfoBox>
        )}
      </ContentLoader>
      <ActionButtonContainer>
        <Button
          onClick={() => setIsOpenWizardModal(true)}
          isDisabled={hasNoPrograms}
          leftIcon={<RiAddFill size="1.25em" />}
          iconSpacing="1"
          colorScheme="blue"
        >
          <FormattedMessage id="coaching.benchmarking.button.create" />
        </Button>
      </ActionButtonContainer>
      <Text fontSize="24px" mb={4} fontWeight={700} color={colors.gray[900]}>
        <FormattedMessage id="coaching.benchmarking.tabs.benchmark.profiles" />
      </Text>
      <CardGrid>
        {isLoading
          ? times(4).map(i => <CardGrid.SkeletonCard key={i} />)
          : templates?.slice(0, 4)?.map(template => (
              <CardGrid.Card
                key={template.extId}
                title={template.title}
                image={template.icon}
                footer={{
                  type: 'cta',
                  labelKey: 'coaching.benchmarking.templates.menu.duplicate',
                  onClick: () => handleDuplicateTemplate(template.extId),
                }}
              >
                <Text fontSize={14}>{template.description}</Text>
              </CardGrid.Card>
            ))}
      </CardGrid>

      <Button
        p={4}
        m={6}
        alignSelf="center"
        w="max-content"
        variant="outline"
        borderRadius=" 6px"
        onClick={() => navigate('/coaching/benchmarking/profiles/templates')}
        fontSize="14px"
      >
        <FormattedMessage id="coaching.benchmarking.templates.button" />
      </Button>

      <Heading size="md" mt={10}>
        <FormattedMessage id="coaching.benchmarking.profiles.title" />
      </Heading>
      <ContentLoader status={profileStatus}>
        {profileData?.length ? (
          <AdvancedTable data={profileData} columns={columns} sx={tableSx} />
        ) : (
          <Box mt={4}>
            <FormattedMessage id="coaching.benchmarking.profiles.noPrograms.label" />
          </Box>
        )}
        {isOpenWizardModal && (
          <FirstWizardModal onClose={() => setIsOpenWizardModal(false)} />
        )}
      </ContentLoader>
      {wizard === 'wizard' && !isOpenWizardModal && (
        <FirstWizardModal onClose={() => navigate(`/coaching/benchmarking`)} />
      )}
    </>
  )
}
