import { Flex, Icon, Text } from '@chakra-ui/react'
import { type ReactNode } from 'react'
import { type IconType } from 'react-icons'

type IconButtonProps = {
  label: ReactNode
  icon: IconType
  onClick: VoidFunction
}

export const IconButton = ({ label, icon, onClick }: IconButtonProps) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    border="2px solid black"
    borderRadius={4}
    h="180px"
    w="220px"
    m={4}
    onClick={onClick}
    _hover={{ cursor: 'pointer', bg: 'gray.200' }}
  >
    <Icon boxSize="80px" as={icon} />
    <Text textAlign="center">{label}</Text>
  </Flex>
)
