import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'

import { PageNavigator } from '../../../../components/page-navigator'
import { type QuestionWithRatios } from '../../../shared/types'
import { DashboardCard } from '../shared/dashboard-card'
import { CompareBestAndWorstView } from './strengths-and-weaknesses-card/compare-best-and-worst-view'

export type StrengthsAndWeaknessesCardProps = {
  overall: {
    best: Array<QuestionWithRatios>
    worst: Array<QuestionWithRatios>
  }
  cohorts: Array<{
    id: string
    name: string
    best: Array<QuestionWithRatios>
    worst: Array<QuestionWithRatios>
  }>
}

export const StrengthsAndWeaknessesCard = ({
  cohorts,
  overall,
}: StrengthsAndWeaknessesCardProps) => {
  const { formatMessage } = useIntl()

  const multipleCohortSelected = cohorts.length > 1

  return (
    <DashboardCard
      colSpan={3}
      titleKey="dashboard.knowledgeCheck.strengthsWeaknessesCard.title"
      descriptionKey="dashboard.knowledgeCheck.strengthsWeaknessesCard.description"
      wrapperProps={{ pt: 5 }}
    >
      <Box mt={3} />

      {multipleCohortSelected ? (
        <Tabs variant="solid-rounded" size="sm">
          <TabList>
            <Tab>
              {formatMessage({
                id: 'dashboard.knowledgeCheck.strengthsWeaknessesCard.tabs.summary',
              })}
            </Tab>
            <Tab>
              {formatMessage({
                id: 'dashboard.knowledgeCheck.strengthsWeaknessesCard.tabs.perCohort',
              })}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <CompareBestAndWorstView
                title={formatMessage({ id: 'dashboard.cohortFilter.multipleCohorts' })}
                best={overall.best}
                worst={overall.worst}
              />
            </TabPanel>
            <TabPanel>
              <PageNavigator
                showLabelAndArrowsAtTop
                pages={cohorts.map(cohort => ({
                  label: cohort.name,
                  content: (
                    <CompareBestAndWorstView
                      key={cohort.id}
                      best={cohort.best}
                      worst={cohort.worst}
                    />
                  ),
                }))}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        cohorts[0] && (
          <CompareBestAndWorstView
            title={cohorts[0].name}
            best={cohorts[0].best}
            worst={cohorts[0].worst}
          />
        )
      )}
    </DashboardCard>
  )
}
