import { Flex, FormControl, Icon, IconButton } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { isNil } from 'lodash-es'
import { useCallback } from 'react'
import { BiPlusCircle } from 'react-icons/bi'
import { RiEditBoxLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { Select } from '../../components/formik-form/select'
import { useThunkFetching } from '../../hooks/thunk-fetching'
import { fetchQuestionnaires } from '../../store/entities/questionnaires/effects'
import { makeSelectQuestionnaires } from '../../store/entities/questionnaires/selectors'
import { selectQuestionnaireIds } from '../../store/ui/plugin-editor/selectors'

const selectQuestionnaires = makeSelectQuestionnaires()

export const QuestionnaireSelection = ({
  questionnaireId,
}: {
  questionnaireId: string
}) => {
  const [fetching, questionnaires, error] = useThunkFetching({
    action: fetchQuestionnaires,
    mapState: state => selectQuestionnaires(state, selectQuestionnaireIds),
  })

  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const handleCreateQuestionnaire = () =>
    navigate('/questionnaires/create', {
      state: { isFromNotification: 'notificationModal' },
    })

  const handleEditQuestionnaire = useCallback(() => {
    if (isNil(questionnaireId)) {
      return
    }

    navigate(`/questionnaires/${questionnaireId}`, {
      state: { isFromNotification: 'notificationModal' },
    })
  }, [questionnaireId, navigate])

  if (error) {
    return <div>{error.message}</div>
  }

  const selectOptions = [
    {
      value: '',
      label: formatMessage({ id: 'plugin.questionnaire.select.empty' }),
    },
  ].concat(
    questionnaires.map(questionnaire => ({
      value: questionnaire.id,
      label: questionnaire.name,
    }))
  )

  return (
    <FormControl>
      <Flex>
        <Select
          type="select"
          disabled={fetching}
          name="questionnaireId"
          options={selectOptions}
        />
        <IconButton
          aria-label=""
          ml={2}
          variant="ghost"
          onClick={handleEditQuestionnaire}
          isDisabled={isNil(questionnaireId)}
          icon={<Icon boxSize="1.25em" as={RiEditBoxLine} />}
        />
        <IconButton
          aria-label=""
          ml={2}
          variant="ghost"
          isLoading={fetching}
          onClick={handleCreateQuestionnaire}
          icon={<Icon boxSize="1.25em" as={BiPlusCircle} />}
        />
      </Flex>
    </FormControl>
  )
}
