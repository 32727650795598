import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  type HTMLChakraProps,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { memo } from 'react'

type MutePlaybackOwnProps = {
  onClose: () => void
}

type MutePlaybackProps = MutePlaybackOwnProps & HTMLChakraProps<'div'>

export const MutePlaybackAlert = memo(
  ({ onClose, ...chakraProps }: MutePlaybackProps) => (
    <Alert status="error" {...chakraProps}>
      <AlertIcon />
      <AlertTitle mr={2}>
        <FormattedMessage id="playback.muted.title" />
      </AlertTitle>
      <AlertDescription>
        <FormattedMessage id="playback.muted.description" />
      </AlertDescription>
      <CloseButton position="absolute" right="8px" top="8px" onClick={onClose} />
    </Alert>
  )
)
