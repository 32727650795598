import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  type ThemeTypings,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, PillTag, showToast } from '@repo/ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ImageWithFallback } from '../../../../../components/image-with-fall-back'
import { useGoogleStorageAsset } from '../../../../../hooks/use-google-storage-asset'
import { useProgramsList } from '../../../../../new-program-builder/shared/api'
import { client } from '../../../../../utils/openapi-client'
import {
  type Department,
  type FiltersOnlyValues,
  type FilterType,
  type Industry,
  type UseCase,
} from '../../../shared/filters'
import { type ProgramTemplate } from '../../../shared/types'
import { WhatIsIncludedSection } from './program-template-modal/what-is-included-section'
import { templateTagVariants } from './template-tag-variants'

interface ProgramTemplateModalProps {
  onClose: () => void
  template: ProgramTemplate
}

const BadgeSection = <T extends FilterType>({
  filterType,
  items,
  colorScheme,
}: {
  items: Array<FiltersOnlyValues[T]>
  colorScheme?: ThemeTypings['colorSchemes']
  filterType: T
}) => (
  <Box>
    <Text fontSize={14} fontWeight={500} textTransform="uppercase" mb={4}>
      <FormattedMessage
        id={`coaching.program.templates.${filterType}` satisfies I18nKey}
      />
    </Text>
    <Stack gap={3} alignItems="flex-start">
      {items.length === 0 ? (
        <Badge maxW="130px" whiteSpace="normal" colorScheme={colorScheme}>
          <FormattedMessage id="coaching.program.templates.suitableForAll" />
        </Badge>
      ) : (
        items.map(item => (
          <Badge maxW="130px" whiteSpace="normal" key={item} colorScheme={colorScheme}>
            <FormattedMessage
              id={`coaching.program.templates.${filterType}.${item}` as I18nKey}
            />
          </Badge>
        ))
      )}
    </Stack>
  </Box>
)

const VerticalDivider = () => <Box w="2px" minH="full" bg={colors.gray[300]} />

export const ProgramTemplateModal = ({
  onClose,
  template,
}: ProgramTemplateModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { mutate } = useProgramsList()

  const useProgram = async () => {
    try {
      setIsLoading(true)
      const { data: createdProgramExtId } = await client.post(
        'createProgramUsingTemplate',
        {
          params: { path: { extId: template.extId } },
        }
      )

      if (!createdProgramExtId) {
        throw new Error('Failed to create program')
      }

      navigate(`/training_programs/${createdProgramExtId}`)
      mutate(
        programs => [
          {
            ...template.program,
            extId: createdProgramExtId,
            hasBreakingChanges: false,
            hasRecordingSessions: false,
            hasRegisteredCoachingUsers: false,
            sharedPrograms: [],
            trainingsCount: template.program.trainings.length,
            imageUrl: template.program.imageUrl,
            status: 'unpublished',
            understandingEnabled: true,
            description: template.program.description,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          },
          ...(programs ?? []),
        ],
        false
      )

      onClose()
    } catch (error) {
      showToast({ messageKey: 'general.error.fallback', status: 'error' })
      setIsLoading(false)
    }
  }

  const useProgramButton = (
    <Button onClick={useProgram} isLoading={isLoading}>
      <FormattedMessage id="coaching.program.templates.modal.useProgram" />
    </Button>
  )

  const imageHttpUrl = useGoogleStorageAsset(template?.program.imageUrl ?? '')

  const {
    departments,
    industries,
    program: { language, title, trainings, description },
    useCases,
    tag,
  } = template

  const templateTag = tag ? templateTagVariants[tag] : undefined

  return (
    <Modal scrollBehavior="inside" size="4xl" onClose={onClose} isOpen isCentered>
      <ModalOverlay />
      <ModalContent overflow="hidden">
        <ModalCloseButton
          top={6}
          right={6}
          bg={colors.blue[500]}
          borderRadius="full"
          color="white"
        />

        <ImageWithFallback
          height={180}
          width="full"
          objectFit="cover"
          pointerEvents="none"
          src={imageHttpUrl}
        />

        <ModalBody px="60px" py="40px">
          {tag && (
            <PillTag
              icon={templateTag?.icon}
              text={
                templateTag?.labelKey && <FormattedMessage id={templateTag.labelKey} />
              }
              sx={{
                color: colors.gray[800],
                bgColor: templateTag?.color,
                mb: 2,
              }}
            />
          )}
          <HStack mb={10} gap={4}>
            <Text flex={1} fontSize={24} fontWeight={500} textTransform="uppercase">
              {title}
            </Text>

            {useProgramButton}
          </HStack>

          <Text fontSize={14} mb="56px">
            {description}
          </Text>

          <Flex justifyContent="space-between" mb="64px">
            <BadgeSection filterType="language" items={[language]} />
            <VerticalDivider />
            <BadgeSection filterType="industry" items={industries as Array<Industry>} />
            <VerticalDivider />
            <BadgeSection
              filterType="department"
              items={departments as Array<Department>}
            />
            <VerticalDivider />
            <BadgeSection
              filterType="useCases"
              colorScheme="blue"
              items={useCases as Array<UseCase>}
            />
          </Flex>

          <WhatIsIncludedSection trainings={trainings} />

          <Center mt="80px">{useProgramButton}</Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
