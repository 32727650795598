import { useFormikContext } from 'formik'

import { type CohortCreationValues } from '../../types'
import { EmailStep } from './components/email-step/email-step'
import { TokenStep } from './components/token-step'

export const ParticipantsStep = () => {
  const {
    values: { type },
  } = useFormikContext<CohortCreationValues>()

  return type === 'email' ? <EmailStep /> : <TokenStep />
}
