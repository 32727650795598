import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useState } from 'react'

import { WizardProgressBar } from '../../../components/wizard-progress-bar'
import { type FirstWizardStep } from '../../types/wizard'
import { IntroScreenWrapper } from './intro-screen-wrapper'
import { ParticipantsScreenWrapper } from './participant-screen-wrapper'
import { TrainingProgramScreen } from './training-program-screen'
import { WaitForRecordingsScreen } from './wait-for-recordings-screen'

const orderedFirstWizardSteps = [
  'intro',
  'participants',
  'trainingProgram',
  'waitForRecordings',
] as const satisfies Array<FirstWizardStep>

type FirstWizardModalProps = {
  onClose: VoidFunction
}

export const FirstWizardModal = ({ onClose }: FirstWizardModalProps) => {
  const [currentStep, setCurrentStep] = useState<FirstWizardStep>(
    orderedFirstWizardSteps[0]
  )

  const [selectedCohort, setSelectedCohort] = useState<string | undefined>()

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <Box sx={{ '.chakra-modal__content-container': { overflow: 'hidden' } }}>
        <ModalOverlay />
        <ModalContent minW="800px" h="700px">
          <ModalHeader>
            <WizardProgressBar
              steps={orderedFirstWizardSteps}
              currentStep={currentStep}
            />
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody pb={4}>
            {currentStep === 'intro' && (
              <IntroScreenWrapper
                handleContinue={() => setCurrentStep('participants')}
                handleCancel={onClose}
              />
            )}
            {currentStep === 'participants' && (
              <ParticipantsScreenWrapper
                handleContinue={() => setCurrentStep('trainingProgram')}
                handleCancel={onClose}
                selectedCohort={selectedCohort}
                setSelectedCohort={setSelectedCohort}
              />
            )}
            {currentStep === 'trainingProgram' && selectedCohort && (
              <TrainingProgramScreen
                handleContinue={() => setCurrentStep('waitForRecordings')}
                handleCancel={onClose}
                cohortExtId={selectedCohort}
              />
            )}
            {currentStep === 'waitForRecordings' && selectedCohort && (
              <WaitForRecordingsScreen cohortExtId={selectedCohort} />
            )}
          </ModalBody>
        </ModalContent>
      </Box>
    </Modal>
  )
}
