import { Stack } from '@chakra-ui/react'
import { type ApiSchemas } from '@repo/api'
import { useIntl } from '@repo/i18n'
import { RHF, showToast, useFormRules } from '@repo/ui'
import { assertExists, assertValueEqualsTarget } from '@repo/utils'
import { useCallback, useEffect, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { CenteredSpinner } from '../../../../../components/centered-spinner'
import { useUserSessionId } from '../../../../../store/entities/user-activity-session/use-user-session-id'
import { client } from '../../../../../utils/openapi-client'
import { StickyFooter } from '../../../../shared/sticky-footer'
import { TextAreaWithTones } from '../../../../shared/textarea-with-tones'
import { type Tone } from '../../../../shared/textarea-with-tones/types'
import { useScenarioBuilderData } from '../../../../shared/use-scenario-builder-data'
import { useRealtimeBuilderContext } from '../../../shared/realtime-builder-context'
import { useInteractionStepContext } from '../interaction-step-context'
import { AiInputPlaceholder } from './step-session-details/ai-input-placeholder'
import { ContinueButton } from './step-session-details/continue-button'

type FormValues = {
  furtherDetails: string
  goal: string
  description: string
  missionGoal: string
}

const inputOrder: Array<keyof FormValues> = [
  'furtherDetails',
  'goal',
  'description',
  'missionGoal',
]

type UnlockedInputs = {
  [K in keyof FormValues]: boolean
}

export const StepSessionDetails = () => {
  const { goToPreviousStep, goToNextStep } = useInteractionStepContext()
  const { updateScenarioDetails, mutateScenario, scenario, isBuilderReadonly } =
    useRealtimeBuilderContext()

  const [pathParams] = useScenarioBuilderData()

  const rules = useFormRules()
  const { formatMessage } = useIntl()

  const userSessionId = useUserSessionId()

  const { control, watch, setValue, formState, handleSubmit } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      furtherDetails: scenario?.furtherDetails ?? undefined,
      goal: scenario?.goal ?? undefined,
      description: scenario?.description ?? undefined,
      missionGoal: scenario?.missionGoal ?? undefined,
    },
    disabled: isBuilderReadonly,
  })

  const [suggestionsWithTone, setSuggestionsWithTone] = useState<{
    goal: Array<Tone>
    description: Array<Tone>
  }>({
    goal: [],
    description: [],
  })

  const [unlockedInputs, setUnlockedInputs] = useState<UnlockedInputs>({
    furtherDetails: true,
    goal: !!scenario.furtherDetails || !!scenario.goal,
    description: !!scenario.goal,
    missionGoal: !!scenario.description,
  })

  const [isFetchingInitialSuggestions, setIsFetchingInitialSuggestions] = useState(true)

  const fetchAndSetAiSuggestionForInput = useCallback(
    async (input: keyof FormValues) => {
      try {
        if (input === 'furtherDetails') {
          return
        }

        if (input === 'missionGoal') {
          const res = await client.post('generateScenarioContentForRealtimeScenario', {
            body: {
              field: 'mission_goal',
              userActivitySessionExtId: userSessionId,
            },
            params: {
              path: pathParams,
            },
          })

          assertExists(res.data)
          assertValueEqualsTarget(res.data.field, 'mission_goal')

          return setValue(input, res.data.suggestion, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }

        const field =
          input === 'goal'
            ? 'learning_goal'
            : ('scenario_description' satisfies ApiSchemas['generateScenarioContentRequestRealtime']['field'])

        const responses = await Promise.all(
          (['professional', 'catchy'] as const).map(tone =>
            client.post('generateScenarioContentForRealtimeScenario', {
              body: {
                field,
                tone,
                userActivitySessionExtId: userSessionId,
              },
              params: {
                path: pathParams,
              },
            })
          )
        )

        assertExists(responses[0]?.data)
        assertExists(responses[1]?.data)

        assertValueEqualsTarget(responses[0]?.data.field, field)
        assertValueEqualsTarget(responses[1]?.data.field, field)

        const [
          {
            data: { suggestion: professionalSuggestion },
          },
          {
            data: { suggestion: catchySuggestion },
          },
        ] = responses

        const newTonesArray = [
          {
            value: professionalSuggestion,
            labelKey: 'common.tone.professional',
          },
          {
            value: catchySuggestion,
            labelKey: 'common.tone.catchy',
          },
        ] as const satisfies Array<Tone>

        setSuggestionsWithTone(prev => ({
          ...prev,
          [input]: newTonesArray,
        }))

        setValue(input, newTonesArray[0].value, { shouldValidate: true })
      } catch (error) {
        showToast({
          messageKey:
            'scenario.ai.builder.steps.conversationGeneration.suggestions.failed',
          status: 'error',
        })
      }
    },
    [pathParams, setValue, userSessionId]
  )

  useEffect(() => {
    const fetchInitialSuggestions = async () => {
      setIsFetchingInitialSuggestions(true)
      const lastUnlockedInput = inputOrder.findLast(input => unlockedInputs[input])

      assertExists(lastUnlockedInput, 'lastUnlockedInput')

      const skipFetchingSuggestion =
        lastUnlockedInput === 'missionGoal' && !!scenario.missionGoal

      if (!skipFetchingSuggestion) {
        await fetchAndSetAiSuggestionForInput(lastUnlockedInput)
      }

      setIsFetchingInitialSuggestions(false)
    }

    fetchInitialSuggestions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const unlockNextInput = useCallback(
    async (currentInput: keyof FormValues) => {
      const currentIndex = inputOrder.indexOf(currentInput)
      const nextInput = inputOrder[currentIndex + 1]

      assertExists(nextInput, 'nextInput')

      await fetchAndSetAiSuggestionForInput(nextInput)

      setUnlockedInputs(prev => ({
        ...prev,
        [nextInput]: true,
      }))
    },
    [fetchAndSetAiSuggestionForInput]
  )

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async data => {
      const isAtLastStep = unlockedInputs.missionGoal

      if (isAtLastStep && !formState.isDirty) {
        return goToNextStep()
      }

      const res = await updateScenarioDetails({
        furtherDetails: data.furtherDetails,
        goal: data.goal,
        description: data.description,
        missionGoal: data.missionGoal,
      })

      if (res.error) {
        return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }

      mutateScenario(res.data, false)

      if (isAtLastStep) {
        return goToNextStep()
      }

      const currentInput =
        inputOrder.findLast(input => unlockedInputs[input]) ?? 'furtherDetails'

      await unlockNextInput(currentInput)
    },
    [
      formState.isDirty,
      goToNextStep,
      updateScenarioDetails,
      mutateScenario,
      unlockNextInput,
      unlockedInputs,
    ]
  )

  if (isFetchingInitialSuggestions) {
    return <CenteredSpinner />
  }

  const allInputsAreUnlocked = Object.values(unlockedInputs).every(val => !!val)

  const isStickyNextButtonDisabled = !formState.isValid || !allInputsAreUnlocked

  return (
    <Stack as="form" flex={1} gap={8} onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={16} maxW="800px">
        <RHF.TextArea
          control={control}
          name="furtherDetails"
          rules={rules.stringCustomLength(50000)}
          labelKey="scenario.builder.ai.steps.goal.furtherDetails.label"
          componentProps={{
            rows: 6,
            placeholder: formatMessage({
              id: 'scenario.builder.ai.steps.goal.furtherDetails.placeholder',
            }),
          }}
          tooltipKey="scenario.builder.realtime.steps.furtherDetails.tooltip"
        />

        {!unlockedInputs.goal && (
          <ContinueButton showAsSkip={!watch('furtherDetails')} formState={formState} />
        )}

        {unlockedInputs.goal ? (
          <TextAreaWithTones
            control={control}
            name="goal"
            rules={rules.stringLongRequired}
            watch={watch}
            setValue={setValue}
            tones={suggestionsWithTone.goal}
            labelKey="scenario.manual.builder.details.step.goal.label"
            placeholderKey="scenario.manual.builder.details.step.goal.label"
            tooltipKey="scenario.builder.realtime.steps.learner.goal.tooltip"
          />
        ) : (
          <AiInputPlaceholder label="scenario.manual.builder.details.step.goal.label" />
        )}

        {!unlockedInputs.description && unlockedInputs.goal && (
          <ContinueButton formState={formState} />
        )}

        {unlockedInputs.description ? (
          <TextAreaWithTones
            control={control}
            name="description"
            rules={rules.stringCustomLengthRequired(2000)}
            watch={watch}
            setValue={setValue}
            tones={suggestionsWithTone.description}
            labelKey="scenario.description.label"
            placeholderKey="scenario.description.placeholder"
            tooltipKey="scenario.builder.realtime.steps.session.description.tooltip"
          />
        ) : (
          <AiInputPlaceholder label="scenario.description.label" />
        )}

        {!unlockedInputs.missionGoal && unlockedInputs.description && (
          <ContinueButton formState={formState} />
        )}

        {unlockedInputs.missionGoal ? (
          <RHF.TextArea
            control={control}
            name="missionGoal"
            labelKey="scenario.builder.realtime.sessionGoal.label"
            rules={rules.stringLongRequired}
            helperTextKey="common.youCanEdit"
            componentProps={{
              placeholder: formatMessage({
                id: 'scenario.builder.realtime.sessionGoal.label',
              }),
            }}
            tooltipKey="scenario.builder.realtime.steps.session.goal.tooltip"
          />
        ) : (
          <AiInputPlaceholder
            label="scenario.builder.realtime.sessionGoal.label"
            hideToneButtons
          />
        )}
      </Stack>

      <StickyFooter
        onBack={goToPreviousStep}
        isNextDisabled={isStickyNextButtonDisabled}
        isNextLoading={!isStickyNextButtonDisabled && formState.isSubmitting}
      />
    </Stack>
  )
}
