import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import { colors } from '@repo/ui'

interface ScoreCircleProps {
  value: number
}

export const ScoreCircle = ({ value }: ScoreCircleProps) => (
  <CircularProgress
    capIsRound
    value={value}
    color={colors.blue[400]}
    size="60px"
    thickness={8}
  >
    <CircularProgressLabel fontSize={18} fontWeight={500}>
      {value}%
    </CircularProgressLabel>
  </CircularProgress>
)
