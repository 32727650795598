import types from './private/actions'

const initialState = []

// eslint-disable-next-line @typescript-eslint/no-explicit-any, import/no-default-export
export default (state = initialState, { type, data }: any = {}) => {
  switch (type) {
    case types.CREATE_ROLE_SUCCESS:
      return [...state, data]
    case types.RECEIVE_ROLES:
      return [...data]
    case types.UPDATE_ROLE_SUCCESS: {
      const newList = [...state]
      const index = newList.findIndex(({ id }) => id === data.id)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newList.splice(index, 1, data)

      return newList
    }

    case types.DELETE_ROLE_SUCCESS: {
      const newList = [...state]
      const index = newList.findIndex(({ id }) => id === data)

      newList.splice(index, 1)

      return newList
    }

    default:
      return state
  }
}
