import { Box } from '@chakra-ui/react'
import { constVoid } from '@repo/utils'
import { useMachine } from '@xstate/react'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { MutePlaybackAlert } from './mute-playback-alert'
import { playVideo, videoPlayerMachine } from './video-player-state-machine'

export type VideoPlayerProps = {
  url: string
  autoPlay?: boolean
  isOnLoop?: boolean
  onCanPlay?: () => void
  onPlay?: () => void
  onPause?: () => void
  onEnd?: () => void
  onTimeUpdate?: (time: number) => void
  nativeControls?: boolean
  aspectRatio?: string
  videoHeight?: string
  videoWidth?: string
  objectFitType?: string
  setVideoLoaded?: (videoLoaded: boolean) => void
}

export type VideoPlayerHandle = {
  play: () => void
  pause: () => void
  seek: (time: number) => void
}

export const VideoPlayer = forwardRef<VideoPlayerHandle, VideoPlayerProps>(
  (
    {
      url,
      autoPlay: autoPlayProp,
      onCanPlay,
      onPlay,
      onPause,
      onEnd,
      onTimeUpdate,
      isOnLoop,
      nativeControls = false,
      aspectRatio,
      videoHeight = 'auto',
      videoWidth = 'auto',
      objectFitType = 'cover',
      setVideoLoaded,
    },
    ref
  ) => {
    const videoElementRef = useRef<HTMLVideoElement>(null)
    const [current, send] = useMachine(videoPlayerMachine, {
      actions: {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        playVideo: context =>
          playVideo(
            context,
            () => {
              send({ type: 'PLAY-OK' })
            },
            () => {
              send({ type: 'PLAY-MUTED' })
            }
          ),
      },
    })

    const isReadyPausedState = current.matches({ ready: 'paused' })
    const isPlaying =
      current.matches({ ready: 'playing' }) || current.matches({ ready: 'playing-muted' })

    const isPlayingMuted = current.matches({ ready: 'playing-muted' })
    // Auto Play cannot be changed after the first render
    const [autoPlay, setAutoPlay] = useState(false)

    // When the URL changes, we should reset the initial Auto Play value
    useEffect(() => {
      setAutoPlay(autoPlayProp ?? false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    // Handle Auto Play
    useEffect(() => {
      if (autoPlay) {
        const t = setInterval(() => {
          if (isReadyPausedState) {
            send({ type: 'PLAY' })
            clearInterval(t)
            setAutoPlay(false)
          }
        }, 100)

        return () => clearInterval(t)
      }

      return constVoid
    }, [autoPlay, isReadyPausedState, send])

    useImperativeHandle(ref, () => ({
      play: () => send({ type: 'PLAY' }),
      pause: () => send({ type: 'PAUSE' }),
      seek: time => send({ type: 'SEEK', time }),
    }))

    // Update videoLoaded state when the 'loadeddata' event is fired
    useEffect(() => {
      const refCurrent = videoElementRef.current
      const handleCanPlayThrough = () => {
        if (setVideoLoaded) {
          setVideoLoaded(true)
        }
      }

      refCurrent?.addEventListener('canplaythrough', handleCanPlayThrough)

      return () => {
        refCurrent?.removeEventListener('canplaythrough', handleCanPlayThrough)
      }
    }, [setVideoLoaded])

    useEffect(() => {
      send({ type: 'END' })
      send({ type: 'LOAD' })
    }, [url, send])

    useEffect(() => {
      if (isReadyPausedState) {
        onPause?.()
      }
    }, [isReadyPausedState, onPause])

    useEffect(() => {
      if (isPlaying) {
        onPlay?.()
      }
    }, [isPlaying, onPlay])

    useEffect(() => {
      videoElementRef.current?.load()
    }, [url])

    return (
      <Box h="auto" w="full" display="flex" alignItems="center" justifyContent="center">
        {isPlayingMuted && (
          <MutePlaybackAlert
            position="fixed"
            top="10vh"
            onClose={() => {
              send({ type: 'UNMUTE' })
            }}
          />
        )}

        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          loop={isOnLoop}
          preload="auto"
          playsInline
          ref={videoElementRef}
          onCanPlay={() => {
            if (videoElementRef.current) {
              send({
                type: 'LOADED',
                videoEl: videoElementRef.current,
                duration: videoElementRef.current.duration,
              })

              onCanPlay?.()
            }
          }}
          onError={() => {
            send({ type: 'FAIL' })
          }}
          onTimeUpdate={() => {
            if (videoElementRef.current) {
              onTimeUpdate?.(videoElementRef.current?.currentTime || 0)
            }
          }}
          onEnded={() => {
            send({ type: 'END' })
            onEnd?.()
          }}
          style={{
            height: videoHeight,
            width: videoWidth || '100%',
            aspectRatio: aspectRatio || 'auto',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            objectFit: objectFitType as any,
          }}
          controls={nativeControls}
        >
          <source src={url} />
        </video>
      </Box>
    )
  }
)
