import { Button, Center, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast, useModal } from '@repo/ui'
import { type SubmitHandler } from 'react-hook-form'

import { type ExtendedProgram } from '../../../types/api-types'
import { client } from '../../../utils/openapi-client'
import { useProgramsList } from '../../shared/api'
import { type ProgramFormValues } from '../../shared/types'
import { useProgramForm } from '../shared/use-program-form'

const EditProgramForm = ({
  closeModal,
  program,
}: {
  closeModal: () => void
  program: ExtendedProgram
}) => {
  const [form, formFieldsJsx] = useProgramForm(program)
  const { handleSubmit, formState } = form
  const { mutate } = useProgramsList()

  const onSubmit: SubmitHandler<ProgramFormValues> = async data => {
    try {
      const res = await client.put('updateProgram', {
        body: data,
        params: { path: { extId: program.extId } },
      })

      if (res.error) {
        throw new Error()
      }

      mutate(
        programs =>
          programs?.map(_p => (_p.extId === program.extId ? { ..._p, ...res.data } : _p)),
        false
      )

      showToast({ messageKey: 'common.alert.saved', status: 'success' })
      closeModal()
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
      {formFieldsJsx}

      <Center gap={3} mt={6}>
        <Button variant="outline" onClick={closeModal}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          type="submit"
          isDisabled={
            !formState.isValid || !formState.isDirty || !!formState.errors.imageUrl
          }
          isLoading={formState.isSubmitting}
        >
          <FormattedMessage id="common.save" />
        </Button>
      </Center>
    </Stack>
  )
}

export const useEditProgramModal = (program: ExtendedProgram) => {
  const modal = useModal(
    ({ closeModal }) => <EditProgramForm program={program} closeModal={closeModal} />,
    {
      titleKey: 'coaching.programList.table.options.edit',
    }
  )

  return modal
}
