import {
  type CoachingStep,
  type CoachingStepFormFields,
} from '../../../../builder/steps/api'

export const getStepTypeForNewBuilder = (
  stepItem: RecordWithOptionalId<CoachingStep> | CoachingStepFormFields
) => {
  // TODO temporary fix for AI QA step vs manual QA step
  if (stepItem.step.stepType === 'coachingStepQA') {
    return 'coachingStepQA'
  }

  if ('type' in stepItem.step) {
    return stepItem.step.type
  }

  return stepItem.step.stepType
}
