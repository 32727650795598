import { ImageWithFallback } from '../../components/image-with-fall-back'
import { useGoogleStorageAsset } from '../../hooks/use-google-storage-asset'

export const ImageCell = ({ url }: { url?: string | null }) => {
  const imageHttpUrl = useGoogleStorageAsset(url ?? '')

  return (
    <ImageWithFallback
      height={50}
      width={50}
      maxWidth={50}
      objectFit="contain"
      pointerEvents="none"
      src={imageHttpUrl}
    />
  )
}
