import { Box, Button, Icon, IconButton, Text, useDisclosure } from '@chakra-ui/react'
import { Menu, MenuItem, type SxProps } from '@mui/material'
import { FormattedMessage } from '@repo/i18n'
import { colors, showToast, useConfirmationModal } from '@repo/ui'
import { createColumnHelper } from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'
import { RiAddFill, RiErrorWarningLine, RiMoreFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { ContentLoader } from '../../builder/components/content-loader'
import { ActionButtonContainer } from '../../coaching-benchmark-profiles/components/action-button-container'
import { AdvancedTable } from '../../components/advanced-table'
import { formatDateTime } from '../../utils/dates'
import { type CohortsListItem } from '../types/cohorts-list-item'
import { useCohorts } from '../use-cohorts'
import { CohortCreationModal } from './cohort-creation-modal/cohort-creation-modal'

const tableSx: SxProps = { minHeight: '90%' }
const getRowId = (row: CohortsListItem) => row.extId

const columnHelper = createColumnHelper<CohortsListItem>()

const ActionMenu = ({
  cohort,
  onDelete,
}: {
  cohort: CohortsListItem
  onDelete: (id: string) => Promise<void>
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleConfirmDelete = async () => {
    try {
      await onDelete(cohort.extId)
      showToast({
        messageKey: 'common.alert.deleted',
        status: 'success',
      })
    } catch (err) {
      showToast({
        messageKey: 'general.error.deleting.data',
        status: 'error',
      })
    }
  }

  const [confirmDeleteModal, showConfirmDeleteModal] = useConfirmationModal({
    onConfirm: handleConfirmDelete,
    confirmButtonKey: 'common.delete',
    title: (
      <FormattedMessage
        id="cohort.delete.confirmModal.title"
        values={{ cohortName: cohort.name }}
      />
    ),
    description: <FormattedMessage id="cohort.delete.confirmModal.description" />,
    isDestructive: true,
  })

  return (
    <>
      <IconButton
        variant="ghost"
        icon={<RiMoreFill />}
        aria-label="actions"
        onClick={handleClick}
        aria-controls={open ? 'cohort-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
      />
      <Menu
        id="cohort-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={e => e.stopPropagation()}
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
      >
        <MenuItem
          sx={{ color: colors.indicator['red-dark'] }}
          onClick={() => {
            handleClose()
            showConfirmDeleteModal()
          }}
        >
          <FormattedMessage id="common.delete" />
        </MenuItem>
      </Menu>

      {confirmDeleteModal}
    </>
  )
}

export const CohortsTable = ({ isBenchmarking }: { isBenchmarking: boolean }) => {
  const { useCohortsList, deleteCohort: removeCohort } = useCohorts()
  const { data, status, refresh: refreshList } = useCohortsList({ isBenchmarking })
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const deleteCohort = useCallback(
    async (cohortId: string) => {
      await removeCohort(cohortId)
      refreshList()
    },
    [removeCohort, refreshList]
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: () => <FormattedMessage id="common.name" />,
        cell: props => props.getValue(),
      }),
      columnHelper.accessor('type', {
        header: () => <FormattedMessage id="cohorts.list.table.invitation.mode" />,
        cell: props => (
          <FormattedMessage
            id={
              props.getValue() === 'token'
                ? 'cohorts.list.table.row.token.mode'
                : 'cohorts.list.table.row.email.mode'
            }
          />
        ),
      }),
      columnHelper.display({
        id: 'participants',
        header: () => <FormattedMessage id="cohorts.list.table.participants" />,
        cell: props => {
          const row = props.row.original

          return row.type === 'token' ? row.activeTokens : row.activeEmails
        },
      }),
      columnHelper.accessor('createdAt', {
        header: () => <FormattedMessage id="cohorts.list.table.createdAt" />,
        cell: props => formatDateTime(props.getValue()),
      }),
      columnHelper.display({
        id: 'actions',
        cell: props => <ActionMenu cohort={props.row.original} onDelete={deleteCohort} />,
        meta: {
          align: 'right',
        },
      }),
    ],
    [deleteCohort]
  )

  const handleRowClick = (row: CohortsListItem) => {
    if (isBenchmarking) {
      navigate(`/coaching/benchmarking/cohorts/${row.extId}`)
    } else {
      navigate(`/participants/cohorts/${row.extId}`)
    }
  }

  return (
    <>
      <ContentLoader status={status}>
        {status === 'loaded' && data?.length ? (
          <AdvancedTable
            isVirtualized
            virtualizedRowHeight={65}
            columns={columns}
            data={data}
            onRowClick={handleRowClick}
            sx={tableSx}
            getRowId={getRowId}
          />
        ) : (
          <Box display="flex" alignItems="center" maxW="800px" pt={8}>
            <Icon mr={4} as={RiErrorWarningLine} />
            <Text>
              <FormattedMessage id="cohorts.list.noCohorts" />
            </Text>
          </Box>
        )}
      </ContentLoader>
      <ActionButtonContainer>
        <Button
          leftIcon={<RiAddFill size="1.25em" />}
          iconSpacing="1"
          colorScheme="blue"
          onClick={onOpen}
        >
          <FormattedMessage id="cohorts.list.button.create" />
        </Button>
      </ActionButtonContainer>

      <CohortCreationModal
        isOpen={isOpen}
        onClose={onClose}
        isBenchmarking={isBenchmarking}
      />
    </>
  )
}
