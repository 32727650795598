import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { HiClipboardCopy } from 'react-icons/hi'
import { RiDeleteBinLine, RiMoreFill } from 'react-icons/ri'

import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { useClipboard } from '../../hooks/use-clipboard'
import { api } from '../../utils/api'
import { useRecordingToken } from '../recording-sessions-api'

type OptionsMenuProps = {
  handleDelete: () => Promise<void>
  recordingId: string
}

export const OptionsMenu = ({ handleDelete, recordingId }: OptionsMenuProps) => {
  const { copyTextToClipboard } = useClipboard()
  const { data, mutate } = useRecordingToken(recordingId)

  const getToken = async () => {
    if (data?.token) {
      return data?.token
    }

    try {
      const recording = await api.recordings.share(recordingId)

      await mutate()

      return recording.token
    } catch (error) {
      return undefined
    }
  }

  const copyShareLink = async () => {
    const shareToken = await getToken()
    const { host, protocol } = window.location
    const shareLink = `${protocol}//${host}/recordings/${recordingId}?token=${shareToken}`

    copyTextToClipboard(shareLink, 'recording.details.options.copiedLink.toast')
  }

  const copyShareLinkWithoutTargetProfile = async () => {
    const shareToken = await getToken()
    const { host, protocol } = window.location
    const shareLink = `${protocol}//${host}/share/recordings/${recordingId}?token=${shareToken}`

    copyTextToClipboard(shareLink, 'recording.details.options.copiedLink.toast')
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        variant="ghost"
        icon={<RiMoreFill />}
        aria-label="More"
      />
      <MenuList>
        <MenuItem onClick={copyShareLink}>
          <Icon as={HiClipboardCopy} mr={2} boxSize="1.25em" />
          <FormattedMessage id="recording.details.options.share" />
        </MenuItem>
        <MenuItem onClick={copyShareLinkWithoutTargetProfile}>
          <Icon as={HiClipboardCopy} mr={2} boxSize="1.25em" />
          <FormattedMessage id="recording.details.options.share.without.target.profile" />
        </MenuItem>
        <InterceptAndConfirm
          onConfirm={handleDelete}
          title={<FormattedMessage id="confirmation.deleteRecording.heading" />}
          description={<FormattedMessage id="confirmation.deleteRecording.text" />}
        >
          {({ openModal }) => (
            <MenuItem onClick={openModal} color="indicator.red-dark">
              <Icon as={RiDeleteBinLine} mr={2} boxSize="1.25em" />
              <FormattedMessage id="recording.details.options.delete" />
            </MenuItem>
          )}
        </InterceptAndConfirm>
      </MenuList>
    </Menu>
  )
}
