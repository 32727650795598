import { Tag } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { isNil } from '@repo/utils'

import { get5SegmentDescription, type SegmentEnum } from '../../hooks/use-result'

const TAG_VARIANT = {
  low: 'value-indicator-low',
  mediumLow: 'value-indicator-mediumLow',
  medium: 'value-indicator-medium',
  mediumHigh: 'value-indicator-mediumHigh',
  high: 'value-indicator-high',
} as const satisfies Record<SegmentEnum, string>

type ValueTagProps = {
  rawValue?: number
}

export const ValueTag = ({ rawValue }: ValueTagProps) => {
  if (isNil(rawValue)) {
    return <FormattedMessage id="recording.list.notAvailable" />
  }

  const segment = get5SegmentDescription(rawValue)

  return (
    <Tag variant={TAG_VARIANT[segment]}>
      <FormattedMessage id={`recording.list.valueTags.${segment}`} />
    </Tag>
  )
}
