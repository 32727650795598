import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  type LayoutProps,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { type ReactNode } from 'react'

import { colors } from '../theme/main'

type GeneralScoreGraphProps = {
  size: number
  label?: string
  value?: number | null
  valueSubtitle?: string | ReactNode
  color?: string
  maxWidth?: LayoutProps['maxWidth']
  noDataLabel?: string | ReactNode
}

const getColor = (score: number) => {
  if (score < 30) {
    return colors.progressBarRed[500]
  }

  if (score < 70) {
    return colors.progressBarYellow[500]
  }

  if (score < 90) {
    return colors.progressBarGreen[500]
  }

  return colors.progressBarCaribbeanGreen[500]
}

export const ScoreGraph = ({
  size,
  label,
  value,
  valueSubtitle,
  color,
  maxWidth,
  noDataLabel,
}: GeneralScoreGraphProps) => {
  const isSmall = size < 150
  const valueFontSize = isSmall ? '2xl' : '4xl'
  const labelFontSize = isSmall ? 'xs' : 'xl'
  const isDataAvailable = typeof value === 'number'
  const scoreColor = color || (isDataAvailable ? getColor(value) : undefined)

  return (
    <Box maxWidth={maxWidth}>
      <Box display="flex" justifyContent="center">
        <CircularProgress
          thickness="6px"
          value={isDataAvailable ? value : 0}
          capIsRound
          color={scoreColor}
          size={`${size}px`}
        >
          <CircularProgressLabel>
            {isDataAvailable ? (
              <>
                <HStack
                  display="flex"
                  justifyContent="center"
                  spacing="2px"
                  alignItems="baseline"
                >
                  <Text fontWeight="bold" fontSize={valueFontSize} lineHeight={1}>
                    {value}%
                  </Text>
                </HStack>
                {!!valueSubtitle && <Box fontSize="sm">{valueSubtitle}</Box>}
              </>
            ) : (
              <Box fontSize="sm">
                {noDataLabel ?? <FormattedMessage id="scoreGraph.noData" />}
              </Box>
            )}
          </CircularProgressLabel>
        </CircularProgress>
      </Box>
      <Tooltip openDelay={500} label={label}>
        <Text noOfLines={1} as="b" fontSize={labelFontSize} textAlign="center">
          {label}
        </Text>
      </Tooltip>
    </Box>
  )
}
