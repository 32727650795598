const types = {
  RECEIVE_PLUGIN: 'plugins/receive',
  CREATE_PLUGIN: 'plugins/create',
  UPDATE_PLUGIN: 'plugins/update',
}

// eslint-disable-next-line import/no-default-export
export default types

export const receivePlugin = plugin => ({
  type: types.RECEIVE_PLUGIN,
  data: plugin,
})

export const updatePlugin = (id, pluginData) => ({
  type: types.UPDATE_PLUGIN,
  data: {
    id,
    pluginData,
  },
})
