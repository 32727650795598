import { FormLabel, type FormLabelProps, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

export const CustomFormLabel = (props: FormLabelProps) => (
  <FormLabel
    fontSize={14}
    optionalIndicator={
      <Text as="span" textTransform="lowercase" fontWeight={300}>
        {' '}
        (<FormattedMessage id="common.optional" />)
      </Text>
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    requiredIndicator={<></>}
    {...props}
  />
)
