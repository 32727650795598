import { type Colors } from '@chakra-ui/react'

const secondaryLight = '#f2f7ff'
const secondary = '#e4efff' // blue.50
const secondaryDark = '#bad0f9' // blue.100
const primary = '#17459c' // blue.600

const primaryDark = '#0e3170' // blue.700

const colors = {
  CircularProgress: {
    trackColor: '#edebe9',
  },
  sessionTypeColors: {
    realtimeBuilder: '#FACBC0',
    aiSupported: '#BBE6FD',
    manual: '#BDEBE5',
  },
  graphColors1: {
    50: '#ddf7ff',
    100: '#b5e2fb',
    200: '#8bcdf1',
    300: '#60b9ea',
    400: '#36a5e3',
    500: '#38A6E3',
    600: '#0f6d9d',
    700: '#034e72',
    800: '#003047',
    900: '#00121d',
  },
  graphColors2: {
    50: '#dffdf2',
    100: '#bbf1dd',
    200: '#95e7c7',
    300: '#6dddb3',
    400: '#46d39d',
    500: '#3FD199',
    600: '#1e9066',
    700: '#126748',
    800: '#043f2b',
    900: '#00170c',
  },
  graphColors3: {
    50: '#defdfb',
    100: '#bbf2ee',
    200: '#95e7e1',
    300: '#6eded5',
    400: '#48d4c9',
    500: '#6FDED5',
    600: '#219188',
    700: '#136861',
    800: '#023f3a',
    900: '#001714',
  },
  graphColors4: {
    50: '#fdfcde',
    100: '#f6f2b7',
    200: '#f0e98c',
    300: '#eae160',
    400: '#e4d835',
    500: '#E6DB45',
    600: '#9d9412',
    700: '#706a09',
    800: '#433f02',
    900: '#171500',
  },
  blue: {
    50: secondary,
    100: secondaryDark,
    150: '#9DBDF9',
    200: '#8fb1f0',
    300: '#6391e8',
    350: '#2793f9',
    400: '#3972e0',
    450: '#2860CB',
    500: '#2159c7',
    600: primary,
    700: primaryDark,
    800: '#051e46',
    900: '#000a1c',
  },
  green: {
    50: '#D6F6DF',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#68D391',
    400: '#48BB78',
    500: '#38A169',
    600: '#2F855A',
    800: '#22543D',
  },
  yellow: {
    100: '#FFF9DF',
    200: '#FAF089',
    500: '#ECC94B',
    800: '#744210',
  },
  gray: {
    50: '#F5F5F5',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#757575',
    600: '#4a5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
  },
  orange: {
    100: '#FEEBCB',
    300: '#F6AD55',
    400: '#ED8936',
  },
  teal: {
    300: '#4FD1C5',
    800: '#234E52',
  },
  purple: {
    100: '#E9D8FD',
    400: '#9F7AEA',
    800: '#44337A',
  },

  pink: {
    100: '#FED7E2',
    800: '#702459',
  },

  lightGray: '#f0f0f0',
  'secondary-light': secondaryLight,
  secondary,
  'secondary-dark': secondaryDark,
  primary,
  'primary-dark': primaryDark,
  lightBlue: '#EDF2F7',
  buttonColor: '#ffffff',
  instructionButtonColor: '#d5d8dc',
  startButtonColor: '#35a3e2',
  startButtonHoverColor: '#359de2',
  recorderLanguageIconColor: '#21272f',
  transcriptBoxColor: '#787E88',
  transcriptCheckedBoxColor: '#5E7475',
  progressBarRed: {
    50: '#ffe5e5',
    100: '#fcb8b9',
    200: '#f48a8b',
    300: '#ee5d5d',
    400: '#e83130',
    500: '#F06E6E',
    600: '#a21111',
    700: '#750a0b',
    800: '#470405',
    900: '#1e0000',
  },
  progressBarYellow: {
    50: '#fff1dd',
    100: '#ffd7b0',
    200: '#ffbd80',
    300: '#fea24e',
    400: '#fe881e',
    500: '#FFB673',
    600: '#b25502',
    700: '#803d00',
    800: '#4e2400',
    900: '#1f0a00',
  },
  progressBarGreen: {
    50: '#e2fceb',
    100: '#bdf2cc',
    200: '#95e8ac',
    300: '#6cdf8d',
    400: '#44d66d',
    500: '#78E296',
    600: '#209340',
    700: '#14692d',
    800: '#083f1a',
    900: '#001703',
  },
  progressBarCaribbeanGreen: {
    50: '#d9fffd',
    100: '#adfff6',
    200: '#7dfff0',
    300: '#4dffea',
    400: '#26ffe3',
    500: '#00C8B0',
    600: '#00b39d',
    700: '#008070',
    800: '#004e43',
    900: '#001c16',
  },
  red: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#F56565',
    800: '#822727',
  },
  lineChartColors: {
    50: '#E5F4FA',
    100: '#AFE4F7',
    200: '#BDD7FF',
    300: '#33A7E6',
    400: '#00BDFF',
    500: '#00468E',
    600: '#2E9395',
  },
  indicator: {
    'red-dark': '#8E0000',
    red: '#F06E6E',
    'red-light': '#FBD4D4',
    'red-extra-light': '#FEF1F1',
    'green-dark': '#008726',
    green: '#78E296',
    'green-light': '#D6F6DF',
    'green-extra-light': '#F1FCF4',
    'yellow-dark': '#954800',
    yellow: '#FFB673',
    'yellow-light': '#FFE9D5',
    'yellow-extra-light': '#FFF8F1',
    'caribbean-green-dark': '#007668',
    'caribbean-green': '#00C8B0',
    'caribbean-green-light': '#B3EEE7',
    'caribbean-green-extra-light': '#E6F9F7',
    'orange-dark': '#8E3300',
    orange: '#F08D6E',
    'orange-light': '#FBE3DB',
    'orange-extra-light': '#FEF4F1',
    gray: '#EEEEEE',
  },
  appHeaderShadow: 'rgba(0, 0, 0, 0.349)',
} satisfies Colors

const graphColors = ['#38A6E3', '#3FD199', '#6FDED5', '#48BB78', '#E6DB45', '#ED8936']

export { colors, graphColors }
