import type { MDEditorProps } from '@uiw/react-md-editor'
import rehypeSanitize from 'rehype-sanitize'

export const markdownPreviewOptions: MDEditorProps['previewOptions'] = {
  rehypePlugins: [rehypeSanitize],
  skipHtml: true,
  linkTarget: '_blank',
  transformLinkUri: uri => {
    if (uri.startsWith('http')) {
      return uri
    }

    return `https://${uri}`
  },
}
