import { EmptyStateIndicator } from '@repo/ui'
import { Route, Routes } from 'react-router-dom'

import { useDashboardContext } from '../shared/dashboard-context'
import { CohortPerformanceCardDetails } from './dashboard-router/cohort-performance-card-details'
import { CommunicationAndLanguageCardDetails } from './dashboard-router/communication-and-language-card-details'
import { CompetencyTimeCardDetails } from './dashboard-router/competency-time-card-details'
import { DashboardErrorState } from './dashboard-router/dashboard-error-state'
import { DashboardOverview } from './dashboard-router/dashboard-overview'
import { KnowledgeCheckCardDetails } from './dashboard-router/knowledge-check-card-details'
import { LearningDevelopmentFunnelCardDetails } from './dashboard-router/learning-development-funnel-card-details'
import { PopularScenariosCardDetails } from './dashboard-router/popular-scenarios-card-details'
import { RecordingCountCardDetails } from './dashboard-router/recording-count-card-details'
import { TraineeCountCardDetails } from './dashboard-router/trainee-count-card-details'

export const DashboardRouter = () => {
  const { pageState, retryFetchingData } = useDashboardContext()

  if (pageState === 'error') {
    return <DashboardErrorState onRetry={retryFetchingData} />
  }

  if (pageState === 'no-cohorts') {
    return <EmptyStateIndicator messageKey="general.noDataAvailable" />
  }

  return (
    <Routes>
      <Route index element={<DashboardOverview />} />

      <Route
        path="cohort_performance_details"
        element={<CohortPerformanceCardDetails />}
      />

      <Route path="trainee_count_details" element={<TraineeCountCardDetails />} />

      <Route path="popular_scenario_details" element={<PopularScenariosCardDetails />} />

      <Route path="competency_time_details" element={<CompetencyTimeCardDetails />} />

      <Route path="video_count_details" element={<RecordingCountCardDetails />} />

      <Route
        path="learning_development_funnel"
        element={<LearningDevelopmentFunnelCardDetails />}
      />

      <Route
        path="communication_and_language_details"
        element={<CommunicationAndLanguageCardDetails />}
      />

      <Route path="knowledge_check_details" element={<KnowledgeCheckCardDetails />} />
    </Routes>
  )
}
