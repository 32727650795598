import { Box, CloseButton } from '@chakra-ui/react'
import { useMemo, useState } from 'react'

export const useNotification = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [settings, setSettings] = useState<any>({ content: null, type: null })
  const notify = (content, type) => setSettings({ content, type })
  const { content, type } = settings
  const notification = useMemo(
    () =>
      content ? (
        <div className={`notification is-${type}`}>
          <Box display="flex" float="right">
            <CloseButton onClick={() => setSettings({ content: null })} size="sm" />
          </Box>
          {content}
        </div>
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content]
  )

  return { notification, notify }
}
