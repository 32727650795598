import {
  Box,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { PersonaThumbnail, PillTag, type PillTagProps } from '@repo/ui'
import { colors } from '@repo/ui'
import { MdArrowBack } from 'react-icons/md'

import { useGoogleStorageAsset } from '../../../../hooks/use-google-storage-asset'
import { KeyValueWithButton } from '../../key-value-with-button'
import { KeyValueWithInput } from '../../key-value-with-input'
import { type Persona } from '../types'
import { ageRangeValues } from '../use-persona-form-modal/shared/age-range-values'

interface PersonaPreviewModalProps {
  onClose: () => void
  isOpen: boolean
  persona: Persona
  tag?: PillTagProps
}

export const PersonaPreviewModal = ({
  onClose,
  persona,
  isOpen,
  tag,
}: PersonaPreviewModalProps) => {
  const { formatMessage } = useIntl()

  const {
    name,
    age,
    sex,
    conversationContext,
    jobRole,
    industry,
    backgroundUrl,
    videoGenerationAvatar,
    attitude,
    personality,
    personalValues,
    needsAndPains,
    goals,
    manners,
    videoGenerationVoice,
  } = persona

  return (
    <Modal
      scrollBehavior="inside"
      size="4xl"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent overflow="hidden">
        <IconButton
          onClick={onClose}
          aria-label="close"
          position="absolute"
          top={4}
          left={4}
          zIndex={1}
          isRound
          fontSize="22px"
          icon={<MdArrowBack />}
        />

        <Box h="216px" minH="216px">
          <PersonaThumbnail
            avatarPreviewUrl={videoGenerationAvatar?.imageUrl}
            bgUrl={backgroundUrl}
            useGoogleStorageAsset={useGoogleStorageAsset}
          />
        </Box>

        <ModalBody px="60px" py="40px">
          <VStack spacing={2} alignItems="flex-start">
            {tag && (
              <PillTag
                icon={tag.icon}
                text={tag.text}
                sx={{ bgColor: colors.blue[700], color: 'white' }}
              />
            )}
            <Text fontSize="24px" mb={10} fontWeight={500}>
              {name}
            </Text>
          </VStack>
          <SimpleGrid columns={2} spacing={10}>
            <VStack spacing={4} alignItems="flex-start">
              <Text fontSize="18px" color={colors.blue[500]} fontWeight={500}>
                <FormattedMessage id="scenario.builder.ai.steps.conversation-partner.personaModal.demographic" />
              </Text>
              <KeyValueWithInput labelKey="common.name" value={name} />
              {age && (
                <KeyValueWithInput labelKey="common.age" value={ageRangeValues[age]} />
              )}
              <KeyValueWithInput
                labelKey="common.sex"
                value={formatMessage({
                  id: `persona.sex.${sex}` satisfies I18nKey,
                })}
              />

              <Text fontSize="18px" color={colors.blue[500]} mt={10} fontWeight={500}>
                <FormattedMessage id="scenario.builder.ai.steps.conversation-partner.personaModal.character" />
              </Text>

              <KeyValueWithButton
                labelKey="common.attitude"
                value={formatMessage({
                  id: `persona.attitude.${attitude}` satisfies I18nKey,
                })}
              />
              <KeyValueWithInput labelKey="common.personality" value={personality} />
              <KeyValueWithInput
                labelKey="scenario.builder.ai.steps.conversation-partner.formLabel.personalValues"
                value={personalValues}
              />
            </VStack>

            <VStack spacing={4} alignItems="flex-start">
              <Text fontSize="18px" color={colors.blue[500]} fontWeight={500}>
                <FormattedMessage id="scenario.builder.ai.steps.conversation-partner.personaModal.professional" />
              </Text>
              <KeyValueWithInput
                labelKey="scenario.builder.ai.steps.conversation-partner.formLabel.conversationContext"
                value={formatMessage({
                  id: `persona.conversationContext.${conversationContext}` satisfies I18nKey,
                })}
              />
              {jobRole && (
                <KeyValueWithInput
                  labelKey="scenario.builder.ai.steps.trainee.formLabel.jobRole"
                  value={jobRole}
                />
              )}
              {industry && (
                <KeyValueWithInput labelKey="common.industry" value={industry} />
              )}
              <Text fontSize="18px" color={colors.blue[500]} mt={10} fontWeight={500}>
                <FormattedMessage id="scenario.builder.ai.steps.conversation-partner.personaModal.look" />
              </Text>
              {videoGenerationAvatar?.name && (
                <KeyValueWithInput
                  labelKey="common.avatar"
                  value={videoGenerationAvatar?.name}
                />
              )}
              {videoGenerationVoice?.labelKey && (
                <KeyValueWithInput
                  labelKey="common.voice"
                  value={
                    videoGenerationVoice?.labelKey.startsWith('persona.voice')
                      ? formatMessage({
                          id: videoGenerationVoice.labelKey as I18nKey,
                        })
                      : videoGenerationVoice?.labelKey
                  }
                />
              )}
            </VStack>
          </SimpleGrid>
          <VStack spacing={4} alignItems="flex-start">
            <Text fontSize="18px" mt={10} color={colors.blue[500]} fontWeight={500}>
              <FormattedMessage id="scenario.builder.ai.steps.conversation-partner.personaModal.insights" />
            </Text>
            {needsAndPains && (
              <KeyValueWithInput
                labelKey="scenario.builder.ai.steps.conversation-partner.formLabel.needsAndPains"
                value={needsAndPains}
              />
            )}
            {goals && (
              <KeyValueWithInput
                labelKey="scenario.builder.ai.steps.conversation-partner.formLabel.goals"
                value={goals}
              />
            )}
            {manners && (
              <KeyValueWithInput
                labelKey="scenario.builder.ai.steps.conversation-partner.formLabel.expectedBehaviors"
                value={manners}
              />
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
