import { HStack, type StackProps } from '@chakra-ui/react'

export const ButtonContainer = ({ children, ...props }: StackProps) => (
  <HStack
    position="absolute"
    bottom="30px"
    right="50px"
    spacing={4}
    justify="right"
    {...props}
  >
    {children}
  </HStack>
)
