import {
  Badge as ChakraBadge,
  type BadgeProps,
  type ThemeTypings,
} from '@chakra-ui/react'
import { type ReactNode } from 'react'

export const Badge = ({
  label,
  colorScheme,
  ...props
}: {
  label: ReactNode
  colorScheme: ThemeTypings['colorSchemes']
  props?: BadgeProps
}) => (
  <ChakraBadge
    w="100%"
    // Before using make sure the text is not too long
    maxW="200px"
    textAlign="center"
    colorScheme={colorScheme}
    {...props}
  >
    {label}
  </ChakraBadge>
)
