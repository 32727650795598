import { Center, type CenterProps, Icon, Image, type ImageProps } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { MdImage } from 'react-icons/md'

type ImageWithFallbackProps = Omit<ImageProps, 'w' | 'h'> & {
  height: NonNullable<ImageProps['height']>
  fallbackProps?: CenterProps
}

export const ImageWithFallback = ({ height, width, ...rest }: ImageWithFallbackProps) => (
  <Image
    height={height}
    width={width}
    bg={colors.blue[50]}
    {...rest}
    fallback={
      <Center minHeight={height} width={width} bg={colors.blue[50]}>
        <Icon fontSize={32} as={MdImage} color={colors.gray[400]} />
      </Center>
    }
  />
)
