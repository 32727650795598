import { WarningIcon } from '@chakra-ui/icons'
import { Button, Center, HStack, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, useModal } from '@repo/ui'
import { Link } from 'react-router-dom'

import { type ExtendedProgram } from '../../../types/api-types'
import { type ErrorMessage } from './types'

export const usePublishingFailedModal = (
  errors: Array<ErrorMessage>,
  program: ExtendedProgram
) =>
  useModal(
    () => (
      <Stack gap={6}>
        <Text color={colors.gray[600]}>
          <FormattedMessage
            id="program.publishErrorModal.description"
            values={{
              x: program.title,
            }}
          />
        </Text>

        <Stack gap={4}>
          {Array.isArray(errors) &&
            errors.map((error, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <HStack gap={3} key={index} alignItems="flex-start">
                <WarningIcon mt={1} fontSize={20} color={colors.red[500]} />
                <Text fontSize={14}>
                  <FormattedMessage
                    id={error.id as I18nKey}
                    values={{ ...error.values, situationIdx: 3 }}
                  />
                </Text>
              </HStack>
            ))}
        </Stack>

        <Text color={colors.gray[600]}>
          <FormattedMessage id="program.publishErrorModal.footnote" />
        </Text>

        <Center>
          <Button color="white !important" as={Link} to={`${program.extId}`}>
            <FormattedMessage id="program.publishErrorModal.button" />
          </Button>
        </Center>
      </Stack>
    ),
    {
      titleKey: 'program.publishErrorModal.title',
      modalProps: { size: '2xl' },
    }
  )
