import { type useIntl } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { get } from 'lodash-es'
import * as yup from 'yup'

export const onSavingError = (error: unknown) => {
  const errorCode = get(error, ['errors', 'dashboard'], null)
  const errorMessageId =
    errorCode === 'layout-update' ? 'positions.errors.layout' : 'positions.errors.saving'

  showToast({
    messageKey: errorMessageId,
    status: 'error',
  })
}

export const getValidationSchema = ({ formatMessage }: ReturnType<typeof useIntl>) =>
  yup.object().shape({
    name: yup
      .string()
      .required(formatMessage({ id: 'positions.form.error.title.required' })),
    benchmarkProfileId: yup.string().required(),
  })
