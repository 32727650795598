import types from './private/actions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: Array<any> = []

// eslint-disable-next-line import/no-default-export
export default (state = initialState, { type, data }) => {
  switch (type) {
    case types.CREATE_PLUGIN:
      return state.concat(data)

    case types.RECEIVE_PLUGIN: {
      const pluginIndex = state.findIndex(plugin => plugin.id === data.id)

      if (pluginIndex > -1) {
        return state.map(plugin => (plugin.id === data.id ? data : plugin))
      }

      return state.concat(data)
    }

    case types.UPDATE_PLUGIN:
      return state.map(plugin =>
        plugin.id === data.id ? { ...plugin, ...data.pluginData } : plugin
      )

    default:
      return state
  }
}
