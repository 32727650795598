import { Checkbox } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'

import { MenuItemDiv } from '../MenuItemDiv'

type CheckboxSelectItemProps = {
  value: string
}

export const CheckboxSelectItem = ({
  children,
  value,
}: PropsWithChildren<CheckboxSelectItemProps>) => (
  <MenuItemDiv>
    <Checkbox value={value}>{children}</Checkbox>
  </MenuItemDiv>
)
