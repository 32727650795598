import { pick } from 'lodash-es'

import { api } from '../../../utils/api'
import store from '../../store'
import { FolderStoreStatus } from './folders-types'
import * as actions from './private/actions'
import { makeSelectFolder } from './selectors'

const fetchFolders = () => async dispatch => {
  const folders = await api.folders.getAll()

  dispatch(actions.receiveFolders(folders))
}

export const initializeFolders = () => async dispatch => {
  const state = store.getState()

  if (state.entities.folders.status !== FolderStoreStatus.IDLE) {
    return
  }

  dispatch(actions.initializeFolders())
  await dispatch(fetchFolders())
}

export const deleteFolder = id => async dispatch => {
  try {
    dispatch(actions.deleteFolderRequest(id))
    await api.folders.delete(id)
    await dispatch(fetchFolders())
    dispatch(actions.deleteFolderSuccess(id))
  } catch (error) {
    console.error(error)
    throw new Error(`Error! status: ${error}`)
  }
}

const getFormValues = formData => pick(formData, ['benchmarkProfileId', 'name', 'path'])

export const editFolder = (id, formData) => async dispatch => {
  try {
    const folderData = getFormValues(formData)

    dispatch(actions.updateFolderRequest(id))
    await api.folders.update(id, folderData)
    await dispatch(fetchFolders())
    dispatch(actions.updateFolderSuccess(id))
  } catch (error) {
    console.error(error)
    throw new Error(`Error! status: ${error}`)
  }
}

const selectFolder = makeSelectFolder()

export const createFolder = (formData, parent) => async (dispatch, getState) => {
  const state = getState()

  try {
    const payload = parent
      ? { ...formData, path: selectFolder(state, parent).path }
      : formData

    const folderData = getFormValues(payload)

    dispatch(actions.createFolderRequest())
    const folder = await api.folders.create(folderData)

    await dispatch(fetchFolders())
    dispatch(actions.createFolderSuccess(folder))

    return folder
  } catch (error) {
    console.error(error)
  }

  return null
}
