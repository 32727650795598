import { type ApiSchemas } from '../openapi'

export type ProgramLanguages = ApiSchemas['languageEnum']

export const AVAILABLE_PROGRAM_LANGUAGES: Array<ProgramLanguages> = [
  'en-US',
  'de-DE',
  'es-ES',
  'fr-FR',
  'it-IT',
  'pt-PT',
]

export const BETA_PROGRAM_LANGUAGES: Array<ProgramLanguages> = [
  'es-ES',
  'fr-FR',
  'it-IT',
  'pt-PT',
]
