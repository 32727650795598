import { Stack, Text } from '@chakra-ui/react'
import { type ApiSchemas } from '@repo/api'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, RHF, SectionHeader, showToast, useFormRules } from '@repo/ui'
import { type AutocompleteOptions } from '@repo/ui/src/react-hook-forms/rhf/form-field-autocomplete-select'
import { useCallback } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { CenteredSpinner } from '../../../components/centered-spinner'
import { useLocalStorage } from '../../../hooks/use-local-storage'
import { client } from '../../../utils/openapi-client'
import { useOpenapiSWR } from '../../../utils/use-openapi-swr'
import { StickyFooter } from '../../shared/sticky-footer'
import { useNextStepModal } from '../../shared/use-next-step-modal'
import { useAIBuilderContext } from '../shared/ai-builder-context'

type FormValues = {
  furtherDetails?: string
  winningBehaviorExtId: string
  customWinningBehavior: ApiSchemas['winningBehaviorsCustomEntity']
}

const emptyCustomWinningBehavior: ApiSchemas['winningBehaviorsCustomEntity'] = {
  title: '',
  goodExamples: '',
  badExamples: '',
  description: '',
}

type WinningBehaviorTactic =
  | 'issue_development'
  | 'developing_creative_solutions'
  | 'search_for_an_acceptable_agreement'
  | 'present_the_situation_from_a_new_perspective'
  | 'principle_of_scarcity'
  | 'principle_of_authority'
  | 'principle_of_social_validation'
  | 'request_information'
  | 'offer_relief'
  | 'restate_the_objection'
  | 'acknowledge_the_objection'
  | 'provide_evidence'
  | 'adaptive_selling'

type WinningBehaviorSkill =
  | 'negotiation'
  | 'persuasion'
  | 'empathy'
  | 'objection_handling'
  | 'selling_behavior'

const CUSTOM_TEMPLATE_ID = 'custom'

export const GoalStep = () => {
  const {
    programExtId,
    trainingExtId,
    scenarioExtId,
    scenario,
    mutateScenario,
    goToStep,
    formatInProgramLanguage,
    isBuilderReadonly,
  } = useAIBuilderContext()

  const { data: winningBehaviorTemplates, isValidating } = useOpenapiSWR(
    'getWinningBehaviorTemplates'
  )

  const isInitializingWinningBehaviorTemplates = !winningBehaviorTemplates && isValidating

  const { control, handleSubmit, formState, watch, setValue } = useForm<FormValues>({
    mode: 'onTouched',
    disabled: isBuilderReadonly,
    defaultValues: async () => {
      if (!scenario.winningBehavior) {
        return {
          winningBehaviorExtId: '',
          furtherDetails: '',
          customWinningBehavior: emptyCustomWinningBehavior,
        }
      }

      const { winningBehavior, furtherDetails } = scenario

      return {
        furtherDetails: furtherDetails ?? '',
        winningBehaviorExtId: winningBehavior.isTemplate
          ? scenario.winningBehavior.extId
          : CUSTOM_TEMPLATE_ID,
        customWinningBehavior:
          scenario.winningBehavior.winningBehaviorsCustom ?? emptyCustomWinningBehavior,
      }
    },
  })

  const rules = useFormRules()

  const { formatMessage } = useIntl()

  const [_, setHasSeenBuilderIntro] = useLocalStorage('hasSeenBuilderIntro')

  const [nextStepModal, confirmProceedingViaModal] = useNextStepModal({
    titleKey: 'common.wellDone',
    descriptionKey: 'scenario.builder.ai.steps.goal.nextStepModal.description',
    continueLabelKey: 'common.saveAndProceed',
  })

  const goNextStep = useCallback(() => {
    goToStep('conversation-partner')
  }, [goToStep])

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async ({ customWinningBehavior, winningBehaviorExtId, furtherDetails }) => {
      const isConfirmed = await confirmProceedingViaModal()

      if (!isConfirmed) {
        return
      }

      if (!formState.isDirty) {
        return goNextStep()
      }

      const isCustom = winningBehaviorExtId === CUSTOM_TEMPLATE_ID

      const res = await client.put('updateScenarioAtGoalStep', {
        body: {
          furtherDetails,
          ...(isCustom
            ? { winningBehaviorType: 'custom', ...customWinningBehavior }
            : { winningBehaviorType: 'template', winningBehaviorExtId }),
        },
        params: {
          path: {
            programExtId,
            trainingExtId,
            extId: scenarioExtId,
          },
        },
      })

      if (res.error) {
        return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }

      mutateScenario(res.data, false)
      setHasSeenBuilderIntro(true)
      goNextStep()
    },
    [
      confirmProceedingViaModal,
      formState.isDirty,
      goNextStep,
      mutateScenario,
      programExtId,
      scenarioExtId,
      trainingExtId,
      setHasSeenBuilderIntro,
    ]
  )

  const winningBehaviorExtId = watch('winningBehaviorExtId')
  const isTemplateNotCustom = winningBehaviorExtId !== CUSTOM_TEMPLATE_ID

  const handleCustomWbFieldChange = () => {
    if (isTemplateNotCustom) {
      setValue('winningBehaviorExtId', CUSTOM_TEMPLATE_ID, { shouldValidate: true })
    }
  }

  const isSelectedTemplate = !!winningBehaviorExtId && isTemplateNotCustom

  if (isInitializingWinningBehaviorTemplates) {
    return <CenteredSpinner />
  }

  const wbOptions: AutocompleteOptions = [
    ...(winningBehaviorTemplates ?? []).map(wb => {
      const { skill, tactic } = wb.winningBehaviorsTemplates ?? {}
      const translatedKey = `${formatInProgramLanguage(
        `winning_behavior.skill.${skill as WinningBehaviorSkill}`
      )} (${formatInProgramLanguage(
        `winning_behavior.tactic.${tactic as WinningBehaviorTactic}`
      )})`

      return {
        label: translatedKey,
        value: wb.extId,
      }
    }),
    { label: formatInProgramLanguage('common.custom'), value: CUSTOM_TEMPLATE_ID },
  ]

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} flex={1} gap={0}>
      {nextStepModal}

      <SectionHeader titleKey="scenario.builder.ai.steps.goal.formTitle" sx={{ mb: 8 }} />

      <Stack maxW="800px" gap={16}>
        <RHF.AutocompleteSelect
          control={control}
          name="winningBehaviorExtId"
          labelKey="scenario.builder.ai.steps.goal.wbSelect.label"
          tooltipKey="scenario.builder.ai.steps.goal.wbSelect.tooltip"
          rules={rules.required}
          componentProps={{
            placeholderKey: 'scenario.builder.ai.steps.goal.wbSelect.placeholder',
            options: wbOptions,
          }}
        />

        {!isSelectedTemplate && (
          <Stack gap={10}>
            <RHF.Input
              control={control}
              name="customWinningBehavior.title"
              rules={rules.stringShortRequired}
              labelKey="scenario.builder.ai.steps.goal.wbTitle.label"
              componentProps={{
                placeholder: formatMessage({
                  id: 'scenario.builder.ai.steps.goal.wbTitle.placeholder',
                }),
                onChange: handleCustomWbFieldChange,
              }}
            />
            <RHF.TextArea
              control={control}
              name="customWinningBehavior.description"
              rules={rules.string10kRequired}
              labelKey="scenario.builder.ai.steps.goal.wbDescription.label"
              componentProps={{
                isDisabled: isSelectedTemplate,
                placeholder: formatMessage({
                  id: 'scenario.builder.ai.steps.goal.wbDescription.placeholder',
                }),
                onChange: handleCustomWbFieldChange,
              }}
            />
            <RHF.TextArea
              control={control}
              name="customWinningBehavior.goodExamples"
              rules={rules.string10kRequired}
              labelKey="scenario.builder.ai.steps.goal.wbGoodExamples.label"
              componentProps={{
                placeholder: formatMessage({
                  id: 'scenario.builder.ai.steps.goal.wbGoodExamples.placeholder',
                }),
                onChange: handleCustomWbFieldChange,
              }}
            />
            <RHF.TextArea
              control={control}
              rules={rules.string10k}
              name="customWinningBehavior.badExamples"
              labelKey="scenario.builder.ai.steps.goal.wbBadExamples.label"
              componentProps={{
                placeholder: formatMessage({
                  id: 'scenario.builder.ai.steps.goal.wbBadExamples.placeholder',
                }),
                onChange: handleCustomWbFieldChange,
              }}
            />
          </Stack>
        )}

        <Stack gap={6}>
          <Text lineHeight={1.2} fontSize={18} fontWeight={500} color={colors.blue[500]}>
            <FormattedMessage id="scenario.builder.ai.steps.goal.furtherDetails.title" />
          </Text>
          <RHF.TextArea
            control={control}
            name="furtherDetails"
            labelKey="scenario.builder.ai.steps.goal.furtherDetails.label"
            tooltipKey="scenario.builder.ai.steps.goal.furtherDetails.tooltip"
            rules={rules.stringCustomLength(50000)}
            componentProps={{
              placeholder: formatMessage({
                id: 'scenario.builder.ai.steps.goal.furtherDetails.placeholder',
              }),
            }}
          />
        </Stack>
      </Stack>

      <StickyFooter
        onNext={isBuilderReadonly ? goNextStep : undefined}
        isNextDisabled={!isBuilderReadonly && !formState.isValid}
        isNextLoading={formState.isSubmitting}
      />
    </Stack>
  )
}
