/* eslint-disable no-alert */
import { Center } from '@chakra-ui/react'
import { type ComponentProps, useState } from 'react'

import { CardGrid } from '../card-grid'
import { ComponentSection } from './shared/component-section'
import { mockFirebaseImage } from './shared/mock-firebase-image'

const onClick = () => alert('clicked')

const defaultGridCardProps: ComponentProps<(typeof CardGrid)['Card']> = {
  title: 'Title',
  children: 'Content of the card comes here',
  image: mockFirebaseImage,
  onClick,
}

export const CardGridSection = () => {
  const [isSelected, setIsSelected] = useState(false)

  return (
    <ComponentSection
      title="CardGrid"
      subtitle="This component comes with both the grid wrapper, and the possible cards you can put into the grid. Image has 16:9 aspect ratio."
    >
      <CardGrid>
        <CardGrid.CreateCard labelKey="common.create" onClick={onClick} />
        <CardGrid.Card {...defaultGridCardProps} title="Default" />
        <CardGrid.Card
          {...defaultGridCardProps}
          title="Without image"
          image={undefined}
        />
        <CardGrid.SkeletonCard />
        <CardGrid.Card
          {...defaultGridCardProps}
          title="Without onClick"
          onClick={undefined}
        />
        <CardGrid.Card
          {...defaultGridCardProps}
          title="With tag"
          tag={{ text: 'Amazing' }}
        />
        <CardGrid.Card
          {...defaultGridCardProps}
          title="Footer variant = selectable"
          onClick={undefined}
          footer={{
            type: 'selectable',
            isSelected,
            onSelect: () => setIsSelected(!isSelected),
          }}
        />
        <CardGrid.Card
          {...defaultGridCardProps}
          title="Footer variant = cta"
          onClick={undefined}
          footer={{ type: 'cta', labelKey: 'common.open', onClick }}
        />
        <CardGrid.Card
          {...defaultGridCardProps}
          title="Footer variant = custom"
          footer={{
            type: 'custom',
            content: (
              <Center border="3px solid red" w="full">
                Custom footer
              </Center>
            ),
          }}
        />
        <CardGrid.Card
          {...defaultGridCardProps}
          title="Footer variant = sortable"
          footer={{ type: 'sortable', sortableId: 0 }}
        />
        <CardGrid.Card
          {...defaultGridCardProps}
          title="With menuItems"
          menuItems={[
            { labelKey: 'common.open', onClick },
            { labelKey: 'common.cancel', onClick, isDestructive: true },
          ]}
        />
        <CardGrid.Card
          {...defaultGridCardProps}
          title="Custom style"
          sx={{ border: '3px solid tomato', borderRadius: 24, overflow: 'hidden' }}
        />
      </CardGrid>
    </ComponentSection>
  )
}
