import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { type SubmitHandler } from 'react-hook-form'

import { type Persona, type PersonaFormValues, type PersonaStepKey } from '../types'
import { orderedPersonaSteps } from './shared/ordered-persona-steps'

const isFormDataComplete = (
  data: Partial<PersonaFormValues>
): data is PersonaFormValues => [...orderedPersonaSteps].every(s => !!data[s])

type PersonaFormContextType = {
  formData: Partial<PersonaFormValues>
  setFormData: Dispatch<SetStateAction<Partial<PersonaFormValues>>>
  currentStep: PersonaStepKey
  setCurrentStep: Dispatch<SetStateAction<keyof PersonaFormValues>>
  getSubmitHandler: <T extends PersonaStepKey>(
    stepKey: T
  ) => SubmitHandler<PersonaFormValues[T]>
  mode: 'create' | 'edit'
}

const PersonaFormContext = createContext<PersonaFormContextType | null>(null)

export type PersonaFormContextProviderProps = PropsWithChildren<{
  persona?: Persona
  onSubmit: (data: PersonaFormValues) => Promise<void>
}>

export const PersonaFormContextProvider = ({
  children,
  persona,
  onSubmit,
}: PersonaFormContextProviderProps) => {
  const [currentStep, setCurrentStep] = useState<PersonaStepKey>('demographic')
  const [formData, setFormData] = useState<Partial<PersonaFormValues>>(
    persona
      ? {
          demographic: { name: persona.name, age: persona.age, sex: persona.sex },
          professional: {
            conversationContext: persona.conversationContext,
            jobRole: persona.jobRole,
            industry: persona.industry,
          },
          character: {
            attitude: persona.attitude,
            personality: persona.personality,
            personalValues: persona.personalValues,
          },
          look: {
            videoGenerationAvatarExtId: persona.videoGenerationAvatar?.extId ?? '',
            videoGenerationVoiceExtId: persona.videoGenerationVoice?.extId ?? '',
            backgroundUrl: persona.backgroundUrl,
          },
          insights: {
            needsAndPains: persona.needsAndPains,
            goals: persona.goals,
            manners: persona.manners,
          },
        }
      : {}
  )

  const getSubmitHandler = useCallback<PersonaFormContextType['getSubmitHandler']>(
    step => data => {
      const isLastStep = step === orderedPersonaSteps[orderedPersonaSteps.length - 1]

      if (isLastStep) {
        const finalFormData = { ...formData, [step]: data }

        if (!isFormDataComplete(finalFormData)) {
          // this is a sanity check, and should never happen
          // if it does, it's a bug
          throw new Error('formData is not complete for submitting')
        }

        return onSubmit(finalFormData)
      }

      setFormData(prev => ({ ...prev, [step]: data }))
      setCurrentStep(orderedPersonaSteps[orderedPersonaSteps.indexOf(step) + 1]!)
    },
    [formData, onSubmit]
  )

  const mode = useMemo(() => (persona ? 'edit' : 'create'), [persona])

  const value = useMemo<PersonaFormContextType>(
    () => ({
      formData,
      setFormData,
      currentStep,
      setCurrentStep,
      getSubmitHandler,
      mode,
    }),
    [formData, currentStep, getSubmitHandler, mode]
  )

  return (
    <PersonaFormContext.Provider value={value}>{children}</PersonaFormContext.Provider>
  )
}

export const usePersonaFormContext = () => {
  const context = useContext(PersonaFormContext)

  if (!context) {
    throw new Error(
      'usePersonaFormContext must be used within a PersonaFormContextProvider'
    )
  }

  return context
}
