import { HStack } from '@chakra-ui/react'
import { uniq, uniqBy } from 'lodash-es'
import { type Dispatch, type SetStateAction, useEffect, useMemo } from 'react'

import { type CoachingRecordingSession } from '../types/coaching-recording'
import { type ProgramTrainingScenariosTree } from '../types/program-training-scenario-tree'
import { SessionCohortFilter } from './session-cohort-filter'
import {
  type LanguageFilterValue,
  SessionLanguageFilter,
} from './session-language-filter'
import { SessionScenarioFilter } from './session-scenario-filter'

export type RecordingsTabFiltersType = {
  cohortExtIds: Array<string>
  scenarioExtIds: Array<string>
  language: LanguageFilterValue
}
type RecordingsTabFiltersProps = {
  sessions: Array<CoachingRecordingSession>
  selectedSessions: Array<string>
  setSelectedSessions: Dispatch<SetStateAction<Array<string>>>
  setFilteredData: Dispatch<SetStateAction<Array<CoachingRecordingSession>>>
  programTrainingScenarioTree: ProgramTrainingScenariosTree
  filters: RecordingsTabFiltersType
  setFilters: Dispatch<SetStateAction<RecordingsTabFiltersType>>
}

export const RecordingsTabFilters = ({
  sessions,
  selectedSessions,
  setSelectedSessions,
  programTrainingScenarioTree,
  filters,
  setFilters,
  setFilteredData,
}: RecordingsTabFiltersProps) => {
  // Cohort used for cohorts filter
  const cohorts = useMemo(
    () =>
      uniqBy(
        sessions.map(session => session.cohort),
        'extId'
      ),
    [sessions]
  )

  // initialize filters so include all scenarios and cohorts
  useEffect(() => {
    if (sessions) {
      const allCohortExtIds = cohorts.map(cohort => cohort.extId)
      const allScenarioExtIds = uniq(sessions.map(session => session.scenario.extId))

      setFilters({
        cohortExtIds: allCohortExtIds,
        scenarioExtIds: allScenarioExtIds,
        language: null,
      })
    }
  }, [sessions, cohorts, setFilters])

  // Update filtered data when filters change
  useEffect(() => {
    const filteredSessions = sessions.filter(
      session =>
        filters.cohortExtIds.includes(session.cohort.extId) &&
        filters.scenarioExtIds.includes(session.scenario.extId) &&
        (filters.language ? filters.language === session.program.language : true)
    )

    setFilteredData(filteredSessions)

    // Remove sessions from selection that have become filtered out
    const selectedSessionsNotFilteredOut = selectedSessions.filter(sessionExtId =>
      filteredSessions.some(filteredSession => filteredSession.extId === sessionExtId)
    )

    if (selectedSessionsNotFilteredOut.length !== selectedSessions.length) {
      setSelectedSessions(selectedSessionsNotFilteredOut)
    }
  }, [sessions, filters, selectedSessions, setFilteredData, setSelectedSessions])

  return (
    <HStack mb={4}>
      <SessionScenarioFilter
        sessions={sessions}
        selectedSessions={selectedSessions}
        programTrainingScenarioTree={programTrainingScenarioTree}
        scenariosFilter={filters.scenarioExtIds}
        setScenariosFilter={scenarioExtIds => setFilters({ ...filters, scenarioExtIds })}
      />
      <SessionCohortFilter
        sessions={sessions}
        selectedSessions={selectedSessions}
        cohorts={cohorts}
        cohortsFilter={filters.cohortExtIds}
        setCohortsFilter={cohortExtIds => setFilters({ ...filters, cohortExtIds })}
      />
      <SessionLanguageFilter
        sessions={sessions}
        selectedSessions={selectedSessions}
        resetSessionSelection={() => setSelectedSessions([])}
        languageFilter={filters.language}
        setLanguageFilter={language => setFilters({ ...filters, language })}
      />
    </HStack>
  )
}
