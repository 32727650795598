import { Box, Button, Center, HStack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { MdArrowBack, MdInfo } from 'react-icons/md'

type StickyFooterProps = {
  onBack?: () => void
  nextLabelKey?: I18nKey
  onNext?: () => void
  isNextDisabled?: boolean
  isNextLoading?: boolean
  nextHintKey?: I18nKey | false
}

export const StickyFooter = ({
  onBack,
  nextLabelKey,
  onNext,
  isNextDisabled,
  isNextLoading,
  nextHintKey,
}: StickyFooterProps) => (
  <Box
    pos="sticky"
    bottom={0}
    mt="auto"
    mx="calc(-1 * 50vw)"
    zIndex="sticky"
    pt={10}
    /**
     * we need to make disable pointer events for this div, and enable it for it's children, so things visible slightly above the footer don't get blocked by the invisible padding area
     * if this sounds confusing, add a border to this component and see how it looks like
     */
    pointerEvents="none"
  >
    <Center
      px="50vw"
      w="full"
      height="80px"
      bg="white"
      boxShadow="0px -2px 11px 0px #00000040"
      pointerEvents="auto"
    >
      {!!onBack && (
        <Button
          px={6}
          size="md"
          variant="outline"
          leftIcon={<MdArrowBack />}
          onClick={onBack}
        >
          <FormattedMessage id="common.back" />
        </Button>
      )}

      <Box flex="1" />

      <HStack gap={10}>
        {!!nextHintKey && (
          <HStack>
            <MdInfo color={colors.blue[500]} fontSize={24} />
            <Text color={colors.gray[600]}>
              <FormattedMessage id={nextHintKey} />
            </Text>
          </HStack>
        )}
        <Button
          px={6}
          size="md"
          type={onNext ? 'button' : 'submit'}
          onClick={onNext}
          isLoading={isNextLoading}
          isDisabled={isNextDisabled}
          gap={10}
        >
          <FormattedMessage id={nextLabelKey || 'common.continue'} />
        </Button>
      </HStack>
    </Center>
  </Box>
)
