import { Stack } from '@chakra-ui/react'
import { SectionHeader, showToast } from '@repo/ui'
import { assertExists } from '@repo/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardGrid } from '../../components/card-grid'
import { CenteredSpinner } from '../../components/centered-spinner'
import { client } from '../../utils/openapi-client'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'
import { PersonaCard } from './conversation-partner-step/persona-card'
import {
  type PersonaFormModalSubmitHandler,
  usePersonaFormModal,
} from './conversation-partner-step/use-persona-form-modal'
import { StickyFooter } from './sticky-footer'
import { useNextStepModal } from './use-next-step-modal'
import { useScenarioBuilderData } from './use-scenario-builder-data'

export const ConversationPartnerStep = ({
  isBuilderReadonly,
}: {
  isBuilderReadonly: boolean
}) => {
  const [
    { programExtId, scenarioExtId, trainingExtId },
    ,
    ,
    { data: scenario, mutate: mutateScenario },
  ] = useScenarioBuilderData()

  assertExists(scenario)

  const {
    data: fetchedPersonas,
    isLoading: isLoadingPersonas,
    mutate: mutatePersonas,
  } = useOpenapiSWR('getPersonas', {
    params: { extId: scenarioExtId, programExtId, trainingExtId },
  })

  const [selectedPersonaExtId, setSelectedPersonaExtId] = useState(
    scenario.persona?.extId ?? ''
  )

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    // if selectedPersonaExtId is not present in fetchedPersonas, reset it
    if (
      selectedPersonaExtId &&
      fetchedPersonas &&
      !fetchedPersonas.find(persona => persona.extId === selectedPersonaExtId)
    ) {
      setSelectedPersonaExtId('')
    }
  }, [fetchedPersonas, selectedPersonaExtId])

  const sortedPersonas = useMemo(() => {
    if (!fetchedPersonas) {
      return []
    }

    // sort by type, then by createdAt
    return fetchedPersonas.sort((a, b) => {
      if (a.type !== b.type) {
        return a.type === 'template' ? -1 : 1
      }

      return b.createdAt.localeCompare(a.createdAt)
    })
  }, [fetchedPersonas])

  const handlePersonaCreate = useCallback<PersonaFormModalSubmitHandler>(
    async (data, closeModal) => {
      try {
        const { character, demographic, insights, look, professional } = data

        const res = await client.post('createPersona', {
          body: {
            ...demographic,
            ...professional,
            ...character,
            ...look,
            ...insights,
          },
        })

        if (res.error) {
          showToast({ messageKey: 'common.error.unexpected', status: 'error' })

          return
        }

        const createdPersona = res.data

        mutatePersonas(
          previous => [
            { ...createdPersona, editable: true, scenariosAttached: 0 },
            ...(previous ?? []),
          ],
          false
        )

        setSelectedPersonaExtId(createdPersona.extId)

        showToast({ messageKey: 'common.alert.created', status: 'success' })
        closeModal()
      } catch (error) {
        showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }
    },
    [mutatePersonas]
  )

  const [createPersonaModal, showCreatePersonaModal] =
    usePersonaFormModal(handlePersonaCreate)

  const [nextStepModal, confirmProceedingViaModal] = useNextStepModal({
    titleKey: 'common.wellDone',
    descriptionKey:
      'scenario.builder.ai.steps.conversation-partner.nextStepModal.description',
    continueLabelKey: 'common.saveAndProceed',
  })

  const navigate = useNavigate()
  const goNextStep = () => {
    navigate('../trainee')
  }

  const onSubmit = async () => {
    if (isBuilderReadonly) {
      return goNextStep()
    }

    try {
      setIsSubmitting(true)

      const isConfirmed = await confirmProceedingViaModal()

      if (!isConfirmed) {
        return
      }

      const isDirty = scenario.persona?.extId !== selectedPersonaExtId

      if (!isDirty) {
        return goNextStep()
      }

      const res = await client.put('selectPersona', {
        params: {
          path: {
            extId: scenarioExtId,
            programExtId,
            trainingExtId,
          },
        },
        body: { personaExtId: selectedPersonaExtId },
      })

      if (res.error) {
        return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }

      await mutateScenario()
      goNextStep()
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  const isInitializingPersonas = !fetchedPersonas && isLoadingPersonas

  if (isInitializingPersonas) {
    return <CenteredSpinner />
  }

  return (
    <>
      {createPersonaModal}
      {nextStepModal}
      <SectionHeader
        titleKey="scenario.builder.ai.steps.conversation-partner.formTitle"
        subtitleKey="scenario.builder.ai.steps.conversation-partner.formSubtitle"
        sx={{ mb: 8 }}
      />
      <Stack>
        <CardGrid w="full">
          {!isBuilderReadonly && (
            <CardGrid.CreateCard
              labelKey="scenario.builder.ai.steps.conversation-partner.createPersona"
              onClick={showCreatePersonaModal}
            />
          )}
          {sortedPersonas.map(persona => (
            <PersonaCard
              key={persona.extId}
              isSelected={selectedPersonaExtId === persona.extId}
              persona={persona}
              onSelect={() => {
                if (isBuilderReadonly) {
                  return
                }

                if (!scenario.editable) {
                  return showToast({
                    messageKey: 'persona.info.cannotChange',
                    status: 'warning',
                  })
                }

                setSelectedPersonaExtId(persona.extId)
              }}
              isBuilderReadonly={isBuilderReadonly}
              mutateScenario={mutateScenario}
            />
          ))}
        </CardGrid>
      </Stack>

      <StickyFooter
        onBack={() => {
          navigate('../goal')
        }}
        onNext={onSubmit}
        isNextDisabled={!isBuilderReadonly && !selectedPersonaExtId}
        isNextLoading={isSubmitting}
      />
    </>
  )
}
