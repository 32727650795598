import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Stack,
  useCheckboxGroup,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type ReactNode } from 'react'

import { type Filters, type FilterType } from '../../shared/filters'

interface FilterSectionProps {
  title: ReactNode
  options: Filters[FilterType]
  value: Filters[FilterType]
  onChange: (value: Filters[FilterType]) => void
}

export const FilterSection = ({
  options,
  value,
  onChange,
  title,
}: FilterSectionProps) => {
  const { getCheckboxProps } = useCheckboxGroup({
    value,
    onChange,
  })

  return (
    <AccordionItem border="none">
      <AccordionButton
        height="32px"
        px={3}
        borderRadius={4}
        border="1px solid"
        borderColor={colors.blue[100]}
        mb={4}
        justifyContent="space-between"
      >
        <Box fontSize={14} fontWeight={500}>
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pt={0} pb={10} px={3}>
        <Stack>
          {options.map((option: Filters[FilterType][number]) => (
            <Checkbox key={option} {...getCheckboxProps({ value: option })}>
              <FormattedMessage
                id={`coaching.program.templates.${option}` satisfies I18nKey}
              />
            </Checkbox>
          ))}
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  )
}
