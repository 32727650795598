import { SectionHeader } from '@repo/ui'

import { ComponentSection } from './shared/component-section'
import { ComponentVariant } from './shared/component-variant'

export const SectionHeaderSection = () => (
  <ComponentSection
    title="SectionHeader"
    subtitle="This component is meant to be used for the heading sections"
  >
    <ComponentVariant title="common usage">
      <SectionHeader title="Here comes title" subtitle="Here comes subtitle" />
    </ComponentVariant>
    <ComponentVariant title="without subtitle">
      <SectionHeader title="Here comes title" />
    </ComponentVariant>
  </ComponentSection>
)
