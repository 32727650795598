import { Box, Button, HStack, Icon, Input, Stack, Text } from '@chakra-ui/react'
import { type SxProps } from '@mui/material'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, showToast } from '@repo/ui'
import { createColumnHelper, type RowSelectionState } from '@tanstack/react-table'
import { uniq } from 'lodash-es'
import { useMemo, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { RiErrorWarningLine } from 'react-icons/ri'

import { ContentLoader } from '../../builder/components/content-loader'
import { ActionButtonContainer } from '../../coaching-benchmark-profiles/components/action-button-container'
import { AdvancedTable } from '../../components/advanced-table'
import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { isInvitationExpired } from '../helpers/utils'
import {
  type EmailParticipantStatus,
  type UserParticipant,
} from '../types/cohort-email-participants'
import { useCohortParticipants } from '../use-cohort-participants'
import { useParticipants } from '../use-participants'
import { CohortParticipantRowMenu } from './cohort-participant-row-menu'

const tableSx: SxProps = { minHeight: '90%' }

interface KeyAndColor {
  messageKey: I18nKey
  statusColor: string
}
const getMessageKeyAndColor = (
  status: EmailParticipantStatus,
  lastInvitedDate: string
): KeyAndColor => {
  switch (status) {
    case 'ACTIVATED':
      return {
        messageKey: 'cohort.creation.email.participant.row.activated.status',
        statusColor: colors.green[500],
      }
    case 'REMOVED':
      return {
        messageKey: 'cohort.creation.email.participant.row.removed.status',
        statusColor: colors.red[500],
      }
    case 'DISABLED':
      return {
        messageKey: 'cohort.creation.email.participant.row.disabled.status',
        statusColor: colors.gray[500],
      }

    default:
      if (isInvitationExpired(lastInvitedDate)) {
        return {
          messageKey: 'cohort.creation.email.participant.row.invitation.expired.status',
          statusColor: colors.blue[500],
        }
      }

      return {
        messageKey: 'cohort.creation.email.participant.row.not.activated.status',
        statusColor: colors.gray[500],
      }
  }
}

export const UserTable = () => {
  const { formatMessage } = useIntl()

  const selectionState = useState<RowSelectionState>({})
  const [rowSelection, setRowSelection] = selectionState
  const selectedParticipantIds = Object.keys(rowSelection).filter(
    key => rowSelection[key]
  )

  const { removeParticipants, updateParticipantsActivationStatus, addEmails } =
    useCohortParticipants()

  const [deleteAllInputValue, setDeleteAllInputValue] = useState('')
  const [isDeleteAllButtonLoading, setIsDeleteAllButtonLoading] = useState(false)

  const { useEmailUsersList, removeUsers } = useParticipants()
  const {
    data: participantsData,
    status,
    refresh: refreshUserParticipantsList,
  } = useEmailUsersList()

  const deleteParticipant = useMemo(
    () => async (userId: string) => {
      try {
        await removeUsers([userId])
        refreshUserParticipantsList()
        showToast({ messageKey: 'common.alert.deleted', status: 'success' })
        setRowSelection(prevSelected => {
          const clone = { ...prevSelected }

          delete clone[userId]

          return clone
        })
      } catch (err) {
        showToast({ messageKey: 'general.error.deleting.data', status: 'error' })
      }
    },
    [removeUsers, refreshUserParticipantsList, setRowSelection]
  )

  const removeParticipantFromCohort = useMemo(
    () => async (userIds: Array<string>, cohortId: string) => {
      try {
        await removeParticipants(cohortId, userIds)
        refreshUserParticipantsList()
        showToast({ messageKey: 'common.alert.deleted', status: 'success' })
      } catch (err) {
        showToast({ messageKey: 'general.error.deleting.data', status: 'error' })
      }
    },
    [removeParticipants, refreshUserParticipantsList]
  )

  const addEmailParticipant = useMemo(
    () => async (userEmail: string, cohortId: string) => {
      try {
        // Language we pass here is not important
        await addEmails([userEmail], cohortId, 'en-US')
        refreshUserParticipantsList()
        showToast({
          messageKey: 'cohort.participant.updateStatus.confirmModal.success',
          status: 'success',
        })
      } catch (err) {
        showToast({ messageKey: 'general.error.saving.data', status: 'error' })
      }
    },
    [addEmails, refreshUserParticipantsList]
  )

  const updateParticipantsStatus = useMemo(
    () => async (userId: string, isDisabled: boolean, cohortId: string) => {
      try {
        await updateParticipantsActivationStatus(cohortId, userId, isDisabled)
        refreshUserParticipantsList()
        showToast({
          messageKey: 'cohort.participant.updateStatus.confirmModal.success',
          status: 'success',
        })
      } catch (err) {
        showToast({ messageKey: 'general.error.saving.data', status: 'error' })
      }
    },
    [updateParticipantsActivationStatus, refreshUserParticipantsList]
  )

  const columnHelper = createColumnHelper<UserParticipant>()
  const columns = [
    columnHelper.accessor('email', {
      header: () => <FormattedMessage id="participant.cohorts.list.table.name" />,
      cell: props => (
        <Box>
          {props.getValue()}
          {props.row.original.profile && (
            <Box fontSize="sm" color={colors.gray[500]} mt={1}>
              {props.row.original.profile.firstName} {props.row.original.profile.lastName}
            </Box>
          )}
        </Box>
      ),
    }),
    columnHelper.accessor('cohortName', {
      header: () => <FormattedMessage id="participant.cohorts.list.table.cohort" />,
      cell: props => <Text>{props.getValue() ?? ''}</Text>,
    }),
    columnHelper.accessor('state', {
      header: () => <FormattedMessage id="participant.cohorts.list.table.state" />,
      cell: props => {
        const { messageKey, statusColor } = getMessageKeyAndColor(
          props.getValue(),
          props.row.original.lastInvitedAt
        )

        return (
          <HStack gap={1} alignContent="center">
            <Box w="12px" h="12px" borderRadius="50%" bg={statusColor} />
            <FormattedMessage id={messageKey} />
          </HStack>
        )
      },
      sortingFn: (rowA, rowB) => {
        const { messageKey: messageKeyA } = getMessageKeyAndColor(
          rowA.original.state,
          rowA.original.lastInvitedAt
        )

        const { messageKey: messageKeyB } = getMessageKeyAndColor(
          rowB.original.state,
          rowB.original.lastInvitedAt
        )

        const labelA = formatMessage({ id: messageKeyA })
        const labelB = formatMessage({ id: messageKeyB })

        return labelA.localeCompare(labelB)
      },
    }),
    columnHelper.display({
      id: 'actions',
      cell: props => (
        <CohortParticipantRowMenu
          deleteParticipant={deleteParticipant}
          removeParticipantFromCohort={removeParticipantFromCohort}
          updateParticipantsStatus={updateParticipantsStatus}
          reAddEmailParticipant={addEmailParticipant}
          isDisabled={props.row.original.isDisabled}
          modalTitle={formatMessage({
            id: 'cohort.creation.email.participants.remove.participant.modal.title',
          })}
          modalLabel={formatMessage({
            id: 'cohort.participant.delete.confirmModal.remove.from.cohort',
          })}
          modalDescription={formatMessage({
            id: 'cohort.creation.email.participants.remove.participant.modal.description',
          })}
          userId={props.row.original.userId}
          userEmail={props.row.original.email}
          cohortId={props.row.original.cohortExtId}
          removed={props.row.original.state === 'REMOVED'}
        />
      ),
    }),
  ]

  const deleteAllAction = formatMessage({
    id: 'cohort.creation.email.participants.remove.all.participant.modal.description.action',
  })

  return (
    <>
      <Text>
        <FormattedMessage id="participant.cohorts.list.table.text" />
      </Text>
      <ContentLoader status={status}>
        {status === 'loaded' && participantsData?.length ? (
          <AdvancedTable
            columns={columns}
            isVirtualized
            data={participantsData}
            sx={tableSx}
            selectionState={selectionState}
            getRowId={row => row.participantId}
            virtualizedRowHeight={60}
          />
        ) : (
          <Box display="flex" alignItems="center" pt={8}>
            <Icon mr={4} as={RiErrorWarningLine} />
            <Text>
              <FormattedMessage id="cohort.creation.email.participants.empty.table" />
            </Text>
          </Box>
        )}
      </ContentLoader>
      <ActionButtonContainer>
        <HStack gap={4}>
          <InterceptAndConfirm
            onCancel={() => setDeleteAllInputValue('')}
            onConfirm={async () => {
              try {
                setIsDeleteAllButtonLoading(true)
                // map participants to userIds, then make them unique
                const selectedUserIds = participantsData
                  ?.filter(participant =>
                    selectedParticipantIds.includes(participant.participantId)
                  )
                  .map(participant => participant.userId)

                const uniqueUserExtIds = uniq(selectedUserIds)

                await removeUsers(uniqueUserExtIds)
                refreshUserParticipantsList()
                setRowSelection({})
                setDeleteAllInputValue('')
                setIsDeleteAllButtonLoading(false)
                showToast({
                  messageKey: 'common.alert.deleted',
                  status: 'success',
                })
              } catch (e) {
                showToast({ messageKey: 'general.error.deleting.data', status: 'error' })
              }
            }}
            title={formatMessage({
              id: 'cohort.creation.email.participants.remove.all.participant.modal.title',
            })}
            description={
              <Stack>
                <FormattedMessage
                  id="cohort.creation.email.participants.remove.all.participant.modal.description"
                  values={{
                    action: deleteAllAction,
                  }}
                />
                <Input
                  onChange={({ target }) => setDeleteAllInputValue(target.value)}
                  borderRadius={3}
                  mb={3}
                  placeholder={formatMessage({
                    id: 'cohort.creation.email.participants.remove.all.participant.modal.input.placeholder',
                  })}
                />
              </Stack>
            }
            ctaLabel={formatMessage({
              id: 'common.delete',
            })}
            size="xl"
            isButtonDisabled={deleteAllInputValue.trim() !== deleteAllAction}
            isButtonLoading={isDeleteAllButtonLoading}
          >
            {({ openModal }) => (
              <Button
                leftIcon={<FiTrash2 />}
                onClick={openModal}
                isDisabled={selectedParticipantIds.length === 0}
              >
                <FormattedMessage id="participant.cohorts.delete.from.organization" />
              </Button>
            )}
          </InterceptAndConfirm>
        </HStack>
      </ActionButtonContainer>
    </>
  )
}
