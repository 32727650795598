import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Select,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { Field as FormikField, Form } from 'formik'
import { includes, without } from 'lodash-es'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { OPEN_UP } from 'react-dates/constants'

import { AnimatedTree } from '../../components/animated-tree/animated-tree'
import { Field } from '../../components/formik-form/field'
import { FormControlCheckboxField } from '../../form/form-control-checkbox-field'
import { FolderSelectItem } from './folder-select-item'

const exportFormatOptions = [
  { value: 'xlsx', label: 'Excel' },
  { value: 'csv', label: 'CSV' },
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ExportForm = (props: any) => {
  const { folders, allRecordingsFolderId, setFieldValue, values, initialValues, errors } =
    props

  const { formatMessage } = useIntl()
  const [hasSelectedAll, setHasSelectedAll] = useState(true)
  const selectableFolders = initialValues.folders
  let checkedFolders = values.folders

  const updateFolderSelection = ({ target }) => {
    const selectedFolderId = target.value

    if (selectedFolderId === allRecordingsFolderId && !hasSelectedAll) {
      setFieldValue('folders', selectableFolders)
      setHasSelectedAll(true)

      return
    }

    if (selectedFolderId === allRecordingsFolderId && hasSelectedAll) {
      setFieldValue('folders', [])
      setHasSelectedAll(false)

      return
    }

    if (includes(checkedFolders, selectedFolderId)) {
      checkedFolders = without(checkedFolders, selectedFolderId)
      setFieldValue('folders', checkedFolders)
      setHasSelectedAll(false)

      return
    }

    checkedFolders = checkedFolders.concat([selectedFolderId])
    setFieldValue('folders', checkedFolders)
    setHasSelectedAll(checkedFolders.length === selectableFolders.length)
  }

  const getFormSelectValue = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const select = document.getElementById('formatSelect') as any
    const selectedValue = select.options[select.selectedIndex].value

    return selectedValue
  }

  return (
    <Form>
      <FormControl isInvalid={errors.folderSelection}>
        <FormLabel>
          <FormattedMessage id="export.modal.folderList.label" />
        </FormLabel>
        <Box overflowX="hidden" overflowY="auto">
          <AnimatedTree data={folders}>
            {folder => (
              <FolderSelectItem
                folderId={folder.id}
                name={folder.name}
                checked={includes(checkedFolders, folder.id) || hasSelectedAll}
                onChange={updateFolderSelection}
              />
            )}
          </AnimatedTree>
        </Box>
        <FormErrorMessage>
          <FormattedMessage id="export.modal.folderList.error" />
        </FormErrorMessage>
      </FormControl>

      <FormLabel mt={6}>
        <FormattedMessage id="export.modal.options.label" />
      </FormLabel>

      <FormControlCheckboxField
        prop="withLinks"
        description={formatMessage({
          id: 'export.modal.sharebaleLink.text',
        })}
      />

      <FormControlCheckboxField
        prop="depersonalized"
        description={formatMessage({
          id: 'export.modal.depersonalized.text',
        })}
      />

      <FormLabel mt={6}>
        <FormattedMessage id="export.modal.export.format" />
      </FormLabel>
      <FormikField name="format">
        {({ form }) => (
          <Select
            id="formatSelect"
            onChange={() => form.setFieldValue('format', getFormSelectValue())}
            name="format"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            options={exportFormatOptions}
            width="204px"
          >
            {exportFormatOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      </FormikField>

      <FormLabel mt={6}>
        <FormattedMessage id="export.modal.export.date.range" />
      </FormLabel>

      <Field
        type="datepicker"
        name="dates"
        defaultEndDate={moment()}
        numberOfMonths={1}
        openDirection={OPEN_UP}
        small
        isOutsideRange={day => !day.isBefore(new Date())}
      />

      <HStack mt={12} spacing={4} justify="center">
        <Button variant="outline" type="reset">
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button type="submit">
          <FormattedMessage id="export.modal.button.export" />
        </Button>
      </HStack>
    </Form>
  )
}

ExportForm.propTypes = {
  folders: PropTypes.object.isRequired,
  allRecordingsFolderId: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}
