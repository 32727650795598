import { Box, type BoxProps, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react'
import { useSortable } from '@dnd-kit/sortable'
import { FormattedMessage } from '@repo/i18n'
import { colors, showToast, useConfirmationModal } from '@repo/ui'

import { useDraggableStepsContext } from '../../draggable-steps-context'
import { useConfirmBreakingChangeModal } from '../../use-confirm-breaking-change-modal'
import { useScenarioBuilderData } from '../../use-scenario-builder-data'
import { StepItem } from './shared/step-item'
import { type SectionItem } from './types'

const ToolboxChip = ({ item, sx }: { item: SectionItem; sx?: BoxProps['sx'] }) => (
  <Box sx={sx}>
    <Tag size="md" backgroundColor={colors.blue['500']} color="white" borderRadius={5}>
      <TagLeftIcon boxSize="12px" as={item.icon} />
      <TagLabel fontWeight="medium" fontSize="xs">
        <FormattedMessage id={item.title} />
      </TagLabel>
    </Tag>
  </Box>
)

type DragDropItemProps = {
  item: SectionItem
  sx?: BoxProps['sx']
}

export const DraggableStepItem = ({ item, sx }: DragDropItemProps) => {
  const { id, isEditingDisabled, isCreated } = item

  const { onDelete, onEdit, isReadonly, isEditEnabled } = useDraggableStepsContext()
  const isStepReadonly = isEditingDisabled || isReadonly

  const { listeners } = useSortable({ id, disabled: isEditingDisabled })

  const deleteStep = async () => {
    try {
      await onDelete.current(id)
      showToast({
        messageKey: 'drag.step.item.delete.modal.success',
        status: 'success',
      })
    } catch (err) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }
  }

  const [deleteModal, showDeleteModal] = useConfirmationModal({
    onConfirm: deleteStep,
    titleKey: 'drag_step_item_delete_modal_title',
    isDestructive: true,
    confirmButtonKey: 'common.delete',
    descriptionKey: 'drag_step_item_delete_modal_description',
  })

  const [breakingChangeModal, confirmBreakingChangeViaModal] =
    useConfirmBreakingChangeModal()

  const [, , , { data: scenario }] = useScenarioBuilderData()

  const handleDeleteClick = async () => {
    const isQuizOrQA = item.type === 'coachingStepQA' || item.type === 'coachingStepQuiz'

    const isBreakingChange = scenario?.hasRecordingSessions && isQuizOrQA

    if (!isBreakingChange) {
      return showDeleteModal()
    }

    const confirmed = await confirmBreakingChangeViaModal()

    if (!confirmed) {
      return
    }

    await deleteStep()
  }

  return (
    <Box>
      {breakingChangeModal}
      {deleteModal}
      <Box {...listeners}>
        {isCreated ? (
          <StepItem
            item={item}
            sx={sx}
            isReadonly={isStepReadonly}
            isEditEnabled={isEditEnabled}
            onEdit={() => onEdit.current(item)}
            onDelete={handleDeleteClick}
          />
        ) : (
          <ToolboxChip item={item} sx={sx} />
        )}
      </Box>
    </Box>
  )
}
