import { mapAndFilterNotNil } from '@repo/utils'
import { sortBy } from 'lodash-es'
import { type PropsWithChildren, useCallback, useState } from 'react'

import { useLocalStorage } from '../../hooks/use-local-storage'
import { type ColumnControlProps } from './column-control'
import {
  type ColumnItemValue,
  type ColumnSortableItemValue,
  type SortingState,
} from './column-control-data'
import { type ColumnSortableControlProps } from './column-sortable-control'
import { type FilterControlProps } from './filter-control'
import { type FilterItemValue } from './filter-control-data'
import {
  type RecordingFiltersContext,
  RecordingFiltersContextProvider,
} from './recording-filters-context'
import { useColumnSelectData } from './recording-filters-hooks'

const defaultFilterValue: FilterItemValue = 'all'

const useFilterValueState = () => {
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(
    'recording-list-filter',
    defaultFilterValue
  )

  const [internalValue, setInternalValue] = useState<FilterItemValue>(localStorageValue)

  const onFilterChange: FilterControlProps['onChange'] = v => {
    setInternalValue(v)
  }

  const onFilterApply: FilterControlProps['onApply'] = () => {
    setLocalStorageValue(internalValue)
  }

  const onFilterClose: FilterControlProps['onClose'] = () => {
    setInternalValue(localStorageValue)
  }

  return {
    value: localStorageValue,
    internalValue,
    onChange: onFilterChange,
    onApply: onFilterApply,
    onClose: onFilterClose,
  }
}

const defaultColumnSortableValue: Array<ColumnSortableItemValue> = [
  { value: 'name', sortBy: 'default' },
  { value: 'createdAt', sortBy: 'desc' },
  { value: 'id', sortBy: 'default' },
  { value: 'email', sortBy: 'default' },
  { value: 'overallScore', sortBy: 'default' },
]

const defaultColumnSortableValueRoot: Array<ColumnSortableItemValue> = [
  { value: 'name', sortBy: 'default' },
  { value: 'createdAt', sortBy: 'desc' },
  { value: 'id', sortBy: 'default' },
  { value: 'email', sortBy: 'default' },
]

const defaultSortingState: SortingState = { sortBy: 'createdAt', sortOrder: 'desc' }

const useColumnValueState = () => {
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(
    'recording-list-column',
    defaultColumnSortableValue
  )

  // use as a temporary value store while modifying column before applying changes
  const [internalValue, setInternalValue] =
    useState<Array<ColumnSortableItemValue>>(localStorageValue)

  // we want to store sorting criterion since sorting can be based on a hidden column
  const [lsSortingState, setLsSortingState] = useLocalStorage(
    'recording-list-sorting',
    defaultSortingState
  )

  const [sortingState, setSortingState] = useState<SortingState>(lsSortingState)

  const updateState = state => {
    setInternalValue(state)
    setLocalStorageValue(state)
  }

  const updateSorting = sorting => {
    setSortingState(sorting)
    setLsSortingState(sorting)
  }

  const localStorageValueRoot = localStorageValue.filter(o1 =>
    defaultColumnSortableValueRoot.some(o2 => o1.value === o2.value)
  )

  const { fields } = useColumnSelectData()
  const sortByFields = useCallback(
    (items: Array<ColumnSortableItemValue>) =>
      sortBy(items, item => fields.indexOf(item.value)),
    [fields]
  )

  const onSortChangeAndApply = (item: ColumnSortableItemValue) => {
    const prevValue = internalValue

    const newValue = prevValue.map<ColumnSortableItemValue>(prevItem =>
      prevItem.value === item.value ? item : { ...prevItem, sortBy: 'default' }
    )

    const sortedValue = sortByFields(newValue)

    const sorting = {
      sortBy: item.value,
      sortOrder: item.sortBy,
    }

    updateState(sortedValue)
    updateSorting(sorting)
  }

  const onColumnChange: ColumnControlProps['onChange'] = value => {
    const prevValue = internalValue
    const newValue = mapAndFilterNotNil<ColumnItemValue, ColumnSortableItemValue>(
      v =>
        prevValue.find(pv => pv.value === v) || {
          value: v,
          sortBy: v === sortingState.sortBy ? sortingState.sortOrder : 'default',
        },
      value
    )

    const sortedValue = sortByFields(newValue)

    setInternalValue(sortedValue)
  }

  const onColumnApply: ColumnControlProps['onApply'] = () => {
    setLocalStorageValue(internalValue)
  }

  const onColumnClose: ColumnControlProps['onClose'] = () => {
    setInternalValue(localStorageValue)
  }

  const onColumnSortableChange: ColumnSortableControlProps['onChange'] = (
    value: Array<ColumnSortableItemValue>
  ) => {
    const sortedValue = sortByFields(value)

    const sortingCriteria = value.find(v => v.sortBy !== 'default')

    if (sortingCriteria) {
      const sorting = {
        sortBy: sortingCriteria?.value || defaultSortingState.sortBy,
        sortOrder:
          (sortingCriteria?.sortBy as SortingState['sortOrder']) ||
          defaultSortingState.sortOrder,
      }

      setSortingState(sorting)
    }

    setInternalValue(sortedValue)
  }

  const onSortColumnChangeAndApply: ColumnSortableControlProps['onApply'] = () => {
    setLocalStorageValue(internalValue)
    setLsSortingState(sortingState)
  }

  const onColumnSortableClose: ColumnSortableControlProps['onClose'] = () => {
    setInternalValue(localStorageValue)
  }

  return {
    value: localStorageValue,
    valueRoot: localStorageValueRoot,
    internalValue,
    onColumnChange,
    onColumnApply,
    onColumnClose,
    onColumnSortableChange,
    onSortColumnChangeAndApply,
    onColumnSortableClose,
    onSortChangeAndApply,
    sorting: sortingState,
  }
}

/**
 * Manages the Filter, Column and Table header sorting and filtering.
 */
export const RecordingFiltersProvider = ({ children }: PropsWithChildren) => {
  const {
    value: filterValue,
    internalValue: filterInternalValue,
    onChange: handleOnFilterChange,
    onApply: handleOnFilterApply,
    onClose: handleOnFilterClose,
  } = useFilterValueState()

  const {
    value: columnValue,
    valueRoot: columnValueRoot,
    internalValue: columnInternalValue,
    onColumnChange: handleOnColumnChange,
    onColumnClose: handleOnColumnClose,
    onColumnSortableChange: handleOnColumnSortableChange,
    onSortColumnChangeAndApply: handleOnColumnSortableSelectApply,
    onColumnSortableClose: handleOnColumnSortableClose,
    onSortChangeAndApply: handleSortOnChangeAndApply,
    onColumnApply: handleOnColumnSelectApply,
    sorting,
  } = useColumnValueState()

  const contextValue: RecordingFiltersContext = {
    filterSelectValue: filterValue,
    filterSelectInternalValue: filterInternalValue,
    onFilterSelectApply: handleOnFilterApply,
    onFilterSelectChange: handleOnFilterChange,
    onFilterSelectClose: handleOnFilterClose,
    columnSelectValue: columnValue,
    columnSelectValueRoot: columnValueRoot,
    columnSelectInternalValue: columnInternalValue,
    onColumnSelectChange: handleOnColumnChange,
    onColumnSelectClose: handleOnColumnClose,
    onColumnSortableSelectChange: handleOnColumnSortableChange,
    onColumnSortableSelectApply: handleOnColumnSortableSelectApply,
    onColumnSortableSelectClose: handleOnColumnSortableClose,
    onColumnSelectApply: handleOnColumnSelectApply,
    onColumnSortChangeAndApply: handleSortOnChangeAndApply,
    sorting,
  }

  return (
    <RecordingFiltersContextProvider value={contextValue}>
      {children}
    </RecordingFiltersContextProvider>
  )
}
