import { Grid } from '@chakra-ui/react'
import { isNotNil } from '@repo/utils'

import { type ColumnSortableItemValue } from '../../recruiting/recording-filters/column-control-data'
import { useColumnSelectData } from '../../recruiting/recording-filters/recording-filters-hooks'
import { type RecordingSession } from '../../recruiting/recording-sessions-api'
import { RecruitingSessionCard } from './recruiting-session-card'

type CardViewProps = {
  onClick: (index: number) => void
  onDeleteClick: (selectedIndex: number) => Promise<void>
  sessions: Array<RecordingSession>
  columns: Array<ColumnSortableItemValue>
  updateRecordings: () => void
  isRootFolder: boolean
}

export const CardView = ({
  onClick,
  onDeleteClick,
  sessions,
  columns,
  isRootFolder,
  updateRecordings,
}: CardViewProps) => {
  const { selectDictionary } = useColumnSelectData()

  const cardFields = isRootFolder
    ? ['id', 'createdAt', 'email']
    : ['id', 'createdAt', 'email', 'overallScore']

  const fields = columns.filter(({ value }) => cardFields.includes(value))
  const shouldDisplayName = isNotNil(columns.find(column => column.value === 'name'))

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={6} mb={6}>
      {sessions.map((session, index) => (
        <RecruitingSessionCard
          key={session.id}
          session={session}
          fields={fields}
          shouldDisplayName={shouldDisplayName}
          columnsDictionary={selectDictionary}
          onClick={() => onClick(index)}
          onDeleteClick={() => onDeleteClick(index)}
          updateRecordings={updateRecordings}
        />
      ))}
    </Grid>
  )
}
