import { Box, Flex, HStack, Image, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import IntroImgConversationPartner from '../../assets/builder-intro-conversation-partner.png'
import IntroImgGoal from '../../assets/builder-intro-goal.png'
import IntroImgInteraction from '../../assets/builder-intro-interaction.png'
import IntroImgTrainee from '../../assets/builder-intro-trainee.png'
import { type BuilderStepKey, orderedBuilderStepKeys } from './builder-steps'
import { StepCard } from './step-card'
import { StickyFooter } from './sticky-footer'

const introImages: Record<BuilderStepKey, string> = {
  goal: IntroImgGoal,
  'conversation-partner': IntroImgConversationPartner,
  trainee: IntroImgTrainee,
  interaction: IntroImgInteraction,
}

const StepDetails = ({ stepKey }: { stepKey: BuilderStepKey }) => (
  <Box minH="220px">
    <Flex gap={6} maxW="860px">
      <Image
        key={stepKey}
        maxW="360px"
        maxH="220px"
        objectFit="contain"
        src={introImages[stepKey]}
      />

      <Stack gap={6} flexGrow={1} flexBasis={0} lineHeight={1.2} fontSize={24}>
        <Text fontWeight={600}>
          <FormattedMessage id={`scenario.builder.ai.steps.${stepKey}.title`} />
        </Text>

        <Text>
          <FormattedMessage id={`scenario.builder.ai.steps.${stepKey}.introText`} />
        </Text>
      </Stack>
    </Flex>
  </Box>
)

export const BuilderIntro = () => {
  const [currentStepKeyIndex, setCurrentStepKeyIndex] = useState(0)
  const navigate = useNavigate()

  const isAtLastStep = currentStepKeyIndex === orderedBuilderStepKeys.length - 1

  const handleNext = () => {
    if (isAtLastStep) {
      return navigate('goal')
    }

    setCurrentStepKeyIndex(currentStepKeyIndex + 1)
  }

  const handleBack = () => {
    setCurrentStepKeyIndex(currentStepKeyIndex - 1)
  }

  return (
    <>
      <HStack gap={6}>
        {orderedBuilderStepKeys.map((stepKey, index) => (
          <StepCard
            key={stepKey}
            isFadedOut={index !== currentStepKeyIndex}
            titleKey={`scenario.builder.ai.steps.${stepKey}.title`}
            descriptionKey={`scenario.builder.ai.steps.${stepKey}.description`}
            number={index + 1}
          />
        ))}
      </HStack>

      <Stack align="center" mt="72px" gap={10}>
        <StepDetails stepKey={orderedBuilderStepKeys[currentStepKeyIndex]!} />

        <HStack>
          {orderedBuilderStepKeys.map((stepKey, index) => (
            <Box
              key={stepKey}
              w="10px"
              h="10px"
              borderRadius="50%"
              bg={colors.blue[index === currentStepKeyIndex ? 500 : 50]}
            />
          ))}
        </HStack>
      </Stack>

      <StickyFooter
        onBack={currentStepKeyIndex > 0 ? handleBack : undefined}
        onNext={handleNext}
        nextLabelKey={
          isAtLastStep ? 'scenario.builder.ai.intro.finishLabel' : 'common.next'
        }
      />
    </>
  )
}
