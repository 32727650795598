import { api } from '../../../utils/api'
import * as actions from './private/actions'

export const createUserSession = () => async dispatch => {
  try {
    dispatch(actions.createRequest())
    const session = await api.userActivitySessions.create()

    dispatch(actions.createSuccess(session))

    return session
  } catch (error) {
    console.error('User session creation failed', error)
  }

  return null
}
