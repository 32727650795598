import { Box, Center, Flex, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

import { type QuestionWithRatios } from '../../../../../shared/types'
import { AnswerAccuracyGraphLegend } from '../../shared/answer-accuracy-graph-legend'
import { AnswerAccuracyGraphCard } from './question-highlight-section/answer-accuracy-graph-card'

interface QuestionHighlightSectionProps {
  title: string
  subtitle: string
  questionsWithRatios: Array<QuestionWithRatios>
}

export const QuestionHighlightSection = ({
  title,
  subtitle,
  questionsWithRatios,
}: QuestionHighlightSectionProps) => (
  <Flex
    backgroundColor="secondary-light"
    direction="column"
    borderRadius="lg"
    px={6}
    py={4}
    alignItems="center"
  >
    <Box w="full">
      <Text fontSize="sm" fontWeight="medium" lineHeight={1}>
        {title}
      </Text>
      <Text mt={2} fontSize="xs">
        {subtitle}
      </Text>
    </Box>

    {questionsWithRatios.length ? (
      <>
        <Flex direction="column" w="full" mt={4} gap={4}>
          {questionsWithRatios.map(questionWithRatios => (
            <AnswerAccuracyGraphCard
              key={questionWithRatios.quizExtId}
              data={questionWithRatios}
            />
          ))}
        </Flex>
        <Flex mt={6} flex={1} alignItems="flex-end">
          <AnswerAccuracyGraphLegend />
        </Flex>
      </>
    ) : (
      <Center py={4} flex="1">
        <FormattedMessage id="dashboard.strengthsAndWeaknesses.noData" />
      </Center>
    )}
  </Flex>
)
