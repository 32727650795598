import { Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { assertExists } from '@repo/utils'
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

import { CenteredSpinner } from '../../../components/centered-spinner'
import { type Scenario } from '../../../types/api-types'
import { useScenarioBuilderData } from '../../shared/use-scenario-builder-data'
import { getInitialStepFromScenario } from './manual-builder-context/get-initial-step-from-scenario'
import {
  type ManualBuilderStepKey,
  orderedManualBuilderSteps,
} from './manual-builder-step-key'

type ManualBuilderFormContextType = {
  scenarioExtId: string
  programExtId: string
  trainingExtId: string
  scenario: Scenario
  currentStep: ManualBuilderStepKey
  goToPreviousStep: () => void
  goToNextStep: () => void
  formatInProgramLanguage: (key: I18nKey) => string
  isBuilderReadonly: boolean
}

const ManualBuilderFormContext = createContext<ManualBuilderFormContextType | null>(null)

export const ManualBuilderContextProvider = ({ children }: PropsWithChildren) => {
  const [
    { programExtId, scenarioExtId, trainingExtId },
    { data: program, error: programError },
    { data: training, error: trainingError },
    { data: scenario, error: scenarioError },
    _,
    formatInProgramLanguage,
  ] = useScenarioBuilderData()

  const navigate = useNavigate()

  const [currentStep, setCurrentStep] = useState<ManualBuilderStepKey>()

  const goToNextStep = useCallback(() => {
    assertExists(currentStep)
    const isLastStep =
      currentStep === orderedManualBuilderSteps[orderedManualBuilderSteps.length - 1]

    if (isLastStep) {
      return navigate(`/training_programs/${programExtId}/trainings/${trainingExtId}`)
    }

    setCurrentStep(
      orderedManualBuilderSteps[orderedManualBuilderSteps.indexOf(currentStep) + 1]
    )
  }, [currentStep, navigate, programExtId, trainingExtId])

  const goToPreviousStep = useCallback<
    ManualBuilderFormContextType['goToPreviousStep']
  >(() => {
    assertExists(currentStep)
    const isFirstStep = currentStep === orderedManualBuilderSteps[0]

    if (!isFirstStep) {
      setCurrentStep(
        orderedManualBuilderSteps[orderedManualBuilderSteps.indexOf(currentStep) - 1]
      )
    }
  }, [currentStep])

  const value = useMemo<ManualBuilderFormContextType | null>(() => {
    if (!program || !training || !scenario || !currentStep) {
      return null
    }

    return {
      scenarioExtId,
      programExtId,
      trainingExtId,
      currentStep,
      formatInProgramLanguage,
      goToPreviousStep,
      goToNextStep,
      scenario,
      isBuilderReadonly: program.status !== 'unpublished',
    }
  }, [
    program,
    training,
    scenario,
    scenarioExtId,
    programExtId,
    trainingExtId,
    currentStep,
    formatInProgramLanguage,
    goToPreviousStep,
    goToNextStep,
  ])

  if (!currentStep && scenario) {
    setCurrentStep(getInitialStepFromScenario(scenario))
  }

  if (programError || trainingError || scenarioError) {
    console.error('Failed to fetch data', { programError, trainingError, scenarioError })

    return (
      <Text>
        <FormattedMessage id="common.error.unexpected" />
      </Text>
    )
  }

  if (!value || !currentStep) {
    return <CenteredSpinner />
  }

  return (
    <ManualBuilderFormContext.Provider value={value}>
      {children}
    </ManualBuilderFormContext.Provider>
  )
}

export const useManualBuilderContext = () => {
  const context = useContext(ManualBuilderFormContext)

  if (!context) {
    throw new Error(
      'useManualBuilderContext must be used within a ManualBuilderFormContextProvider'
    )
  }

  return context
}
