import types from './private/actions'

const initialState = {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, import/no-default-export
export default (state = initialState, { type, data }: any = {}) => {
  switch (type) {
    case types.CREATE_USER_SESSION_SUCCESS:
      return { ...state, ...data }

    default:
      return state
  }
}
