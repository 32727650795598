import { Box, Spacer } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import moment from 'moment'
import { useMemo } from 'react'

import { DashboardCard } from '../shared/dashboard-card'
import { useDashboardCardData } from '../shared/use-dashboard-card-data'
import { DashboardInfoBox } from './shared/dashboard-info-box'
import { DashboardNameValueList } from './shared/dashboard-name-value-list'
import { DetailPageLink } from './shared/detail-page-link'
import { NoDataInformation } from './shared/no-data-information'

export const CompetencyTimeCard = () => {
  const { dataSlot } = useDashboardCardData('competencyTime')
  const { locale } = useIntl()

  const timeToCompetencyData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    return data.timeToCompetencyOverall
      .map(({ cohortExtId, duration }) => ({
        name: data.cohortNames[data.cohortExtIds.indexOf(cohortExtId)]!,
        value: moment.duration(duration).humanize(),
      }))
      .slice(0, 3)
    // we need `locale` here, because `humanize` function needs to run when the language is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSlot, locale])

  const hasEnoughDataForDetails = timeToCompetencyData.length > 2

  return (
    <DashboardCard
      titleKey="dashboard.cards.competencyTime.title"
      descriptionKey="dashboard.cards.competencyTime.subtitle"
      colSpan={2}
      wrapperProps={{ alignItems: 'center', flex: 1, mt: 7 }}
    >
      {timeToCompetencyData.length ? (
        <>
          <DashboardNameValueList
            data={timeToCompetencyData}
            tableHeadLabelOne="dashboard.cards.cohortPerformance.tab.column.cohort.name"
            tableHeadLabelTow="dashboard.cards.popularScenarios.tab.column.time"
          />

          <Box height={6} />

          {hasEnoughDataForDetails ? (
            <>
              <DashboardInfoBox>
                {dataSlot.status === 'loaded' && (
                  <FormattedMessage
                    id="dashboard.cards.popularScenarios.days.info.box.alert"
                    values={{
                      days: moment
                        .duration(dataSlot.data.timeToCompetencyAverageOverall)
                        .humanize(),
                    }}
                  />
                )}
              </DashboardInfoBox>

              <Spacer />

              <DetailPageLink sx={{ mt: 6 }} page="competency_time_details" />
            </>
          ) : (
            <DashboardInfoBox sx={{ mt: 'auto' }}>
              <FormattedMessage id="dashboard.page.changeFilterAlert" />
            </DashboardInfoBox>
          )}
        </>
      ) : (
        <NoDataInformation />
      )}
    </DashboardCard>
  )
}
