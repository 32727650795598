import {
  Button,
  Center,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, showToast } from '@repo/ui'
import { useState } from 'react'
import { MdCircle } from 'react-icons/md'

import { useErrorHandler } from '../../../../error-handling/use-error-handler'
import { type EmailParticipant } from '../../../types/cohort-email-participants'
import { useCohortParticipants } from '../../../use-cohort-participants'
import { MultiTagInput } from '../../cohort-creation-modal/components/participants-step/components/email-step/components/multi-tag-input'
import { CohortEmailInvitationsAlert } from '../../cohort-email-invitations-alert'

interface ResendEmailInvitationsModalProps {
  emailParticipants: Array<EmailParticipant>
  isOpen: boolean
  onClose: () => void
  cohortId: string
  invitedLessThen1DayAgo: Array<string>
  invitedMoreThen1DayAgo: Array<string>
  refreshParticipants: () => void
}

export const ResendEmailInvitationsModal = ({
  emailParticipants,
  isOpen,
  onClose,
  cohortId,
  invitedLessThen1DayAgo,
  invitedMoreThen1DayAgo,
  refreshParticipants,
}: ResendEmailInvitationsModalProps) => {
  const { translate } = useErrorHandler()
  const { resendEmailInvitations } = useCohortParticipants()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [emailsToResend, setEmailsToResend] = useState(invitedMoreThen1DayAgo)
  const [recentlyInvitedEmails, setRecentlyInvitedEmails] =
    useState(invitedLessThen1DayAgo)

  const [prevOpenState, setPrevOpenState] = useState(isOpen)

  if (isOpen !== prevOpenState) {
    setPrevOpenState(isOpen)
    setEmailsToResend(invitedMoreThen1DayAgo)
    setRecentlyInvitedEmails(invitedLessThen1DayAgo)
  }

  const onChange = (_: Array<string>, modifiedValue?: string) => {
    if (modifiedValue) {
      if (emailsToResend.indexOf(modifiedValue) > -1) {
        setEmailsToResend(emailsToResend.filter(email => email !== modifiedValue))
      } else {
        setRecentlyInvitedEmails(
          recentlyInvitedEmails.filter(email => email !== modifiedValue)
        )
      }
    }
  }

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      const userExtIds = emailsToResend.map(
        email =>
          emailParticipants.find(participant => email === participant.email)?.userId ?? ''
      )

      await resendEmailInvitations(userExtIds, cohortId)
      refreshParticipants()
      showToast({
        messageKey: 'general.success.email.invitations.sent',
        status: 'success',
      })
    } catch (error) {
      showToast({ message: translate(error), status: 'error' })
    }

    onClose()
    setIsLoading(false)
  }

  return (
    <Modal
      size="3xl"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent minH={450}>
        <ModalHeader fontSize="2xl">
          <FormattedMessage id="resend.email.invitations.modal.title" />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody pb={4} h={400}>
          <Text color={colors.blue[500]} fontWeight={500}>
            <FormattedMessage id="resend.email.invitations.modal.description" />
          </Text>
          <MultiTagInput
            onChange={onChange}
            errors={recentlyInvitedEmails}
            initialValues={emailsToResend}
            errorTagColor={colors.gray[300]}
          />
          {!!recentlyInvitedEmails.length && (
            <Flex align="center">
              <Icon as={MdCircle} color={colors.gray[300]} h={3} w={3} mr={1} />
              <Text fontSize="xs">
                <FormattedMessage id="resend.email.invitations.modal.error.message" />
              </Text>
            </Flex>
          )}
          <CohortEmailInvitationsAlert messageKey="resend.email.invitations.modal.alert.description" />
          <Center>
            <Button
              position="absolute"
              bottom={8}
              onClick={onSubmit}
              isLoading={isLoading}
              isDisabled={emailsToResend.length === 0}
            >
              <FormattedMessage id="resend.email.invitations.modal.button" />
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
