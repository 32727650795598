import { uniq } from 'lodash-es'

import config from '../config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tagsToArray: any = tags =>
  tags
    ? uniq(
        tags
          .split(',')
          .map(tag => tag.trim())
          .filter(tag => !!tag)
      )
    : []

export const tagsToString = tags => (tags ? tags.join(', ') : '')

export const composePluginUrl = (id, tags, org) => {
  let url = `${config.recorderUrl}/${encodeURIComponent(org)}/${id}`

  if (tags) {
    const tagsStr = tagsToArray(tags)
      .map(tag => `tags=${encodeURIComponent(tag)}`)
      .join('&')

    url += `?${tagsStr}`
  }

  return encodeURI(url)
}
