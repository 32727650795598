import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  type FormLabelProps,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  type InputProps,
} from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { colors, HelpTooltip } from '@repo/ui'
import { useField } from 'formik'
import { type ForwardedRef, forwardRef } from 'react'
import { MdOutlineDragIndicator } from 'react-icons/md'

type InputControlProps = InputProps & {
  name: string
  label?: string
  tooltipLabel?: string
  disabled?: boolean
  readOnly?: boolean
  withDrag?: boolean
  labelSize?: FormLabelProps['fontSize']
}

export const InputControl = forwardRef(
  (props: InputControlProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { name, label, labelSize, tooltipLabel, withDrag, disabled, ...rest } = props
    const { formatMessage } = useIntl()
    const [field, { error, touched }] = useField(name)

    return (
      <FormControl isInvalid={(!!error && touched) ?? false} flexGrow={1}>
        <Flex align="baseline">
          {label && <FormLabel fontSize={labelSize}>{label}</FormLabel>}
          {tooltipLabel && <HelpTooltip label={tooltipLabel} />}
        </Flex>
        <InputGroup>
          {withDrag && (
            <InputLeftElement>
              <Icon
                color={colors.gray[500]}
                as={MdOutlineDragIndicator}
                cursor={disabled ? 'not-allowed' : 'grab'}
              />
            </InputLeftElement>
          )}
          <Input
            type="text"
            {...field}
            disabled={disabled}
            name={name}
            {...rest}
            ref={ref}
          />
        </InputGroup>
        <FormErrorMessage>
          {error && formatMessage({ id: error as I18nKey })}
        </FormErrorMessage>
      </FormControl>
    )
  }
)
