import { Button, Flex } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { useNavigate } from 'react-router-dom'

import { ButtonContainer } from './button-container'
import { ContentContainer } from './content-container'
import { InfoGraph } from './info-graph'

type WaitForRecordingsScreenProps = {
  cohortExtId: string
}

export const WaitForRecordingsScreen = ({
  cohortExtId,
}: WaitForRecordingsScreenProps) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  return (
    <ContentContainer
      title={formatMessage({
        id: 'coaching.benchmarking.wizard.waitForRecordings.title',
      })}
      subtitle={
        <FormattedMessage id="coaching.benchmarking.wizard.waitForRecordings.subtitle" />
      }
    >
      <Flex justifyContent="center" p={8} pt="10px" alignItems="center">
        <InfoGraph currentStep={2} />
      </Flex>
      <ButtonContainer>
        <Button onClick={() => navigate(`/coaching/benchmarking/cohorts/${cohortExtId}`)}>
          <FormattedMessage id="coaching.benchmarking.wizard.waitForRecordings.continue" />
        </Button>
      </ButtonContainer>
    </ContentContainer>
  )
}
