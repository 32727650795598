import { Text } from '@chakra-ui/react'
import { type ApiOperations, type ApiSchemas } from '@repo/api'
import { FormattedMessage } from '@repo/i18n'
import { type FetchResponse } from 'openapi-fetch'
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { type KeyedMutator } from 'swr'

import { CenteredSpinner } from '../../../components/centered-spinner'
import { useOrganization } from '../../../hooks/use-organization'
import { type Program, type Scenario, type Training } from '../../../types/api-types'
import { type BuilderStepKey } from '../../shared/builder-steps'
import { useScenarioBuilderData } from '../../shared/use-scenario-builder-data'

type RealtimeBuilderContextType = {
  program: Program
  training: Training
  scenario: Scenario
  scenarioExtId: string
  programExtId: string
  trainingExtId: string
  mutateScenario: KeyedMutator<Scenario>
  goToStep: (stepKey: BuilderStepKey) => void
  isBuilderReadonly: boolean
  updateScenarioDetails: (
    data: Partial<ApiSchemas['updateScenarioDetails']>
  ) => Promise<
    FetchResponse<ApiOperations['updateScenarioDetails'], 'put', `${string}/${string}`>
  >
}

const RealtimeBuilderContext = createContext<RealtimeBuilderContextType | null>(null)

export const RealtimeBuilderContextProvider = ({ children }: PropsWithChildren) => {
  const [
    { programExtId, scenarioExtId, trainingExtId },
    { data: program, error: programError },
    { data: training, error: trainingError },
    { data: scenario, error: scenarioError, mutate: mutateScenario },
    updateScenarioDetails,
  ] = useScenarioBuilderData()

  const { hasRealtimeConversationStep } = useOrganization()

  const navigate = useNavigate()
  const goToStep = useCallback<RealtimeBuilderContextType['goToStep']>(
    stepKey => navigate(stepKey),
    [navigate]
  )

  const value = useMemo<RealtimeBuilderContextType | null>(() => {
    if (!program || !training) {
      return null
    }

    if (!scenario) {
      return null
    }

    return {
      program,
      training,
      programExtId,
      trainingExtId,
      scenario,
      scenarioExtId,
      mutateScenario,
      goToStep,
      updateScenarioDetails,

      isBuilderReadonly: program.status !== 'unpublished' || !hasRealtimeConversationStep,
    }
  }, [
    program,
    training,
    programExtId,
    trainingExtId,
    scenarioExtId,
    scenario,
    mutateScenario,
    goToStep,
    updateScenarioDetails,
    hasRealtimeConversationStep,
  ])

  if (programError || trainingError || scenarioError) {
    console.error('Failed to fetch data', { programError, trainingError, scenarioError })

    return (
      <Text>
        <FormattedMessage id="common.error.unexpected" />
      </Text>
    )
  }

  if (!value) {
    return <CenteredSpinner />
  }

  return (
    <RealtimeBuilderContext.Provider value={value}>
      {children}
    </RealtimeBuilderContext.Provider>
  )
}

export const useRealtimeBuilderContext = () => {
  const context = useContext(RealtimeBuilderContext)

  if (!context) {
    throw new Error(
      'useRealtimeBuilderContext must be used within a RealtimeBuilderContextProvider'
    )
  }

  return context
}
