import { Icon, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { BsInbox } from 'react-icons/bs'

export const NoSearchResult = () => (
  <Stack gap={4} flex={1} alignItems="center" justifyContent="center">
    <Icon as={BsInbox} w={8} h={8} color={colors.blue[500]} />
    <Text maxW={420} textAlign="center">
      <FormattedMessage id="coaching.program.templates.page.noResults.search" />
    </Text>
  </Stack>
)
