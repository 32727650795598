import { Box } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'

// Card item that shows e.g. a button or a menu in top right corner
// of the session card. If isShownOnHover is true, the action item
// is shown only when the card is hovered.
export const ActionItem = ({
  isShownOnHover,
  children,
}: PropsWithChildren<{ isShownOnHover: boolean }>) => (
  <Box
    className="action-item"
    display={isShownOnHover ? 'none' : 'flex'}
    position="absolute"
    top={2}
    right={2}
    // required to suppress the onCLick event on the card
    onClick={e => e.stopPropagation()}
  >
    {children}
  </Box>
)
