import { Button, HStack, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { usePromisingModal } from '@repo/ui'

export const useConfirmDeleteProgram = (isShared: boolean) =>
  usePromisingModal<boolean>(
    ({ resolve }) => (
      <Stack>
        {isShared ? (
          <>
            <Text align="center">
              <FormattedMessage id="coaching.program.send.modal.requestsStatus.listItem.DeleteProgram.warningModal.description" />
            </Text>
            <Text align="center">
              <FormattedMessage id="coaching.program.edit.warning.proceed" />
            </Text>
          </>
        ) : (
          <Text align="center">
            <FormattedMessage id="program.button.delete.confirm" />
          </Text>
        )}
        <HStack mt={8} spacing={4} justify="center">
          <Button variant="outline" onClick={() => resolve(false)}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button type="submit" colorScheme="red" onClick={() => resolve(true)}>
            <FormattedMessage id="coaching.program.edit.warning.continue" />
          </Button>
        </HStack>
      </Stack>
    ),
    { titleKey: 'common.warning' }
  )
