import { type ApiSchemas } from '@repo/api'
import { uniqueId } from 'lodash-es'

import { type CoachingStepAIQA } from '../../../../../../builder/steps/api'

type FieldArrayValue = {
  id: string
  value: string
}

export type QuestionFormValues = {
  questionTranscript: string
  behavioralGoals: string
  dos: Array<FieldArrayValue>
  donts: Array<FieldArrayValue>
  currentFormSection: SectionKey
}

export type LastFetchedSuggestion = {
  questionExtId: string
  suggestion: ApiSchemas['generateStepContentResponse']
}

export const getQuestionFormDataFromSuggestion = ({
  field,
  suggestion,
}: ApiSchemas['generateStepContentResponse']): {
  fieldKey: SectionKey
  value: QuestionFormValues[SectionKey]
} => {
  switch (field) {
    case 'interlocutor_statement':
    case 'trainee_expected_behavior':
      return {
        fieldKey:
          field === 'interlocutor_statement' ? 'questionTranscript' : 'behavioralGoals',
        value: suggestion,
      }

    case 'dos':
    case 'donts':
      return {
        fieldKey: field,
        value: suggestion.map(value => ({ id: uniqueId(), value })),
      }

    default:
      throw new Error(`Unknown field ${field}`)
  }
}

export type SectionKey = 'questionTranscript' | 'behavioralGoals' | 'dos' | 'donts'

export const orderedFormSections: Array<SectionKey> = [
  'questionTranscript',
  'behavioralGoals',
  'dos',
  'donts',
]

export const MAX_QUESTION_COUNT = 4

const getLastSectionKeyFromQuestionData = (
  questionData: QuestionFormValues
): SectionKey => {
  if (questionData.dos.length) {
    return 'donts'
  }

  if (questionData.behavioralGoals) {
    return 'dos'
  }

  if (questionData.questionTranscript) {
    return 'behavioralGoals'
  }

  return 'questionTranscript'
}

export const getDefaultFormValues = (
  stepData: CoachingStepAIQA | undefined,
  lastFetchedSuggestion?: LastFetchedSuggestion
): QuestionFormValues => {
  if (!stepData) {
    return {
      currentFormSection: 'questionTranscript',
      questionTranscript: '',
      behavioralGoals: '',
      dos: [],
      donts: [],
    }
  }

  const {
    step: { questionTranscript, behavioralGoals, dos, donts },
  } = stepData

  const data: QuestionFormValues = {
    currentFormSection: 'questionTranscript',
    questionTranscript: questionTranscript ?? '',
    behavioralGoals: behavioralGoals ?? '',
    dos:
      dos?.map(d => ({
        id: uniqueId(),
        value: d.instruction,
      })) ?? [],
    donts:
      donts?.map(d => ({
        id: uniqueId(),
        value: d.instruction,
      })) ?? [],
  }

  data.currentFormSection = getLastSectionKeyFromQuestionData(data)

  if (lastFetchedSuggestion && lastFetchedSuggestion.questionExtId === stepData.id) {
    const { suggestion } = lastFetchedSuggestion
    const { fieldKey, value } = getQuestionFormDataFromSuggestion(suggestion)
    const stepHasExistingData = !!stepData[fieldKey]

    if (!stepHasExistingData) {
      // TODO this type here can be improved by using generic type at getQuestionFormDataFromSuggestion
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data[fieldKey] = value as any
    }
  }

  return data
}
