import { Box, Flex, Stack } from '@chakra-ui/react'
import { colors, Logo, scrollbarStylesWhite } from '@repo/ui'
import { isDevelopment } from '@repo/utils'
import { type PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'

import { useLocalStorage } from '../hooks/use-local-storage'
import {
  selectOrganizationHasCoaching,
  selectOrganizationHasRecruiting,
} from '../store/entities/organization/selectors'
import { SidebarGroupCoaching } from './sidebar/sidebar-group-coaching'
import { SidebarGroupDevOnly } from './sidebar/sidebar-group-dev-only'
import { SidebarGroupRecruiting } from './sidebar/sidebar-group-recruiting'

const SidebarWrapper = ({ children }: PropsWithChildren) => (
  <Flex
    as="aside"
    flexDir="column"
    bg={colors.blue[700]}
    overflow="hidden"
    width="248px"
    zIndex="100"
  >
    {children}
  </Flex>
)

const SidebarHeader = () => (
  <Box px={4} my={10} as="a" href="/" sx={{ img: { width: '150px' } }}>
    <Logo type="light" />
  </Box>
)

const SidebarContent = ({ children }: PropsWithChildren) => (
  <Stack px={4} gap={5} overflowY="auto" sx={scrollbarStylesWhite}>
    {children}
  </Stack>
)

export const Sidebar = () => {
  const hasRecruiting = useSelector(selectOrganizationHasRecruiting)
  const hasCoaching = useSelector(selectOrganizationHasCoaching)
  const [hideDevOnly] = useLocalStorage('hideDevOnlySidebar', false)

  return (
    <SidebarWrapper>
      <SidebarHeader />

      <SidebarContent>
        {hasCoaching && <SidebarGroupCoaching />}

        {hasRecruiting && (
          <>
            <Box bg={colors.gray[100]} h="1px" />

            <SidebarGroupRecruiting />
          </>
        )}

        {isDevelopment && !hideDevOnly && <SidebarGroupDevOnly />}
      </SidebarContent>
    </SidebarWrapper>
  )
}
