import { Button, HStack, Image, Tag } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { type SxProps } from '@mui/material'
import { FormattedMessage } from '@repo/i18n'
import { PageHeader } from '@repo/ui'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { BsUpload } from 'react-icons/bs'
import { RiAddFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'

import { AdvancedTable } from '../../components/advanced-table'
import { AppHeaderTitle } from '../../components/app-header-title'
import { PageList } from '../../containers/page-list'
import { useGoogleStorageAsset } from '../../hooks/use-google-storage-asset'
import { useProgramsList } from '../../new-program-builder/shared/api'
import { type ExerciseWithConnectedPrograms } from '../../types/api-types'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'
import { ConnectExerciseButton } from './exercise-list/connect-exercise-button'
import { useCreateExerciseModal } from './exercise-list/use-create-exercise-modal'
import { OptionsMenu } from './options-menu'

const tableSx: SxProps = { minHeight: '90%' }

const flexColumnCss = css`
  display: flex;
  flex-direction: column;
`

const ImageRender = ({ imageUrl }) => {
  const imageHttpUrl = useGoogleStorageAsset(imageUrl)

  return <Image w="100px" src={imageHttpUrl} />
}

const LinkedProgramsCell = ({ programExtIds }: { programExtIds: Array<string> }) => {
  const { data: trainingPrograms } = useProgramsList()
  const linkedPrograms = trainingPrograms?.filter(program =>
    programExtIds.includes(program.extId)
  )

  return (
    <HStack spacing={2} flexWrap="wrap">
      {linkedPrograms?.map(program => (
        <Tag textAlign="center" key={program.extId} size="sm" colorScheme="teal">
          {program.title}
        </Tag>
      ))}
    </HStack>
  )
}

const columnHelper = createColumnHelper<ExerciseWithConnectedPrograms>()

const columns = [
  columnHelper.display({
    id: 'imageUrl',
    header: () => <FormattedMessage id="coaching.exerciseList.table.image" />,
    cell: ({ row }) => <ImageRender imageUrl={row.original.imageUrl} />,
    enableSorting: false,
  }),
  columnHelper.accessor('title', {
    header: () => <FormattedMessage id="coaching.exerciseList.table.title" />,
  }),
  columnHelper.accessor('type', {
    header: () => <FormattedMessage id="coaching.exerciseList.table.variable" />,
    cell: ({ getValue }) => <FormattedMessage id={`exercise.type.${getValue()}`} />,
  }),
  columnHelper.display({
    id: 'linkedPrograms',
    header: () => <FormattedMessage id="coaching.exerciseList.table.program" />,
    cell: ({ row }) => <LinkedProgramsCell programExtIds={row.original.programExtIds} />,
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <HStack justify="flex-end">
        <ConnectExerciseButton exercise={row.original} />
        <OptionsMenu exercise={row.original} />
      </HStack>
    ),
    meta: {
      align: 'right',
    },
  }),
]

export const ExerciseList = () => {
  const { data } = useOpenapiSWR('getExercises')
  const isLoading = data === undefined
  const exercises = useMemo(() => data ?? [], [data])
  const [createExerciseModal, showCreateExerciseModal] = useCreateExerciseModal()

  return (
    <PageList css={flexColumnCss}>
      {createExerciseModal}
      <AppHeaderTitle formattedMessageId="coaching.exerciseList.title" />
      <PageHeader
        buttons={
          <HStack spacing={4} h="fit-content">
            <Button variant="link" leftIcon={<BsUpload />} as={Link} to="import">
              <FormattedMessage id="coaching.programList.button.import" />
            </Button>
            <Button
              leftIcon={<RiAddFill size="1.25em" />}
              iconSpacing="1"
              onClick={showCreateExerciseModal}
            >
              <FormattedMessage id="coaching.programList.button.create" />
            </Button>
          </HStack>
        }
      />

      <AdvancedTable
        isLoading={isLoading}
        data={exercises}
        columns={columns}
        sx={tableSx}
        emptyStateLabelKey="general.noDataAvailable"
      />
    </PageList>
  )
}
