import { type FC } from 'react'

import { useInteractionStepContext } from './interaction-step-context'
import { StepBehavioralAttitude } from './interaction-step-renderer/step-behavioral-attitude'
import { StepConversationCustomization } from './interaction-step-renderer/step-conversation-customization'
import { StepConversationDynamics } from './interaction-step-renderer/step-conversation-dynamics'
import { StepInstructions } from './interaction-step-renderer/step-instructions'
import { StepReview } from './interaction-step-renderer/step-review'
import { StepSessionDetails } from './interaction-step-renderer/step-session-details'
import { StepStageGoals } from './interaction-step-renderer/step-stage-goals'
import { type InteractionStepKey } from './ordered-interaction-steps'

const interactionStepComponents: Record<InteractionStepKey, FC> = {
  'conversation-dynamics': StepConversationDynamics,
  'session-details': StepSessionDetails,
  'stage-goals': StepStageGoals,
  'behavioral-attitude': StepBehavioralAttitude,
  instructions: StepInstructions,
  'conversation-customization': StepConversationCustomization,
  review: StepReview,
}

export const InteractionStepRenderer = () => {
  const { currentStep } = useInteractionStepContext()

  const Component = interactionStepComponents[currentStep]

  return <Component />
}
