import { Checkbox } from '@chakra-ui/react'
import { type ChangeEvent, type PropsWithChildren } from 'react'

import { MenuItemDiv } from '../../MenuItemDiv'
import { SortDirectionToggle, type SortOptions } from '../../SortDirectionToggle'
import {
  makeSortableChecked,
  makeSortableNotChecked,
  useCheckboxSortableSelectContext,
} from './utils'

type CheckboxSortableSelectItemProps = {
  value: string
  sorting: {
    sortBy: string
    sortOrder: Exclude<SortOptions, 'default'>
  }
}

export const CheckboxSortableSelectItem = ({
  children,
  value: valueProp,
  sorting,
}: PropsWithChildren<CheckboxSortableSelectItemProps>) => {
  const { onItemChange, sortableValues } = useCheckboxSortableSelectContext()

  const sortableValue = sortableValues.find(s => s.value === valueProp)
  const sortBy = valueProp === sorting.sortBy ? sorting.sortOrder : 'default'
  const sortableCheckbox = sortableValue
    ? makeSortableChecked(sortableValue.value, sortBy)
    : makeSortableNotChecked(valueProp, sortBy)

  const { checked } = sortableCheckbox
  const handleOnCheckedChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (!ev.target.checked) {
      onItemChange({
        item: sortableCheckbox.item,
        checked: false,
        sortBy,
      })
    } else {
      onItemChange({
        item: sortableCheckbox.item,
        checked: true,
        sortBy,
      })
    }
  }

  const handleOnSortChange = (v: SortOptions) => {
    onItemChange({
      item: sortableCheckbox.item,
      checked: true,
      sortBy: v,
    })
  }

  return (
    <MenuItemDiv>
      <Checkbox isChecked={checked} onChange={handleOnCheckedChange}>
        {children}
      </Checkbox>
      <SortDirectionToggle ml={2} value={sortBy} onChange={handleOnSortChange} />
    </MenuItemDiv>
  )
}
