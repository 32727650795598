import { createSelector } from 'reselect'

const getLocalState = state => state.entities.roles

export const selectRoles = createSelector(getLocalState, roles => roles)

export const makeSelectRole = () =>
  createSelector(
    selectRoles,
    (_, { id }) => id,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (roles: any, id) => roles.find(profile => profile.id === id)
  )
