import { Box, Center, Icon, IconButton, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { MdEdit } from 'react-icons/md'

import { type Question, type QuestionWithRatios } from '../../../../shared/types'
import { AnswerAccuracyGraph } from '../shared/answer-accuracy-graph'
import { AnswerAccuracyGraphLegend } from '../shared/answer-accuracy-graph-legend'

interface ResultViewProps {
  questions: Array<Question>
  userStatsDataKnowledgeCheckSummary: QuestionWithRatios
  openSelectQuestionModal: () => void
}

export const ResultView = ({
  questions,
  userStatsDataKnowledgeCheckSummary,
  openSelectQuestionModal,
}: ResultViewProps) => (
  <Stack spacing={6} align="center" mt={4}>
    <Box
      width="full"
      pt={5}
      position="relative"
      bg={colors['secondary-light']}
      borderRadius="md"
    >
      <Text px={4} mb={4} fontSize="sm" fontWeight="medium">
        <FormattedMessage
          id="dashboard.resultPerQuestion.nQuestionsSelected"
          values={{ n: questions.length }}
        />
      </Text>
      <Stack spacing={6} px={4} pb={4} overflowY="auto" maxH="160px">
        {questions.map(question => (
          <Text fontSize="xs" key={question.extId}>
            {question.questionText}
          </Text>
        ))}
      </Stack>

      <IconButton
        position="absolute"
        right="4"
        top="4"
        aria-label="Edit"
        isRound
        icon={<Icon as={MdEdit} fontSize="lg" />}
        onClick={openSelectQuestionModal}
      />
    </Box>

    <Stack w="full" spacing={4}>
      <AnswerAccuracyGraph data={userStatsDataKnowledgeCheckSummary} />
      <Center>
        <AnswerAccuracyGraphLegend />
      </Center>
    </Stack>
  </Stack>
)
