import { Progress, Stack, Tab, TabList, Tabs, Text } from '@chakra-ui/react'
import { type ApiSchemas } from '@repo/api'
import { FormattedMessage } from '@repo/i18n'
import { SectionHeader, showToast } from '@repo/ui'
import { assertExists } from '@repo/utils'
import { times } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useSWR from 'swr'

import {
  type CoachingStep,
  type CoachingStepAIQA,
} from '../../../../../builder/steps/api'
import { CenteredSpinner } from '../../../../../components/centered-spinner'
import { useUserSessionId } from '../../../../../store/entities/user-activity-session/use-user-session-id'
import { client } from '../../../../../utils/openapi-client'
import { useCustomizationStepIntroModal } from '../../../../shared/use-customization-step-intro-modal'
import { useNextStepModal } from '../../../../shared/use-next-step-modal'
import { useAIBuilderContext } from '../../../shared/ai-builder-context'
import { useInteractionStepContext } from '../interaction-step-context'
import {
  QuestionForm,
  type QuestionFormHandle,
  type QuestionFormProps,
} from './step-conversation-generation/question-form'
import {
  type LastFetchedSuggestion,
  MAX_QUESTION_COUNT,
} from './step-conversation-generation/question-form-utils'

type ContentGenerationField = ApiSchemas['generateStepContentRequest']['field']

const SKIP_GENERATION_KEY = 'skipGeneration'

const getContentGenerationFieldFromQuestion = (
  question: CoachingStepAIQA
): ContentGenerationField | typeof SKIP_GENERATION_KEY => {
  const { step } = question

  if (!step.questionTranscript) {
    return 'interlocutor_statement'
  }

  if (!step.behavioralGoals) {
    return 'trainee_expected_behavior'
  }

  if (!step.dos?.length) {
    return 'dos'
  }

  /**
   * TODO this if condition causes an edge case bug, it's not crucial, can fix it later
   *
   * If we confirm the last question when the donts field is empty
   * then we go back to this page again, we auto-apply the AI suggestion to donts field of last question again,
   * even though user has already hit the continue button after removing the suggestion from the donts field once
   */
  if (!step.donts?.length) {
    return 'donts'
  }

  return SKIP_GENERATION_KEY
}

export const StepConversationGeneration = () => {
  const { setCurrentStep, initialQuestionIndex } = useInteractionStepContext()

  const { programExtId, trainingExtId, scenarioExtId, scenario, isBuilderReadonly } =
    useAIBuilderContext()

  const userSessionId = useUserSessionId()

  const {
    data: steps,
    error: stepsError,
    mutate: mutateSteps,
    isLoading,
  } = useSWR<Array<CoachingStep>>(
    `/admin/programs/${programExtId}/trainings/${trainingExtId}/scenarios/${scenarioExtId}/steps`
  )

  const questionFormRef = useRef<QuestionFormHandle>(null)

  const aiQuestions = useMemo<Array<CoachingStepAIQA>>(
    () =>
      (steps?.filter(
        ({ step }) => 'type' in step && step.type === 'aiSupported'
      ) as Array<CoachingStepAIQA>) ?? [],
    [steps]
  )

  const [lastFetchedSuggestion, setLastFetchedSuggestion] =
    useState<LastFetchedSuggestion>()

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0)

  const currentQuestionCount = aiQuestions.length

  const [nextQuestionModal, confirmNextQuestion] = useNextStepModal({
    titleKey: 'scenario.ai.builder.steps.conversationGeneration.nextQuestionModal.title',
    descriptionKey:
      'scenario.ai.builder.steps.conversationGeneration.nextQuestionModal.description',
    continueLabelKey:
      'scenario.ai.builder.steps.conversationGeneration.nextQuestionModal.button',
  })

  const [introModal, showIntroModal] = useCustomizationStepIntroModal()

  const fetchSuggestionForQuestion = useCallback(
    (questionId: string, field: ContentGenerationField) =>
      client.post('generateStepContent', {
        params: {
          path: { programExtId, scenarioExtId, trainingExtId, id: questionId },
        },
        body: { field, userActivitySessionExtId: userSessionId },
      }),
    [programExtId, scenarioExtId, trainingExtId, userSessionId]
  )

  const fetchAndApplySuggestionForQuestion = useCallback(
    async ({
      question,
      shouldApplyUsingRef,
    }: {
      question: CoachingStepAIQA
      shouldApplyUsingRef: boolean
    }) => {
      if (!scenario.editable) {
        return
      }

      const field = getContentGenerationFieldFromQuestion(question)

      // if there is no field to get suggestions for, skip fetching
      if (field === SKIP_GENERATION_KEY) {
        return
      }

      try {
        const res = await fetchSuggestionForQuestion(question.id, field)

        if (res.error) {
          throw new Error()
        }

        if (shouldApplyUsingRef) {
          questionFormRef.current?.applySuggestion(res.data)
        }

        setLastFetchedSuggestion({
          suggestion: res.data,
          questionExtId: question.id,
        })
      } catch (error) {
        showToast({
          status: 'error',
          messageKey:
            'scenario.ai.builder.steps.conversationGeneration.suggestions.failed',
        })
      }
    },
    [fetchSuggestionForQuestion, scenario.editable]
  )

  const createNewEmptyStep = useCallback(async () => {
    const res = await client.post('createAiQaStep', {
      body: { order: aiQuestions.length },
      params: {
        path: {
          programExtId,
          scenarioExtId,
          trainingExtId,
        },
      },
    })

    if (res.error) {
      showToast({ status: 'error', messageKey: 'common.error.unexpected' })

      return
    }

    const newStep = res.data as CoachingStepAIQA

    await fetchAndApplySuggestionForQuestion({
      question: newStep,
      shouldApplyUsingRef: false,
    })

    mutateSteps(async prevSteps => {
      const isStepAlreadyInList = !!prevSteps?.find(step => step.id === newStep.id)

      /**
       * this is preventing an edge case where we add the same step to the list twice here
       * normally we should immediately mutate after the step is created
       * but here we await for `fetchAndApplySuggestionForQuestion`
       * so, it's possible to trigger an SWR revalidate for steps (e.g. vie re-focusing the tab) before this mutation is executed
       * so, we check just in case to prevent adding the same step twice
       *
       * probably an ideal solution would be to use a separate loading state for fetching and applying suggestions, so it does not gets mixed with mutation of steps
       */
      if (isStepAlreadyInList) {
        return prevSteps
      }

      return [...(prevSteps ?? []), newStep]
    }, false)
  }, [
    mutateSteps,
    programExtId,
    scenarioExtId,
    trainingExtId,
    aiQuestions.length,
    fetchAndApplySuggestionForQuestion,
  ])

  const [isInitialized, setIsInitialized] = useState(false)
  const shouldInitializeRef = useRef(true)

  const handleSaveQuestion: QuestionFormProps['onSaveQuestion'] = async ({
    isFinalized,
    formValues,
  }) => {
    // immediately go to next step if scenario is not editable
    if (!scenario.editable) {
      return setCurrentStep('conversation-customization')
    }

    const stepIdToUpdate = aiQuestions[selectedQuestionIndex]?.id

    assertExists(steps, 'steps')
    assertExists(stepIdToUpdate, 'stepIdToUpdate')

    const filteredDos = formValues.dos
      .filter(d => d.value.trim().length)
      .map(d => ({ instruction: d.value }))

    const filteredDonts = formValues.donts
      .filter(d => d.value.trim().length)
      .map(d => ({ instruction: d.value }))

    // call update request
    const res = await client.put('updateAiQaStep', {
      body: {
        questionTranscript: formValues.questionTranscript,
        behavioralGoals: formValues.behavioralGoals,
        // filter out empty values
        dos: filteredDos.length ? filteredDos : null,
        donts: filteredDonts.length ? filteredDonts : null,
      },
      params: {
        path: {
          programExtId,
          scenarioExtId,
          trainingExtId,
          id: stepIdToUpdate,
        },
      },
    })

    if (res.error) {
      showToast({ status: 'error', messageKey: 'common.error.unexpected' })

      throw new Error()
    }

    const updatedStepData = res.data as CoachingStepAIQA

    mutateSteps(
      steps.map(step => {
        if (step.id === updatedStepData.id) {
          return updatedStepData
        }

        return step
      })
    )

    if (!isFinalized) {
      await fetchAndApplySuggestionForQuestion({
        question: updatedStepData,
        shouldApplyUsingRef: true,
      })

      return
    }

    const isSavingTheLastQuestion = selectedQuestionIndex === MAX_QUESTION_COUNT - 1

    if (isSavingTheLastQuestion) {
      await showIntroModal()

      return setCurrentStep('conversation-customization')
    }

    const shouldCreateNewQuestion = selectedQuestionIndex === aiQuestions.length - 1

    if (shouldCreateNewQuestion) {
      const confirmed = await confirmNextQuestion()

      if (!confirmed) {
        return
      }

      await createNewEmptyStep()
    }

    setSelectedQuestionIndex(selectedQuestionIndex + 1)
  }

  useEffect(() => {
    const init = async () => {
      if (
        isInitialized ||
        isLoading ||
        steps === undefined ||
        !shouldInitializeRef.current
      ) {
        return
      }

      shouldInitializeRef.current = false

      if (isBuilderReadonly) {
        return setIsInitialized(true)
      }

      if (steps.length === 0) {
        await createNewEmptyStep()
      } else {
        setSelectedQuestionIndex(initialQuestionIndex ?? aiQuestions.length - 1)

        const lastQuestion = aiQuestions[aiQuestions.length - 1]

        assertExists(lastQuestion, 'lastQuestion')

        await fetchAndApplySuggestionForQuestion({
          question: lastQuestion,
          shouldApplyUsingRef: false,
        })
      }

      setIsInitialized(true)
    }

    init()
  }, [
    createNewEmptyStep,
    isInitialized,
    isLoading,
    steps,
    aiQuestions.length,
    aiQuestions,
    fetchAndApplySuggestionForQuestion,
    isBuilderReadonly,
    initialQuestionIndex,
  ])

  if (!steps || !isInitialized) {
    return <CenteredSpinner />
  }

  if (stepsError) {
    console.error('Failed to fetch data', stepsError)

    return (
      <Text>
        <FormattedMessage id="common.error.unexpected" />
      </Text>
    )
  }

  return (
    <Stack flex={1}>
      {nextQuestionModal}
      {introModal}
      <SectionHeader
        titleKey="scenario.builder.ai.steps.conversationGeneration.formTitle"
        sx={{ p: { fontSize: '18px' } }}
      />
      <Progress
        value={(currentQuestionCount / MAX_QUESTION_COUNT) * 100}
        maxW="500px"
        borderRadius="full"
        size="sm"
        mb={6}
        mt={3}
      />

      <Tabs
        onChange={index => {
          setSelectedQuestionIndex(index)
        }}
        index={selectedQuestionIndex}
        variant="soft-rounded"
        mb={6}
      >
        <TabList gap={6}>
          {times(MAX_QUESTION_COUNT, index => (
            <Tab isDisabled={index >= currentQuestionCount} key={index}>
              <FormattedMessage id="questionnaire.form.question.text.label" /> {index + 1}
            </Tab>
          ))}
        </TabList>
      </Tabs>

      <QuestionForm
        ref={questionFormRef}
        key={selectedQuestionIndex}
        onSaveQuestion={handleSaveQuestion}
        questionData={aiQuestions[selectedQuestionIndex]}
        isReadonly={!scenario.editable || isBuilderReadonly}
        lastFetchedSuggestion={lastFetchedSuggestion}
      />
    </Stack>
  )
}
