/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { PageHeader } from '@repo/ui'
import { Formik } from 'formik'
import { get, pick, round } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppHeaderTitle } from '../../components/app-header-title'
import { CenteredSpinner } from '../../components/centered-spinner'
import { Gate } from '../../components/gate'
import { useBenchmarkProfile } from '../../hooks/use-benchmark-profile'
import { useNotification } from '../../hooks/use-notification'
import { useRecruitingBenchmarkProfiles } from '../../store/entities/benchmark-profiles/use-recruiting-benchmark-profiles'
import { ProfileForm, validationSchema } from './profile-form'

const DEFAULT_FORM_DATA = {
  title: '',
  type: 'customProfile',
  values: {},
  scores: {},
}

const BenchmarkProfileEditorInner = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { data, fields, getFieldsGroupedByCategory, save } = useBenchmarkProfile(id)
  const { notification, notify } = useNotification()
  const [expertMode, setExpertMode] = useState(false)
  const isCreateMode = !data
  const hasScores = isCreateMode || data.scores !== null

  const goIndex = () => navigate('/target_profiles')
  const toggleExpertMode = () => setExpertMode(!expertMode)
  const handleSubmit = async formData => {
    const values = Object.keys(formData.values).map(field => ({
      name: field,
      ideal: round(formData.values[field] * 100, 2),
      weight: formData.scores[field],
    }))

    const benchmarkData = {
      ...pick(formData, ['title', 'type']),
      values,
    } as any

    try {
      await save(benchmarkData)

      if (isCreateMode) {
        goIndex()
      } else {
        notify(<FormattedMessage id="common.alert.saved" />, 'success')
      }
    } catch (error) {
      notify(<FormattedMessage id="benchmarkProfile.editor.error.content" />, 'danger')
    }
  }

  const fieldsByCategory = getFieldsGroupedByCategory()

  const values = useMemo(
    () =>
      fields.reduce((result, field: any) => {
        let value

        if (data?.values) {
          value = data.values[field.id] || field.value
        }

        result[field.id] = value || 0

        return result
      }, {}),
    [data, fields]
  )

  const getScores = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMemo(
      () =>
        fields.reduce((result, field) => {
          const scores = get(data, 'scores', {})
          const score = typeof scores[field.id] !== 'undefined' ? scores[field.id] : 0

          result[field.id] = score

          return result
        }, {}),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [data, fields]
    )

  const scores = hasScores ? getScores() : null
  const infoMessage = `benchmarkProfile.editor.${hasScores ? 'withScores' : 'noScores'}`

  const initialData = { ...DEFAULT_FORM_DATA, ...data, values, scores }

  return (
    <Gate>
      <Stack padding="1.5rem" pb={0} width="100%">
        <AppHeaderTitle formattedMessageId="benchmarkProfile.editor.title" />
        <PageHeader
          buttons={
            <Button onClick={toggleExpertMode} color="white">
              <FormattedMessage
                id={
                  expertMode
                    ? 'benchmarkProfile.editor.button.mode.expert'
                    : 'benchmarkProfile.editor.button.mode.normal'
                }
              />
            </Button>
          }
        />
        <Box flex={1} overflowX="hidden" overflowY="auto" pb={16}>
          {notification}
          <Box pb={4}>
            <Box borderRadius="sm" p={4} bgColor="secondary">
              <FormattedMessage id={infoMessage as I18nKey} />
            </Box>
          </Box>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialData}
            validationSchema={validationSchema}
          >
            {formikProps => (
              <ProfileForm
                {...formikProps}
                categories={fieldsByCategory as any}
                onCancel={goIndex}
                hasScores={hasScores}
                expertMode={expertMode}
              />
            )}
          </Formik>
        </Box>
      </Stack>
    </Gate>
  )
}

export const BenchmarkProfileEditor = () => {
  const { isInitializing } = useRecruitingBenchmarkProfiles()

  if (isInitializing) {
    return <CenteredSpinner />
  }

  return <BenchmarkProfileEditorInner />
}
