import { Box, Icon } from '@chakra-ui/react'
import { type MouseEventHandler } from 'react'
import { RiArrowDropDownLine, RiArrowDropRightLine } from 'react-icons/ri'

type TreeToggleProps = {
  onClick: MouseEventHandler<SVGElement>
  isIconVisible: boolean
  isOpen: boolean
}

export const TreeToggle = ({ onClick, isIconVisible, isOpen }: TreeToggleProps) => {
  const RiIcon = isOpen ? RiArrowDropDownLine : RiArrowDropRightLine

  return (
    <Box
      width="1.5em"
      height="1.5em"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Icon
        as={RiIcon}
        boxSize={6}
        color="secondary-dark"
        visibility={isIconVisible ? 'initial' : 'hidden'}
        cursor="pointer"
        sx={{ _hover: { color: 'primary' } }}
        onClick={onClick}
      />
    </Box>
  )
}
