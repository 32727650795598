import { Button, Center, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast, useModal } from '@repo/ui'
import { type SubmitHandler } from 'react-hook-form'

import { client } from '../../../../utils/openapi-client'
import { useRealtimeBuilderContext } from '../../shared/realtime-builder-context'
import { type BehavioralGoalFormValues } from '../../shared/types'
import { useStageStepperContext } from '../shared/stage-stepper-context'
import { useBehavioralGoalForm } from './use-behavioral-goal-form'

const BehavioralGoalCreateForm = ({ closeModal }: { closeModal: () => void }) => {
  const [form, formFieldsJsx] = useBehavioralGoalForm()
  const { activeStageIndex } = useStageStepperContext()

  const { programExtId, scenarioExtId, trainingExtId, mutateScenario } =
    useRealtimeBuilderContext()

  const { handleSubmit, formState } = form

  const onSubmit: SubmitHandler<BehavioralGoalFormValues> = async data => {
    try {
      const res = await client.post('createWinningBehaviorRealtime', {
        params: {
          path: {
            programExtId,
            scenarioExtId,
            trainingExtId,
          },
        },
        body: {
          behaviorType: 'positive',
          description: data.description,
          missionStageOrder: activeStageIndex,
          skillName: data.skillName,
        },
      })

      if (res.error) {
        throw new Error()
      }

      // TODO optimistic update
      await mutateScenario()

      showToast({ messageKey: 'common.alert.created', status: 'success' })
      closeModal()
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
      {formFieldsJsx}

      <Center gap={3} mt={10}>
        <Button variant="outline" onClick={closeModal}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          type="submit"
          isDisabled={!formState.isValid}
          isLoading={formState.isSubmitting}
        >
          <FormattedMessage id="common.create" />
        </Button>
      </Center>
    </Stack>
  )
}

export const useCreateBehavioralGoalModal = () =>
  useModal(BehavioralGoalCreateForm, {
    titleKey: 'behavioral.goal.modal.title',
    modalProps: { size: 'xl' },
  })
