import { Button, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FormattedMessage } from '@repo/i18n'
import { ClickableTr, colors, PageHeader } from '@repo/ui'
import { RiAddFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { AppHeaderTitle } from '../components/app-header-title'
import { CenteredSpinner } from '../components/centered-spinner'
import { Gate } from '../components/gate'
import { useRecruitingBenchmarkProfiles } from '../store/entities/benchmark-profiles/use-recruiting-benchmark-profiles'
import { formatDateTime } from '../utils/dates'
import { PageList } from './page-list'

const flexColumnCss = css`
  display: flex;
  flex-direction: column;
`

const TableContainer = styled.div`
  ${flexColumnCss}
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;
`

export const BenchmarkProfileList = () => {
  const navigate = useNavigate()
  const { benchmarkProfiles, isInitializing } = useRecruitingBenchmarkProfiles()

  if (isInitializing) {
    return <CenteredSpinner />
  }

  return (
    <Gate>
      <PageList display="flex" flexDirection="column">
        <AppHeaderTitle formattedMessageId="benchmarkProfile.list.title" />
        <PageHeader
          buttons={
            <Button
              leftIcon={<RiAddFill size="1.25em" />}
              color="white"
              onClick={() => navigate('create')}
              iconSpacing="1"
              flexShrink={0}
              alignSelf="flex-end"
              _hover={{
                bgColor: colors['primary-dark'],
                color: colors.buttonColor,
              }}
            >
              <FormattedMessage id="common.create" />
            </Button>
          }
        />
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <FormattedMessage id="common.name" />
                </Th>
                <Th>
                  <FormattedMessage id="common.type" />
                </Th>
                <Th>
                  <FormattedMessage id="benchmarkProfile.list.table.updated" />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {benchmarkProfiles.map(({ updatedAt, id, title, type }) => (
                <ClickableTr
                  key={id}
                  _hover={{ bgColor: colors.lightBlue }}
                  onClick={() => navigate(id)}
                >
                  <Td>{title} </Td>
                  <Td>{type}</Td>
                  <Td>{formatDateTime(updatedAt)} </Td>
                </ClickableTr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </PageList>
    </Gate>
  )
}
