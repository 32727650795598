import { useDispatch } from 'react-redux'
import { type ThunkDispatch } from 'redux-thunk'

import { createFolder } from '../store/entities/folders/effects'
import { type Folder } from './use-folder-types'

type CreateProps = {
  parent: string
  name: string
  benchmarkProfileId: string
}

export const useCreateFolder = () => {
  // To correctly type redux thunk would take to much time, TODO later
  const dispatch: ThunkDispatch<unknown, unknown, any> = useDispatch() // eslint-disable-line @typescript-eslint/no-explicit-any

  return ({ parent, ...data }: CreateProps) =>
    dispatch<Promise<Folder>>(createFolder(data, parent))
}
