import { Badge, HStack, Tooltip } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'

export const NoResultsCell = ({ isHiddenMetric }: { isHiddenMetric?: boolean }) => {
  const { formatMessage } = useIntl()

  return (
    <Tooltip
      hasArrow
      label={formatMessage({
        id: isHiddenMetric
          ? 'dashboard.cards.shared.tab.hidden.results.tooltip'
          : 'dashboard.cards.shared.tab.no.results.tooltip',
      })}
      bg={colors.secondary}
      color={colors.blue[500]}
      fontWeight="normal"
      placement="left"
    >
      <Badge
        colorScheme={isHiddenMetric ? 'gray' : 'yellow'}
        borderRadius="2px"
        textAlign="center"
        fontWeight={700}
        px={2}
      >
        <HStack gap={1}>
          <FormattedMessage
            id={
              isHiddenMetric
                ? 'dashboard.cards.shared.tab.hidden.results'
                : 'dashboard.cards.shared.tab.no.results'
            }
          />
        </HStack>
      </Badge>
    </Tooltip>
  )
}
