import { Stack } from '@chakra-ui/react'
import { type ApiSchemas } from '@repo/api'
import { RHF, showToast, useFormRules } from '@repo/ui'
import { useCallback } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { type IconType } from 'react-icons'
import { IoCall, IoVideocam } from 'react-icons/io5'

import { StickyFooter } from '../../../../shared/sticky-footer'
import { useSelectOptions } from '../../../../shared/use-select-options'
import { useRealtimeBuilderContext } from '../../../shared/realtime-builder-context'
import { useInteractionStepContext } from '../interaction-step-context'

const personaTraineeRelationships = [
  'initialContact',
  'transactionalInteraction',
  'wellEstablishedRelationship',
  'longTermLoyalCustomer',
  'workColleague',
  'closeColleague',
  'professionalMentor',
  'directManager',
] as const

const interlocutorRolesNotInternal = ['existingCustomer', 'newCustomer'] as const
const interlocutorRolesInternal = ['manager', 'teamMember', 'colleague'] as const

type FormValues = {
  degreeOfRelationship: string
  interlocutorRole: string
  realtimeCallType: ApiSchemas['realtimeCallType']
}

const callTypes: Array<{
  value: ApiSchemas['realtimeCallType']
  icon: IconType
}> = [
  { value: 'videoCall', icon: IoVideocam },
  { value: 'phoneCall', icon: IoCall },
]

export const StepConversationDynamics = () => {
  const rules = useFormRules()
  const { scenario, goToStep, mutateScenario, updateScenarioDetails, isBuilderReadonly } =
    useRealtimeBuilderContext()

  const { control, formState, handleSubmit } = useForm<FormValues>({
    mode: 'onTouched',
    disabled: isBuilderReadonly,
    defaultValues: {
      degreeOfRelationship: scenario.degreeOfRelationship ?? '',
      interlocutorRole: scenario.interlocutorRole ?? '',
      realtimeCallType: scenario.realtimeCallType ?? 'videoCall',
    },
  })

  const { goToNextStep } = useInteractionStepContext()

  const { getSelectOptions } = useSelectOptions()

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async values => {
      if (!formState.isDirty) {
        return goToNextStep()
      }

      const res = await updateScenarioDetails({
        degreeOfRelationship: values.degreeOfRelationship,
        interlocutorRole: values.interlocutorRole,
        realtimeCallType: values.realtimeCallType,
      })

      if (res.error) {
        return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }

      mutateScenario(res.data, false)
      goToNextStep()
    },
    [formState.isDirty, goToNextStep, updateScenarioDetails, mutateScenario]
  )

  if (!scenario.persona) {
    throw new Error('Persona should be selected at this point, there should be an error')
  }

  return (
    <Stack as="form" flex={1} onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={16} maxW="800px">
        <RHF.AutocompleteSelect
          control={control}
          name="degreeOfRelationship"
          labelKey="scenario.personaTraineeRel.input.label"
          rules={rules.required}
          componentProps={{
            placeholderKey: 'scenario.personaTraineeRel.input.placeholder',
            options: getSelectOptions(
              personaTraineeRelationships,
              'scenario.personaTraineeRel',
              true
            ),
          }}
          tooltipKey="scenario.personaTraineeRel.degreeOfRelationship.tooltip.text"
        />

        <RHF.AutocompleteSelect
          control={control}
          name="interlocutorRole"
          labelKey="scenario.manual.builder.dynamics.step.interlocutor.role.label"
          rules={rules.required}
          componentProps={{
            placeholderKey: 'scenario.interlocutorRole.input.placeholder',
            options:
              scenario.persona.conversationContext === 'internal'
                ? getSelectOptions(
                    interlocutorRolesInternal,
                    'scenario.interlocutorRole',
                    true
                  )
                : getSelectOptions(
                    interlocutorRolesNotInternal,
                    'scenario.interlocutorRole',
                    true
                  ),
          }}
        />

        <RHF.RadioButtonGroup
          control={control}
          name="realtimeCallType"
          labelKey="scenario.builder.realtime.callType.label"
          rules={rules.required}
          componentProps={{
            options: callTypes.map(callType => ({
              value: callType.value,
              labelKey:
                `scenario.builder.realtime.callType.${callType.value}` satisfies I18nKey,
              icon: callType.icon,
            })),
          }}
        />
      </Stack>

      <StickyFooter
        onBack={() => goToStep('trainee')}
        onNext={isBuilderReadonly ? goToNextStep : undefined}
        isNextDisabled={!isBuilderReadonly && !formState.isValid}
        isNextLoading={formState.isSubmitting}
      />
    </Stack>
  )
}
