import { useCallback, useEffect, useState } from 'react'

import { getHttpLink } from '../firebase'

export const useGoogleStorageAsset = (mediaUrl: string | undefined | null) => {
  const [mediaHttpUrl, setMediaHttpUrl] = useState<string | undefined>(undefined)
  const getMediaHttpLink = useCallback(async (url?: string | null) => {
    if (url) {
      const httpLink = await getHttpLink(url)

      setMediaHttpUrl(httpLink)
    } else {
      setMediaHttpUrl('')
    }
  }, [])

  useEffect(() => {
    if (mediaUrl?.startsWith('https')) {
      setMediaHttpUrl(mediaUrl)
    } else {
      getMediaHttpLink(mediaUrl)
    }

    return () => {
      setMediaHttpUrl('')
    }
  }, [getMediaHttpLink, mediaUrl])

  return mediaHttpUrl
}
