import { IconButton, Input, type InputProps, InputRightElement } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { useFormikContext } from 'formik'
import { memo, useMemo, useState } from 'react'
import { BiHide, BiShow } from 'react-icons/bi'

import { FormControlInputField } from '../form/form-control-input-field'

export type ResetPasswordFormValue = {
  firstName: string
  email: string
  password: string
}

type ResetPasswordFormProps = Pick<InputProps, 'size'>

export const ResetPasswordForm = memo(({ size }: ResetPasswordFormProps) => {
  const { formatMessage } = useIntl()
  const [isHidden, setIsHidden] = useState(true)
  const { isSubmitting, touched, errors } = useFormikContext<ResetPasswordFormValue>()
  const showHideButton = useMemo(
    () => (
      <InputRightElement h="full">
        <IconButton
          aria-label="Show password"
          onClick={() => setIsHidden(!isHidden)}
          icon={isHidden ? <BiShow /> : <BiHide />}
          variant="ghost"
          tabIndex={-1}
        />
      </InputRightElement>
    ),
    [isHidden]
  )

  const emailInputProps = useMemo<InputProps>(
    () => ({
      size,
      type: 'email',
      isDisabled: true,
      variant: 'filled',
    }),
    [size]
  )

  const firstNameInputProps = useMemo<InputProps>(
    () => ({
      size,
      isDisabled: true,
      variant: 'filled',
    }),
    [size]
  )

  const passwordInputProps = useMemo<InputProps>(
    () => ({
      size,
      type: isHidden ? 'password' : 'text',
      placeholder: formatMessage({ id: 'signup.form.field.password.placeholder' }),
      isDisabled: isSubmitting,
      isRequired: true,
      variant: 'filled',
    }),
    [size, isHidden, formatMessage, isSubmitting]
  )

  return (
    <>
      <FormControlInputField<ResetPasswordFormValue>
        prop="firstName"
        inputType={Input}
        label={formatMessage({ id: 'signup.form.field.firstName.label' })}
        inputProps={firstNameInputProps}
      />
      <FormControlInputField<ResetPasswordFormValue>
        prop="email"
        inputType={Input}
        label={formatMessage({ id: 'signup.form.field.email.label' })}
        inputProps={emailInputProps}
      />
      <FormControlInputField<ResetPasswordFormValue>
        errors={errors}
        inputType={Input}
        touched={touched}
        prop="password"
        label={formatMessage({ id: 'signup.form.field.password.label' })}
        errorMessageKey={errors.password}
        inputProps={passwordInputProps}
      >
        {showHideButton}
      </FormControlInputField>
    </>
  )
})
