import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

import { InviteForm } from '../invite/invite-form'

export const MemberInviteModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <FormattedMessage id="members.invite.title" />
      </ModalHeader>

      <ModalCloseButton />

      <ModalBody>
        <InviteForm />
      </ModalBody>

      <ModalFooter />
    </ModalContent>
  </Modal>
)
