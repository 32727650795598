import { Badge, Box, Stack, Stepper, Text } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, RHF, useFormRules } from '@repo/ui'
import { useForm } from 'react-hook-form'

import { useRealtimeBuilderContext } from '../../../../shared/realtime-builder-context'
import { SkillSelectAutocomplete } from '../../../../shared/skill-select-autocomplete'
import { BehaviorButtonGroup } from '../../../shared/behavior-button-group'
import { useStageStepperContext } from '../../../shared/stage-stepper-context'
import { MAX_INSTRUCTIONS_PER_STAGE } from './constants'
import { AttachReference } from './instruction-form/attach-reference'
import { InstructionFormStep } from './instruction-form/instruction-form-step'

// reference and reference in hows are array of values because we want to use it with the FormFieldArray component
export type InstructionFormValues = {
  description: string
  skillName: string
  reference: Array<{ value?: string | null }>
  hows: Array<{ description: string; reference: Array<{ value?: string | null }> }>
}

const MAX_INSTRUCTION_HOWS = 3

export const useInstructionForm = (defaultValues?: InstructionFormValues) => {
  const form = useForm<InstructionFormValues>({
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          hows:
            defaultValues.hows.length > 0
              ? defaultValues.hows
              : [{ description: '', reference: [] }],
        }
      : {
          description: '',
          skillName: '',
          reference: [],
          hows: [{ description: '', reference: [] }],
        },
    mode: 'onTouched',
  })

  const { formatMessage } = useIntl()
  const rules = useFormRules()
  const { scenario, program } = useRealtimeBuilderContext()
  const { activeStageIndex } = useStageStepperContext()

  const { control } = form

  const instructions = scenario.missionStages?.[activeStageIndex]?.instructions ?? []

  const formFieldsJxs = (
    <Stack spacing={2}>
      <Text fontSize="14px" fontWeight={500} mb={4}>
        <FormattedMessage id="scenario.builder.realtime.steps.instructions.keyDetails.title" />
      </Text>
      <Stepper size="sm" index={-1} orientation="vertical" gap={0}>
        <InstructionFormStep stepKey={0} showSeparator>
          <Stack w="100%">
            <RHF.TextArea
              control={control}
              name="description"
              labelKey="behavioral.goal.form.description"
              componentProps={{
                placeholder: formatMessage({
                  id: 'behavioral.goal.form.description.placeholder',
                }),
              }}
              rules={{
                ...rules.stringShortRequired,
                validate: {
                  ...rules.stringShortRequired.validate,
                  duplicate: value =>
                    rules.validateDuplicatedText({
                      value,
                      allValues: instructions.map(instruction => instruction.description),
                      shouldMatchTwice: false,
                      ignoredValue: defaultValues?.description,
                    }),
                },
              }}
            />
            <AttachReference form={form} inputName="reference" />
          </Stack>
        </InstructionFormStep>
        <InstructionFormStep stepKey={1} showSeparator>
          <BehaviorButtonGroup
            labelKey="scenario.builder.realtime.steps.instructions.typeLabel"
            currentItemCount={instructions.length}
            maxItemCount={MAX_INSTRUCTIONS_PER_STAGE}
            onClick={() => {
              // TODO: Nothing happens until we enable avoided behaviors
            }}
          />
        </InstructionFormStep>
        <InstructionFormStep stepKey={2}>
          <Box>
            <SkillSelectAutocomplete
              control={control}
              name="skillName"
              language={program.language}
            />
          </Box>
        </InstructionFormStep>
      </Stepper>
      <RHF.FieldArray
        control={control}
        labelKey="scenario.builder.realtime.steps.instructions.howTo.label"
        helperTextKey="scenario.builder.realtime.steps.instructions.howTo.helper"
        name="hows"
        maxItems={MAX_INSTRUCTION_HOWS}
        minItems={1}
        addNewButtonKey="scenario.builder.realtime.steps.instructions.howTo.addNew"
        defaultValue={{ description: '', reference: [] }}
        arrayItemSx={{
          border: '1px solid',
          borderColor: colors.blue[100],
          borderRadius: 8,
          p: '24px',
        }}
        addNewButtonProps={{
          alignSelf: 'center',
        }}
        renderField={index => (
          <Stack width="100%" spacing={2}>
            <Badge colorScheme="teal" variant="subtle" maxWidth="fit-content" mb={4}>
              <FormattedMessage id="common.howTo" />
            </Badge>
            <RHF.TextArea
              control={control}
              name={`hows.${index}.description`}
              labelKey="scenario.builder.realtime.steps.instructions.howTo.description.label"
              rules={{
                ...rules.stringCustomLength(1000),
                deps: ['hows'],
                validate: (value, values) =>
                  rules.validateDuplicatedText({
                    value,
                    allValues: values.hows.map(s => s.description),
                    shouldMatchTwice: true,
                  }),
              }}
              componentProps={{
                placeholder: formatMessage({
                  id: 'scenario.builder.realtime.steps.instructions.howTo.placeholder',
                }),
              }}
            />
            <AttachReference form={form} inputName={`hows.${index}.reference`} />
          </Stack>
        )}
      />
    </Stack>
  )

  return [form, formFieldsJxs] as const
}
