import { VStack } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { BsFillCameraVideoFill } from 'react-icons/bs'

import { type SubFormContent } from '../../../../../builder/types'
import { RecordIntro } from './record-intro'
import { StepFormButton } from './step-form-button'

export const VideoStep = ({ disabled }: Partial<SubFormContent>) => {
  const { formatMessage } = useIntl()

  return (
    <VStack
      display="flex"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      mt={4}
      align="stretch"
      w="full"
      minH="inherit"
    >
      <RecordIntro
        disabled={disabled}
        title={formatMessage({ id: 'common.video' })}
        description={formatMessage({ id: 'step.video.intro.description' })}
        buttonUploadText={formatMessage({ id: 'step.video.intro.upload' })}
        icon={BsFillCameraVideoFill}
        fieldName="step.videoStepUrl"
      />
      <StepFormButton />
    </VStack>
  )
}
