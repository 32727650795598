import { useIntl } from '@repo/i18n'
import { PersonaThumbnail } from '@repo/ui'

import { useGoogleStorageAsset } from '../../../../hooks/use-google-storage-asset'
import { useOpenapiSWR } from '../../../../utils/use-openapi-swr'
import { SteppedModalPreviewArea } from '../../stepped-modal-preview-area'
import { useScenarioBuilderData } from '../../use-scenario-builder-data'
import { usePersonaFormContext } from './persona-form-context'
import { ageRangeValues } from './shared/age-range-values'

export const PersonaFormPreview = () => {
  const { formData } = usePersonaFormContext()

  const [, programSWR] = useScenarioBuilderData()
  const programLanguage = programSWR.data?.language

  const { formatMessage } = useIntl()

  const { demographic, character, look } = formData

  if (!programLanguage) {
    throw new Error('Program language is not set')
  }

  const { data } = useOpenapiSWR('getAvatarsAndVoices', {
    query: { language: programLanguage },
  })

  const { avatars } = data ?? {}
  const selectedAvatar = avatars?.find(
    avatar => avatar.extId === look?.videoGenerationAvatarExtId
  )

  return (
    <SteppedModalPreviewArea
      imageContent={
        look ? (
          <PersonaThumbnail
            bgUrl={look.backgroundUrl}
            avatarPreviewUrl={selectedAvatar?.imageUrl}
            useGoogleStorageAsset={useGoogleStorageAsset}
          />
        ) : null
      }
      imagePlaceholderKey="scenario.builder.ai.steps.conversation-partner.personaModal.personaPreview"
      title={demographic?.name}
      titlePlaceholderKey="common.name"
      propertyCards={[
        {
          labelKey: 'common.age',
          value: demographic?.age ? ageRangeValues[demographic.age] : undefined,
        },
        {
          labelKey: 'common.sex',
          value: demographic?.sex
            ? formatMessage({
                id: `persona.sex.${demographic.sex}` satisfies I18nKey,
              })
            : undefined,
        },
        {
          labelKey: 'common.personality',
          value: character?.personality,
        },
        {
          labelKey: 'common.attitude',
          value: character?.attitude
            ? formatMessage({
                id: `persona.attitude.${character.attitude}` satisfies I18nKey,
              })
            : undefined,
        },
      ]}
    />
  )
}
