import { object, type SchemaOf, string } from 'yup'

import { type BenchmarkProfile } from '../types/benchmark-profile'

export const benchmarkProfileFormValidator: SchemaOf<Pick<BenchmarkProfile, 'title'>> =
  object().shape({
    title: string()
      .required('benchmarkProfile.form.validation.name.required')
      .min(3, 'benchmarkProfile.form.validation.name.min')
      .max(100, 'benchmarkProfile.form.validation.name.max'),
  })
