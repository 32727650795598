import { Stack } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { RHF, useFormRules } from '@repo/ui'
import { useForm } from 'react-hook-form'

import { type TrainingFormValues } from '../shared/types'

export const useTrainingForm = (defaultValues?: TrainingFormValues) => {
  const form = useForm<TrainingFormValues>({
    defaultValues: defaultValues ?? { description: '', title: '' },
    mode: 'onTouched',
  })

  const { formatMessage } = useIntl()
  const rules = useFormRules()

  const { control } = form

  const formFieldsJsx = (
    <Stack gap={6}>
      <RHF.Input
        control={control}
        name="title"
        labelKey="program.title"
        rules={rules.stringShortRequired}
        componentProps={{
          placeholder: formatMessage({ id: 'program.title.placeholder' }),
        }}
      />
      <RHF.TextArea
        control={control}
        name="description"
        labelKey="program.introduction.label"
        componentProps={{
          placeholder: formatMessage({ id: 'program.description.placeholder' }),
        }}
        rules={rules.stringLong}
      />
    </Stack>
  )

  return [form, formFieldsJsx] as const
}
