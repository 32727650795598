import { Alert, AlertIcon, Button } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { AiFillInfoCircle } from 'react-icons/ai'
import { IoReload } from 'react-icons/io5'

export const UpdateAllVideosButton = ({
  onClick,
  isLoading,
}: {
  onClick: () => void
  isLoading: boolean
}) => (
  <Alert status="info" fontSize={12} width="fit-content" py={2} mb={5}>
    <AlertIcon as={AiFillInfoCircle} boxSize="20px" color={colors.blue['500']} />
    <FormattedMessage id="scenario.builder.ai.steps.review.update.alert.text" />
    <Button onClick={onClick} isLoading={isLoading} ml={6} leftIcon={<IoReload />}>
      <FormattedMessage id="scenario.builder.ai.steps.review.update.alert.button" />
    </Button>
  </Alert>
)
