import { motion } from 'framer-motion'
import { type PropsWithChildren, useMemo } from 'react'

type AnimatedNodeProps = {
  isOpen: boolean
}

export const AnimatedNode = ({
  children,
  isOpen,
}: PropsWithChildren<AnimatedNodeProps>) => {
  const animateValue = useMemo(
    () => ({
      height: isOpen ? 'auto' : 0,
      opacity: isOpen ? 1 : 0,
    }),
    [isOpen]
  )

  return (
    <motion.div
      animate={animateValue}
      transition={{ ease: 'easeInOut', duration: 0.2 }}
      style={{ overflow: 'hidden' }}
    >
      {children}
    </motion.div>
  )
}
