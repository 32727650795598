import { Box, Button, Heading, HStack, Text } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { FormikFormField, NumberInput as NumberInputChakra } from '@repo/ui'
import { Form, useFormikContext } from 'formik'

import { type CohortFormValues } from '../types/cohort-form-values'

const MAX_NUMBER_OF_TOKENS = 200

export const CohortForm = ({
  mode,
  showActionButtons = true,
}: {
  mode: 'create' | 'edit' | 'addTokens'
  showActionButtons?: boolean
}) => {
  const { formatMessage } = useIntl()
  const { isValid, dirty, errors, values, setFieldValue, isSubmitting } =
    useFormikContext<CohortFormValues>()

  const showNameInput = mode === 'create' || mode === 'edit'
  const showTokenInput = mode === 'create' || mode === 'addTokens'

  const handleOnBlurNumberOfTokens = () => {
    if (values.numberTokens === '') {
      setFieldValue('numberTokens', 0)
    }
  }

  return (
    <Form>
      {showNameInput && (
        <Box pb={4}>
          <FormikFormField
            errors={errors.name && formatMessage({ id: errors.name as I18nKey })}
            label={formatMessage({ id: 'cohort.form.name.title' })}
            subLabel={formatMessage({ id: 'cohort.form.name.subtitle' })}
            name="name"
            placeholder={formatMessage({ id: 'cohort.form.name.placeholder' })}
          />
        </Box>
      )}
      {showTokenInput && (
        <>
          <Heading as="h6" color="gray.600" margin="3px" size="xs">
            {formatMessage({ id: 'cohort.form.number.participants.title' })}
          </Heading>

          <Text mx="3px" my={2} size="xs">
            {formatMessage({
              id: 'cohort.form.number.participants.subtitle',
            })}
          </Text>
          <NumberInputChakra
            id="numberTokens"
            value={values.numberTokens}
            defaultValue={0}
            min={0}
            max={MAX_NUMBER_OF_TOKENS}
            maxW="100px"
            inputMode="numeric"
            onChange={value =>
              setFieldValue('numberTokens', value ? Number.parseInt(value, 10) : '')
            }
            onBlur={handleOnBlurNumberOfTokens}
          />
        </>
      )}
      {showActionButtons && (
        <HStack mt={12} spacing={4} justify="center">
          <Button variant="outline" type="reset">
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
            isDisabled={
              !(dirty && isValid) ||
              (mode === 'addTokens' &&
                (values.numberTokens === 0 || values.numberTokens === ''))
            }
          >
            {mode === 'create' && <FormattedMessage id="cohort.form.create.submit" />}
            {mode === 'edit' && <FormattedMessage id="cohort.form.update.submit" />}
            {mode === 'addTokens' && (
              <FormattedMessage id="cohort.form.addTokens.submit" />
            )}
          </Button>
        </HStack>
      )}
    </Form>
  )
}
