import { colors } from '@repo/ui'
import { type IconType } from 'react-icons'
import { IoTrophy } from 'react-icons/io5'
import { MdCampaign } from 'react-icons/md'

import { type ProgramTemplate } from '../../../shared/types'

export const templateTagVariants: Record<
  NonNullable<ProgramTemplate['tag']>,
  {
    labelKey: I18nKey
    color: string
    icon: IconType
  }
> = {
  new: {
    color: colors.indicator.green,
    icon: MdCampaign,
    labelKey: 'coaching.program.templates.card.tag.new',
  },
  popular: {
    color: colors.indicator.yellow,
    icon: IoTrophy,
    labelKey: 'coaching.program.templates.card.tag.popular',
  },
}
