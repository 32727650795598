import { HStack, Icon, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { BsXCircleFill } from 'react-icons/bs'
import { MdCheckCircle } from 'react-icons/md'

import { type SharedProgram } from '../types/api-types'

type SharedProgramStatusBadgeProps = {
  status: SharedProgram['status']
}

export const SharedProgramStatusBadge = ({ status }: SharedProgramStatusBadgeProps) => {
  switch (status) {
    case 'rejected':
      return (
        <HStack gap={1}>
          <Icon as={BsXCircleFill} boxSize="12px" color={colors.red[400]} />
          <Text color={colors.red[400]} fontSize="12px" fontWeight={500}>
            <FormattedMessage id="coaching.program.send.modal.tabs.requestsStatus.rejected" />
          </Text>
        </HStack>
      )
    case 'expired':
      return (
        <Text color={colors.gray[700]} fontSize="12px" fontWeight={500}>
          <FormattedMessage id="coaching.program.send.modal.tabs.requestsStatus.expired" />
        </Text>
      )
    case 'accepted':
      return (
        <HStack gap={1}>
          <Icon as={MdCheckCircle} boxSize="12px" color={colors.green[400]} />
          <Text color={colors.green[400]} fontSize="12px" fontWeight={500}>
            <FormattedMessage id="coaching.program.send.modal.tabs.requestsStatus.accepted" />
          </Text>
        </HStack>
      )
    default:
      return null
  }
}
