import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { usePromisingModal } from '@repo/ui'
import { type PropsWithChildren } from 'react'

import { type Tone } from './types'

const ToneButton = ({
  onClick,
  children,
  isMatch,
}: PropsWithChildren<{
  isMatch?: boolean
  onClick: () => void
}>) => (
  <Button
    size="xs"
    borderRadius="full"
    px={4}
    variant={isMatch ? 'solid' : 'outline'}
    onClick={onClick}
  >
    {children}
  </Button>
)

export const ToneSelectionButtons = ({
  currentInputValue,
  setInputValue,
  tones,
}: {
  tones: Array<Tone>
  currentInputValue: string
  setInputValue: (value: string) => void
}) => {
  const [modal, confirmSure] = usePromisingModal<boolean>(
    ({ closeModal, resolve }) => (
      <Box>
        <Text>
          <FormattedMessage id="scenario.ai.builder.replaceContentModal.description" />
        </Text>
        <HStack justify="flex-end" mt={16}>
          <Button variant="outline" flex={1} onClick={closeModal}>
            <FormattedMessage id="scenario.ai.builder.replaceContentModal.no" />
          </Button>
          <Button flex={1} onClick={() => resolve(true)}>
            <FormattedMessage id="scenario.ai.builder.replaceContentModal.yes" />
          </Button>
        </HStack>
      </Box>
    ),
    { titleKey: 'scenario.ai.builder.replaceContentModal.title' }
  )

  const matchingToneKey = tones.find(s => s.value === currentInputValue)?.labelKey

  const handleToneClick = async (clickedTone: Tone | 'custom') => {
    if (clickedTone === 'custom') {
      if (matchingToneKey) {
        return setInputValue('')
      }

      return
    }

    const hasCustomValue = !!currentInputValue && !matchingToneKey

    if (hasCustomValue) {
      const isSure = await confirmSure()

      if (!isSure) {
        return
      }
    }

    setInputValue(clickedTone.value)
  }

  return (
    <Stack gap={3} mb={2}>
      {modal}
      <HStack>
        <Text fontSize={14}>
          <FormattedMessage id="common.tone.select" />
        </Text>
        {tones.map(tone => {
          const isMatch = matchingToneKey === tone.labelKey

          return (
            <ToneButton
              key={tone.labelKey}
              isMatch={isMatch}
              onClick={() => handleToneClick(tone)}
            >
              <FormattedMessage id={tone.labelKey} />
            </ToneButton>
          )
        })}

        <ToneButton
          isMatch={!matchingToneKey}
          onClick={() => {
            handleToneClick('custom')
          }}
        >
          <FormattedMessage id="common.custom" />
        </ToneButton>
      </HStack>
    </Stack>
  )
}
