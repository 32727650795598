import { SimpleGrid, Stack } from '@chakra-ui/react'
import { SectionHeader } from '@repo/ui'
import { type PropsWithChildren } from 'react'

export const DashboardSection = ({
  children,
  titleKey,
  subtitleKey,
}: PropsWithChildren<{ titleKey?: I18nKey; subtitleKey?: I18nKey }>) => (
  <Stack gap={5}>
    <SectionHeader titleKey={titleKey} subtitleKey={subtitleKey} />
    <SimpleGrid
      templateColumns={{
        base: '1fr 1fr',
        lg: 'repeat(auto-fill, minmax(150px, 1fr))',
      }}
      gap={5}
    >
      {children}
    </SimpleGrid>
  </Stack>
)
