import { Alert, AlertDescription, AlertIcon, CloseButton } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { type MouseEventHandler } from 'react'

type FetchErrorAlertProps = {
  onCloseClick?: MouseEventHandler<HTMLButtonElement>
}

export const FetchErrorAlert = ({ onCloseClick }: FetchErrorAlertProps) => (
  <Alert status="error" mb={4}>
    <AlertIcon />
    <AlertDescription>
      <FormattedMessage id="recording.error.fetchError" />
    </AlertDescription>
    {onCloseClick && (
      <CloseButton position="absolute" right="8px" top="8px" onClick={onCloseClick} />
    )}
  </Alert>
)
