export const assertValueEqualsTarget: {
  <TValue, TTarget extends TValue>(
    value: TValue,
    target: TTarget
  ): asserts value is TTarget
} = (value, target) => {
  if (value !== target) {
    throw new Error(`assertion failed. ${value} should be ${target}`)
  }
}
