import { calculateVideoLength } from '@repo/utils'
import { getDownloadURL, ref } from 'firebase/storage'
import { useField } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { type ErrorStates } from '../../../../../components/upload-error'
import {
  firebaseUsersStorage,
  getHttpLink,
  uploadMediaFile,
} from '../../../../../firebase'
import { useOrganization } from '../../../../../hooks/use-organization'
import { MAX_VIDEO_UPLOAD_SIZE } from '../../../../../utils/constants'
import { useScenarioBuilderData } from '../../../use-scenario-builder-data'

export const useVideoUploader = (
  name: string,
  sizeLimit: number = MAX_VIDEO_UPLOAD_SIZE,
  disabledField = false,
  videoDurationLimitInSeconds?: number
) => {
  const [{ scenarioExtId }] = useScenarioBuilderData()
  const { organization } = useOrganization()
  const [fileUploaded, setFileUploaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [videoHttpUrl, setVideoHttpUrl] = useState<string | null>(null)
  const [videoUrlField, , videoUrlHelpers] = useField(name)
  const { value: videoUrl } = videoUrlField
  const { setValue: setVideoUrlFormValue } = videoUrlHelpers
  const [error, setError] = useState<ErrorStates | null>(null)

  const setVideoUrl = useCallback((value: string | null) => {
    setVideoHttpUrl(value)
    setLoading(false)
  }, [])

  useEffect(() => {
    const getUrl = async () => {
      const url = await getDownloadURL(ref(firebaseUsersStorage, videoUrl))

      setVideoUrl(url)
    }

    setLoading(true)
    if (!videoUrl) {
      setVideoUrl(null)
    } else if (videoUrl.startsWith('https://')) {
      setVideoUrl(videoUrl)
    } else {
      getUrl()
    }

    return () => {
      setVideoUrl(null)
    }
  }, [setVideoUrl, videoUrl])

  const handleVideoUpload = async event => {
    const video = event.target.files[0]
    // clear the Input element state

    event.target.value = null
    if (video.size > sizeLimit) {
      setError('maxUploadSize')

      return
    }

    const videoDurationInSeconds = await calculateVideoLength(video)

    if (
      videoDurationLimitInSeconds &&
      videoDurationInSeconds > videoDurationLimitInSeconds
    ) {
      setError('maxDuration')

      return
    }

    setLoading(true)

    if (!organization) {
      console.error('Organization is not defined')

      return
    }

    const videoGSUrl = await uploadMediaFile(video, organization.id)
    const videoHttpLink = await getHttpLink(videoGSUrl)

    setVideoUrlFormValue(videoGSUrl)
    setVideoHttpUrl(videoHttpLink)
    setLoading(false)
    setFileUploaded(true)
  }

  const clearError = () => {
    setError(null)
  }

  const isFormControlDisabled = useMemo(
    () => !scenarioExtId || scenarioExtId === 'new' || disabledField,
    [disabledField, scenarioExtId]
  )

  return {
    error,
    loading,
    videoHttpUrl,
    handleVideoUpload,
    setVideoUrlFormValue,
    clearError,
    isFormControlDisabled,
    fileUploaded,
  }
}
