import { Box, Flex, Spacer } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { useMemo } from 'react'

import { CustomLineChart } from '../shared/custom-line-chart'
import { DashboardCard } from '../shared/dashboard-card'
import { useDashboardCardData } from '../shared/use-dashboard-card-data'
import { DetailPageLink } from './shared/detail-page-link'
import { NoDataInformation } from './shared/no-data-information'

export const RecordingCountCard = () => {
  const { dataSlotSelector, dataSlot } = useDashboardCardData('recordingCount')

  const [chartData, _totalRecordingsCount] = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot
    const dataField = data.recordingsAccOverallPerTimestamp

    if (!dataField.length) {
      return []
    }

    return [
      data.startDatesFormatted.map((date, index) => ({
        date,
        // do not show same values consecutively
        recordingsOverall:
          index > 0 &&
          index < dataField.length - 1 &&
          dataField[index - 1] === dataField[index] &&
          dataField[index] === dataField[index + 1]
            ? null
            : dataField[index]!,
      })),
      dataField[dataField.length - 1],
    ]
  }, [dataSlot])

  return (
    <DashboardCard
      titleKey="dashboard.cards.recordingCount.title"
      descriptionKey="dashboard.cards.recordingCount.subtitle"
      colSpan={3}
      wrapperProps={{ pt: 5, flex: 1 }}
      dataSlotSelector={dataSlotSelector}
      isLoading={dataSlot.status !== 'loaded'}
    >
      {chartData?.length ? (
        <Flex flexDir="column" flex={1}>
          <Box height={300}>
            <CustomLineChart
              data={chartData}
              dataConfig={[{ dataKey: 'recordingsOverall', color: colors.blue[400] }]}
            />
          </Box>
          <Spacer mt={6} />
          {/* totalRecordingsCount && (
            <GreenInsightsAlert
              messageKey="dashboard.cards.recordingCount.alert.description"
              messageKeyProp="recordingCount"
              totalCount={totalRecordingsCount}
            />
          ) */}
          <DetailPageLink page="video_count_details" />
        </Flex>
      ) : (
        <NoDataInformation />
      )}
    </DashboardCard>
  )
}
