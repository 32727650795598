import {
  Box,
  Button,
  HStack,
  Icon,
  useRadio,
  useRadioGroup,
  type UseRadioGroupProps,
  type UseRadioProps,
} from '@chakra-ui/react'
import { FormattedMessage, type ReactNodeOrI18nKey } from '@repo/i18n'
import { type PropsWithChildren } from 'react'
import { type IconType } from 'react-icons'

const CustomRadioButton = ({
  children,
  icon,
  ...rest
}: PropsWithChildren<UseRadioProps & { icon?: IconType }>) => {
  const { getInputProps, getRadioProps, state } = useRadio(rest)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as="label">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...input} hidden />
      <Button
        {...checkbox}
        as={Box}
        cursor="pointer"
        variant={state.isChecked ? 'solid' : 'outline'}
        leftIcon={icon ? <Icon as={icon} /> : undefined}
      >
        {children}
      </Button>
    </Box>
  )
}

type Option = {
  value: string
  icon?: IconType
} & ReactNodeOrI18nKey<'label', true>

export type RadioButtonGroupProps = {
  radioGroupProps: UseRadioGroupProps
  options: Array<Option>
}

export const RadioButtonGroup = ({ options, radioGroupProps }: RadioButtonGroupProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup(radioGroupProps)

  const group = getRootProps()

  return (
    <HStack w="max-content" {...group}>
      {options.map(option => (
        <CustomRadioButton
          key={option.value}
          isDisabled={radioGroupProps.isDisabled}
          icon={option.icon}
          {...getRadioProps({ value: option.value })}
        >
          {option.labelKey ? <FormattedMessage id={option.labelKey} /> : option.label}
        </CustomRadioButton>
      ))}
    </HStack>
  )
}
