import { Badge, Button, HStack, Stack } from '@chakra-ui/react'
import { colors, RHF, useFormRules } from '@repo/ui'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { RHFImageUploader } from '../rhf-image-uploader'
import { ComponentSection } from './shared/component-section'

type FormValues = {
  firstName: string
  lastName: string
  favoriteNumber: number
  age: number
  information: string
  favoriteColor: string
  profilePicture: string
  country: string
  game: string
  stack: string
  skills: Array<{ value: string }>
  users: Array<{ name: string; email: string }>
  markdownContent: string
  consent: boolean
}

const sleep = (ms: number) =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })

const countries = ['Nigeria', 'Japan', 'India', 'United States', 'South Korea']

const games = [
  'God of War',
  'The Legend of Zelda',
  'Final Fantasy',
  'Super Mario',
  'Red Dead Redemption',
]

const stacks = ['Frontend', 'Backend', 'Fullstack']

export const RHFSection = () => {
  const { control, formState, handleSubmit, reset, setError, clearErrors } =
    useForm<FormValues>({
      mode: 'onTouched',
      defaultValues: {
        firstName: '',
        lastName: '',
        age: 35,
        favoriteNumber: 0,
        favoriteColor: '',
        information: '',
        profilePicture: '',
        country: '',
        game: '',
        stack: '',
        skills: [{ value: '' }],
        users: [{ name: '', email: '' }],
        markdownContent: '# Hello World\n\nThis is a markdown editor example.',
        consent: false,
      },
    })

  const rules = useFormRules()

  const onSubmit: SubmitHandler<FormValues> = async data => {
    await sleep(1000)

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(data, null, 2))
  }

  return (
    <ComponentSection
      title="RHF Form Fields"
      subtitle="These are the form fields we wrapped to easily use with RHF (react-hook-forms). Check the code to see a complete form example with validation, errors, submit handling etc."
    >
      <Stack spacing={4} as="form" onSubmit={handleSubmit(onSubmit)}>
        <RHF.Markdown
          control={control}
          name="markdownContent"
          label="Markdown Editor"
          componentProps={{
            height: 250,
            textareaProps: {
              placeholder: 'Write your markdown content here...',
            },
          }}
        />

        <RHF.Input
          control={control}
          name="firstName"
          label="Input"
          componentProps={{ placeholder: 'First Name' }}
        />

        <RHF.Input
          tooltipKey="common.name"
          control={control}
          name="lastName"
          label="Input - with validation rules and tooltip"
          rules={{
            required: rules.primitive.required,
            maxLength: rules.primitive.maxLength(6),
            minLength: rules.primitive.minLength(2),
          }}
          componentProps={{ placeholder: 'Last Name' }}
        />

        <RHF.NumberInput control={control} name="favoriteNumber" label="NumberInput" />

        <RHF.Slider
          control={control}
          name="age"
          label="Slider"
          componentProps={{ min: 18, max: 80 }}
        />

        <RHF.TextArea
          control={control}
          name="information"
          label="TextArea"
          componentProps={{ placeholder: 'Information about you' }}
        />

        <RHF.Select
          control={control}
          name="favoriteColor"
          label="Select"
          componentProps={{
            placeholder: 'Pick a color',
            children: ['Red', 'Green', 'Blue'].map(color => (
              <option key={color} value={color}>
                {color}
              </option>
            )),
          }}
        />

        <RHF.AutocompleteSelect
          control={control}
          name="country"
          label="AutocompleteSelect"
          componentProps={{
            options: countries.map(c => ({ label: c, value: c })),
            placeholderKey: 'common.select',
          }}
        />

        <RHF.AutocompleteSelect
          control={control}
          name="game"
          label="AutocompleteSelect - allowCustomValues"
          componentProps={{
            options: games.map(g => ({ label: g, value: g })),
            placeholderKey: 'common.select',
            allowCustomValues: true,
          }}
        />

        <RHF.RadioButtonGroup
          control={control}
          name="stack"
          label="RadioButtonGroup"
          componentProps={{
            options: stacks.map(stack => ({ value: stack, label: stack })),
          }}
        />

        <RHF.FieldArray
          control={control}
          name="skills"
          label="Field Array (simple items)"
          isRequired
          defaultValue={{ value: '' }}
          renderField={index => (
            <RHF.Checkbox
              label={`Skill ${index + 1}`}
              control={control}
              name={`skills.${index}.value`}
            />
          )}
        />

        <RHF.FieldArray
          control={control}
          name="users"
          label="Field Array (complex items)"
          maxItems={3}
          minItems={1}
          defaultValue={{ email: '', name: '' }}
          arrayItemSx={{
            border: '1px solid',
            borderColor: colors.blue[100],
            borderRadius: 8,
            p: 2,
          }}
          renderField={index => (
            <Stack w="full">
              <Badge colorScheme="green" alignSelf="flex-start">
                User Role {index + 1}
              </Badge>
              <HStack>
                <RHF.Input
                  control={control}
                  name={`users.${index}.name`}
                  componentProps={{ placeholder: 'Name' }}
                />
                <RHF.Input
                  control={control}
                  name={`users.${index}.email`}
                  componentProps={{ placeholder: 'Email' }}
                />
              </HStack>
            </Stack>
          )}
        />

        <RHFImageUploader
          control={control}
          name="profilePicture"
          label="ImageUploader"
          setError={setError}
          clearErrors={clearErrors}
        />

        <RHF.Checkbox
          control={control}
          name="consent"
          label="Checkbox"
          componentProps={{ children: 'I agree to the terms and conditions' }}
        />

        <HStack spacing={4}>
          <Button
            type="submit"
            isLoading={formState.isSubmitting}
            isDisabled={!formState.isValid || !formState.isDirty}
          >
            Submit
          </Button>
          <Button
            variant="outline"
            isDisabled={!formState.isDirty}
            onClick={() => reset()}
          >
            Reset Form
          </Button>
        </HStack>
      </Stack>
    </ComponentSection>
  )
}
