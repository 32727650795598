import { Box, Heading, HStack, Icon, ListItem, OrderedList, Text } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'
import { type IconType } from 'react-icons/lib'

import { VideoPicker } from '../../../../../builder/components/video-picker'
import { useVideoUploader } from './use-video-uploader'

type RecordIntroProps = {
  title: string
  description: string
  buttonUploadText: string
  hints?: Array<string>
  icon: IconType
  fieldName: string
  disabled?: boolean
  videoDurationLimit?: number
}

export const RecordIntro = ({
  icon,
  title,
  videoDurationLimit,
  description,
  hints,
  buttonUploadText,
  fieldName,
  disabled,
  children,
}: PropsWithChildren<RecordIntroProps>) => {
  const {
    clearError,
    handleVideoUpload,
    error,
    isFormControlDisabled,
    setVideoUrlFormValue,
    loading,
    videoHttpUrl,
  } = useVideoUploader(fieldName, undefined, disabled, videoDurationLimit)

  return (
    <Box mb={5}>
      {!videoHttpUrl && !loading && (
        <Box p={4} pb={0} color="black">
          <HStack>
            <Icon as={icon} size="48px" />
            <Heading as="h1" size="md">
              {title}
            </Heading>
          </HStack>
          <Text mt={2}>{description}</Text>
          <OrderedList mt={2} spacing={3}>
            {hints?.map((hint, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index}>{hint}</ListItem>
            ))}
          </OrderedList>
        </Box>
      )}
      <HStack justifyContent="center">
        <Box p={4}>
          <VideoPicker
            name={fieldName}
            clearError={clearError}
            isDisabled={isFormControlDisabled}
            handleVideoUpload={handleVideoUpload}
            videoDurationLimitInSeconds={videoDurationLimit}
            error={error}
            setVideoUrlFormValue={setVideoUrlFormValue}
            loading={loading}
            videoHttpUrl={videoHttpUrl}
            buttonText={buttonUploadText}
          />
        </Box>
        {children}
      </HStack>
    </Box>
  )
}
