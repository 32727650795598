import { Box, Center, Icon, Text, Tooltip } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { type PropsWithChildren } from 'react'
import { MdOutlineTipsAndUpdates } from 'react-icons/md'

import { colors } from '../../../theme/main'

export const FormTooltipProvider = ({
  children,
  tooltipKey,
}: PropsWithChildren<{ tooltipKey?: I18nKey }>) => {
  if (!tooltipKey) {
    return children
  }

  return (
    <Tooltip
      hasArrow
      placement="right-start"
      bg={colors.blue[50]}
      color={colors.gray[700]}
      p={4}
      borderRadius="16px"
      closeOnClick={false}
      openDelay={0}
      modifiers={[
        {
          name: 'preventOverflow',
          options: { boundary: 'viewport', padding: 8 },
        },
        {
          name: 'flip',
          options: { fallbackPlacements: ['auto-start', 'auto-bottom', 'auto-top'] },
        },
      ]}
      label={
        <Box display="flex" alignItems="flex-start" gap={4}>
          <Center
            minW="34px"
            minH="34px"
            fontSize={18}
            borderRadius="full"
            border="1px solid"
            color="white"
            bg={colors.blue[500]}
          >
            <Icon as={MdOutlineTipsAndUpdates} boxSize="20px" />
          </Center>
          <Text fontSize="14px" fontWeight={400}>
            <FormattedMessage id={tooltipKey} />
          </Text>
        </Box>
      }
    >
      {children}
    </Tooltip>
  )
}
