import { FormattedMessage } from '@repo/i18n'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'

import { AdvancedTable } from '../../../components/advanced-table'
import { useDashboardContext } from '../../shared/dashboard-context'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

type TraineeData = {
  id: string
  cohortName: string
  traineeNumber: number
}

const columnHelper = createColumnHelper<TraineeData>()

const columns = [
  columnHelper.accessor('cohortName', {
    header: () => (
      <FormattedMessage id="dashboard.cards.traineeCount.details.tab.cohortName" />
    ),
  }),
  columnHelper.accessor('traineeNumber', {
    header: () => (
      <FormattedMessage id="dashboard.cards.traineeCount.details.tab.traineeNumber" />
    ),
    meta: {
      align: 'right',
    },
  }),
]

export const TraineeCountCardDetails = () => {
  const { dataSlot } = useDashboardCardData('traineeCount')
  const { pageState } = useDashboardContext()

  const tableRows = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    return data.cohortNames.map((cohortName, index) => ({
      id: data.cohortExtIds[index]!,
      cohortName,
      traineeNumber:
        data.numUsersWithRecordingSessionsAccPerTimestamp[index]!.at(-1) ?? 0,
    }))
  }, [dataSlot])

  return (
    <DetailPageWrapper cardId="traineeCount">
      <AdvancedTable
        columns={columns}
        data={tableRows}
        isLoading={pageState === 'loading'}
      />
    </DetailPageWrapper>
  )
}
