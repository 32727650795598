const types = {
  LOGOUT: 'auth/logout',
}

// eslint-disable-next-line import/no-default-export
export default types

// We are keeping only the LOGOUT action because
// it's used to reset the whole store
export const logOut = () => ({ type: types.LOGOUT })
