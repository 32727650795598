import { type ApiSchemas } from '@repo/api'
import { isNull, omitBy } from 'lodash-es'
import { array, boolean, mixed, number, object, string } from 'yup'

import { customSwrFetcher } from '../../utils/swr-fetcher'

const coachingProgramApiUrl = `/admin/programs`

export type StepTypes =
  | 'coachingStepBasic'
  | 'coachingStepQA'
  | 'coachingStepQuiz'
  | 'coachingStepRealtime'

export type BasicStepTypes = 'text' | 'video'

export const getDefaultPoints = () => [
  { x: 100, y: 100 },
  { x: 150, y: 100 },
  { x: 200, y: 100 },
  { x: 200, y: 200 },
  { x: 200, y: 300 },
  { x: 150, y: 300 },
  { x: 100, y: 300 },
  { x: 100, y: 200 },
]

export type GoalsInstructions = {
  extId: string
  instruction: string
}

export type QuestionAndAnswerStep = {
  id: string
  stepType: 'coachingStepQA'
  questionUrl: string
  answerUrl: string
  maxRecordingTime: number
  dos?: Array<GoalsInstructions>
  donts?: Array<GoalsInstructions>
}

export type QuestionAndAnswerStepFormFields = Omit<QuestionAndAnswerStep, 'id'>

export type Vertex = {
  x: number
  y: number
}

export type QuizType = 'singleChoice' | 'multiChoice' | 'videoComparison' | 'imageRegion'

export type QuestionType = 'text' | 'textVideo'

export type QuizStep = {
  id: string
  stepType: 'coachingStepQuiz'
  questionType: QuestionType
  quizType: QuizType
  questionText: string
  questionVideo?: string
  questionImage?: string
  feedback?: string
  optionsData: Array<{
    id: string
    text?: string
    videoUrl?: string
    imageRegion?: Array<Vertex>
    isCorrect: boolean
  }>
}

export type QuizStepFormFields = RecordWithOptionalId<QuizStep>

type RealtimeStep = {
  id: string
  missionId: string
  stepType: 'coachingStepRealtime'
}

/**
 * This is a combination of both text steps and Video steps
 */
export type BasicStep = {
  id: string
  stepType: 'coachingStepBasic'
  type: BasicStepTypes
  heading: string | null
  text: string | null
  videoStepUrl: string | null
}

export type BasicStepFormFields = Omit<BasicStep, 'id'>

export type AIQuestionAndAnswerStep = ApiSchemas['aiQaStepEntity']

export type CoachingStep = {
  id: string
  step:
    | BasicStep
    | QuestionAndAnswerStep
    | QuizStep
    | AIQuestionAndAnswerStep
    | RealtimeStep
  order: number
}

export type CoachingStepAIQA = {
  id: string
  step: AIQuestionAndAnswerStep
  order: number
}

// only for form validation
export type CoachingStepFormFields = {
  readonly step:
    | BasicStepFormFields
    | QuestionAndAnswerStepFormFields
    | QuizStepFormFields
  order: number
}

// backend CRUD

export const saveCoachingStep = async (
  programId: string,
  trainingId: string,
  scenarioId: string,
  data: Omit<CoachingStep, 'id'>,
  id?: CoachingStep['id']
) => {
  const stepsUrl = `${coachingProgramApiUrl}/${programId}/trainings/${trainingId}/scenarios/${scenarioId}/steps`

  // if `id` exists, this means the step is being edited, otherwise it's being created for the first time
  return customSwrFetcher<CoachingStep>(id ? `${stepsUrl}/${id}` : stepsUrl, {
    method: id ? 'PUT' : 'POST',
    body: JSON.stringify(id ? data.step : data),
  })
}

export const saveStepsOrder = async (
  programId: string,
  trainingId: string,
  scenarioId: string,
  steps: Array<CoachingStep>
) =>
  customSwrFetcher<CoachingStep>(
    `${coachingProgramApiUrl}/${programId}/trainings/${trainingId}/scenarios/${scenarioId}/steps/order`,
    {
      method: 'PUT',
      body: JSON.stringify(steps.map((t, index) => ({ extId: t.id, order: index }))),
    }
  )

export const deleteCoachingStep = async (
  programId: string,
  trainingId: string,
  scenarioId: string,
  stepId: string
) =>
  customSwrFetcher<CoachingStep>(
    `${coachingProgramApiUrl}/${programId}/trainings/${trainingId}/scenarios/${scenarioId}/steps/${stepId}`,
    {
      method: 'DELETE',
    }
  )

//------------------

// populate initial data for the form, and switch to type to be form fields
export const prepareCoachingStepsFormValues = (rawData): CoachingStepFormFields => ({
  ...rawData,
  step: omitBy(rawData.step, isNull),
})

const checkDuplicates = (value, context, field: string): boolean => {
  const formArray: Array<GoalsInstructions> = context.from[1].value[field]

  return (
    (value?.length &&
      formArray.filter(
        item => item.instruction && item.instruction.toLowerCase() === value.toLowerCase()
      ).length === 1) ??
    true
  )
}

// TODO:-quizzes change intl key names, and make QA step required
export const coachingStepFormValidator = object({
  step: object().shape({
    stepType: mixed().oneOf([
      'coachingStepBasic',
      'coachingStepQA',
      'coachingStepQuiz',
      'coachingStepRealtime',
    ] satisfies Array<StepTypes>),
    type: mixed().when('stepType', {
      is: 'coachingStepBasic' satisfies StepTypes,
      then: string()
        .oneOf(['text', 'video'] satisfies Array<BasicStepTypes>)
        .required(),
    }),
    // question & answer validation
    questionUrl: mixed().when('stepType', {
      is: 'coachingStepQA' satisfies StepTypes,
      then: string().required('step.question.required'),
    }),
    answerUrl: mixed().when('stepType', {
      is: 'coachingStepQA' satisfies StepTypes,
      then: string().required('step.answer.required'),
    }),
    maxRecordingTime: mixed().when('stepType', {
      is: 'coachingStepQA' satisfies StepTypes,
      then: number().required('step.maxRecordingTime.required'),
    }),
    // basic text & video validation
    heading: mixed().when('type', {
      is: 'text' satisfies BasicStepTypes,
      then: string().required('step.text.title.required'),
    }),
    text: mixed().when('type', {
      is: 'text' satisfies BasicStepTypes,
      then: string().required('step.text.content.required'),
    }),
    videoStepUrl: mixed().when('type', {
      is: 'video' satisfies BasicStepTypes,
      then: string().required('step.video.required'),
    }),
    // quiz validations
    questionType: mixed().when('stepType', {
      is: 'coachingStepQuiz' satisfies StepTypes,
      then: string()
        .oneOf(['text', 'textVideo'] satisfies Array<QuestionType>)
        .required(),
    }),
    questionText: mixed().when('questionType', {
      is: (val: QuestionType) => ['text', 'textVideo'].includes(val),
      then: string()
        .required('step.quiz.form.questionText.required')
        .max(255, 'step.quiz.form.questionText.length.max'),
    }),
    questionVideo: mixed().when('questionType', {
      is: 'textVideo' satisfies QuestionType,
      then: string().required('step.quiz.form.questionVideo.required'),
    }),
    quizType: mixed().when('stepType', {
      is: 'coachingStepQuiz' satisfies StepTypes,
      then: string()
        .oneOf(['singleChoice', 'multiChoice', 'videoComparison', 'imageRegion'])
        .required('step.quiz.form.quizType.required'),
    }),
    questionImage: mixed().when('quizType', {
      is: 'imageRegion',
      then: string().required('step.quiz.form.questionImage.required'),
    }),
    optionsData: mixed().when('stepType', {
      is: 'coachingStepQuiz' satisfies StepTypes,
      then: array()
        .of(
          object({
            text: string().when('quizType', {
              is: (val: QuizType) => ['singleChoice', 'multiChoice'].includes(val),
              then: string()
                .required('step.quiz.form.options.text.required')
                .max(255, 'step.quiz.form.options.text.length.max'),
              otherwise: string().nullable(),
            }),
            videoUrl: string().when('quizType', {
              is: 'videoComparison',
              then: string().required('step.quiz.form.options.videoUrl.required'),
              otherwise: string().nullable(),
            }),
            imageRegion: array().when('quizType', {
              is: 'imageRegion',
              then: array()
                .of(
                  object().shape({
                    x: number().required(
                      'step.quiz.form.options.imageRegion.image.required'
                    ),
                    y: number().required(
                      'step.quiz.form.options.imageRegion.image.required'
                    ),
                  })
                )
                .length(8, 'step.quiz.form.options.imageRegion.vertex.length')
                .required('step.quiz.form.options.imageRegion.required'),
              otherwise: array().nullable(),
            }),
            isCorrect: boolean().required('step.quiz.form.options.isCorrect.required'),
          }).test(
            'requiredField',
            'step.quiz.form.options.requiredField',
            value => !!value.text || !!value.videoUrl || !!value.imageRegion
          )
        )
        .test(
          'requiredCorrectAnswer',
          'step.quiz.requiredAnswer.error',
          options => !!options?.some(opt => opt.isCorrect)
        ),
    }),
    feedback: mixed().when('stepType', {
      is: 'coachingStepQuiz' satisfies StepTypes,
      then: string().optional().max(255, 'step.quiz.form.feedback.length.max'),
    }),
    // realtime validations
    missionId: mixed().when('stepType', {
      is: 'coachingStepRealtime' satisfies StepTypes,
      then: string().required('step.realtime.form.missionId.required'),
    }),
  }),
  order: number().required(),
})

export const coachingStepWithUnderstandingValidator = coachingStepFormValidator.concat(
  object({
    step: object().shape({
      dos: mixed().when('stepType', {
        is: 'coachingStepQA' satisfies StepTypes,
        then: array()
          .min(1, 'step.form.validation.keywords.items.min')
          .max(3, 'step.form.validation.keywords.items.max')
          .required('step.form.validation.dos.required')
          .of(
            object({
              instruction: string()
                .required('step.form.validation.do.required')
                .max(255, 'step.form.validation.keyword.max')
                .min(3, 'step.form.validation.keyword.min')
                .test(
                  'noDuplicates',
                  'common.validation.alreadyExists',
                  (value, context) => checkDuplicates(value, context, 'dos')
                ),
            })
          ),
      }),
      donts: mixed().when('stepType', {
        is: 'coachingStepQA' satisfies StepTypes,
        then: array()
          .max(3, 'step.form.validation.keywords.items.max')
          .optional()
          .of(
            object({
              instruction: string()
                .required('step.form.validation.do.required')
                .max(255, 'step.form.validation.keyword.max')
                .min(3, 'step.form.validation.keyword.min')
                .test(
                  'noDuplicates',
                  'common.validation.alreadyExists',
                  (value, context) => checkDuplicates(value, context, 'donts')
                ),
            })
          ),
      }),
    }),
  })
)
