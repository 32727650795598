import { Box, Icon, Stack, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, PageWrapper } from '@repo/ui'
import { isDevelopment } from '@repo/utils'
import { type FC, useEffect } from 'react'
import { BsInbox } from 'react-icons/bs'

import { logAnalyticsEvent } from '../../../utils/analytics'
import { SESSION_STORAGE_SCROLL_KEY } from '../../shared/constants'
import { useDashboardContext } from '../../shared/dashboard-context'
import { type DashboardCard, type DashboardCardId } from '../../shared/types'
import { BehavioralAdaptationCard } from './dashboard-overview/behavioral-adaptation-card'
import { CohortPerformanceCard } from './dashboard-overview/cohort-performance-card'
import { CommunicationAndLanguageCard } from './dashboard-overview/communication-and-language-card'
import { CompetencyTimeCard } from './dashboard-overview/competency-time-card'
import { DashboardSectionsSkeleton } from './dashboard-overview/dashboard-sections-skeleton'
import { KnowledgeCheckCard } from './dashboard-overview/knowledge-check-card'
import { LearningDevelopmentFunnelCard } from './dashboard-overview/learning-development-funnel-card'
import { MessagingCard } from './dashboard-overview/messaging-card'
import { PopularScenariosCard } from './dashboard-overview/popular-scenarios-card'
import { RecordingCountCard } from './dashboard-overview/recording-count-card'
import { ScenarioRepetitionsCard } from './dashboard-overview/scenario-repetitions-card'
import { TraineeCountCard } from './dashboard-overview/trainee-count-card'
import { DashboardSection } from './shared/dashboard-section'

const componentPerCardId: Record<DashboardCardId, FC> = {
  behavioralAdaptation: BehavioralAdaptationCard,
  traineeCount: TraineeCountCard,
  recordingCount: RecordingCountCard,
  learningDevelopmentFunnel: LearningDevelopmentFunnelCard,
  scenarioRepetitions: ScenarioRepetitionsCard,
  popularScenarios: PopularScenariosCard,
  competencyTime: CompetencyTimeCard,
  communicationAndLanguage: CommunicationAndLanguageCard,
  knowledgeCheck: KnowledgeCheckCard,
  messaging: MessagingCard,
  cohortPerformance: CohortPerformanceCard,
}

export const DashboardOverview = () => {
  const { pageState, overviewPageWrapperRef, isLoadingLayout, layout } =
    useDashboardContext()

  useEffect(() => {
    logAnalyticsEvent('page_view_dashboard')
  }, [])

  useEffect(() => {
    const savedScrollTop = sessionStorage.getItem(SESSION_STORAGE_SCROLL_KEY)

    if (savedScrollTop) {
      // restore scroll position and remove the saved value
      sessionStorage.removeItem(SESSION_STORAGE_SCROLL_KEY)
      const scrollY = parseInt(savedScrollTop, 10)

      overviewPageWrapperRef.current?.scrollTo(0, scrollY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderSectionContent = (cards: Array<DashboardCard>) => {
    const visibleCards = cards.filter(card => card.visible)

    if (!visibleCards.length) {
      return (
        <Box gridColumn="1 / -1">
          <VStack mx="auto" my={5} maxW={500}>
            <Icon as={BsInbox} color={colors.blue[400]} w={10} h={10} mr={2} />
            <Text fontSize="md" textAlign="center">
              <FormattedMessage id="dashboard.page.emptySection.message" />
            </Text>
          </VStack>
        </Box>
      )
    }

    return visibleCards.map(card => {
      const CardComponent = componentPerCardId[card.id]

      // Hide `scenarioRepetitions` until we get a green light for it
      if (card.id === 'scenarioRepetitions' && !isDevelopment) {
        return
      }

      return <CardComponent key={card.id} />
    })
  }

  return (
    <PageWrapper
      ref={overviewPageWrapperRef}
      sx={{ bg: colors.gray[50], overflowY: 'auto', mt: -8 }}
    >
      <Stack gap="60px">
        {pageState === 'loading' || isLoadingLayout ? (
          <DashboardSectionsSkeleton />
        ) : (
          <>
            {layout.map(section => (
              <DashboardSection
                key={section.id}
                titleKey={`dashboard.sections.${section.id}.title`}
                subtitleKey={`dashboard.sections.${section.id}.subtitle`}
              >
                {renderSectionContent(section.cards)}
              </DashboardSection>
            ))}
          </>
        )}
      </Stack>
    </PageWrapper>
  )
}
