import { useMachine } from '@xstate/react'
import { useEffect, useMemo, useRef } from 'react'

import { type VideoSizeType, type VideoSource } from './types'
import { videoManagerMachine } from './video-manager-state-machine'
import { type VideoPlayerHandle } from './video-player'

export const useVideoManager = (
  sources: Array<VideoSource>,
  autoPlay = false,
  autoPlayNext = false
) => {
  const [state, send] = useMachine(videoManagerMachine, {
    context: { autoPlay, autoPlayNext },
  })

  const videoPlayerRef = useRef<VideoPlayerHandle>(null)

  useEffect(() => {
    send({ type: 'END' })
    send({ type: 'LOAD', videoSources: sources })
  }, [sources, send])

  const currentVideoUrl = useMemo<string>(
    () => state.context.videos?.[state.context.currentVideoIndex]?.url || '',
    [state.context.videos, state.context.currentVideoIndex]
  )

  const dividers: Array<number> = useMemo(
    () =>
      (state.context.videos?.slice(0, -1) || [])
        .map(video => (video.duration * 100) / state.context.duration)
        .reduce(
          (acc, el) => [...acc, el + (acc?.[acc.length - 1] || 0)],
          [] as Array<number>
        ),
    [state.context.videos, state.context.duration]
  )

  const isPlaying = state.matches({ ready: 'playing' })
  const isPaused = state.matches({ ready: 'paused' })
  const isEnded = state.matches({ ready: 'ended' })
  const isReady = state.matches('ready')
  const isLoading = state.matches('loading')

  // TODO get from video sources metadata
  const videoSize: VideoSizeType = useMemo(
    () => ({
      width: 1600,
      height: 900,
    }),
    []
  )

  return {
    dividers,
    currentVideoUrl,
    videoPlayerRef,
    isPlaying,
    isReady,
    isPaused,
    isEnded,
    isLoading,
    state,
    send,
    videoSize,
  }
}
