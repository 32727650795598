import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { Formik } from 'formik'

import { CohortForm } from './cohort-form'

export const CohortAddTokensModal = ({
  cohortId,
  isOpen,
  onClose,
  refreshParticipantsList,
  refreshCohortDetails,
  addTokens,
}: {
  cohortId: string
  isOpen: boolean
  onClose: () => void
  refreshParticipantsList: () => void
  refreshCohortDetails: () => void
  addTokens: (numberTokens: number, cohortId: string) => Promise<unknown>
}) => {
  const onSubmit = async formData => {
    try {
      await addTokens(formData.numberTokens, cohortId)
      refreshParticipantsList()
      refreshCohortDetails()
      showToast({ messageKey: 'common.alert.created', status: 'success' })
      onClose()
    } catch (err) {
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="cohort.addTokensModal.title" />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody pb={4}>
          <Formik
            onSubmit={onSubmit}
            onReset={onClose}
            initialValues={{ name: '', numberTokens: 0 }}
            validateOnBlur={false}
          >
            <CohortForm mode="addTokens" />
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
