import { Box, Text } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { type PropsWithChildren, type ReactNode } from 'react'

type ContentContainerProps = {
  title: string | ReactNode
  subtitle?: string | ReactNode
}

export const ContentContainer = ({
  title,
  subtitle,
  children,
}: PropsWithChildren<ContentContainerProps>) => (
  <Box p={12} h="600px" position="relative">
    <Text pb={4} fontSize="lg" fontWeight="bold">
      {title}
    </Text>
    {subtitle && <Text color={colors.gray[500]}>{subtitle}</Text>}
    {children}
  </Box>
)
