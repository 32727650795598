import { useIntl } from '@repo/i18n'
import { useCallback } from 'react'

const removePOEditorPrefix = (str: string) => str.replace(/^<poeditor>/, '')

const hasPOEditorPrefix = (str: string) => str.startsWith('<poeditor>')

export const useRenderSkillName = () => {
  const { formatMessage } = useIntl()

  const renderSkillName = useCallback(
    (skillName: string) => {
      if (hasPOEditorPrefix(skillName)) {
        return formatMessage({ id: removePOEditorPrefix(skillName) as I18nKey })
      }

      return skillName
    },
    [formatMessage]
  )

  return renderSkillName
}
