import { Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { NumberInput, showToast } from '@repo/ui'
import { Form, useFormikContext } from 'formik'

import { useCohortParticipants } from '../../../../../use-cohort-participants'
import { type CohortCreationValues } from '../../../types'
import { StepActions } from '../../step-actions'

const MAX_NUMBER_OF_TOKENS = 200

export const TokenStep = () => {
  const { addTokens } = useCohortParticipants()
  const {
    handleSubmit,
    values: { tokenCount, id: cohortId },
    setFieldValue,
  } = useFormikContext<CohortCreationValues>()

  const onChange = (value: string) => {
    setFieldValue('tokenCount', value ? Number.parseInt(value, 10) : 0)
  }

  const onSubmit = async () => {
    try {
      await addTokens(tokenCount, cohortId)
      handleSubmit()
    } catch (error) {
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }
  }

  return (
    <Form>
      <FormattedMessage id="cohort.creation.token.step.description" />
      <Text mb={2} mt={8} size="xs" fontWeight="semibold">
        <FormattedMessage id="cohort.creation.token.step.input.label" />
      </Text>
      <NumberInput
        value={tokenCount}
        defaultValue={0}
        min={0}
        max={MAX_NUMBER_OF_TOKENS}
        maxW="100px"
        inputMode="numeric"
        onChange={value => onChange(value)}
      />
      <StepActions onSubmit={onSubmit} isDisabled={tokenCount < 1} />
    </Form>
  )
}
