import { Box, Button, Divider, Menu, MenuList } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, CustomSelectButton } from '@repo/ui'
import { Formik } from 'formik'
import { uniq, uniqBy } from 'lodash-es'
import { useState } from 'react'

import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { type CoachingRecordingSession } from '../types/coaching-recording'
import { type ProgramTrainingScenariosTree } from '../types/program-training-scenario-tree'
import {
  SelectScenariosForm,
  type SelectScenariosFormValues,
} from './select-scenarios-form'

type SessionScenarioFilterProps = {
  sessions: Array<CoachingRecordingSession>
  selectedSessions: Array<string>
  scenariosFilter: Array<string>
  setScenariosFilter: (scenarios: Array<string>) => void
  programTrainingScenarioTree: ProgramTrainingScenariosTree
}

export const SessionScenarioFilter = ({
  sessions,
  selectedSessions,
  scenariosFilter,
  setScenariosFilter,
  programTrainingScenarioTree,
}: SessionScenarioFilterProps) => {
  const { formatMessage } = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const [showInterceptModal, setShowInterceptModal] = useState(false)
  const [scenariosToOverwrite, setScenariosToOverwrite] = useState<Array<string>>([])
  const numberScenarios = uniq(sessions.map(session => session.scenario.extId)).length

  const handleSubmit = (formData: SelectScenariosFormValues) => {
    // if a selected session is excluded by filters, we want to show an intercept and confirm
    const scenariosInSelection = sessions
      .filter(session => selectedSessions.includes(session.extId))
      .map(session => session.scenario)

    const uniqScenariosInSelection = uniqBy(scenariosInSelection, 'extId')
    const filteredOutScenarios = uniqScenariosInSelection.filter(
      scenario => !formData.selectedScenarioExtIds.includes(scenario.extId)
    )

    setScenariosToOverwrite(filteredOutScenarios.map(scenario => scenario.title))
    if (filteredOutScenarios.length) {
      setShowInterceptModal(true)
    } else {
      setScenariosFilter(formData.selectedScenarioExtIds)
      setIsOpen(false)
    }
  }

  return (
    <Menu closeOnSelect={false} isOpen={isOpen} onClose={() => setIsOpen(false)} isLazy>
      <CustomSelectButton
        bg={
          scenariosFilter.length !== numberScenarios
            ? colors['secondary-dark']
            : undefined
        }
        onClick={() => setIsOpen(true)}
        maxWidth="full"
      >
        <FormattedMessage id="coaching.benchmarking.recordings.filters.scenarios.title" />
      </CustomSelectButton>
      <MenuList>
        <Box p={4} overflow="scroll">
          <Formik
            onSubmit={formData => handleSubmit(formData)}
            initialValues={{ selectedScenarioExtIds: scenariosFilter }}
          >
            {({ values, submitForm, dirty, isValid, isSubmitting }) => (
              <>
                <SelectScenariosForm
                  programTrainingScenarioTree={programTrainingScenarioTree}
                  showActionButtons={false}
                  showAllCollapsed
                />
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                  <Divider m={3} />

                  <Button
                    onClick={submitForm}
                    isDisabled={!(dirty && isValid)}
                    isLoading={isSubmitting}
                  >
                    <FormattedMessage id="coaching.benchmarking.recordings.filters.scenarios.apply" />
                  </Button>
                  <InterceptAndConfirm
                    isOpen={showInterceptModal}
                    onConfirm={() => {
                      setScenariosFilter(values.selectedScenarioExtIds)
                      setShowInterceptModal(false)
                      setIsOpen(false)
                    }}
                    onCancel={() => setShowInterceptModal(false)}
                    description={formatMessage(
                      {
                        id: 'coaching.benchmarking.recordings.filters.scenarios.intercept.description',
                      },
                      { scenarios: scenariosToOverwrite.toString() }
                    )}
                    title={formatMessage({
                      id: 'coaching.benchmarking.recordings.filters.scenarios.intercept.title',
                    })}
                  />
                </Box>
              </>
            )}
          </Formik>
        </Box>
      </MenuList>
    </Menu>
  )
}
