import { Button, useDisclosure } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { RiAddFill } from 'react-icons/ri'

import { CohortCreationModal } from '../../coaching-participants/components/cohort-creation-modal/cohort-creation-modal'
import { CohortsTable } from '../../coaching-participants/components/cohorts-table'
import { ActionButtonContainer } from './action-button-container'

export const CohortsTab = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <ActionButtonContainer>
        <Button leftIcon={<RiAddFill size="1.25em" />} iconSpacing="1" onClick={onOpen}>
          <FormattedMessage id="coaching.benchmarking.cohorts.create" />
        </Button>
      </ActionButtonContainer>
      <CohortsTable isBenchmarking />
      <CohortCreationModal isOpen={isOpen} onClose={onClose} isBenchmarking />
    </>
  )
}
