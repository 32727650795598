import { FormattedMessage } from '@repo/i18n'
import { RadioButtonGroup } from '@repo/ui'

import { organizationMemberRoles } from '../../store/entities/organization/organization-defaults'
import { type OrganizationMemberRole } from '../../store/entities/organization/organization-types'

type MemberRolesProps = {
  value?: OrganizationMemberRole
  isLoading?: boolean
  isDisabled?: boolean
  onChange: (v: OrganizationMemberRole) => void
}

export const MemberRoles = ({
  value,
  isLoading,
  isDisabled,
  onChange,
}: MemberRolesProps) => (
  <RadioButtonGroup
    options={organizationMemberRoles.map(r => ({
      value: r,
      label: <FormattedMessage id={`members.role.${r}`} />,
    }))}
    radioGroupProps={{
      isDisabled: isLoading || isDisabled,
      value,
      onChange,
    }}
  />
)
