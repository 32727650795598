import { Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { RHF, showToast, useFormRules } from '@repo/ui'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { StickyFooter } from '../../shared/sticky-footer'
import { useCustomizationStepIntroModal } from '../../shared/use-customization-step-intro-modal'
import { useScenarioBuilderData } from '../../shared/use-scenario-builder-data'
import { useManualBuilderContext } from '../shared/manual-builder-context'

type FormValues = { goal: string; description: string }

export const DetailsStep = () => {
  const { goToPreviousStep, scenario, goToNextStep, isBuilderReadonly } =
    useManualBuilderContext()

  const { control, handleSubmit, formState } = useForm<FormValues>({
    mode: 'onTouched',
    disabled: isBuilderReadonly,
    defaultValues: {
      goal: scenario?.goal ?? '',
      description: scenario?.description ?? '',
    },
  })

  const rules = useFormRules()
  const [introModal, showIntroModal] = useCustomizationStepIntroModal()

  const [, , , { mutate: mutateScenario }, updateScenarioDetails] =
    useScenarioBuilderData()

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const res = await updateScenarioDetails(data)

    if (res.error) {
      return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }

    mutateScenario(res.data, false)

    await showIntroModal()

    goToNextStep()
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} h="full">
      {introModal}
      <Stack flex={1} w="600px" gap={10}>
        <Text fontWeight="bold">
          <FormattedMessage id="scenario.manual.builder.details.step.subtitle" />
        </Text>

        <RHF.TextArea
          control={control}
          name="goal"
          labelKey="scenario.manual.builder.details.step.goal.label"
          rules={rules.stringLongRequired}
        />

        <RHF.TextArea
          control={control}
          name="description"
          rules={rules.string10kRequired}
          labelKey="scenario.description.label"
          tooltipKey="scenario.builder.realtime.steps.session.description.tooltip"
        />
      </Stack>
      <StickyFooter
        onBack={goToPreviousStep}
        onNext={isBuilderReadonly ? goToNextStep : undefined}
        isNextDisabled={!isBuilderReadonly && !formState.isValid}
        isNextLoading={formState.isSubmitting}
      />
    </Stack>
  )
}
