import { Center, Icon, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { type IconType } from 'react-icons'

export const StatusBadge = ({
  icon,
  intlKey,
  borderColor,
  bgColor,
}: {
  icon?: IconType
  intlKey: I18nKey
  borderColor: string
  bgColor: string
}) => (
  <Center
    py={1}
    gap={1}
    px={4}
    borderRadius="full"
    border="1px solid"
    borderColor={borderColor}
    bgColor={bgColor}
    display="inline-flex"
    minW="112px"
  >
    {!!icon && <Icon as={icon} boxSize="14px" color={borderColor} />}
    <Text fontWeight={500} fontSize="14px" color={borderColor}>
      <FormattedMessage id={intlKey} />
    </Text>
  </Center>
)
