import { merge } from 'lodash-es'
import {
  type Dispatch,
  type MutableRefObject,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { v4 as generateUUid } from 'uuid'

import { type SWRLoaderResponse, useSWRLoader } from '../hooks/use-swr-loader'
import { type ContentStatus } from './components/content-loader'

export type DataLoaderType<T extends Record<string, unknown>> = {
  uuid: string
  data: RecordWithOptionalId<T>
}

/**
 * - this is for loading array of data items from backend
 * - the new data item always will be the last one
 * - new data item will have id undefined
 *
 * TODO:
 * - add support for single data items
 *
 * Data flow
 *  [load]
 *      backend --> swr cache -->  local store {our data variable} -> data is sent to ui
 * 		               [mutate]           [here we do the uuid assign]
 *                                     in a useEffect
 *
 * [add]                                add an empty item in local store
 *
 * [save]  (problem is with create without id -> data comes with id after save)
 *
 *     send Request to backend -> cache the uuid -> id pair -> trigger load data flow by mutating swr cache or refresh
 *
 *
 */

export type DataLoaderResponse<T extends Record<string, unknown>> = Omit<
  SWRLoaderResponse<Array<T>>,
  'data'
> & {
  data: Array<DataLoaderType<RecordWithOptionalId<T>>>
  editingUuid?: string
  setEditingUuid: Dispatch<SetStateAction<string | undefined>>
  addRef: MutableRefObject<() => Promise<string>>
  save: (uuid: string, data: RecordWithOptionalId<T>) => Promise<T>
  edit: (newData: DeepPartial<RecordWithOptionalId<T>>) => Promise<void>
  remove: (uuid: string) => Promise<void>
  getById: (id?: string) => DataLoaderType<RecordWithOptionalId<T>> | undefined
  status: ContentStatus
}
const uuidCache: Record<string, string> = {}
const createUuid = (id: string) => {
  if (!uuidCache[id]) {
    uuidCache[id] = generateUUid()
  }

  return uuidCache[id]
}

const unimplementedFunction = () => {
  throw new Error('Unimplemented')
}

export function useDataLoader<
  U extends Record<string, unknown> & {
    id: string
  },
>(
  url: string | null,
  createEmptyCallback: (uuid: string) => Promise<Omit<U, 'id'>>,
  saveCallback: (
    data: RecordWithOptionalId<U>
  ) => Promise<U | undefined> = unimplementedFunction,
  deleteCallback: (id: string) => Promise<void> = unimplementedFunction
): DataLoaderResponse<U> {
  const [data, setData] = useState<Array<DataLoaderType<RecordWithOptionalId<U>>>>([])
  // TODO: keep a list of uuid's that are being edited instead of one
  const [editingUuid, setEditingUuid] = useState<string | undefined>()
  const {
    data: dataFromServer,
    error,
    mutate,
    refresh,
    ...loaderResp
  } = useSWRLoader<Array<U>>(url)

  const status = useMemo<ContentStatus>(() => {
    if (url === null) {
      return 'loaded'
    }

    if (error) {
      return 'error'
    }

    if ((dataFromServer && dataFromServer.length === data.length) || data.length) {
      return 'loaded'
    }

    return 'loading'
  }, [url, error, dataFromServer, data.length])

  // using ref to prevent rerendering when add function is changed
  const addRef = useRef<() => Promise<string>>(() => Promise.resolve(''))

  addRef.current = async () => {
    if (!editingUuid) {
      const uuid = generateUUid()

      setEditingUuid(uuid)
      const newItem = await createEmptyCallback(uuid)

      setData(oldData => [
        ...oldData,
        {
          data: newItem as unknown as U,
          uuid,
        } as DataLoaderType<U>,
      ])

      return uuid
    }

    return editingUuid
  }

  const edit = useCallback(
    async (newData: DeepPartial<RecordWithOptionalId<U>>) => {
      if (editingUuid) {
        setData(oldData =>
          oldData.map(oldDataItem =>
            oldDataItem.uuid === editingUuid
              ? { uuid: oldDataItem.uuid, data: merge(oldDataItem.data, newData) }
              : oldDataItem
          )
        )
      }
    },
    [editingUuid]
  )

  const save: DataLoaderResponse<U>['save'] = useCallback(
    async (uuid: string, itemData) => {
      setData(oldData =>
        oldData.map(d => (d.uuid === uuid ? { data: itemData, uuid } : d))
      )

      const savedData = await saveCallback(itemData)

      if (!savedData) {
        // This should never happen with the current backend implementation
        // if you change the save endpoint to return 203 (no content) this should be changed
        // to return the itemData
        setEditingUuid(eu => (eu === uuid ? undefined : eu))
        refresh()
        throw new Error('Failed to save data')
      }

      setData(oldData => {
        const newData = oldData.map(d =>
          d.uuid === uuid ? { data: savedData, uuid } : d
        )

        // this is for uuid -> id pair caching. This is necessary for the created item to be
        // identified with the same uuid
        return newData
      })

      await mutate(async d =>
        d
          ? [
              ...d.map(i => (i.id === savedData.id ? savedData : i)),
              ...(itemData?.id ? [] : [savedData]),
            ]
          : [savedData]
      )

      setEditingUuid(eu => (eu === uuid ? undefined : eu))

      return savedData
    },
    [mutate, refresh, saveCallback]
  )

  const remove = useCallback(
    async (uuid: string) => {
      const item = data.find(d => d.uuid === uuid)
      const id = item?.data.id

      if (id) {
        await deleteCallback(id)
        setEditingUuid(eu => (eu === uuid ? undefined : eu))
        mutate(async d => d?.filter(i => i.id !== id))
      } else {
        setData(oldData => oldData.filter(d => d.uuid !== uuid))
        setEditingUuid(eu => (eu === uuid ? undefined : eu))
      }
    },
    [data, deleteCallback, mutate]
  )

  const getById = useCallback((id?: string) => data.find(d => d.data.id === id), [data])

  // editingUuid keeps the current changes and not for activating editors
  // This is the merging of the data from the server and the data from the local state
  useEffect(() => {
    setData(oldData => {
      const editing = oldData.find(d => d.uuid === editingUuid)
      const idToUuid = oldData.reduce<Record<string, string>>(
        (acc, d) => (d.data?.id ? { ...acc, [d.data.id]: d.uuid } : acc),
        {}
      )

      const returnedData = [
        ...(dataFromServer ?? []).map(d => {
          const uuid = (d?.id && idToUuid[d.id]) ?? createUuid(d.id)
          const itemData = uuid === editingUuid && editing ? editing.data : d

          return {
            data: itemData,
            uuid,
          } as unknown as DataLoaderType<U>
        }),
        ...(editing && !editing.data?.id ? [editing] : []),
      ]

      return returnedData
    })
  }, [editingUuid, dataFromServer])

  return {
    ...loaderResp,
    data,
    mutate,
    refresh,
    error,
    status,
    editingUuid,
    setEditingUuid,
    addRef,
    save,
    edit,
    remove,
    getById,
  }
}
