// React Dnd Source-code https://react-dnd.github.io/react-dnd/examples/sortable/simple

import { Icon, type IconProps, Stack, type StackDirection } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import type { Identifier, XYCoord } from 'dnd-core'
import { type ReactNode, useMemo, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { MdOutlineDragIndicator } from 'react-icons/md'

import { useContentEditorManager } from './content-editor/content-editor-manager-context'

type DragItemProps = {
  id: string
  canDrag: boolean
  dragItemType: string
  dragIcon?: ReactNode
  moveItem: (id: string, to: number, saveOrder?: boolean) => void
  findItem: (id: string) => { index: number }
} & Omit<IconProps, 'aria-label'>

type DragItemType = {
  index: number
  id: string
  type: string
}

export const DragItem = ({
  id,
  moveItem,
  findItem,
  children,
  canDrag,
  dragIcon,
  dragItemType,
  ...props
}: DragItemProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { setIsDragging } = useContentEditorManager()
  const [{ handlerId }, drop] = useDrop<
    DragItemType,
    void,
    { handlerId: Identifier | null }
  >({
    accept: dragItemType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItemType, monitor) {
      if (!ref.current) {
        return
      }

      setIsDragging(true)
      const { index } = findItem(id)
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveItem(item.id, hoverIndex, false)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      item.index = hoverIndex
    },
  })

  const { index } = findItem(id)
  const [{ isDragging }, drag] = useDrag({
    type: dragItemType,
    item: () => ({ id, index }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const { id: droppedId } = item
      const didDrop = monitor.didDrop()

      if (didDrop) {
        moveItem(droppedId, index, true)
        setIsDragging(false)
      }
    },
  })

  const opacity = isDragging ? 0.5 : 1

  if (canDrag) {
    drag(drop(ref))
  }

  const icon = useMemo(
    () =>
      dragIcon ?? (
        <Icon
          color={colors.gray[500]}
          as={MdOutlineDragIndicator}
          w={4}
          h={4}
          position="absolute"
          {...props}
          cursor="grab"
        />
      ),
    [dragIcon, props]
  )

  return (
    <Stack
      ref={ref}
      data-handler-id={handlerId}
      style={{
        position: 'relative',
        opacity,
      }}
      direction={props.direction as StackDirection}
      spacing={props.spacing}
      alignItems={props.alignItems}
    >
      {children}
      {dragItemType !== 'list-with-checkmark' && icon}
    </Stack>
  )
}
