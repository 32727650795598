import { Stack } from '@chakra-ui/react'

import {
  isCardView,
  isListView,
  type RecordingView,
} from '../../containers/recording-list/use-recording-view'
import { ColumnControl } from './column-control'
import { ColumnSortableControl } from './column-sortable-control'
import { FilterControl } from './filter-control'
import { useRecordingFiltersContext } from './recording-filters-context'

type RecordingFiltersProps = {
  recordingView: RecordingView
  isRootFolder: boolean
}

export const RecordingFilters = ({
  recordingView,
  isRootFolder,
}: RecordingFiltersProps) => {
  const {
    filterSelectInternalValue,
    onFilterSelectChange,
    onFilterSelectApply,
    onFilterSelectClose,
    columnSelectInternalValue,
    onColumnSelectChange,
    onColumnSelectApply,
    onColumnSelectClose,
    onColumnSortableSelectChange,
    onColumnSortableSelectApply,
    onColumnSortableSelectClose,
    sorting,
  } = useRecordingFiltersContext()

  const columInternalValue = columnSelectInternalValue.map(item => item.value)

  return (
    <Stack mb={4} direction={['column', 'column', 'column', 'row']}>
      <FilterControl
        width="max-content"
        value={filterSelectInternalValue}
        onApply={onFilterSelectApply}
        onChange={onFilterSelectChange}
        onClose={onFilterSelectClose}
      />
      {isListView(recordingView) && (
        <ColumnControl
          minW={0}
          value={columInternalValue}
          onChange={onColumnSelectChange}
          onApply={onColumnSelectApply}
          onClose={onColumnSelectClose}
          isRootFolder={isRootFolder}
        />
      )}
      {isCardView(recordingView) && (
        <ColumnSortableControl
          minW={0}
          value={columnSelectInternalValue}
          onApply={onColumnSortableSelectApply}
          onChange={onColumnSortableSelectChange}
          onClose={onColumnSortableSelectClose}
          sorting={sorting}
          isRootFolder={isRootFolder}
        />
      )}
    </Stack>
  )
}
