import { Checkbox } from './checkbox'
import { DateRangePicker } from './date-range-picker'
import { Input } from './input'
import { Select } from './select'
import { Textarea } from './textarea'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Field = (props: any) => {
  // eslint-disable-next-line react/destructuring-assignment
  switch (props.type) {
    case 'checkbox':
      return <Checkbox {...props} />
    case 'datepicker':
      return <DateRangePicker {...props} />
    case 'select':
      return <Select {...props} />
    case 'textarea':
      return <Textarea {...props} />
    default:
      return <Input {...props} />
  }
}
