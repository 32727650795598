import { Box, Center, HStack, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type PropsWithChildren } from 'react'

import { type CoachingStep, type QuestionAndAnswerStep } from '../../builder/steps/api'
import { AIQuestionAnswerStep } from './step-review-section/ai-question-answer-step'
import { BasicStepReview } from './step-review-section/basic-step-review'
import { CustomQuestionAnswerStepReview } from './step-review-section/custom-question-answer-step-review'
import { KnowledgeCheckStep } from './step-review-section/knowledge-check-step'
import { RealTimeStep } from './step-review-section/realtime-step'

const StepReviewListItem = ({
  children,
  sectionIndex,
}: PropsWithChildren<{
  sectionIndex: number
}>) => (
  <HStack
    gap={5}
    alignItems="stretch"
    sx={{
      '&:not(:last-of-type) .step-card': {
        mb: 12,
      },
    }}
  >
    <Center flexDirection="column">
      <Center
        minW={8}
        minH={8}
        fontSize={18}
        borderRadius="full"
        border="1px solid"
        color="white"
        bg={colors.blue[500]}
        px="16px"
        py="8px"
      >
        <Text fontSize="16px" fontWeight={500}>
          <FormattedMessage id="common.step" /> {sectionIndex + 1}
        </Text>
      </Center>
      <Box borderLeft="1px solid" borderColor={colors.blue[500]} h="100%" />
    </Center>
    <Stack
      className="step-card"
      p="24px"
      flex={1}
      borderRadius="8px"
      bg={colors.gray[50]}
      alignItems="flex-start"
    >
      {children}
    </Stack>
  </HStack>
)

export const StepReviewList = ({
  steps,
  outdatedStepId,
}: {
  steps?: Array<CoachingStep>
  outdatedStepId?: string
}) => {
  let aiSupportedOrder = 1

  return (
    <Box>
      {steps?.map((step, index) => {
        const getStepChildren = () => {
          if (
            step.step.stepType === 'coachingStepQA' &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (step.step as any).type === 'aiSupported'
          ) {
            // AI Supported Step
            const currentOrder = aiSupportedOrder

            aiSupportedOrder += 1

            return (
              <AIQuestionAnswerStep
                order={currentOrder}
                step={step}
                showUpdateButton={step.id === outdatedStepId}
              />
            )
          }

          if (step.step.stepType === 'coachingStepQuiz') {
            return <KnowledgeCheckStep stepQuiz={step.step} />
          }

          if (step.step.stepType === 'coachingStepBasic') {
            return <BasicStepReview step={step.step} />
          }

          if (step.step.stepType === 'coachingStepRealtime') {
            return <RealTimeStep />
          }

          return (
            <CustomQuestionAnswerStepReview step={step.step as QuestionAndAnswerStep} />
          )
        }

        return (
          <StepReviewListItem key={step.id} sectionIndex={index}>
            {getStepChildren()}
          </StepReviewListItem>
        )
      })}
    </Box>
  )
}
