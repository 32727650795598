import { createSelector } from 'reselect'

const getLocalState = state => state.entities.plugins

export const makeSelectPlugin = () =>
  createSelector(
    getLocalState,
    (_, props) => props,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (plugins: any, id) => plugins.find(plugin => plugin.id === id)
  )
