/* eslint-disable no-underscore-dangle */
import { fabric } from 'fabric'

import { type Vertex } from '../builder/steps/api'

// define a function that can locate the controls.
// this function will be used both for drawing and for interaction.
function polygonPositionHandler(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  this: any,
  _dim,
  _finalMatrix,
  fabricObject: fabric.Polygon
): fabric.Point {
  const point = fabricObject.points?.[this.pointIndex]

  if (!point) {
    return new fabric.Point(0, 0)
  }

  const x = point.x - fabricObject.pathOffset.x
  const y = point.y - fabricObject.pathOffset.y

  return fabric.util.transformPoint(
    new fabric.Point(x, y),
    fabric.util.multiplyTransformMatrices(
      fabricObject.canvas?.viewportTransform || [1, 0, 0, 1, 0, 0],
      fabricObject.calcTransformMatrix()
    )
  )
}

export const calculatePolygonArea = points => {
  let area = 0

  for (let i = 0; i < points.length; i += 1) {
    const p1 = points[i]
    const p2 = points[(i + 1) % points.length]

    area += p1.x * p2.y - p2.x * p1.y
  }

  return Math.abs(area / 2)
}

const getObjectSizeWithStroke = (object: fabric.Object): fabric.Point => {
  const { strokeUniform, scaleX, scaleY, strokeWidth, width, height } = object

  if (!scaleX || !scaleY || !width || !height || !strokeWidth) {
    return new fabric.Point(0, 0)
  }

  const stroke = new fabric.Point(
    strokeUniform ? 1 / scaleX : 1,
    strokeUniform ? 1 / scaleY : 1
  ).multiply(strokeWidth)

  return new fabric.Point(width + stroke.x, height + stroke.y)
}

// define a function that will define what the control does
// this function will be called on every mouse move after a control has been
// clicked and is being dragged.
// The function receive as argument the mouse event, the current transform object
// and the current position in canvas coordinate
// transform.target is a reference to the current object being transformed
const actionHandler = (
  _eventData: MouseEvent,
  transform: fabric.Transform,
  x: number,
  y: number
): boolean => {
  const poly = transform.target as fabric.Polygon

  if (!poly.points) {
    return false
  }

  const mouseLocalPosition = poly.toLocalPoint(new fabric.Point(x, y), 'center', 'center')
  const polygonBaseSize = getObjectSizeWithStroke(poly)
  const size = poly._getTransformedDimensions(0, 0)
  const finalPointPosition = new fabric.Point(
    (mouseLocalPosition.x * polygonBaseSize.x) / size.x + poly.pathOffset.x,
    (mouseLocalPosition.y * polygonBaseSize.y) / size.y + poly.pathOffset.y
  )

  const pointIndex = poly.controls[poly.__corner]?.pointIndex as number

  poly.points[pointIndex] = finalPointPosition

  return true
}

// define a function that can keep the polygon in the same position when we change its
// width/height/top/left.
const anchorWrapper =
  (anchorIndex: number) =>
  (eventData: MouseEvent, transform: fabric.Transform, x: number, y: number): boolean => {
    const fabricObject = transform.target as fabric.Polygon
    const point = fabricObject.points?.[anchorIndex]

    if (!point) {
      return false
    }

    const absolutePoint = fabric.util.transformPoint(
      new fabric.Point(
        point.x - fabricObject.pathOffset.x,
        point.y - fabricObject.pathOffset.y
      ),
      fabricObject.calcTransformMatrix()
    )

    const actionPerformed = actionHandler(eventData, transform, x, y)

    fabricObject._setPositionDimensions({})
    const polygonBaseSize = getObjectSizeWithStroke(fabricObject)
    const newX = (point.x - fabricObject.pathOffset.x) / polygonBaseSize.x
    const newY = (point.y - fabricObject.pathOffset.y) / polygonBaseSize.y

    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5)

    return actionPerformed
  }

export const getPolygonEditControls = (polygon: fabric.Polygon) =>
  (polygon.points ?? []).reduce(
    (acc, _, index) => ({
      ...acc,
      [`p${index}`]: new fabric.Control({
        positionHandler: polygonPositionHandler,
        actionHandler: anchorWrapper(
          index > 0 ? index - 1 : (polygon?.points?.length ?? 0) - 1
        ),
        actionName: 'modifyPolygon',
        pointIndex: index,
      }),
    }),
    {}
  )

const calculateScaling = (
  canvasWidth?: number,
  canvasHeight?: number,
  imageWidth?: number,
  imageHeight?: number
) => {
  if (!canvasWidth || !canvasHeight || !imageWidth || !imageHeight) {
    return 1
  }

  const widthScale = canvasWidth / imageWidth
  const heightScale = canvasHeight / imageHeight

  return Math.min(widthScale, heightScale)
}

export const loadImageIntoCanvas = (imageUrl: string, fabricCanvas: fabric.Canvas) => {
  fabric.Image.fromURL(imageUrl, img => {
    const scaling = calculateScaling(
      fabricCanvas.width,
      fabricCanvas.height,
      img.width,
      img.height
    )

    img.scale(scaling)

    fabricCanvas.setBackgroundImage(img, fabricCanvas.renderAll.bind(fabricCanvas), {
      scaleX: scaling,
      scaleY: scaling,
    })
  })
}

export const createPolygon = (points: Array<Vertex>, borderColor: string = 'blue') =>
  new fabric.Polygon(points, {
    fill: 'transparent',
    strokeWidth: 2,
    stroke: borderColor,
    scaleX: 1,
    scaleY: 1,
    objectCaching: false,
    transparentCorners: false,
    cornerColor: 'blue',
    exactBoundingBox: true,
  })
