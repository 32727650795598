import { Center, Flex, Icon, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { type ReactNode } from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { HiUserCircle } from 'react-icons/hi'

const KeyValueCard = ({
  labelKey,
  value,
}: {
  labelKey: I18nKey
  value?: string | number
}) => (
  <Flex
    direction="column"
    justify="space-between"
    bg="white"
    borderRadius={8}
    p={4}
    height="90px"
    fontSize={18}
    pos="relative"
  >
    {!!value && (
      <Icon
        color={colors.blue[500]}
        pos="absolute"
        right={2}
        top={2}
        as={BsFillCheckCircleFill}
      />
    )}
    <Text color={colors.gray[500]}>
      <FormattedMessage id={labelKey} />
    </Text>
    <Text title={value?.toString()} noOfLines={1}>
      {value}
    </Text>
  </Flex>
)

type SteppedModalPreviewAreaProps = {
  propertyCards: Array<{
    labelKey: I18nKey
    value?: string | number
  }>
  title?: string
  titlePlaceholderKey: I18nKey
  imageContent: ReactNode
  imagePlaceholderKey: I18nKey
}

export const SteppedModalPreviewArea = ({
  imageContent,
  imagePlaceholderKey,
  propertyCards,
  titlePlaceholderKey,
  title,
}: SteppedModalPreviewAreaProps) => (
  <Stack width="460px" bg={colors.gray[50]} px={10} py={8} justify="center" gap={0}>
    <Text fontSize={24} fontWeight={600} mb={6}>
      {title ?? <FormattedMessage id={titlePlaceholderKey} />}
    </Text>

    <Center
      flexDirection="column"
      bg="white"
      aspectRatio="16/9"
      borderRadius={12}
      gap={2}
      overflow="hidden"
      mb={14}
    >
      {imageContent ?? (
        <>
          <Icon as={HiUserCircle} fontSize={40} color={colors.gray[300]} />
          <Text color={colors.gray[500]}>
            <FormattedMessage id={imagePlaceholderKey} />
          </Text>
        </>
      )}
    </Center>

    <SimpleGrid columns={2} gap={2}>
      {propertyCards.map(({ labelKey, value }) => (
        <KeyValueCard key={labelKey} labelKey={labelKey} value={value} />
      ))}
    </SimpleGrid>
  </Stack>
)
