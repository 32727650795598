import { Box, Text } from '@chakra-ui/react'

export const SessionCardText = ({ fields }: { fields: Array<string> }) => (
  <Box mr={2} overflow="hidden">
    {fields.map((field, i) => (
      <Text
        // eslint-disable-next-line react/no-array-index-key
        key={`${i} ${field}`}
        fontSize="sm"
        color="gray.500"
        sx={{ overflowWrap: 'break-word', whiteSpace: 'break-word' }}
        mb={1}
      >
        {field}
      </Text>
    ))}
  </Box>
)
