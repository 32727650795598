import { PageWrapper } from '@repo/ui'

import { AdvancedTableSection } from './component-book-page/advanced-table-section'
import { CardGridSection } from './component-book-page/card-grid-section'
import { HorizontalStepperSection } from './component-book-page/horizontal-stepper-section'
import { ModalHooksSection } from './component-book-page/modal-hooks-section'
import { PageWrapperSection } from './component-book-page/page-wrapper-section'
import { RHFSection } from './component-book-page/rhf-section'
import { SectionHeaderSection } from './component-book-page/section-header-section'
import { SelectWithPopoverSection } from './component-book-page/select-with-popover-section'

export const ComponentBookPage = () => (
  <PageWrapper
    title="Components"
    subtitle="Possible properties and variants of the components are shown at this page"
    sx={{ overflowY: 'scroll' }}
    backTo="/"
    backLabelKey="common.back"
  >
    <AdvancedTableSection />
    <RHFSection />
    <HorizontalStepperSection />
    <SelectWithPopoverSection />
    <PageWrapperSection />
    <SectionHeaderSection />
    <ModalHooksSection />
    <CardGridSection />
  </PageWrapper>
)
