import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { Formik } from 'formik'

import { ContentLoader } from '../../builder/components/content-loader'
import { cohortsFormValidator } from '../helpers/cohort-form-validator'
import { useCohorts } from '../use-cohorts'
import { CohortForm } from './cohort-form'

export const CohortEditModal = ({
  cohortId,
  isOpen,
  onClose,
}: {
  cohortId: string
  isOpen: boolean
  onClose: () => void
}) => {
  const { updateCohort, useCohort } = useCohorts()
  const { data, status, refresh: refreshCohortDetails } = useCohort(cohortId)

  const onSubmit = async formData => {
    const { name } = formData

    try {
      await updateCohort({ name }, cohortId)
      refreshCohortDetails()
      showToast({ messageKey: 'common.alert.created', status: 'success' })
    } catch (err) {
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }

    onClose()
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ContentLoader status={status}>
          <ModalHeader>
            <FormattedMessage id="cohort.editModal.title" />
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody pb={4}>
            <Formik
              onSubmit={onSubmit}
              onReset={onClose}
              initialValues={{ name: data?.name || '', numberTokens: 0 }}
              validationSchema={cohortsFormValidator}
              validateOnBlur={false}
            >
              <CohortForm mode="edit" />
            </Formik>
          </ModalBody>
        </ContentLoader>
      </ModalContent>
    </Modal>
  )
}
