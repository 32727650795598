import types from '../../entities/folders/types'

const initialState: {
  updating: boolean
  creating: boolean
  deleting: boolean
} = {
  updating: false,
  creating: false,
  deleting: false,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, import/no-default-export
export default (state = initialState, { type }: any = {}) => {
  switch (type) {
    case types.ON_CREATE_FOLDER_REQUEST:
      return { ...state, creating: true }
    case types.ON_CREATE_FOLDER_SUCCESS:
      return { ...state, creating: false }
    case types.ON_UPDATE_FOLDER_REQUEST:
      return { ...state, updating: true }
    case types.ON_UPDATE_FOLDER_SUCCESS:
      return { ...state, updating: false }
    case types.ON_DELETE_FOLDER_REQUEST:
      return { ...state, deleting: true }
    case types.ON_DELETE_FOLDER_SUCCESS:
      return { ...state, deleting: false }
    default:
      return state
  }
}
