import { Box, Flex } from '@chakra-ui/react'
import { Spinner } from '@repo/ui'
import { useCallback, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { type ColumnSortableItemValue } from '../../recruiting/recording-filters/column-control-data'
import { type RecordingSession } from '../../recruiting/recording-sessions-api'
import { CardView } from './card-view'
import { ListView } from './list-view'
import { RecordingProcessingModal } from './recording-processing-modal'
import { type RecordingView } from './use-recording-view'

type VideoListProps = {
  onMoreRecordingsNeeded: () => void
  isValidating: boolean
  onDeleteRecording: (recordingId: string) => Promise<void>
  sessions: Array<RecordingSession>
  totalCountSessions: number
  showDeleteButtons: boolean
  onSelectRecording: (recording: RecordingSession) => void
  selectedView: RecordingView
  columns: Array<ColumnSortableItemValue>
  updateRecordings: () => void
  isRootFolder: boolean
}

export const VideoList = (props: VideoListProps) => {
  const {
    columns,
    selectedView,
    showDeleteButtons,
    onMoreRecordingsNeeded,
    onDeleteRecording,
    onSelectRecording,
    sessions,
    totalCountSessions,
    updateRecordings,
    isValidating,
    isRootFolder,
  } = props

  const viewProps = {
    columns,
    selectedView,
    showDeleteButtons,
    sessions,
    isRootFolder,
    updateRecordings,
  }

  const [showPending, setShowPending] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const handleOnDeleteClick = async (selectedIndex: number) => {
    const selectedRecording = sessions[selectedIndex]

    if (selectedRecording) {
      await onDeleteRecording(selectedRecording.id)
    }
  }

  const handleOnRecordingClick = (selectedIndex: number) => {
    const selectedRecording = sessions[selectedIndex]

    if (selectedRecording?.status !== 'done') {
      setShowPending(true)

      return
    }

    onSelectRecording(selectedRecording)
  }

  const hideDetails = useCallback(() => setShowPending(false), [])

  return (
    <>
      <Box
        display="flex"
        flex="1 0"
        flexDirection="column"
        minHeight="0"
        overflow="auto"
        sx={{ '> div': { height: '100%' } }}
        ref={containerRef}
        id="scrollContainer"
      >
        <InfiniteScroll
          dataLength={sessions.length}
          next={onMoreRecordingsNeeded}
          hasMore={sessions.length < totalCountSessions}
          loader={null}
          scrollableTarget="scrollContainer"
          height="100%"
        >
          {selectedView === 'list' ? (
            <ListView
              onClick={handleOnRecordingClick}
              onDeleteClick={handleOnDeleteClick}
              {...viewProps}
            />
          ) : (
            <CardView
              onClick={handleOnRecordingClick}
              onDeleteClick={handleOnDeleteClick}
              {...viewProps}
            />
          )}
          {isValidating && (
            <Flex h={20} justify="center" align="center">
              <Spinner />
            </Flex>
          )}
        </InfiniteScroll>
      </Box>
      <RecordingProcessingModal isOpen={showPending} onClose={hideDetails} />
    </>
  )
}
