import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Stack,
} from '@chakra-ui/react'
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { FormattedMessage } from '@repo/i18n'
import { isDevelopment } from '@repo/utils'
import { produce } from 'immer'
import { cloneDeep } from 'lodash-es'
import { BsArrowClockwise } from 'react-icons/bs'

import { DEFAULT_DASHBOARD_LAYOUT } from '../../shared/constants'
import { useDashboardContext } from '../../shared/dashboard-context'
import { type DashboardCard, type DashboardSectionId } from '../../shared/types'
import { SortableCard } from './customize-dashboard-modal/sortable-card'
import { SortableSection } from './customize-dashboard-modal/sortable-section'

export const CustomizeDashboardModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const { layout, setLayoutAndCallDebouncedSave } = useDashboardContext()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleSectionDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (over?.id && active.id !== over?.id) {
      let newLayout = [...layout]
      const oldIndex = newLayout.findIndex(s => s.id === active.id)
      const newIndex = newLayout.findIndex(s => s.id === over.id)

      newLayout = arrayMove(newLayout, oldIndex, newIndex)

      setLayoutAndCallDebouncedSave(newLayout)
    }
  }

  const handleCardDragEnd = (event: DragEndEvent, sectionId: DashboardSectionId) => {
    const { active, over } = event

    if (over?.id && active.id !== over?.id) {
      const newLayout = produce(layout, draft => {
        const section = draft.find(s => s.id === sectionId)!

        const oldIndex = section.cards.findIndex(c => c.id === active.id)
        const newIndex = section.cards.findIndex(c => c.id === over.id)

        section.cards = arrayMove(section.cards, oldIndex, newIndex)
      })

      setLayoutAndCallDebouncedSave(newLayout)
    }
  }

  const resetLayout = () => {
    setLayoutAndCallDebouncedSave(cloneDeep(DEFAULT_DASHBOARD_LAYOUT))
  }

  return (
    <Drawer size="md" isOpen={isOpen} onClose={onClose} placement="right">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <FormattedMessage id="dashboard.page.customize.modal.title" />
        </DrawerHeader>

        <DrawerBody display="flex" flexDir="column" overflowX="hidden">
          <Button
            variant="outline"
            leftIcon={<BsArrowClockwise size="1.25em" />}
            flexShrink={0}
            alignSelf="flex-end"
            onClick={resetLayout}
          >
            <FormattedMessage id="dashboard.page.customize.modal.reset" />
          </Button>

          <Stack gap={12}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleSectionDragEnd}
              modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            >
              <SortableContext items={layout} strategy={verticalListSortingStrategy}>
                {layout.map(section => (
                  <SortableSection sectionId={section.id} key={section.id}>
                    <DndContext
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={e => handleCardDragEnd(e, section.id)}
                      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                    >
                      <SortableContext
                        items={section.cards}
                        strategy={verticalListSortingStrategy}
                      >
                        {section.cards.map((card: DashboardCard) => {
                          // Hide `scenarioRepetitions` until we get a green light for it
                          if (card.id === 'scenarioRepetitions' && !isDevelopment) {
                            return
                          }

                          return (
                            <SortableCard
                              card={card}
                              key={card.id}
                              onToggleVisibility={() => {
                                const newLayout = produce(layout, draft => {
                                  draft
                                    .find(s => s.id === section.id)!
                                    .cards.find(c => c.id === card.id)!.visible =
                                    !card.visible
                                })

                                setLayoutAndCallDebouncedSave(newLayout)
                              }}
                            />
                          )
                        })}
                      </SortableContext>
                    </DndContext>
                  </SortableSection>
                ))}
              </SortableContext>
            </DndContext>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
