import { Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

import { type CohortProgramStatus } from '../types/cohort-program'

export const CohortProgramStateColumn = ({ status }: { status: CohortProgramStatus }) => {
  // Using let we save some locs

  let color

  switch (status) {
    case 'upcoming':
      color = 'primary-dark'
      break
    case 'active':
      color = 'indicator.green-dark'
      break
    case 'finished':
      color = 'indicator.red-dark'
      break
    default:
  }

  return (
    <Text color={color}>
      <FormattedMessage id={`cohort.programs.list.state.${status}`} />
    </Text>
  )
}
