import { HiViewGrid } from 'react-icons/hi'

import { useLocalStorage } from '../../hooks/use-local-storage'
import { SidebarItem } from './shared/sidebar-item'
import { SidebarSection } from './shared/sidebar-section'

export const SidebarGroupDevOnly = () => {
  const [, setHideDevOnly] = useLocalStorage('hideDevOnlySidebar')

  return (
    <SidebarSection labelKey="sidebar.item.dev" onLabelClick={() => setHideDevOnly(true)}>
      <SidebarItem
        labelKey="sidebar.item.components"
        to="/components"
        icon={HiViewGrid}
      />
    </SidebarSection>
  )
}
