import { mode, transparentize } from '@chakra-ui/theme-tools'
import { type Dictionary } from '@repo/utils'

// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/alert.ts

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const variantSubtle = (props: Dictionary<any>) => {
  const { theme, colorScheme: c } = props

  const lightBg = c === 'blue' ? `${c}.50` : `${c}.100`
  const darkBg = transparentize(`${c}.200`, 0.16)(theme)

  return {
    container: {
      bg: mode(lightBg, darkBg)(props),
    },
  }
}

export const alertTheme = {
  variants: {
    subtle: variantSubtle,
  },
}
