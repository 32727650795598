import { Box, Button } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'

import { ButtonContainer } from './button-container'
import { ContentContainer } from './content-container'
import { InfoGraph } from './info-graph'

type IntroScreenProps = {
  handleContinue: VoidFunction
  handleCancel: VoidFunction
}

export const IntroScreen = ({ handleContinue, handleCancel }: IntroScreenProps) => {
  const { formatMessage } = useIntl()

  return (
    <ContentContainer
      title={formatMessage({ id: 'coaching.benchmarking.wizard.intro.title' })}
      subtitle={formatMessage({ id: 'coaching.benchmarking.wizard.intro.subtitle' })}
    >
      <Box mt={8}>
        <InfoGraph currentStep={1} />
      </Box>
      <ButtonContainer>
        <Button variant="outline" onClick={handleCancel}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button onClick={handleContinue}>
          <FormattedMessage id="common.continue" />
        </Button>
      </ButtonContainer>
    </ContentContainer>
  )
}
