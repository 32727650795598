import {
  Box,
  Tooltip as ChakraTooltip,
  type TooltipProps as ChakraTooltipProps,
} from '@chakra-ui/react'

import { QuestionHelpCircle } from './CustomIcons'

export const Tooltip = ({ children, ...chakraProps }: ChakraTooltipProps) => (
  <ChakraTooltip
    placement="bottom-start"
    bg="gray.50"
    color="black"
    borderRadius={5}
    fontWeight="lighter"
    border="1px"
    borderColor="gray.100"
    openDelay={500}
    closeDelay={500}
    {...chakraProps}
  >
    {children}
  </ChakraTooltip>
)

export const HelpTooltip = ({
  label,
  ...chakraProps
}: Omit<ChakraTooltipProps, 'children'>) => (
  <Tooltip label={label} {...chakraProps}>
    <Box as="span" px={2}>
      <QuestionHelpCircle />
    </Box>
  </Tooltip>
)
