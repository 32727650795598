export const loadImageFromFile = async (file: File): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('No file selected'))

      return
    }

    const image = new Image()

    image.onload = () => {
      resolve(image)
    }

    image.onerror = err => {
      reject(err)
    }

    image.src = URL.createObjectURL(file)
  })
