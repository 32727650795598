import { Text } from '@chakra-ui/react'
import { showToast } from '@repo/ui'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardGrid } from '../../components/card-grid'
import { useBenchmarkProfiles } from '../hooks/use-benchmark-profiles'

interface BenchmarkTemplateProps {
  title: string
  description: string
  extId: string
  icon?: string
}
interface BenchmarkTemplateCardProps {
  template: BenchmarkTemplateProps
  refreshBenchmarkList?: () => void
}

export const BenchmarkTemplateCard = ({
  template,
  refreshBenchmarkList,
}: BenchmarkTemplateCardProps) => {
  const { description, title, extId, icon } = template
  const { duplicateTemplate } = useBenchmarkProfiles()
  const navigate = useNavigate()
  const handleDuplicateTemplate = useCallback(async () => {
    try {
      await duplicateTemplate(extId)
      refreshBenchmarkList?.()
      showToast({
        messageKey: 'coaching.benchmarking.templates.menu.duplicate.success',
        status: 'success',
      })

      navigate('/coaching/benchmarking/profiles')
    } catch (err) {
      showToast({
        messageKey: 'coaching.benchmarking.templates.menu.duplicate.error',
        status: 'error',
      })
    }
  }, [duplicateTemplate, extId, refreshBenchmarkList, navigate])

  return (
    <CardGrid.Card
      key={extId}
      title={title}
      image={icon}
      footer={{
        type: 'cta',
        labelKey: 'coaching.benchmarking.templates.menu.duplicate',
        onClick: handleDuplicateTemplate,
      }}
    >
      <Text fontSize={14}>{description}</Text>
    </CardGrid.Card>
  )
}
