import { Slider, SliderMark, SliderThumb, SliderTrack } from '@chakra-ui/react'
import { type ComponentProps } from 'react'
import { Controller, type FieldPath, type FieldValues } from 'react-hook-form'

import { colors } from '../../theme/main'
import { FormControlWrapper } from './shared/form-control-wrapper'
import { type FieldBaseProps } from './shared/types'

export const FormFieldSlider = <T extends FieldValues, TName extends FieldPath<T>>(
  props: FieldBaseProps<T, ComponentProps<typeof Slider>, TName>
) => {
  const { name, control, rules, shouldUnregister, componentProps } = props

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FormControlWrapper fieldBaseProps={props} error={fieldState.error}>
          <Slider {...field} {...componentProps} mt={5}>
            <SliderMark
              value={field.value}
              textAlign="center"
              bg={colors.blue[500]}
              color="white"
              mt="-9"
              ml="-5"
              w="10"
              fontSize={14}
            >
              {field.value}
            </SliderMark>
            <SliderTrack />
            <SliderThumb boxSize={6} border="2px solid" borderColor={colors.blue[500]} />
          </Slider>
        </FormControlWrapper>
      )}
    />
  )
}
