import { HStack, Stack } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { RHF } from '@repo/ui'
import { useFormRules } from '@repo/ui/src/react-hook-forms/use-form-rules'
import { useForm } from 'react-hook-form'

import { useScenarioBuilderData } from '../../../shared/use-scenario-builder-data'
import { useRealtimeBuilderContext } from '../../shared/realtime-builder-context'
import { SkillSelectAutocomplete } from '../../shared/skill-select-autocomplete'
import { type BehavioralGoalFormValues } from '../../shared/types'
import { BehaviorButtonGroup } from '../shared/behavior-button-group'
import { useStageStepperContext } from '../shared/stage-stepper-context'
import { MAX_GOALS_PER_STAGE } from './constants'
import {
  type Suggestion,
  SuggestionButtons,
} from './use-behavioral-goal-form/suggestion-buttons'

export const useBehavioralGoalForm = (defaultValues?: BehavioralGoalFormValues) => {
  const form = useForm<BehavioralGoalFormValues>({
    defaultValues,
    mode: 'onTouched',
  })

  const [, , , , , formatInProgramLanguage] = useScenarioBuilderData()
  const { scenario, program } = useRealtimeBuilderContext()
  const { activeStageIndex } = useStageStepperContext()
  const { formatMessage } = useIntl()
  const rules = useFormRules()

  const { control, setValue, watch } = form

  const isDescriptionEmpty = !watch('description')?.length

  const onSuggestionClick = (suggestion: Suggestion) => {
    setValue('description', formatInProgramLanguage(suggestion.nameKey), {
      shouldValidate: true,
      shouldDirty: true,
    })

    setValue('skillName', suggestion.skillName, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const goals = scenario.missionStages?.[activeStageIndex]?.winningBehaviorsRealtime ?? []

  const formFieldsJxs = (
    <Stack gap={8}>
      <Stack gap={2}>
        <RHF.TextArea
          control={control}
          name="description"
          labelKey="behavioral.goal.form.description"
          componentProps={{
            placeholder: formatMessage({
              id: 'behavioral.goal.form.description.placeholder',
            }),
          }}
          rules={{
            ...rules.stringShortRequired,
            validate: {
              ...rules.stringShortRequired.validate,
              duplicate: value =>
                rules.validateDuplicatedText({
                  value,
                  allValues: goals.map(goal => goal.description),
                  shouldMatchTwice: false,
                  ignoredValue: defaultValues?.description,
                }),
            },
          }}
        />
        {isDescriptionEmpty && (
          <SuggestionButtons onSuggestionClick={onSuggestionClick} />
        )}
      </Stack>
      <HStack gap={5} alignItems="flex-start">
        <BehaviorButtonGroup
          currentItemCount={goals.length}
          maxItemCount={MAX_GOALS_PER_STAGE}
          onClick={() => {
            // TODO: Nothing happens until we enable avoided behaviors
          }}
          labelKey="behavioral.goal.form.behavior.button.group.label"
        />

        <SkillSelectAutocomplete
          control={control}
          name="skillName"
          language={program.language}
        />
      </HStack>
    </Stack>
  )

  return [form, formFieldsJxs] as const
}
