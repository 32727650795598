import { CheckIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  HStack,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, EmptyStateIndicator } from '@repo/ui'
import { useEffect, useRef, useState } from 'react'
import { BsPlayCircleFill } from 'react-icons/bs'
import { MdOutlinePauseCircle } from 'react-icons/md'

import { SelectWithPopover } from '../../components/select-with-popover'

const VoiceItem = <T extends Voice>({
  voice,
  onChange,
  isSelected,
  currentPlayingVoice,
  setCurrentPlayingVoice,
}: {
  voice: T
  onChange: (voice: T) => void
  isSelected?: boolean
  currentPlayingVoice: string | null
  setCurrentPlayingVoice: (voiceKey: string | null) => void
}) => {
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const isPlaying = currentPlayingVoice === voice.key

  const playAudio = () => {
    if (audioRef.current) {
      if (currentPlayingVoice !== voice.key) {
        setCurrentPlayingVoice(voice.key)
      }

      audioRef.current.load()
      audioRef.current.play()
    }
  }

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause()
      setCurrentPlayingVoice(null)
    }
  }

  useEffect(() => {
    if (!isPlaying && audioRef.current) {
      audioRef.current.pause()
    }
  }, [isPlaying])

  return (
    <VStack alignItems="flex-start">
      {isSelected && (
        <Text fontWeight={500} fontSize="14px">
          <FormattedMessage id="common.selected" />
        </Text>
      )}

      <HStack
        w="full"
        key={voice.key}
        cursor="pointer"
        borderRadius="md"
        _hover={{ bgColor: colors.lightBlue }}
        p={2}
      >
        <HStack flex={1} onClick={() => onChange(voice)}>
          {isSelected && <CheckIcon color={colors.blue[500]} />}
          <Box>{voice.label}</Box>
        </HStack>

        <Box zIndex={1}>
          <audio ref={audioRef} onEnded={() => setCurrentPlayingVoice(null)}>
            <source src={voice.audioUrl} type="audio/wav" />
            <track kind="captions" src="" label="No captions available" />
          </audio>
          <IconButton
            onClick={isPlaying ? pauseAudio : playAudio}
            icon={
              <Icon
                as={isPlaying ? MdOutlinePauseCircle : BsPlayCircleFill}
                fontSize="1.25rem"
                color={colors.blue[500]}
              />
            }
            aria-label="Play"
            variant="ghost"
            _hover={{ bgColor: 'transparent' }}
          />
        </Box>
      </HStack>
      {isSelected && <Divider orientation="horizontal" />}
    </VStack>
  )
}

type Voice = {
  key: string
  label: string
  audioUrl: string
}

interface VoiceSelectorProps<T extends Voice> {
  voices: Array<T>
  value?: T
  onChange: (voice: T) => void
}

export const VoiceSelector = <T extends Voice>({
  onChange,
  voices,
  value,
}: VoiceSelectorProps<T>) => {
  const [search, setSearch] = useState('')

  const [currentPlayingVoice, setCurrentPlayingVoice] = useState<string | null>(null)

  const filteredVoices = voices.filter(v =>
    v.label.toLowerCase().includes(search.toLowerCase())
  )

  const sortedVoices = value
    ? [value, ...filteredVoices.filter(v => v.key !== value.key)]
    : filteredVoices

  const disclosure = useDisclosure({
    onClose: () => setCurrentPlayingVoice(null),
  })

  return (
    <SelectWithPopover
      titleKey="common.voice"
      placeholderKey="common.select"
      inputValue={value?.label}
      searchInputProps={{
        value: search,
        onChange: setSearch,
        placeholderKey: 'common.search',
      }}
      disclosure={disclosure}
    >
      {sortedVoices.length ? (
        <Box maxH="400px" overflowY="auto" p={4}>
          {sortedVoices.map(voice => (
            <VoiceItem
              key={voice.key}
              voice={voice}
              onChange={v => {
                onChange(v)
                disclosure.onClose()
              }}
              isSelected={value?.key === voice.key}
              currentPlayingVoice={currentPlayingVoice}
              setCurrentPlayingVoice={setCurrentPlayingVoice}
            />
          ))}
        </Box>
      ) : (
        <EmptyStateIndicator messageKey="general.noDataAvailable" />
      )}
    </SelectWithPopover>
  )
}
