import { ListItem, UnorderedList } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { countBy, find, uniq } from 'lodash-es'

import { type CoachingRecordingSession } from '../types/coaching-recording'
import { InfoBox } from './info-box'

export const MIN_REQUIRED_SESSIONS = 5

type Props = {
  selectedSessions: Array<string>
  sessions: Array<CoachingRecordingSession>
}

export const SessionSelectionWarnings = ({ selectedSessions, sessions }: Props) => {
  const numberMissingSessions = MIN_REQUIRED_SESSIONS - selectedSessions.length
  const hasNotEnoughSessionsAvailable =
    sessions && sessions.length < MIN_REQUIRED_SESSIONS

  const hasNotEnoughSessionsSelected = selectedSessions.length < MIN_REQUIRED_SESSIONS
  const numberOfDistinctScenariosSelected = sessions
    .filter(session => selectedSessions.includes(session.extId))
    .reduce<
      Array<string>
    >((scenarioExtIds, session) => uniq([...scenarioExtIds, session.scenario.extId]), []).length

  const userIdentifiers = selectedSessions.reduce<Array<string>>(
    (userIdentifiersAcc, sessionExtId) => {
      const userIdentifier = find(sessions, { extId: sessionExtId })?.userIdentifier

      if (!userIdentifier) {
        throw new Error('User identifier not found')
      }

      return [...userIdentifiersAcc, userIdentifier]
    },
    []
  )

  const expectedNumberSessionPerUser =
    selectedSessions.length / uniq(userIdentifiers).length

  const selectedSessionsPerUser = countBy(userIdentifiers)
  const hasUnbalancedSessionsPerUser = Object.values(selectedSessionsPerUser).some(
    numberOfSessions => numberOfSessions !== expectedNumberSessionPerUser
  )

  const showWarnings =
    hasNotEnoughSessionsAvailable ||
    hasNotEnoughSessionsSelected ||
    hasUnbalancedSessionsPerUser ||
    numberOfDistinctScenariosSelected > 1

  return showWarnings ? (
    <InfoBox>
      <UnorderedList>
        {hasNotEnoughSessionsAvailable && (
          <ListItem>
            <FormattedMessage
              id="coaching.benchmarking.recordings.sessions.missing"
              values={{ numberMinimumSessions: MIN_REQUIRED_SESSIONS }}
            />
          </ListItem>
        )}
        {!hasNotEnoughSessionsAvailable && hasNotEnoughSessionsSelected && (
          <ListItem>
            <FormattedMessage
              id="coaching.benchmarking.recordings.selected.missing"
              values={{ numberMissingSessions }}
            />
          </ListItem>
        )}
        {numberOfDistinctScenariosSelected > 1 && (
          <ListItem>
            <FormattedMessage id="coaching.benchmarking.recordings.selected.multipleScenarios" />
          </ListItem>
        )}
        {hasUnbalancedSessionsPerUser && (
          <ListItem>
            <FormattedMessage id="coaching.benchmarking.recordings.selected.unbalancedSelection" />
          </ListItem>
        )}
      </UnorderedList>
    </InfoBox>
  ) : (
    <> </>
  )
}
