import { cloneDeep, debounce, isEqual, take } from 'lodash-es'
import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type RefObject,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { type CohortsListItem } from '../../coaching-participants/types/cohorts-list-item'
import { useCohorts } from '../../coaching-participants/use-cohorts'
import {
  DEFAULT_DASHBOARD_LAYOUT,
  MAX_NUMBER_OF_COHORTS_FOR_SELECTION,
} from './constants'
import { createDashboardDateRange } from './create-dashboard-date-range'
import {
  getDashboardLayout,
  saveDashboardLayout,
} from './dashboard-context/dashboard-layout-api'
import { fetchDashboardData } from './fetch-dashboard-data'
import { generateSelectedDataSlotPerCard } from './generate-data-slot-per-card'
import { mergeFetchedLayoutWithDefaultOne } from './merge-fetched-layout-with-default-one'
import {
  type DashboardFilterDates,
  type DashboardLayout,
  type DataSlot,
  type DataSlotType,
  type Filters,
  type SelectedDataSlotPerCard,
} from './types'

type DashboardDataSlots = Record<DataSlotType, DataSlot>

type DashboardPageState = 'loading' | 'error' | 'loaded' | 'no-cohorts'

interface DashboardContextType {
  filters: Filters
  allCohorts: Array<CohortsListItem>
  selectedCohorts: Array<string>
  setSelectedCohorts: (cohorts: Array<string>) => void
  setSelectedDates: (dates: DashboardFilterDates) => void
  selectedDataSlotPerCard: SelectedDataSlotPerCard
  setSelectedDataSlotPerCard: Dispatch<SetStateAction<SelectedDataSlotPerCard>>
  dataSlots: DashboardDataSlots
  setDataSlots: Dispatch<SetStateAction<DashboardDataSlots>>
  pageState: DashboardPageState
  setPageState: Dispatch<SetStateAction<DashboardPageState>>
  retryFetchingData: () => void
  overviewPageWrapperRef: RefObject<HTMLDivElement>
  layout: DashboardLayout
  setLayoutAndCallDebouncedSave: (layout: DashboardLayout) => void
  isLoadingLayout: boolean
}

const DashboardContext = createContext<DashboardContextType | null>(null)

export const DashboardContextProvider = ({ children }: PropsWithChildren) => {
  const overviewPageWrapperRef = useRef<HTMLDivElement>(null)
  const [pageState, setPageState] = useState<DashboardPageState>('loading')

  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [layout, setLayout] = useState<DashboardLayout>(
    cloneDeep(DEFAULT_DASHBOARD_LAYOUT)
  )

  useEffect(() => {
    const initLayout = async () => {
      try {
        const fetchedLayout = await getDashboardLayout()

        if (!fetchedLayout) {
          return setLayout(cloneDeep(DEFAULT_DASHBOARD_LAYOUT))
        }

        const { mergedLayout, shouldUpdateSavedConfig } =
          mergeFetchedLayoutWithDefaultOne(fetchedLayout, DEFAULT_DASHBOARD_LAYOUT)

        // save merged layout to db if saved config is outdated
        if (shouldUpdateSavedConfig) {
          await saveDashboardLayout(mergedLayout)
        }

        setLayout(mergedLayout)
      } catch (error) {
        setLayout(cloneDeep(DEFAULT_DASHBOARD_LAYOUT))
      } finally {
        setIsLoadingLayout(false)
      }
    }

    initLayout()
  }, [])

  const [dataSlots, setDataSlots] = useState<DashboardDataSlots>({
    lastWeek: { status: 'loading' },
    lastMonth: { status: 'empty' },
    custom: { status: 'loading' },
  })

  const [selectedDataSlotPerCard, setSelectedDataSlotPerCard] =
    useState<SelectedDataSlotPerCard>(generateSelectedDataSlotPerCard('lastWeek'))

  const [selectedCohorts, setSelectedCohorts] = useState<Array<string>>([])
  const [selectedDates, setSelectedDates] = useState<DashboardFilterDates>(
    createDashboardDateRange('lastWeek')
  )

  const fetchAndSetDashboardData = async (
    dates: DashboardFilterDates,
    cohorts: Array<string>
  ) => {
    try {
      setPageState('loading')
      const dashboardData = await fetchDashboardData({
        dates,
        cohorts,
      })

      // checking this, because this function runs at both initialization and retry on error
      const fetchedLastWeek = isEqual(dates, createDashboardDateRange('lastWeek'))

      const loadedSlot: DataSlot = { status: 'loaded', data: dashboardData }
      const emptySlot: DataSlot = { status: 'empty' }

      setDataSlots({
        lastMonth: emptySlot,
        lastWeek: fetchedLastWeek ? loadedSlot : emptySlot,
        custom: loadedSlot,
      })

      setPageState('loaded')
    } catch (err) {
      setDataSlots({
        lastMonth: { status: 'empty' },
        lastWeek: { status: 'empty' },
        custom: { status: 'empty' },
      })

      setPageState('error')
    }
  }

  const retryFetchingData = useCallback(() => {
    // initialize dashboard again, using current filters
    setDataSlots({
      lastWeek: { status: 'empty' },
      lastMonth: { status: 'empty' },
      custom: { status: 'loading' },
    })

    setSelectedDataSlotPerCard(generateSelectedDataSlotPerCard('custom'))
    fetchAndSetDashboardData(selectedDates, selectedCohorts)
  }, [selectedCohorts, selectedDates])

  const { useCohortsList } = useCohorts()
  const { data: cohortData } = useCohortsList({ isBenchmarking: false })
  const allCohorts = useMemo(() => cohortData ?? [], [cohortData])

  const filters = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _filters: Filters = {
      selectedCohorts: [],
      selectedDates,
    }

    // we can rely on custom data slot, because it will always have the correct cohorts
    if (dataSlots.custom.status === 'loaded') {
      const { cohortExtIds } = dataSlots.custom.data

      _filters.selectedCohorts = cohortExtIds.map(cohortExtId => ({
        name: allCohorts.find(cohort => cohort.extId === cohortExtId)?.name ?? '',
        extId: cohortExtId,
      }))
    }

    return _filters
  }, [allCohorts, dataSlots.custom, selectedDates])

  const debouncedUpdateEntity = useMemo(
    () =>
      debounce(async newLayout => {
        await saveDashboardLayout(newLayout)
      }, 3000),
    []
  )

  const setLayoutAndCallDebouncedSave = useCallback<
    Dispatch<SetStateAction<DashboardLayout>>
  >(
    newLayout => {
      setLayout(newLayout)
      debouncedUpdateEntity(newLayout)
    },
    [debouncedUpdateEntity]
  )

  const contextValue = useMemo<DashboardContextType>(
    () => ({
      filters,
      allCohorts,
      selectedCohorts,
      dataSlots,
      selectedDataSlotPerCard,
      setDataSlots,
      setSelectedDataSlotPerCard,
      pageState,
      setPageState,
      retryFetchingData,
      setSelectedCohorts,
      setSelectedDates,
      overviewPageWrapperRef,
      layout,
      setLayoutAndCallDebouncedSave,
      isLoadingLayout,
    }),
    [
      dataSlots,
      selectedDataSlotPerCard,
      filters,
      allCohorts,
      selectedCohorts,
      setDataSlots,
      setSelectedDataSlotPerCard,
      pageState,
      setPageState,
      retryFetchingData,
      setSelectedCohorts,
      setSelectedDates,
      overviewPageWrapperRef,
      layout,
      setLayoutAndCallDebouncedSave,
      isLoadingLayout,
    ]
  )

  const initializedRef = useRef(false)

  useEffect(() => {
    if (!initializedRef.current && cohortData) {
      initializedRef.current = true

      const cohortExtIds = cohortData.map(cohort => cohort.extId)

      // render no-cohorts state if there are no cohorts available
      if (cohortExtIds.length === 0) {
        setPageState('no-cohorts')
        setDataSlots({
          lastMonth: { status: 'empty' },
          lastWeek: { status: 'empty' },
          custom: { status: 'empty' },
        })

        return
      }

      const limitedCohortExtIds = take(cohortExtIds, MAX_NUMBER_OF_COHORTS_FOR_SELECTION)

      setSelectedCohorts(limitedCohortExtIds)
      fetchAndSetDashboardData(selectedDates, limitedCohortExtIds)
    }
    // this is a one-time effect for initialization, so no need to add any dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortData])

  return (
    <DashboardContext.Provider value={contextValue}>{children}</DashboardContext.Provider>
  )
}

export const useDashboardContext = () => {
  const state = useContext(DashboardContext)

  if (state === null) {
    throw new Error('no value has been provided to DashboardContext')
  }

  return state
}
