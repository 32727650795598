import { Box, Flex, Stack } from '@chakra-ui/react'
import { type ReactNode } from 'react'

import { getAdjustedScore } from '../hooks/use-result'

type ScoreBarProps = {
  startLabel?: ReactNode
  endLabel?: ReactNode
  lowerLimit?: number
  upperLimit?: number
  value: number
}

export const ScoreBar = ({
  startLabel,
  endLabel,
  lowerLimit = 0.0,
  upperLimit = 1.0,
  value = 0,
}: ScoreBarProps) => {
  // Normalize values between percentile based thresholds
  const normalizedScore = getAdjustedScore(value, lowerLimit, upperLimit)

  return (
    <Stack flexGrow={1} direction="row" align="center" spacing={2}>
      {startLabel}
      {`OverallScore:  ${value}`}
      <Flex
        pos="relative"
        align="center"
        flexGrow={1}
        bgGradient="linear(to-r, red.200, green.200)"
        h={4}
        borderRadius={10}
      >
        <Box
          pos="absolute"
          left={`${98 * normalizedScore}%`}
          w={1}
          h={8}
          bgColor="blackAlpha.800"
          borderRadius={4}
          zIndex={100}
        />
      </Flex>
      {endLabel}
    </Stack>
  )
}
