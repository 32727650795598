import { Button, HStack } from '@chakra-ui/react'
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { FormattedMessage } from '@repo/i18n'
import { colors, PageWrapper } from '@repo/ui'
import { assertExists } from '@repo/utils'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AppHeaderTitle } from '../components/app-header-title'
import { CardGrid } from '../components/card-grid'
import { CenteredSpinner } from '../components/centered-spinner'
import { SearchInput } from '../components/search-input'
import { type Scenario } from '../types/api-types'
import { client } from '../utils/openapi-client'
import { useOpenapiSWR } from '../utils/use-openapi-swr'
import { CreateScenarioCard } from './training-detail-page/create-scenario-card'
import { ScenarioCard } from './training-detail-page/scenario-card'

type ScenariosList = Array<Scenario>

export const TrainingDetailPage = () => {
  const { programExtId, trainingExtId } = useParams()

  assertExists(programExtId)
  assertExists(trainingExtId)

  const {
    data: scenarios,
    isLoading: isLoadingScenarios,
    mutate: mutateScenarios,
  } = useOpenapiSWR('getScenarios', { params: { programExtId, trainingExtId } })

  const { data: program } = useOpenapiSWR('getProgramByExtId', {
    params: { extId: programExtId },
  })

  const { data: training, isLoading: isLoadingTraining } = useOpenapiSWR(
    'getTrainingByExtId',
    {
      params: { programExtId, extId: trainingExtId },
    }
  )

  const [scenarioListForSorting, setScenarioListForSorting] = useState<ScenariosList>([])
  const [inReorderMode, setInReorderMode] = useState(false)
  const [isReordering, setIsReordering] = useState(false)

  const [search, setSearch] = useState('')

  const isPageInitializing =
    isLoadingTraining || isLoadingScenarios || !scenarios || !training

  const filteredScenarios = useMemo(() => {
    if (!search.length) {
      return scenarios ?? []
    }

    return (
      scenarios?.filter(s => {
        const searchableStrings = [s.title, s.description]

        return searchableStrings.some(str =>
          str?.toLowerCase().includes(search.toLowerCase())
        )
      }) ?? []
    )
  }, [scenarios, search])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  if (isPageInitializing) {
    return <CenteredSpinner />
  }

  const handleScenarioDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (over?.id && active.id !== over?.id) {
      setScenarioListForSorting(oldList => {
        const oldIndex = oldList.findIndex(s => s.extId === active.id)
        const newIndex = oldList.findIndex(s => s.extId === over.id)

        const newList = arrayMove(oldList, oldIndex, newIndex)

        return newList
      })
    }
  }

  const handleToggleReordering = async () => {
    if (!inReorderMode) {
      setInReorderMode(true)
      setScenarioListForSorting(scenarios)

      return
    }

    setIsReordering(true)
    await client.put('reorderScenarios', {
      params: { path: { programExtId, trainingExtId } },
      body: scenarioListForSorting.map((s, index) => ({
        extId: s.extId,
        order: index,
      })),
    })

    setIsReordering(false)
    setInReorderMode(false)
    mutateScenarios(() => scenarioListForSorting, false)
  }

  const isReadonly = program?.status !== 'unpublished'

  return (
    <PageWrapper
      backLabelKey="training.goBack"
      backTo=".."
      sx={{ overflowY: 'auto' }}
      title={training.title}
    >
      <AppHeaderTitle formattedMessageId="coaching.programList.title" />

      {!!scenarios.length && (
        <HStack
          mb={4}
          justify="flex-end"
          pb={4}
          borderBottom="2px solid"
          borderColor={colors.gray[200]}
        >
          <Button
            isLoading={isReordering}
            onClick={handleToggleReordering}
            variant={inReorderMode ? 'solid' : 'outline'}
            isDisabled={isReadonly}
          >
            <FormattedMessage
              id={inReorderMode ? 'common.done' : 'training.reorderScenarios'}
            />
          </Button>

          {!inReorderMode && (
            <SearchInput
              onChange={setSearch}
              value={search}
              sx={{ alignSelf: 'flex-start' }}
            />
          )}
        </HStack>
      )}
      <CardGrid>
        {inReorderMode ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleScenarioDragEnd}
          >
            <SortableContext
              items={scenarioListForSorting.map(s => ({ ...s, id: s.extId }))}
              strategy={rectSortingStrategy}
            >
              {scenarioListForSorting.map(scenario => (
                <ScenarioCard key={scenario.extId} scenario={scenario} sortable />
              ))}
            </SortableContext>
          </DndContext>
        ) : (
          <>
            {!isReadonly && <CreateScenarioCard />}
            {filteredScenarios.map(scenario => (
              <ScenarioCard key={scenario.extId} scenario={scenario} />
            ))}
          </>
        )}
      </CardGrid>
    </PageWrapper>
  )
}
