import { SimpleGrid, type SimpleGridProps } from '@chakra-ui/react'

import { GridCard } from './card-grid/grid-card'
import { GridCreateCard } from './card-grid/grid-create-card'
import { GridSkeletonCard } from './card-grid/grid-skeleton-card'

export const CardGrid = (props: SimpleGridProps) => (
  <SimpleGrid
    templateColumns="repeat(auto-fill, minmax(270px, 1fr))"
    gap={6}
    autoRows="1fr"
    {...props}
  />
)

CardGrid.Card = GridCard
CardGrid.SkeletonCard = GridSkeletonCard
CardGrid.CreateCard = GridCreateCard
