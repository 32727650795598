import { Stack } from '@chakra-ui/react'
import { type FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { SteppedModalFormStepFooter } from '../../stepped-modal-form-step-footer'
import { type TraineeFormValues, type TraineeStepKey } from '../types'
import { orderedTraineeSteps } from './shared/ordered-trainee-steps'
import { useTraineeFormContext } from './trainee-form-context'
import { StepCompany } from './trainee-form-step-renderer/step-company'
import { StepGeneral } from './trainee-form-step-renderer/step-general'
import { StepProfessional } from './trainee-form-step-renderer/step-professional'

const stepFormFieldComponents: Record<TraineeStepKey, FC> = {
  general: StepGeneral,
  company: StepCompany,
  professional: StepProfessional,
}

const TraineeFormStepRendererInner = () => {
  const { getSubmitHandler, formData, currentStep, setCurrentStep, mode } =
    useTraineeFormContext()

  const form = useForm<TraineeFormValues[typeof currentStep]>({
    mode: 'onTouched',
    defaultValues: formData[currentStep],
  })

  const FormFieldComponent = stepFormFieldComponents[currentStep]

  return (
    <FormProvider {...form} key={currentStep}>
      <Stack
        as="form"
        onSubmit={form.handleSubmit(getSubmitHandler(currentStep))}
        h="full"
        gap={10}
      >
        <Stack gap={10}>
          <FormFieldComponent />
        </Stack>

        <SteppedModalFormStepFooter
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          steps={orderedTraineeSteps}
          entityNameKey="common.trainee"
          mode={mode}
        />
      </Stack>
    </FormProvider>
  )
}

// this component re-renders the inner component using useForm() at each step, to make sure a new form instance is created for each step
export const TraineeFormStepRenderer = () => {
  const { currentStep } = useTraineeFormContext()

  return <TraineeFormStepRendererInner key={currentStep} />
}
