import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { useMemo, useState } from 'react'

import { type CoachingResultDimensionExcludedUnused } from '../../../../coaching/coaching-result'
import { CustomLineChart } from '../shared/custom-line-chart'
import { ToggleList, type ToggleListItem } from '../shared/toggle-list'
import { useDashboardCardData } from '../shared/use-dashboard-card-data'

const toggleListColors = [
  colors.lineChartColors[300],
  colors.lineChartColors[200],
  colors.lineChartColors[600],
  colors.lineChartColors[500],
]

interface MetricsComparisonModalProps {
  isOpen: boolean
  onClose: () => void
  cohortName: string
  cohortIndex: number
  scoringVariables: Array<CoachingResultDimensionExcludedUnused>
}

export const MetricsComparisonModal = ({
  isOpen,
  onClose,
  cohortName,
  scoringVariables,
  cohortIndex,
}: MetricsComparisonModalProps) => {
  const [activeDataKeys, setActiveDataKeys] = useState(scoringVariables)
  const { dataSlot } = useDashboardCardData('communicationAndLanguage')

  const [prevOpenState, setPrevOpenState] = useState(isOpen)

  if (isOpen !== prevOpenState) {
    setPrevOpenState(isOpen)
    setActiveDataKeys(scoringVariables)
  }

  const dataConfig = useMemo(() => {
    const config: Partial<
      Record<
        CoachingResultDimensionExcludedUnused,
        ToggleListItem<CoachingResultDimensionExcludedUnused>
      >
    > = {}

    scoringVariables.forEach((variable: CoachingResultDimensionExcludedUnused, index) => {
      config[variable] = {
        labelKey: `coaching.result.${variable}.label`,
        color: toggleListColors[index]!,
        dataKey: variable,
      }
    })

    return config
  }, [scoringVariables])

  const metricScoresPerTimestampData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    return data.startDatesFormatted.map((date, index) => {
      const metricScores = dataSlot.data.metricScoresPerTimestamp[index]!
      const categoryValues = {
        sentenceLength: null,
        socialFocus: null,
        adjectives: null,
        understandability: null,
        speakingPausesMinute: null,
        positiveLanguage: null,
        speakingSpeed: null,
        facialExpressions: null,
      }

      // Populate category arrays with metric scores
      metricScores.forEach(metric => {
        categoryValues[metric.metric] = metric.cohorts[cohortIndex] ?? null
      })

      return {
        date,
        sentenceLength: categoryValues.sentenceLength!,
        socialFocus: categoryValues.socialFocus!,
        adjectives: categoryValues.adjectives!,
        understandability: categoryValues.understandability!,
        speakingPausesMinute: categoryValues.speakingPausesMinute!,
        positiveLanguage: categoryValues.positiveLanguage!,
        speakingSpeed: categoryValues.speakingSpeed!,
        facialExpressions: categoryValues.facialExpressions!,
      }
    })
  }, [dataSlot, cohortIndex])

  return (
    <Modal size="xl" onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent minH={450}>
        <ModalHeader maxW={520}>
          <Text fontSize="16px">
            <FormattedMessage
              id="dashboard.cards.communicationAndLanguage.details.modal.title"
              values={{
                cohortName,
              }}
            />
          </Text>
          <Text fontSize="14px" color={colors.gray[600]} fontWeight="normal">
            <FormattedMessage id="dashboard.cards.communicationAndLanguage.details.modal.subtitle" />
          </Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody mb={8} mt={3} h={400}>
          <>
            <Box height={300} mb={8}>
              <CustomLineChart
                data={metricScoresPerTimestampData}
                yAxisTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                yAxisLabelKey="dashboard.cards.communicationAndLanguage.details.modal.yaxis.label"
                dataConfig={activeDataKeys.map(dataKey => {
                  const config = dataConfig[dataKey]
                  const color = config?.color ?? ''

                  return { dataKey, color }
                })}
                valueSuffix="%"
              />
            </Box>
            <Box px={5} pb={10}>
              <ToggleList
                items={Object.values(dataConfig)}
                value={activeDataKeys}
                onChange={newDataKeys => setActiveDataKeys(newDataKeys)}
              />
            </Box>
            <Center>
              <Button onClick={onClose}>
                <FormattedMessage id="dashboard.cards.communicationAndLanguage.details.modal.close.button" />
              </Button>
            </Center>
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
