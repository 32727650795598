import { HorizontalStepper } from '../../../../components/horizontal-stepper'
import { usePersonaFormContext } from './persona-form-context'
import { orderedPersonaSteps } from './shared/ordered-persona-steps'

export const PersonaFormStepper = () => {
  const { currentStep } = usePersonaFormContext()

  return (
    <HorizontalStepper
      currentStep={currentStep}
      stepsWithTitles={orderedPersonaSteps.map(step => ({
        key: step,
        titleKey: `scenario.builder.ai.steps.conversation-partner.personaModal.${step}`,
      }))}
      sx={{ px: 10 }}
    />
  )
}
