import { Button, Flex, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import {
  type PromisingModalContentRenderer,
  RHF,
  showToast,
  useFormRules,
  usePromisingModal,
} from '@repo/ui'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'

import { DashboardInfoBox } from '../../dashboard/dashboard-page/dashboard-router/dashboard-overview/shared/dashboard-info-box'
import { useLocalStorage } from '../../hooks/use-local-storage'
import { ClappingAnimation } from './clapping-animation'
import { useScenarioBuilderData } from './use-scenario-builder-data'

type FormValues = {
  title: string
}

type RenameScenarioProps = {
  showInfoBox: boolean
}

const RenameScenarioModal: PromisingModalContentRenderer<
  boolean,
  RenameScenarioProps
> = ({ resolve, customProps }) => {
  const rules = useFormRules()
  const [, , , scenarioSWR, updateScenarioDetails] = useScenarioBuilderData()

  const [scenarioTitle] = useState(() => scenarioSWR.data?.title ?? '')

  const { control, formState, handleSubmit } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      title: scenarioTitle,
    },
  })

  const [, setHasCompletedScenarioBuilder] = useLocalStorage(
    'hasCompletedScenarioBuilder'
  )

  const onSubmit = useCallback(
    async (data: FormValues) => {
      setHasCompletedScenarioBuilder(true)

      if (formState.isDirty) {
        const res = await updateScenarioDetails({
          title: data.title,
        })

        if (res.error) {
          return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
        }

        scenarioSWR.mutate(res.data, false)
      }

      resolve(true)
    },
    [
      formState.isDirty,
      updateScenarioDetails,
      scenarioSWR,
      resolve,
      setHasCompletedScenarioBuilder,
    ]
  )

  return (
    <Flex
      as="form"
      direction="column"
      onSubmit={event => {
        // on manual builder, this modal is rendered inside another 'form' component.
        // this is to prevent the outer form from submitting when this form is submitted
        event.stopPropagation()

        return handleSubmit(onSubmit)(event)
      }}
    >
      <Stack px={6}>
        <ClappingAnimation />

        <Text fontWeight={600} fontSize={18}>
          <FormattedMessage id="scenario.builder.enterNameModal.title" />
        </Text>
        <Text>
          <FormattedMessage id="scenario.builder.enterNameModal.description" />
        </Text>

        <RHF.Input control={control} name="title" rules={rules.stringShortRequired} />

        {customProps?.showInfoBox && (
          <DashboardInfoBox sx={{ fontSize: 'sm', mt: 8, mb: 2, lineHeight: 'short' }}>
            <FormattedMessage id="scenario.builder.ai.steps.review.info" />
          </DashboardInfoBox>
        )}
      </Stack>

      <Button
        type="submit"
        alignSelf="flex-end"
        size="md"
        mt={8}
        isDisabled={!formState.isValid}
        isLoading={formState.isSubmitting}
      >
        <FormattedMessage id="common.continue" />
      </Button>
    </Flex>
  )
}

export const useRenameScenarioModal = (showInfoBox: boolean = false) =>
  usePromisingModal<boolean, RenameScenarioProps>(RenameScenarioModal, undefined, {
    showInfoBox,
  })
