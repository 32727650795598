import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useState } from 'react'

export const AddCohortParticipantModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  onConfirm: () => Promise<void> | void
  isOpen: boolean
  onClose: () => void
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    setIsLoading(true)
    await onConfirm()

    setIsLoading(false)
    onClose()
  }

  return (
    <Modal size="lg" onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt="24px" px="24px" pb="0px !important">
          <FormattedMessage id="cohort.participant.reAdd.participant.confirmModal.title" />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody p="24px">
          <Text>
            <FormattedMessage id="cohort.participant.reAdd.participant.confirmModal.text" />
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <ButtonGroup>
            <Button variant="outline" onClick={onClose}>
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button type="submit" isLoading={isLoading} onClick={onSubmit}>
              <FormattedMessage id="common.confirm" />
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
