import { Button, Center, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { type ModalContentRenderer, showToast, useModal } from '@repo/ui'
import { type SubmitHandler } from 'react-hook-form'

import { client } from '../../../utils/openapi-client'
import { useProgramsList } from '../../shared/api'
import { type ProgramFormValues } from '../../shared/types'
import { useProgramForm } from '../shared/use-program-form'

const CreateProgramForm: ModalContentRenderer = ({ closeModal }) => {
  const [form, formFieldsJsx] = useProgramForm()
  const { handleSubmit, formState } = form
  const { mutate } = useProgramsList()

  const onSubmit: SubmitHandler<ProgramFormValues> = async data => {
    try {
      const res = await client.post('createProgram', { body: data })

      if (res.error) {
        throw new Error()
      }

      mutate(
        programs => [
          {
            ...res.data,
            hasBreakingChanges: false,
            hasRecordingSessions: false,
            hasRegisteredCoachingUsers: false,
            sharedPrograms: [],
            trainingsCount: 0,
          },
          ...(programs ?? []),
        ],
        false
      )

      showToast({ messageKey: 'program.createModal.success', status: 'success' })
      closeModal()
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
      {formFieldsJsx}

      <Center gap={3} mt={6}>
        <Button variant="outline" onClick={closeModal}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          type="submit"
          isDisabled={!formState.isValid || !!formState.errors.imageUrl}
          isLoading={formState.isSubmitting}
        >
          <FormattedMessage id="common.create" />
        </Button>
      </Center>
    </Stack>
  )
}

export const useCreateProgramModal = () => {
  const modal = useModal(CreateProgramForm, {
    titleKey: 'program.createModal.title',
  })

  return modal
}
