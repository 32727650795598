import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select as ChakraSelect,
} from '@chakra-ui/react'
import { useField } from 'formik'
import { get, isString } from 'lodash-es'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Select = ({ help, label, name, ...restProps }: any) => {
  const [field, meta] = useField(name)

  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}

      <ChakraSelect
        color={meta.touched && meta.error ? 'danger' : undefined}
        {...field}
        {...restProps}
      >
        {restProps.placeholder && <option value="">{restProps.placeholder}</option>}
        {get(restProps, 'options', []).map(option => {
          let optionValue
          let optionLabel

          if (isString(option)) {
            optionValue = option
            optionLabel = option
          } else {
            optionValue = option.value
            optionLabel = option.label
          }

          return (
            <option key={optionValue} value={optionValue}>
              {optionLabel}
            </option>
          )
        })}
      </ChakraSelect>

      {meta.touched && meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      {help && <FormHelperText>{help} </FormHelperText>}
    </FormControl>
  )
}
