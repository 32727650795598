import { Radio } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'

import { MenuItemDiv } from '../MenuItemDiv'

type RadioSelectItemProps = {
  value: string
}

export const RadioSelectItem = ({
  children,
  value,
}: PropsWithChildren<RadioSelectItemProps>) => (
  <MenuItemDiv>
    <Radio value={value}>{children}</Radio>
  </MenuItemDiv>
)
