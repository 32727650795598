import { Box, Button, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { createColumnHelper } from '@tanstack/react-table'
import { take, takeRight } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { MdQueryStats } from 'react-icons/md'

import { type CoachingResultDimensionExcludedUnused } from '../../../coaching/coaching-result'
import { AdvancedTable } from '../../../components/advanced-table'
import { CenteredSpinner } from '../../../components/centered-spinner'
import { MetricsComparisonModal } from './communication-and-language-card-details/metrics-comparison-modal'
import { PercentageBadge } from './communication-and-language-card-details/percentage-badge'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { ScoreCircle } from './shared/score-circle'
import { ToggleBetweenTwo } from './shared/toggle-between-two'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

type RowData = {
  id: string
  cohortIndex: number
  cohortName: string
  sentenceLength: number | null
  socialFocus: number | null
  adjectives: number | null
  understandability: number | null
  speakingPausesMinute: number | null
  positiveLanguage: number | null
  speakingSpeed: number | null
  facialExpressions: number | null
}

const columnHelper = createColumnHelper<RowData>()

const getColumns = (onTrendClick: (cohortName: string, cohortIndex: number) => void) => [
  columnHelper.accessor('cohortName', {
    header: () => (
      <FormattedMessage id="dashboard.cards.communicationAndLanguage.details.cohortName.table.header" />
    ),
  }),
  columnHelper.accessor('speakingPausesMinute', {
    header: () => <FormattedMessage id="coaching.result.speakingPausesMinute.label" />,
    cell: props => <PercentageBadge value={props.getValue()} />,
  }),
  columnHelper.accessor('sentenceLength', {
    header: () => <FormattedMessage id="coaching.result.sentenceLength.label" />,
    cell: props => <PercentageBadge value={props.getValue()} />,
  }),
  columnHelper.accessor('socialFocus', {
    header: () => <FormattedMessage id="coaching.result.socialFocus.label" />,
    cell: props => <PercentageBadge value={props.getValue()} />,
  }),
  columnHelper.accessor('adjectives', {
    header: () => <FormattedMessage id="coaching.result.adjectives.label" />,
    cell: props => <PercentageBadge value={props.getValue()} />,
  }),
  columnHelper.accessor('facialExpressions', {
    header: () => <FormattedMessage id="coaching.result.facialExpressions.label" />,
    cell: props => <PercentageBadge value={props.getValue()} />,
  }),
  columnHelper.accessor('understandability', {
    header: () => <FormattedMessage id="coaching.result.understandability.label" />,
    cell: props => <PercentageBadge value={props.getValue()} />,
  }),
  columnHelper.accessor('speakingSpeed', {
    header: () => <FormattedMessage id="coaching.result.speakingSpeed.label" />,
    cell: props => <PercentageBadge value={props.getValue()} />,
  }),
  columnHelper.accessor('positiveLanguage', {
    header: () => <FormattedMessage id="coaching.result.positiveLanguage.label" />,
    cell: props => <PercentageBadge value={props.getValue()} />,
  }),
  columnHelper.display({
    id: 'trendChart',
    cell: props => (
      <Button
        leftIcon={<MdQueryStats />}
        onClick={() =>
          onTrendClick(props.row.original.cohortName, props.row.original.cohortIndex)
        }
      >
        <FormattedMessage id="dashboard.cards.communicationAndLanguage.details.trendOverTime.table.button" />
      </Button>
    ),
  }),
]

const initialRowValues: RowData = {
  id: '',
  cohortName: '',
  cohortIndex: 0,
  sentenceLength: null,
  socialFocus: null,
  adjectives: null,
  understandability: null,
  speakingPausesMinute: null,
  positiveLanguage: null,
  speakingSpeed: null,
  facialExpressions: null,
}

export const CommunicationAndLanguageCardDetails = () => {
  const { dataSlot } = useDashboardCardData('communicationAndLanguage')
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [showStrengths, setShowStrengths] = useState(true)
  const [selectedCohortName, setSelectedCohortName] = useState('')
  const [selectedCohortIndex, setSelectedCohortIndex] = useState(0)

  const onTrendClick = useCallback(
    (cohortName: string, cohortIndex: number) => {
      setSelectedCohortName(cohortName)
      setSelectedCohortIndex(cohortIndex)
      onOpen()
    },
    [onOpen]
  )

  const { tableRows, strengthKeys, weakKeys } = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return { tableRows: [], strengthKeys: [], weakKeys: [] }
    }

    const { data } = dataSlot

    const rows = data.cohortNames.map((cohortName, index) => {
      const rowData = {
        ...initialRowValues,
        id: data.cohortExtIds[index]!,
        cohortName,
        cohortIndex: index,
      }

      data.metricScores.forEach(({ metric, cohorts }) => {
        rowData[metric] = cohorts[index]
      })

      return rowData
    })

    const keys = data.metricScores.map(({ metric }) => metric)

    return { tableRows: rows, strengthKeys: take(keys, 4), weakKeys: takeRight(keys, 4) }
  }, [dataSlot])

  const { filteredColumns, scoringVariables } = useMemo(() => {
    const metrics = showStrengths ? strengthKeys : weakKeys
    const allColumns = getColumns(onTrendClick)

    // Get only columns that match the current metrics + cohortName + trendChart
    const visibleColumnIds = ['cohortName', ...metrics, 'trendChart']

    const columnsToShow = allColumns.filter(column => {
      const columnId = 'accessorKey' in column ? column.accessorKey : column.id

      return visibleColumnIds.includes(columnId as string)
    })

    return { filteredColumns: columnsToShow, scoringVariables: metrics }
  }, [showStrengths, strengthKeys, weakKeys, onTrendClick])

  if (dataSlot.status !== 'loaded') {
    return <CenteredSpinner />
  }

  const { data } = dataSlot

  return (
    <DetailPageWrapper
      cardId="communicationAndLanguage"
      titleStartContent={
        data.communicationScoreOverall !== null && (
          <ScoreCircle value={data.communicationScoreOverall} />
        )
      }
    >
      <VStack alignItems="flex-start" mb={4}>
        <Box>
          <Text fontSize="12px" fontWeight={500} mt={5} mb={1} color={colors.gray[600]}>
            <FormattedMessage id="dashboard.cards.communicationAndLanguage.details.scoring.variables.toggle" />
          </Text>
          <ToggleBetweenTwo
            isLeftActive={showStrengths}
            toggleValue={() => setShowStrengths(!showStrengths)}
            left={
              <Text mx={3}>
                <FormattedMessage id="dashboard.cards.communicationAndLanguage.details.strengths.toggle.option" />
              </Text>
            }
            right={
              <Text mx={3}>
                <FormattedMessage id="dashboard.cards.communicationAndLanguage.details.weaknesses.toggle.option" />
              </Text>
            }
          />
        </Box>
      </VStack>

      <AdvancedTable columns={filteredColumns} data={tableRows} />

      <MetricsComparisonModal
        isOpen={isOpen}
        onClose={onClose}
        cohortName={selectedCohortName}
        cohortIndex={selectedCohortIndex}
        scoringVariables={
          // excluded version is a subset, so this is safe
          scoringVariables as Array<CoachingResultDimensionExcludedUnused>
        }
      />
    </DetailPageWrapper>
  )
}
