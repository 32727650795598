import { api } from '../../../utils/api'
import { tagsToArray } from '../../../utils/plugins'
import * as actions from './private/actions'
import { makeSelectPlugin } from './selectors'

const selectPlugin = makeSelectPlugin()

export const fetchPlugin = id => async (dispatch, getState) => {
  let plugin = selectPlugin(getState(), id)

  if (!plugin) {
    plugin = await api.plugins.getById(id)
  }

  const notifications = await api.plugins.notifications.getAll(id)

  dispatch(
    actions.receivePlugin({
      ...plugin,
      notifications,
    })
  )
}

export const updateNotifications = (id, notifications) => async dispatch => {
  try {
    await api.plugins.notifications.update(id, { notifications })
    await dispatch(fetchPlugin(id))
  } catch (error) {
    console.error(error)
    throw new Error(`Error! status: ${error}`)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updatePlugin = (id, formData, onSuccess?: any) => async dispatch => {
  const tags = tagsToArray(formData.tags)
  const { ...plugin } = formData
  const pluginData = {
    ...plugin,
    tags,
  }

  try {
    const updatedPlugin = await api.plugins.update(id, pluginData)

    dispatch(actions.updatePlugin(id, updatedPlugin))
    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    console.error(error)
    throw new Error(`Error! status: ${error}`)
  }
}
