import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { type Organization } from '../store/entities/organization/organization-types'
import {
  selectOrganization,
  selectOrganizationHasAiContentGen,
  selectOrganizationHasProgramNotificationsEnabled,
  selectOrganizationHasRealtimeConversationStep,
  selectOrganizationHasReviewedGDPR,
  selectOrganizationHasUnderstanding,
} from '../store/entities/organization/selectors'

export const useOrganization = () => {
  const organization = useSelector(selectOrganization) as Organization | null
  const hasReviewedGDPR: boolean = useSelector(selectOrganizationHasReviewedGDPR)
  const hasUnderstanding: boolean = useSelector(selectOrganizationHasUnderstanding)
  const hasRealtimeConversationStep = useSelector(
    selectOrganizationHasRealtimeConversationStep
  )

  const hasAiContentGen: boolean = useSelector(selectOrganizationHasAiContentGen)
  const hasProgramNotificationsEnabled: boolean | null = useSelector(
    selectOrganizationHasProgramNotificationsEnabled
  )

  const isGDPRCompliant = useMemo(() => {
    const {
      gdprEnabled: enabled,
      gdprCompany: company,
      privacyPolicy: policy,
    } = organization ?? {}

    if (!enabled) {
      return true
    }

    if (company) {
      // We don't need to provide default values here, because our backend makes sure
      // that only the correct data is stored in our database. Because of that we know
      // that company MUST have every prop we want to check OR the developer fucked up
      // by passing a wrong object to `isCompliant`.
      const companyProps = Object.keys(company)
      const isValidCompanyData =
        !!companyProps.length &&
        companyProps.reduce((isValid, prop) => {
          if (!isValid) {
            return false
          }

          return !!company[prop]
        }, true)

      if (policy && isValidCompanyData) {
        return true
      }
    }

    return false
  }, [organization])

  return {
    organization,
    isGDPRCompliant,
    hasReviewedGDPR,
    hasUnderstanding,
    hasRealtimeConversationStep,
    hasAiContentGen,
    hasProgramNotificationsEnabled,
  }
}
