import { Spacer, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useMemo } from 'react'

import { DashboardCard } from '../shared/dashboard-card'
import { useDashboardCardData } from '../shared/use-dashboard-card-data'
import { DashboardInfoBox } from './shared/dashboard-info-box'
import { DashboardNameValueList } from './shared/dashboard-name-value-list'
import { DetailPageLink } from './shared/detail-page-link'
import { NoDataInformation } from './shared/no-data-information'

export const PopularScenariosCard = () => {
  const { dataSlot } = useDashboardCardData('popularScenarios')

  const mostPopularScenariosData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot
    const scenarioNameMap = Object.entries(data.scenariosMetadata).reduce(
      (map, [extId, scenarioData]) => {
        map[extId] = scenarioData.scenarioName

        return map
      },
      {}
    )

    const mappedData = data.mostPopularScenariosOverall.map(scenario => ({
      name: scenarioNameMap[scenario.scenarioExtId],
      value: scenario.count,
    }))

    return mappedData.slice(0, 3)
  }, [dataSlot])

  const hasEnoughDataForDetails = mostPopularScenariosData.length > 2

  return (
    <DashboardCard
      titleKey="dashboard.cards.popularScenarios.title"
      descriptionKey="dashboard.cards.popularScenarios.subtitle"
      colSpan={3}
      wrapperProps={{
        alignItems: 'center',
        flex: 1,
        mt: 7,
        gap: 6,
      }}
    >
      {mostPopularScenariosData.length ? (
        <>
          <DashboardNameValueList
            data={mostPopularScenariosData}
            tableHeadLabelOne="dashboard.cards.popularScenarios.tab.column.scenario.name"
            tableHeadLabelTow="dashboard.cards.popularScenarios.tab.column.completions"
          />
          {hasEnoughDataForDetails ? (
            <>
              <DashboardInfoBox>
                <Text textAlign="start">
                  <FormattedMessage id="dashboard.page.viewMoreReminder" />
                </Text>
              </DashboardInfoBox>
              <Spacer />
              <DetailPageLink page="popular_scenario_details" />
            </>
          ) : (
            <>
              <Spacer />
              <DashboardInfoBox>
                <Text textAlign="start">
                  <FormattedMessage id="dashboard.page.changeFilterAlert" />
                </Text>
              </DashboardInfoBox>
            </>
          )}
        </>
      ) : (
        <NoDataInformation />
      )}
    </DashboardCard>
  )
}
