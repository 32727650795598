import { customSwrFetcher } from '../../../utils/swr-fetcher'
import { type DashboardLayout } from '../types'

const dashboardLayoutUrl = '/user_settings/dashboard_layout'

export const getDashboardLayout = () =>
  customSwrFetcher<DashboardLayout>(dashboardLayoutUrl, {
    method: 'GET',
  })

export const saveDashboardLayout = async (dashboardLayout: DashboardLayout) =>
  customSwrFetcher(dashboardLayoutUrl, {
    method: 'POST',
    body: JSON.stringify(dashboardLayout),
  })
