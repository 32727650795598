import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { Formik } from 'formik'

import { cohortProgramFormValidator } from '../helpers/cohort-program-form-validator'
import { type CohortProgramAssignFormValues } from '../types/cohort-program-assign-form-values'
import { useCohortPrograms } from '../use-cohort-programs'
import { useCohorts } from '../use-cohorts'
import { CohortProgramAssignForm } from './cohort-program-assign-form'

export const CohortProgramAssignModal = ({
  isOpen,
  onClose,
  cohortId,
}: {
  isOpen: boolean
  onClose: () => void
  cohortId: string
}) => {
  const { createCohortPrograms, useCohortProgramsList, useAvailablePrograms } =
    useCohortPrograms()

  const { useCohort } = useCohorts()
  const { refresh: refreshCohortStatistics } = useCohort(cohortId)
  const { refresh: refreshProgramList } = useCohortProgramsList(cohortId)
  const { data, status, isLoading } = useAvailablePrograms(cohortId)

  const availablePrograms = data || []

  const onSubmit = async formData => {
    const dates = {
      startDate: formData.startDate,
      endDate: formData.endDate || null,
    }

    try {
      await createCohortPrograms(
        { ...dates, programExtIds: formData.programIds },
        cohortId
      )

      refreshProgramList()
      refreshCohortStatistics()

      showToast({ messageKey: 'common.alert.created', status: 'success' })
      onClose()
    } catch (err) {
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent maxW="lg">
        <ModalHeader>
          <FormattedMessage id="cohort.program.assignModal.title" />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody pb={4}>
          {status === 'loaded' && (
            <Formik<CohortProgramAssignFormValues>
              onSubmit={onSubmit}
              onReset={onClose}
              initialValues={{
                programIds: [],
                startDate: new Date(),
                endDate: null,
              }}
              validationSchema={cohortProgramFormValidator}
              validateOnBlur={false}
            >
              <CohortProgramAssignForm
                availablePrograms={availablePrograms}
                isLoading={isLoading}
              />
            </Formik>
          )}
          {status === 'error' && <FormattedMessage id="general.error.fallback" />}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
