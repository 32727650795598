import { showToast } from '@repo/ui'

import {
  type CoachingStepFormFields,
  coachingStepFormValidator,
  coachingStepWithUnderstandingValidator,
  prepareCoachingStepsFormValues,
} from '../../../../builder/steps/api'
import { useOrganization } from '../../../../hooks/use-organization'
import {
  useStepNew,
  useStepsNew,
} from '../../../scenario-manual-builder-page/steps-context'
import { useProgramsList } from '../../api'
import { useDraggableStepsContext } from '../../draggable-steps-context'
import { getStepInitialData } from './constants'
import { StepFormEditor } from './step-form-editor'
import { StepForms } from './step-forms'

type StepEditorProps = {
  order: number
}

export const StepEditor = ({ order }: StepEditorProps) => {
  const { isStepCreationMode, activeStepType, activeStepId, onCancel } =
    useDraggableStepsContext()

  const { step, save: saveStep } = useStepNew(activeStepId)
  const { mutate: mutatePrograms } = useProgramsList()
  const { hasUnderstanding } = useOrganization()
  const { data: coachingStep } = step || {}
  const { moveNewStep } = useStepsNew()

  if (!coachingStep) {
    return null
  }

  const saveHandler = async (newValues: CoachingStepFormFields) => {
    try {
      const newStepData =
        newValues.step.stepType === 'coachingStepQA'
          ? {
              ...newValues,
              step: {
                ...newValues.step,
                hasUnderstanding,
              },
            }
          : newValues

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const stepData = await saveStep(newStepData)

      if (isStepCreationMode) {
        await moveNewStep(stepData, order, true)
      }

      await mutatePrograms()

      onCancel.current()

      showToast({ messageKey: 'common.alert.saved', status: 'success' })
    } catch (_error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }
  }

  return (
    <StepFormEditor<CoachingStepFormFields>
      initialData={
        isStepCreationMode
          ? {
              order: coachingStep.order,
              step: getStepInitialData(hasUnderstanding)[activeStepType],
            }
          : prepareCoachingStepsFormValues(coachingStep)
      }
      validationSchema={
        hasUnderstanding
          ? coachingStepWithUnderstandingValidator
          : coachingStepFormValidator
      }
      onSubmit={saveHandler}
    >
      <StepForms />
    </StepFormEditor>
  )
}
