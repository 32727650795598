/* eslint-disable @typescript-eslint/no-explicit-any */
import { Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

/**
 * @name RangeSlider
 *
 * @param {number} gap A number which will be multiplied with `step` and sets the
 * minimum gap between both thumbs.
 * @param {number} maxValue If no number is given the value will be `minValue + gap * step`
 * @param {number} single If set to `true` only one thumb will be shown and the values in
 * `values` will be identical.
 */
export const RangeSlider = ({
  defaultValue,
  end,
  gap,
  minValue,
  maxValue,
  onChange,
  onMount,
  single,
  start,
  step,
}) => {
  const minRangeRef = useRef<any>()
  const maxRangeRef = useRef<any>()
  const [values, setValues] = useState([
    single ? defaultValue || minValue : minValue,
    single ? defaultValue : Math.min(maxValue, end),
  ])

  const [spacePerStep, setSpacePerStep] = useState(0)

  // We return the position of the value a thumb has in pixels
  const getThumbPosition = useCallback(
    value => (value / step) * spacePerStep,
    [spacePerStep, step]
  )

  const steps = useMemo(() => end / step, [end, step])

  const handleChange = useCallback(
    event => {
      const minInputValue = parseFloat(minRangeRef.current.value)
      const maxInputValue = single ? minInputValue : parseFloat(maxRangeRef.current.value)
      const [minVal, maxVal] = values

      if (
        !single &&
        getThumbPosition(minInputValue) >
          getThumbPosition(maxInputValue) - Math.max(gap * spacePerStep, spacePerStep)
      ) {
        minRangeRef.current.value = minVal
        maxRangeRef.current.value = maxVal

        return
      }

      onChange(minInputValue, maxInputValue, event)
      setValues([minInputValue, single ? maxVal : maxInputValue])
    },
    [gap, getThumbPosition, onChange, single, spacePerStep, values]
  )

  useEffect(() => {
    // In case someone wants to do something with the refs. It's actually quite handy
    // if you need info like `width` from the range-slider.
    onMount(minRangeRef, maxRangeRef)
  }, [onMount])

  useEffect(() => {
    // TODO: `thumbWidth` needs to be flexible
    const thumbWidth = 10

    setSpacePerStep((minRangeRef.current.offsetWidth - thumbWidth) / steps)
  }, [steps])

  const [minVal, maxVal] = values

  return (
    <>
      <Slider
        ref={minRangeRef}
        defaultValue={minVal}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        type="range"
        min={start}
        max={end}
        step={step}
        onChange={handleChange}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      {!single && (
        <input
          ref={maxRangeRef}
          defaultValue={maxVal}
          type="range"
          min={start}
          max={end}
          step={step}
          onChange={handleChange}
        />
      )}
    </>
  )
}

RangeSlider.propTypes = {
  defaultValue: PropTypes.number,
  gap: PropTypes.number,
  end: PropTypes.number,
  maxValue: PropTypes.number,
  start: PropTypes.number,
  minValue: PropTypes.number,
  onChange: PropTypes.func,
  onMount: PropTypes.func,
  single: PropTypes.bool,
  step: PropTypes.number,
}

RangeSlider.defaultProps = {
  defaultValue: 0,
  gap: 1,
  end: 1,
  maxValue: 1,
  start: 0,
  minValue: 0,
  onChange: Function.prototype,
  onMount: Function.prototype,
  single: false,
  step: 0.005,
}
