import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { VideoManager } from '@repo/ui'

import { type RecordingUrls } from '../types/coaching-recording'

type SessionPlayerModalProps = {
  onClose: VoidFunction
  recordingUrls: RecordingUrls
}

export const SessionPlayerModal = ({
  onClose,
  recordingUrls,
}: SessionPlayerModalProps) => (
  <Modal onClose={onClose} isOpen isCentered>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <FormattedMessage id="sessionPlayer.modal.title" />
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody pb={4}>
        <VideoManager sources={recordingUrls} autoPlay autoPlayNext />
      </ModalBody>
    </ModalContent>
  </Modal>
)
