/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { saveAs } from 'file-saver'
import { Formik } from 'formik'
import { without } from 'lodash-es'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import {
  selectFolderHash,
  selectFolderTree,
} from '../../store/entities/folders/selectors'
import { api } from '../../utils/api'
import { ROOT_FOLDER_NAME } from '../../utils/constants'
import { ExportForm } from './export-form'

const validateForm = values => {
  const errors: any = {}

  if (!values.folders.length) {
    errors.folderSelection = 'Required'
  }

  return errors
}

const extractFolderIds = folderHash => {
  const allRecordingsFolderId = Object.entries(folderHash).reduce<any>(
    (acc, [id, { name }]: any) => {
      if (name === ROOT_FOLDER_NAME) {
        return id
      }

      return acc
    },
    undefined
  )

  const allFolderIds = Object.keys(folderHash)
  const folderIds = without(allFolderIds, allRecordingsFolderId)

  return { allRecordingsFolderId, folderIds }
}

const prepareQuery = formData => {
  const { depersonalized, withLinks, format, folders, dates } = formData
  const query: any = { depersonalized, withLinks, format }

  if (folders?.length) {
    query.folders = folders
  }

  if (dates) {
    query.start = dates.startDate.startOf('day').toISOString()
    query.end = dates.endDate.endOf('day').toISOString()
  }

  return query
}

export const ExportModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const folderTree = useSelector(selectFolderTree)
  const folderHash = useSelector(selectFolderHash)
  const { allRecordingsFolderId, folderIds } = extractFolderIds(folderHash)

  const onSubmit = async formData => {
    setIsSubmitted(true)
    const query = prepareQuery(formData)

    try {
      const report = await api.reports.analysis_results.get(query)

      saveAs(report, `Retorio_Analysis_Report.${formData.format}`)
      showToast({ messageKey: 'general.success.downloading.data', status: 'success' })
      onClose()
    } catch {
      setIsSubmitted(false)
      showToast({ messageKey: 'export.modal.export.error', status: 'error' })
    }
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="export.modal.title" />
          <Text fontSize="md" fontWeight="light">
            <FormattedMessage id="export.modal.subtitle" />
          </Text>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody pb={4}>
          {!isSubmitted && (
            <Formik
              onSubmit={onSubmit}
              onReset={onClose}
              validate={validateForm}
              initialValues={{
                format: 'xlsx',
                depersonalized: false,
                withLinks: false,
                folders: folderIds,
              }}
            >
              {props => (
                <ExportForm
                  folders={folderTree}
                  allRecordingsFolderId={allRecordingsFolderId}
                  {...props}
                />
              )}
            </Formik>
          )}
          {isSubmitted && (
            <Stack align="center">
              <Spinner size="md" />
              <FormattedMessage id="export.modal.export.submitted" />
            </Stack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
