import { Box, Center, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { FiCheck } from 'react-icons/fi'

interface StepCardProps {
  onClick?: () => void
  isCompleted?: boolean
  isDisabled?: boolean
  isFadedOut?: boolean
  number: number
  titleKey: I18nKey
  descriptionKey: I18nKey
  hideBorder?: boolean
}

export const StepCard = ({
  titleKey,
  descriptionKey,
  onClick,
  number,
  isDisabled,
  isFadedOut,
  isCompleted,
  hideBorder,
}: StepCardProps) => (
  <Box
    pos="relative"
    flex={1}
    transition="all 0.2s ease"
    onClick={isDisabled ? undefined : onClick}
    opacity={isFadedOut ? 0.3 : 1}
    border="2px solid"
    borderRadius={12}
    p={6}
    pb={4}
    h="full"
    lineHeight={1.2}
    sx={
      isDisabled
        ? {
            cursor: 'not-allowed',
            '> *': {
              color: `${colors.gray[500]}`,
            },
            '> :last-child': {
              bg: colors.indicator.gray,
            },
            borderColor: colors.gray[300],
          }
        : {
            borderColor: hideBorder ? colors['secondary-light'] : colors.blue[500],
            cursor: onClick ? 'pointer' : undefined,
            _hover: {
              shadow: 'lg',
            },
            bg: colors['secondary-light'],
          }
    }
  >
    <Text color={colors.blue[500]} fontWeight={600} fontSize={18} mb={4}>
      <FormattedMessage id={titleKey} />
    </Text>
    <Text color={colors.gray[700]}>
      <FormattedMessage id={descriptionKey} />
    </Text>

    <Center
      pos="absolute"
      bg={colors.blue[500]}
      borderRadius="full"
      color="white"
      w={8}
      h={8}
      bottom={0}
      left="50%"
      transform="translate(-50%, 50%)"
    >
      {isCompleted ? <FiCheck fontSize={22} /> : number}
    </Center>
  </Box>
)
