import { useState } from 'react'

import { type ContentEditorManagerContextType } from './content-editor-manager-context'

export const useContentEditorManagerHook = (): ContentEditorManagerContextType => {
  const [isDragging, setIsDragging] = useState<boolean>(false)

  return {
    isDragging,
    setIsDragging,
  }
}
