import { showToast } from '@repo/ui'
import { useState } from 'react'
import useSWR from 'swr'

import { type CoachingStep } from '../../builder/steps/api'
import { client } from '../../utils/openapi-client'
import { useAIBuilderContext } from '../scenario-ai-builder-page/shared/ai-builder-context'

export const useUpdateAiVideos = () => {
  const { programExtId, scenarioExtId, trainingExtId } = useAIBuilderContext()

  const { mutate } = useSWR<Array<CoachingStep>>(
    `/admin/programs/${programExtId}/trainings/${trainingExtId}/scenarios/${scenarioExtId}/steps`
  )

  const [isLoading, setIsLoading] = useState(false)

  const updateVideos = async (extIds: Array<string>) => {
    try {
      setIsLoading(true)
      const res = await client.put('updateVideoGenerationData', {
        params: {
          path: {
            programExtId,
            extId: scenarioExtId,
            trainingExtId,
          },
        },
        body: {
          stepExtIds: extIds,
        },
      })

      await mutate()
      if (res.error) {
        throw new Error()
      }
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  return { updateVideos, isLoading }
}
