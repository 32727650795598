import { Box, Center } from '@chakra-ui/react'
import { type Dispatch, type SetStateAction, useCallback } from 'react'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'

import uploadImage from '../../assets/uploadImage.svg'

type TargetBoxProps = {
  file: File | undefined
  setFile: Dispatch<SetStateAction<File | undefined>>
}

export const TargetBox = ({ file, setFile }: TargetBoxProps) => {
  const onDrop = useCallback(
    (item: { files: Array<File> }) => {
      if (item) {
        const { files } = item

        setFile(files[0])
      }
    },
    [setFile]
  )

  const [, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: Array<File> }) {
        if (onDrop) {
          onDrop(item)
        }
      },
    }),
    [onDrop]
  )

  return (
    <Center height="100%" width="100%" ref={drop}>
      {file === undefined && (
        <Box mt={4} bgSize="cover">
          <img src={uploadImage} />
        </Box>
      )}
    </Center>
  )
}
