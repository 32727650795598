import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'
import { fabric } from 'fabric'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import VectorShape from '../../../../../../assets/vector-shape.svg'
import VectorSize from '../../../../../../assets/vector-size.svg'
import { DeleteIconButton } from '../../../../../../builder/components/delete-icon-button'
import {
  type CoachingStepFormFields,
  getDefaultPoints,
} from '../../../../../../builder/steps/api'
import { FormikImageUploader } from '../../../../../../components/formik-image-uploader'
import {
  calculatePolygonArea,
  createPolygon,
  getPolygonEditControls,
  loadImageIntoCanvas,
} from '../../../../../../utils/polygon'

type ImageOptionsProps = {
  readOnly: boolean | undefined
}

type PolygonEditMode = 'shape' | 'size'

const MINIMUM_AREA = 1000

export const ImageOptions = ({ readOnly }: ImageOptionsProps) => {
  const { values, setFieldValue } = useFormikContext<CoachingStepFormFields>()
  const { formatMessage } = useIntl()
  const { step } = values

  if (step.stepType !== 'coachingStepQuiz') {
    throw new Error('Wrong step type')
  }

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [imageHttpUrl, setImageHttpUrl] = useState<string>()
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [polygon, setPolygon] = useState<fabric.Polygon | null>(null)
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null)
  const [polygonEditMode, setPolygonEditMode] = useState<PolygonEditMode>('size')

  const polygonEditControls = useMemo<fabric.Object['controls'] | null>(
    () => (polygon ? getPolygonEditControls(polygon) : null),
    [polygon]
  )

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const toggleEditMode = useCallback(() => {
    setPolygonEditMode(polygonEditMode === 'shape' ? 'size' : 'shape')
  }, [polygonEditMode])

  useEffect(() => {
    // Initialize fabric canvas and polygon
    const points = step.optionsData[0]?.imageRegion

    if (imageHttpUrl && points && !canvas) {
      const fabricCanvas: fabric.Canvas = new fabric.Canvas(canvasRef.current)

      loadImageIntoCanvas(imageHttpUrl, fabricCanvas)
      const poly = createPolygon(points)

      fabricCanvas.viewportTransform = [1, 0, 0, 1, 0, 0]
      fabricCanvas.add(poly)
      fabricCanvas.setHeight(320)
      fabricCanvas.setWidth(480)
      setPolygon(poly)
      setCanvas(fabricCanvas)
    }
  }, [canvas, imageHttpUrl, step.optionsData])

  useEffect(() => {
    if (polygon && canvas && readOnly) {
      polygon.selectable = false
      polygon.hasControls = false
      polygon.hasBorders = false
      canvas.wrapperEl.style.cursor = 'not-allowed'
      canvas.wrapperEl.style.pointerEvents = 'none'
      canvas.requestRenderAll()
    }
  }, [canvas, polygon, readOnly])

  const setPolygonProperties = useCallback(
    (polygonToUpdate: fabric.Polygon, editMode: PolygonEditMode) => {
      if (polygonToUpdate && editMode && polygonEditControls) {
        if (editMode === 'shape') {
          polygonToUpdate.cornerStyle = 'circle'
          polygonToUpdate.cornerColor = 'rgba(0,0,255,0.5)'
          polygonToUpdate.controls = polygonEditControls
          polygonToUpdate.hasBorders = false
        } else {
          polygonToUpdate.cornerColor = 'blue'
          polygonToUpdate.cornerStyle = 'rect'
          polygonToUpdate.controls = fabric.Object.prototype.controls
          polygonToUpdate.hasBorders = true
        }
      }
    },
    [polygonEditControls]
  )

  const togglePolygonEditing = () => {
    if (polygon && canvas && polygonEditControls && !readOnly) {
      // polygonEditMode before toggling
      const prevEditMode = polygonEditMode === 'size' ? 'shape' : 'size'

      setPolygonProperties(polygon, prevEditMode)
      toggleEditMode()
      canvas.requestRenderAll()
    }
  }

  useEffect(() => {
    const handlePolygonModified = () => {
      if (polygon) {
        // It is not allowed to move polygon out of canvas
        // When this happens, we recreate the polygon at last valid position
        if (!polygon.isOnScreen(true) && step.optionsData?.[0]?.imageRegion) {
          const updatedPolygon = createPolygon(step.optionsData[0].imageRegion)

          // since we create polygon from scratch, we need to make sure the properties are correctly set for the current edit mode
          setPolygonProperties(updatedPolygon, polygonEditMode)
          canvas.add(updatedPolygon)
          canvas.remove(polygon)
          setPolygon(updatedPolygon)
          canvas.requestRenderAll()

          return
        }

        const matrix = polygon.calcTransformMatrix()
        const imageRegion = (polygon.points ?? [])
          .map(
            p => new fabric.Point(p.x - polygon.pathOffset.x, p.y - polygon.pathOffset.y)
          )
          .map(p => fabric.util.transformPoint(p, matrix))
          .map(p => ({ x: p.x, y: p.y }))

        const polygonArea = calculatePolygonArea(imageRegion)

        if (polygonArea < MINIMUM_AREA) {
          setErrorMessage('step.quiz.form.questionImage.polygon.area')
          // We set the field to undefined to prevent user to save
          setFieldValue('step.optionsData[0].imageRegion', undefined)

          return
        }

        setErrorMessage(null)
        const updatedOption = {
          ...step.optionsData[0],
          imageRegion,
        }

        setFieldValue('step.optionsData[0]', updatedOption)
      }
    }

    if (polygon && !readOnly) {
      polygon.on('modified', handlePolygonModified)
    }

    return () => {
      if (polygon) {
        polygon.off('modified', handlePolygonModified)
      }
    }
  }, [
    canvas,
    polygon,
    polygonEditMode,
    readOnly,
    setFieldValue,
    setPolygonProperties,
    step.optionsData,
  ])

  const { onClose } = useDisclosure()

  return (
    <FormControl isDisabled={readOnly}>
      <FormLabel fontSize="sm">
        <FormattedMessage id="step.quiz.form.questionImage.label" />
      </FormLabel>
      <FormHelperText mb="4">
        <FormattedMessage id="step.quiz.form.questionImage.subLabel" />
      </FormHelperText>

      {!readOnly && imageHttpUrl && (
        <Button my={2} isDisabled={readOnly} onClick={togglePolygonEditing}>
          <img
            src={polygonEditMode === 'shape' ? VectorSize : VectorShape}
            alt=""
            width={16}
            height={16}
          />
          <Text ml={1}>
            <FormattedMessage
              id={
                polygonEditMode === 'shape'
                  ? 'step.quiz.form.options.imageRegion.editButton.size'
                  : 'step.quiz.form.options.imageRegion.editButton.shape'
              }
            />
          </Text>
        </Button>
      )}

      {imageHttpUrl && !isUploadingFile ? (
        <Flex
          justify="center"
          borderRadius="md"
          width="480px"
          height="320"
          pos="relative"
        >
          <canvas ref={canvasRef} id="canvasID" width="480" height="320" />
          <DeleteIconButton
            position="absolute"
            top={0}
            right={0}
            color={colors.gray[200]}
            bg="blackAlpha.600"
            _hover={{ color: 'blue.700' }}
            title={formatMessage({
              id: 'step.quiz.form.questionImage.delete',
            })}
            isDisabled={readOnly}
            message={formatMessage({
              id: 'step.quiz.form.questionImage.delete.confirm',
            })}
            onConfirmAction={() => {
              setImageHttpUrl(undefined)
              setPolygon(null)
              setCanvas(null)
              onClose()
              setFieldValue('step.questionImage', undefined)
              setFieldValue('step.optionsData[0]', {
                ...step.optionsData[0],
                imageRegion: getDefaultPoints(),
              })
            }}
          />
        </Flex>
      ) : (
        <FormikImageUploader
          fontSize="sm"
          isRequired
          disabled={readOnly}
          title="step.quiz.form.questionImage.image"
          name="step.questionImage"
          setImageHttpUrl={setImageHttpUrl}
          setIsUploadingFile={setIsUploadingFile}
          maxHeight={2000}
          maxWidth={2000}
        />
      )}

      {errorMessage && (
        <Text color="red.500" fontSize="sm" mt="2">
          <FormattedMessage id={errorMessage as I18nKey} />
        </Text>
      )}
    </FormControl>
  )
}
