import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { FormikFormField, showToast } from '@repo/ui'
import { Form, Formik } from 'formik'
import { get } from 'lodash-es'
import * as yup from 'yup'

import { Gate } from '../../components/gate'
import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { useRole } from '../../hooks/use-role'

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Z0-9_\-\s]+$/)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .required(<FormattedMessage id="role.editor.error.name.required" />),
})

type RoleEditorModalProps = {
  mode: { type: 'create' } | { type: 'edit'; id: string }
  onClose: () => void
}

export const RoleEditorModal = ({ mode, onClose }: RoleEditorModalProps) => {
  const { role, remove, save } = useRole(mode.type === 'create' ? null : mode.id)
  const roleName = get(role, 'name', '')

  const handleDelete = async () => {
    try {
      await remove()
      onClose()
    } catch (error) {
      showToast({ messageKey: 'role.editor.error.delete', status: 'error' })
    }
  }

  const handleSave = async formData => {
    const result = await save(formData)

    const messageId = !result ? 'role.editor.error.save' : 'common.alert.saved'

    showToast({ messageKey: messageId, status: !result ? 'error' : 'success' })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (result) {
      onClose()
    }
  }

  const { formatMessage } = useIntl()

  return (
    <Gate>
      <InterceptAndConfirm
        description={<FormattedMessage id="role.editor.confirmation.delete.text" />}
        onConfirm={handleDelete}
        title={<FormattedMessage id="role.editor.confirmation.delete.title" />}
      >
        {({ openModal }) => (
          <Modal onClose={onClose} isOpen isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <FormattedMessage id="role.editor.title" />
              </ModalHeader>

              <Formik
                initialValues={{
                  name: roleName,
                }}
                onSubmit={handleSave}
                validateOnBlur
                validationSchema={validationSchema}
              >
                {formProps => (
                  <Form>
                    <ModalBody>
                      <FormikFormField
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        errors={formProps.errors.name}
                        name="name"
                        placeholder={formatMessage({
                          id: 'role.editor.name.placeholder',
                        })}
                        label={formatMessage({
                          id: 'role.editor.name.label',
                        })}
                      />
                    </ModalBody>

                    <ModalFooter>
                      <ButtonGroup>
                        {role && (
                          <Button colorScheme="red" onClick={openModal}>
                            <FormattedMessage id="common.delete" />
                          </Button>
                        )}

                        <Button variant="outline" onClick={onClose}>
                          <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button
                          type="submit"
                          isDisabled={formProps.isSubmitting}
                          isLoading={formProps.isSubmitting}
                        >
                          <FormattedMessage id="common.save" />
                        </Button>
                      </ButtonGroup>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </ModalContent>
          </Modal>
        )}
      </InterceptAndConfirm>
    </Gate>
  )
}
