import { chakra, Icon, Link } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { BsChevronLeft } from 'react-icons/bs'
import { Link as RouterLink } from 'react-router-dom'

export const BackToLogin = () => (
  <Link as={RouterLink} to="/login" variant="content-primary-dark" fontSize="sm">
    <Icon as={BsChevronLeft} />
    <chakra.span ml={1}>
      <FormattedMessage id="onboarding.backToLogin" />
    </chakra.span>
  </Link>
)
