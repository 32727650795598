import { Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { RHF, useFormRules } from '@repo/ui'
import { useFormContext, type Validate } from 'react-hook-form'

import { type PersonaFormValues } from '../../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validateField: Validate<any, PersonaFormValues['insights']> = (_, values) => {
  if (!values.needsAndPains?.trim() && !values.goals?.trim() && !values.manners?.trim()) {
    return ''
  }

  return undefined
}

export const StepInsights = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<PersonaFormValues['insights']>()

  const rules = useFormRules()

  return (
    <>
      <RHF.TextArea
        control={control}
        name="needsAndPains"
        labelKey="scenario.builder.ai.steps.conversation-partner.formLabel.needsAndPains"
        rules={{
          deps: ['manners', 'goals'],
          validate: validateField,
          ...rules.stringCustomLength(30000),
        }}
        tooltipKey="persona.needsAndPains.tooltip.text"
      />
      <RHF.TextArea
        control={control}
        name="goals"
        labelKey="scenario.builder.ai.steps.conversation-partner.formLabel.goals"
        rules={{
          deps: ['manners', 'needsAndPains'],
          validate: validateField,
          ...rules.stringCustomLength(30000),
        }}
        tooltipKey="persona.goals.tooltip.text"
      />
      <RHF.TextArea
        control={control}
        name="manners"
        labelKey="scenario.builder.ai.steps.conversation-partner.formLabel.expectedBehaviors"
        rules={{
          deps: ['goals', 'needsAndPains'],
          validate: validateField,
          ...rules.stringCustomLength(30000),
        }}
        tooltipKey="persona.manners.tooltip.text"
      />

      {errors.goals?.message === '' && (
        <Text color="red.500" fontSize={14}>
          <FormattedMessage id="common.validation.fillAtLeastOneField" />
        </Text>
      )}
    </>
  )
}
