import { Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import { type CoachingStep } from '../../../builder/steps/api'
import { CenteredSpinner } from '../../../components/centered-spinner'
import { StepReviewList } from '../../shared/step-review-section'
import { StickyFooter } from '../../shared/sticky-footer'
import { useRenameScenarioModal } from '../../shared/use-rename-scenario-modal'
import { useScenarioBuilderData } from '../../shared/use-scenario-builder-data'
import { useManualBuilderContext } from '../shared/manual-builder-context'

export const ReviewStep = () => {
  const navigate = useNavigate()
  const [renameScenarioModal, renameScenarioViaModal] = useRenameScenarioModal()
  const { goToPreviousStep, isBuilderReadonly } = useManualBuilderContext()

  const [{ programExtId, trainingExtId, scenarioExtId }] = useScenarioBuilderData()

  const { data: steps, isLoading } = useSWR<Array<CoachingStep>>(
    `/admin/programs/${programExtId}/trainings/${trainingExtId}/scenarios/${scenarioExtId}/steps`
  )

  const handleNext = async () => {
    if (!isBuilderReadonly) {
      const isRenamed = await renameScenarioViaModal()

      if (!isRenamed) {
        return
      }
    }

    navigate(`/training_programs/${programExtId}/trainings/${trainingExtId}`)
  }

  const isStepsInitializing = !steps && isLoading

  return (
    <Stack gap={0} flex={1}>
      {renameScenarioModal}

      {isStepsInitializing ? <CenteredSpinner /> : <StepReviewList steps={steps} />}

      <StickyFooter onBack={goToPreviousStep} onNext={handleNext} />
    </Stack>
  )
}
