import {
  onAuthStateChanged,
  signInWithCustomToken,
  signOut,
  type User as FirebaseUser,
} from 'firebase/auth'
import { parse } from 'qs'
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import config from '../config'
import { firebaseAuth } from '../firebase'

type CustomFirebaseUser = FirebaseUser & { createdAt: string }

type AuthContextType = {
  user: (FirebaseUser & { createdAt: string }) | null
  isInitialized: boolean
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isInitialized: false,
})

const redirectTraineeToCoachingApp = async (email: string | null) => {
  // logout the user from this app, and redirect to coaching website
  await signOut(firebaseAuth)
  window.location.href = `${config.coachingUrl}/login/${email}`
}

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [auth, setAuth] = useState<AuthContextType>({
    user: null,
    isInitialized: false,
  })

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const unsubscribe = onAuthStateChanged(firebaseAuth, async fiUser => {
      const query = parse(window.location.search)
      const qsToken = query?.['?token'] as string | undefined

      if (qsToken) {
        try {
          const res = await signInWithCustomToken(firebaseAuth, qsToken)

          setAuth({
            user: res.user as CustomFirebaseUser,
            isInitialized: true,
          })
        } catch (err) {
          // TODO Handle error
          setAuth({ user: null, isInitialized: true })
        }

        return
      }

      if (!fiUser) {
        return setAuth({ user: null, isInitialized: true })
      }

      const { claims } = await fiUser.getIdTokenResult()
      const isTraineeAccount = claims.role === 'external'

      if (isTraineeAccount) {
        /**
         * Here, if the user is a trainee, we redirect them to the coaching app
         * The reason of having this workaround is, we're using the same firebase project for both
         * And currently we rely on Firebase Auth's email templates for password reset & email verify
         * Which means, both coaching and admin users will have the same URLs for these actions
         * Ideal solution would be having our own email templates to prevent this weird flow. This is a temporary workaround
         */
        return redirectTraineeToCoachingApp(fiUser.email)
      }

      setAuth({
        user: fiUser as CustomFirebaseUser,
        isInitialized: true,
      })
    })

    return () => unsubscribe()
  }, [])

  return auth.isInitialized ? (
    <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
  ) : null
}

export const useAuth = () => useContext(AuthContext)
