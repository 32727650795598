import { Tag } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { isNil } from '@repo/utils'
import { memo } from 'react'

import { getOverallScoreRating, type ScoreRatingEnum } from '../../hooks/use-result'

type RelevanceTagProps = {
  overallScore?: number
}

const TAG_VARIANT = {
  low: 'indicator-red',
  medium: 'indicator-yellow',
  good: 'indicator-green',
  great: 'indicator-caribbean-green',
} as const satisfies Record<Exclude<ScoreRatingEnum, 'noScores'>, string>

export const RelevanceTag = memo(({ overallScore }: RelevanceTagProps) => {
  if (isNil(overallScore)) {
    return <FormattedMessage id="recording.list.notAvailable" />
  }

  const scoreRating = getOverallScoreRating(overallScore)

  return (
    <Tag variant={TAG_VARIANT[scoreRating]}>
      <FormattedMessage id={`recording.list.tags.${scoreRating}`} />
    </Tag>
  )
})
