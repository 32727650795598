import { Spinner } from '@chakra-ui/react'
import { type ProgramLanguages } from '@repo/api'
import { RHF, useFormRules } from '@repo/ui'
import { type Control, type FieldPath, type FieldValues } from 'react-hook-form'

import { useOpenapiSWR } from '../../../utils/use-openapi-swr'
import { useRenderSkillName } from './use-render-skill-name'

type SkillSelectAutocompleteProps<T extends FieldValues> = {
  control: Control<T>
  name: FieldPath<T>
  language: ProgramLanguages
}

export const SkillSelectAutocomplete = <T extends FieldValues>({
  control,
  name,
  language,
}: SkillSelectAutocompleteProps<T>) => {
  const renderSkillName = useRenderSkillName()
  const rules = useFormRules()

  const { data: skills, isLoading: isSkillsLoading } = useOpenapiSWR('getSkills')
  const isInitializingSkills = skills === undefined && isSkillsLoading

  const filteredSkills = skills?.filter(skill =>
    skill.language ? skill.language === language : true
  )

  if (isInitializingSkills) {
    return <Spinner />
  }

  return (
    <RHF.AutocompleteSelect
      control={control}
      name={name}
      labelKey="behavioral.goal.form.behavior.skills.select.label"
      componentProps={{
        options:
          filteredSkills?.map(({ name: skillName }) => {
            const label = renderSkillName(skillName)

            return { label, value: skillName }
          }) ?? [],
        placeholderKey: 'behavioral.goal.form.behavior.skills.select.placeholder',
        allowCustomValues: true,
      }}
      rules={rules.stringShortRequired}
    />
  )
}
