import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { Spinner } from '@repo/ui'
import { Formik } from 'formik'
import { useLocation } from 'react-router-dom'

import { useOrganization } from '../../../hooks/use-organization'
import { usePlugin } from '../../../hooks/use-plugin'
import { selectOrganizationName } from '../../../store/entities/organization/selectors'
import { composePluginUrl, tagsToString } from '../../../utils/plugins'
import { PluginForm } from '../plugin-form/plugin-form'
import {
  getInitialDateInputValue,
  getIsActiveRecorder,
  type PluginData,
  type PluginFormValues,
  sanitizeFormData,
} from '../plugin-form/plugin-form-utils'
import { MissingGDPR } from './missing-gdpr'
import { NotificationsModal } from './notifications-modal'

// eslint-disable-next-line
const makeMapStateToProps = (pluginId: string, selectPlugin: any) => (state: any) => ({
  organizationName: selectOrganizationName(state),
  ...selectPlugin(state, pluginId),
})

export const PluginModal = ({
  pluginId,
  onClose,
  isOpen,
}: {
  pluginId: string
  onClose: () => void
  isOpen: boolean
}) => {
  const { isGDPRCompliant } = useOrganization()
  const location = useLocation()

  const {
    isFetching: fetching,
    isSaving,
    plugin,
    save,
    fetchError,
    saveError,
  } = usePlugin(pluginId, makeMapStateToProps)

  const error = fetchError || saveError
  const pluginData = plugin as PluginData
  const pluginUrl = composePluginUrl(
    pluginData.id,
    tagsToString(pluginData.tags),
    pluginData.organizationName
  )

  const questionId = location.state
    ? location.state.updatedQuestionnaire
    : pluginData?.questionnaire?.id

  const initialValues = {
    questionnaireId: questionId || null,
    expirationDate: getInitialDateInputValue(pluginData?.expirationDate),
    identityRequired: pluginData.identityRequired,
    preventUpload: pluginData.preventUpload,
    isActiveRecorder: getIsActiveRecorder(pluginData?.expirationDate),
    notifyApplicant: pluginData.notifyApplicant,
    shareWithTargetProfile: pluginData.shareWithTargetProfile,
    hasShareResult: pluginData?.organization_settings?.hasShareResult || false,
  }

  const handleSubmit = async (formValues: PluginFormValues) => {
    const sanitizedData = sanitizeFormData(formValues)
    const status = await save(sanitizedData)

    if (status === 'success') {
      onClose()
    }
  }

  const notificationsModal = useDisclosure()

  if (!isGDPRCompliant) {
    return <MissingGDPR onClose={onClose} />
  }

  return (
    <>
      <NotificationsModal
        pluginId={pluginId}
        onClose={notificationsModal.onClose}
        isOpen={notificationsModal.isOpen}
      />
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage id="plugin.modal.title" />
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody pb={4}>
            {error && (
              <Box
                p={4}
                mb={4}
                display="flex"
                justifyContent="center"
                bg="indicator.red-light"
                borderRadius={4}
              >
                <FormattedMessage
                  id={`plugin.modal.error.${fetchError ? 'fetch' : 'save'}`}
                />
              </Box>
            )}

            {!fetchError && !!fetching && (
              <Flex justify="center" h="500px">
                <Spinner />
              </Flex>
            )}

            {!fetchError && !fetching && pluginData && (
              <Formik<PluginFormValues>
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                <PluginForm
                  isSaving={isSaving}
                  onCancel={onClose}
                  onOpenNotificationSettings={notificationsModal.onOpen}
                  pluginUrl={pluginUrl}
                />
              </Formik>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
