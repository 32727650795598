import moment from 'moment'

/**
 * Converts the given time in seconds to a minutes format (m:ss).
 *
 * @param {number} timeInSeconds - The time in seconds.
 * @returns {string} The formatted time in minutes format (m:ss).
 * */
export const getHumanReadableTime = (timeInSeconds: number): string =>
  moment.utc(timeInSeconds * 1000).format('m:ss')
