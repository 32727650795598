import { Flex } from '@chakra-ui/react'
import { type ProgramLanguages } from '@repo/api'
import { PageWrapper } from '@repo/ui'
import { useMemo, useState } from 'react'

import { NoFilterResult } from '../../coaching/program-template-library/create-program-from-template/no-filter-result'
import { NoSearchResult } from '../../coaching/program-template-library/create-program-from-template/no-search-result'
import { TemplateFilters } from '../../coaching/program-template-library/create-program-from-template/template-filters'
import { TemplatesTopBar } from '../../coaching/program-template-library/create-program-from-template/templates-top-bar'
import {
  type Filters,
  initialFilters,
} from '../../coaching/program-template-library/shared/filters'
import { AppHeaderTitle } from '../../components/app-header-title'
import { TemplateGrid } from '../../components/template-grid'
import { useBenchmarkProfiles } from '../hooks/use-benchmark-profiles'
import { BenchmarkTemplateCard } from './benchmark-template-card'

type TemplateSectionProps = {
  refreshBenchmarkList?: () => void
}

export const TemplateSection = ({ refreshBenchmarkList }: TemplateSectionProps) => {
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState<Filters>(initialFilters)
  const [filtersVisible, setFiltersVisible] = useState(true)
  const { useTemplateProfiles } = useBenchmarkProfiles()
  const { data, isLoading } = useTemplateProfiles()
  const languageFilters = filters.language.map(filter =>
    filter.replace('language.', '')
  ) as Array<ProgramLanguages>

  const templateData = refreshBenchmarkList ? data?.slice(0, 4) : data
  const filteredData = useMemo(() => {
    if (!templateData) {
      return []
    }

    return templateData.filter(template => {
      const languageMatch =
        !languageFilters.length || languageFilters.includes(template.language)

      return languageMatch
    })
  }, [languageFilters, templateData])

  // search
  const filteredSearchedData = useMemo(
    () =>
      filteredData.filter(({ title, description }) => {
        const lowerCaseSearch = search.toLowerCase()

        return (
          title.toLowerCase().includes(lowerCaseSearch) ||
          description?.toLowerCase().includes(lowerCaseSearch)
        )
      }),
    [filteredData, search]
  )

  const showNoFiltersResults = !filteredData.length
  const showNoSearchResults = !!search.length && !filteredSearchedData.length

  const getMainContent = () => {
    if (showNoSearchResults) {
      return <NoSearchResult />
    }

    if (showNoFiltersResults) {
      return (
        <NoFilterResult
          currentFilters={
            Object.entries(filters)
              .map(([type, values]) => values.map(value => ({ type, value })))
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .reduce((acc, val) => acc.concat(val), []) as any
          }
          onRemoveFilter={filterToRemove => {
            setFilters({
              ...filters,
              [filterToRemove.type]: filters[filterToRemove.type].filter(
                filter => filter !== filterToRemove.value
              ),
            })
          }}
        />
      )
    }

    return (
      <TemplateGrid
        isLoading={isLoading}
        templates={filteredSearchedData}
        CardComponent={({ template }) => (
          <BenchmarkTemplateCard
            template={template}
            refreshBenchmarkList={refreshBenchmarkList}
          />
        )}
        title="coaching.benchmarking.templates.title"
      />
    )
  }

  const availableFilterOptions = useMemo(() => {
    const languages = data?.map(template => `language.${template.language}`)

    return {
      industry: [],
      language: Array.from(new Set(languages)).sort(),
      department: [],
      useCases: [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any as Filters
  }, [data])

  return (
    <PageWrapper
      titleKey="coaching.benchmarking.templates.page.title"
      subtitleKey="coaching.benchmarking.templates.page.subtitle"
      backTo="/coaching/benchmarking/profiles"
      backLabelKey="coaching.benchmarking.templates.page.backLabel"
      sx={{
        height: 'full',
        pb: 0,
        ps: 0,
        '> div:first-of-type': {
          ps: 6,
        },
      }}
    >
      <AppHeaderTitle formattedMessageId="coaching.benchmarking.title" />

      <TemplatesTopBar
        filtersVisible={filtersVisible}
        toggleFiltersVisible={() => setFiltersVisible(!filtersVisible)}
        search={search}
        setSearch={setSearch}
      />

      <Flex flex={1} overflow="hidden">
        {filtersVisible && (
          <TemplateFilters
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            hasFilters
            availableFilterOptions={availableFilterOptions}
          />
        )}
        {getMainContent()}
      </Flex>
    </PageWrapper>
  )
}
