import { FormattedMessage } from '@repo/i18n'
import { type AnyObject } from '@repo/utils'
import { useCallback, useMemo, useState } from 'react'
import { Cell, Pie, PieChart } from 'recharts'

interface PieLabelProps {
  alignmentBaseline?:
    | 'auto'
    | 'baseline'
    | 'before-edge'
    | 'text-before-edge'
    | 'middle'
    | 'central'
    | 'after-edge'
    | 'text-after-edge'
    | 'ideographic'
    | 'alphabetic'
    | 'hanging'
    | 'mathematical'
    | 'inherit'
  textAnchor?: string
  cx?: number
  cy?: number
  payload?: AnyObject
  selectedDimension?: string
  x?: number
  y?: number
}

const PieLabel = ({
  alignmentBaseline,
  cx,
  cy,
  payload,
  selectedDimension,
  textAnchor,
  x,
  y,
}: PieLabelProps) => (
  <text
    cx={cx}
    cy={cy}
    alignmentBaseline={alignmentBaseline}
    textAnchor={textAnchor}
    x={x}
    y={y}
    fontWeight="500"
  >
    <tspan x={x} dy="0em">
      <FormattedMessage
        id={
          `meta.benchmarkProfile.field.${selectedDimension}.${payload?.payload.name}.adjectives.0.result` as I18nKey
        }
      />
    </tspan>
  </text>
)

type MoonChartProps = {
  width: number
  height: number
  segments: Array<string>
  realSegment: number
  targetSegment: number | undefined
  selectedDimension: string
  realSegmentColor?: string
  expectedSegmentColor?: string
  showTargetProfile: boolean
}

export const MoonChart = ({
  segments,
  realSegment,
  targetSegment,
  realSegmentColor,
  expectedSegmentColor,
  width,
  height,
  selectedDimension,
  showTargetProfile,
}: MoonChartProps) => {
  const backgroundChart = [{ value: 1 }]
  const mappedSegments = useMemo(
    () => (segments ? segments.map(text => ({ value: 1, name: text })) : []),
    [segments]
  )

  const tickWidth = 8
  const [baseInnerRadius] = useState(100)
  const [baseOuterRadius] = useState(baseInnerRadius + tickWidth * 2)

  const makePie = useCallback(
    (props = {}) => (
      <Pie
        isAnimationActive={false}
        cy="100%"
        cx="50%"
        cornerRadius={20}
        endAngle={0}
        startAngle={180}
        innerRadius={baseInnerRadius}
        outerRadius={baseOuterRadius}
        stroke="none"
        fill="#EEEEEE"
        dataKey="value"
        {...props}
      />
    ),
    [baseInnerRadius, baseOuterRadius]
  )

  return (
    <PieChart width={width} height={height}>
      {makePie({ data: backgroundChart, innerRadius: baseInnerRadius, cornerRadius: 2 })}
      {makePie({
        data: mappedSegments,
        innerRadius: baseInnerRadius + tickWidth,
        children: mappedSegments.map(({ name }, index) => (
          <Cell
            key={name}
            fill={index === realSegment ? realSegmentColor || '#17459C' : 'transparent'}
          />
        )),
      })}
      {makePie({
        data: mappedSegments,
        outerRadius: baseInnerRadius + tickWidth,
        labelLine: false,
        nameKey: 'name',
        children: mappedSegments.map(({ name }, index) => (
          <Cell
            key={name}
            fill={
              showTargetProfile && index === targetSegment
                ? expectedSegmentColor || '#78E296'
                : 'transparent'
            }
          />
        )),
        label: (props: AnyObject) => (
          <PieLabel {...props} selectedDimension={selectedDimension} />
        ),
      })}
    </PieChart>
  )
}
