// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/modal.ts

import { type ComponentSingleStyleConfig } from '@chakra-ui/react'

const modalTheme: ComponentSingleStyleConfig = {
  baseStyle: {
    overlay: {
      backdropFilter: 'blur(4px)',
      background: '#0e317099',
    },
  },
}

export { modalTheme }
