import { InputAdornment, TextField } from '@mui/material'
import { useIntl } from '@repo/i18n'
import { FiSearch } from 'react-icons/fi'

type TableSearchInputProps = {
  value: string
  setValue: (value: string) => void
}

export const TableSearchInput = ({ value, setValue }: TableSearchInputProps) => {
  const { formatMessage } = useIntl()

  return (
    <TextField
      value={value}
      onChange={e => setValue(String(e.target.value))}
      placeholder={formatMessage({ id: 'common.search' })}
      type="search"
      size="small"
      variant="standard"
      sx={{ width: 200, alignSelf: 'flex-end' }}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <FiSearch />
            </InputAdornment>
          ),
        },
      }}
    />
  )
}
