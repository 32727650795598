import {
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast, useConfirmationModal } from '@repo/ui'
import { assertExists } from '@repo/utils'
import { RiMoreFill } from 'react-icons/ri'
import { Link, useParams } from 'react-router-dom'

import { type Training } from '../../types/api-types'
import { client } from '../../utils/openapi-client'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'
import { useProgramsList } from '../shared/api'
import { useEditTrainingModal } from './program-row-actions/use-edit-training-modal'

export const TrainingRowActions = ({ training }: { training: Training }) => {
  const { programExtId } = useParams()

  assertExists(programExtId)

  const { data: program } = useOpenapiSWR('getProgramByExtId', {
    params: { extId: programExtId },
  })

  const { mutate: mutatePrograms } = useProgramsList()
  const { mutate } = useOpenapiSWR('getTrainings', {
    params: { programExtId },
  })

  const [deleteModal, showDeleteModal] = useConfirmationModal({
    onConfirm: async () => {
      try {
        const { error } = await client.delete('deleteTraining', {
          params: { path: { extId: training.extId, programExtId } },
        })

        if (error) {
          throw new Error()
        }

        mutate(trainings => trainings?.filter(t => t.extId !== training.extId), false)
        mutatePrograms(
          programs =>
            programs?.map(p => {
              if (p.extId !== programExtId || !p.trainingsCount) {
                return p
              }

              return {
                ...p,
                trainingsCount: p.trainingsCount - 1,
              }
            }),
          false
        )

        showToast({ messageKey: 'common.alert.deleted', status: 'success' })
      } catch (error) {
        showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }
    },
    title: training.title,
    isDestructive: true,
    confirmButtonKey: 'common.confirm',
    descriptionKey: 'training.button.delete.confirm',
  })

  const [editTrainingModal, showEditTrainingModal] = useEditTrainingModal(
    training,
    programExtId
  )

  const isReadonly = program?.status !== 'unpublished'

  return (
    <HStack justifyContent="flex-end">
      {deleteModal}
      {editTrainingModal}
      <Button as={Link} to={`trainings/${training.extId}`} variant="outline">
        <FormattedMessage id="common.open" />
      </Button>
      <Menu>
        <MenuButton as={IconButton} variant="ghost" icon={<RiMoreFill />} />
        <MenuList>
          <MenuItem onClick={showEditTrainingModal} isDisabled={isReadonly}>
            <FormattedMessage id="training.edit" />
          </MenuItem>

          <MenuItem
            onClick={showDeleteModal}
            color="indicator.red-dark"
            isDisabled={isReadonly}
          >
            <FormattedMessage id="training.button.delete" />
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  )
}
