import { HStack, Table, Tbody, Thead, Tr } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { SortDirectionToggle, ThSticky } from '@repo/ui'
import { memo } from 'react'

import { type ColumnSortableItemValue } from '../../recruiting/recording-filters/column-control-data'
import { useRecordingFiltersContext } from '../../recruiting/recording-filters/recording-filters-context'
import { useColumnSelectData } from '../../recruiting/recording-filters/recording-filters-hooks'
import { type RecordingSession } from '../../recruiting/recording-sessions-api'
import { getRecordingFieldDisplayText } from './get-recording-field-display-text'
import { ListViewRow } from './list-view-row'

type ListViewProps = {
  onClick: (index: number) => void
  onDeleteClick: (selectedIndex: number) => Promise<void>
  sessions: Array<RecordingSession>
  columns: Array<ColumnSortableItemValue>
  showDeleteButtons: boolean
  updateRecordings?: () => void
}

export const ListView = memo(
  ({
    onClick,
    onDeleteClick,
    sessions,
    columns,
    showDeleteButtons = false,
    updateRecordings,
  }: ListViewProps) => {
    const { onColumnSortChangeAndApply } = useRecordingFiltersContext()

    const { selectDictionary } = useColumnSelectData()

    return (
      <Table position="relative" zIndex={0}>
        <Thead>
          <Tr>
            <ThSticky />
            {columns.map(({ value, sortBy }) => (
              <ThSticky key={value}>
                <HStack alignItems="center" spacing={2}>
                  <span>{getRecordingFieldDisplayText(value, selectDictionary)}</span>
                  <SortDirectionToggle
                    value={sortBy}
                    onChange={sortOption =>
                      onColumnSortChangeAndApply({
                        value,
                        sortBy: sortOption,
                      })
                    }
                  />
                </HStack>
              </ThSticky>
            ))}
            {showDeleteButtons && (
              <ThSticky>
                <FormattedMessage id="common.delete" />
              </ThSticky>
            )}
          </Tr>
        </Thead>
        <Tbody mb={2}>
          {sessions.map((session, index) => (
            <ListViewRow
              key={session.id}
              session={session}
              columns={columns}
              onRowClick={() => onClick(index)}
              onDeleteClick={() => onDeleteClick(index)}
              showDeleteButtons={showDeleteButtons}
              updateRecordings={updateRecordings}
            />
          ))}
        </Tbody>
      </Table>
    )
  }
)
