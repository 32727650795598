import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { useState } from 'react'

import { type Question, type ScenarioTree } from '../../../../shared/types'
import { SelectContentStep } from './question-selection-modal/select-content-step'
import { SelectQuestionsStep } from './question-selection-modal/select-questions-step'

const stepKeys: Array<I18nKey> = [
  'dashboard.resultPerQuestion.modal.step.1',
  'dashboard.resultPerQuestion.modal.step.2',
]

export const QuestionSelectionModal = ({
  isOpen,
  scenarioTree,
  onClose,
  onQuestionsSelect,
}: {
  scenarioTree: Array<ScenarioTree>
  isOpen: boolean
  onClose: () => void
  onQuestionsSelect: (selectedQuestions: Array<Question>) => void
}) => {
  const [prevIsOpen, setPrevIsOpen] = useState(isOpen)
  const [currentStep, setCurrentStep] = useState(0)
  const [selectedScenarioExtIds, setSelectedScenarioExtIds] = useState<Array<string>>([])

  if (prevIsOpen !== isOpen) {
    setPrevIsOpen(isOpen)

    // reset state before opening modal
    if (isOpen) {
      setCurrentStep(0)
    }
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="lg" color={colors['primary-dark']}>
            <FormattedMessage id="dashboard.resultPerQuestion.title" />
          </Text>
          <Text fontWeight="normal" fontSize="sm">
            <FormattedMessage id="dashboard.resultPerQuestion.modal.description" />
          </Text>

          <ModalCloseButton />
        </ModalHeader>
        <ModalBody display="flex" flexDir="column" gap={7} px={0} pb={10}>
          <Stepper index={currentStep} width="max-content" px={6}>
            {stepKeys.map(stepKey => (
              <Step key={stepKey}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <StepTitle>
                  <FormattedMessage id={stepKey} />
                </StepTitle>

                <StepSeparator
                  style={{
                    minWidth: '40px',
                  }}
                />
              </Step>
            ))}
          </Stepper>

          {currentStep === 0 ? (
            <SelectContentStep
              scenarioTree={scenarioTree}
              handleSubmit={formData => {
                setSelectedScenarioExtIds(formData.selectedScenarioExtIds)
                setCurrentStep(1)
              }}
            />
          ) : (
            <SelectQuestionsStep
              selectedScenarioExtIds={selectedScenarioExtIds}
              scenarioTree={scenarioTree}
              handleSubmit={onQuestionsSelect}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
