import {
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { VideoPicker } from '../../../../../../builder/components/video-picker'
import { type CoachingStepFormFields } from '../../../../../../builder/steps/api'
import { useVideoUploader } from '../use-video-uploader'

type VideoOptionsProps = {
  isDisabled: boolean | undefined
}
const VideoTabPanel = ({ index, isDisabled, setFileUploaded }) => {
  const { formatMessage } = useIntl()
  const {
    clearError,
    handleVideoUpload,
    error,
    isFormControlDisabled,
    setVideoUrlFormValue,
    loading,
    videoHttpUrl,
    fileUploaded,
  } = useVideoUploader(`step.optionsData[${index}].videoUrl`, undefined, isDisabled)

  useEffect(() => {
    if (setFileUploaded && fileUploaded) {
      setFileUploaded(fileUploaded)
    }
  }, [setFileUploaded, fileUploaded])

  return (
    <VStack alignItems="center">
      {!videoHttpUrl && !loading && (
        <Text fontSize="14px" fontWeight={400} mb={2}>
          <FormattedMessage id="step.quiz.form.videoCompare.text" />
        </Text>
      )}
      <VideoPicker
        name={`videoCompare${index}`}
        clearError={clearError}
        handleVideoUpload={handleVideoUpload}
        error={error}
        setVideoUrlFormValue={setVideoUrlFormValue}
        loading={loading}
        videoHttpUrl={videoHttpUrl}
        isDisabled={isFormControlDisabled}
        buttonText={formatMessage({
          id: `step.quiz.form.videoCompare.videUploadButton.${index}.label` as I18nKey,
        })}
      />
    </VStack>
  )
}

export const VideoOptions = ({ isDisabled }: VideoOptionsProps) => {
  const { values, setFieldValue } = useFormikContext<CoachingStepFormFields>()
  const { step } = values

  if (step.stepType !== 'coachingStepQuiz') {
    throw new Error('Wrong step type')
  }

  const [correctOptionId, setCorrectOptionId] = useState<string | undefined>(undefined)
  const [tabIndex, setTabIndex] = useState(0)
  const { formatMessage } = useIntl()

  useEffect(() => {
    setCorrectOptionId(step.optionsData.find(opt => opt.isCorrect)?.id)
  }, [step.id, step.optionsData])

  const handleRadioChange = (selectedOptionId: string) => {
    step.optionsData.forEach((opt, index) => {
      setFieldValue(`step.optionsData.${index}.isCorrect`, selectedOptionId === opt.id)
    })
  }

  const [fileUploaded, setFileUploaded] = useState(false)

  useEffect(() => {
    if (fileUploaded) {
      setTabIndex(1)
    }
  }, [fileUploaded])

  return (
    <FormControl>
      <FormLabel fontSize="sm">
        <FormattedMessage id="step.quiz.form.videoCompare.label" />
      </FormLabel>
      <FormHelperText>
        <FormattedMessage id="step.quiz.form.videoCompare.subLabel" />
      </FormHelperText>
      <Tabs
        onChange={index => setTabIndex(index)}
        index={tabIndex}
        isFitted
        variant="unstyled"
      >
        <TabList>
          <Tab _focus={{ boxShadow: 'none' }}>
            <FormattedMessage id="step.quiz.form.videoOptions.videoOne" />
          </Tab>
          <Tab _focus={{ boxShadow: 'none' }}>
            <FormattedMessage id="step.quiz.form.videoOptions.videoTwo" />
          </Tab>
        </TabList>
        <TabIndicator mt="-1.5px" height="2px" bg="blue.500" />
        <TabPanels>
          {step.optionsData.map((option, index) => (
            <TabPanel key={option.id}>
              <VideoTabPanel
                index={index}
                isDisabled={isDisabled}
                setFileUploaded={setFileUploaded}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      <FormLabel fontSize="sm">
        <FormattedMessage id="step.quiz.form.videoCompare.answer.label" />
      </FormLabel>
      <FormHelperText>
        <FormattedMessage id="step.quiz.form.videoCompare.answer.subLabel" />
      </FormHelperText>
      <RadioGroup
        onChange={handleRadioChange}
        value={correctOptionId}
        name="step.optionsData"
      >
        {step.optionsData.map((option, index) => (
          <HStack key={option.id} spacing="2" mt={2}>
            <Radio colorScheme="green" value={option.id} isDisabled={isDisabled} />
            <HStack
              w="100%"
              border="1px"
              borderRadius="md"
              borderColor={option.isCorrect ? colors.green[500] : colors.indicator.red}
              p={1}
            >
              <Text>{formatMessage({ id: 'common.video' })}</Text>
              <Text> {index + 1}</Text>
            </HStack>
          </HStack>
        ))}
      </RadioGroup>
    </FormControl>
  )
}
