import { Box } from '@chakra-ui/react'
import { showToast } from '@repo/ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import {
  type AIQuestionAndAnswerStep,
  type CoachingStep,
} from '../../../../../builder/steps/api'
import { CenteredSpinner } from '../../../../../components/centered-spinner'
import { client } from '../../../../../utils/openapi-client'
import { StepReviewList } from '../../../../shared/step-review-section'
import { StickyFooter } from '../../../../shared/sticky-footer'
import { useRenameScenarioModal } from '../../../../shared/use-rename-scenario-modal'
import { useUpdateAiVideos } from '../../../../shared/use-update-ai-videos'
import { useAIBuilderContext } from '../../../shared/ai-builder-context'
import { useInteractionStepContext } from '../interaction-step-context'
import { UpdateAllVideosButton } from './step-review/update-all-videos-button'

export const StepReview = () => {
  const { setCurrentStep } = useInteractionStepContext()

  const { programExtId, scenarioExtId, trainingExtId, isBuilderReadonly } =
    useAIBuilderContext()

  const { data: steps, isLoading } = useSWR<Array<CoachingStep>>(
    `/admin/programs/${programExtId}/trainings/${trainingExtId}/scenarios/${scenarioExtId}/steps`
  )

  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { updateVideos, isLoading: isUpdatingVideos } = useUpdateAiVideos()

  const [renameScenarioModal, renameScenarioViaModal] =
    useRenameScenarioModal(isUpdatingVideos)

  const outdatedAiSteps = steps
    ?.filter(
      step =>
        step.step.stepType === 'coachingStepQA' &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (step.step as any).type === 'aiSupported' &&
        (step.step as AIQuestionAndAnswerStep).isVideoGenerationDataChanged
    )
    .map(step => step.id)

  const generateVideo = async () => {
    try {
      setIsSubmitting(true)
      const res = await client.post('startVideoGenerations', {
        params: {
          path: {
            programExtId,
            extId: scenarioExtId,
            trainingExtId,
          },
        },
      })

      if (res.error) {
        throw new Error()
      }
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleContinue = async () => {
    if (!isBuilderReadonly) {
      await generateVideo()

      const isRenamed = await renameScenarioViaModal()

      if (!isRenamed) {
        return
      }
    }

    navigate(`/training_programs/${programExtId}/trainings/${trainingExtId}`)
  }

  const isStepsInitializing = !steps && isLoading

  const outdatedAiStepId =
    outdatedAiSteps?.length === 1 ? outdatedAiSteps.pop() : undefined

  return (
    <Box gap={0} flex={1}>
      {renameScenarioModal}

      {!isBuilderReadonly && outdatedAiSteps && outdatedAiSteps.length > 1 && (
        <UpdateAllVideosButton
          onClick={() => updateVideos(outdatedAiSteps)}
          isLoading={isUpdatingVideos}
        />
      )}

      {isStepsInitializing ? (
        <CenteredSpinner />
      ) : (
        <StepReviewList steps={steps} outdatedStepId={outdatedAiStepId} />
      )}

      <StickyFooter
        nextLabelKey={isBuilderReadonly ? 'common.continue' : 'common.saveAndFinalize'}
        isNextLoading={isSubmitting}
        onBack={() => setCurrentStep('conversation-customization')}
        onNext={handleContinue}
      />
    </Box>
  )
}
