import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  HStack,
  Stack,
  Text,
  useConst,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, RHF, showToast, useFormRules, useModal } from '@repo/ui'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { useProgramsList } from '../../new-program-builder/shared/api'
import { type ExtendedProgram } from '../../types/api-types'
import { client } from '../../utils/openapi-client'

type DuplicateProgramFormValues = {
  title: string
}

const DuplicateProgramForm = ({
  program,
  closeModal,
}: {
  program: ExtendedProgram
  closeModal: () => void
}) => {
  const { mutate, data } = useProgramsList()
  // we are using constPrograms to save the first value of the programs, so it does not update the `isTitleExists` value when we update programs data manually with mutate function
  const constPrograms = useConst(() => data)

  const { formatMessage } = useIntl()

  const { handleSubmit, formState, control, watch } = useForm<DuplicateProgramFormValues>(
    {
      mode: 'onTouched',
      defaultValues: async () => ({
        title: `${program.title} ${formatMessage({
          id: 'coaching.program.clone',
        })}`,
      }),
    }
  )

  const rules = useFormRules()

  const onSubmit: SubmitHandler<DuplicateProgramFormValues> = async ({ title }) => {
    try {
      const { data: clonedProgram } = await client.post('cloneProgram', {
        body: { title },
        params: { path: { extId: program.extId } },
      })

      if (!clonedProgram) {
        throw new Error()
      }

      showToast({ messageKey: 'general.success.program.duplicated', status: 'success' })

      mutate(
        prev => [
          {
            ...clonedProgram,
            hasBreakingChanges: false,
            hasRecordingSessions: false,
            hasRegisteredCoachingUsers: false,
            sharedPrograms: [],
            trainingsCount: program.trainingsCount,
          },
          ...(prev ?? []),
        ],
        false
      )
    } catch (err) {
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    } finally {
      closeModal()
    }
  }

  const isTitleExists = constPrograms?.some(p => p.title === watch('title'))

  return (
    <Stack as="form" gap={4} onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={1}>
        <RHF.Input
          name="title"
          control={control}
          rules={rules.stringShortRequired}
          labelKey="coaching.program.new.title"
          componentProps={{
            bg: isTitleExists ? colors.indicator['yellow-extra-light'] : 'white',
            borderColor: isTitleExists
              ? `${colors.progressBarYellow[500]} !important`
              : '',
            boxShadow: isTitleExists ? 'none !important' : undefined,
          }}
        />
        {isTitleExists && (
          <Text color={colors.gray[600]} fontSize="12px">
            *<FormattedMessage id="coaching.program.name.exist" />
          </Text>
        )}
      </Stack>

      <Alert status="info" px={0} pb={2} bg="white">
        <AlertIcon />
        <AlertDescription fontSize="sm">
          <FormattedMessage id="coaching.program.duplicate.info" />
        </AlertDescription>
      </Alert>
      <HStack gap={4} justify="center">
        <Button variant="outline" onClick={closeModal}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          type="submit"
          isDisabled={!formState.isValid}
          isLoading={formState.isSubmitting}
        >
          <FormattedMessage id="common.continue" />
        </Button>
      </HStack>
    </Stack>
  )
}

export const useDuplicateProgramModal = (program: ExtendedProgram) => {
  const modal = useModal(
    ({ closeModal }) => (
      <DuplicateProgramForm closeModal={closeModal} program={program} />
    ),
    {
      titleKey: 'coaching.programList.table.options.duplicate',
    }
  )

  return modal
}
