/* eslint-disable no-nested-ternary */
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  type FormLabelProps,
  HStack,
  type HTMLChakraProps,
  Input,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, CustomFormLabel, Spinner } from '@repo/ui'
import {
  type ChangeEvent,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { type FieldError } from 'react-hook-form'

import { DeleteIconButton } from '../builder/components/delete-icon-button'
import { uploadMediaFile } from '../firebase'
import { useGoogleStorageAsset } from '../hooks/use-google-storage-asset'
import { useOrganization } from '../hooks/use-organization'
import { loadImageFromFile } from '../utils/load-image-from-file'
import { TargetBox } from './image-picker/target-box'
import { type ErrorStates } from './upload-error'

const MAX_IMAGE_UPLOAD_SIZE = 10 * 1024 * 1024 // 10Mb

export type ImageUploaderProps = {
  value?: string
  setValue: (value: string) => void
  setTouched?: (touched: boolean) => void
  error?: FieldError | undefined
  onFormError?: (error: null | string) => void
  isRequired?: boolean
  label?: ReactNode
  disabled?: boolean
  sizeLimit?: number
  setImageHttpUrl?: (imageUrl: string | undefined) => void
  setIsUploadingFile?: (uploadingFile: boolean) => void
  maxWidth?: number
  maxHeight?: number
  minWidth?: number
  minHeight?: number
  fontSize?: FormLabelProps['fontSize']
} & HTMLChakraProps<'img'>

// We cannot move this to @repo/ui because it depends on a lot of components/logic from admin package
export const ImageUploader = ({
  error,
  isRequired,
  value,
  setValue,
  label,
  disabled = false,
  sizeLimit = MAX_IMAGE_UPLOAD_SIZE,
  maxWidth = 800,
  maxHeight = 400,
  minHeight = 100,
  minWidth = 100,
  fontSize,
  setImageHttpUrl,
  setIsUploadingFile,
  onFormError,
  ...chakraProps
}: ImageUploaderProps) => {
  const [uploadingFile, setUploadingFile] = useState(false)
  const inputFile = useRef<HTMLInputElement>(null)
  const { formatMessage } = useIntl()

  const [pickedFile, setPickedFile] = useState<File>()
  const { organization } = useOrganization()

  const imageHttpUrl = useGoogleStorageAsset(value)

  const handleFileInputClick = useCallback(() => {
    inputFile.current?.click()
  }, [])

  useEffect(() => {
    if (setImageHttpUrl) {
      setImageHttpUrl(imageHttpUrl)
    }
  }, [imageHttpUrl, setImageHttpUrl])

  useEffect(() => {
    if (setIsUploadingFile) {
      setIsUploadingFile(uploadingFile)
    }
  }, [uploadingFile, setIsUploadingFile])

  const validateFile = useCallback(
    async (file: File): Promise<ErrorStates | null> => {
      // Validate file size
      if (file.size > sizeLimit) {
        return 'maxUploadSize'
      }

      // Validate image dimensions
      const image = await loadImageFromFile(file)

      if (image.width < minWidth || image.width > maxWidth) {
        return 'uploadDimension'
      }

      return null
    },
    [sizeLimit, maxWidth, minWidth]
  )

  const handleImageUpload = useCallback(
    async (file: File) => {
      if (!file || !organization) {
        return
      }

      try {
        // upload process
        setUploadingFile(true)
        const imageGSUrl = await uploadMediaFile(file, organization.id)

        setValue(imageGSUrl)
      } catch (err) {
        console.error('Error uploading file:', err)
      } finally {
        setUploadingFile(false)
      }
    },
    [organization, setValue]
  )

  const handleFilePick = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0]

      if (!selectedFile) {
        return
      }

      const validationError = await validateFile(selectedFile)

      if (validationError) {
        onFormError?.(
          formatMessage(
            {
              id: `error.videoUpload.${validationError}`,
            },
            { maxHeight, maxWidth, minHeight, minWidth }
          )
        )
      } else {
        onFormError?.(null)
      }

      setPickedFile(selectedFile)

      if (!validationError) {
        await handleImageUpload(selectedFile)
      }
    },
    [
      validateFile,
      onFormError,
      handleImageUpload,
      formatMessage,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
    ]
  )

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error}>
      <CustomFormLabel fontSize={fontSize}>{label}</CustomFormLabel>
      <Box m={0}>
        <Center
          w={value || pickedFile ? '10.5rem' : '100%'}
          h="10.5rem"
          cursor={disabled ? 'not-allowed' : 'pointer'}
          onClick={!value && !pickedFile && !disabled ? handleFileInputClick : undefined}
          {...chakraProps}
        >
          {value || imageHttpUrl || pickedFile ? (
            <Flex
              justify="center"
              align="end"
              bgImage={pickedFile ? URL.createObjectURL(pickedFile) : imageHttpUrl}
              bgSize="cover"
              bgPos="center"
              height="100%"
              width="100%"
              borderRadius="md"
              position="relative"
            >
              <DeleteIconButton
                position="absolute"
                top={0}
                right={0}
                color="gray.200"
                bg="blackAlpha.600"
                _hover={{ color: 'blue.700' }}
                title="Delete Image"
                isDisabled={disabled}
                message="Are you sure you want to delete this image?"
                onConfirmAction={() => {
                  setValue('')
                  setPickedFile(undefined)
                  onFormError?.(null)
                }}
              />
            </Flex>
          ) : uploadingFile ? (
            <Spinner />
          ) : (
            <Box
              textAlign="center"
              border="2px dashed"
              borderRadius="md"
              _hover={{ borderColor: colors.gray[700] }}
              p={2}
              borderColor={error ? 'red' : 'gray.300'}
              onDragOver={e => e.preventDefault()}
              onDrop={e => {
                e.preventDefault()
                const droppedFiles = e.dataTransfer.files

                if (droppedFiles.length > 0) {
                  handleFilePick({
                    target: { files: droppedFiles },
                  } as ChangeEvent<HTMLInputElement>)
                }
              }}
            >
              <TargetBox file={pickedFile} setFile={setPickedFile} />

              <HStack spacing={1} justify="center" mt={2}>
                <Text color="blue.500" fontSize="14px">
                  {pickedFile
                    ? formatMessage({ id: 'imagepicker.upload.new' })
                    : formatMessage({ id: 'imagepicker.button.upload' })}
                </Text>
                <Input
                  ref={inputFile}
                  type="file"
                  accept="image/png, image/jpg"
                  onChange={handleFilePick}
                  hidden
                />
                <Text fontSize="14px">
                  {formatMessage({ id: 'imagepicker.drag&drop' })}
                </Text>
              </HStack>
              <Text px={6} align="center" fontSize="12px" color="gray.400">
                <FormattedMessage
                  id="videoUpload.uploadDimension"
                  values={{ maxHeight, maxWidth, minHeight, minWidth }}
                />
              </Text>
            </Box>
          )}
        </Center>
      </Box>
      {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  )
}
