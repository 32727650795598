import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input as ChakraInput,
} from '@chakra-ui/react'
import { useField } from 'formik'
import PropTypes from 'prop-types'

export const Input = ({ label, help, type, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [field, meta] = useField(props as any)

  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}

      <ChakraInput type={type} {...field} {...props} />

      {meta.touched && meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      {help && <FormHelperText>{help}</FormHelperText>}
    </FormControl>
  )
}

Input.propTypes = {
  help: PropTypes.node,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}
