import { Badge, type ThemeTypings } from '@chakra-ui/react'
import { isNull } from 'lodash-es'

interface PercentageBadgeProps {
  value: number | null
}

export const PercentageBadge = ({ value }: PercentageBadgeProps) => {
  let colorScheme: ThemeTypings['colorSchemes'] = 'gray'

  if (!isNull(value)) {
    if (value < 70) {
      colorScheme = value < 30 ? 'red' : 'yellow'
    } else {
      colorScheme = 'green'
    }
  }

  return (
    <Badge
      colorScheme={colorScheme}
      borderRadius="2px"
      w="60px"
      textAlign="center"
      fontWeight="normal"
      ml="15%"
    >
      {isNull(value) ? '-' : `${value}%`}
    </Badge>
  )
}
