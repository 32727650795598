import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  IconButton,
  InputGroup,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import {
  colors,
  FormikFormField,
  FormikFormFieldHeader,
  NumberInput as NumberInputChakra,
} from '@repo/ui'
import { type FormikErrors, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { RiMoreFill } from 'react-icons/ri'

import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { type FormValues, type Question } from './types'

type QuestionCardProps = {
  data: Question
  handleRemove: (index: number) => void
  index: number
}

export const QuestionCard = ({ data, handleRemove, index }: QuestionCardProps) => {
  const { errors, setFieldValue } = useFormikContext<FormValues>()
  const { text, type, repeatable, timeLimit } = data
  const isVideo = type === 'video'
  const handleRemoveClick = () => handleRemove(index)
  const { formatMessage } = useIntl()
  const [errorQuestionIndex, setErrorQuestionIndex] = useState<Array<number>>()
  const buttonAriaLabel = formatMessage({
    id: 'cohort.details.header.menu.ariaLabel',
  })

  useEffect(() => {
    if (errors?.questions?.[index]) {
      setErrorQuestionIndex([0])
    }
  }, [errors, index])

  const textErrorKey = (errors?.questions?.[index] as FormikErrors<Question> | undefined)
    ?.text

  return (
    <Accordion
      defaultIndex={[index]}
      index={errors?.questions?.length ? errorQuestionIndex : undefined}
      allowMultiple
    >
      <AccordionItem>
        <HStack bgColor={colors.secondary}>
          <AccordionButton>
            <HStack flex="1" textAlign="left" spacing={2}>
              <Box>
                <FormattedMessage
                  id={`questionnaire.form.question.${
                    isVideo ? 'scenario.label' : 'title'
                  }`}
                  values={{ number: index + 1 }}
                />
                :
              </Box>
              <Text>{text}</Text>
            </HStack>
            <AccordionIcon />
          </AccordionButton>

          <Menu>
            <MenuButton
              as={IconButton}
              variant="ghost"
              icon={<RiMoreFill />}
              aria-label={buttonAriaLabel}
              onClick={e => e.stopPropagation()}
            />
            <MenuList onClick={e => e.stopPropagation()}>
              <InterceptAndConfirm
                onConfirm={handleRemoveClick}
                title={formatMessage(
                  { id: 'question.delete.confirmModal.title' },
                  {
                    questionName: formatMessage(
                      {
                        id: `questionnaire.form.question.${
                          isVideo ? 'scenario.label' : 'title'
                        }`,
                      },
                      { number: index + 1 }
                    ),
                  }
                )}
                description={formatMessage({
                  id: 'question.delete.confirmModal.description',
                })}
              >
                {({ openModal }) => (
                  <MenuItem onClick={openModal}>
                    <Text color="indicator.red-dark">
                      <FormattedMessage id="common.delete" />
                    </Text>
                  </MenuItem>
                )}
              </InterceptAndConfirm>
            </MenuList>
          </Menu>
        </HStack>

        <AccordionPanel border="md" pb={4}>
          <HStack>
            <HStack>
              <FormikFormFieldHeader
                label={formatMessage({
                  id: 'common.type',
                })}
              />

              <Select
                w="100px"
                h="40px"
                borderRadius="sm"
                onChange={option =>
                  setFieldValue(`questions.${index}.type`, option.target.value)
                }
                value={type}
              >
                <option value="standard">
                  {formatMessage({
                    id: 'common.text',
                  })}
                </option>
                <option value="video">
                  {formatMessage({
                    id: 'common.video',
                  })}
                </option>
              </Select>
            </HStack>
            <FormikFormField
              name={`questions.${index}.text`}
              errors={textErrorKey ? formatMessage({ id: textErrorKey as I18nKey }) : ''}
              label={
                isVideo
                  ? formatMessage({
                      id: 'questionnaire.form.question.scenario.label',
                    })
                  : formatMessage({
                      id: 'questionnaire.form.question.text.label',
                    })
              }
              width="700px"
            />
            <HStack>
              <FormikFormFieldHeader
                label={formatMessage({
                  id: 'questionnaire.form.question.repetitions.label',
                })}
              />
              <NumberInputChakra
                id={`questions.${index}.repeatable`}
                defaultValue={0}
                value={repeatable}
                min={0}
                size="md"
                enableSteppers={false}
                inputMode="numeric"
                onChange={value =>
                  setFieldValue(
                    `questions.${index}.repeatable`,
                    value ? Number.parseInt(value, 10) : ''
                  )
                }
              />
            </HStack>
            <InputGroup w="400px" alignItems="center">
              <FormikFormFieldHeader
                label={formatMessage({
                  id: 'questionnaire.form.question.timeLimit.label',
                })}
              />
              <NumberInputChakra
                id={`questions.${index}.timeLimit`}
                value={timeLimit}
                min={0}
                size="md"
                enableSteppers={false}
                inputMode="numeric"
                onChange={value =>
                  setFieldValue(
                    `questions.${index}.timeLimit`,
                    value ? Number.parseInt(value, 10) : ''
                  )
                }
              />

              <InputRightAddon borderRadius="sm" h="40px">
                <FormattedMessage id="questionnaire.form.question.timeLimit.addon" />
              </InputRightAddon>
            </InputGroup>
          </HStack>

          <FormikFormField
            name={`questions.${index}.note`}
            as={Textarea}
            placeholder={
              isVideo
                ? 'https://'
                : formatMessage({
                    id: 'common.optional',
                  })
            }
            label={
              isVideo
                ? formatMessage({
                    id: 'questionnaire.form.question.video.label',
                  })
                : formatMessage({
                    id: 'questionnaire.form.question.note.label',
                  })
            }
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
