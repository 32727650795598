import {
  Box,
  Center,
  Icon,
  Image,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { colors, EmptyStateIndicator } from '@repo/ui'
import { useState } from 'react'
import { type IconType } from 'react-icons'
import { BsImage } from 'react-icons/bs'
import { MdImage } from 'react-icons/md'

import { SelectWithPopover } from '../../components/select-with-popover'
import { useGoogleStorageAsset } from '../../hooks/use-google-storage-asset'

type Option = {
  key: string
  label: string
  imageSrc: string
}

const OptionItem = <T extends Option>({
  option,
  onChange,
  imageAspectRatio,
  isSelected,
}: {
  option: T
  onChange: (option: T) => void
  imageAspectRatio: string
  isSelected?: boolean
}) => {
  const imageHttpUrl = useGoogleStorageAsset(option.imageSrc)

  return (
    <Box
      key={option.key}
      textAlign="center"
      cursor="pointer"
      onClick={() => onChange(option)}
      color={isSelected ? colors.blue[500] : colors.gray[500]}
      _hover={{
        textDecor: 'underline',
        img: {
          boxShadow: `0px 0px 14px 0px #00000040`,
        },
      }}
    >
      <Box
        transition="box-shadow 0.2s ease"
        bg={colors.blue[50]}
        outline={isSelected ? `3px solid ${colors.blue[500]} !important` : undefined}
        pointerEvents="none"
        aspectRatio={imageAspectRatio}
        borderRadius={8}
        overflow="hidden"
      >
        <Image
          src={imageHttpUrl}
          w="full"
          h="full"
          objectFit="cover"
          fallback={
            <Center w="full" h="full">
              <Icon fontSize={32} as={MdImage} color={colors.gray[400]} />
            </Center>
          }
        />
      </Box>

      <Text mt={3} fontSize={14}>
        {option.label}
      </Text>
    </Box>
  )
}

interface ImageSelectorProps<T extends Option> {
  titleKey: I18nKey
  options: Array<T>
  value?: T
  onChange: (option: T) => void
  imageAspectRatio?: string
  placeholderKey: I18nKey
  searchPlaceholderKey: I18nKey
  columnCount?: number
  icon?: IconType
}

export const ImageSelector = <T extends Option>({
  onChange,
  options,
  titleKey,
  value,
  imageAspectRatio = '16/9',
  placeholderKey,
  searchPlaceholderKey,
  columnCount = 2,
  icon = BsImage,
}: ImageSelectorProps<T>) => {
  const [search, setSearch] = useState('')

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(search.toLowerCase())
  )

  const disclosure = useDisclosure()

  return (
    <SelectWithPopover
      titleKey={titleKey}
      placeholderKey={placeholderKey}
      inputValue={value?.label}
      searchInputProps={{
        value: search,
        onChange: setSearch,
        placeholderKey: searchPlaceholderKey,
      }}
      disclosure={disclosure}
      icon={icon}
    >
      {filteredOptions.length ? (
        <SimpleGrid columns={columnCount} gap={4} maxH="400px" overflowY="scroll" p={4}>
          {filteredOptions.map(option => (
            <OptionItem
              key={option.key}
              option={option}
              onChange={v => {
                onChange(v)
                disclosure.onClose()
              }}
              imageAspectRatio={imageAspectRatio}
              isSelected={value?.key === option.key}
            />
          ))}
        </SimpleGrid>
      ) : (
        <EmptyStateIndicator messageKey="general.noDataAvailable" />
      )}
    </SelectWithPopover>
  )
}
