import { Box, Code, HStack } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { type PropsWithChildren } from 'react'

export const ComponentVariant = ({
  title,
  children,
}: PropsWithChildren<{ title: string }>) => (
  <Box overflow="hidden" border="1px solid" borderColor={colors.gray[400]}>
    <Box bg={colors.gray[200]} alignItems="flex-start">
      <Code p={4} bg="none" fontWeight="500">
        {title}
      </Code>
      <HStack p={4} w="full" bg="white">
        {children}
      </HStack>
    </Box>
  </Box>
)
