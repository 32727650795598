import { Badge, Box, Stack, Text } from '@chakra-ui/react'
import { type ApiSchemas } from '@repo/api'
import { FormattedMessage } from '@repo/i18n'
import { RHF, useFormRules } from '@repo/ui'
import { forwardRef, useImperativeHandle } from 'react'
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form'

import { type CoachingStepAIQA } from '../../../../../../builder/steps/api'
import { StickyFooter } from '../../../../../shared/sticky-footer'
import { useInteractionStepContext } from '../../interaction-step-context'
import { QuestionFormSection } from './question-form/question-form-section'
import {
  getDefaultFormValues,
  getQuestionFormDataFromSuggestion,
  type LastFetchedSuggestion,
  orderedFormSections,
  type QuestionFormValues,
} from './question-form-utils'

export type QuestionFormProps = {
  onSaveQuestion: (data: {
    formValues: QuestionFormValues
    isFinalized: boolean
  }) => Promise<void>
  questionData?: CoachingStepAIQA
  isReadonly: boolean
  lastFetchedSuggestion?: LastFetchedSuggestion
}

export type QuestionFormHandle = {
  applySuggestion: (data: ApiSchemas['generateStepContentResponse']) => void
}

export const QuestionForm = forwardRef<QuestionFormHandle, QuestionFormProps>(
  ({ onSaveQuestion, questionData, isReadonly, lastFetchedSuggestion }, ref) => {
    const rules = useFormRules()
    const { setCurrentStep } = useInteractionStepContext()

    const form = useForm<QuestionFormValues>({
      mode: 'onTouched',
      defaultValues: async () =>
        getDefaultFormValues(questionData, lastFetchedSuggestion),
      disabled: isReadonly,
    })

    const { control, handleSubmit, formState, watch, setValue } = form

    useImperativeHandle(
      ref,
      () => ({
        applySuggestion: data => {
          const { fieldKey, value } = getQuestionFormDataFromSuggestion(data)

          setValue(fieldKey, value, { shouldValidate: true, shouldDirty: true })
        },
      }),
      [setValue]
    )

    const currentFormSection = watch('currentFormSection')

    const atLastSection = currentFormSection === orderedFormSections.slice(-1)[0]

    const onSubmit: SubmitHandler<QuestionFormValues> = async formValues => {
      try {
        await onSaveQuestion({ formValues, isFinalized: atLastSection })

        if (!atLastSection) {
          const nextSectionIndex = orderedFormSections.indexOf(currentFormSection) + 1

          setValue('currentFormSection', orderedFormSections[nextSectionIndex]!)
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }

    return (
      <FormProvider {...form}>
        <Stack as="form" gap={0} flex={1} onSubmit={handleSubmit(onSubmit)}>
          <Box flex={1}>
            <QuestionFormSection
              sectionKey="questionTranscript"
              inputComponent={
                <RHF.TextArea
                  control={control}
                  name="questionTranscript"
                  rules={{
                    ...rules.stringLongRequired,
                    minLength: rules.primitive.minLength(3), // // D-ID requires at least 3 characters to generate a video
                  }}
                  tooltipKey="scenario.ai.builder.steps.conversationGeneration.transcript.tooltip.text"
                />
              }
            >
              <Badge colorScheme="purple" alignSelf="flex-start">
                <FormattedMessage id="scenario.builder.ai.steps.conversationGeneration.statement" />
              </Badge>
            </QuestionFormSection>

            <QuestionFormSection
              sectionKey="behavioralGoals"
              inputComponent={
                <RHF.TextArea
                  control={control}
                  name="behavioralGoals"
                  rules={rules.stringLongRequired}
                />
              }
            >
              <Badge colorScheme="pink" alignSelf="flex-start">
                <FormattedMessage id="scenario.builder.ai.steps.conversationGeneration.goal" />
              </Badge>
            </QuestionFormSection>

            <QuestionFormSection
              sectionKey="dos"
              inputComponent={
                <RHF.FieldArray
                  defaultValue={{ value: '' }}
                  control={control}
                  name="dos"
                  maxItems={3}
                  minItems={1}
                  renderField={index => (
                    <RHF.Input
                      control={control}
                      name={`dos.${index}.value`}
                      rules={{
                        ...rules.stringShortRequired,
                        deps: ['dos'],
                        validate: {
                          ...rules.stringShortRequired.validate,
                          duplicate: (value, values) =>
                            rules.validateDuplicatedText({
                              value,
                              allValues: values.dos.map(s => s.value),
                              shouldMatchTwice: true,
                            }),
                        },
                      }}
                    />
                  )}
                />
              }
            >
              <Text fontSize={18}>
                <FormattedMessage id="scenario.builder.ai.steps.conversationGeneration.dos" />
              </Text>
            </QuestionFormSection>

            <QuestionFormSection
              sectionKey="donts"
              inputComponent={
                <RHF.FieldArray
                  defaultValue={{ value: '' }}
                  control={control}
                  name="donts"
                  maxItems={3}
                  renderField={index => (
                    <RHF.Input
                      control={control}
                      name={`donts.${index}.value`}
                      rules={{
                        ...rules.stringShort,
                        deps: ['donts'],
                        validate: (value, values) =>
                          rules.validateDuplicatedText({
                            value,
                            allValues: values.donts.map(s => s.value),
                            shouldMatchTwice: true,
                          }),
                      }}
                    />
                  )}
                />
              }
            >
              <Text fontSize={18}>
                <FormattedMessage id="scenario.builder.ai.steps.conversationGeneration.donts" />{' '}
                (<FormattedMessage id="common.optional" />)
              </Text>
            </QuestionFormSection>
          </Box>

          <StickyFooter
            onBack={() => {
              setCurrentStep('scenario-goal')
            }}
            onNext={
              isReadonly ? () => setCurrentStep('conversation-customization') : undefined
            }
            isNextDisabled={!isReadonly && (!atLastSection || !formState.isValid)}
            isNextLoading={atLastSection && formState.isSubmitting}
          />
        </Stack>
      </FormProvider>
    )
  }
)
