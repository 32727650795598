export type InteractionStepKey =
  | 'conversation-dynamics'
  | 'session-details'
  | 'stage-goals'
  | 'behavioral-attitude'
  | 'instructions'
  | 'conversation-customization'
  | 'review'

export const orderedInteractionStepKeys: Array<InteractionStepKey> = [
  'conversation-dynamics',
  'session-details',
  'stage-goals',
  'behavioral-attitude',
  'instructions',
  'conversation-customization',
  'review',
]

export const interactionStepConfig: Record<
  InteractionStepKey,
  {
    stepperKey: I18nKey
    titleKey: I18nKey
    subtitleKey: I18nKey
  }
> = {
  'conversation-dynamics': {
    stepperKey: 'scenario.manual.builder.steps.dynamics',
    titleKey: 'scenario.builder.ai.steps.interaction.formTitle',
    subtitleKey: 'scenario.builder.ai.steps.interaction.formSubtitle',
  },
  'session-details': {
    stepperKey: 'scenario.realtime.builder.steps.details',
    titleKey: 'scenario.realtime.builder.steps.session-details.title',
    subtitleKey: 'scenario.realtime.builder.steps.session-details.subtitle',
  },
  'stage-goals': {
    stepperKey: 'scenario.realtime.builder.steps.goal',
    titleKey: 'scenario.realtime.builder.steps.stage-goals.title',
    subtitleKey: 'scenario.realtime.builder.steps.stage-goals.subtitle',
  },
  'behavioral-attitude': {
    stepperKey: 'scenario.realtime.builder.steps.behavioral',
    titleKey: 'scenario.realtime.builder.steps.behavioral-attitude.title',
    subtitleKey: 'scenario.realtime.builder.steps.behavioral-attitude.subtitle',
  },
  instructions: {
    stepperKey: 'scenario.realtime.builder.steps.instructions',
    titleKey: 'scenario.realtime.builder.steps.instructions.title',
    subtitleKey: 'scenario.realtime.builder.steps.instructions.subtitle',
  },
  'conversation-customization': {
    stepperKey: 'scenario.manual.builder.steps.customization',
    titleKey: 'scenario.realtime.builder.steps.conversation-customization.title',
    subtitleKey: 'scenario.builder.ai.steps.conversationCustomization.formTitle',
  },
  review: {
    stepperKey: 'scenario.manual.builder.steps.review',
    titleKey: 'scenario.realtime.builder.steps.review.title',
    subtitleKey: 'scenario.realtime.builder.steps.review.subtitle',
  },
}
