import { Stack } from '@chakra-ui/react'

import { ContentEditorManagerProvider } from '../../../builder/components/content-editor/content-editor-manager-provider'
import { DragDropStepEditor } from '../../shared/drag-drop-step-editor'
import { StickyFooter } from '../../shared/sticky-footer'
import { useManualBuilderContext } from '../shared/manual-builder-context'
import { StepsProviderNew, useStepsNew } from '../steps-context'

const CustomizationStepInner = () => {
  const { goToPreviousStep, goToNextStep, isBuilderReadonly } = useManualBuilderContext()

  const { steps, editingUuid } = useStepsNew()

  const currentSteps = editingUuid
    ? steps.filter(({ uuid }) => uuid !== editingUuid)
    : steps

  const hasQAStep = currentSteps.find(
    ({ data }) => data.step.stepType === 'coachingStepQA'
  )

  return (
    <Stack flex={1}>
      <DragDropStepEditor isReadonly={isBuilderReadonly} />

      <StickyFooter
        onBack={goToPreviousStep}
        onNext={goToNextStep}
        isNextDisabled={!isBuilderReadonly && (currentSteps.length === 0 || !hasQAStep)}
      />
    </Stack>
  )
}

// This outer component is necessary to provide the required contexts to the inner component
export const CustomizationStep = () => {
  const { programExtId, trainingExtId, scenarioExtId } = useManualBuilderContext()

  return (
    <ContentEditorManagerProvider>
      <StepsProviderNew
        programId={programExtId}
        trainingId={trainingExtId}
        scenarioId={scenarioExtId}
      >
        <CustomizationStepInner />
      </StepsProviderNew>
    </ContentEditorManagerProvider>
  )
}
