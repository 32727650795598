import { omit } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  createBenchmarkProfile,
  updateBenchmarkProfile,
} from '../store/entities/benchmark-profiles/effects'
import { makeSelectBenchmarkProfileSelector } from '../store/entities/benchmark-profiles/selectors'
import { getValues } from '../utils/benchmark'
import fields from './fields.json'

const categories = [
  {
    id: 'big5',
    fields: [
      'agreeableness',
      'conscientiousness',
      'extraversion',
      'neuroticism',
      'openness',
    ],
  },
  {
    id: 'culturefit',
    fields: [
      'cfCompetitive',
      'cfDetailOriented',
      'cfInnovative',
      'cfOutcomeOriented',
      'cfStability',
      'cfSupportive',
      'cfTeamOriented',
    ],
  },
  {
    id: 'taskfit',
    fields: ['tfAutonomy', 'tfDealing', 'tfIdentity', 'tfSocialRelations', 'tfVariety'],
  },
]

export const useBenchmarkProfile = id => {
  const dispatch = useDispatch()
  const selectBenchmarkProfile = useMemo(makeSelectBenchmarkProfileSelector, [])
  // If no target profile is found by `id`, we receive `undefined`. Please make sure
  // to make checks before accessing props on `data`.
  const dataNewFormat = useSelector(state => selectBenchmarkProfile(state, { id }))
  let data

  if (dataNewFormat) {
    data = {
      ...omit(dataNewFormat, ['values']),
      scores: [],
      values: [],
    }

    // TODO: instead of changing format to old version, update BenchmarkProfileEditor component
    data.scores = dataNewFormat.values.reduce(
      (o, value) => ({ ...o, [value.name]: value.weight }),
      {}
    )

    data.values = getValues(dataNewFormat.values)
  }

  useEffect(() => {
    if (id && !data) {
      throw Error(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        `Expected to find target profile for id \`${id}\`, but received \`${typeof profile}\`.`
      )
    }
  }, [id, data])

  const getFieldsByCategoryId = useCallback(
    categoryId => {
      const category = categories.find(item => item.id === categoryId)

      // I would rather return an empty array, instead of `null`. Not only is this more
      // comfortable to use, but also shows the developer that no fields exist for the `categoryId`
      // he passed to this function.
      if (!category) {
        return []
      }

      return category.fields.map(item => ({
        ...fields.find(({ id: fieldId }) => fieldId === item),
        value: data?.values ? data.values[item] : 0,
      }))
    },
    [data]
  )

  const getFieldsGroupedByCategory = useCallback(
    () =>
      categories.map(({ id: categoryId, ...rest }) => ({
        ...rest,
        id: categoryId,
        fields: getFieldsByCategoryId(categoryId),
      })),
    [getFieldsByCategoryId]
  )

  const handleSave = useCallback(
    async ({ title, type, values, scores }) => {
      const body = { title, type, values, scores }

      return dispatch(
        data ? updateBenchmarkProfile(id, body) : createBenchmarkProfile(body)
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  return {
    data,
    fields,
    getFieldsByCategoryId,
    getFieldsGroupedByCategory,
    save: handleSave,
  }
}
