import { Button, Flex, Image } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

import CreatedImage from '../../../assets/benchmarking-wizard-createdProfile.png'
import { ButtonContainer } from './button-container'
import { ContentContainer } from './content-container'

type CreatedScreenProps = {
  handleContinue: VoidFunction
  handleCancel: VoidFunction
  isCreatingBenchmarkProfile: boolean
}

export const CreatedScreen = ({
  handleContinue,
  handleCancel,
  isCreatingBenchmarkProfile,
}: CreatedScreenProps) => (
  <ContentContainer
    title={<FormattedMessage id="coaching.benchmarking.wizard.created.title" />}
    subtitle={<FormattedMessage id="coaching.benchmarking.wizard.created.subtitle" />}
  >
    <Flex justifyContent="center" p={8} alignItems="center" w="100%">
      <Image height="200px" src={CreatedImage} />
    </Flex>
    <ButtonContainer>
      <Button
        variant="outline"
        onClick={() => handleCancel()}
        isLoading={isCreatingBenchmarkProfile}
      >
        <FormattedMessage id="coaching.benchmarking.wizard.created.cancel" />
      </Button>
      <Button onClick={handleContinue} isLoading={isCreatingBenchmarkProfile}>
        <FormattedMessage id="coaching.benchmarking.wizard.created.continue" />
      </Button>
    </ButtonContainer>
  </ContentContainer>
)
