import { FormattedMessage } from '@repo/i18n'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'

import { AdvancedTable } from '../../../components/advanced-table'
import { useDashboardContext } from '../../shared/dashboard-context'
import { type GeneralScore } from '../../shared/types'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { NoResultsCell } from './shared/no-results-cell'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

const columnHelper = createColumnHelper<{
  id: string
  cohortName: string
  overallScore: GeneralScore
  isHiddenMetric: boolean
}>()

const columns = [
  columnHelper.accessor('cohortName', {
    header: () => (
      <FormattedMessage id="dashboard.cards.cohortPerformance.details.cohortName.table.header" />
    ),
  }),
  columnHelper.accessor('overallScore', {
    header: () => (
      <FormattedMessage id="dashboard.cards.cohortPerformance.details.overallScore.table.header" />
    ),
    cell: props =>
      props.getValue() === null ? (
        <NoResultsCell isHiddenMetric={props.row.original.isHiddenMetric} />
      ) : (
        `${props.getValue()}%`
      ),
    meta: {
      align: 'right',
    },
  }),
]

export const CohortPerformanceCardDetails = () => {
  const { dataSlot } = useDashboardCardData('cohortPerformance')

  const { pageState } = useDashboardContext()

  const tableRows = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    const rows = data.cohortNames.map((cohortName, index) => ({
      id: data.cohortExtIds[index]!,
      cohortName,
      overallScore: data.overallScore[index]!,
      isHiddenMetric: data.metricsHidden[index]!,
    }))

    const sortedRows = rows.toSorted((a, b) => b.overallScore - a.overallScore)

    return sortedRows
  }, [dataSlot])

  return (
    <DetailPageWrapper cardId="cohortPerformance">
      <AdvancedTable
        columns={columns}
        data={tableRows}
        isLoading={pageState === 'loading'}
      />
    </DetailPageWrapper>
  )
}
