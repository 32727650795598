import { Badge, Stack } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, RHF, useFormRules } from '@repo/ui'
import { type Control } from 'react-hook-form'

import { stageConfigs } from '../shared/stage-configs'
import { type StageGoalFormValues } from './types'

interface StageGoalSectionProps {
  stage: keyof StageGoalFormValues
  control: Control<StageGoalFormValues>
}

export const StageGoalSection = ({ stage, control }: StageGoalSectionProps) => {
  const { colorScheme } = stageConfigs[stage]
  const { formatMessage } = useIntl()
  const rules = useFormRules()

  return (
    <Stack gap={2}>
      <Badge colorScheme={colorScheme} alignSelf="flex-start" color={colors.purple[700]}>
        <FormattedMessage
          id={`scenario.realtime.builder.stages.${stage}` satisfies I18nKey}
        />
      </Badge>

      <RHF.TextArea
        control={control}
        name={stage}
        rules={rules.stringShortRequired}
        componentProps={{
          placeholder: formatMessage({
            id: `scenario.realtime.builder.stages.${stage}` satisfies I18nKey,
          }),
        }}
        helperTextKey="common.youCanEdit"
        labelKey="scenario.realtime.builder.stageGoal"
      />
    </Stack>
  )
}
