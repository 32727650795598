import { useIntl } from '@repo/i18n'
import { RHF, useFormRules } from '@repo/ui'
import { type UseFormReturn } from 'react-hook-form'
import { HiPlusSm } from 'react-icons/hi'

import { type InstructionFormValues } from '../use-instruction-form'

type AddReferenceProps = {
  form: UseFormReturn<InstructionFormValues>
  inputName: 'reference' | `hows.${number}.reference`
}

export const AttachReference = ({ form, inputName }: AddReferenceProps) => {
  const { formatMessage } = useIntl()
  const rules = useFormRules()

  const { control } = form

  return (
    <RHF.FieldArray
      control={control}
      name={inputName}
      maxItems={1}
      addNewButtonProps={{
        variant: 'outline',
        leftIcon: <HiPlusSm size="14px" />,
      }}
      addNewButtonKey="scenario.builder.realtime.steps.instructions.attach.reference.button"
      defaultValue={{ value: null }}
      renderField={index => (
        <RHF.Markdown
          control={control}
          name={`${inputName}.${index}.value`}
          labelKey="scenario.builder.realtime.steps.instructions.reference.label"
          tooltipKey="scenario.builder.realtime.steps.instructions.reference.tooltip"
          rules={rules.stringCustomLength(1000)}
          componentProps={{
            height: 120,
            excluded:
              /(live|edit|preview|fullscreen|code|codeBlock|image|checked-list|help|table)/,
            textareaProps: {
              placeholder: formatMessage({
                id: 'scenario.builder.realtime.steps.instructions.reference.placeholder',
              }),
            },
          }}
        />
      )}
      arrayItemSx={{
        maxWidth: '600px',
      }}
    />
  )
}
