import { Box, Button, Flex } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useFormContext } from 'react-hook-form'
import { MdArrowBack } from 'react-icons/md'

type SteppedModalFormStepFooterProps<T extends string> = {
  steps: Array<T>
  currentStep: T
  setCurrentStep: (step: T) => void
  mode: 'create' | 'edit'
  entityNameKey: I18nKey
}

export const SteppedModalFormStepFooter = <T extends string>({
  currentStep,
  steps,
  setCurrentStep,
  entityNameKey,
  mode,
}: SteppedModalFormStepFooterProps<T>) => {
  const { formState } = useFormContext()

  const isFirstStep = currentStep === steps[0]
  const isLastStep = currentStep === steps[steps.length - 1]

  return (
    <Flex mt="auto">
      {!isFirstStep && (
        <Button
          size="md"
          variant="outline"
          leftIcon={<MdArrowBack />}
          onClick={() => setCurrentStep(steps[steps.indexOf(currentStep) - 1]!)}
        >
          <FormattedMessage id="common.back" />
        </Button>
      )}

      <Box flex={1} />

      <Button
        size="md"
        isDisabled={!formState.isValid}
        isLoading={formState.isSubmitting}
        type="submit"
      >
        {isLastStep ? (
          <FormattedMessage
            id={mode === 'create' ? 'common.generateX' : 'common.saveX'}
            values={{
              x: <FormattedMessage id={entityNameKey} />,
            }}
          />
        ) : (
          <FormattedMessage id="common.continue" />
        )}
      </Button>
    </Flex>
  )
}
