import { HorizontalStepper } from '../../../../components/horizontal-stepper'
import { orderedTraineeSteps } from './shared/ordered-trainee-steps'
import { useTraineeFormContext } from './trainee-form-context'

export const TraineeFormStepper = () => {
  const { currentStep } = useTraineeFormContext()

  return (
    <HorizontalStepper
      currentStep={currentStep}
      stepsWithTitles={orderedTraineeSteps.map(step => ({
        key: step,
        titleKey: `scenario.builder.ai.steps.trainee.traineeModal.${step}`,
      }))}
      sx={{ maxW: '360px', px: 10 }}
    />
  )
}
