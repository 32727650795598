import { Button } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { useState } from 'react'

import { type ExtendedProgram } from '../../types/api-types'
import { client } from '../../utils/openapi-client'
import { useProgramsList } from '../shared/api'
import { type ErrorMessage, type PublishingError } from './publish-program-button/types'
import { usePublishingFailedModal } from './publish-program-button/use-publishing-failed-modal'
import { useShouldNotifyModal } from './publish-program-button/use-should-notify-modal'

export const PublishUnpublishProgramButton = ({
  program,
}: {
  program: ExtendedProgram
}) => {
  const { mutate: mutatePrograms } = useProgramsList()
  const [isLoading, setIsLoading] = useState(false)

  const [errors, setErrors] = useState<Array<ErrorMessage>>([])
  const [errorModal, showErrorModal] = usePublishingFailedModal(errors, program)
  const [shouldNotifyModal, askShouldNotifyViaModal] = useShouldNotifyModal()

  const publishProgram = async () => {
    try {
      // we're fetching the program just in case it has been updated, to make sure
      const { data: fetchedProgram } = await client.get('getProgramByExtId', {
        params: { path: { extId: program.extId } },
      })

      if (!fetchedProgram) {
        throw new Error()
      }

      const shouldOpenShouldNotifyModal =
        fetchedProgram.status === 'unpublished' &&
        fetchedProgram.hasBreakingChanges &&
        fetchedProgram.hasRegisteredCoachingUsers

      let shouldNotify: boolean | null = false

      if (shouldOpenShouldNotifyModal) {
        shouldNotify = await askShouldNotifyViaModal()

        // if null, means user closed the modal
        if (shouldNotify === null) {
          return
        }
      }

      const { error } = await client.put('publishProgram', {
        body: { shouldNotify },
        params: { path: { extId: program.extId } },
      })

      if (error) {
        throw error
      }

      await mutatePrograms(programs =>
        programs?.map(p =>
          p.extId === program.extId ? { ...p, status: 'publishing' } : p
        )
      )
    } catch (error) {
      const { message } = error as PublishingError | Error

      // Only show the error modal if we get the list of publishing errors
      if (Array.isArray(message) && message.length > 0) {
        setErrors(message)
        showErrorModal()
      } else {
        // This might happen if we get e.g. a 400 error
        showToast({
          messageKey: 'common.error.unexpected',
          status: 'error',
        })

        setErrors([]) // clear errors
      }
    }
  }

  const unpublishProgram = async () => {
    try {
      const { error } = await client.put('unpublishProgram', {
        params: { path: { extId: program.extId } },
      })

      if (error) {
        throw new Error()
      }

      await mutatePrograms(programs =>
        programs?.map(p =>
          p.extId === program.extId ? { ...p, status: 'unpublished' } : p
        )
      )
    } catch {
      showToast({
        messageKey: 'common.error.unexpected',
        status: 'error',
      })
    }
  }

  const handleClick = async () => {
    if (program.status === 'publishing') {
      return
    }

    try {
      setIsLoading(true)

      if (program.status === 'unpublished') {
        await publishProgram()
      } else {
        await unpublishProgram()
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {errorModal}
      {shouldNotifyModal}

      <Button
        onClick={handleClick}
        isLoading={isLoading || program.status === 'publishing'}
      >
        <FormattedMessage
          id={program.status === 'published' ? 'common.unpublish' : 'common.publish'}
        />
      </Button>
    </>
  )
}
