import { Grid, GridItem, useCheckboxGroup } from '@chakra-ui/react'

import { ToggleListSwitch } from './toggle-list/toggle-list-switch'

export type ToggleListItem<TValue extends string> = {
  labelKey: I18nKey
  color: string
  dataKey: TValue
}

interface ToggleListProps<TValue extends string> {
  items: Array<ToggleListItem<TValue>>
  value: Array<TValue>
  onChange: (value: Array<TValue>) => void
}

export const ToggleList = <TValue extends string>({
  value,
  onChange,
  items,
}: ToggleListProps<TValue>) => {
  const { getCheckboxProps } = useCheckboxGroup({
    value,
    onChange,
  })

  return (
    <Grid templateColumns="1fr 1fr" columnGap={2} rowGap={4}>
      {items.map(item => (
        <GridItem key={item.dataKey}>
          <ToggleListSwitch
            labelKey={item.labelKey}
            color={item.color}
            useCheckboxProps={getCheckboxProps({ value: item.dataKey })}
            showToggleFirst
          />
        </GridItem>
      ))}
    </Grid>
  )
}
