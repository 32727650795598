import { isProduction, isStaging } from '@repo/utils'

export const personaBackgroundNames = [
  'cafe-office',
  'car-gallery',
  'conference-room',
  'industrial-building',
  'industrial-office',
  'lounge',
  'minimalist-office',
  'open-workstations',
  'window-view',
  'clothing-shop',
  'corner-store',
  'coworking-office',
  'glass-office',
  'grocery-store',
  'luxury-store-front',
  'luxury-store',
  'music-store-front',
  'office',
  'outdoors',
  'park',
  'reception',
  'shoe-store',
  'store-front',
] as const

const bgFolder = (() => {
  if (isProduction) {
    return 'gs://retorio-assets/dId/background'
  }

  if (isStaging) {
    return 'gs://retorio-assets-staging/dId/background'
  }

  return 'gs://retorio-assets-dev/dId/background'
})()

export const personaBackgroundNameToUrl = (name: string) => `${bgFolder}/${name}.jpg`
