import { HStack, Radio, Stack, Text } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { type ReactNode } from 'react'

interface RadioCardProps {
  value: string
  title: ReactNode
  description: ReactNode
  isChecked: boolean
}

export const RadioCard = ({ value, title, description, isChecked }: RadioCardProps) => (
  <HStack
    border="2px"
    borderColor={colors.blue[100]}
    borderRadius="lg"
    padding={3}
    backgroundColor={isChecked ? colors.blue[50] : 'white'}
  >
    <Radio value={value}>
      <Stack spacing={0} ml={2}>
        <Text fontSize="sm" fontWeight="500">
          {title}
        </Text>
        <Text fontSize="sm">{description}</Text>
      </Stack>
    </Radio>
  </HStack>
)
