import { Button, Center, Heading, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { FormikFormField, showToast } from '@repo/ui'
import { Form, Formik, type FormikHelpers, type FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { object, type SchemaOf, string } from 'yup'

import { FormikImageUploader } from '../../components/formik-image-uploader'
import { saveGeneralSettings } from '../../store/entities/organization/effects'
import { selectOrganization } from '../../store/entities/organization/selectors'

type FormValues = {
  companyName: string
  logoUrl?: string | null
}

const GeneralForm = ({
  isSubmitting,
  errors,
  onCancel,
}: FormikProps<FormValues> & { onCancel: () => void }) => {
  const { formatMessage } = useIntl()

  return (
    <Form>
      <Stack>
        <Text as="h6" color="gray.600" margin="3px" size="xs">
          <FormattedMessage id="organization.settings.general.form.company.info" />
        </Text>
        <FormikFormField
          errors={errors.companyName}
          name="companyName"
          label={formatMessage({
            id: 'organization.settings.gdpr.form.company.field.name',
          })}
        />

        <Heading as="h6" color="gray.600" margin="3px" size="xs">
          <FormattedMessage id="organization.settings.general.form.title" />
        </Heading>
        <Text as="h6" color="gray.600" margin="3px" size="xs">
          <FormattedMessage id="organization.settings.general.form.info" />
        </Text>

        <FormikImageUploader name="logoUrl" />

        <Center gap={4}>
          <Button type="button" variant="outline" onClick={onCancel}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button type="submit" isLoading={isSubmitting} isDisabled={isSubmitting}>
            <FormattedMessage id="common.save" />
          </Button>
        </Center>
      </Stack>
    </Form>
  )
}

const defaultValues: FormValues = {
  companyName: '',
  logoUrl: null,
}

export const GeneralFormContainer = ({ onCancel }: { onCancel: () => void }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      await dispatch(saveGeneralSettings(values))
      actions.setSubmitting(false)
      showToast({ messageKey: 'common.alert.created', status: 'success' })
    } catch (err) {
      actions.setSubmitting(false)
      showToast({ messageKey: 'general.error.saving.data', status: 'error' })
    }
  }

  const { logoUrl, companyName } = useSelector(selectOrganization)

  const validationSchema: SchemaOf<FormValues> = object().shape({
    companyName: string()
      .required(formatMessage({ id: 'organization.settings.general.form.required' }))
      .matches(
        /^[a-zA-Z0-9äöüÄÖÜ \-.&]*$/g,
        formatMessage({ id: 'organization.settings.general.form.invalid' })
      ),
    logoUrl: string().nullable(),
  })

  return (
    <Formik<FormValues>
      initialValues={{
        ...defaultValues,
        companyName,
        logoUrl,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {props => <GeneralForm {...props} onCancel={onCancel} />}
    </Formik>
  )
}
