import { Button, Center, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast, useModal } from '@repo/ui'
import { type SubmitHandler } from 'react-hook-form'

import { type BehavioralGoal } from '../../../../types/api-types'
import { client } from '../../../../utils/openapi-client'
import { useRealtimeBuilderContext } from '../../shared/realtime-builder-context'
import { type BehavioralGoalFormValues } from '../../shared/types'
import { useBehavioralGoalForm } from './use-behavioral-goal-form'

const BehavioralGoalEditForm = ({
  behavioralGoal,
  closeModal,
}: {
  behavioralGoal: BehavioralGoal
  closeModal: () => void
}) => {
  const [form, formFieldsJsx] = useBehavioralGoalForm({
    description: behavioralGoal.description,
    skillName: behavioralGoal.skill.name,
  })

  const { programExtId, scenarioExtId, trainingExtId, mutateScenario } =
    useRealtimeBuilderContext()

  const { handleSubmit, formState } = form

  const onSubmit: SubmitHandler<BehavioralGoalFormValues> = async data => {
    try {
      const res = await client.put('updateWinningBehaviorRealtime', {
        params: {
          path: {
            extId: behavioralGoal.extId,
            programExtId,
            scenarioExtId,
            trainingExtId,
          },
        },
        body: {
          description: data.description,
          skillName: data.skillName,
          behaviorType: 'positive',
        },
      })

      if (res.error) {
        throw new Error()
      }

      // TODO optimistic update
      await mutateScenario()

      showToast({ messageKey: 'common.alert.saved', status: 'success' })
      closeModal()
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
      {formFieldsJsx}

      <Center gap={3} mt={10}>
        <Button variant="outline" onClick={closeModal}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          type="submit"
          isDisabled={!formState.isValid}
          isLoading={formState.isSubmitting}
        >
          <FormattedMessage id="common.save" />
        </Button>
      </Center>
    </Stack>
  )
}

export const useEditBehavioralGoalModal = (behavioralGoal: BehavioralGoal) =>
  useModal(
    ({ closeModal }) => (
      <BehavioralGoalEditForm behavioralGoal={behavioralGoal} closeModal={closeModal} />
    ),
    {
      titleKey: 'behavioral.goal.modal.title',
      modalProps: { size: 'xl' },
    }
  )
