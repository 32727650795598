/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { findIndex, get, isEmpty } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { RiAddFill, RiDeleteBinLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'

import { CenteredSpinner } from '../../../components/centered-spinner'
import { InterceptAndConfirm } from '../../../components/intercept-and-confirm'
import { usePlugin } from '../../../hooks/use-plugin'
import { NotificationIntervals } from '../../../store/entities/plugins/constants'
import { fetchRoles } from '../../../store/entities/roles/effects'
import { selectRoles } from '../../../store/entities/roles/selectors'
import { IntervalSelector } from '../interval-selector'
import { RoleSelector } from '../role-selector'

const DEFAULT_NOTIFICATION = {
  roleIndex: 0,
  interval: NotificationIntervals.IMMEDIATELY,
}

const EMPTY_ARRAY = []
const EditTypes = {
  ADD: 'add',
  DELETE: 'del',
  MODIFY: 'mod',
}

const getErrorId = (fetchError, saveError) => {
  const notificationErrorType = get(saveError, ['errors', 'notifications'], null)

  switch (true) {
    case notificationErrorType === 'unique':
      return 'plugin.modal.error.uniqueRole'
    case !!fetchError:
      return 'plugin.modal.error.fetch'
    case !!saveError:
    default:
      return 'plugin.modal.error.save'
  }
}

export const NotificationsModal = ({
  pluginId,
  onClose,
  isOpen,
}: {
  pluginId: string
  onClose: () => void
  isOpen: boolean
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRoles())
  }, [dispatch])

  const roles = useSelector(selectRoles)
  const hasRoles = !isEmpty(roles)
  const [edits, setEdits] = useState<Array<any>>([])
  const { isFetching, isSaving, plugin, updateNotifications, fetchError, saveError } =
    usePlugin(pluginId)

  const notifications = get(plugin, 'notifications', EMPTY_ARRAY)
  const isPristine = isEmpty(edits)
  const error = fetchError || saveError
  const areControlsDisabled = isFetching || isSaving

  // Every time we get new data from backend we need to reset edits
  useEffect(() => {
    setEdits([])
  }, [notifications])

  const notificationsAfterEdits = useMemo(
    () =>
      edits.reduce((acc, { type, roleIndex, interval, index }) => {
        switch (type) {
          case EditTypes.ADD:
            return [...acc, { role: get(roles, [roleIndex, 'id']), interval }]
          case EditTypes.DELETE:
            return [...acc.slice(0, index), ...acc.slice(index + 1)]
          case EditTypes.MODIFY: {
            const copy = [...acc]

            copy[index] = { role: get(roles, [roleIndex, 'id']), interval }

            return copy
          }

          default:
            return acc
        }
      }, notifications),
    [notifications, edits, roles]
  )

  const hasNoNotifications = isEmpty(notificationsAfterEdits)

  const onSave = useCallback(() => {
    updateNotifications(notificationsAfterEdits)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsAfterEdits])

  const addNotification = () => {
    setEdits([...edits, { ...DEFAULT_NOTIFICATION, type: EditTypes.ADD }])
  }

  const updateNotification = (index, edit) => {
    setEdits([
      ...edits,
      {
        type: EditTypes.MODIFY,
        index,
        roleIndex: findIndex<any>(
          roles,
          r => r.id === notificationsAfterEdits[index].role
        ),
        interval: notificationsAfterEdits[index].interval,
        ...edit,
      },
    ])
  }

  const deleteNotification = index => {
    setEdits([...edits, { index, type: EditTypes.DELETE }])
  }

  const handleOnDeleteClick = useCallback(async (e, openDeleteModal) => {
    // Needed because the whole row is clickable,
    // and we should prevent the click to pass through the button
    e.stopPropagation()
    openDeleteModal()
  }, [])

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="plugin.notifications.modal.title" />
        </ModalHeader>
        <ModalBody>
          {error && (
            <Box
              textAlign="center"
              border="2px solid"
              borderColor="red.500"
              p={4}
              borderRadius="md"
              bg="red.50"
            >
              <FormattedMessage id={getErrorId(fetchError, saveError)} />
            </Box>
          )}
          {!hasRoles && (
            <Box
              textAlign="center"
              border="2px solid"
              borderColor="red.500"
              p={4}
              borderRadius="md"
              bg="red.50"
            >
              <FormattedMessage id="plugin.modal.error.noRoles" />
            </Box>
          )}
          <VStack spacing={4} py={2}>
            <Box w="full">
              <Button
                leftIcon={<RiAddFill size="1.25em" />}
                iconSpacing="1"
                isLoading={isFetching}
                onClick={addNotification}
                minW="70px"
                float="right"
              >
                <FormattedMessage id="plugin.notifications.modal.addBtn.label" />
              </Button>
            </Box>
            <VStack spacing={0} w="full" align="stretch">
              {!isFetching && hasNoNotifications && hasRoles && (
                <Text>
                  <FormattedMessage id="plugin.notifications.modal.empty" />
                </Text>
              )}
              {notificationsAfterEdits.map(({ role, interval }, index) => (
                <Box
                  key={`notification-${role}-${interval}`}
                  borderBottom="1px solid"
                  borderColor="gray.200"
                  _last={{ border: 'none' }}
                  py={4}
                >
                  <HStack spacing="24px">
                    <RoleSelector
                      roles={roles}
                      role={role}
                      index={index}
                      disabled={areControlsDisabled}
                      onChange={updateNotification}
                    />

                    <IntervalSelector
                      interval={interval}
                      index={index}
                      disabled={areControlsDisabled}
                      onChange={updateNotification}
                    />

                    <InterceptAndConfirm
                      onConfirm={() => deleteNotification(index)}
                      title={
                        <FormattedMessage id="confirmation.deleteNotification.heading" />
                      }
                      description={
                        <FormattedMessage id="confirmation.deleteNotification.text" />
                      }
                    >
                      {({ openModal }) => (
                        <IconButton
                          color="indicator.red-dark"
                          onClick={e => handleOnDeleteClick(e, openModal)}
                          aria-label="Delete recording"
                          variant="ghost"
                          icon={<RiDeleteBinLine />}
                        />
                      )}
                    </InterceptAndConfirm>
                  </HStack>
                </Box>
              ))}
            </VStack>
            {isFetching && <CenteredSpinner />}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button variant="outline" onClick={onClose} isDisabled={isSaving}>
              <FormattedMessage id="plugin.modal.button.close" />
            </Button>
            <Button isDisabled={isPristine} isLoading={isSaving} onClick={onSave}>
              <FormattedMessage id="common.save" />
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
