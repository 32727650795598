import { IconButton, type IconButtonProps } from '@chakra-ui/react'
import { type ReactNode } from 'react'
import { MdDelete } from 'react-icons/md'

import { InterceptAndConfirm } from '../../components/intercept-and-confirm'

type DeleteDialogProps = {
  title?: string
  message: ReactNode | string
  onConfirmAction: () => Promise<void> | void
}

type DeleteIconButtonProps = DeleteDialogProps & Omit<IconButtonProps, 'aria-label'>

export const DeleteIconButton = ({
  title,
  message,
  onConfirmAction,
  ...props
}: DeleteIconButtonProps) => (
  <InterceptAndConfirm title={title} description={message} onConfirm={onConfirmAction}>
    {({ openModal }) => (
      <IconButton
        variant="ghost"
        colorScheme="red"
        icon={<MdDelete />}
        onClick={e => {
          e.stopPropagation()
          openModal()
        }}
        aria-label={title ?? ''}
        {...props}
      />
    )}
  </InterceptAndConfirm>
)
