import { Box, Button, Flex, Heading, Link, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { Form, Formik, type FormikHelpers } from 'formik'
import { useMemo } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { useErrorHandler } from '../error-handling/use-error-handler'
import { login } from './authentication'
import { LoginForm, type LoginFormValue } from './login-form'

export const Login = () => {
  const navigate = useNavigate()
  const { translate } = useErrorHandler()

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email('login.form.field.email.errors.invalid')
          .required('login.form.field.email.errors.required'),
        password: yup.string().required('login.form.field.password.errors.required'),
      }),
    []
  )

  const handleSubmit = async (
    { email, password }: LoginFormValue,
    { setErrors }: FormikHelpers<LoginFormValue>
  ) => {
    try {
      const { user } = await login(email, password)

      if (user && !user.emailVerified) {
        navigate('/verify-email')
      } else if (user) {
        navigate('/')
      }
      // TODO Create custom error types:
      // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-4.html#defaulting-to-the-unknown-type-in-catch-variables---useunknownincatchvariables
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.code === 'auth/user-not-found') {
        setErrors({ email: translate(error) })
      } else if (error?.code === 'auth/wrong-password') {
        setErrors({ password: translate(error) })
      } else {
        showToast({ message: translate(error), status: 'error' })
      }
    }
  }

  return (
    <>
      <Heading
        as="h1"
        fontSize="x-large"
        color="primary-dark"
        textAlign="center"
        fontWeight="medium"
      >
        <FormattedMessage id="login.welcome" />
      </Heading>
      <Text mt={5} mb={8} fontSize="sm" color="primary-dark" textAlign="center">
        <FormattedMessage id="login.form.button.login" />
      </Text>
      <Formik<LoginFormValue>
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <VStack as={Form} noValidate spacing={5}>
            <LoginForm size="md" />

            <Box w="full">
              <Button
                type="submit"
                size="lg"
                width="full"
                mt={5}
                isLoading={isSubmitting}
              >
                <FormattedMessage id="login.form.button.login" />
              </Button>
            </Box>

            <Flex w="full" justifyContent="space-between" fontSize="sm">
              <Link variant="content-primary-dark" as={RouterLink} to="/request-password">
                <FormattedMessage id="login.forgotPassword" />
              </Link>
              <Link variant="content-primary-dark" as={RouterLink} to="/signup">
                <FormattedMessage id="login.signup.register" />
              </Link>
            </Flex>
          </VStack>
        )}
      </Formik>
    </>
  )
}
