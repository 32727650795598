import { Stack } from '@chakra-ui/react'
import { SectionHeader } from '@repo/ui'

import { InteractionStepContextProvider } from './interaction-step/interaction-step-context'
import { InteractionStepRenderer } from './interaction-step/interaction-step-renderer'
import { InteractionStepStepper } from './interaction-step/interaction-step-stepper'

export const InteractionStep = () => (
  <InteractionStepContextProvider>
    <Stack gap={8} flex={1}>
      <SectionHeader
        titleKey="scenario.builder.ai.steps.interaction.formTitle"
        subtitleKey="scenario.builder.ai.steps.interaction.formSubtitle"
      />

      <InteractionStepStepper />

      <InteractionStepRenderer />
    </Stack>
  </InteractionStepContextProvider>
)
