import { createColumnHelper } from '@tanstack/react-table'

import { AdvancedTable } from '../advanced-table'
import { ComponentSection } from './shared/component-section'
import { ComponentVariant } from './shared/component-variant'

type User = {
  id: string
  name: string
  email: string
  role: string
}

const mockUsers: Array<User> = [
  { id: '1', name: 'John Doe', email: 'john.doe@example.com', role: 'admin' },
  { id: '2', name: 'Jane Smith', email: 'jane.smith@example.com', role: 'user' },
  { id: '3', name: 'Jim Beam', email: 'jim.beam@example.com', role: 'user' },
]

const columnHelper = createColumnHelper<User>()
const newColumns = [
  columnHelper.accessor('id', { header: 'ID' }),
  columnHelper.accessor('name', { header: 'Name' }),
  columnHelper.accessor('email', { header: 'Email' }),
  columnHelper.accessor('role', { header: 'Role' }),
]

export const AdvancedTableSection = () => (
  <ComponentSection
    title="Advanced Table"
    subtitle="This is the table component we use in the admin app"
  >
    <ComponentVariant title="simple usage - new">
      <AdvancedTable columns={newColumns} data={mockUsers} sx={{ width: '100%' }} />
    </ComponentVariant>
  </ComponentSection>
)
