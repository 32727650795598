import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { Formik, type FormikProps } from 'formik'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { WizardProgressBar } from '../../../components/wizard-progress-bar'
import {
  cohortCreationInitialValues,
  getCohortWizardSteps,
  useCohortCreationSteps,
} from './constants'
import { type CohortCreationValues } from './types'

interface CohortCreationModalProps {
  isOpen: boolean
  onClose: () => void
  isBenchmarking: boolean
}

export const CohortCreationModal = ({
  isOpen,
  onClose,
  isBenchmarking,
}: CohortCreationModalProps) => {
  const [currentStep, setCurrentStep] = useState(0)
  const formRef = useRef<FormikProps<CohortCreationValues>>(null)

  const wizardSteps = getCohortWizardSteps()

  const cohortSteps = useCohortCreationSteps(isBenchmarking, () =>
    setCurrentStep(currentStep - 1)
  )

  const activeStepData = cohortSteps[currentStep] ?? cohortSteps[0]!

  const navigate = useNavigate()

  const onSubmit = (values: CohortCreationValues) => {
    if (currentStep === cohortSteps.length - 1) {
      onClose()
      showToast({ messageKey: 'common.alert.created', status: 'success' })
      if (isBenchmarking) {
        navigate(`/coaching/benchmarking/cohorts/${values.id}`)
      } else {
        navigate(`/participants/cohorts/${values.id}`)
      }

      return
    }

    setCurrentStep(currentStep + 1)
  }

  return (
    <Modal
      size="3xl"
      onClose={() => {
        onClose()
        setCurrentStep(0)
      }}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent minH={500}>
        <ModalHeader>
          <Stack>
            <Flex mb={5}>
              <Text fontSize="2xl">
                <FormattedMessage id="cohort.creation.modal.title" />
              </Text>
              <ModalCloseButton />
            </Flex>
            <Box ml={5}>
              <WizardProgressBar
                steps={wizardSteps}
                currentStep={wizardSteps[currentStep] || 'cohortName'}
              />
            </Box>
          </Stack>
        </ModalHeader>
        <ModalBody>
          <Formik
            innerRef={formRef}
            validationSchema={activeStepData.schema}
            onSubmit={onSubmit}
            initialValues={cohortCreationInitialValues}
            validateOnBlur={false}
          >
            {activeStepData.component}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
