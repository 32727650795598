import {
  Badge,
  Button,
  type CardProps,
  Center,
  Flex,
  Icon,
  IconButton,
  Image,
  type InteractivityProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { type UniqueIdentifier } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { FormattedMessage, type ReactNodeOrI18nKey } from '@repo/i18n'
import { PillTag, type PillTagProps } from '@repo/ui'
import { colors } from '@repo/ui'
import { type ReactNode, useMemo, useState } from 'react'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { MdImage } from 'react-icons/md'

import { useGoogleStorageAsset } from '../../hooks/use-google-storage-asset'
import { DragHandle } from '../drag-handle'
import { BaseCard } from './shared/base-card'

type Footer =
  | {
      type: 'selectable'
      isSelected: boolean
      onSelect: () => void
    }
  | {
      type: 'sortable'
      sortableId: UniqueIdentifier
    }
  | {
      type: 'cta'
      labelKey: I18nKey
      onClick: () => unknown
    }
  | {
      type: 'custom'
      content: ReactNode
    }

type MenuItemProps = {
  labelKey: I18nKey
  onClick: () => Promise<unknown> | void
  isDestructive?: boolean
  isDisabled?: boolean
  tooltipKey?: I18nKey
}

type BadgeInfo = {
  labelKey: I18nKey
  bg: string
}

type GridCardProps = {
  onClick?: () => void
  menuItems?: Array<MenuItemProps>
  sx?: CardProps['sx']
  children?: ReactNode
  footer?: Footer
  tag?: PillTagProps
  badge?: BadgeInfo
  image?: string
  imageComponent?: ReactNode
} & ReactNodeOrI18nKey<'title', true>

export const GridCard = ({
  children,
  footer,
  image,
  title,
  titleKey,
  tag,
  menuItems,
  onClick,
  sx,
  imageComponent,
  badge,
}: GridCardProps) => {
  const imageHttpUrl = useGoogleStorageAsset(image)

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: footer?.type === 'sortable' ? footer.sortableId : 0,
    disabled: footer?.type !== 'sortable',
  })

  const [isAwaitingCta, setIsAwaitingCta] = useState(false)

  const renderFooter = () => {
    if (!footer) {
      return null
    }

    if (footer.type === 'custom') {
      return footer.content
    }

    if (footer.type === 'cta') {
      const handleCtaClick = async () => {
        try {
          setIsAwaitingCta(true)
          await footer.onClick()
        } finally {
          setIsAwaitingCta(false)
        }
      }

      return (
        <Button
          mx="auto"
          mb={4}
          px={8}
          onClick={handleCtaClick}
          isLoading={isAwaitingCta}
        >
          <FormattedMessage id={footer.labelKey} />
        </Button>
      )
    }

    if (footer.type === 'selectable') {
      const { isSelected } = footer

      return (
        <Center
          h="50px"
          w="full"
          borderTop="1px solid"
          borderColor={isSelected ? colors.blue[100] : colors.gray[300]}
          color={isSelected ? colors.blue[500] : colors.gray[400]}
          bg={isSelected ? colors['secondary-light'] : undefined}
          gap={2}
          fontSize={14}
        >
          <Radio isChecked={isSelected} />
          <FormattedMessage id={isSelected ? 'common.selected' : 'common.select'} />
        </Center>
      )
    }

    if (footer.type === 'sortable') {
      return (
        <DragHandle
          w="full"
          color={colors.blue[500]}
          sx={{
            '> *': {
              w: 6,
              h: 6,
              transform: 'rotate(90deg)',
            },
          }}
        />
      )
    }
  }

  const handleClick = () => {
    if (footer?.type === 'sortable') {
      return
    }

    onClick?.()

    if (footer?.type === 'selectable') {
      footer.onSelect()
    }
  }

  const cursor = useMemo<InteractivityProps['cursor']>(() => {
    if (footer?.type === 'sortable') {
      return 'grab'
    }

    if (onClick || footer?.type === 'selectable') {
      return 'pointer'
    }

    return 'default'
  }, [footer, onClick])

  return (
    <BaseCard
      userSelect="none"
      onClick={handleClick}
      cursor={cursor}
      transform={CSS.Translate.toString(transform)}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      {...(footer?.type === 'sortable' ? { transition } : {})}
      {...(footer?.type === 'selectable' && footer.isSelected
        ? {
            outline: `2px solid ${colors.blue[500]} !important`,
            borderColor: colors.blue[500],
          }
        : {})}
      sx={sx}
    >
      {imageComponent || (
        <Image
          src={imageHttpUrl}
          w="full"
          bg={colors.blue[50]}
          aspectRatio="16/9"
          objectFit="cover"
          pointerEvents="none"
          fallback={
            <Center width="full" aspectRatio="16/9" bg={colors.blue[50]}>
              <Icon fontSize={32} as={MdImage} color={colors.gray[400]} />
            </Center>
          }
        />
      )}
      {tag && <PillTag {...tag} />}

      <Stack flex={1} p={4} gap={3} mb={8}>
        <Flex gap={2} justifyContent="space-between">
          {badge ? (
            <Badge bg={badge.bg} color="black" fontSize="12px" px={2} py={1}>
              <FormattedMessage id={badge.labelKey} />
            </Badge>
          ) : (
            <Text fontSize={18} fontWeight={500} flex={1} noOfLines={2}>
              {titleKey ? <FormattedMessage id={titleKey} /> : title}
            </Text>
          )}
          {!!menuItems?.length && (
            <Menu strategy="fixed">
              <MenuButton
                onClick={e => e.stopPropagation()}
                as={IconButton}
                isRound
                icon={<BiDotsHorizontalRounded fontSize={16} />}
                size="xs"
              />
              <MenuList>
                {menuItems.map(item => (
                  <Tooltip
                    key={item.labelKey}
                    isDisabled={!item.tooltipKey}
                    label={
                      item.tooltipKey ? <FormattedMessage id={item.tooltipKey} /> : ''
                    }
                  >
                    <MenuItem
                      isDisabled={item.isDisabled}
                      onClick={e => {
                        e.stopPropagation()
                        item.onClick()
                      }}
                      color={
                        item.isDestructive ? colors.indicator['red-dark'] : undefined
                      }
                    >
                      <FormattedMessage id={item.labelKey} />
                    </MenuItem>
                  </Tooltip>
                ))}
              </MenuList>
            </Menu>
          )}
        </Flex>

        <Flex direction="column" flex={1}>
          {children}
        </Flex>
      </Stack>
      <Center mt="auto">{renderFooter()}</Center>
    </BaseCard>
  )
}
