import { type FC } from 'react'

import { type BuilderStepKey } from '../shared/builder-steps'
import { ConversationPartnerStep } from '../shared/conversation-partner-step'
import { TraineeStep } from '../shared/trainee-step'
import { useRealtimeBuilderContext } from './shared/realtime-builder-context'
import { GoalStep } from './step-router/goal-step'
import { InteractionStep } from './step-router/interaction-step'

const WrappedConversationPartnerStep = () => {
  const { isBuilderReadonly } = useRealtimeBuilderContext()

  return <ConversationPartnerStep isBuilderReadonly={isBuilderReadonly} />
}

const WrappedTraineeStep = () => {
  const { isBuilderReadonly } = useRealtimeBuilderContext()

  return <TraineeStep isBuilderReadonly={isBuilderReadonly} />
}

export const realtimeBuilderStepComponents: Record<BuilderStepKey, FC> = {
  goal: GoalStep,
  'conversation-partner': WrappedConversationPartnerStep,
  trainee: WrappedTraineeStep,
  interaction: InteractionStep,
}
