import { Flex } from '@chakra-ui/react'
import { type ProgramLanguages } from '@repo/api'
import { PageWrapper } from '@repo/ui'
import { useMemo, useState } from 'react'

import { AppHeaderTitle } from '../../components/app-header-title'
import { TemplateGrid } from '../../components/template-grid'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'
import { NoFilterResult } from './create-program-from-template/no-filter-result'
import { NoSearchResult } from './create-program-from-template/no-search-result'
import { TemplateFilters } from './create-program-from-template/template-filters'
import { ProgramTemplateCard } from './create-program-from-template/templates-grid/program-template-card'
import { TemplatesTopBar } from './create-program-from-template/templates-top-bar'
import {
  type Department,
  type Filters,
  type Industry,
  initialFilters,
  type UseCase,
} from './shared/filters'
import { type TemplateSortOptions } from './shared/types'

export const CreateProgramFromTemplate = () => {
  const { data, isLoading } = useOpenapiSWR('getAllProgramTemplates')
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState<Filters>(initialFilters)
  const [sortBy, setSortBy] = useState<TemplateSortOptions>('none')

  const hasFilters = Object.values(filters).some(filter => filter.length > 0)

  const [filtersVisible, setFiltersVisible] = useState(true)

  // sort
  const sortedData = useMemo(() => {
    if (!data) {
      return []
    }

    if (sortBy === 'usageCount') {
      return data.toSorted((a, b) => b.submittedSessionsCount - a.submittedSessionsCount)
    }

    if (sortBy === 'createdAt') {
      return data.toSorted(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    }

    return data
  }, [data, sortBy])

  // filter
  const sortedFilteredData = useMemo(() => {
    const industryFilters = filters.industry.map(filter =>
      filter.replace('industry.', '')
    ) as Array<Industry>

    const languageFilters = filters.language.map(filter =>
      filter.replace('language.', '')
    ) as Array<ProgramLanguages>

    const departmentFilters = filters.department.map(filter =>
      filter.replace('department.', '')
    ) as Array<Department>

    const useCaseFilters = filters.useCases.map(filter =>
      filter.replace('useCases.', '')
    ) as Array<UseCase>

    const filteredData = sortedData.filter(template => {
      // empty arrays for departments-industry-useCases has a special meaning here
      // it means that the template is suitable for all values of that filter
      const industryMatch =
        !industryFilters.length ||
        industryFilters.some(
          filter =>
            template.industries.includes(filter) || template.industries.length === 0
        )

      const languageMatch =
        !languageFilters.length || languageFilters.includes(template.program.language)

      const departmentMatch =
        !departmentFilters.length ||
        departmentFilters.some(
          filter =>
            template.departments.includes(filter) || template.departments.length === 0
        )

      const useCasesMatch =
        !useCaseFilters.length ||
        useCaseFilters.some(
          filter => template.useCases.includes(filter) || template.useCases.length === 0
        )

      return industryMatch && languageMatch && departmentMatch && useCasesMatch
    })

    return filteredData
  }, [sortedData, filters])

  // search
  const sortedFilteredSearchedData = useMemo(
    () =>
      sortedFilteredData.filter(({ program: { title, description } }) => {
        const lowerCaseSearch = search.toLowerCase()

        return (
          title.toLowerCase().includes(lowerCaseSearch) ||
          description?.toLowerCase().includes(lowerCaseSearch)
        )
      }),
    [sortedFilteredData, search]
  )

  const showNoFiltersResults = hasFilters && !sortedFilteredData.length
  const showNoSearchResults = !!search.length && !sortedFilteredSearchedData.length

  const getMainContent = () => {
    if (showNoSearchResults) {
      return <NoSearchResult />
    }

    if (showNoFiltersResults) {
      return (
        <NoFilterResult
          currentFilters={
            Object.entries(filters)
              .map(([type, values]) => values.map(value => ({ type, value })))
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .reduce((acc, val) => acc.concat(val), []) as any
          }
          onRemoveFilter={filterToRemove => {
            setFilters({
              ...filters,
              [filterToRemove.type]: filters[filterToRemove.type].filter(
                filter => filter !== filterToRemove.value
              ),
            })
          }}
        />
      )
    }

    return (
      <TemplateGrid
        isLoading={isLoading}
        templates={sortedFilteredSearchedData}
        CardComponent={ProgramTemplateCard}
        title="coaching.program.templates.page.grid.title"
      />
    )
  }

  const availableFilterOptions = useMemo(() => {
    const industries = data?.flatMap(template =>
      template.industries.map(industry => `industry.${industry}`)
    )

    const languages = data?.map(template => `language.${template.program.language}`)

    const departments = data?.flatMap(template =>
      template.departments.map(department => `department.${department}`)
    )

    const useCases = data?.flatMap(template =>
      template.useCases.map(useCase => `useCases.${useCase}`)
    )

    return {
      industry: Array.from(new Set(industries)).sort(),
      language: Array.from(new Set(languages)).sort(),
      department: Array.from(new Set(departments)).sort(),
      useCases: Array.from(new Set(useCases)).sort(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any as Filters
  }, [data])

  return (
    <PageWrapper
      titleKey="coaching.program.templates.page.title"
      subtitleKey="coaching.program.templates.page.subtitle"
      backTo="/training_programs"
      backLabelKey="program.goBack"
      sx={{
        pb: 0,
        ps: 0,
        '> div:first-of-type': {
          ps: 6,
        },
      }}
    >
      <AppHeaderTitle formattedMessageId="coaching.programList.title" />

      <TemplatesTopBar
        filtersVisible={filtersVisible}
        toggleFiltersVisible={() => setFiltersVisible(!filtersVisible)}
        sorting={{ sortBy, setSortBy }}
        search={search}
        setSearch={setSearch}
      />

      <Flex flex={1} overflow="hidden">
        {filtersVisible && (
          <TemplateFilters
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            hasFilters={hasFilters}
            availableFilterOptions={availableFilterOptions}
          />
        )}
        {getMainContent()}
      </Flex>
    </PageWrapper>
  )
}
