import { TableCell, TableRow } from '@mui/material'
import { flexRender, type Row, type RowData } from '@tanstack/react-table'
import { type VirtualItem } from '@tanstack/react-virtual'
import { memo } from 'react'

import { CHECKBOX_COLUMN_ID } from './constants'

type CustomTableRowProps<TData extends RowData> = {
  row: Row<TData>
  onRowClick?: (row: TData) => void
  isSelectable?: boolean
  isSelected?: boolean
  // virtualized row properties below
  virtualRow?: VirtualItem
  index?: number
}

export const CustomTableRow = <TData extends RowData>({
  row,
  onRowClick,
  isSelectable,
  isSelected,
  virtualRow,
  index,
}: CustomTableRowProps<TData>) => {
  const handleRowClick = (event: React.MouseEvent) => {
    // If the click target is a button, link, or any interactive element, don't trigger row click
    const isInteractiveElement = (event.target as Element).closest(
      'button, a, [role="button"]'
    )

    if (!isInteractiveElement) {
      onRowClick?.(row.original)

      if (isSelectable) {
        row.toggleSelected()
      }
    }
  }

  const showHoverEffect = !!onRowClick || isSelectable

  return (
    <TableRow
      selected={isSelected}
      onClick={handleRowClick}
      hover={showHoverEffect}
      sx={[
        { '&:last-child td, &:last-child th': { border: 0 } },
        showHoverEffect ? { cursor: 'pointer' } : {},
      ]}
      style={
        virtualRow && typeof index === 'number'
          ? {
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
            }
          : {}
      }
    >
      {row.getVisibleCells().map(cell => {
        const align = cell.column.columnDef.meta?.align

        return (
          <TableCell
            key={cell.id}
            align={align}
            padding={cell.column.id === CHECKBOX_COLUMN_ID ? 'checkbox' : 'normal'}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export const MemoizedCustomTableRow = memo(CustomTableRow) as typeof CustomTableRow
