import { Box, useToken } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'

type AnswerOptionContainerProps = {
  showFeedback: boolean
  isCorrectAnswer: boolean | undefined
}

export const AnswerOptionContainer = ({
  showFeedback,
  isCorrectAnswer,
  children,
}: PropsWithChildren<AnswerOptionContainerProps>) => {
  const [
    errorFeedbackColor,
    errorFeedbackBorderColor,
    correctFeedbackColor,
    correctFeedbackBorderColor,
  ] = useToken('colors', [
    'indicator.red-extra-light',
    'indicator.red-dark',
    'indicator.green-extra-light',
    'green.500',
  ])

  const getBorderAndBackgroundColors = (): [string, string] => {
    if (showFeedback && isCorrectAnswer) {
      return [correctFeedbackBorderColor, correctFeedbackColor]
    }

    if (showFeedback && isCorrectAnswer === false) {
      return [errorFeedbackBorderColor, errorFeedbackColor]
    }

    return ['white', 'white']
  }

  const [borderColor] = getBorderAndBackgroundColors()

  return (
    <Box
      bgColor="white"
      display="flex"
      justifyContent="stretch"
      borderRadius="md"
      border="1px"
      borderColor={borderColor}
      p={2}
      w="full"
      my={2}
    >
      {children}
    </Box>
  )
}
