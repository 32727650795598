const types = {
  RECEIVE_ORGANIZATION: 'organization/receive',
  RECEIVE_INVITATION: 'organization/receive_invitation',
  DELETE_MEMBER: 'organization/delete_member',
  UPDATE_MEMBER: 'organization/update_member',
  DELETE_INVITATION: 'organization/delete_invitation',
  UPDATE_GDPR: 'organization/update_gdpr',
  UPDATE_PRIVACY: 'organization/update_privacy',
}

// eslint-disable-next-line import/no-default-export
export default types

export const receiveOrganization = organization => ({
  type: types.RECEIVE_ORGANIZATION,
  data: organization,
})

export const receiveInvitation = invitation => ({
  type: types.RECEIVE_INVITATION,
  data: invitation,
})

export const deleteMember = id => ({
  type: types.DELETE_MEMBER,
  data: id,
})

export const deleteInvitation = id => ({
  type: types.DELETE_INVITATION,
  data: id,
})

export const updateMember = (id, payload) => ({
  type: types.UPDATE_MEMBER,
  data: { id, payload },
})
