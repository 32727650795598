import { Stack } from '@chakra-ui/react'
import { SectionHeader } from '@repo/ui'

import {
  InteractionStepContextProvider,
  useInteractionStepContext,
} from './interaction-step/interaction-step-context'
import { InteractionStepRenderer } from './interaction-step/interaction-step-renderer'
import { InteractionStepStepper } from './interaction-step/interaction-step-stepper'
import { interactionStepConfig } from './interaction-step/ordered-interaction-steps'

const InteractionStepInner = () => {
  const { currentStep } = useInteractionStepContext()

  return (
    <Stack gap={8} flex={1}>
      <SectionHeader
        titleKey={interactionStepConfig[currentStep].titleKey}
        subtitleKey={interactionStepConfig[currentStep].subtitleKey}
      />

      <InteractionStepStepper />

      <InteractionStepRenderer />
    </Stack>
  )
}

export const InteractionStep = () => (
  <InteractionStepContextProvider>
    <InteractionStepInner />
  </InteractionStepContextProvider>
)
