import { FormattedMessage } from '@repo/i18n'

import { CustomFormLabel } from '../../../components/custom-form-label'
import { type FieldBaseProps } from './types'

type LabelWrapperProps = Pick<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FieldBaseProps<any, any, any>,
  'name' | 'label' | 'labelKey'
>

export const LabelWrapper = ({ label, labelKey, name }: LabelWrapperProps) =>
  !label && !labelKey ? null : (
    <CustomFormLabel htmlFor={name}>
      {labelKey ? <FormattedMessage id={labelKey} /> : label}
    </CustomFormLabel>
  )
