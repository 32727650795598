import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { showToast, useConfirmationModal } from '@repo/ui'
import { RiMoreFill } from 'react-icons/ri'

import { type Exercise } from '../../types/api-types'
import { client } from '../../utils/openapi-client'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'
import { useEditExerciseModal } from './exercise-list/use-edit-exercise-modal'

type OptionsMenuProps = {
  exercise: Exercise
}

export const OptionsMenu = ({ exercise }: OptionsMenuProps) => {
  const { mutate } = useOpenapiSWR('getExercises')
  const [editExerciseModal, showEditExerciseModal] = useEditExerciseModal(exercise)

  const [deleteModal, showDeleteModal] = useConfirmationModal({
    onConfirm: async () => {
      try {
        const { error } = await client.delete('deleteExercise', {
          params: { path: { extId: exercise.extId } },
        })

        if (error) {
          throw new Error()
        }

        mutate(prev => prev?.filter(i => i.extId !== exercise.extId), false)
        showToast({
          messageKey: 'common.alert.deleted',
          status: 'success',
        })
      } catch (_error) {
        showToast({ messageKey: 'general.error.deleting.data', status: 'error' })
      }
    },
    isDestructive: true,
    titleKey: 'common.delete',
    confirmButtonKey: 'common.delete',
    descriptionKey: 'exercise.button.delete.confirm',
  })

  return (
    <Menu>
      {editExerciseModal}
      {deleteModal}
      <MenuButton
        as={IconButton}
        variant="ghost"
        icon={<RiMoreFill />}
        aria-label="options"
      />

      <MenuList onClick={e => e.stopPropagation()}>
        <MenuItem onClick={showEditExerciseModal}>
          <FormattedMessage id="common.edit" />
        </MenuItem>
        <MenuItem onClick={showDeleteModal} color="indicator.red-dark">
          <FormattedMessage id="common.delete" />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
