import { useDisclosure } from '@chakra-ui/react'
import { times } from 'lodash-es'
import { useState } from 'react'
import { BiUserCircle } from 'react-icons/bi'

import { ImageSelector } from '../../new-program-builder/shared/image-selector'
import { VoiceSelector } from '../../new-program-builder/shared/voice-selector'
import { SelectWithPopover } from '../select-with-popover'
import { ComponentSection } from './shared/component-section'
import { ComponentVariant } from './shared/component-variant'
import { mockFirebaseImage } from './shared/mock-firebase-image'

const images = times(9).map(i => ({
  key: `option-${i}`,
  label: `Image ${i}`,
  imageSrc: mockFirebaseImage,
}))

const voices = times(9).map(i => ({
  key: `option-${i}`,
  label: `Voice ${i}`,
  audioUrl:
    'https://storage.googleapis.com/retorio-assets-dev/dId/audio/de-DE-Stefan.wav',
}))

export const SelectWithPopoverSection = () => {
  const [selectedImage, setSelectedImage] = useState<(typeof images)[number]>()
  const [selectedVoice, setSelectedVoice] = useState<(typeof voices)[number]>()
  const disclosure = useDisclosure()

  return (
    <ComponentSection title="SelectWithPopover">
      <ComponentVariant title="Plain Usage">
        <SelectWithPopover
          disclosure={disclosure}
          titleKey="common.select"
          placeholderKey="common.select"
        >
          Put selectable items here
        </SelectWithPopover>
      </ComponentVariant>

      <ComponentVariant title="Used at ImageSelector component">
        <ImageSelector
          titleKey="common.avatar"
          options={images}
          value={selectedImage}
          onChange={setSelectedImage}
          placeholderKey="common.name"
          searchPlaceholderKey="common.search"
          imageAspectRatio="1/1"
          columnCount={3}
          icon={BiUserCircle}
        />

        <ImageSelector
          titleKey="common.avatar"
          options={images}
          value={selectedImage}
          onChange={setSelectedImage}
          placeholderKey="common.name"
          searchPlaceholderKey="common.search"
        />
      </ComponentVariant>

      <ComponentVariant title="Used at VoiceSelector component">
        <VoiceSelector
          voices={voices}
          value={selectedVoice}
          onChange={setSelectedVoice}
        />
      </ComponentVariant>
    </ComponentSection>
  )
}
