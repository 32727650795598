import { type Scenario } from '../../../../types/api-types'
import { type ManualBuilderStepKey } from '../manual-builder-step-key'

export const getInitialStepFromScenario = (scenario: Scenario): ManualBuilderStepKey => {
  const isDynamicsDataMissing = !scenario.degreeOfRelationship

  if (isDynamicsDataMissing) {
    return 'dynamics'
  }

  const isDetailsDataMissing = !scenario.goal

  if (isDetailsDataMissing) {
    return 'details'
  }

  const isCompletedTheFlowOnce = !!scenario.title

  if (!isCompletedTheFlowOnce) {
    return 'customization'
  }

  return 'review'
}
