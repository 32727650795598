/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ApiPaths } from '@repo/api'
import { operationIdToPathMap } from '@repo/api'
import createClient, {
  type InitParam,
  type MaybeOptionalInit,
  type Middleware,
} from 'openapi-fetch'

import config from '../config'
import { getCurrentToken } from './swr-fetcher'

const nonMappedClient = createClient<ApiPaths>({ baseUrl: config.apiBaseUrl })

const middleware: Middleware = {
  onRequest: async ({ request }) => {
    const token = await getCurrentToken()

    if (token) {
      request.headers.set('Authorization', `Bearer ${token}`)
    }

    request.headers.set('Content-Type', 'application/json; charset=utf-8')

    return request
  },
  // TODO add onResponse for logging errors like customSwrFetcher
}

nonMappedClient.use(middleware)

type MapType = typeof operationIdToPathMap

export const client = {
  post: <
    TOperationId extends keyof MapType['post'],
    TPath extends MapType['post'][TOperationId],
    TInit extends MaybeOptionalInit<ApiPaths[TPath], 'post'>,
  >(
    operationId: TOperationId,
    ...init: InitParam<TInit>
  ) => nonMappedClient.POST(operationIdToPathMap.post[operationId], ...(init as any)),

  get: <
    TOperationId extends keyof MapType['get'],
    TPath extends MapType['get'][TOperationId],
    TInit extends MaybeOptionalInit<ApiPaths[TPath], 'get'>,
  >(
    operationId: TOperationId,
    ...init: InitParam<TInit>
  ) => nonMappedClient.GET(operationIdToPathMap.get[operationId], ...(init as any)),

  put: <
    TOperationId extends keyof MapType['put'],
    TPath extends MapType['put'][TOperationId],
    TInit extends MaybeOptionalInit<ApiPaths[TPath], 'put'>,
  >(
    operationId: TOperationId,
    ...init: InitParam<TInit>
  ) => nonMappedClient.PUT(operationIdToPathMap.put[operationId], ...(init as any)),

  delete: <
    TOperationId extends keyof MapType['delete'],
    TPath extends MapType['delete'][TOperationId],
    TInit extends MaybeOptionalInit<ApiPaths[TPath], 'delete'>,
  >(
    operationId: TOperationId,
    ...init: InitParam<TInit>
  ) => nonMappedClient.DELETE(operationIdToPathMap.delete[operationId], ...(init as any)),
}
