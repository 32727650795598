import { Select, type SelectProps } from '@chakra-ui/react'
import { Controller, type FieldPath, type FieldValues } from 'react-hook-form'

import { FormControlWrapper } from './shared/form-control-wrapper'
import { type FieldBaseProps } from './shared/types'

export const FormFieldSelect = <T extends FieldValues, TName extends FieldPath<T>>(
  props: FieldBaseProps<T, SelectProps, TName>
) => {
  const { name, control, rules, shouldUnregister, componentProps } = props

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FormControlWrapper fieldBaseProps={props} error={fieldState.error}>
          <Select
            {...field}
            {...componentProps}
            onChange={e => {
              field.onChange(e)
              componentProps?.onChange?.(e)
            }}
          />
        </FormControlWrapper>
      )}
    />
  )
}
