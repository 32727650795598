import { Button, Center, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'

export const ClickToSelectQuestion = ({ onClick }: { onClick: () => void }) => (
  <Center flex="1" flexDirection="column" maxW="200px" mx="auto" gap={6}>
    <Text align="center" fontSize="sm" color={colors.gray[400]}>
      <FormattedMessage id="dashboard.resultPerQuestion.selectQuestionDescription" />
    </Text>
    <Button onClick={onClick}>
      <FormattedMessage id="dashboard.resultPerQuestion.selectQuestion" />
    </Button>
  </Center>
)
