import { Flex, Heading } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'

type Props = {
  intlKey: I18nKey
}

export const RouteNotAvailableError = ({ intlKey }: Props) => (
  <Flex w="100%" h="100%" justify="center" align="center" p={20}>
    <Heading as="h6" color="gray.600" margin="3px" size="md" maxW="700px">
      <FormattedMessage id={intlKey} />
    </Heading>
  </Flex>
)
