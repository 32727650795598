import { Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { RHF, showToast, useFormRules } from '@repo/ui'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { StickyFooter } from '../../shared/sticky-footer'
import { useScenarioBuilderData } from '../../shared/use-scenario-builder-data'
import { useSelectOptions } from '../../shared/use-select-options'
import { useManualBuilderContext } from '../shared/manual-builder-context'

const relationshipDegrees = [
  'initialContact',
  'transactionalInteraction',
  'wellEstablishedRelationship',
  'longTermCustomer',
  'workColleague',
  'closeColleague',
  'professionalMentor',
  'directManager',
] as const

const interlocutorRoles = [
  'existingCustomer',
  'newCustomer',
  'manager',
  'teamMember',
  'colleague',
] as const

const traineeRoles = ['cs', 'sales', 'manager'] as const

type FormValues = {
  interlocutorRole: string
  degreeOfRelationship: string
  traineeRole: string
}

export const DynamicsStep = () => {
  const rules = useFormRules()

  const { getSelectOptions } = useSelectOptions()

  const { scenario, goToNextStep, isBuilderReadonly } = useManualBuilderContext()

  const [, , , { mutate: mutateScenario }, updateScenarioDetails] =
    useScenarioBuilderData()

  const { control, handleSubmit, formState } = useForm<FormValues>({
    mode: 'onTouched',
    disabled: isBuilderReadonly,
    defaultValues: {
      degreeOfRelationship: scenario?.degreeOfRelationship ?? '',
      interlocutorRole: scenario?.interlocutorRole ?? '',
      traineeRole: scenario?.traineeRole ?? '',
    },
  })

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const res = await updateScenarioDetails(data)

    if (res.error) {
      return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    }

    mutateScenario(res.data, false)

    goToNextStep()
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} h="full">
      <Stack w="430px" gap={10}>
        <Text fontWeight="bold">
          <FormattedMessage id="scenario.manual.builder.dynamics.step.subtitle" />
        </Text>
        <RHF.AutocompleteSelect
          control={control}
          name="degreeOfRelationship"
          labelKey="scenario.manual.builder.dynamics.step.relationship.degree.label"
          rules={rules.stringShortRequired}
          componentProps={{
            placeholderKey: 'common.select',
            options: getSelectOptions(relationshipDegrees, 'relationship.degree', true),
          }}
        />

        <RHF.AutocompleteSelect
          control={control}
          name="interlocutorRole"
          labelKey="scenario.manual.builder.dynamics.step.interlocutor.role.label"
          rules={rules.required}
          componentProps={{
            placeholderKey: 'common.select',
            options: getSelectOptions(interlocutorRoles, 'interlocutor.role', true),
          }}
        />

        <RHF.AutocompleteSelect
          control={control}
          name="traineeRole"
          labelKey="scenario.manual.builder.dynamics.step.trainee.role.label"
          rules={rules.stringShortRequired}
          componentProps={{
            placeholderKey: 'common.select',
            options: getSelectOptions(traineeRoles, 'trainee.role', true),
            allowCustomValues: true,
          }}
        />
      </Stack>
      <StickyFooter
        onNext={isBuilderReadonly ? goToNextStep : undefined}
        isNextDisabled={!isBuilderReadonly && !formState.isValid}
        isNextLoading={formState.isSubmitting}
      />
    </Stack>
  )
}
