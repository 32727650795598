import {
  Box,
  Center,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { colors, Spinner } from '@repo/ui'
import { type Dispatch, type SetStateAction } from 'react'
import { BsCloudUpload } from 'react-icons/bs'

import { type ErrorStates, UploadError } from '../../components/upload-error'
import { MAX_VIDEO_UPLOAD_SIZE } from '../../utils/constants'
import { DeleteIconButton } from './delete-icon-button'

type VideoPickerProps = {
  name: string
  error: ErrorStates | null
  sizeLimit?: number
  loading: boolean
  videoHttpUrl: string | undefined | null
  handleVideoUpload: (event) => Promise<void>
  setVideoUrlFormValue: Dispatch<SetStateAction<string | undefined>>
  clearError: () => void
  isDisabled?: boolean
  buttonText?: string
  videoDurationLimitInSeconds?: number
}

export const VideoPicker = ({
  name,
  error,
  sizeLimit = MAX_VIDEO_UPLOAD_SIZE,
  loading,
  videoHttpUrl,
  handleVideoUpload,
  setVideoUrlFormValue,
  clearError,
  isDisabled,
  videoDurationLimitInSeconds,
  buttonText,
}: VideoPickerProps) => {
  const { formatMessage } = useIntl()

  return (
    <FormControl id={name} flexShrink={0} width="auto" isDisabled={isDisabled}>
      <Box>
        {error && (
          <UploadError
            sizeLimit={sizeLimit}
            clearError={clearError}
            error={error}
            maxVideoDurationInSeconds={videoDurationLimitInSeconds}
          />
        )}
        {loading && (
          <Center minH="350">
            <Spinner />
          </Center>
        )}
        {videoHttpUrl && (
          <DeleteIconButton
            isDisabled={isDisabled}
            message={formatMessage({ id: 'video.delete.message' })}
            onConfirmAction={() => {
              setVideoUrlFormValue(undefined)
            }}
            variant="solid"
            color="white"
            colorScheme="blue"
            mb={2}
            pos="absolute"
            isRound
            right={2}
            top={2}
            zIndex={1}
          />
        )}
      </Box>
      <FormLabel marginInlineEnd={0}>
        {videoHttpUrl && (
          // eslint-disable-next-line
          <video style={{ borderRadius: '1rem', width: '100%' }} height={'100%'} controls>
            <source src={videoHttpUrl} type="video/mp4" />
          </video>
        )}
        {!videoHttpUrl && !loading && (
          <Stack>
            <Center
              w="14rem"
              h="2rem"
              color="white"
              bg="blue.500"
              borderRadius="md"
              border="1px solid"
              borderColor={colors.blue[500]}
              _hover={{ bg: 'blue.300' }}
            >
              <Icon boxSize="1.25em" as={BsCloudUpload} color="white" />
              <Text ml={4}>{buttonText}</Text>
              <Input
                type="file"
                name="video"
                accept="video/mp4"
                onChange={handleVideoUpload}
                style={{ display: 'none' }}
              />
            </Center>
          </Stack>
        )}
      </FormLabel>
    </FormControl>
  )
}
