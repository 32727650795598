import { get, set } from 'lodash-es'
import { createSelector } from 'reselect'

import * as folderSelectors from '../../entities/folders/selectors'

export const selectFolderTree = createSelector(
  folderSelectors.selectFolderTree,
  folderSelectors.selectActiveFolderIndexPath,
  (tree, indexPath) => {
    if (!indexPath) {
      return {
        ...tree,
        open: true,
      }
    }

    const extendedTree = { ...tree, open: true }

    indexPath.forEach((_, index) => {
      const childIndexes = indexPath.slice(0, index + 1)
      const partialPath = `children.${childIndexes.join('.children.')}`.split('.')
      const node = get(extendedTree, partialPath)

      set(extendedTree, partialPath, {
        ...node,
        open: true,
      })
    })

    return extendedTree
  }
)
