import { Box } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { colors } from '@repo/ui'
import { useMemo } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { BarDotIndicators } from './repetition-chart/bar-dot-indicators'

type RepetitionChartProps = {
  data: Array<{
    repetition: number
    score: number
    isPredicted?: boolean
    customLabel?: string
  }>
}

export const RepetitionChart = ({ data }: RepetitionChartProps) => {
  const { formatMessage } = useIntl()

  const calculatedAngle = useMemo(
    () =>
      Math.min(
        ...data.filter(({ isPredicted }) => isPredicted).map(({ score }) => score)
      ) <= 20
        ? 0
        : -90,
    [data]
  )

  return (
    <Box w="full" height={300}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          style={{ fontSize: 12 }}
          height={300}
          data={data}
          margin={{
            top: 25,
            right: 0,
            left: -30,
            bottom: 0,
          }}
        >
          <CartesianGrid stroke={colors.gray[100]} vertical={false} />
          <XAxis
            dataKey="repetition"
            tick={{ fill: 'black' }}
            padding={{ left: 50, right: 50 }}
          >
            <Label
              style={{ fill: 'black' }}
              position="insideBottomRight"
              value={formatMessage({
                id: 'dashboard.cards.scenarioRepetitions.repetition',
              })}
            />
          </XAxis>
          <YAxis
            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            tick={{ fill: 'black' }}
            tickLine={false}
            minTickGap={0}
            axisLine={false}
          >
            <Label
              style={{ fill: 'black' }}
              dy={-30}
              dx={32}
              position="insideTopLeft"
              value={`% ${formatMessage({
                id: 'dashboard.cards.scenarioRepetitions.scoreAchievement',
              })}`}
            />
          </YAxis>
          <Tooltip
            formatter={value => [`${value}%`]}
            labelStyle={{ display: 'none' }}
            contentStyle={{
              padding: '4px 10px',
              border: 'none',
              borderRadius: 4,
              boxShadow: '0px 0px 28px 0px rgba(0, 0, 0, 0.2)',
            }}
            itemStyle={{ lineHeight: 1, fontSize: 14 }}
            cursor={{ fill: 'none', stroke: colors.gray[300] }}
          />

          <Bar dataKey="score">
            <LabelList content={BarDotIndicators} />
            <LabelList
              dataKey="customLabel"
              fill={colors.blue[500]}
              stroke="none"
              fontSize={10}
              angle={calculatedAngle}
            />
            {data.map(({ repetition, isPredicted }) => (
              <Cell
                fill={isPredicted ? 'white' : '#C2E5F7'}
                stroke={isPredicted ? colors.blue[500] : 'none'}
                key={`cell-${repetition}`}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
