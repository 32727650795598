import { createSelector } from 'reselect'

import { FolderStoreStatus } from './folders-types'

const getLocalState = state => state.entities.folders

export const selectFolderTree = createSelector(getLocalState, ({ tree }) => tree)

export const selectFoldersInitialized = createSelector(
  getLocalState,
  ({ status }) => status === FolderStoreStatus.INITIALIZED
)

export const selectRootFolderId = createSelector(selectFolderTree, ({ id }) => id)

export const selectFolderHash = createSelector(getLocalState, ({ idHash }) => idHash)

export const makeSelectFolder = () =>
  createSelector(
    selectFolderHash,
    (_, folderId) => folderId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (folderHash: any, folderId: any) => folderHash[folderId]
  )

export const makeSelectFoldersList = () =>
  createSelector(makeSelectFolder(), folder => {
    if (!folder?.recordingLists) {
      return null
    }

    return folder.recordingLists[0] || null
  })

export const makeSelectFoldersBenchmarkProfileId = () =>
  createSelector(
    makeSelectFolder(),
    folder => folder?.recordingLists[0]?.dashboard?.benchmark?.id
  )

export const selectActiveFolderIndexPath = createSelector(
  getLocalState,
  ({ activeFolderIndexPath }) => activeFolderIndexPath
)

export const selectFolderCount = createSelector(
  selectFolderHash,
  idHash => Object.keys(idHash).length
)
