import { Center } from '@chakra-ui/react'
import { type EmotionJSX } from '@emotion/react/dist/declarations/src/jsx-namespace'
import { FormattedMessage } from '@repo/i18n'
import { type PropsWithChildren } from 'react'

import { CenteredSpinner } from '../../components/centered-spinner'

export type ContentStatus = 'loading' | 'loaded' | 'error'

type ContentLoaderProps = {
  status: ContentStatus
  errorMessage?: EmotionJSX.Element | string
}

export const ContentLoader = ({
  status,
  errorMessage = <FormattedMessage id="general.error.fallback" />,
  children,
}: PropsWithChildren<ContentLoaderProps>) => (
  <>
    {status === 'loading' && <CenteredSpinner />}
    {status === 'error' && (
      <Center w="full" h="full" p={4}>
        {errorMessage}
      </Center>
    )}
    {status === 'loaded' && children}
  </>
)
