import { Box, HStack, keyframes, Stack } from '@chakra-ui/react'

import ellipseLeft from '../../assets/ellipseLeft.svg'
import ellipseRight from '../../assets/ellipseRight.svg'
import left from '../../assets/left.svg'
import right from '../../assets/right.svg'

const particleLeftKeyframes = keyframes`
  0%, 49.9%, 100% {
    visibility: hidden;
    opacity: 0;
  }
  50% {
    visibility: visible;
    opacity: 0.5;
    transform: translateX(-5px);
  }
  75% {
    opacity: 1;
  }
`

const particleRightKeyframes = keyframes`
  0%, 49.9%, 100% {
    visibility: hidden;
    opacity: 0;
  }
  50% {
    visibility: visible;
    opacity: 0.5;
    transform: translateX(5px);
  }
  75% {
    opacity: 1;
  }
`

const clapLeftKeyframes = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
`

const clapRightKeyframes = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
`

export const ClappingAnimation = () => (
  <HStack gap="1px">
    <Box
      border="none"
      animation={`${particleLeftKeyframes} 0.4s ease-out 1ms infinite`}
      visibility="hidden"
      transform="translateX(-10px)"
      width="20px"
    >
      <img src={ellipseLeft} />
    </Box>
    <Stack mb={2} ms={-1} position="relative">
      <Box
        position="absolute"
        animation={`${clapLeftKeyframes} 0.4s ease-out 1ms infinite`}
        display="flex"
        alignItems="center"
        width="40px"
        height="40px"
      >
        <img src={left} />
      </Box>
      <Box
        transform="scaleX(-1)"
        animation={`${clapRightKeyframes} 0.4s ease-out 1ms infinite`}
        display="flex"
        alignItems="center"
        width="40px"
        height="40px"
      >
        <img src={right} />
      </Box>
    </Stack>
    <Box
      animation={`${particleRightKeyframes} 0.5s ease-out 1ms infinite`}
      visibility="hidden"
      transform="translateX(10px)"
      width="20px"
    >
      <img src={ellipseRight} />
    </Box>
  </HStack>
)
