import { Log } from '@repo/utils'
import { parse } from 'qs'
import { mutate } from 'swr'

import config from '../config'
import { firebaseAuth } from '../firebase'

export const getCurrentToken = (): Promise<string | null> => {
  const query = parse(window.location.search)
  const token = query?.['?token']

  if (token) {
    return Promise.resolve(token as string)
  }

  const { currentUser } = firebaseAuth

  if (currentUser) {
    return currentUser.getIdToken()
  }

  return Promise.resolve(null)
}

const defaultHeaders: Record<string, string> = {
  'Content-Type': 'application/json; charset=utf-8',
}

export const customSwrFetcher = async <ResponseType = unknown>(
  resource: string,
  requestInit?: RequestInit
): Promise<ResponseType | undefined> => {
  const token = await getCurrentToken()

  const fetchOptions: RequestInit = {
    ...requestInit,
    headers: {
      ...defaultHeaders,
      ...requestInit?.headers,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
    },
  }

  const fetchUrl = `${config.apiBaseUrl}${resource}`
  const response = await fetch(fetchUrl, fetchOptions)

  if (response.ok) {
    if (response.status === 204) {
      return undefined
    }

    const json = await response.json()

    return json
  }

  const functionName = resource.split('/')[1]

  Log.error(new Error(`API error, ${functionName}, ${response.status}`), {
    extra: {
      // TODO:
      // query: transformQuery(fetchOptions.query),
      method: fetchOptions.method,
      token,
      url: response.url,
      status: response.status,
      type: response.type,
      requestBody: fetchOptions.body || null,
    },
  })

  const json = await response.json()

  // usually error message is in this json
  // our backend returns json for erroneous cases as well
  const error = json != null ? json : response.toString()

  console.error('API returned error response:', {
    fetchUrl,
    fetchOptions,
    error,
  })

  throw error
}

export const clearSWRCache = () => mutate(() => true, undefined, { revalidate: false })
