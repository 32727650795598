import { CheckboxGroup, Menu, useControllableState } from '@chakra-ui/react'
import { type PropsWithChildren, useCallback } from 'react'

type CheckboxSelectProps = {
  defalutValue?: Array<string>
  value?: Array<string>
  onClose?: (v: Array<string>) => void
  onChange?: (v: Array<string>) => void
  isOpen?: boolean
}

export const CheckboxSelect = ({
  children,
  defalutValue: defalutValueProp = [],
  value: valueProp,
  isOpen,
  onClose,
  onChange,
}: PropsWithChildren<CheckboxSelectProps>) => {
  const onChangeInternal = useCallback((v: Array<string>) => onChange?.(v), [onChange])

  const [value, setValue] = useControllableState<Array<string>>({
    defaultValue: defalutValueProp,
    value: valueProp,
    onChange: onChangeInternal,
  })

  const handleOnClose = useCallback(() => onClose?.(value), [onClose, value])

  return (
    <Menu closeOnSelect={false} isOpen={isOpen} onClose={handleOnClose} isLazy>
      <CheckboxGroup value={value} onChange={v => setValue(v as Array<string>)}>
        {children}
      </CheckboxGroup>
    </Menu>
  )
}
