import { type ComponentProps, type PropsWithChildren } from 'react'

import { type FolderTree } from '../../store/ui/sidebar/sidebar-types'
import { FolderNode } from './folder-node'

type FolderNavigationTreeProps = {
  folder: FolderTree
  isNodeSelected: (id: string) => boolean
  onDrop: ComponentProps<typeof FolderNode>['onDrop']
  depth?: number
}

export const FolderNavigationTree = ({
  folder,
  isNodeSelected,
  onDrop,
  children,
  depth = 0,
}: PropsWithChildren<FolderNavigationTreeProps>) => {
  const { children: folderChildren, id } = folder
  const isSelected = isNodeSelected(id)

  // broken items are only possible during short phase
  // of deletion process. To prevent visual glitches
  // we don't render broken items
  if (!id) {
    return null
  }

  return (
    <FolderNode
      key={id}
      folder={folder}
      isSelected={isSelected}
      onDrop={onDrop}
      depth={depth}
    >
      {folderChildren?.map(child => (
        <FolderNavigationTree
          key={child.id}
          folder={child}
          isNodeSelected={isNodeSelected}
          onDrop={onDrop}
          depth={depth + 1}
        >
          {children}
        </FolderNavigationTree>
      ))}
    </FolderNode>
  )
}
