import { Stack } from '@chakra-ui/react'
import { SectionHeader, showToast } from '@repo/ui'
import { assertExists } from '@repo/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardGrid } from '../../components/card-grid'
import { CenteredSpinner } from '../../components/centered-spinner'
import { client } from '../../utils/openapi-client'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'
import { StickyFooter } from './sticky-footer'
import { TraineeCard } from './trainee-step/trainee-card'
import {
  type TraineeFormModalSubmitHandler,
  useTraineeFormModal,
} from './trainee-step/use-trainee-form-modal'
import { useNextStepModal } from './use-next-step-modal'
import { useScenarioBuilderData } from './use-scenario-builder-data'

export const TraineeStep = ({ isBuilderReadonly }: { isBuilderReadonly: boolean }) => {
  const [
    { programExtId, scenarioExtId, trainingExtId },
    { data: program },
    ,
    { data: scenario, mutate: mutateScenario },
  ] = useScenarioBuilderData()

  assertExists(scenario)
  assertExists(program)

  const {
    data: fetchedTrainees,
    isLoading: isLoadingTrainees,
    mutate: mutateTrainees,
  } = useOpenapiSWR('getTrainees', {
    params: { extId: scenarioExtId, programExtId, trainingExtId },
  })

  const [selectedTraineeExtId, setSelectedTraineeExtId] = useState(
    scenario.trainee?.extId ?? ''
  )

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    // if selectedTraineeExtId is not present in fetchedTrainees, reset it
    if (
      selectedTraineeExtId &&
      fetchedTrainees &&
      !fetchedTrainees.find(trainee => trainee.extId === selectedTraineeExtId)
    ) {
      setSelectedTraineeExtId('')
    }
  }, [fetchedTrainees, selectedTraineeExtId])

  const sortedTrainees = useMemo(() => {
    if (!fetchedTrainees) {
      return []
    }

    // sort by type, then by createdAt
    return fetchedTrainees.sort((a, b) => {
      if (a.type !== b.type) {
        return a.type === 'template' ? -1 : 1
      }

      return b.createdAt.localeCompare(a.createdAt)
    })
  }, [fetchedTrainees])

  const handleTraineeCreate = useCallback<TraineeFormModalSubmitHandler>(
    async (data, closeModal) => {
      try {
        const { company, general, professional } = data

        const res = await client.post('createTrainee', {
          body: {
            ...company,
            ...general,
            ...professional,
            hasLeadershipResponsibility:
              professional.hasLeadershipResponsibility === 'yes',
            language: program.language,
          },
        })

        if (res.error) {
          showToast({ messageKey: 'common.error.unexpected', status: 'error' })

          return
        }

        const createdTrainee = res.data

        mutateTrainees(
          previous => [
            { ...createdTrainee, editable: true, scenariosAttached: 0 },
            ...(previous ?? []),
          ],
          false
        )

        setSelectedTraineeExtId(createdTrainee.extId)

        showToast({ messageKey: 'common.alert.created', status: 'success' })
        closeModal()
      } catch (error) {
        showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }
    },
    [mutateTrainees, program.language]
  )

  const [createTraineeModal, showCreateTraineeModal] =
    useTraineeFormModal(handleTraineeCreate)

  const [nextStepModal, confirmProceedingViaModal] = useNextStepModal({
    titleKey: 'common.wellDone',
    descriptionKey: 'scenario.builder.ai.steps.trainee.nextStepModal.description',
    continueLabelKey: 'common.saveAndProceed',
  })

  const navigate = useNavigate()
  const goNextStep = () => navigate('../interaction')

  const onSubmit = async () => {
    if (isBuilderReadonly) {
      return goNextStep()
    }

    try {
      setIsSubmitting(true)

      const isConfirmed = await confirmProceedingViaModal()

      if (!isConfirmed) {
        return
      }

      const isDirty = scenario.trainee?.extId !== selectedTraineeExtId

      if (!isDirty) {
        return goNextStep()
      }

      const res = await client.put('selectTrainee', {
        params: {
          path: {
            extId: scenarioExtId,
            programExtId,
            trainingExtId,
          },
        },
        body: { traineeExtId: selectedTraineeExtId },
      })

      if (res.error) {
        return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }

      await mutateScenario()
      goNextStep()
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  const isInitializingTrainees = !fetchedTrainees && isLoadingTrainees

  if (isInitializingTrainees) {
    return <CenteredSpinner />
  }

  return (
    <>
      {createTraineeModal}
      {nextStepModal}

      <SectionHeader
        titleKey="scenario.builder.ai.steps.trainee.formTitle"
        subtitleKey="scenario.builder.ai.steps.trainee.formSubtitle"
        sx={{ mb: 8 }}
      />

      <Stack>
        <CardGrid w="full">
          {!isBuilderReadonly && (
            <CardGrid.CreateCard
              labelKey="scenario.builder.ai.steps.trainee.createTrainee"
              onClick={showCreateTraineeModal}
            />
          )}
          {sortedTrainees.map(trainee => (
            <TraineeCard
              key={trainee.extId}
              isSelected={selectedTraineeExtId === trainee.extId}
              trainee={trainee}
              onSelect={() => {
                if (isBuilderReadonly) {
                  return
                }

                setSelectedTraineeExtId(trainee.extId)
              }}
              isBuilderReadonly={isBuilderReadonly}
            />
          ))}
        </CardGrid>
      </Stack>

      <StickyFooter
        onBack={() => navigate('../conversation-partner')}
        onNext={onSubmit}
        isNextDisabled={!isBuilderReadonly && !selectedTraineeExtId}
        isNextLoading={isSubmitting}
      />
    </>
  )
}
