import { type ReactNode, useCallback, useMemo, useRef } from 'react'

import { type ModalConfig, useModal } from './use-modal'

type ModalResolveFunction<T> = (value: T | null) => void

export type PromisingModalContentRenderer<T, P> = (props: {
  closeModal: () => void
  resolve: ModalResolveFunction<T>
  customProps?: P
}) => ReactNode

export const usePromisingModal = <T, P = undefined>(
  Renderer: PromisingModalContentRenderer<T, P>,
  config?: ModalConfig,
  customProps?: P
): [JSX.Element, () => Promise<T | null>] => {
  const resolveRef = useRef<ModalResolveFunction<T>>(() => {})

  const modalConfigWithOnClose = useMemo<ModalConfig>(
    () => ({
      ...config,
      onClose: () => {
        config?.onClose?.()
        resolveRef.current(null)
      },
    }),
    [config]
  )

  const [modal, showModal, closeModal] = useModal(
    renderProps => (
      <Renderer
        closeModal={renderProps.closeModal}
        resolve={resolveRef.current}
        customProps={customProps}
      />
    ),
    modalConfigWithOnClose
  )

  const showModalAndPromise = useCallback(
    () =>
      new Promise<T | null>(resolve => {
        showModal()

        resolveRef.current = value => {
          resolve(value)

          // we are resetting the resolve function, so when it gets called again by to onClose handler, nothing happens
          resolveRef.current = () => {}

          closeModal()
        }
      }),
    [closeModal, showModal]
  )

  return [modal, showModalAndPromise] as const
}
