import { Alert, AlertDescription, AlertIcon, Flex, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { PageWrapper, SectionHeader } from '@repo/ui'
import { useMemo } from 'react'
import { Outlet } from 'react-router-dom'

import { AppHeaderTitle } from '../components/app-header-title'
import { useOrganization } from '../hooks/use-organization'
import { AIBuilderContextProvider } from './scenario-ai-builder-page/shared/ai-builder-context'
import { RealtimeBuilderContextProvider } from './scenario-realtime-builder-page/shared/realtime-builder-context'
import { type BuilderType } from './shared/types'

const builderContextProviders = {
  ai: AIBuilderContextProvider,
  realtime: RealtimeBuilderContextProvider,
}

// this is the layout for both the AI and Realtime builder, with slight differences
export const ScenarioSharedBuilderLayout = ({
  builderType,
}: {
  builderType: BuilderType
}) => {
  const { hasAiContentGen, hasRealtimeConversationStep } = useOrganization()

  const disabledAlertKey = useMemo<I18nKey | null>(() => {
    if (builderType === 'ai' && !hasAiContentGen) {
      return 'error.aiGeneration.not.available'
    }

    if (builderType === 'realtime' && !hasRealtimeConversationStep) {
      return 'error.realtimeConversation.not.available'
    }

    return null
  }, [builderType, hasAiContentGen, hasRealtimeConversationStep])

  const ContextProvider = builderContextProviders[builderType]

  return (
    <ContextProvider>
      <PageWrapper
        backLabelKey="scenario.goBack"
        backTo=".."
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          pb: 0,
        }}
      >
        <AppHeaderTitle formattedMessageId="coaching.programList.title" />

        <Flex mx="auto" direction="column" maxW="1152px" w="full" flex={1}>
          <Stack sx={{ mb: 10 }}>
            <SectionHeader
              titleKey="scenario.builder.ai.title"
              subtitleKey="scenario.builder.ai.subtitle"
            />
            {!!disabledAlertKey && (
              <Alert status="warning" mb={2}>
                <AlertIcon />
                <AlertDescription fontSize="sm">
                  <FormattedMessage id={disabledAlertKey} />
                </AlertDescription>
              </Alert>
            )}
          </Stack>

          <Outlet />
        </Flex>
      </PageWrapper>
    </ContextProvider>
  )
}
