import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { useRef } from 'react'
import { BsChevronDown } from 'react-icons/bs'

type Option = {
  value: string
  label: string
}

type CustomSelectWithLabelProps = {
  labelKey: I18nKey
  options: Array<Option>
  value?: string
  label?: string
  onChange: (value: string) => void
}

export const CustomSelectWithLabel = ({
  labelKey,
  onChange,
  label,
  options,
  value,
}: CustomSelectWithLabelProps) => {
  const selectedItemRef = useRef<HTMLButtonElement>(null)

  return (
    <Stack flex={1} minW={200}>
      <Text fontSize={12} fontWeight={500} color={colors.gray[600]} lineHeight={1}>
        <FormattedMessage id={labelKey} />
      </Text>
      <Menu initialFocusRef={selectedItemRef}>
        <MenuButton
          as={Button}
          borderRadius="md"
          borderWidth="1px"
          variant="unstyled"
          display="flex"
          pl={2}
          pr={2}
          bgColor="white"
          color={colors.gray[700]}
          border="1px"
          borderColor={colors['secondary-dark']}
          sx={{
            '> span:first-of-type': {
              whiteSpace: 'nowrap',
              overflow: ' hidden',
              textOverflow: 'ellipsis',
            },
          }}
          rightIcon={<Icon as={BsChevronDown} />}
          textAlign="start"
        >
          {label}
        </MenuButton>
        <MenuList p={2}>
          {options.map(option => (
            <MenuItem
              ref={option.value === value ? selectedItemRef : null}
              key={option.value}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Stack>
  )
}
