import { Button, type ButtonProps } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { MdArrowForward } from 'react-icons/md'
import { Link } from 'react-router-dom'

import { SESSION_STORAGE_SCROLL_KEY } from '../../../../shared/constants'
import { useDashboardContext } from '../../../../shared/dashboard-context'

export const DetailPageLink = ({
  page,
  sx,
  i18nKey = 'dashboard.page.expand',
}: {
  page: string
  sx?: ButtonProps['sx']
  i18nKey?: I18nKey
}) => {
  const { overviewPageWrapperRef } = useDashboardContext()

  return (
    <Button
      as={Link}
      to={page}
      color={colors.blue[500]}
      variant="outline"
      borderRadius="6px"
      rightIcon={<MdArrowForward />}
      alignSelf="flex-end"
      sx={sx}
      onClick={() => {
        // save scroll position before navigating to detail page, so we can restore it when coming back
        sessionStorage.setItem(
          SESSION_STORAGE_SCROLL_KEY,
          overviewPageWrapperRef.current?.scrollTop.toString() ?? '0'
        )
      }}
    >
      <FormattedMessage id={i18nKey} />
    </Button>
  )
}
