import {
  Box,
  type BoxProps,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Stack,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { FaTrash } from 'react-icons/fa'
import { MdOutlineDragIndicator } from 'react-icons/md'
import { RiPencilFill } from 'react-icons/ri'

import { type SectionItem } from '../types'

type StepItemProps = {
  item: SectionItem
  sx?: BoxProps['sx']
  isReadonly?: boolean
  isEditEnabled?: boolean
  onEdit?: () => void
  onDelete?: () => void
  showDragHandle?: boolean
}

export const StepItem = ({
  item,
  sx,
  isReadonly = false,
  isEditEnabled = false,
  onEdit,
  onDelete,
  showDragHandle = true,
}: StepItemProps) => {
  const { title, description } = item
  const isStepReadonly = isReadonly

  return (
    <Box sx={sx} width="500px" h="fit-content">
      <HStack backgroundColor="white" pl={2} borderRadius={5}>
        {showDragHandle && (
          <Icon
            as={MdOutlineDragIndicator}
            color={isStepReadonly ? colors.gray[400] : colors.blue[500]}
            boxSize="20px"
          />
        )}
        <Box w="450px" h="full" my={4}>
          <Flex justifyContent="space-between">
            <Box justifyContent="center" alignItems="center" display="flex">
              <Tag
                size="sm"
                backgroundColor={colors.gray['600']}
                color="white"
                borderRadius="full"
              >
                <TagLabel fontWeight="medium" fontSize="xs">
                  <FormattedMessage id={title} />
                </TagLabel>
              </Tag>
            </Box>
            <HStack>
              {(!isStepReadonly || isEditEnabled) && onEdit && (
                <Button
                  leftIcon={<RiPencilFill />}
                  variant="outline"
                  onClick={() => onEdit()}
                  size="xs"
                >
                  <FormattedMessage id="common.edit" />
                </Button>
              )}

              {!isStepReadonly && onDelete && (
                <IconButton
                  icon={<FaTrash />}
                  aria-label="Delete Step"
                  onClick={onDelete}
                  isRound
                  size="xs"
                />
              )}
            </HStack>
          </Flex>
          {description && (
            <Stack fontSize="xs" textAlign="left" w="90%" mt={2} gap={0.5}>
              {item.type === 'coachingStepRealtime' && (
                <Text fontWeight="semibold">
                  <FormattedMessage id="customization.step.realtime.item.goal.title" />
                </Text>
              )}
              <Text>{description}</Text>
            </Stack>
          )}
        </Box>
      </HStack>
    </Box>
  )
}
