import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import {
  closestCorners,
  defaultDropAnimation,
  DndContext,
  type DragEndEvent,
  type DragOverEvent,
  DragOverlay,
  type DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { useCallback, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useStepsNew } from '../../scenario-manual-builder-page/steps-context'
import { useDraggableStepsContext } from '../draggable-steps-context'
import { useConfirmBreakingChangeModal } from '../use-confirm-breaking-change-modal'
import { useScenarioBuilderData } from '../use-scenario-builder-data'
import { ConversationCustomization } from './draggable-step-sections/conversation-customization'
import { DraggableStepItem } from './draggable-step-sections/draggable-step-item'
import { StepEditor } from './draggable-step-sections/step-editor'
import { type SectionItem, type Sections } from './draggable-step-sections/types'

interface DragDropSectionsProps {
  initialItems: Sections
  ignoreDropsAtLastItem?: boolean
}

export const DraggableStepSections = ({
  initialItems,
  ignoreDropsAtLastItem = false,
}: DragDropSectionsProps) => {
  const {
    setIsStepCreationMode,
    setActiveStepType,
    setActiveStepId,
    onCancel,
    onDelete,
    onEdit,
    isStepCreationMode,
  } = useDraggableStepsContext()

  const [sections, setSections] = useState<Sections>(initialItems)
  const [newStepOrder, setNewStepOrder] = useState<number>(0)
  const [activeItem, setActiveItem] = useState<SectionItem | null>(null)
  const [hasSectionChanged, setHasSectionChanged] = useState<boolean>(false)

  const { isOpen, onClose, onOpen } = useDisclosure()

  const { addRef, move: moveStep, remove } = useStepsNew()

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const [breakingChangeModal, confirmBreakingChangeViaModal] =
    useConfirmBreakingChangeModal()

  const [, , , { data: scenario }] = useScenarioBuilderData()

  useEffect(() => {
    if (initialItems.stepsEditor.length !== sections.stepsEditor.length) {
      setSections(initialItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialItems])

  const createEmptyStep = useCallback(async () => {
    const newStepUuid = await addRef.current()

    setActiveStepId(newStepUuid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addRef])

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveItem(
      sections[active.data.current?.sortable.containerId].find(
        (item: SectionItem) => item.id === active.id
      )
    )
  }

  const findItemSection = (id: UniqueIdentifier | undefined) => {
    if (!id) {
      return
    }

    if (id in sections) {
      return id
    }

    return Object.keys(sections).find(key =>
      sections[key].find((item: SectionItem) => item.id === id)
    )
  }

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    const sourceSection = findItemSection(active.id)
    const targetSection = findItemSection(over?.id)

    if (!sourceSection || !targetSection || sourceSection === targetSection) {
      return
    }

    if (sourceSection === 'stepsEditor') {
      setSections(initialItems)
      setHasSectionChanged(false)

      return
    }

    const sourceItems = sections[sourceSection]
    const targetItems = sections[targetSection]

    const sourceItemIndex = sourceItems.findIndex(
      (item: SectionItem) => item.id === active.id
    )

    const targetItemIndex = targetItems.findIndex(
      (item: SectionItem) => item.id !== over?.id
    )

    const newSourceItems = [...sections[sourceSection]]

    newSourceItems[sourceItemIndex] = {
      ...newSourceItems[sourceItemIndex],
      id: uuidv4(),
    }

    setSections({
      ...sections,
      [sourceSection]: newSourceItems,
      [targetSection]: [
        ...sections[targetSection].slice(0, targetItemIndex),
        {
          ...sections[sourceSection][sourceItemIndex],
          isCreated: true,
        },
        ...sections[targetSection].slice(targetItemIndex, sections[targetSection].length),
      ],
    })

    setHasSectionChanged(true)
  }

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    const sourceSection = findItemSection(active.id)
    const targetSection = findItemSection(over?.id)

    if (
      !sourceSection ||
      !targetSection ||
      sourceSection !== targetSection ||
      targetSection === 'toolbox'
    ) {
      return
    }

    const sourceItemIndex = sections[sourceSection].findIndex(
      (item: SectionItem) => item.id === active.id
    )

    let targetItemIndex = sections[targetSection].findIndex(
      (item: SectionItem) => item.id === over?.id
    )

    if (ignoreDropsAtLastItem && targetItemIndex === sections[targetSection].length - 1) {
      if (!hasSectionChanged) {
        return
      }

      targetItemIndex -= 1
    }

    setNewStepOrder(targetItemIndex)

    if (sourceItemIndex !== targetItemIndex) {
      setSections({
        ...sections,
        [targetSection]: arrayMove(
          sections[targetSection],
          sourceItemIndex,
          targetItemIndex
        ),
      })
    }

    if (activeItem) {
      if (hasSectionChanged) {
        const isCreatingQuizOrQA =
          activeItem.type === 'coachingStepQA' || activeItem.type === 'coachingStepQuiz'

        const isBreakingChange = scenario?.hasRecordingSessions && isCreatingQuizOrQA

        if (isBreakingChange) {
          const confirmed = await confirmBreakingChangeViaModal()

          if (!confirmed) {
            onCancel.current()

            return
          }
        }

        setIsStepCreationMode(true)
        setActiveStepType(activeItem.type)
        await createEmptyStep()
        setHasSectionChanged(false)
        onOpen()
      } else {
        await moveStep(activeItem.id, targetItemIndex, true)
      }
    }

    setActiveItem(null)
  }

  const onStepDelete = async (id: string) => {
    const section = findItemSection(id)

    if (!section) {
      return
    }

    const sectionItems = sections[section]

    await remove(id)

    setSections({
      ...sections,
      [section]: sectionItems.filter((item: SectionItem) => item.id !== id),
    })
  }

  const onStepEdit = async (item: SectionItem) => {
    const isBreakingChange =
      scenario?.hasRecordingSessions && item.type === 'coachingStepQuiz'

    if (isBreakingChange) {
      const confirmed = await confirmBreakingChangeViaModal()

      if (!confirmed) {
        return
      }
    }

    setActiveStepType(item.type)
    setIsStepCreationMode(false)
    setActiveStepId(item.id)
    onOpen()
  }

  onCancel.current = () => {
    // only reset when we cancel without creating a step
    if (initialItems.stepsEditor.length !== sections.stepsEditor.length) {
      setSections(initialItems)
    }

    onClose()
  }

  onDelete.current = onStepDelete
  onEdit.current = onStepEdit

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      autoScroll={{ acceleration: 1 }}
    >
      {breakingChangeModal}
      <ConversationCustomization sections={sections} />
      <Modal
        size="5xl"
        onClose={onCancel.current}
        isOpen={isOpen}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={!isStepCreationMode}
      >
        <ModalOverlay />
        <ModalContent borderRadius={8}>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody px={6} pb={6} overflow="auto" maxH="900px">
            <StepEditor order={newStepOrder} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <DragOverlay
        dropAnimation={{ ...defaultDropAnimation }}
        modifiers={[restrictToFirstScrollableAncestor]}
      >
        {activeItem ? <DraggableStepItem item={activeItem} /> : null}
      </DragOverlay>
    </DndContext>
  )
}
