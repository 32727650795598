import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { type Scenario } from '../../../../types/api-types'
import { useRealtimeBuilderContext } from '../../shared/realtime-builder-context'
import {
  type InteractionStepKey,
  orderedInteractionStepKeys,
} from './ordered-interaction-steps'

type InteractionStepContextType = {
  currentStep: InteractionStepKey
  goToPreviousStep: () => void
  goToNextStep: () => void
}

const InteractionStepContext = createContext<InteractionStepContextType | null>(null)

const getLastInteractionStepKeyFromScenario = (
  scenario: Scenario
): InteractionStepKey => {
  if (!scenario.degreeOfRelationship) {
    return 'conversation-dynamics'
  }

  if (!scenario.missionGoal) {
    return 'session-details'
  }

  if (!scenario.missionStages?.[0]?.stageGoal) {
    return 'stage-goals'
  }

  if (!scenario.personaOpeningStatement) {
    return 'behavioral-attitude'
  }

  const allStagesHaveAtLeastTwoInstructions = scenario.missionStages?.every(
    stage => (stage.instructions?.length ?? 0) > 1
  )

  if (!allStagesHaveAtLeastTwoInstructions) {
    return 'instructions'
  }

  if (!scenario.title) {
    return 'conversation-customization'
  }

  return 'review'
}

export const InteractionStepContextProvider = ({ children }: PropsWithChildren) => {
  const { scenario } = useRealtimeBuilderContext()

  const [currentStep, setCurrentStep] = useState<InteractionStepKey>(() =>
    getLastInteractionStepKeyFromScenario(scenario)
  )

  const goToNextStep = useCallback(() => {
    setCurrentStep(
      orderedInteractionStepKeys[
        orderedInteractionStepKeys.findIndex(step => step === currentStep) + 1
      ] || currentStep
    )
  }, [currentStep])

  const goToPreviousStep = useCallback<
    InteractionStepContextType['goToPreviousStep']
  >(() => {
    const isFirstStep = currentStep === orderedInteractionStepKeys[0]

    if (!isFirstStep) {
      setCurrentStep(
        orderedInteractionStepKeys[
          orderedInteractionStepKeys.findIndex(step => step === currentStep) - 1
        ] || currentStep
      )
    }
  }, [currentStep])

  const value = useMemo<InteractionStepContextType>(
    () => ({
      currentStep,
      goToPreviousStep,
      goToNextStep,
    }),
    [currentStep, goToNextStep, goToPreviousStep]
  )

  return (
    <InteractionStepContext.Provider value={value}>
      {children}
    </InteractionStepContext.Provider>
  )
}

export const useInteractionStepContext = () => {
  const context = useContext(InteractionStepContext)

  if (!context) {
    throw new Error(
      'useInteractionStepContext must be used within a InteractionStepContextProvider'
    )
  }

  return context
}
