import { Button, Center, HStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useFormikContext } from 'formik'

import { type CohortCreationValues } from '../types'

interface StepActionsProps {
  onSubmit: () => void
  onStepBack?: () => void
  isDisabled?: boolean
  isSubmitting?: boolean
  buttonKey?: I18nKey
}

export const StepActions = ({
  onSubmit,
  onStepBack,
  isDisabled = false,
  isSubmitting = false,
  buttonKey,
}: StepActionsProps) => {
  const { handleSubmit, setValues } = useFormikContext<CohortCreationValues>()

  const onSkip = () => {
    // Clear emails when skipping the step, which is necessary to avoid validation errors
    setValues(values => ({ ...values, emails: [] }))

    // `handleSubmit` should run in the next frame, because `formik.setValues` is a `setState` function
    setTimeout(() => handleSubmit(), 0)
  }

  return (
    <Center>
      <HStack position="absolute" bottom={8}>
        <Button variant="outline" width={150} onClick={onStepBack ?? onSkip}>
          <FormattedMessage
            id={onStepBack ? 'common.back' : 'cohort.creation.step.actions.skip.button'}
          />
        </Button>
        <Button onClick={onSubmit} isDisabled={isDisabled} isLoading={isSubmitting}>
          <FormattedMessage id={buttonKey ?? 'common.saveAndContinue'} />
        </Button>
      </HStack>
    </Center>
  )
}
