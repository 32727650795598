import {
  Badge,
  Box,
  Center,
  Icon,
  InputGroup,
  InputRightElement,
  Portal,
  Text,
} from '@chakra-ui/react'
import {
  AutoComplete,
  AutoCompleteCreatable,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  useAutoCompleteContext,
} from '@choc-ui/chakra-autocomplete'
import { useIntl } from '@repo/i18n'
import { useMemo, useState } from 'react'
import {
  Controller,
  type FieldPath,
  type FieldValues,
  useFormState,
} from 'react-hook-form'
import { FiChevronDown, FiChevronRight, FiPlus } from 'react-icons/fi'

import { EmptyStateIndicator } from '../../components/empty-state-indicator'
import { colors } from '../../theme/main'
import { FormControlWrapper } from './shared/form-control-wrapper'
import { type FieldBaseProps } from './shared/types'

export type AutocompleteOptions = Array<{
  value: string
  label: string
  tag?: string
}>

type AutocompleteComponentProps = {
  options: AutocompleteOptions
  allowCustomValues?: boolean
  placeholderKey?: I18nKey
  isDisabled?: boolean
}

const TrimmedAutoCompleteCreatable = ({
  allowCustomValues,
}: {
  allowCustomValues?: boolean
}) => {
  const { query, itemList } = useAutoCompleteContext()
  const { formatMessage } = useIntl()

  const trimmedLowercaseQuery = query.trim().toLowerCase()

  const isDuplicated = itemList.some(
    option => option.label?.toLowerCase() === trimmedLowercaseQuery
  )

  if (!allowCustomValues || isDuplicated) {
    return null
  }

  const isDisabled = !trimmedLowercaseQuery.length

  return (
    <Box
      position="sticky"
      bottom={-4}
      mb={-4}
      py={2}
      mt={2}
      borderTop={`1px solid ${colors.gray[200]}`}
      bg="white"
    >
      <AutoCompleteCreatable
        style={{
          backgroundColor: colors.blue[50],
          color: colors.blue[500],
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          border: `2px solid`,
          borderColor: 'transparent',
        }}
        _focus={
          !isDisabled
            ? {
                borderColor: `${colors.blue[500]} !important`,
              }
            : undefined
        }
        alwaysDisplay
      >
        {({ value }) => (
          <Center
            w="full"
            bg={colors.blue[50]}
            fontSize="14px"
            opacity={isDisabled ? 0.4 : 1}
            gap={2}
          >
            <Icon as={FiPlus} />
            <Text fontWeight={500}>
              {isDisabled
                ? formatMessage({ id: 'common.addItem' })
                : `${formatMessage({ id: 'common.add' })} "${value}"`}
            </Text>
          </Center>
        )}
      </AutoCompleteCreatable>
    </Box>
  )
}

export const FormFieldAutocompleteSelect = <
  T extends FieldValues,
  TName extends FieldPath<T>,
>(
  props: FieldBaseProps<T, AutocompleteComponentProps, TName, true>
) => {
  const {
    name,
    control,
    rules,
    shouldUnregister,
    componentProps: {
      options,
      allowCustomValues,
      placeholderKey = 'common.select',
      isDisabled = false,
    },
  } = props

  const { defaultValues } = useFormState({ control })

  const { formatMessage } = useIntl()

  const [lastCustomValue, setLastCustomValue] = useState<string>(() => {
    const defaultValue = defaultValues?.[name]

    // if the default value is not in the options list, we set it as the last custom value
    if (defaultValue && !options.find(o => o.value === defaultValue)) {
      return defaultValue
    }

    return ''
  })

  const availableOptions = useMemo(() => {
    const isCustomValue =
      lastCustomValue.trim().length && !options.some(o => o.label === lastCustomValue)

    if (isCustomValue) {
      return [{ label: lastCustomValue, value: lastCustomValue }, ...options]
    }

    return options
  }, [lastCustomValue, options])

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FormControlWrapper fieldBaseProps={props} error={fieldState.error}>
          <Box>
            <AutoComplete
              value={field.value}
              onSelectOption={({ item }) => {
                if (item.creatable) {
                  // remember the last custom value when creatable, to make sure component works as expected
                  // otherwise it gets buggy if user selects a custom value, then interacts with the input again
                  setLastCustomValue(item.value)
                }

                field.onChange(item.value)
              }}
              creatable={allowCustomValues}
              openOnFocus
              listAllValuesOnFocus
              suggestWhenEmpty
              selectOnFocus
              emptyState={() => (
                <EmptyStateIndicator messageKey="general.noSearchResult" />
              )}
            >
              {({ isOpen }) => (
                <>
                  <InputGroup>
                    <AutoCompleteInput
                      ref={field.ref}
                      name={field.name}
                      disabled={field.disabled || isDisabled}
                      onBlur={field.onBlur}
                      placeholder={formatMessage({ id: placeholderKey })}
                      autoComplete="off"
                    />
                    <InputRightElement
                      cursor="pointer"
                      onClick={e => {
                        e.stopPropagation()
                        const input =
                          e.currentTarget.parentElement?.querySelector('input')

                        input?.focus()
                      }}
                    >
                      <Icon fontSize={16} as={isOpen ? FiChevronRight : FiChevronDown} />
                    </InputRightElement>
                  </InputGroup>
                  <Portal>
                    <AutoCompleteList rootProps={{ zIndex: 'popover' }}>
                      {availableOptions.map(({ label, value, tag }) => (
                        <AutoCompleteItem
                          key={value}
                          value={value}
                          label={label}
                          border={
                            value === field.value
                              ? `2px solid ${colors.blue[500]}`
                              : undefined
                          }
                        >
                          {label}
                          {!!tag && (
                            <Badge
                              ml="auto"
                              display="flex"
                              alignItems="center"
                              colorScheme="blue"
                              bg={colors.blue[50]}
                            >
                              {tag}
                            </Badge>
                          )}
                        </AutoCompleteItem>
                      ))}

                      <TrimmedAutoCompleteCreatable
                        allowCustomValues={allowCustomValues}
                      />
                    </AutoCompleteList>
                  </Portal>
                </>
              )}
            </AutoComplete>
          </Box>
        </FormControlWrapper>
      )}
    />
  )
}
