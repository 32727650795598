import { Box, Button, Heading, Icon, Stack, Text, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { passwordSchemaValidator } from '@repo/utils'
import { Form, Formik } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'
import { string } from 'yup'

import { EditorMessage } from '../builder/components/alert-message'
import { api } from '../utils/api'
import { BackToLogin } from './back-to-login'
import { ResetPasswordForm, type ResetPasswordFormValue } from './reset-password-form'

type ResetPasswordProps = {
  actionCode: string
}

export const ResetPassword = ({ actionCode }: ResetPasswordProps) => {
  const [state, setState] = useState<'idle' | 'successful' | 'error'>('idle')
  const [userInfo, setUserInfo] = useState<Pick<
    ResetPasswordFormValue,
    'firstName' | 'email'
  > | null>({
    email: '',
    firstName: '',
  })

  const getUserInfo = useCallback(async () => {
    try {
      const userData = await api.users.getUserInfo({ actionCode })

      setUserInfo(userData)
    } catch (e) {
      setUserInfo(null)
    }
  }, [actionCode])

  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])

  const validationSchema: yup.SchemaOf<ResetPasswordFormValue> = useMemo(
    () =>
      yup.object().shape({
        firstName: string().required(),
        email: string().required(),
        ...passwordSchemaValidator,
      }),
    []
  )

  const handleSubmit = async ({ password }: ResetPasswordFormValue) => {
    try {
      await api.users.resetPassword({ actionCode, password, ...userInfo })
      setState('successful')
    } catch (error) {
      setState('error')
    }
  }

  return (
    <>
      <Stack align="stretch" spacing={4} textAlign="center">
        {state === 'successful' && (
          <>
            <Heading as="h1" fontSize="2xl" fontWeight="medium" color="primary-dark">
              <Icon as={MdCheckCircle} color="#78e296" w={8} h={8} mr={2} />
              <FormattedMessage id="resetPassword.success.title" />
            </Heading>
            <Text fontSize="sm" color="primary-dark">
              <FormattedMessage id="resetPassword.success.text" />
            </Text>
            <Button type="button" size="lg" width="full" as={RouterLink} to="/login">
              <FormattedMessage id="onboarding.backToLogin" />
            </Button>
          </>
        )}

        {state === 'error' && (
          <>
            <Text fontSize="sm" color="primary-dark">
              <FormattedMessage id="resetPassword.error.text" />
            </Text>
            <Button type="button" size="lg" as={RouterLink} to="/request-password">
              <FormattedMessage id="resetPassword.error.button" />
            </Button>
          </>
        )}
      </Stack>
      {state === 'idle' && (
        <>
          <BackToLogin />
          <Heading
            mt={5}
            as="h1"
            fontSize="x-large"
            color="primary-dark"
            textAlign="center"
            fontWeight="medium"
          >
            <FormattedMessage id="resetPassword.form.title" />
          </Heading>
          <Text mt={5} mb={8} fontSize="sm" color="primary-dark" textAlign="center">
            <FormattedMessage id="resetPassword.form.text" />
          </Text>
          {userInfo?.email && userInfo.firstName ? (
            <Formik<ResetPasswordFormValue>
              initialValues={{
                ...userInfo,
                password: '',
              }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              validateOnBlur={false}
            >
              {({ isSubmitting }) => (
                <VStack as={Form} noValidate spacing={5}>
                  <ResetPasswordForm size="md" />

                  <Box w="full">
                    <Button
                      type="submit"
                      size="lg"
                      width="full"
                      mt={5}
                      isLoading={isSubmitting}
                    >
                      <FormattedMessage id="resetPassword.form.button.submit.label" />
                    </Button>
                  </Box>
                </VStack>
              )}
            </Formik>
          ) : (
            <EditorMessage
              type="error"
              haveCloseButton={false}
              description="resetPassword.error.userInfo.description"
              title="resetPassword.error.userInfo.title"
            />
          )}
        </>
      )}
    </>
  )
}
