import { fabric } from 'fabric'

const calculateScaling = (
  canvasWidth?: number,
  canvasHeight?: number,
  imageWidth?: number,
  imageHeight?: number
) => {
  if (!canvasWidth || !canvasHeight || !imageWidth || !imageHeight) {
    return 1
  }

  const widthScale = canvasWidth / imageWidth
  const heightScale = canvasHeight / imageHeight

  return Math.min(widthScale, heightScale)
}

// Load the image onto the canvas
export const loadImage = (fabricCanvas: fabric.Canvas, imageUrl: string) => {
  fabric.Image.fromURL(imageUrl, img => {
    const scaling = calculateScaling(
      fabricCanvas.getWidth(),
      fabricCanvas.getHeight(),
      img.width,
      img.height
    )

    img.scale(scaling)

    fabricCanvas.setBackgroundImage(img, fabricCanvas.renderAll.bind(fabricCanvas), {
      scaleX: scaling,
      scaleY: scaling,
    })
  })
}
