import { Box } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { useMemo, useState } from 'react'
import { FiPercent } from 'react-icons/fi'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { CustomChartTooltip } from '../shared/custom-line-chart/custom-chart-tooltip'
import { DashboardCard } from '../shared/dashboard-card'
import { ToggleBetweenTwo } from '../shared/toggle-between-two'
import { ToggleList, type ToggleListItem } from '../shared/toggle-list'
import { useDashboardCardData } from '../shared/use-dashboard-card-data'
import { DetailPageLink } from './shared/detail-page-link'
import { NoDataInformation } from './shared/no-data-information'

type DataKey =
  | 'completedAll'
  | 'registeredNoRecordings'
  | 'finishedAtLeastOne'
  | 'notRegistered'

const dataConfig: Record<DataKey, ToggleListItem<DataKey>> = {
  completedAll: {
    labelKey: 'dashboard.cards.learningDevelopmentFunnel.data.completedAll',
    color: colors.lineChartColors[50],
    dataKey: 'completedAll',
  },
  registeredNoRecordings: {
    labelKey: 'dashboard.cards.learningDevelopmentFunnel.data.registeredNoRecordings',
    color: colors.lineChartColors[100],
    dataKey: 'registeredNoRecordings',
  },
  finishedAtLeastOne: {
    labelKey: 'dashboard.cards.learningDevelopmentFunnel.data.finishedAtLeastOne',
    color: colors.lineChartColors[500],
    dataKey: 'finishedAtLeastOne',
  },
  notRegistered: {
    labelKey: 'dashboard.cards.learningDevelopmentFunnel.data.notRegistered',
    color: colors.lineChartColors[400],
    dataKey: 'notRegistered',
  },
}

export const LearningDevelopmentFunnelCard = () => {
  const [isPercentageActive, setIsPercentageActive] = useState(false)
  const { dataSlotSelector, dataSlot } = useDashboardCardData('learningDevelopmentFunnel')

  const [activeDataKeys, setActiveDataKeys] = useState<Array<DataKey>>([
    'finishedAtLeastOne',
  ])

  const chartData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    const dataType = isPercentageActive
      ? data.usersProgressOverallPercentagePerTimestamp
      : data.usersProgressOverallValuePerTimestamp

    return data.startDatesFormatted.map((date, index) => ({
      date,
      completedAll: dataType.completed[index]!,
      registeredNoRecordings: dataType.withoutProgress[index]!,
      finishedAtLeastOne: dataType.inProgress[index]!,
      notRegistered: dataType.open[index]!,
    }))
  }, [dataSlot, isPercentageActive])

  const isDataEmpty = useMemo(() => {
    if (dataSlot.status === 'loaded') {
      const { data } = dataSlot
      const dataType = isPercentageActive
        ? data.usersProgressOverallPercentagePerTimestamp
        : data.usersProgressOverallValuePerTimestamp

      return dataType.completed.length === 0
    }

    return false
  }, [dataSlot, isPercentageActive])

  return (
    <DashboardCard
      titleKey="dashboard.cards.learningDevelopmentFunnel.title"
      descriptionKey="dashboard.cards.learningDevelopmentFunnel.subtitle"
      colSpan={3}
      titleEndContent={
        <ToggleBetweenTwo
          isLeftActive={!isPercentageActive}
          toggleValue={() => setIsPercentageActive(!isPercentageActive)}
          left="123"
          right={<FiPercent />}
        />
      }
      wrapperProps={{ mt: 10 }}
      dataSlotSelector={dataSlotSelector}
      isLoading={dataSlot.status !== 'loaded'}
    >
      {isDataEmpty ? (
        <NoDataInformation />
      ) : (
        <>
          <Box height={300} mb={10}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={chartData}
                width={500}
                height={300}
                margin={{ top: 30, left: -20, right: 0, bottom: 0 }}
                style={{ fontSize: 12 }}
              >
                <CartesianGrid vertical={false} stroke={colors.gray[100]} />
                <XAxis dataKey="date" tick={{ fill: 'black' }} />
                <YAxis
                  minTickGap={-1}
                  tick={{ fill: 'black' }}
                  axisLine={false}
                  tickLine={false}
                  ticks={
                    isPercentageActive
                      ? [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
                      : undefined
                  }
                />
                <Tooltip
                  cursor={{ fill: 'none', stroke: colors.gray[300] }}
                  content={
                    <CustomChartTooltip
                      valueSuffix={isPercentageActive ? '%' : undefined}
                    />
                  }
                />
                {activeDataKeys.map(dataKey => (
                  <Bar
                    barSize={16}
                    key={dataKey}
                    dataKey={dataKey}
                    stackId="a"
                    fill={dataConfig[dataKey].color}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Box>
          <Box>
            <ToggleList
              items={Object.values(dataConfig)}
              value={activeDataKeys}
              onChange={setActiveDataKeys}
            />
          </Box>

          <DetailPageLink sx={{ mt: 7 }} page="learning_development_funnel" />
        </>
      )}
    </DashboardCard>
  )
}
