import { useSWRLoader } from '../hooks/use-swr-loader'
import { customSwrFetcher } from '../utils/swr-fetcher'
import { type Cohort } from './types/cohort'
import { type CohortCreate, type CohortUpdate } from './types/cohort-create'
import { type CohortsListItem } from './types/cohorts-list-item'

const cohortsUrl = '/cohorts'
const getCohortsCohortUrl = (cohortId: string) => `${cohortsUrl}/${cohortId}`

export const useCohorts = () => {
  const createCohort = async (data: CohortCreate) =>
    customSwrFetcher<Cohort>(cohortsUrl, {
      method: 'POST',
      body: JSON.stringify(data),
    })

  const updateCohort = async (data: CohortUpdate, cohortId: string) =>
    customSwrFetcher<Cohort>(getCohortsCohortUrl(cohortId), {
      method: 'PUT',
      body: JSON.stringify(data),
    })

  const deleteCohort = async (cohortId: string) =>
    customSwrFetcher<Cohort>(getCohortsCohortUrl(cohortId), {
      method: 'DELETE',
    })

  const useCohortsList = ({ isBenchmarking }: { isBenchmarking: boolean }) =>
    useSWRLoader<Array<CohortsListItem>>(
      `${cohortsUrl}?includeBenchmarkingCohorts=${isBenchmarking}`
    )

  const useCohort = (cohortId: string) =>
    useSWRLoader<Cohort>(getCohortsCohortUrl(cohortId))

  return {
    createCohort,
    updateCohort,
    deleteCohort,
    useCohortsList,
    useCohort,
  }
}
