import { RHF, useFormRules } from '@repo/ui'
import { useFormContext } from 'react-hook-form'

import { useSelectOptions } from '../../../use-select-options'
import { type Persona, type PersonaFormValues } from '../../types'
import { ageRangeValues } from '../shared/age-range-values'

const genders: Array<Persona['sex']> = ['female', 'male']

const ageRanges: Array<NonNullable<Persona['age']>> = [
  'youngAdult',
  'adult',
  'olderAdult',
]

export const StepDemographic = () => {
  const { control } = useFormContext<PersonaFormValues['demographic']>()
  const rules = useFormRules()

  const { getSelectOptions } = useSelectOptions()

  return (
    <>
      <RHF.Input
        control={control}
        name="name"
        labelKey="common.name"
        rules={rules.stringShortRequired}
        tooltipKey="persona.name.tooltip.text"
      />

      <RHF.RadioButtonGroup
        control={control}
        name="age"
        labelKey="common.age"
        componentProps={{
          options: ageRanges.map(range => ({
            value: range,
            label: ageRangeValues[range],
          })),
        }}
      />

      <RHF.AutocompleteSelect
        control={control}
        name="sex"
        labelKey="common.sex"
        rules={rules.required}
        componentProps={{
          placeholderKey: 'common.select',
          options: getSelectOptions(genders, 'persona.sex', false),
        }}
      />
    </>
  )
}
