import { Box } from '@chakra-ui/react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { type PropsWithChildren } from 'react'

type SortableDragDropItemProps = {
  id: string
}

export const SortableStepItem = ({
  id,
  children,
}: PropsWithChildren<SortableDragDropItemProps>) => {
  const { attributes, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  })

  return (
    <Box
      ref={setNodeRef}
      transform={CSS.Transform.toString(transform)}
      transition={transition}
      opacity={isDragging ? 0 : 1}
      {...attributes}
    >
      {children}
    </Box>
  )
}
