import { Stack } from '@chakra-ui/react'
import { RHF, SectionHeader, showToast, useFormRules } from '@repo/ui'
import { useCallback } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { StickyFooter } from '../../../../shared/sticky-footer'
import { useSelectOptions } from '../../../../shared/use-select-options'
import { useAIBuilderContext } from '../../../shared/ai-builder-context'
import { useInteractionStepContext } from '../interaction-step-context'

const personaTraineeRelationships = [
  'initialContact',
  'transactionalInteraction',
  'wellEstablishedRelationship',
  'longTermLoyalCustomer',
  'workColleague',
  'closeColleague',
  'professionalMentor',
  'directManager',
] as const

const interlocutorRolesNotInternal = ['existingCustomer', 'newCustomer'] as const
const interlocutorRolesInternal = ['manager', 'teamMember', 'colleague'] as const

type FormValues = {
  degreeOfRelationship: string
  interlocutorRole: string
}

export const StepConversationDynamics = () => {
  const rules = useFormRules()
  const { scenario, goToStep, mutateScenario, updateScenarioDetails, isBuilderReadonly } =
    useAIBuilderContext()

  const { control, formState, handleSubmit } = useForm<FormValues>({
    mode: 'onTouched',
    disabled: isBuilderReadonly,
    defaultValues: {
      degreeOfRelationship: scenario.degreeOfRelationship ?? '',
      interlocutorRole: scenario.interlocutorRole ?? '',
    },
  })

  const { setCurrentStep } = useInteractionStepContext()

  const { getSelectOptions } = useSelectOptions()

  const goNextStep = useCallback(() => setCurrentStep('scenario-goal'), [setCurrentStep])

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async values => {
      if (!formState.isDirty) {
        return goNextStep()
      }

      const res = await updateScenarioDetails({
        degreeOfRelationship: values.degreeOfRelationship,
        interlocutorRole: values.interlocutorRole,
      })

      if (res.error) {
        return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }

      mutateScenario(res.data, false)
      goNextStep()
    },
    [formState.isDirty, goNextStep, updateScenarioDetails, mutateScenario]
  )

  if (!scenario.persona) {
    throw new Error('Persona should be selected at this point, there should be an error')
  }

  return (
    <Stack as="form" flex={1} onSubmit={handleSubmit(onSubmit)}>
      <SectionHeader
        titleKey="scenario.manual.builder.dynamics.step.subtitle"
        sx={{ mb: 8, p: { fontSize: '18px' } }}
      />

      <Stack gap={16} maxW="800px">
        <RHF.AutocompleteSelect
          control={control}
          name="degreeOfRelationship"
          labelKey="scenario.personaTraineeRel.input.label"
          rules={rules.required}
          componentProps={{
            placeholderKey: 'scenario.personaTraineeRel.input.placeholder',
            options: getSelectOptions(
              personaTraineeRelationships,
              'scenario.personaTraineeRel',
              true
            ),
          }}
          tooltipKey="scenario.personaTraineeRel.degreeOfRelationship.tooltip.text"
        />

        <RHF.AutocompleteSelect
          control={control}
          name="interlocutorRole"
          labelKey="scenario.manual.builder.dynamics.step.interlocutor.role.label"
          rules={rules.required}
          componentProps={{
            placeholderKey: 'scenario.interlocutorRole.input.placeholder',
            options:
              scenario.persona.conversationContext === 'internal'
                ? getSelectOptions(
                    interlocutorRolesInternal,
                    'scenario.interlocutorRole',
                    true
                  )
                : getSelectOptions(
                    interlocutorRolesNotInternal,
                    'scenario.interlocutorRole',
                    true
                  ),
          }}
        />
      </Stack>

      <StickyFooter
        onBack={() => goToStep('trainee')}
        onNext={isBuilderReadonly ? goNextStep : undefined}
        isNextDisabled={!isBuilderReadonly && !formState.isValid}
        isNextLoading={formState.isSubmitting}
      />
    </Stack>
  )
}
