import { Box, GridItem, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, PersonaThumbnail } from '@repo/ui'

import { useGoogleStorageAsset } from '../../../../../../../../hooks/use-google-storage-asset'
import { type ProgramTemplate } from '../../../../../../shared/types'

export const ScenarioCard = ({
  scenario: { durationInMinutes, title, description, persona },
}: {
  scenario: ProgramTemplate['program']['trainings'][number]['scenarios'][number]
}) => (
  <GridItem
    borderRadius={6}
    overflow="hidden"
    border="1px solid"
    borderColor={colors.blue[200]}
    flex={1}
    pos="relative"
  >
    <Box w="full" aspectRatio="16/9">
      <PersonaThumbnail
        avatarPreviewUrl={persona?.videoGenerationAvatar.imageUrl}
        bgUrl={persona?.backgroundUrl}
        useGoogleStorageAsset={useGoogleStorageAsset}
      />
    </Box>
    <Box
      bg={colors.gray[100]}
      fontSize={12}
      fontWeight={500}
      pos="absolute"
      top={2}
      right={3}
      px={2}
      py={1}
      borderRadius="full"
    >
      <FormattedMessage
        id="exercise.preview.durationInMinutes"
        values={{ minutes: durationInMinutes }}
      />
    </Box>
    <Stack gap={1} p={3}>
      <Text fontWeight={600}>
        <Box as="span" color={colors.blue[500]}>
          <FormattedMessage id="scenario.title" />
        </Box>
        {` ${title}`}
      </Text>
      <Text fontSize={14} noOfLines={3}>
        {description}
      </Text>
    </Stack>
  </GridItem>
)
