import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, showToast, useFormRules, useModal } from '@repo/ui'
import { type CSSProperties } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { MdInfoOutline } from 'react-icons/md'

import { useProgramsList } from '../../new-program-builder/shared/api'
import { type ExtendedProgram } from '../../types/api-types'
import { emailRegex } from '../../utils/constants'
import { client } from '../../utils/openapi-client'
import { SharedProgramTabs } from './shared-program-tabs'

type FormValues = {
  email: string
}

const ShareProgramModal = ({
  program,
  closeModal,
}: {
  program: ExtendedProgram
  closeModal: () => void
}) => {
  const { formatMessage } = useIntl()
  const { sharedPrograms: sharedProgramsData } = program
  const { mutate } = useProgramsList()
  const rules = useFormRules()

  const { register, handleSubmit, formState, reset } = useForm<FormValues>({
    mode: 'onTouched',
  })

  const onSubmit: SubmitHandler<FormValues> = async formData => {
    try {
      const { data: sharedProgram } = await client.post('shareProgram', {
        body: formData,
        params: { path: { extId: program.extId } },
      })

      if (!sharedProgram) {
        throw new Error()
      }

      mutate(prevData => {
        if (!prevData) {
          return prevData
        }

        const updatedPrograms = prevData.map(coachingProgram => {
          if (coachingProgram.extId === program.extId) {
            return {
              ...coachingProgram,
              sharedPrograms: [...coachingProgram.sharedPrograms, sharedProgram],
            }
          }

          return coachingProgram
        })

        return updatedPrograms
      }, false)

      showToast({ messageKey: 'coaching.program.send.success', status: 'success' })
      reset()

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.details?.code === 'same_organization') {
        showToast({ messageKey: 'general.error.share.program.data', status: 'error' })
      } else {
        showToast({ messageKey: 'general.error.saving.data', status: 'error' })
      }
    }
  }

  return (
    <Box>
      <Text fontSize="14px" color={colors.gray[800]} pb="4">
        <FormattedMessage id="coaching.program.send.modal.input.description" />
      </Text>

      <Stack gap={0} as="form" onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="email" isInvalid={!!formState.errors.email} pb="6">
          <InputGroup
            size="md"
            border="2px"
            borderColor={colors.primary}
            borderRadius="md"
          >
            <Input
              {...register('email', {
                ...rules.stringShortRequired,
                pattern: {
                  value: emailRegex,
                  message: formatMessage({
                    id: 'coaching.program.send.form.validation.email',
                  }),
                },
              })}
              placeholder={formatMessage({
                id: 'coaching.program.send.form.validation.email.placeholder',
              })}
              style={
                {
                  border: 'none',
                  borderRadius: 'md',
                  borderRightRadius: 'none',
                  _focus: {
                    borderRightRadius: 'none',
                  },
                } as CSSProperties
              }
            />

            <InputRightAddon border="none" p={0} bg="none">
              <Button
                size="md"
                type="submit"
                h="inherit"
                isLoading={formState.isSubmitting}
                isDisabled={!formState.isValid}
              >
                <FormattedMessage id="common.send" />
              </Button>
            </InputRightAddon>
          </InputGroup>
          <FormErrorMessage>{formState.errors.email?.message}</FormErrorMessage>
        </FormControl>

        {!!sharedProgramsData?.length && (
          <>
            <Text color={colors.gray[700]} pb="4" fontSize="14px" fontWeight={500}>
              <FormattedMessage id="coaching.program.send.modal.requestsStatus.sectionTitle" />
            </Text>
            <SharedProgramTabs
              sharedProgramsData={sharedProgramsData}
              type="sender"
              program={program}
            />
          </>
        )}

        <Button alignSelf="center" onClick={closeModal} px={6} size="md">
          <FormattedMessage id="coaching.program.send.modal.requestsStatus.listItem.warningModal.button.close" />
        </Button>
      </Stack>
    </Box>
  )
}

export const useShareProgramModal = (program: ExtendedProgram) => {
  const { formatMessage } = useIntl()

  const modal = useModal(
    ({ closeModal }) => <ShareProgramModal closeModal={closeModal} program={program} />,
    {
      title: (
        <Box>
          {`${formatMessage({
            id: 'common.send',
          })} "${program.title}"`}
          <HStack>
            <Text fontWeight={400} fontSize="14px" textColor={colors.gray[800]}>
              <FormattedMessage id="coaching.program.send.modal.subtitle" />
            </Text>
            <Popover trigger="hover">
              <PopoverTrigger>
                <Box role="button" aria-label="Info icon">
                  <Icon as={MdInfoOutline} boxSize="16px" color={colors.blue[400]} />
                </Box>
              </PopoverTrigger>
              <PopoverContent w="300px">
                <PopoverArrow />

                <PopoverBody
                  fontWeight={400}
                  fontSize="14px"
                  textColor={colors.gray[900]}
                >
                  <FormattedMessage id="coaching.program.send.modal.tooltip.description" />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>
        </Box>
      ),
      bodySx: { pt: '0 !important' },
    }
  )

  return modal
}
