import {
  chakra,
  Checkbox,
  type CheckboxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
} from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import {
  Field,
  type FieldInputProps,
  type FormikErrors,
  type FormikTouched,
} from 'formik'
import { memo, type ReactNode } from 'react'
import { BiErrorCircle } from 'react-icons/bi'

type FormControlCheckboxFieldProps<
  TValue extends { [key: string]: string | boolean | number },
  TProp extends keyof TValue,
> = {
  prop: TProp
  touched?: FormikTouched<TValue>
  errors?: FormikErrors<TValue>
  label?: string
  description: string | ReactNode
  errorMessageKey?: string
  checkboxProps?: Omit<CheckboxProps, 'value' | 'onChange'>
}

const FormControlCheckboxFieldNoMemo = <
  T extends { [key: string]: string | boolean | number },
>({
  prop: p,
  touched,
  errors,
  label,
  description,
  errorMessageKey,
  checkboxProps,
}: FormControlCheckboxFieldProps<T, keyof T>) => {
  const prop = p as string

  // IMPORTANT We are not using Formik `useField` hook because it would re-render
  // the whole compnent every time a field in the form changes.
  // That would lead to huge performance issues on slow machines.
  // By using the memo + props the component will re-render only when
  // `touched` and `errors` change.

  const isInvalid = !!errors?.[prop] && !!touched?.[prop]
  const { formatMessage } = useIntl()

  return (
    <FormControl id={prop} isInvalid={isInvalid}>
      {label && (
        <FormLabel
          htmlFor={prop}
          color="primary-dark"
          textTransform="uppercase"
          fontSize="sm"
          fontWeight="normal"
        >
          {checkboxProps?.isRequired && '*'}
          {label}
        </FormLabel>
      )}

      <Field name={prop} type="checkbox">
        {({ field }: { field: FieldInputProps<T[keyof T]> }) => (
          <Checkbox
            {...checkboxProps}
            isInvalid={isInvalid}
            isChecked={field.checked}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
          >
            {checkboxProps?.isRequired && '*'}
            {description}
          </Checkbox>
        )}
      </Field>
      {errors?.[prop] && errorMessageKey && (
        <FormErrorMessage as={HStack} spacing={1}>
          <BiErrorCircle />
          <chakra.span>{formatMessage({ id: errorMessageKey as I18nKey })}</chakra.span>
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export const FormControlCheckboxField = memo(
  FormControlCheckboxFieldNoMemo
) as typeof FormControlCheckboxFieldNoMemo
