import { useCallback, useMemo } from 'react'
import useSWR, { type SWRResponse } from 'swr'

import { type ContentStatus } from '../builder/components/content-loader'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SWRLoaderResponse<T, E = any> = SWRResponse<T, E> & {
  status: ContentStatus
  refresh: () => void
  isLoading: boolean
  isLoaded: boolean
  hasError: boolean
}

export const getContentStatusOfSWR = (
  data: SWRResponse['data'],
  error: SWRResponse['error']
): ContentStatus => {
  if (error) {
    return 'error'
  }

  if (data) {
    return 'loaded'
  }

  return 'loading'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSWRLoader = <T = unknown, E = any>(
  url: string | null
): SWRLoaderResponse<T, E> => {
  const swr = useSWR<T>(url)
  const refresh = useCallback(() => {
    swr.mutate()
  }, [swr])

  const status = useMemo<ContentStatus>(
    () => getContentStatusOfSWR(swr.data, swr.error),
    [swr.data, swr.error]
  )

  const isLoaded = useMemo(() => swr.data !== undefined, [swr.data])
  const isLoading = useMemo(() => !swr.error && !swr.data, [swr.error, swr.data])
  const hasError = useMemo(() => swr.error !== undefined, [swr.error])

  return {
    ...swr,
    isLoaded,
    isLoading,
    hasError,
    refresh,
    status,
  }
}
