import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { Heading } from '@repo/ui'
import { type ReactNode, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { RiMoreFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { formatDateTime } from '../../utils/dates'
import { type Cohort } from '../types/cohort'
import { CohortEditModal } from './cohort-edit-modal'

const CohortDetailsHeaderItem = ({
  title,
  value,
}: {
  title:
    | 'numberOfTrainingPrograms'
    | 'numberOActiveParticipants'
    | 'numberOfAvailableTokens'
    | 'typeOfCohort'
    | 'createdAt'
  value: ReactNode
}) => (
  <Box width="min-content">
    <Text>
      <FormattedMessage id={`cohort.details.header.${title}`} />
    </Text>
    <Text fontWeight="bold">{value}</Text>
  </Box>
)

export const CohortDetailsHeader = ({
  cohort,
  deleteCohort,
  isBenchmarking,
}: {
  cohort: Cohort
  deleteCohort: (cohortId: string) => Promise<void>
  isBenchmarking: boolean
}) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const {
    extId,
    name,
    programs,
    activeTokens,
    availableTokens,
    createdAt,
    type,
    activeEmails,
  } = cohort

  const deleteCohortById = async () => {
    await deleteCohort(extId)
    navigate(-1)
  }

  const navigateToCohortList = () =>
    navigate(isBenchmarking ? '/coaching/benchmarking/cohorts' : '/participants/cohorts')

  const [isOpenEditModal, setIsOpenEditModal] = useState(false)

  const buttonAriaLabel = formatMessage({
    id: 'cohort.details.header.menu.ariaLabel',
  })

  return (
    <Box w="100%" bg="gray.50" p={4}>
      <Button variant="link" onClick={navigateToCohortList}>
        <Icon as={BiArrowBack} boxSize="1.25em" />
        <FormattedMessage id="cohort.details.header.backToList" />
      </Button>
      <Flex alignItems="center">
        <Heading size="md" mr={2}>
          {name}
        </Heading>
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            icon={<RiMoreFill />}
            aria-label={buttonAriaLabel}
            onClick={e => e.stopPropagation()}
          />
          <MenuList onClick={e => e.stopPropagation()}>
            <MenuItem onClick={() => setIsOpenEditModal(true)}>
              <FormattedMessage id="common.edit" />
            </MenuItem>

            <InterceptAndConfirm
              onConfirm={deleteCohortById}
              title={formatMessage(
                { id: 'cohort.delete.confirmModal.title' },
                { cohortName: name }
              )}
              description={formatMessage({
                id: 'cohort.delete.confirmModal.description',
              })}
            >
              {({ openModal }) => (
                <MenuItem onClick={openModal}>
                  <Text color="indicator.red-dark">
                    <FormattedMessage id="common.delete" />
                  </Text>
                </MenuItem>
              )}
            </InterceptAndConfirm>
          </MenuList>
        </Menu>
      </Flex>

      <HStack py={4} spacing={12}>
        <CohortDetailsHeaderItem title="numberOfTrainingPrograms" value={programs} />
        <CohortDetailsHeaderItem
          title="numberOActiveParticipants"
          value={type === 'token' ? activeTokens : activeEmails}
        />
        {type === 'token' ? (
          <CohortDetailsHeaderItem
            title="numberOfAvailableTokens"
            value={availableTokens}
          />
        ) : (
          <CohortDetailsHeaderItem
            title="typeOfCohort"
            value={<FormattedMessage id="cohort.details.header.additionMode" />}
          />
        )}
        <CohortDetailsHeaderItem title="createdAt" value={formatDateTime(createdAt)} />
      </HStack>
      <CohortEditModal
        cohortId={extId}
        isOpen={isOpenEditModal}
        onClose={() => setIsOpenEditModal(false)}
      />
    </Box>
  )
}
