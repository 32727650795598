// Builder step related things in this file are shared between AI and Realtime builder, because they both have the same steps. Only the step contents are different. If in future, they are not the same anymore, we can move them to different files.
export type BuilderStepKey = (typeof orderedBuilderStepKeys)[number]

export const orderedBuilderStepKeys = [
  'goal',
  'conversation-partner',
  'trainee',
  'interaction',
] as const

const isStepKey = (key: string): key is BuilderStepKey =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderedBuilderStepKeys.includes(key as any)

export const checkIsStepKeyAvailableForRouting = (
  stepKey: string,
  resumeStepKey: BuilderStepKey
): stepKey is BuilderStepKey => {
  const isStepKeyValid = isStepKey(stepKey)

  if (!isStepKeyValid) {
    return false
  }

  const lastStepIndex = orderedBuilderStepKeys.indexOf(resumeStepKey)
  const currentStepIndex = orderedBuilderStepKeys.indexOf(stepKey)

  const isAtDisabledStep = currentStepIndex > lastStepIndex

  return !isAtDisabledStep
}
